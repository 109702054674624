import DepositTerminationStart from './DepositTerminationStart'
import DepositTerminationSentToBank from './DepositTerminationSentToBank'
import DepositTerminationCompleted from './DepositTerminationCompleted'
import DepositTerminationSelectType from './DepositTerminationSelectType'
import {TerminationState} from '#state/selectors'
import {DepositDto, TerminationType} from '@fixrate/fixrate-query'

export type TerminationMode = TerminationType | 'HIDE' | 'SELECT'

type Props = {
    deposit: DepositDto,
    terminationMode: TerminationMode,
    setTerminationMode: (TerminationMode) => void
}

export default function DepositTermination(props: Props) {

    const {deposit, terminationMode} = props

    if (deposit?.product.termsType !== 'NOTICE' && deposit?.product.termsType !== 'RECURRING_FIXED_TERMS') {
        return null
    }

    switch (deposit.terminationState) {
        case TerminationState.NO_ACTIVE_TERMINATION:
            switch (terminationMode) {
                case 'HIDE':
                case 'SELECT':
                    // Render this component even if mode is HIDE, since we want to use a transition when it is opened
                    return <DepositTerminationSelectType {...props}/>

                case 'UNCONDITIONAL':
                case 'CONDITIONAL':
                case 'REJECT_INTEREST_RATE_CHANGE':
                    return <DepositTerminationStart {...props}/>

                default:
                    return null
            }
        case TerminationState.STARTED:
        case TerminationState.DOCUMENT_SIGNED:
            return <DepositTerminationStart {...props}/>
        case TerminationState.SENT_TO_BANK:
            return <DepositTerminationSentToBank/>
        case TerminationState.CONFIRMED:
            return <DepositTerminationCompleted {...props}/>
        default:
            return null
    }

}
