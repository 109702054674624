import { CSSProperties, useState } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import { Button, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

type PeriodPickerProps = {
    value: string
    onChange: (period: string) => void
    showPeriods?: string
}

type PeriodProps = PeriodPickerProps & {
    year: number
    style: CSSProperties
}

type Interval = 'year' | 'half' | 'quarter' | 'month'

const YearSelect = ({ value, onChange }: { value: number, onChange: (event: SelectChangeEvent) => void}) => {
    const years = Array.from({length: new Date().getFullYear() + 1 - 2017}, (_, i) => i + 2017).reverse()
    const changeYear = (event: SelectChangeEvent) => onChange(event)

    return (
        <Select
        sx={{ mb: 2 }}
        id='period-year-select'
        value={value.toString()}
        onChange={changeYear}
    >
        {years.map(year =>
            <MenuItem key={year} value={year}>{year}</MenuItem>
        )}
    </Select>
    )
}

export default function PeriodPicker({ value, onChange, showPeriods = 'YHQM' }: PeriodPickerProps) {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    const transitionStyles = {
        right: {
            entering: {transform: 'translateX(100%)'},
            entered: {transform: 'translateX(0%)'},
            exiting: {transform: 'translateX(-100%)'},
            exited: {transform: 'translateX(-100%)'},
        },
        left: {
            entering: {transform: 'translateX(-100%)'},
            entered: {transform: 'translateX(0%)'},
            exiting: {transform: 'translateX(100%)'},
            exited: {transform: 'translateX(100%)'},
        },
    }

    function changeYear(event: SelectChangeEvent) {
        setCurrentYear(event.target.value as unknown as number)
        onChange(event.target.value as string)
    }

    return (
        <>
        <YearSelect value={currentYear} onChange={changeYear} />
        <Stack>
            <div style={{overflow: 'hidden'}}>
                <SwitchTransition>
                    <Transition key={currentYear} timeout={250}>
                        {state => (
                            <Period
                                year={currentYear}
                                value={value}
                                onChange={onChange}
                                style={{transition: 'transform 250ms ease-in-out', ...transitionStyles['left'][state]}}
                                showPeriods={showPeriods}
                            />
                        )}
                    </Transition>
                </SwitchTransition>
            </div>
        </Stack>
        </>
    )
}

function Period({value, year, onChange, showPeriods}: PeriodProps) {
    const { t } = useTranslation()

    const periods = {
        year: [
            {label: t('common.yearly'), key: year + '', from: new Date(year, 0, 1), to: new Date(year, 11, 31)},
        ],
        half: [
            {label: 'H1', key: year + '-H1', from: new Date(year, 0, 1), to: new Date(year, 5, 30)},
            {label: 'H2', key: year + '-H2', from: new Date(year, 6, 1), to: new Date(year, 11, 31)},
        ],
        quarter: [
            {label: 'Q1', key: year + '-Q1', from: new Date(year, 0, 1), to: new Date(year, 2, 31)},
            {label: 'Q2', key: year + '-Q2', from: new Date(year, 3, 1), to: new Date(year, 5, 30)},
            {label: 'Q3', key: year + '-Q3', from: new Date(year, 6, 1), to: new Date(year, 8, 30)},
            {label: 'Q4', key: year + '-Q4', from: new Date(year, 9, 1), to: new Date(year, 11, 31)},
        ],
        month: [
            {label: '1', key: year + '-01', from: new Date(year, 0, 1), to: new Date(year, 1, 0)},
            {label: '2', key: year + '-02', from: new Date(year, 1, 1), to: new Date(year, 2, 0)},
            {label: '3', key: year + '-03', from: new Date(year, 2, 1), to: new Date(year, 3, 0)},
            {label: '4', key: year + '-04', from: new Date(year, 3, 1), to: new Date(year, 4, 0)},
            {label: '5', key: year + '-05', from: new Date(year, 4, 1), to: new Date(year, 5, 0)},
            {label: '6', key: year + '-06', from: new Date(year, 5, 1), to: new Date(year, 6, 0)},
            {label: '7', key: year + '-07', from: new Date(year, 6, 1), to: new Date(year, 7, 0)},
            {label: '8', key: year + '-08', from: new Date(year, 7, 1), to: new Date(year, 8, 0)},
            {label: '9', key: year + '-09', from: new Date(year, 8, 1), to: new Date(year, 9, 0)},
            {label: '10', key: year + '-10', from: new Date(year, 9, 1), to: new Date(year, 10, 0)},
            {label: '11', key: year + '-11', from: new Date(year, 10, 1), to: new Date(year, 11, 0)},
            {label: '12', key: year + '-12', from: new Date(year, 11, 1), to: new Date(year, 11, 31)},
        ],
    }

    const PeriodRow = ({ interval }: { interval: Interval }) => {
        const isFuture = (date: Date) => date > new Date()

        return (
            <Stack spacing={0.5} direction={'row'} justifyContent={'space-evenly'}>
                {periods[interval].map(period =>
                    <Button
                        sx={{ display: 'flex', flexGrow: 1, px: 0, minWidth: 0, '&.Mui-disabled': { boxShadow: 'none'} }}
                        key={period.key}
                        variant={period.key === value || isFuture(period.from)  ? 'contained' : 'outlined'}
                        onClick={() => onChange(period.key)}
                        disabled={isFuture(period.from)}
                    >
                        {period.label}
                    </Button>)
                }
            </Stack>
        )
    }

    return (
        <Stack spacing={0.5}>
            {showPeriods.includes('Y') && <PeriodRow interval='year' />}
            {showPeriods.includes('H') && <PeriodRow interval='half' />}
            {showPeriods.includes('Q') && <PeriodRow interval='quarter' />}
            {showPeriods.includes('M') && <PeriodRow interval='month' />}
        </Stack>
    )
}
