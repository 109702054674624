import {DocumentDto} from '@fixrate/fixrate-query'
import AsyncButton from '../Button/AsyncButton'
import { Avatar, Chip, Tooltip } from '@mui/material'
import { SUCCESS_GREEN } from '#app/colors/colors'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../LoadingSpinner'

type Props = {
    isCheckingSignatureStatus: boolean
    document: DocumentDto
    onStartSignature: () => void
    signText?: string
    disabled?: boolean
}

export default function SigningAction({isCheckingSignatureStatus, document, onStartSignature, signText, disabled}: Props) {
    const {t} = useTranslation()

    if (isCheckingSignatureStatus) {
        return (
            <LoadingSpinner text={t('components-SignableDocument.checkingStatus')} />
        )
    } else if (document.allSignatures.length > 0 && document.signedByAll) {
        return (
            <Tooltip title={t('components-SignableDocument.signedExplanation')}>
                <Chip
                    data-cy="signedStamp"
                    color="success"
                    variant='outlined'
                    icon={
                        <Avatar sx={{ height: '2.4rem', width: '2.4rem', backgroundColor: SUCCESS_GREEN[100] }}>
                            <i className="ri-check-line"></i>
                        </Avatar>
                    }
                    label={t('components-SignableDocument.signed')} />
            </Tooltip>
        )
    } else if (document.allSignatures.length > 0 && document.signedByUser) {
        return (
            <Tooltip title={t('components-SignableDocument.signedByYouExplanation')}>
                <Chip
                    data-cy="signedStamp"
                    color="success"
                    variant='outlined'
                    icon={
                        <Avatar sx={{ height: '2.4rem', width: '2.4rem', backgroundColor: SUCCESS_GREEN[100] }}>
                            <i className="ri-check-line"></i>
                        </Avatar>
                    }
                    label={t('components-SignableDocument.signedByYou')} />
            </Tooltip>
        )
    } else if (document.userCanSign) {
        return (
            <AsyncButton
                disabled={disabled}
                id="signDocumentButton"
                data-cy="signDocumentButton"
                onClick={onStartSignature}
                sx={{mt: { xs: 1, sm: 0 }}}
            >
                {signText || t('components-SignableDocument.signDocument')}
            </AsyncButton>
        )
    } else {
        return (
            <p>&nbsp;</p>
        )
    }
}
