import {Fragment} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from '#state/useSelector'
import SimpleExposureChart from '#pages/Analytics/SimpleExposureChart'
import ExposureTable from '../ExposureTable/ExposureTable'
import {
    getCurrentVolumeByBankForDepositor,
    getDepositorPortfolioByBank,
    getDepositorPortfolioByBankType
} from '#services/thunks/statistics'
import {useTranslation} from 'react-i18next'
import {StaggDataItem} from '#state/stagg'
import { NorwayExposureMap } from '@fixrate/fixrate-analytics-widgets'
import FxPaper from '#app/components/Paper/FxPaper'
import { AnalyticsPaperSx } from '../Analytics'

const AnalyticsExposure = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch<(arg0: unknown) => Promise<StaggDataItem>>()

    const depositor = useSelector(state => state.depositor)
    const deposits = useSelector(state => state.deposits)
    const depositBanks = useSelector(
        state => state.deposits
            .map(deposit => deposit.bankId)
    )

    const numberOfBanksExposed = [...new Set(depositBanks)].length
    const historicalDepositVolume = deposits.reduce((acc, deposit) => acc + deposit.volume, 0)

    return (
        <Fragment key={depositor.id}>
            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.exposureSimpleHeader')} key={'E2'}>
                <p>{t('pages-analytics.exposureSimpleInfo1')}</p>
                <h4>Fordeling per bank</h4>
                <SimpleExposureChart
                    depositorId={depositor.id}
                    getData={() => dispatch(getDepositorPortfolioByBank(depositor.id))}
                />
                <h4>Fordeling per banksegment</h4>
                <SimpleExposureChart
                    depositorId={depositor.id}
                    getData={() => dispatch(getDepositorPortfolioByBankType(depositor.id))}
                />
            </FxPaper>
            {numberOfBanksExposed > 2 && historicalDepositVolume > 50 && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.exposureAdvancedHeader')} key={'E1'}>
                    <p>{t('pages-analytics.exposureAdvancedInfo1')}</p>
                    <p>{t('pages-analytics.exposureAdvancedInfo2')}</p>
                    <ExposureTable getData={(productId: string) => dispatch(getCurrentVolumeByBankForDepositor(depositor.id, productId, undefined, undefined))}/>
                </FxPaper>
            )}
            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.exposureGeographicalHeader')} key={'E4'}>
                <NorwayExposureMap depositorId={depositor.id}/>
            </FxPaper>
        </Fragment>
    )
}

export default AnalyticsExposure

