import styles from "./PopupMessage.module.scss";
import classNames from 'classnames';

interface PopupMessageProps {
    message: string,
    success?: boolean,
    closePopup: () => void,
}

export const PopupMessage = ({message, success = true, closePopup}:PopupMessageProps) => {

    return (
        <div className={styles.popup}>
            <div className={styles.popupBackgroundClickLayer}></div>
            <div className={styles.popupInner}>
                {success && (
                    <p className={styles.popupStatus}>
                        <i className="ri-check-line"></i>
                        <span>Sendt</span>
                    </p>
                )}
                {!success && (
                    <p className={styles.popupStatus}>
                        <i className={classNames("ri-error-warning-line", styles.iconError)}></i>
                        <span>Ikke sendt</span>
                    </p>
                )}
                <p>{message}</p>
                <div onClick={closePopup} className={styles.popupClose}>
                    <span>Lukk</span>
                </div>
            </div>
        </div>
    )
}