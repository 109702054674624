import ClipboardCopyButton from "#app/components/ClipboardCopyButton";
import { copyTextToClipboard } from "#app/components/ClipboardCopyButton/ClipboardCopyButton";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";

export default function SuggestedMessage({ receiverName, linkText, messageText, subject }: { receiverName: string, linkText: string, messageText: string, subject: string }) {
    const [messageIsCopied, setMessageIsCopied] = useState(false)

    const copyMessage = (message: string) => {
        copyTextToClipboard(message)
            .then(() => {
                setMessageIsCopied(true)
                setTimeout(() => setMessageIsCopied(false), 3000)
            })
            .catch(console.error)
    }
    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Box>
                    <Typography variant="h3" fontWeight="600">
                        Lenke til kunde
                    </Typography>
                    <Typography variant="labelDescription">
                        Under finner du lenken med forslaget om ny
                        innskuddskonto som skal sendes til{" "}
                        {receiverName}.
                    </Typography>
                </Box>
                <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography
                        fontSize={"1.4rem"}
                        p={1}
                        sx={{
                            backgroundColor: "rgba(0,0,0,0.05)",
                            border: "0.1rem solid rgba(0,0,0,0.2)",
                        }}
                    ><span data-cy="proposal-link">{linkText ?? ""}</span></Typography>
                    <ClipboardCopyButton inputText={linkText ?? ""} />
                </Stack>
            </Stack>
            <Stack spacing={1} alignItems="flex-start">
                <Box>
                    <Typography variant="h3" fontWeight="600">
                        Forslag til meldingstekst
                    </Typography>
                    <Typography variant="labelDescription">
                        Her er en meldingstekst som du kan kopiere og sende til
                        kunde.
                    </Typography>
                </Box>
                <textarea
                    style={{
                        width: "100%",
                        height: "17rem",
                        border: "0.1rem solid rgba(0,0,0,0.2)",
                        backgroundColor: "rgba(0,0,0,0.05)",
                        fontSize: "1.4rem",
                    }}
                    readOnly
                    value={messageText}
                />
                <Stack direction="row" spacing={1} mt={1}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                            (window.location.href = `mailto:?subject=${encodeURI(
                                subject
                            )}&body=${encodeURI(messageText)}`)
                        }
                        startIcon={<i className={"ri-mail-line"} />}
                    >
                        Åpne meldingen i epostklient
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => copyMessage(messageText)}
                        startIcon={
                            <i
                                className={
                                    messageIsCopied
                                        ? "ri-check-line"
                                        : "ri-file-copy-line"
                                }
                            />
                        }
                    >
                        {messageIsCopied ? "Kopiert!" : "Kopier meldingstekst"}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}
