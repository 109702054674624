import command from '../command/command'
import {signatureStatusCheckDone, startSignatureStatusCheck} from '#state/reducers/signatureStatusIsLoading'

export const ON_SUCCESS = window.location.origin + '/signicat-response/signature-success.html'
export const ON_ABORT = window.location.origin + '/signicat-response/signature-abort.html'

export const updateSignatureStatus = () => (dispatch, getState) => {
    const documents = getState().signatureProcess.documents
    documents.forEach(document => {
        dispatch(startSignatureStatusCheck(document))
    })

    return command(dispatch).updateSignatureStatus().then(() => {
        documents.forEach(document => {
            dispatch(signatureStatusCheckDone(document))
        })
    })
}

export function startElectronicSignature(processId, documentType, identity) {
    return dispatch => command(dispatch).startElectronicSignature(processId, documentType, identity, ON_SUCCESS, ON_ABORT)
}