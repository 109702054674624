const CheckMarkIcon = ({ className }: { className: string }) => (
    <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14ZM6.4976 10.956L10.2927 4.3136L8.87556 3.5038L6.14086 8.28992L4.44699 6.59686L3.2927 7.75115L6.4976 10.956Z'
            fill='#59A161'
        />
    </svg>
)

export default CheckMarkIcon
