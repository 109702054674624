import { useEffect, useState } from 'react';
import * as React from 'react';
import {SxProps, TextField} from '@mui/material'


type Props = {
    id?: string
    label?: string | null
    value: number | null
    onChange: (value: number | null) => void
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void
    size?: 'small' | 'medium'
    formatFn?: (value: number | null) => string
    placeholder?: string
    helperText?: string
    sx?: SxProps
    title?: string
    InputProps?: {
        startAdornment?: JSX.Element,
        endAdornment?: JSX.Element
    }
    error?: boolean
    noDecimals?: boolean
    enforceThousandSeparator?: boolean
    'data-cy'?: string
    className?: string
    disabled?: boolean
}

/**
 * A number input field that allows for easy editing of formatted numbers.
 */
export default function NumberInput({id, label, value, title, InputProps, onChange, onBlur, size, formatFn, placeholder, sx, error, noDecimals, helperText, enforceThousandSeparator, 'data-cy': dataCy, className, disabled}: Props) {
    const [isEditing, setIsEditing] = useState(false)
    const [stringValue, setStringValue] = useState(value?.toString() ?? '')

    useEffect(() => {
        if (!isEditing) {
            setStringValue(convertToString(value, enforceThousandSeparator))
        }
    }, [enforceThousandSeparator, isEditing, value])

    return isEditing ? (
        <TextField
            id={id}
            size={size}
            label={label ?? ''}
            disabled={disabled}
            inputMode={'tel'}
            sx={{...sx}}
            value={stringValue}
            inputProps={{inputMode: 'numeric', pattern: '[0-9]*', 'data-cy': dataCy}}
            className={className}
            error={error}
            InputProps={InputProps}
            helperText={helperText}
            title={title}
            onChange={e => {
                const thousandFormatted = enforceThousandSeparator ? e.target.value.replace(/\s/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : e.target.value
                setStringValue(thousandFormatted)
                const outputValue = parseStringValue(e.target.value, !!noDecimals)
                onChange(outputValue)
            }}
            onBlur={e => {
                setStringValue(convertToString(value, enforceThousandSeparator))
                setIsEditing(false)
                onBlur?.(e)
            }}
        />
    ) : (
        <TextField
            id={id}
            size={size}
            disabled={disabled}
            title={title}
            label={label ?? ''}
            value={(!formatFn ? value : formatFn(value)) ?? ''}
            placeholder={placeholder ?? ''}
            onFocus={() => setIsEditing(true)}
            className={className}
            helperText={helperText}
            InputProps={InputProps}
            sx={{...sx}}
            error={error}
            inputProps={{'data-cy': dataCy}}
        />
    );
}

function parseStringValue(stringValue: string, noDecimals: boolean): number | null {
    // Allow only numbers and punctuation and dashes
    const formattedValue = stringValue.replace(/[^0-9.,-]/g, '').replace(/\s/g, '').replace(',', '.')

    const numberValue = !isNaN(parseFloat(formattedValue)) ? parseFloat(formattedValue) : null

    if (numberValue === null) {
        return null
    }
    return noDecimals ? Math.floor(numberValue) : numberValue
}

function convertToString(value: number | null, enforceThousandSeparator: boolean | undefined): string {
    const stringValue = value?.toString() ?? ''
    const thousandFormatted = enforceThousandSeparator ? stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : stringValue
    return thousandFormatted.replace('.', ',')
}
