import * as React from 'react';
import styles from '#pages/newcustomerdeclarations/NewCustomerDeclarations.module.scss'
import {ChangelogObject, ChangeType} from '#pages/newcustomerdeclarations/Changelog/types'
import classNames from 'classnames'
import {formatTranslationKey, hasNestedChangelogObjects, hasNoChildren} from '#pages/newcustomerdeclarations/Changelog/Changelog'
import {DiffAccordion} from '#pages/newcustomerdeclarations/Changelog/DiffAccordion'
import {DiffRow} from '#pages/newcustomerdeclarations/Changelog/DiffRow'
import {useTranslation} from 'react-i18next'

interface AccordionOptions {
    headerClass?: string,
    labelColor: string,
    labelIcon: string,
}

const ACCORDION_OPTIONS = new Map<ChangeType, AccordionOptions>([
    ['UNCHANGED', {
        headerClass: undefined,
        labelIcon: 'ri-check-line',
        labelColor: '#40a251',
    }],
    ['CHANGED', {
        headerClass: styles.changed,
        labelIcon: 'ri-refresh-line',
        labelColor: '#FFA621',
    }],
    ['ADDED', {
        headerClass: styles.added,
        labelIcon: 'ri-refresh-line',
        labelColor: '#FFA621',
    }],
    ['UPDATED', {
        headerClass: styles.changed,
        labelIcon: 'ri-refresh-line',
        labelColor: '#FFA621',
    }],
    ['DELETED', {
        headerClass: styles.deleted,
        labelIcon: 'ri-delete-bin-line',
        labelColor: '#FFA621',
    }],
])

interface Props {
    currentKey: string,
    changelogObject: ChangelogObject
}

export function CategoryAccordion({currentKey, changelogObject}: Props) {
    const {t} = useTranslation()

    const onlyEmptyChildren = Object.keys(changelogObject.value).every(childKey => hasNoChildren(changelogObject.value[childKey]))
    const changeType = onlyEmptyChildren ? 'UNCHANGED' : changelogObject.changeType
    const isExpanded = changeType !== 'UNCHANGED'

    const {headerClass, labelIcon, labelColor} = ACCORDION_OPTIONS.get(changeType)

    const children: React.ReactElement[] = []

    if (hasNestedChangelogObjects(changelogObject)) {
        Object.keys(changelogObject.value).forEach(childKey => {
            if (hasNestedChangelogObjects(changelogObject.value[childKey])) {
                children.push(
                    <DiffAccordion
                        key={`${currentKey}.${childKey}`}
                        parentKey={currentKey}
                        currentKey={childKey}
                        changelogObject={changelogObject.value[childKey]}
                    />,
                )
            } else {
                children.push(
                    <ul key={`${currentKey}.${childKey}`} className={styles.diffRow}>
                        <DiffRow parentKey={currentKey}
                                 translationKey={formatTranslationKey(currentKey, childKey)}
                                 currentKey={childKey}
                                 changelogObject={changelogObject.value[childKey]}
                        />
                    </ul>,
                )
            }
        })
    }

    return (
        <div className={styles.accordion}>
            <div className={classNames(styles.accordionHeader, headerClass)} style={{borderLeftColor: labelColor}}>
                <h4>{t(`pages-newcustomerdeclarations.field-${currentKey}`)}</h4>
                <div className={styles.statusToggleWrapper}>
                    <div className={styles.status}>
                        <i className={labelIcon} style={{color: labelColor}}/>
                        <span>{t(`pages-newcustomerdeclarations.categoryChangeType-${changeType}`)}</span>
                    </div>
                </div>
            </div>
            {isExpanded && (
                <div className={styles.accordionBody}>
                    {children}
                </div>
            )}
        </div>
    )
}
