import styles from '../InfoTabs.module.scss'
import {FundDto, FundShareClassDto} from '@fixrate/fixrate-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {useCurrencyOutputWithCurrency} from '#app/components/CurrencyOutput/useCurrencyOutput'
import { Box, Button, Drawer, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { PURPLE } from '#app/colors/colors'
import FundTradingInfoCalendar from './FundTradingInfoCalendar'


type Props = { fund: FundDto, shareClass: FundShareClassDto }
const filled = <i className={classNames('ri-information-fill')}></i>
const unfilled = <i className={classNames('ri-information-line')}></i>

export default function FundTradingInfo({ fund, shareClass }: Readonly<Props>) {
    const [isOpenCutOffInfo, setIsOpenCutOffInfo] = useState(false)
    const [isOpenTradingDatesInfo, setIsOpenTradingDatesInfo] = useState(false)
    const [isOpenSettlementDatesInfo, setIsOpenSettlementDatesInfo] = useState(false)
    const { t } = useTranslation()
    const Currency = useCurrencyOutputWithCurrency(shareClass.currency)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const [calendarOpen, setCalendarOpen] = useState(false)

    const countryCode = shareClass.isin.substring(0, 2).toUpperCase()

    return (
        <Stack direction={'row'} alignItems={'flex-start'}>
            <div className={styles.tradingInfo}>
                <div>
                    <h4>{t('pages-fund-details.transactionInfo')}</h4>
                    <ul className={classNames(styles.keyValueList, styles.tradeInfoList)}>
                        <li>
                            <span className={styles.key}>{t('pages-fund-details.minimumBuyAmount')}</span>
                            <span className={styles.value}>{Currency(shareClass.minimumInitialInvestment, { decimals: 0 })}</span>
                        </li>
                        <li>
                            <span className={styles.key}>{t('pages-fund-details.currency')}</span>
                            <span className={styles.value}>{shareClass.currency}</span>
                        </li>
                        <li>
                            <Stack width={'100%'}>
                                <Stack width={'100%'} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                        <span className={styles.key}>{t('pages-fund-details.cutOffTime')}</span>
                                        <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                            <IconButton color='primary' onClick={() => setIsOpenCutOffInfo(!isOpenCutOffInfo)}>
                                                {isOpenCutOffInfo ? filled : unfilled}
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                    <span className={styles.value}>{fund.cutOffTime}</span>
                                </Stack>
                                {isOpenCutOffInfo && (
                                    <Box sx={{p: 1, pl: 1.5, mt: 0.5, borderRadius: '0.4rem', backgroundColor: PURPLE[50], lineHeight: '1rem' }}>
                                        <Typography color={PURPLE[900]} variant={'caption'}>{t('pages-fund-details.cutOffExplanation')}</Typography>
                                    </Box>
                                )}
                            </Stack>
                        </li>
                        <li>
                            <Stack width={'100%'}>
                                <Stack width={'100%'} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                        <span className={styles.key}>{t('pages-fund-details.tradeDates')}</span>
                                        <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                            <IconButton color='primary' onClick={() => setIsOpenTradingDatesInfo(!isOpenTradingDatesInfo)}>
                                                {isOpenTradingDatesInfo ? filled : unfilled}
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                    <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                        <span className={styles.value}>
                                            <Button variant={'text'} onClick={() => setCalendarOpen(!calendarOpen)}>
                                                {t('pages-fund-details.tradingDatesCalendar')}
                                            </Button>
                                        </span>
                                    </Stack>
                                </Stack>
                                {isOpenTradingDatesInfo && (
                                    <Box sx={{p: 1, pl: 1.5, mt: 0.5, borderRadius: '0.4rem', backgroundColor: PURPLE[50], lineHeight: '1rem' }}>
                                        <Typography color={PURPLE[900]} variant={'caption'}>{t('pages-fund-details.tradingDatesInfo')}</Typography>
                                    </Box>
                                )}
                            </Stack>
                        </li>
                        <li>
                            <Stack width={'100%'}>
                                <Stack width={'100%'} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                        <span className={styles.key}>{t('pages-fund-details.settlementDays')}</span>
                                        <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                            <IconButton color='primary' onClick={() => setIsOpenSettlementDatesInfo(!isOpenSettlementDatesInfo)}>
                                                {isOpenSettlementDatesInfo ? filled : unfilled}
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                    <span className={styles.value}>
                                        {fund.settlementDays} {t('pages-fund-details.settlementDaysInfo')}
                                    </span>
                                </Stack>
                                {isOpenSettlementDatesInfo && (
                                    <Box sx={{p: 1, pl: 1.5, mt: 0.5, borderRadius: '0.4rem', backgroundColor: PURPLE[50], lineHeight: '1rem' }}>
                                        <Typography color={PURPLE[900]} variant={'caption'}>{t('pages-fund-details.settlementDaysExplanation')}</Typography>
                                    </Box>
                                )}
                            </Stack>
                        </li>
                    </ul>
                    <p className={styles.nextOrderInfo}>{t('pages-fund-details.nextOrderInfo')}</p>
                </div>
            </div>
            { 
                isMobile ? (
                    <Drawer
                        anchor={"bottom"}
                        open={calendarOpen}
                        onClose={() => setCalendarOpen(false)}
                    >
                        <FundTradingInfoCalendar countryCode={countryCode} />
                    </Drawer>
                ) : (
                    <Box sx={{ maxWidth: '100%', minWidth: '300px' }}>
                        {calendarOpen ? <FundTradingInfoCalendar countryCode={countryCode} /> : null}
                    </Box>
                )
            }
        </Stack>
    )
}

