import { useSelector } from '#app/state/useSelector'
import { DepositorPartnerProposalDto } from '@fixrate/fixrate-query'
import { Badge, Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import ProposalCards from './components/ProposalCards'

interface ProposalTypes {
    orderedProposals: DepositorPartnerProposalDto[] // replace ProposalType with the actual type
    pendingProposals: DepositorPartnerProposalDto[]
    rejectedProposals: DepositorPartnerProposalDto[]
    expiredProposals: DepositorPartnerProposalDto[]
    acceptedProposals: DepositorPartnerProposalDto[]
}

const BASE_URL = '/partner/proposals'
export default function PartnerProposalsOverview() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const partner = useSelector(state => state.partner)
    const [proposals, setProposals] = useState<ProposalTypes>({
        orderedProposals: [],
        pendingProposals: [],
        rejectedProposals: [],
        expiredProposals: [],
        acceptedProposals: [],
    })

    useEffect(() => {
        if (!partner) return
        const proposals: DepositorPartnerProposalDto[] = partner?.customers
            .map(customer => customer.partnerRelations?.map(relation => relation.partnerProposals))
            .flat()
            .flat()
        const orderedProposals = proposals?.filter(p => p.status === 'ORDERED')
        const acceptedProposals = proposals?.filter(p => p.status === 'ACCEPTED')
        const rejectedProposals = proposals?.filter(p => p.status === 'REJECTED')
        const expiredProposals = proposals?.filter(p => p.status === 'EXPIRED')
        const pendingProposals = proposals?.filter(p => p.status === 'PENDING')
        setProposals({ orderedProposals, pendingProposals, rejectedProposals, expiredProposals, acceptedProposals })
    }, [partner])

    const allOrders = [
        {
            label: t('pages-proposal-overview.sentProposals'),
            url: BASE_URL + '/sent',
            proposals: proposals.pendingProposals,
            visible: location.pathname === BASE_URL + '/sent',
            dataCy: 'pending-proposals',
        },
        {
            label: t('pages-proposal-overview.rejectedProposals'),
            url: BASE_URL + '/rejected',
            proposals: proposals.rejectedProposals,
            visible: location.pathname === BASE_URL + '/rejected',
            dataCy: 'rejected-proposals',
        },
        {
            label: t('pages-proposal-overview.expiredProposals'),
            url: BASE_URL + '/expired',
            proposals: proposals.expiredProposals,
            visible: location.pathname === BASE_URL + '/expired',
            dataCy: 'expired-proposals',
        },
        {
            label: t('pages-proposal-overview.acceptedProposals'),
            url: BASE_URL + '/accepted',
            proposals: proposals.acceptedProposals,
            visible: location.pathname === BASE_URL + '/accepted',
            dataCy: 'accepted-proposals',
        },
                {
            label: t('pages-proposal-overview.activeOrders'),
            url: BASE_URL + '/orders',
            proposals: proposals.orderedProposals,
            visible: location.pathname === BASE_URL + '/orders',
            dataCy: 'ordered-proposals',
        },
    ]

    return (
        <Stack spacing={4} alignItems='flex-start'>
            <ToggleButtonGroup value={location.pathname} color='primary'>
                {allOrders.map(item => (
                    <ToggleButton data-cy={item.dataCy} key={item.url} value={item.url} onClick={() => navigate(item.url)}>
                        <Stack direction={'row'} alignItems='center' spacing={1.6} ml={1} mr={1}>
                            <Typography fontWeight={600} fontSize={'1.4rem'}>
                                {item.label}
                            </Typography>
                            {item.proposals.length > 0 && <Badge badgeContent={item.proposals.length} color='primary' sx={{ mr: 1 }} />}
                        </Stack>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {allOrders.map(
                order =>
                    order.visible && (
                        <Box key={order.label} width="100%">
                            {order.proposals.length > 0 ? <ProposalCards proposals={order.proposals} /> : <Typography variant="emptyState">Det er ingen {order.label.toLowerCase()}</Typography>}
                        </Box>
                    )
            )}
        </Stack>
    )
}
