import styles from './BrregBox.module.scss'
import {DepositorDto} from '@fixrate/fixrate-query'
import {useTranslation} from "#components/i18n";
import {useSelector} from "#state/useSelector";
import {DateOutput} from "#components";

interface Props {
    depositor: DepositorDto,
}

const formatPerson = (person) => `${person.name} (${DateOutput.formatDate(person.birthDate)})`

export default function BrregBoard({depositor}: Props) {
    const {t} = useTranslation('pages-organizations')
    const companyPublicInformation = useSelector(state => state.companyPublicInformation.find(c => c.nationalIdentity === depositor.nationalIdentity))
    if(!companyPublicInformation) return null;

    const boardChairman = companyPublicInformation.people.find(person => person.roles.includes("BOARD_CHAIRMAN"))
    const boardMembers = companyPublicInformation.people.filter(person => person.roles.includes("BOARD_MEMBER") || person.roles.includes("BOARD_DEPUTY_MEMBER"))
    const ceo = companyPublicInformation.people.find(person => person.roles.includes("CEO"))
    const keyPerson = companyPublicInformation.people.find(person => person.roles.includes("KEY_PERSON"))

    return (
        <div className={styles.data}>
            { boardChairman && <div className={styles.section}>
                <b>
                    <span>{t('boardChairman')}</span>
                </b>

                <ul>
                    <li>{formatPerson(boardChairman)}</li>
                </ul>
            </div> }

            { boardMembers && boardMembers.length > 0 && <div className={styles.section}>
                <b>
                    <span>{t('boardMembers')}</span>
                </b>

                <ul>
                    {boardMembers.map((person, i) => <li key={i}>{formatPerson(person)}</li>)}
                </ul>
            </div> }

            { ceo && <div className={styles.section}>
                <b>
                    <span>{t('boardCeo')}</span>
                </b>

                <ul>
                    <li>{formatPerson(ceo)}</li>
                </ul>
            </div> }

            { keyPerson && <div className={styles.section}>
                <b>
                    <span>{t('boardKeyPerson')}</span>
                </b>

                <ul>
                    <li>{formatPerson(keyPerson)}</li>
                </ul>
            </div> }
        </div>
    )
}
