import { useConfirmModal } from '#app/layers/ConfirmModal/ConfirmModal';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import { useCommand } from '#command';
import { DateOutput, DatePicker } from '#components';
import Button from '#components/Button';
import ButtonRow from '#components/ButtonRow/ButtonRow';
import LabeledInfo from '#components/LabeledInfo/LabeledInfo';
import LabeledInput from '#components/LabeledInput/LabeledInput';
import Modal from '#components/Modal';
import PageHeader from '#components/PageHeader/PageHeader';
import PageLayout from '#components/PageLayout/PageLayout';
import Paper from '#components/Paper';
import { getState, State } from '#pages/portfolio-bank/AccountStatements/AccountStatementListWithUpload';
import { formatAccount } from '#services/formatnumber';
import * as selectors from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { MenuItem, Select } from '@mui/material';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {NumericFormat} from 'react-number-format'
import { useNavigate, useParams } from 'react-router-dom';
import styles from './AccountStatementManual.module.css';

type Params = {
    documentId?: string
}

export default function AccountStatementManual() {
    const {t} = useTranslation()
    const {documentId} = useParams<Params>()
    const navigate = useNavigate()
    const organisationCountry = useCurrentCountryCode()

    const {reportAccountStatementManually, processBankDocumentManually, deleteBankDocument} = useCommand()

    const [showManualProcessing, setShowManualProcessing] = useState(false)

    const confirmModal = useConfirmModal()
    const bankId = useSelector(state => selectors.bankInSession(state)?.id)
    const documentLink = '/api/document/id/' + documentId + '/data?inline=true'
    const deposits = useSelector(state => state.deposits)

    const getDateByMonthsFromNow = (months) => addMonths(new Date(), -(months))
    const getPeriod = (date) => format(new Date(date), 'yyyy-MM')
    const startOfThisYear = new Date(new Date().getFullYear(), 0, 1)
    const lastYear = startOfThisYear.getFullYear() - 1

    const [typeSelected, setTypeSelected] = useState(false)
    const [documentType, setDocumentType] = useState(null)
    const [depositId, setDepositId] = useState(null)
    const [balance, setBalance] = useState(null)
    const [formattedBalance, setFormattedBalance] = useState('')
    const [accruedInterest, setAccruedInterest] = useState(null)
    const [formattedAccruedInterest, setFormattedAccruedInterest] = useState('')
    const [interestDate, setInterestDate] = useState(null)
    const [period, setPeriod] = useState(getPeriod(getDateByMonthsFromNow(1)))

    const document = useSelector(state => state.bankDocuments).find(document => document.documentId === documentId)
    if (!document) {
        return null
    }
    const deposit = deposits.find(d => d.id === document.depositId)


    const isValid = (): boolean => {
        switch (documentType) {
            case 'MONTHLY_STATEMENT':
                return !!(depositId && balance && accruedInterest && interestDate && period)

            case 'YEARLY_STATEMENT':
                return !!(depositId && period)

            case 'OTHER':
                return !!(depositId && period)

            default:
                return false
        }
    }

    const reset = () => {
        setShowManualProcessing(false)
        setTypeSelected(false)
        setDocumentType(null)
    }

    const deleteDocument = () => {
        confirmModal({
            title: t('pages-portfolio-bank.statementsDeleteConfirmTitle'),
            text: t('pages-portfolio-bank.statementsDeleteConfirmText'),
            submitButtonText: t('common.delete'),
            submitAction: async () => {
                const {waitForCommand} = await deleteBankDocument(documentId, bankId)
                await waitForCommand()
                navigate('/bank-reports/balance-reporting')
            },
        })
    }

    const onConfirm = async () => {
        if (isValid()) {
            let success

            if (documentType === 'MONTHLY_STATEMENT') {
                const {waitForCommand} = await reportAccountStatementManually(documentId, depositId, balance, accruedInterest, format(new Date(interestDate), 'yyyy-MM-dd'), period)
                success = await waitForCommand()
            } else if (documentType === 'YEARLY_STATEMENT') {
                const {waitForCommand} = await processBankDocumentManually(documentId, bankId, depositId, format(startOfThisYear, 'yyyy-MM'), documentType)
                success = await waitForCommand()
            } else if (documentType === 'OTHER') {
                const {waitForCommand} = await processBankDocumentManually(documentId, bankId, depositId, undefined, documentType)
                success = await waitForCommand()
            }

            if (success) {
                reset()
            }
        }
    }

    const documentTypeOptions = ['YEARLY_STATEMENT', 'MONTHLY_STATEMENT', 'OTHER']
        .map(type => <MenuItem key={type} value={type}>{t('enum-BankDocumentType.' + type)}</MenuItem>)

    const accountOptions = deposits
        .slice().sort((a, b) => a.account.localeCompare(b.account))
        .map(deposit =>{
            const bankInternalAccountNumber = deposit.bankInternalAccountNumber ? ' (' + deposit.bankInternalAccountNumber + ')' : ''
             return (<MenuItem key={deposit.id} value={deposit.id}>
                <div className={styles.accountMenuItem}>
                    <div>{formatAccount(deposit.account, organisationCountry) + bankInternalAccountNumber + ' ' + deposit.depositor.name}</div>
                    <div>{DateOutput.formatDate(deposit.created)} {deposit.volume}M @ {deposit.nominalInterestRate}%</div>
                </div>
            </MenuItem>)
            }
        )

    const periodOptions = Array.from(Array(12).keys())
        .map(index => getDateByMonthsFromNow(index + 1))
        .map((date, index) =>
            <MenuItem key={index} value={getPeriod(date)}>{DateOutput.formatMonthYear(date)}</MenuItem>,
        )

    const selectTypeModal = !!document && (
        <Modal
            width={'60rem'}
            header={t('pages-portfolio-bank.statementsManualProcessingHeader')}
            onCancel={() => {
                reset()
            }}
            onSubmit={() => {
                if (documentType) {
                    setTypeSelected(true)
                }
            }}
            submitButtonText={t('common.continue')}
        >
            <div>
                <form className={styles.inputForm} autoComplete={'off'}>
                    <LabeledInput label={t('pages-portfolio-bank.statementsManualSelectDocumentType')} className={styles.input}>
                        <Select style={{width: '100%'}} onChange={e => setDocumentType(e.target.value)} value={documentType} data-cy="documentTypeSelect" displayEmpty>
                            <MenuItem value={null}>{t('common.selectOption')}</MenuItem>
                            {documentTypeOptions}
                        </Select>
                    </LabeledInput>
                </form>
            </div>
        </Modal>
    )

    const modal = !!document && (
        <Modal
            width={'60rem'}
            header={t('pages-portfolio-bank.statementsManualProcessingHeader')}
            onCancel={reset}
            onSubmit={onConfirm}
            submitButtonText={t('pages-portfolio-bank.statementsSendToCustomerButtonText')}
        >
            <div>
                <form className={styles.inputForm} autoComplete={'off'}>
                    {documentType === 'YEARLY_STATEMENT' &&
                        <h4>{t('enum-BankDocumentType.' + documentType) + ' ' + lastYear}:</h4>
                    }

                    <LabeledInput label={t('pages-portfolio-bank.statementsManualAccountNoLabel')} className={styles.input}>
                        <Select
                            style={{width: '100%'}} onChange={e => setDepositId(e.target.value)}
                            value={depositId} data-cy="accountNoSelect" displayEmpty
                        >
                            <MenuItem value={null}>{t('common.selectOption')}</MenuItem>
                            {accountOptions}
                        </Select>
                    </LabeledInput>

                    {documentType === 'MONTHLY_STATEMENT' && (
                        <Fragment>
                            <LabeledInput label={t('pages-portfolio-bank.statementsManualPeriodLabel')} className={styles.input}>
                                <Select onChange={e => setPeriod(e.target.value)} value={period} data-cy="periodSelect">
                                    {periodOptions}
                                </Select>
                            </LabeledInput>

                            <LabeledInput label={t('pages-portfolio-bank.statementsManualInterestDateLabel')} className={styles.input} helpText={t('pages-portfolio-bank.statementsInterestDateInfo')}>
                                <DatePicker
                                    data-cy="interestDate"
                                    id="interestDate"
                                    selected={interestDate}
                                    onChange={date => setInterestDate(date)}
                                    maxDate={addDays(new Date(), -1)}
                                />
                            </LabeledInput>

                            <LabeledInput label={interestDate ? t('pages-portfolio-bank.statementsManualBalancePerDateLabel', {date: DateOutput.formatDate(interestDate)}) : t('pages-portfolio-bank.statementsManualBalanceLabel')} className={styles.input}>
                                <NumericFormat
                                    data-cy="balance"
                                    value={formattedBalance}
                                    onValueChange={(values) => {
                                        setBalance(values.value)
                                        setFormattedBalance(values.formattedValue)
                                    }}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={'kr '}
                                />
                            </LabeledInput>

                            <LabeledInput label={interestDate ? t('pages-portfolio-bank.statementsManualAccruedInterestPerDateLabel', {date: DateOutput.formatDate(interestDate)}) : t('pages-portfolio-bank.statementsManualAccruedInterestLabel')} className={styles.input}>
                                <NumericFormat
                                    data-cy="accruedInterest"
                                    value={formattedAccruedInterest}
                                    onValueChange={(values) => {
                                        setAccruedInterest(values.value)
                                        setFormattedAccruedInterest(values.formattedValue)
                                    }}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={'kr '}
                                />
                            </LabeledInput>
                        </Fragment>
                    )}
                </form>
            </div>
        </Modal>
    )

    const approvedMarkup = (
        <Paper title={t('pages-portfolio-bank.statementsManualUploadedDocumentHeader')}>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-bank.statementsTypeLabel')} info={t('enum-BankDocumentType.' + document.type)}/>

                {document.type === 'MONTHLY_STATEMENT' &&
                    <LabeledInfo label={t('pages-portfolio-bank.statementsPeriodLabel')}>
                        <DateOutput.MonthYear date={parse(document?.month, 'yyyy M', new Date())}/>
                    </LabeledInfo>
                }

                {document.type === 'YEARLY_STATEMENT' &&
                    <LabeledInfo label={t('pages-portfolio-bank.statementsYearLabel')} info={'' + (parse(document?.month, 'yyyy M', new Date()).getFullYear() - 1)}/>
                }

                <LabeledInfo label={t('pages-portfolio-bank.statementsUploadTimeLabel')} info={DateOutput.formatDateTime(document?.received)}/>
                <LabeledInfo label={t('pages-portfolio-bank.statementsCustomerLabel')} info={document?.depositorName ? document.depositorName : '-'}/>
                <LabeledInfo label={t('pages-portfolio-bank.statementsAccountNoLabel')} info={deposit?.account ? formatAccount(deposit.account, organisationCountry) : '-'}/>
            </div>

            <p/><p>{t('pages-portfolio-bank.statementsManualApproved')}</p>
        </Paper>
    )

    const errorMarkup = (
        <Paper title={t('pages-portfolio-bank.statementsManualUploadedDocumentHeader')}>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-bank.statementsUploadTimeLabel')} info={DateOutput.formatDateTime(document?.received)}/>
            </div>

            <p/><p>{t('pages-portfolio-bank.statementsManualError')}</p>
        </Paper>
    )

    const processingMarkup = (
        <Paper title={t('pages-portfolio-bank.statementsManualUploadedStatementHeader')}>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-bank.statementsUploadTimeLabel')} info={DateOutput.formatDateTime(document?.received)}/>
            </div>

            <p/><p>{t('pages-portfolio-bank.statementsManualProcessing')}</p>
        </Paper>
    )

    const notParsableMarkup = (
        <Paper title={t('pages-portfolio-bank.statementsManualUploadedDocumentHeader')}>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-bank.statementsUploadTimeLabel')} info={DateOutput.formatDateTime(document?.received)}/>
            </div>

            <p/><p>{t('pages-portfolio-bank.statementsManualNotParsable')}</p>

            <ButtonRow className={styles.buttons}>
                <Button variant={'secondary'} data-cy="deleteDocumentButton" onClick={deleteDocument}>
                    {t('pages-portfolio-bank.statementsDeleteDocument')}
                </Button>

                <Button variant={'primary'} data-cy="processManuallyButton" inProgress={false} onClick={() => setShowManualProcessing(true)}>
                    {t('pages-portfolio-bank.statementsManualProcessingButtonText')}
                </Button>
            </ButtonRow>
        </Paper>
    )

    const unknownMarkup = (
        <Paper title={t('pages-portfolio-bank.statementsManualUploadedStatementHeader')}>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-bank.statementsUploadTimeLabel')} info={DateOutput.formatDateTime(document?.received)}/>
                <LabeledInfo label={t('pages-portfolio-bank.statementsTypeLabel')} info={document?.type}/>
            </div>

            <p/><p>{t('pages-portfolio-bank.statementsManualUnknownStatus')}</p>
        </Paper>
    )

    const previewMarkup = (
        <div className={styles.documentObjectBackground}>
            <object className={styles.documentObject} data={documentLink} type="application/pdf">
                <div className={styles.documentObjectFallback}>
                    <p><Trans t={t} i18nKey={'pages-portfolio-bank.missingPdfSupport'}/></p>
                    <p><Trans t={t} i18nKey={'pages-portfolio-bank.installPdfSupport'}>...<a href="https://get.adobe.com/reader/" rel="noopener noreferrer" target="_blank">...</a>...</Trans></p>
                </div>
            </object>
        </div>
    )

    return (
        <Fragment>
            <PageHeader
                title={t('pages-portfolio-bank.statementsManualPageHeader')}
                backToLink={'/bank-reports/balance-reporting'}
            />
            <PageLayout>
                {getState(document) === State.sent && approvedMarkup}
                {getState(document) === State.error && errorMarkup}
                {getState(document) === State.processing && processingMarkup}
                {getState(document) === State.notparsable && notParsableMarkup}
                {getState(document) === State.unknown && unknownMarkup}
                {previewMarkup}

                {showManualProcessing && !typeSelected && selectTypeModal}
                {showManualProcessing && typeSelected && modal}
            </PageLayout>
        </Fragment>
    );
}
