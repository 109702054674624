import { useConfirmModal } from '#app/layers/ConfirmModal/ConfirmModal'
import { useIsIntegrationsAdmin } from '#app/services/authorization'
import { useCommand } from '#command'
import Paper from '#components/Paper'
import EmailDistributionEditTargetConfiguration from '#pages/organizations/OrganizationDetail/Integrations/EmailDistributionConfigurationEditTarget'
import { DepositorDto } from '@fixrate/fixrate-query'
import { Button, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

type EmailDistributionIntegrationProps = {
    depositor: DepositorDto,
}
type EmailTargetDialog = {
    open: boolean
    targetId?: string | null
}

export function EmailDistributionIntegration({depositor}: EmailDistributionIntegrationProps) {
    const {t} = useTranslation()
    const integrationsAdmin = useIsIntegrationsAdmin()
    const confirmModal = useConfirmModal()
    const {removeDepositorEmailDistributionTarget} = useCommand()
    const [emailDistributionTargetDialogOpen, setEmailDistributionTargetDialogOpen] = useState<EmailTargetDialog>({open: false})

    function confirmRemoveTarget(targetId: string, email: string) {
        confirmModal({
            title: t('pages-organizations.integrationOverview.emailDistribution.removeTitle'),
            text: t('pages-organizations.integrationOverview.emailDistribution.removeText', {email: email}),
            submitButtonText: t('common.delete'),
            submitAction: async () => {
                await removeDepositorEmailDistributionTarget(depositor.id, targetId)
            },
        })
    }

    return (
        <Paper title={t('pages-organizations.integrationOverview.emailDistribution.title')}>
            {emailDistributionTargetDialogOpen.open && (
                <EmailDistributionEditTargetConfiguration
                    depositor={depositor}
                    targetId={emailDistributionTargetDialogOpen.targetId}
                    onClose={() => setEmailDistributionTargetDialogOpen({open: false})}
                />
            )}

            <Stack spacing={2}>
                <Typography>{t('pages-organizations.integrationOverview.emailDistribution.description')}</Typography>
                <List>
                    {depositor.emailDistributionConfiguration.targets.map(target => (
                        <Fragment key={target.targetId}>
                            <ListItem>
                                <ListItemText>{target.email}</ListItemText>
                                {integrationsAdmin && (
                                    <Fragment>
                                        <Button size={'small'} startIcon={<i className="ri-edit-line"/>} onClick={() => setEmailDistributionTargetDialogOpen({open: true, targetId: target.targetId})}>
                                            {t('pages-organizations.integrationOverview.emailDistribution.edit')}
                                        </Button>
                                        <Button size={'small'} startIcon={<i className="ri-delete-bin-line"/>} onClick={() => confirmRemoveTarget(target.targetId, target.email)}>
                                            {t('pages-organizations.integrationOverview.emailDistribution.remove')}
                                        </Button>
                                    </Fragment>
                                )}
                            </ListItem>
                            <Divider component={'li'}/>
                        </Fragment>
                    ))}
                </List>
                {integrationsAdmin && (
                    <Button variant={'outlined'} onClick={() => setEmailDistributionTargetDialogOpen({open: true})}>
                        {t('pages-organizations.integrationOverview.emailDistribution.add')}
                    </Button>
                )}
            </Stack>
        </Paper>
    )
}
