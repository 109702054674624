import { Fragment, useEffect, useState } from 'react';
import Modal from '#components/Modal'
import ToggleSwitch from '#components/ToggleSwitch'
import styles from './SellModal.module.scss'
import {CurrencyOutput, NumberInput} from '#components'
import {FundPlacementDto} from '@fixrate/fixrate-query'
import {useSelector} from '#app/state/useSelector'
import {SettlementAccountSelect} from '#blocks/SettlementAccountSelect/SettlementAccountSelect'
import {v4 as uuidv4} from 'uuid'
import {Big} from 'big.js'
import {useFieldState} from '@fixrate/fieldstate'
import {getI18n, useTranslation} from 'react-i18next'
import {Box, Button, Stack, Typography} from '@mui/material'
import config from '#app/config'
import {Buffer} from 'buffer'
import {useEndpoint} from '#command'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput';
import usePortfolio from '#app/services/usePortfolio';
import FundPlatformFeeTable from "#components/FundPlatformFeeTable/FundPlatformFeeTable";
import CurrencyPricingBar from "#pages/FundDetails/PricingChart/CurrencyPricingBar";
import FxDialog from "#components/FxDialog/FxDialog";
import FxDialogTitle from "#components/FxDialog/FxDialogTitle";
import FxDialogContent from "#components/FxDialog/FxDialogContent";

type Props = {
    onCancel: () => void,
    fundPlacement: FundPlacementDto
}

export default function SellModal({onCancel, fundPlacement}: Props) {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()
    const {getTokenForFixrateCapital} = useEndpoint()

    const portfolio = usePortfolio()

    const fund = useSelector(state => state.funds.find(fund => fund.id === fundPlacement.fundId))
    const shareClass = fund?.fundShareClasses.find(shareClass => shareClass.id === fundPlacement.fundShareClassId)
    const depositorName = useSelector(state => state.depositor.name)
    const settlementAccounts = useSelector(state => state.depositor.settlementAccounts)

    const [units, setUnits] = useState<Big>(new Big(0))
    const [unitInPercent, setUnitInPercent] = useState(true)
    const [selectedSettlementAccountId, setSelectedSettlementAccountId] = useState('')
    const [depositorFundPortfolioClientAccountId, setDepositorFundPortfolioClientAccountId] = useState<string | undefined>(undefined)
    const percentageField = useFieldState(0, ({value, isEditing}) => {
        if (isEditing) {
            return ''
        }
        if (value > 100) {
            return t('pages-FundOverview.percentageToHighError')
        }
        if (value < 0) {
            return t('pages-FundOverview.forbiddenValueError')
        }
    })
    const amountField = useFieldState(0, ({value, isEditing}) => {
        if (isEditing) {
            return ''
        }
        if (value > fundPlacement.availableUnitQuantity * shareClass.nav) {
            return t('pages-FundOverview.amountToHighError')
        }
        if (value < 0) {
            return t('pages-FundOverview.forbiddenValueError')
        }
    })
    const [platformFeeTableVisible, setPlatformFeeTableVisible] = useState(false)

    useEffect(() => {
        if (settlementAccounts.length === 1) {
            setSelectedSettlementAccountId(settlementAccounts[0].id)
        }
        const portfolioClientAccount = settlementAccounts.find(acc => acc.currency === portfolio?.currency && acc.fundPortfolioClientAccount) || undefined
        if (portfolioClientAccount) {
            setDepositorFundPortfolioClientAccountId(portfolioClientAccount.id)
            setSelectedSettlementAccountId(portfolioClientAccount.id)
        }
    }, [portfolio?.currency, settlementAccounts])

    const minimumNumberOfUnits = 1 // TODO: Get from backend
    const minimumAmount = 10_000 // TODO: Get from backend

    function setUnitsByPercentage(percentage: number | null) {
        if (percentage === null) {
            setUnits(new Big(0))
            return
        }

        if (percentage <= 0) {
            setUnits(new Big(0))
            return
        }

        // calculate available amount from current nav and units
        const currentAmount = new Big(fundPlacement.availableUnitQuantity).times(shareClass.nav).round(2)

        let unitsFromPercent = new Big(fundPlacement.availableUnitQuantity).times(percentage).div(100)
        if (unitsFromPercent.gt(fundPlacement.availableUnitQuantity)) {
            unitsFromPercent = new Big(fundPlacement.availableUnitQuantity)
        }

        // if remaining amount is below minimum amount, sell all units
        if (currentAmount.minus(unitsFromPercent.times(shareClass.nav)).lt(minimumAmount)) {
            setUnits(new Big(fundPlacement.availableUnitQuantity))
            return
        }

        // if remaining unit quantity is below minimum quantity, sell all units
        if (new Big(fundPlacement.availableUnitQuantity).minus(unitsFromPercent).lt(minimumNumberOfUnits)) {
            setUnits(new Big(fundPlacement.availableUnitQuantity))
            return
        }

        setUnits(unitsFromPercent)
    }

    function setUnitsByAmount(amountToSell: number | null): void {
        if (amountToSell === null) {
            setUnits(new Big(0))
            return
        }

        if (amountToSell <= 0) {
            setUnits(new Big(0))
            return
        }

        // calculate available amount from current nav and units
        const currentAmount = new Big(fundPlacement.availableUnitQuantity).times(shareClass.nav).round(2)

        const unitsToSell = new Big(amountToSell).div(shareClass.nav).round(fund.decimalPrecision)

        // if amount to sell is bigger than available amount, sell all units
        if (currentAmount.lt(amountToSell)) {
            setUnits(new Big(fundPlacement.availableUnitQuantity))
            return
        }

        // if remaining amount is below minimum amount, sell all units
        if (currentAmount.minus(amountToSell).lt(minimumAmount)) {
            setUnits(new Big(fundPlacement.availableUnitQuantity))
            return
        }

        // if remaining unit quantity is below minimum quantity, sell all units
        if (new Big(fundPlacement.availableUnitQuantity).minus(unitsToSell).lt(minimumNumberOfUnits)) {
            setUnits(new Big(fundPlacement.availableUnitQuantity))
            return
        }

        setUnits(unitsToSell)
    }

    function resetAmountFields() {
        amountField.setValue(0)
        percentageField.setValue(0)
        setUnits(new Big(0))
    }

    async function startCheckout() {
        const items = [{
            id: fundPlacement.id,
            subId: fundPlacement.fundShareClassId,
            amount: units.toNumber(),
        }]
        const {token} = await getTokenForFixrateCapital()

        const lng = getI18n().language
        const itemsBase64 = Buffer.from(JSON.stringify(items), 'utf8').toString('base64')
        window.location.href = `${config().fixrateCapitalBaseUrl}/order/sell/confirm?lng=${lng}&items=${itemsBase64}&customer=${fundPlacement.depositorId}&externalReference=${uuidv4()}&settlementAccountId=${selectedSettlementAccountId}&token=${token}`
    }

    return (
        <Modal className={styles.sellModal} header={t('pages-FundOverview.sellFund') + ' – ' + shareClass.fullName} onCancel={onCancel}>
            <Stack className={styles.modalWrapper}>
                <Stack className={styles.salesInfoRow}>
                    <ul className={styles.keyValueList}>
                        <li>
                            <span className={styles.key}>{t('pages-FundOverview.organization')}</span>
                            <span className={styles.value}>{depositorName}</span>
                        </li>
                        <li>
                            <span className={styles.key}>{t('pages-FundOverview.unitsToSell')}</span>
                            <span className={styles.value}>
                                {CurrencyOutput.formatNoCode(fundPlacement.availableUnitQuantity, fund.decimalPrecision)}
                            </span>
                        </li>
                        <li>
                            <span className={styles.key}>{t('pages-FundOverview.availableValue')}</span>
                            <span
                                data-cy="estimatedAvailableText"
                                className={styles.value}
                            >{Currency(fundPlacement.availableUnitQuantity * shareClass.nav, { decimals: 0 })}</span>
                        </li>
                    </ul>
                </Stack>
                <Stack className={`${styles.salesInfoRow} ${styles.bottomDivider}`} spacing={2}>
                    <Stack spacing={1} className={styles.settlementAccountSelect}>
                        <label>{t('pages-FundOverview.selectSettlementAccount')}</label>
                        <SettlementAccountSelect accounts={
                            settlementAccounts.filter(
                                a =>
                                    depositorFundPortfolioClientAccountId
                                        ? a.id === depositorFundPortfolioClientAccountId
                                        : a.currency === portfolio?.currency || (portfolio?.currency === "NOK" && a.currency === null)
                            )
                        }
                                                 selectedAccountId={selectedSettlementAccountId}
                                                 setSelectedAccountId={setSelectedSettlementAccountId}
                        />
                    </Stack>

                    <div>
                        <label htmlFor={'numberInput'} className={styles.labelSwitch}>
                            <span>{unitInPercent ? t('pages-FundOverview.unitsBeingSold') : t('pages-FundOverview.salesAmount')}</span>
                            <div className={styles.switchWrapper}>
                                <ToggleSwitch
                                    dataCy="salesAmountPercent"
                                    onChange={() => {
                                        setUnitInPercent(!unitInPercent)
                                        resetAmountFields()
                                    }}
                                    checked={unitInPercent}
                                    className={styles.smallSwitch}
                                />
                                <span>%</span>
                            </div>
                        </label>
                        {unitInPercent ? (
                            <Fragment>
                                <Stack direction={'row'} spacing={2}>
                                    <div className={styles.withLabel}>
                                        <NumberInput
                                            data-cy="quantityInput"
                                            sx={{width: '20rem', backgroundColor: 'white'}}
                                            value={percentageField.value}
                                            onChange={v => {
                                                percentageField.setValue(v)
                                                setUnitsByPercentage(v)
                                            }}
                                            onBlur={percentageField.onBlur}
                                            noDecimals
                                        />
                                        <span className={styles.label}>%</span>
                                    </div>
                                    <Stack>
                                        <p className={styles.estimateTitle}>{t('pages-FundOverview.estimatedTotalAmount')}</p>
                                        <p data-cy="estimatedTotalText" className={styles.estimateOutput}>
                                            {Currency(units ? (fundPlacement.availableUnitQuantity * shareClass.nav) / (fundPlacement.availableUnitQuantity / units.toNumber()) : 0, { decimals: 0 })}
                                        </p>
                                    </Stack>
                                </Stack>
                                {percentageField.errorMessage && <p className={'field-error-message'} data-cy="quantityInputErrorText">{percentageField.errorMessage}</p>}
                                <p className={styles.metaDescription}>{t('pages-FundOverview.sellTradingDescription')}</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={styles.withLabel}>
                                    <NumberInput
                                        data-cy="quantityInput"
                                        sx={{width: '20rem', backgroundColor: 'white'}}
                                        value={amountField.value}
                                        onChange={v => {
                                            amountField.setValue(v)
                                            setUnitsByAmount(v)
                                        }}
                                        formatFn={(val) => Currency(val, { decimals: 0 })}
                                        onBlur={amountField.onBlur}
                                        noDecimals
                                    />
                                    <span className={styles.label}>{fundPlacement.currency}</span>
                                </div>

                                {amountField.errorMessage && <p className={'field-error-message'} data-cy="quantityInputErrorText">{amountField.errorMessage}</p>}
                                <p className={styles.metaDescription}>{t('pages-FundOverview.sellPercentageUnitsDescription')}</p>
                            </Fragment>
                        )}
                    </div>
                </Stack>
                <Stack spacing={2} className={styles.bottomDivider}>
                    <Typography variant={'h4'}>{t('components-ShoppingCart.platformFee')}</Typography>
                    <CurrencyPricingBar onClick={() => setPlatformFeeTableVisible(true)} condensed order={{type: 'SELL', amount: units.mul(shareClass.nav).toNumber()}}/>
                    <FxDialog open={platformFeeTableVisible} onClose={() => setPlatformFeeTableVisible(false)}>
                        <FxDialogTitle onClose={() => setPlatformFeeTableVisible(false)}>
                            <Typography variant={'h4'}>{t('components-ShoppingCart.platformFee')}</Typography>
                        </FxDialogTitle>
                        <FxDialogContent>
                            <Box py={2}>
                                <FundPlatformFeeTable order={{type: "SELL", amount: units.mul(shareClass.nav).toNumber()}}/>
                            </Box>
                        </FxDialogContent>
                    </FxDialog>
                </Stack>
            </Stack>
            <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                <Button variant={'outlined'} onClick={onCancel}>{t('common.cancel')}</Button>
                <Button variant={'contained'} data-cy="signAndSellButton" onClick={startCheckout} disabled={units.eq(0).valueOf() || !selectedSettlementAccountId}>{t('pages-FundOverview.toSellConfirmation')}</Button>
            </Stack>
        </Modal>
    );
}
