import {SILVER_GRAY} from '#app/colors/colors'
import config from '#app/config'
import {LoadingSpinner, PageHeader, PageLayout, Paper} from '#components'
import DocumentLink from '#components/DocumentLink'
import {SignableDocumentList} from '#components/SignableDocument/SignableDocumentList'
import Onboarding from '#pages/FundCustomers/FundCustomerDetail/Onboarding'
import PlatformFee from '#pages/FundCustomers/FundCustomerDetail/PlatformFee/PlatformFee'
import SetCid from '#pages/FundCustomers/FundCustomerDetail/SetCid'
import SetFundAccess from '#pages/FundCustomers/FundCustomerDetail/SetFundAccess'
import {fundCategoryTMap} from '#services/enumTranslationKeyMapping'
import {useSelector} from '#state/useSelector'
import {Box, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import SetMinOrderVolume from './SetMinOrderVolume'
import UploadFundContractAddendum from '#pages/FundCustomers/FundCustomerDetail/UploadFundContractAddendum'
import UploadCustomFundContract from '#pages/FundCustomers/FundCustomerDetail/UploadCustomFundContract'
import { formatOrganizationNumber } from '#app/services/formatnumber'

const API_BASE_URL = config().apiUrl
const FC_DOC_URL = config().fixrateFrontDocumentUrl + '/fixrate-capital-dokumenter'

type Params = {
    depositorId?: string
}

export default function FundCustomerDetail() {
    const {t} = useTranslation()
    const {depositorId} = useParams<Params>()
    const customer = useSelector(state => depositorId ? state.fundCustomers.find(c => c.depositorId === depositorId) : undefined)
    const documents = useSelector(state => state.documents)

    const fundContractDocumentId = customer?.contracts.filter(c => c.documentType === 'FUND_CONTRACT')?.[0]?.documentId
    const fundGeneralTermsDocumentId = customer?.contracts.filter(c => c.documentType === 'FUND_GENERAL_TERMS_AND_CONDITIONS')?.[0]?.documentId
    const fundContractAddendumDocumentId = customer?.contracts.filter(c => c.documentType === 'FUND_CONTRACT_ADDENDUM')?.[0]?.documentId
    const fundContract = documents[fundContractDocumentId]
    const fundGeneralTerms = documents[fundGeneralTermsDocumentId]
    const fundContractAddendum = documents[fundContractAddendumDocumentId]
    const contracts = [fundContract, fundGeneralTerms, fundContractAddendum].filter(Boolean)

    const lang = useSelector(state => state?.session?.language) ?? 'nb'

    const hasPlatformFeeSteps = Object.keys(fundCategoryTMap).every((fundCategory) => {
        return customer?.specialPlatformFeeHistory && Object.keys(customer.specialPlatformFeeHistory?.[fundCategory])?.length > 0
    })

    const fundContractAddendumPriceListUrl = `${API_BASE_URL}/document/depositor/${depositorId}/fund/platformfee?lang=${lang}`
    const fundInfoAboutFinancialInstrumentsUrl = `${FC_DOC_URL}/Informasjon+om+egenskaper+og+risiko+knyttet+til+finansielle+instrumenter.pdf`
    const fundGuidelinesForHandlingConflictsOfInterestUrl = `${FC_DOC_URL}/Retningslinjer+for+h%C3%A5ndtering+av+interessekonflikter.pdf`
    const fundGuidelinesForCustomerComplaintsUrl = `${FC_DOC_URL}/Retningslinjer+for+kundeklager.pdf`
    const fundStockTransferFormUrl = `${FC_DOC_URL}/Stock+Transfer+Form.docx`

    const docSx = {mt: 1}

    const customerNameWithOrg = customer?.name ? `${customer.name} (${formatOrganizationNumber(customer.nationalIdentity, "NO")})` : ''

    return customer ? (
        <>
            <PageHeader title={customerNameWithOrg} backToLink={'/fund-customers'}/>
            <PageLayout>
                <Paper title={t('pages.fundCustomers.customerDetail.setup')}>
                    <Stack spacing={2}>
                        <SetFundAccess customer={customer}/>
                        {customer.portfolios.map(portfolio => (
                            <Stack key={portfolio.id} spacing={1}>
                                <h2>
                                    <span>{t('pages.fundCustomers.customerDetail.portfolio', {portfolioName: portfolio.name})}</span>
                                </h2>
                                <SetCid customer={customer} portfolio={portfolio}/>
                                <SetMinOrderVolume customer={customer} portfolio={portfolio}/>
                            </Stack>
                        ))}
                    </Stack>
                </Paper>
                <Paper title={t('pages.fundCustomers.customerDetail.contracts')}>
                    <p>{contracts.length > 0 ? t('pages.fundCustomers.customerDetail.acceptTerms') : t('pages.fundCustomers.customerDetail.noContracts')}</p>
                    <SignableDocumentList
                        documents={contracts}
                        onStartSignature={() => { /* Empty by design */
                        }}
                    />
                    <Stack spacing={2} direction={'column'}>
                        <UploadCustomFundContract
                            depositorId={depositorId}
                            currentContract={contracts.find(c => c?.documentType === 'FUND_CONTRACT')}
                        />
                        <UploadFundContractAddendum
                            depositorId={depositorId}
                            currentContract={contracts.find(c => c?.documentType === 'FUND_CONTRACT_ADDENDUM')}
                        />
                    </Stack>
                    <Box sx={{mt: 3}}>
                        <h3>
                            <span>{t('pages-organizations.fundOtherDocuments')}</span>
                        </h3>
                    </Box>
                    <Box sx={docSx}>
                        <DocumentLink external link={fundInfoAboutFinancialInstrumentsUrl}
                                      name={t('pages-organizations.fundInfoAboutFinancialInstrumentsLink')}/>
                    </Box>
                    <Box sx={{docSx}}>
                        <DocumentLink external link={fundGuidelinesForHandlingConflictsOfInterestUrl}
                                      name={t('pages-organizations.fundGuidelinesForHandlingConflictsOfInterestLink')}/>
                    </Box>
                    <Box sx={{docSx}}>
                        <DocumentLink external link={fundGuidelinesForCustomerComplaintsUrl}
                                      name={t('pages-organizations.fundGuidelinesForCustomerComplaintsLink')}/>
                    </Box>
                    <Box sx={{docSx}}>
                        <DocumentLink external link={fundStockTransferFormUrl}
                                      name={t('pages-organizations.fundStockTransferFormLink')}/>
                    </Box>
                    <Box sx={{docSx}}>
                        {hasPlatformFeeSteps && (
                            <DocumentLink external link={fundContractAddendumPriceListUrl}
                                          name={t('pages-organizations.fundContractAddendumPriceListLink')}/>
                        )}
                        {!hasPlatformFeeSteps && (
                            <Typography variant={'body1'} sx={{
                                color: SILVER_GRAY[400],
                                fontWeight: 600
                            }}>{t('pages-organizations.noPlatformFeeDocument')}</Typography>
                        )}
                    </Box>
                </Paper>
                <Paper title={t('pages.fundCustomers.customerDetail.onboarding')}>
                    <Onboarding customer={customer}/>
                </Paper>
                <Paper title={t('pages.fundCustomers.customerDetail.platformFee.title')}>
                    <PlatformFee customer={customer}/>
                </Paper>
            </PageLayout>
        </>
    ) : (
        <>
            <PageHeader title={t('pagetitles.fund-customers')} backToLink={'/fund-customers'}/>
            <PageLayout>
                <LoadingSpinner/>
            </PageLayout>
        </>
    )
}
