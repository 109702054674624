import { Fragment } from "react";
import {useTranslation} from "#components/i18n"
import {InterestOutput} from '#components'
import {useSelector} from "#state/useSelector"
import {UserFilterDto} from '@fixrate/fixrate-query'

type Props = {
    customUserFilter: UserFilterDto
}

export default function CustomUserFilterDescription({customUserFilter}: Props) {

    const {t} = useTranslation('pages-marketplace')
    const basicNumberFormat = new Intl.NumberFormat('nb-NO')
    const allProducts = useSelector(state => state.products)
    const floatingProducts = Object.values(allProducts).filter(p => p.type === 'FLOATING').map(p => p.id)
    const fixedProducts = Object.values(allProducts).filter(p => p.type === 'FIXED').map(p => p.id)
    const floatingInterestEnabled = customUserFilter.products?.some(p => floatingProducts.includes(p)) ?? false
    const fixedInterestEnabled = customUserFilter.products?.some(p => fixedProducts.includes(p)) ?? false

    let filterDesc = ""

    if (floatingInterestEnabled) {
        filterDesc += t('floatingInterest') + ', '
    }

    if (fixedInterestEnabled) {
        filterDesc += t('fixedInterest') + ', '
    }

    if (customUserFilter.minDepositAmount > 0) {
        if (customUserFilter.minDepositAmount > 100) {
            filterDesc += t('depositAmount') + ' > 100 mill.'
        } else {
            filterDesc += t('depositAmount') + ' >= ' + customUserFilter.minDepositAmount + ' mill, '
        }
    }

    if (customUserFilter.maxDepositAmount !== null) {
        filterDesc += t('depositAmount') + ' <= ' + customUserFilter.maxDepositAmount + ' mill, '
    }

    if (customUserFilter.totalAssets > 0) {
        filterDesc += t('totalAssets') + ' > ' + basicNumberFormat.format(customUserFilter.totalAssets) + ' mill, '
    }

    if (customUserFilter.minInterestRate > 0) {
        filterDesc += InterestOutput.formatFixed(customUserFilter.minInterestRate) + ', '
    }

    if (customUserFilter.minInterestRateMargin > 0) {
        filterDesc += InterestOutput.formatMarginWithBenchmark(customUserFilter.minInterestRateMargin, 'NIBOR_3M', t) + ', '
    }

    if (filterDesc.endsWith(", ")) {
        filterDesc = filterDesc.substring(0, filterDesc.length - 2)
    }

    return <Fragment>{filterDesc}</Fragment>;
}
