import { Fragment } from 'react';
import {ButtonRow, DatePicker, LabeledInput} from '#components'
import Button from '#components/Button'
import styles from './Steps.module.css'
import {useTranslation} from 'react-i18next'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import { StepProps } from '../types';

export default function Step4({onPrevious, onNext, fields}: StepProps) {
    
    const {t} = useTranslation()

    function setValue(date) {
        if (!isNaN(Date.parse(date))) {
            date.setHours(16, 0, 0, 0)
        }
        fields.deadline.submitValue(date)
    }

    const firstValidDate = startOfDay(new Date())
    if (fields.deadline.value === null) {
        setValue(firstValidDate)
    }

    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep4Message')}</p>
            <div className={styles.inputSection}>
                <div>
                    <LabeledInput
                        label={t('pages-offer.deadline')}
                        className={styles.deadline}
                        helpText={t('pages-offer.wizardStep4DeadlineHelp')}
                        errorMessage={fields.deadline.errorMessage}
                    >
                        <div className={styles.date}>
                            <DatePicker
                                id="deadline"
                                selected={new Date(fields.deadline.value)}
                                onChange={(date) => setValue(date)}
                                filterDate={date => !isBefore(date, firstValidDate)}
                            />
                            <div>{t('pages-offer.wizardStep41600Hours')}</div>
                        </div>
                    </LabeledInput>
                </div>

            </div>
            <ButtonRow>
                <Button variant={'secondary'} onClick={onPrevious}>{t('common.previous')}</Button>
                <Button id="continueButton" onClick={onNext}>{t('common.continue')}</Button>
            </ButtonRow>
        </Fragment>
    );
}
