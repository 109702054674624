import useCurrentDepositor from '#services/useCurrentDepositor'
import {Stack} from '@mui/material'
import {EmailDistributionIntegration} from '#pages/organizations/OrganizationDetail/Integrations/EmailDistributionIntegration'
import {EscaliIntegration} from '#pages/organizations/OrganizationDetail/Integrations/EscaliIntegration'
import {SwimbirdIntegration} from '#pages/organizations/OrganizationDetail/Integrations/SwimbirdIntegration'
import {PandaConnectIntegration} from '#pages/organizations/OrganizationDetail/Integrations/PandaConnectIntegration'


export default function IntegrationOverview() {
    const depositor = useCurrentDepositor()
    return (
        <Stack spacing={1} flexDirection={'column'}>
            <EscaliIntegration depositor={depositor}/>
            <SwimbirdIntegration depositor={depositor}/>
            <PandaConnectIntegration depositor={depositor}/>
            <EmailDistributionIntegration depositor={depositor}/>
        </Stack>
    )
}
