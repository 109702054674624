import { useState } from 'react';
import {useSelector} from '#state/useSelector'
import {DateOutput, InterestOutput} from '#components'
import config from '#app/config'
import ActionBlock from '#components/ActionBlock/ActionBlock'
import HelpIcon from '#components/HelpIcon/HelpIcon'
import styles from './TerminateDepositAction.module.css'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import Button from '#components/Button'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import {TerminationState} from '#state/selectors'
import {TerminationModeConstant} from '#pages/portfolio-bank/DepositDetailsBank/DepositDetailsBank'
import {useCommand} from '#command'
import {DepositDto} from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

export default function ConditionalTerminationAction({deposit}: { deposit: DepositDto }) {

    const {t} = useTranslation()
    const [terminationMode, setTerminationMode] = useState('')

    const {confirmDepositTermination, confirmInterestRateChange} = useCommand()

    const terminationDocument = useSelector(state => state.documents[deposit.terminationDocumentId])

    async function submitTermination() {
        const {waitForCommand} = await confirmDepositTermination(deposit.id)
        await waitForCommand()
    }

    async function submitInterestRateChange() {
        const {waitForCommand} = await confirmInterestRateChange(deposit.id)
        await waitForCommand()
    }

    if (deposit.terminationState !== TerminationState.SENT_TO_BANK || deposit.terminationType !== TerminationModeConstant.CONDITIONAL) {
        return null
    }

    switch (terminationMode) {

        case 'INTEREST_RATE_CHANGE':
            return (
                <ActionBlock header={t('pages-portfolio-bank.conditionalInterestChangeHeader')}>
                    <HelpIcon className={styles.helpIcon} supportArticleId={'c36d29b2-c3a5-43ee-a0b3-8b36217d5f6d'}
                              helpContextPage="ConditionalTerminationAction"/>
                    <p>{t('pages-portfolio-bank.conditionalInfo1', {date: DateOutput.formatVerboseMonth(deposit?.terminationRequested)})}</p>
                    <p>{t('pages-portfolio-bank.conditionalInterestChangeInfo2')}</p>
                    <dl>
                        <dt>{t('pages-portfolio-bank.conditionalInterestChangeStep1')}</dt>
                        <dd>
                            <a id="terminationDocumentLink"
                               href={`${API_BASE_URL}${terminationDocument?.signedLink}`}>{t('pages-portfolio-bank.conditionalInterestChangeDocumentName')}</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('pages-portfolio-bank.conditionalInterestChangeStep2')}</dt>
                        <dd>
                            <div className={styles.infoList}>
                                <LabeledInfo label={t('pages-portfolio-bank.conditionalInterestMarginLabel')}
                                             info={InterestOutput.format(deposit.interestRateRequirement)}/>
                                <LabeledInfo label={t('pages-portfolio-bank.conditionalInterestDateLabel')}><DateOutput.VerboseMonth
                                    date={deposit?.interestRateRequirementDate}/></LabeledInfo>
                            </div>
                        </dd>
                    </dl>
                    <ButtonRow>
                        <Button variant={'secondary'} onClick={() => setTerminationMode('')}>
                            {t('common.cancel')}
                        </Button>
                        <Button data-cy="conditionalInterestChangeConfirmButton" onClick={submitInterestRateChange}>
                            {t('pages-portfolio-bank.conditionalInterestChangeButtonText')}
                        </Button>
                    </ButtonRow>
                </ActionBlock>
            )

        case 'TERMINATION':
            return (
                <ActionBlock header="Oppsigelse">
                    <HelpIcon className={styles.helpIcon} supportArticleId={'c36d29b2-c3a5-43ee-a0b3-8b36217d5f6d'}
                              helpContextPage="ConditionalTerminationAction"/>
                    <p>{t('pages-portfolio-bank.conditionalInfo1', {date: DateOutput.formatVerboseMonth(deposit?.terminationRequested)})}</p>
                    <p>{t('pages-portfolio-bank.conditionalTerminationInfo2')}</p>
                    <p>{t('pages-portfolio-bank.conditionalTerminationInfo3', {date: DateOutput.formatVerboseMonth(deposit?.terminationDate)})}</p>
                    <dl>
                        <dt>{t('pages-portfolio-bank.conditionalTerminationStep1')}</dt>
                        <dd>
                            <a id="terminationDocumentLink"
                               href={`${API_BASE_URL}${terminationDocument?.signedLink}`}>{t('pages-portfolio-bank.conditionalTerminationDocumentName')}</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('pages-portfolio-bank.conditionalTerminationStep2')}</dt>
                        {deposit.terminationDate && (
                            <dd>
                                <DateOutput.VerboseMonth date={deposit?.terminationDate}/>
                            </dd>
                        )}
                    </dl>
                    <ButtonRow>
                        <Button variant={'secondary'} onClick={() => setTerminationMode('')}>
                            {t('common.cancel')}
                        </Button>
                        <Button onClick={submitTermination}>
                            {t('pages-portfolio-bank.conditionalTerminationButtonText')}
                        </Button>
                    </ButtonRow>
                </ActionBlock>
            )

        default:
            return (
                <ActionBlock header={t('pages-portfolio-bank.conditionalHeader')}>
                    <HelpIcon className={styles.helpIcon} supportArticleId={'c36d29b2-c3a5-43ee-a0b3-8b36217d5f6d'}
                              helpContextPage="ConditionalTerminationAction"/>
                    <p>{t('pages-portfolio-bank.conditionalInfo1', {date: DateOutput.formatVerboseMonth(deposit?.terminationRequested)})}</p>
                    <p>{t('pages-portfolio-bank.conditionalInfo2', {date: DateOutput.formatVerboseMonth(deposit?.interestRateRequirementDate)})}</p>
                    <p>{t('pages-portfolio-bank.conditionalInfo3', {date: DateOutput.formatVerboseMonth(deposit?.terminationDate)})}</p>
                    <p>
                        <a href={`${API_BASE_URL}${terminationDocument?.signedLink}`}>{t('pages-portfolio-bank.conditionalTerminationNoticeName')}</a>
                    </p>

                    <ButtonRow>
                        <Button data-cy="conditionalGoToInterestChangeButton"
                                onClick={() => setTerminationMode('INTEREST_RATE_CHANGE')}>
                            {t('pages-portfolio-bank.conditionalGoToInterestChange')}
                        </Button>
                        <Button onClick={() => setTerminationMode('TERMINATION')}>
                            {t('pages-portfolio-bank.conditionalGoToTermination')}
                        </Button>
                    </ButtonRow>
                </ActionBlock>
            )
    }
}
