import { Fragment, useState } from 'react';
import MenuItem from './MenuItem'
import RoleBadgeList from './RoleBadgeList'
import isAfter from 'date-fns/isAfter'
import styles from './UsersList.module.scss'
import {useCommand} from '#command'
import If from '#components/If/If'
import {Button, DateOutput, LinkButton} from '#components'
import {supportedLanguages, Trans, useTranslation} from '#components/i18n'
import classNames from 'classnames'
import {UsersListParams} from '#blocks/UsersList/UsersList'
import ResendUserInvite from './ResendUserInvite'
import Modal from '#components/Modal'

type Props = {
    userInvite,
    userListParams: UsersListParams
}

export default function UserInvite({userInvite, userListParams}: Props) {
    const {t} = useTranslation('blocks-UsersList')

    const {
        isAdmin,
        organisation,
        missingRoles,
        basePath,
    } = userListParams

    const emphasizeRoleLink = missingRoles?.length > 0
    const [resendInviteVisible, setResendInviteVisible] = useState(false)
    const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)

    const expired = isAfter(new Date(), new Date(userInvite.expirationDate))
    const invalid = userInvite.invalid
    const inviteLink = window.location.href.split(window.location.pathname)[0] + '/invite/' + userInvite.id

    const {removeUserInvite} = useCommand()

    async function remove() {
        try {
            await removeUserInvite(organisation.id, userInvite.id)
        } catch (err) {
            console.error(err)
        }
    }

    function copyLink(userInvite) {
        const copyText = (document.getElementById('inviteLink' + userInvite.id)) as HTMLInputElement
        copyText.select()
        copyText.setSelectionRange(0, 99999) //For mobile devices
        document.execCommand('copy')
    }

    const resendButton = (isAdmin) && organisation.type !== 'DEPOSITOR'
        ? (
            <LinkButton className={styles.ctaLink} variant={'secondary'} id="resendInvitationMenuitem" to={`${basePath}/resend-invite/${userInvite.id}#!`}>
                <i className="ri-send-plane-2-line"/>
                <span>{t('menuItemResendInvite')}</span>
            </LinkButton>
        ) : (
            <Button className={styles.ctaLink} variant={'secondary'} id="resendInvitationMenuitem" onClick={() => setResendInviteVisible(true)}>
                <i className="ri-send-plane-2-line"/>
                <span>{t('menuItemResendInvite')}</span>
            </Button>
        )

    return (
        <div id={userInvite.id} data-cy="userRequestElement" className={classNames(styles.userslist__listElement, styles.userslist__sentInvitation)}>
            <div className={styles.userslist__listElementTop}>
                <div>
                    <h3 className={styles.userlist__name}>{userInvite.firstName + ' ' + userInvite.lastName}</h3>
                    <div className={styles.userlist__identity}>
                        <p className={styles.userlist__identityElement}>
                            <i className="ri-mail-send-line"/>
                            <span>
                                <Trans t={t} i18nKey={'inviteSentOn'}>
                                    Invite sent on <DateOutput.Date date={userInvite.requested}/>
                                </Trans>
                            </span>
                        </p>
                        {resendButton}
                    </div>
                    {expired && (
                        <p className={styles.userlist__invitationStatus}>
                            {t('inviteStatusExpired', {
                                date: DateOutput.formatDate(userInvite.requested),
                                expiryDate: DateOutput.formatDate(userInvite.expirationDate),
                            })}
                        </p>
                    )}
                    {!expired && !invalid && (
                        <p className={styles.userlist__invitationStatus}>
                            {t('inviteStatusActive', {
                                date: DateOutput.formatDate(userInvite.requested),
                                expiryDate: DateOutput.formatDate(userInvite.expirationDate),
                            })}
                        </p>
                    )}
                    {!expired && invalid && (
                        <p className={styles.userlist__invitationStatus}>
                            {t('inviteStatusInvalid')}
                        </p>
                    )}
                </div>
                <ul className={styles.actionMenu}>
                    <If condition={isAdmin}>
                        <Fragment>
                            {organisation.type !== "DEPOSITOR" && (
                                <MenuItem
                                    emphasized={emphasizeRoleLink}
                                    id="editRoleMenuItem"
                                    text={t('menuItemEditRole')}
                                    url={`${basePath}/edit-role/${userInvite.id}#!`}
                                    icon="ri-edit-line"
                                />
                            )}
                            <MenuItem
                                id="removeInvitationMenuitem"
                                onClick={() => setRemoveUserModalVisible(true)}
                                text={t('menuItemRemoveInvite')}
                                icon="ri-delete-bin-line"
                            />
                        </Fragment>
                    </If>
                </ul>
            </div>
            <div className={styles.invitationInfo}>
                <input
                    id={'inviteLink' + userInvite.id}
                    readOnly
                    style={{position: 'absolute', top: '-10000px', left: '-10000px'}}
                    type="text"
                    value={inviteLink}
                />
                <dl className={styles.infoElement}>
                    <dt className={styles.label}>{t('inviteEmail')}</dt>
                    <dd className={styles.value}>{userInvite.email}</dd>
                </dl>
                <dl className={styles.infoElement}>
                    <dt className={styles.label}>{t('inviteDateOfBirth')}</dt>
                    <dd className={styles.value}><DateOutput.Date date={userInvite.birthDate}/></dd>
                </dl>
                <dl className={styles.infoElement}>
                    <dt className={styles.label}>
                        {t('inviteLink')}
                    </dt>
                    <dd className={styles.value}>
                        {expired
                            ? t('expired')
                            : (
                                <span id="copyLink" className={styles.copyLink} onClick={() => copyLink(userInvite)}>{t('copyToClipboard')}</span>
                            )
                        }
                    </dd>
                </dl>
                <dl className={styles.infoElement}>
                    <dt className={styles.label}>{t('validUntil')}</dt>
                    <dd className={classNames(styles.value, expired && styles.userslist__listElementMenuItemLinkRed)}>
                        <DateOutput.Date date={userInvite.expirationDate}/>
                    </dd>
                </dl>
                <dl className={styles.infoElement}>
                    <dt className={styles.label}>{t('inviteLanguage')}</dt>
                    <dd className={styles.value}>
                        {(userInvite.language && supportedLanguages[userInvite.language]) || ''}
                    </dd>
                </dl>
            </div>

            <RoleBadgeList
                userListParams={userListParams}
                roles={userInvite.roles}
                userId={userInvite.id}
            />

            {resendInviteVisible && (
                <ResendUserInvite
                    organisationId={organisation.id}
                    organisationType={organisation.type}
                    inviteId={userInvite.id}
                    inviteEmail={userInvite.email}
                    close={() => setResendInviteVisible(false)}
                    returnTo={basePath}
                />
            )}
            {removeUserModalVisible && (
                <Modal onCancel={() => setRemoveUserModalVisible(false)}
                       width="40rem"
                       header={t('removeInviteHeading')}
                       onSubmit={() => remove()}
                       className={styles.confirmation}>
                    <div className={styles.innerWrapper}>
                        <p className={styles.body}>
                            {t('removeInviteConfirmation', {
                                userName: userInvite.firstName + ' ' + userInvite.lastName,
                                organizationName: organisation.name,
                            })}
                        </p>
                    </div>
                </Modal>
            )}
        </div>
    );
}

