import { useState } from 'react';
import styles from './InfoTabs.module.scss'
import {FundDto, FundShareClassDto} from '@fixrate/fixrate-query'
import FundPortfolio from './InfoSections/FundPortfolio'
import FundESG from './InfoSections/FundESG'
import FundTradingInfo from './InfoSections/FundTradingInfo'
import FundAbout from './InfoSections/FundAbout'
import {useTranslation} from 'react-i18next'
import FundDocuments from './InfoSections/FundDocuments'
import { Box, Tab, Tabs } from '@mui/material'
import FundReturns from './InfoSections/FundReturns'

export default function InfoTabs({fund, shareClass}: { fund: FundDto, shareClass: FundShareClassDto }) {
    const {t} = useTranslation()
    const [activeTab, setActiveTab] = useState('returns')

    if (fund == null || shareClass == null) {
        return null;
    }

    const handleTabChange = (tabKey: string) => {
        setActiveTab(tabKey);
    };

    const tabList = [
        {key: 'returns', label: t('pages-fund-details.returns'), content: <FundReturns fund={fund}/>},
        {key: 'portfolio', label: t('pages-fund-details.portfolio'), content: <FundPortfolio fund={fund}/>},
        {key: 'sustainability', label: t('pages-fund-details.sustainability'), content: <FundESG fund={fund}/>},
        {key: 'tradingInfo', label: t('pages-fund-details.tradingInfo'), content: <FundTradingInfo fund={fund} shareClass={shareClass}/>},
        {key: 'aboutFund', label: t('pages-fund-details.shortAboutFund'), content: <FundAbout fund={fund} shareClass={shareClass} onTabChange={handleTabChange}/>},
        {key: 'documents', label: t('pages-fund-details.documents'), content: <FundDocuments fund={fund} shareClass={shareClass}/>},
    ]

    return (
        <div className={styles.infoTabs}>
            <Tabs
                value={activeTab}
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                variant="scrollable"
                scrollButtons
                >
                {tabList.map(item => (
                    <Tab
                        key={item.key}
                        value={item.key}
                        label={item.label}
                        sx={{px: { md: 4 }}}
                        onClick={() => setActiveTab(item.key)} />
                ))}
            </Tabs>
            <Box sx={{minHeight: '35rem', py: 2}}>
                {tabList.find(item => item.key === activeTab)?.content}
            </Box>
        </div>
    )
}
