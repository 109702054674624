import { Fragment } from 'react';
import styles from './BankAds.module.scss'
import {useSelector} from '#state/useSelector'
import {DateOutput, InterestOutput} from '#components'
import {useTranslation} from 'react-i18next'
import {AdDto} from '@fixrate/fixrate-query'

type Props = {
    ad: AdDto
}

export function DeactivatedAdRow({ad}: Props) {
    const {t} = useTranslation()

    const product = useSelector(state => state.products[ad.productId])
    const knownUserFullNames = useSelector(state => state.bank?.knownUserFullNames ?? {})
    const createdBy = knownUserFullNames[ad.createdBy]
    const deactivatedBy = knownUserFullNames[ad.deactivatedBy]

    return (
        <div data-cy="bankAdRow" className={styles.adRow} data-specialoffer={!!ad.depositorName}>
            {ad?.depositorName && <div className={styles.specialOfferLabel}>{ad.depositorName}</div>}
            <div className={styles.volumeField}>
                <span className={styles.mobileHeader}>{t('pages-BankAds.volumeHeader')}</span>
                <span className={styles.adFieldValue}>{ad.volume - ad.remaining} / {t(`common.volume${ad.volume === 1 ? '' : '_other'}`, {count: ad.volume})}</span>
                <div className={styles.volumeIndicator}>
                    <div style={{width: '100%'}}/>
                    <div style={{width: `${(ad.volume - ad.remaining) / ad.volume * 100}%`}}/>
                </div>
            </div>
            <div className={styles.productField}>
                <span className={styles.mobileHeader}>{t('pages-BankAds.productHeader')}</span>
                <span className={styles.adFieldValue}>{t(`common.productLongName${ad.productId}`)}</span>
                <span className={styles.adFieldDescription}>
                    {!ad.fixedTermination && (
                        <Fragment>
                            {(product.termsType === 'NOTICE' || product.termsType === 'RECURRING_FIXED_TERMS') && t('pages-BankAds.notice', {duration: product.days})}
                            {product.termsType === 'FIXED_TERMS' && t('pages-BankAds.automaticTermination', {duration: product.months})}
                        </Fragment>
                    )}
                    {ad.fixedTermination && <Fragment>{t('pages-BankAds.fixedTermination')} <DateOutput.Date date={ad.termination}/></Fragment>}
                </span>
            </div>
            <div className={styles.interestTermsField}>
                <span className={styles.mobileHeader}>{t('pages-BankAds.conditionsHeader')}</span>
                <span className={styles.adFieldValue}>{InterestOutput.format(ad?.interest)}</span>
                <span className={styles.adFieldDescription}>
                    {product.type === 'FLOATING' && t('pages-BankAds.floatingInterest', {benchmark: t('common.interestRateBenchmark.' + ad.interestRateBenchmark), interest: InterestOutput.formatMargin(ad.nominalInterestRate)})}
                    {product.type === 'FIXED' && t('pages-BankAds.fixedInterest')}
                </span>
            </div>
            <div className={styles.depositLimitsField}>
                <span className={styles.mobileHeader}>{t('pages-BankAds.depositLimitsHeader')}</span>
                <span className={styles.adFieldValue}>{ad.min} - {t(`common.volume${ad.max === 1 ? '' : '_other'}`, {count: ad.max})}</span>
            </div>
            <div className={styles.publishedField}>
                <span className={styles.mobileHeader}>{t('pages-BankAds.publishedHeader')}</span>
                <span className={styles.adFieldValue}><DateOutput.DateTime date={ad.published}/></span>
                <span className={styles.adFieldDescription}>{createdBy}</span>
            </div>
            <div className={styles.deactivateField}>
                <span className={styles.mobileHeader}>{t('pages-BankAds.deactivationHeader')}</span>
                <span className={styles.adFieldValue}><DateOutput.DateTime date={ad.validity}/></span>
                <span className={styles.adFieldDescription}>{deactivatedBy || t('pages-BankAds.bySystem')}</span>
            </div>
        </div>
    );
}
