import { getBenchmarkInterest } from '#app/services/thunks/statistics';
import { useSelector } from '#app/state/useSelector';
import { StaggData } from '#state/stagg';
import { Chart } from 'chart.js';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const BenchmarkGraph = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<StaggData>>();
    const [data, setData] = useState<StaggData>([])
    const [error, setError] = useState(false)
    const currency = useSelector(state => state.session?.association?.currentCurrency)

    useEffect(() => {
        dispatch(getBenchmarkInterest(currency, 'range=6M'))
            .then(setData)
            .catch(() => setError(true))
    }, [currency, dispatch])

    useEffect(() => {
        const colors = [
            '#8AE3FF',
            '#FFA621',
            '#78D369'
        ]

        function formattedDatasets(data: StaggData) {
            return data.length === 0 ? [] : data[0]?.series.map((series, index) => ({
                label: series.tags.type?.replace('FOLIO', t('pages-BankDashboard.policyRate')),
                data: series.values?.map(value => value[1]),
                backgroundColor: colors[index] + '33',
                borderColor: colors[index],
                color: colors[index],
                pointRadius: 1,
                spanGaps: true,
                // fill: true,
            }))
        }

        if (chartRef.current) {
            chartRef.current.data.labels = data[0]?.series[0].values.map(value => value[0])
            chartRef.current.data.datasets = formattedDatasets(data)
            chartRef.current.update()
        }
    }, [data, t])

    const chartRef = useRef<Chart | null>(null)

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data[0]?.series[0].values.map(value => value[0]),
                    datasets: []
                },
                options: {
                    color: '#666',
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                font: {
                                    size: 8,
                                    family: "'Montserrat'",
                                    weight: '600'
                                },
                                color: "#666",
                                display: false,
                            },
                            grid: {
                                color: "rgba(0,0,0,0.05)"
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                font: {
                                    family: "'Montserrat'",
                                    weight: '600'
                                },
                                color: "#666"
                            },
                            grid: {
                                color: "rgba(0,0,0,0.05)"
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            align: "end",
                            labels: {
                                usePointStyle: true,
                                font: {
                                    size: 12,
                                    family: "'Montserrat'",
                                    weight: '600'
                                }
                            }
                        },
                        tooltip: {
                            displayColors: false
                        }
                    },
                    onResize: (chart, size) => {
                        if (size.width < 400) {
                            chart.options.scales.x.ticks["maxTicksLimit"] = 5;
                        } else {
                            chart.options.scales.x.ticks["maxTicksLimit"] = 20;
                        }
                    },
                },
            })
        }
    }
    return (
        <Fragment>
            {!error && <canvas ref={canvasCallback} width="600" height="160"/>}
            {error && <p style={{marginTop: 24}}><i className="ri-error-warning-line"/>{t('pages-BankDashboard.loadingFailed')}</p>}
        </Fragment>
    );
}
