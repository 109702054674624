import { useCommand } from '#command';
import { DateOutput } from '#components';
import ActionBlock from '#components/ActionBlock/ActionBlock';
import ButtonRow from '#components/ButtonRow/ButtonRow';
import { SignableDocument } from '#components/SignableDocument/SignableDocument';
import { startElectronicSignature } from '#services/thunks/documents';
import { showConfirmationModal } from '#state/reducers/confirmationModal';
import * as selectors from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { DepositDto } from '@fixrate/fixrate-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './AcceptExtensionOffer.module.css';
import {useSignableDocumentListInstruction} from '#components/SignableDocument/SignableDocumentList'
import {LoadingButton} from '@mui/lab'

export default function AcceptExtensionOffer({deposit}: { deposit: DepositDto }) {

    const {t} = useTranslation()
    const bank = useSelector(state => state.banks[deposit?.bankId])
    const document = useSelector(state => state.documents[deposit?.extensionOfferDocumentId])
    const isCheckingSignatureStatus = useSelector(state => selectors.lookupSignatureStatus(state)(deposit?.extensionOfferDocumentId))
    const {rejectExtensionOffer, acceptExtensionOffer} = useCommand()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showSigningError, setShowSigningError] = useState(false)
    const signatureInstructions = useSignableDocumentListInstruction([document])
    const canSign = useSelector(state => state.session.association.roles.includes('DEPOSITOR_ACCOUNT_HOLDER'))
    const canSubmit = useSelector(state => state.session.association.roles.includes('DEPOSITOR_ORDER'))
    const isSigned = document?.signedByAll
    const signedToday = document && document.lastSignatureTime && new Date().toDateString() === new Date(document.lastSignatureTime).toDateString()
    const acceptedToday = signedToday && deposit.extensionOfferState === 'ACCEPTED'

    if (!deposit || !document || !bank) return null
    if (!acceptedToday && deposit.extensionOfferState !== 'READY_FOR_SIGNING') return null

    async function onAccept() {
        if (document.signedByAll) {
            const {waitForCommand} = await acceptExtensionOffer(deposit.id, document.id)
            const success = await waitForCommand()
            if (success) {
                dispatch(showConfirmationModal({
                    title: t('pages-portfolio-depositor.extensionOfferAcceptedTitle'),
                    text: t('pages-portfolio-depositor.extensionOfferAcceptedText', {date: DateOutput.formatVerboseMonth(deposit?.terminationDate)}),
                    buttonText: t('common.continue')
                }))
            }
        } else {
            setShowSigningError(true)
        }
    }

    async function onReject() {
        const {waitForCommand} = await rejectExtensionOffer(deposit.id, document.id)
        const success = await waitForCommand()
        if (success) {
            dispatch(showConfirmationModal({
                title: t('pages-portfolio-depositor.extensionOfferRejectedTitle'),
                text: t('pages-portfolio-depositor.extensionOfferRejectedText'),
                buttonText: t('common.continue')
            }))
        }
    }

    function onStartSignature() {
        const processId = uuidv4()
        dispatch(startElectronicSignature(processId, 'SINGLE_DOCUMENT', document.id))
        navigate(`/signature/${processId}?context=/portfolio/id/${deposit.id}`)
    }

    if (acceptedToday) {
        return (
            <ActionBlock header={t('pages-portfolio-depositor.extensionOfferTitle')}>
                <p>{t('pages-portfolio-depositor.extensionOfferAcceptedTitle')}</p>
                <p>{t('pages-portfolio-depositor.extensionOfferAcceptedText', {date: DateOutput.formatVerboseMonth(deposit?.terminationDate)})}</p>

            </ActionBlock>
        )
    }

    return (
        <ActionBlock header={t('pages-portfolio-depositor.extensionOfferTitle')}>
            <p>{t('pages-portfolio-depositor.extensionOfferInfo1', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}</p>
            <p>{t('pages-portfolio-depositor.extensionOfferInfo2', {bankName: bank.name})}</p>
            <p>{t('pages-portfolio-depositor.extensionOfferInfo3', {
                deadline: DateOutput.formatVerboseMonth(deposit.extensionDeadline),
                date: DateOutput.formatVerboseMonth(deposit.terminationDate)
            })}</p>
            <p>{t('pages-portfolio-depositor.extensionOfferInfo4')}</p>

            <div className={styles.borderedSection}>
                <h4>{t('pages-portfolio-depositor.extensionOfferDocumentHeader')}</h4>
                <p>{signatureInstructions}</p>

                <SignableDocument isCheckingSignatureStatus={isCheckingSignatureStatus}
                                  linkText={t('pages-portfolio-depositor.extensionOfferDocumentLinkText')}
                                  document={document}
                                  onStartSignature={!canSubmit && onStartSignature}
                                  signingErrorText={t('pages-portfolio-depositor.extensionOfferDocumentSigningError')}
                                  showSigningError={showSigningError}
                />
            </div>
            <ButtonRow>
                <LoadingButton id="declineExtensionOfferButton" variant={'outlined'} onClick={onReject} disabled={!canSubmit}>{t('common.decline')}</LoadingButton>
                <LoadingButton id="acceptExtensionOfferButton" variant={'contained'} onClick={(canSign && !isSigned) ? onStartSignature : onAccept} disabled={!canSubmit}>{(canSubmit && !isSigned) ? t('pages-portfolio-depositor.extensionOfferSignAndSend') : t('pages-portfolio-depositor.extensionOfferSend')}</LoadingButton>
            </ButtonRow>

        </ActionBlock>
    )
}
