import { useState } from 'react';
import { PURPLE } from "#app/colors/colors"
import { Box, Select } from "@mui/material"

export default function ChipSelect({onChange, value, children} : { onChange: (event) => void, value: string, children: React.ReactNode}) {
    const [open, setOpen] = useState(false)
    return (
        <Select
            variant={'outlined'}
            color='primary'
            sx={{
                borderRadius: '10rem',
                mt: 0.5,
                mb: 0.5,
                maxWidth: '100%',
                '& .MuiSelect-select.MuiSelect-outlined': {
                    fontWeight: '600',
                    py: 0.6,
                    borderRadius: '10rem',
                    width: '100%',
                    backgroundColor: PURPLE[50],
                    color: PURPLE[800]
                },
            }}
            autoWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={() =>
                <Box
                    position={"absolute"}
                    sx={{
                        transition: "0.2s",
                        pointerEvents: "none",
                        transform: open ? "rotate(180deg)" : "rotate(0deg)"
                    }}
                    right={"1rem"}>
                    <i className='ri-arrow-down-s-line'/>
                </Box>
            }
            value={value}
            onChange={onChange}>
                {children}
        </Select>
    )
}
