import guestBjoernOddvarLandsem from "./guests/bjoern-oddvar-landsem.png"
import guestBrynjarEllingsen from "./guests/brynjar-ellingsen.png"
import guestHenrikBlymke from "./guests/henrik-blymke.png"
import guestKatheNyhus from "./guests/kathe-nyhus.png"
import guestKjerstiTTroebraaten from "./guests/kjersti-t-troebraaten.png"
import guestPaalSolberg from "./guests/paal-solberg.png"
import guestPhilipBraathen from "./guests/philip-braathen.png"
import guestRuneSotberg from "./guests/rune-sotberg.png"
import guestThomasWivestad from "./guests/thomas-wivestad.png"
import guestThorIvarTryggestad from "./guests/thor-ivar-tryggestad.png"

type EventTypes = "webinar"
type EventCategory = "Hvelvets kvaler live"
type CustomerSegment = "depositor" | "bank" | "partner"
type Guest = {
    name: string,
    title: string,
    image: string
}
export type CustomerEvent = {
    customerSegment: CustomerSegment,
    date: {
        from: Date,
        to: Date,
    },
    type: EventTypes,
    description: string,
    shortDescription: string,
    shortDescriptionPast?: string,
    categoryTitle: EventCategory,
    categoryEpisodeNo: number
    categoryDescription?: string,
    title: string,
    eventUrl: string,
    movieUrl?: string,
    guests?: Guest[],
    hosts?: Guest[]
}

export const hvelvetsKvalerDescription = "Hvelvets Kvaler Live er skreddersydd for dere som har ansvar for funding av banken, og er et svar på tilbakemeldinger der flere har bedt oss ta opp og drøfte dagsaktuelle temaer som naturlig faller inn under de tjenestene vi tilbyr."

export const customerEvents: CustomerEvent[] = [
    {
        title: "Markedskommentar i lys av seneste rentebeslutning og tips til å rigge seg på Markedsplassen under sommeren",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 7,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Nylig kuttet den europeiske sentralbanken renten og dagen før dette webinaret, 20.juni, offentliggjør Norges Bank sin seneste rentebeslutning og Pengepolitisk rapport 2/24. Vi har med oss Pål Solberg, senior porteføljeforvalter i Storebrand Kapitalforvaltning, til å si noen ord om hva han tror om utviklingen i rentemarkedet.  \n\n" +
            "Sommerferien skal avvikles og da bruker vi å få en del spørsmål fra bankene om hva de bør gjøre i forhold til Markedsplassen. Er det spesielle ting banken bør huske på og forberede, osv. Derfor tar vi en gjennomgang av tips og råd til hvordan man kan rigge seg best mulig før ferietiden setter inn.\n\n" +
            "Agenda for denne episoden:\n\n" +
            "•\t<b>En kjapp status fra Fixrate</b>, ved Bjørn Oddvar Landsem, Head of Sales and Customer Service i Fixrate AS (ca. kl. 08:45)\n\n" +
            "•\t<b>Markedskommentar i lys av seneste rentebeslutning</b>, ved Pål Solberg, senior porteføljeforvalter i Storebrand Kapitalforvaltning (ca. kl. 08:50)\n\n" +
            "•\t<b>Tips til å rigge seg på Markedsplassen under sommeren</b>, ved Bjørn Oddvar Landsem og Kathe Nyhus, KAM Bank i Fixrate AS (ca. kl. 09:00)",
        shortDescriptionPast: "I denne episoden gir Pål Solberg, senior porteføljeforvalter i Storebrand Kapitalforvaltning, sine kommentarer til den seneste utviklingen i rentemarkedet. I tillegg tar vi kort status for Fixrate og en gjennomgang av tips og råd til hvordan man kan rigge seg best mulig på Markedsplassen før ferietiden setter inn. ",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Pål Solberg",
                title: "Senior porteføljeforvalter, Storebrand Kapitalforvaltning",
                image: guestPaalSolberg
            }
        ],
        hosts: [
            {
                name: "Bjørn Oddvar Landsem",
                title: "Head of Sales and Customer Service i Fixrate AS",
                image: guestBjoernOddvarLandsem
            },
            {
                name: "Kathe Nyhus",
                title: "Key Account Manager Bank i Fixrate AS",
                image: guestKatheNyhus
            }
        ],
        date: {
            from: new Date("2024-06-21T08:45"),
            to: new Date("2024-06-21T09:15"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDJjNzhlOTUtMjA4ZC00ODEyLTkyMjctNWY0ZDEyOWM3ZGMx%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22c2efe755-f38e-442d-be43-a7cc3b762f9d%22%7d",
        movieUrl: "https://player.vimeo.com/video/964529152?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    },
    {
        title: "Ny funksjonalitet gjør det enklere å annonsere «rett pris» på Markedsplassen. Og, enkelt for banken å plassere og håndtere overskuddslikviditet på Fixrate sin rentefondsplattform.",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 6,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Fixrate lanserer ny funksjonalitet som vi tror vil gjøre det enklere å annonsere «rett pris» på Markedsplassen. Og, om banken skulle være full av penger, vel så har vi løsning for det også; vi viser hvordan banken enkelt selv kan plassere og forvalte overskuddslikviditet på Fixrate sin nye fondsplattform. \n\n" +
            "Agenda for denne episoden:\n\n" +
            "•\tMarkedsoppdatering med siste nytt fra kundene og markedsplassen ved Bjørn Oddvar Landsem, Support- og kundeansvarlig i Fixrate AS (ca. kl. 08.45)\n\n" +
            "•\tNy funksjonalitet i annonseringsverktøyet skal forhåpentligvis bidra til å gjøre det enklere å sette «rett pris» på annonsen. Bjørn Oddvar viser og demonstrerer (ca. kl. 08.55)\n\n" +
            "•\tBankens overskuddslikviditet enkelt håndtert på Fixrates fondsplattform. Philip Braathen, Key Account Manager i Fixrate AS, viser hvordan bankene kan dra nytte av Fixrate sin rentefondsplattform for enkel plassering og oppfølging av overskuddslikviditet plassert i rentefond fra velrenommerte norske og internasjonale forvaltere (ca. kl. 09:00)",
        shortDescriptionPast: "I denne episoden har i som vanlig med en markedsoppdatering med siste nytt fra kundene og markedsplassen, og vi viser ny funksjonalitet som vi tror vil gjøre det enklere å annonsere «rett pris» på Markedsplassen. Philip Braathen, Key Account Manager i Fixrate AS viser hvordan bankene kan dra nytte av Fixrate sin rentefondsplattform for enkel plassering og oppfølging av overskuddslikviditet plassert i rentefond fra velrenommerte norske og internasjonale forvaltere.",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Philip Braathen",
                title: "Key Account Manager i Fixrate AS",
                image: guestPhilipBraathen
            }
        ],
        hosts: [
            {
                name: "Bjørn Oddvar Landsem",
                title: "Support- og kundeansvarlig i Fixrate AS",
                image: guestBjoernOddvarLandsem
            }
        ],
        date: {
            from: new Date("2024-05-24T08:45"),
            to: new Date("2024-05-24T09:15"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjQ0ZDQwM2MtY2VmOC00M2VhLThmYWUtZjI5NjkyZTIwM2Ix%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22c2efe755-f38e-442d-be43-a7cc3b762f9d%22%7d",
        movieUrl: "https://player.vimeo.com/video/949955390?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    },
    {
        title: "Er rating viktig for bankene? Hvordan vil bankene bli påvirket av at VFF innskjerper krav til offisiell rating for rentefond?",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 5,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Vi har også denne gangen med oss gjester som vil diskutere temaer som vi tror mange av dere vil være interessert i å høre om: \n\nPål Solberg, Senior porteføljeforvalter i Storebrand Kapitalforvaltning, gir oss en oppdatering på rentemarkedet og forventningene fremover (fra ca. kl. 08:50-09.00) \n\nHenrik Blymke, Chief Commercial Officer i Nordic Credit Rating, vil presentere Nordic Credit Rating (fra ca. kl. 09.00-09.05) \n\nPål Solberg, Henrik Blymke og Bjørn Oddvar Landsem (Fixrate) tar en prat om rating-prosessen og hvilken betydning offisiell rating har for banker i seniormarkedet, for investorer og for innskytere (fra ca. kl. 09.05-09.30) \n\nFør Pål og Henrik slipper til, vil Bjørn Oddvar som vanlig gi en kort status for Fixrate (ca. kl. 08.47-08.50).",
        shortDescriptionPast: "I denne episoden har vi med Pål Solberg, Senior porteføljeforvalter i Storebrand Kapitalforvaltning, for å gi oss en oppdatering på rentemarkedet og forventningene fremover, og Henrik Blymke, Chief Commercial Officer i Nordic Credit Rating, som gir en presentasjon av Nordic Credit Rating. Sammen med Bjørn Oddvar Landsem fra Fixrate, diskuterer Pål og Henrik rating-prosessen og hvilken betydning offisiell rating har for banker i seniormarkedet, for investorer og for innskytere.",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Pål Solberg",
                title: "Senior porteføljeforvalter, Storebrand Kapitalforvaltning",
                image: guestPaalSolberg
            },
            {
                name: "Henrik Blymke",
                title: "Chief Commercial Officer i Nordic Credit Rating",
                image: guestHenrikBlymke
            }
        ],
        hosts: [
            {
                name: "Bjørn Oddvar Landsem",
                title: "Support- og kundeansvarlig i Fixrate AS",
                image: guestBjoernOddvarLandsem
            }
        ],
        date: {
            from: new Date("2024-04-26T08:45"),
            to: new Date("2024-04-26T09:30"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_Y2I0OTYwZjMtYjU4OS00ZTQ2LTkxODUtMDFhMjM0MWMxZWMz%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22c2efe755-f38e-442d-be43-a7cc3b762f9d%22%7d",
        movieUrl: "https://player.vimeo.com/video/939532137?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    },
    {
        title: "KYC og AHV i praksis – midlenes opprinnelse, RRH og PEP mm.",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 4,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Vi fortsetter der vi slapp i forrige webinar og legger opp til en praktisk tilnærming til temaene KYC og AHV, basert på hvordan en kunde onboardes i Fixrate og ikke minst erfaringer fra etablering av rammeverket og det praktiske arbeidet med anti-hvitvask og risk- og compliance-rutiner i vårt eget datterselskap Fixrate Capital AS. \n\nVi får med oss Risk & Compliance-ansvarlig i Fixrate Capital, Rune Sotberg, for å diskutere spørsmål vi har mottatt i forbindelse med seneste webinar. Rune har hatt en sentral rolle i utviklingen av rammeverket for risk, compliance og anti-hvitvask i forbindelse med konsesjonen som Fixrate Capital fikk fra Finanstilsynet i mars i fjor.",
        shortDescriptionPast: "Praktisk tilnærming til temaene KYC og AHV, basert på hvordan en kunde onboardes i Fixrate og ikke minst erfaringer fra etablering av rammeverket og det praktiske arbeidet med AHV og risk- og compliance-rutiner i vårt datterselskap Fixrate Capital AS. Fra Fixrate Capital har vi med oss Rune Sotberg, Risk & Compliance-ansvarlig. Sammen med Bjørn Oddvar Landsem går han gjennom spørsmål som vi har fått til kundekontroll og anti-hvitvask, samt konkrete eksempler på hvordan selskapet håndterer ulike problemstillinger knyttet til kundekontroll og dokumentasjonskrav, med hovedfokus på midlenes opprinnelse, RRH og PEP.",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Rune Sotberg",
                title: "Risk- og Compliance-ansvarlig i Fixrate Capital AS",
                image: guestRuneSotberg
            }
        ],
        hosts: [
            {
                name: "Bjørn Oddvar Landsem",
                title: "Support- og kundeansvarlig i Fixrate AS",
                image: guestBjoernOddvarLandsem
            }
        ],
        date: {
            from: new Date("2024-03-22T08:45"),
            to: new Date("2024-03-22T09:45"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YmZjNjM3NzYtZmJiZS00NDJmLWEzZmYtZWJiZTkwNDIwYTll%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22c2efe755-f38e-442d-be43-a7cc3b762f9d%22%7d",
        movieUrl: "https://player.vimeo.com/video/926254512?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    },
    {
        title: "Tilsynspraksis, stedlig tilsyn, kundekontroll og anti-hvitvask",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 3,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Vi starter dagen med en oppdatering på den seneste utviklingen i markedet og tar en titt i glasskula. Og om du sitter med spørsmål til tilsynspraksis, stedlig tilsyn, kundekontroll og anti-hvitvask – store eller små – nå har du sjansen til å få dem besvart av Norges fremste ekspert på området – helt gratis! Vi oppfordrer deg derfor sterkt til å sende dine spørsmål til kathe.nyhus@fixrate.no i forkant av webinaret, senest 20.februar.",
        shortDescriptionPast: "Kjersti T. Trøbråten, Advokatfirmaet Wiersholm, presenterer en gjennomgang av erfaringer og observasjoner fra stedlige tilsyn, og svarer på spørsmål bla. om kundekontroll og anti-hvitvask. Før Kjersti’s innlegg, starter Bjørn Oddvar Landsem med en kort oppdatering på Fixrate, og på hva vi ser i markedet per nå.",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Kjersti T. Trøbråten",
                title: "Partner i Advokatfirmaet Wiersholm AS",
                image: guestKjerstiTTroebraaten
            }
        ],
        hosts: [
            {
                name: "Bjørn Oddvar Landsem",
                title: "Support- og kundeansvarlig i Fixrate AS",
                image: guestBjoernOddvarLandsem
            }
        ],
        date: {
            from: new Date("2024-02-23T08:45"),
            to: new Date("2024-02-23T09:45"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MWZmNmYxNzMtZGQzOS00NWYwLTk1OTMtOTg4ZmY2NzJlNzcy%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22c2efe755-f38e-442d-be43-a7cc3b762f9d%22%7d",
        movieUrl: "https://player.vimeo.com/video/915943844?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    },
    {
        title: "Hva koster det egentlig å vokse organisk?",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 2,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Vi har også denne gangen med oss en gjest som har fingeren på pulsen på markedstrendene og forventet utvikling fremover. I denne andre episoden vil vi i tillegg se nærmere på et tema det er viktig å ta med i vurderingen når man skal ut og konkurrere om innskuddskronene: «Hva koster det egentlig å vokse organisk?» ",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Thor Ivar Tryggestad",
                title: "Partner og investeringsdirektør i Viden AS",
                image: guestThorIvarTryggestad
            },
            {
                name: "Brynjar Ellingsen",
                title: "Daglig leder i Fixrate AS",
                image: guestBrynjarEllingsen
            }
        ],
        date: {
            from: new Date("2024-01-26T08:45"),
            to: new Date("2024-01-26T09:15"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzNmZmJkOWItNTE3Ny00NmQ3LTk5MWQtYzE4NGE2MTFmNGY1%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22baf1fa14-37bd-4935-a17b-f6ffa8d0108f%22%7d",
        movieUrl: "https://player.vimeo.com/video/906714495?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    },
    {
        title: "Hva er prisen for penger?",
        categoryTitle: "Hvelvets kvaler live",
        categoryEpisodeNo: 1,
        categoryDescription: hvelvetsKvalerDescription,
        description: "Hvordan få mest mulig ut av Hvelvet",
        shortDescription: "Første episode vil gi deg verdifull innsikt i et av de mest presserende spørsmålene i dagens økonomiske landskap: «Hva er prisen for penger?». Vi har også med oss 2 gjester som skal kommentere markedsbildet og dele sine tanker og erfaringer.",
        customerSegment: "bank",
        type: "webinar",
        guests: [
            {
                name: "Pål Solberg",
                title: "Senior porteføljeforvalter, Storebrand Kapitalforvaltning",
                image: guestPaalSolberg
            },
            {
                name: "Thomas Wivestad",
                title: "Økonomisjef i Eika Kredittbank",
                image: guestThomasWivestad
            }
        ],
        date: {
            from: new Date("2023-12-15T08:45"),
            to: new Date("2023-12-15T09:15"),
        },
        eventUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MDVlMjcyMDYtMjQ4OC00YWVkLTlmOWEtZTM5MDFhZWIwNGI5%40thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22baf1fa14-37bd-4935-a17b-f6ffa8d0108f%22%7d",
        movieUrl: "https://player.vimeo.com/video/896494370?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no"
    }
]
