import { UsersListParams } from '#blocks/UsersList/UsersList'
import { useCommand } from '#command'
import { addSelfRemovingInfoMessage } from '#services/thunks/infoMessages'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

type Params = {
    userId?: string
}

export default function usePartnerUserListParams(): UsersListParams {
    const dispatch = useDispatch()
    const {userId} = useParams<Params>()

    const partner = useSelector(state => state.partner)

    const {removePartnerUser} = useCommand()

    const userInvites = useSelector(selectors.partnerUserInvites)
    const users = useSelector(selectors.partnerUsers)
    const user = users.find(user => user.id === userId)
    const roles = useSelector(selectors.lookupRolesForPartner)

    async function removeUser(providedUserId) {
        try {
            const userToRemove = providedUserId || userId
            const {waitForCommand} = await removePartnerUser(partner.id, userToRemove)
            const success = await waitForCommand()
            if (success) {
                dispatch(addSelfRemovingInfoMessage('info', 'Brukeren ble fjernet'))
            }
        } catch (err) {
            console.error(err)
        }
    }

    function isAdmin() {
        return roles.includes('PARTNER_ADMIN')
    }

    return {
        organisation: partner,
        users,
        user,
        userInvites,
        missingRoles: [],
        roles,
        isAdmin: isAdmin(),
        removeUser,
        basePath: '/partner/users',
    }
}


