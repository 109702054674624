import { Fragment } from 'react';
import MenuItem from './MenuItem'
import {useTranslation} from "react-i18next"

export default function UnknownMenu() {
    const {t} = useTranslation()

    return (
        <Fragment>
            <MenuItem
                id="marketPlaceMenuItem"
                link={'/marketplace'}
                name={t('menu.marketplace')}
                remixIcon="ri-store-2-line"
            />
            <MenuItem
                id="loginMenuItem"
                key="login"
                link={'/marketplace?login'}
                name={t('menu.logIn')}
                remixIcon="ri-account-circle-line"
            />
        </Fragment>
    );
}
