import React from 'react'
import {FormControl, FormControlLabel, FormGroup, Radio, RadioGroup} from '@mui/material'


interface Props {
    value: 'NO' | 'SE';
    onChange: (value: 'NO' | 'SE') => void;
}

export default function RegionalRegionSelector({value, onChange}: Props) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as 'NO' | 'SE')
    }

    return (
        <FormControl component="fieldset">
            <FormGroup row>
                <RadioGroup
                    aria-label="country"
                    name="country-selector"
                    value={value}
                    onChange={handleChange}
                    row
                >
                    <FormControlLabel
                        value="NO"
                        control={<Radio/>}
                        label="Norge"
                    />
                    <FormControlLabel
                        value="SE"
                        control={<Radio/>}
                        label="Sverige"
                    />
                </RadioGroup>
            </FormGroup>
        </FormControl>
    )
}
