import PartnerLogo from '#app/components/PartnerLogo/PartnerLogo'
import PhoneNumber from '#app/components/PhoneNumber'
import LoginPortalIframe from '#app/layers/Login/LoginPortalIframe'
import { useSessionBroadcast } from '#app/services/sessionBroadcast'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { isLoggedIn } from '#app/state/selectors'
import { useEndpoint } from '#command'
import { ButtonRow } from '#components'
import AsyncButton from '#components/Button/AsyncButton'
import EmptyState from '#components/EmptyState/EmptyState'
import OnboardingFrame from '#components/OnboardingFrame'
import { openChatBubble } from '#services/hubspot'
import { logout, syncSessionState } from '#services/thunks/session'
import {useFieldState} from '@fixrate/fieldstate'
import { validateEmailAddress } from '#services/validateFields'
import { useSelector } from '#state/useSelector'
import { UserInviteDto } from '@fixrate/fixrate-security'
import { Box, Button, Checkbox, FormControlLabel, Paper, Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import styles from './ProposalSignup.module.scss'

type Params = {
    partnerId?: string
    proposalId?: string
    inviteId?: string
}

export default function DepositorPartnerProposalSignup({ signingRedirect } : { signingRedirect?: boolean }) {
    const { t } = useTranslation()
    const session = useSelector(state => state.session)
    const organisationCountry = useCurrentCountryCode()
    const { trackEvent } = useTracking({ page: 'Signup', session })
    const loggedIn = useSelector(isLoggedIn)
    const navigate = useNavigate()
    const dispatch = useDispatch<(arg0: unknown) => Promise<unknown>>()
    const { getInvite, registerUser } = useEndpoint()
    const acceptedTermsOfUse = useFieldState(false, ({ value }) => !value && 'Du må bekrefte at du har lest personvernerklæringen')
    const [userInvite, setUserInvite] = useState<UserInviteDto>(null)
    const { partnerId, proposalId, inviteId } = useParams<Params>()
    const [triedSubmit, setTriedSubmit] = useState(false)
    const broadcast = useSessionBroadcast()

    const loggedInRedirectLink =  signingRedirect ? `/partner/signature/${partnerId}` : `/partner/proposals/${partnerId}/${proposalId}`

    const onLoginSuccess = () => {
        dispatch(syncSessionState())
        broadcast.sendLoginMessage()
    }

    function onLoginSuccessForce() {
        window.location.reload()
    }

    const validateEmail = useCallback(({ value, isEditing }) => {
        if (!value) {
            return t('layers-Signup.signupEmailMissing')
        }
        if (!isEditing && !validateEmailAddress(value)) {
            return t('layers-Signup.signupEmailInvalid')
        }
    }, [t])

    const email = useFieldState('', validateEmail)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [validPhoneNumber, setValidPhoneNumber] = useState(false)

    useEffect(() => {
        (async () => {
            if (inviteId) {
                try {
                    const data: UserInviteDto = await getInvite(inviteId)
                    setUserInvite(data)
                    email.fn.setValue(data.email)
                    if (data.status === 'ACCEPTED') {
                        console.log('User has already accepted this invite. Redirects to /marketplace')
                        navigate(loggedInRedirectLink)
                    } else if (data.status === 'NOT_FOR_SUBJECT') {
                        console.error('The invite was not generated for this subject')
                    }
                } catch (e) {
                    console.error('Error fetching invite', e)
                    setUserInvite(null)
                }
            }
        })()
    }, [email.fn, getInvite, inviteId, loggedInRedirectLink, navigate])

    function cancel() {
        dispatch(logout(navigate))
    }

    const onSubmitForm = async () => {
        const isValidAcceptedTermsOfUse = acceptedTermsOfUse.validate()
        setTriedSubmit(true)

        if (isValidAcceptedTermsOfUse && userInvite) {
            const response = await registerUser({
                email: email.value,
                phone: phoneNumber,
                acceptedTermsOfUse: acceptedTermsOfUse.value,
                userInviteId: inviteId,
            })

            if (response.emailError === 'ALREADY_REGISTERED') {
                email.setErrorMessage(t('layers-Signup.signupEmailAlreadyRegistered'))
                return
            }

            dispatch(syncSessionState())
            trackEvent({ event: 'userRegistered' })
            navigate(loggedInRedirectLink)
        }
    }

    if (userInvite?.status === 'NOT_FOR_SUBJECT') {
        trackEvent({ action: 'inviteNotForSubject' })
        return (
            <OnboardingFrame header={"Noe gikk dessverre galt"} onCancel={cancel}>
                <EmptyState
                    illustration={{ name: 'brokenLink', color: 'default' }}
                    variant='centered-on-background'
                    title={t('error.header')}
                    button={{
                        text: t('layers-Signup.continueToFixrate'),
                        onClick: () => document.location.assign('https://fixrate.no'),
                    }}
                    link={{
                        text: t('blocks-UsersList.talkToSupport'),
                        onClick: openChatBubble,
                    }}
                    description={{
                        dataCy: 'signupInviteNotForSubject',
                        text: t('layers-Signup.signupInviteNotForSubject'),
                    }}
                />
            </OnboardingFrame>
        )
    }

    return (
        <Stack overflow={"auto"} alignItems={"center"} justifyContent={"center"} bgcolor={"#F6F6F6"} left="0" top="0" width={"100%"} height={"100%"} position={"fixed"} zIndex={2}>
            <Stack alignItems="center" justifyContent="center" py={3} width="100%">
                <Stack alignItems={"center"} width="100%">
                    { !loggedIn && (
                        <Stack spacing={3} alignItems="center" width={"100%"}>
                            <Paper sx={{px: 3, pb: 3, pt: 0, width: "50rem", maxWidth: "100%", height: "40rem"}}>
                                <Stack alignItems={"center"} py={1}>
                                    <Box width="15rem">
                                        <PartnerLogo partnerId={partnerId} />
                                    </Box>
                                </Stack>
                                { session.authenticationLevel === "IDENTIFIED" ? (
                                    <>
                                        <div className={styles.textInput}>
                                            <label htmlFor='signup__input-phone'>{t('layers-Signup.signupPhone')}</label>
                                            <PhoneNumber
                                                initialCountry={organisationCountry}
                                                setValid={valid => setValidPhoneNumber(valid)}
                                                setNumber={number => setPhoneNumber(number)} />
                                            {validPhoneNumber === false && phoneNumber && <p className={styles.fieldErrorMessage}>{t('layers-Signup.signupPhoneInvalid')}</p>}
                                        </div>
                                        <Stack direction={'row'} alignItems='center'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        id='signup__input-privacy'
                                                        data-cy='acceptedTermsOfUse'
                                                        onChange={e => acceptedTermsOfUse.setValue(e.target.checked)}
                                                        checked={acceptedTermsOfUse.value}
                                                    />
                                                }
                                                label={
                                                    <Trans t={t} i18nKey={'layers-Signup.signupPrivacyPolicy'}>
                                                        Jeg har lest og forstått{' '}
                                                        <a href='https://fixrate.no/privacypolicy/' target='_blank' rel='noreferrer'>
                                                            personvernerklæringen
                                                        </a>
                                                    </Trans>
                                                }
                                            />
                                        </Stack>
                                        {triedSubmit && acceptedTermsOfUse.errorMessage && <p className={styles.fieldErrorMessage}>{acceptedTermsOfUse.errorMessage}</p>}
                                        <ButtonRow>
                                            <Button variant={'outlined'} data-cy='signupCancelButton' onClick={cancel}>
                                                {t('common.cancel')}
                                            </Button>
                                            <AsyncButton data-cy='signupContinueButton' variant={'contained'} onClick={onSubmitForm}>
                                                {t('common.continue')}
                                            </AsyncButton>
                                        </ButtonRow>
                                    </>
                                ) : (
                                    <LoginPortalIframe
                                        loginUrl={'/auth/portal'}
                                        onLoginSuccess={onLoginSuccess}
                                        onLoginSuccessForce={onLoginSuccessForce}
                                        onLoginAbort={() => console.log("failed")} />
                                )
                            }
                            </Paper>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}
