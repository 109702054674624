import { useState } from 'react';
import * as React from 'react';
import styles from './OrderProcess.module.scss'
import {DepositorDto, OrganisationalRole, SecurityRole} from '@fixrate/fixrate-query'
import BadgeList from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/BadgeList'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {HelpIcon} from "#components";
import {Stack, Typography} from "@mui/material";

interface Props {
    depositor: DepositorDto
}

export default function OrderProcess({depositor}: Props) {
    const {t} = useTranslation()

    // Only a single AddUserButton can be open at the same time in this context
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const closeOpenRoleOnClickOutside = () => {
        if(selectedOpenRole != null) {
            setSelectedOpenRole(null)
        }
    }

    const viewOnlyUsersAndInvites = depositor.people.filter(u => u.securityRoles.every(role => role === 'DEPOSITOR_VIEW' || role === 'DEPOSITOR_SIGN_AUTHORIZATION'))

    const nonSelectableRoles = ['DEPOSITOR_PARTNER_SUPPORT']

    const selectablePeople = depositor.people.filter(person => person.securityRoles.every(role => !nonSelectableRoles.includes(role)))

    return (
        <Stack spacing={4} onClick={closeOpenRoleOnClickOutside}>
            <Stack>
                <Stack direction={'row'} spacing={1} paddingBottom={1}>
                    <Typography variant='h2'>
                        <i className='ri-hand-coin-line' />
                        <span>{t('pages-organizations.orderProcess')}</span>
                    </Typography>
                </Stack>
                <p>
                    {t('pages-organizations.orderProcessDescription')} <HelpIcon supportArticleId={'1fbfbc92-3c54-4815-b25a-27470889ada6'} inline />
                </p>
                <ul className={styles.orderProcess}>
                    <Step title={t('pages-organizations.orderStepOrderTitle')} icon='ri-shopping-cart-line'>
                        <BadgeList
                            securityRole={'DEPOSITOR_ORDER'}
                            partnerAccountantHasAccess={true}
                            selectedOpenRole={selectedOpenRole}
                            setSelectedOpenRole={setSelectedOpenRole}
                            depositor={depositor}
                            people={selectablePeople}
                            missingRoleFeedback={t('pages-organizations.orderStepOrderMissingFeedback')}
                            onlyInvitesCoverRoleFeedback={t('pages-organizations.waitingInviteFeedback')}
                        />
                    </Step>
                    <Step title={t('pages-organizations.orderStepSignTitle')} icon='ri-quill-pen-line'>
                        <BadgeList
                            securityRole={'DEPOSITOR_ACCOUNT_HOLDER'}
                            selectedOpenRole={selectedOpenRole}
                            setSelectedOpenRole={setSelectedOpenRole}
                            depositor={depositor}
                            people={selectablePeople}
                            missingRoleFeedback={t('pages-organizations.orderStepSignMissingFeedback')}
                            onlyInvitesCoverRoleFeedback={t('pages-organizations.waitingInviteFeedback')}
                        />
                    </Step>
                    <Step title={t('pages-organizations.orderStepMakePaymentTitle')} icon='ri-coin-line'>
                        <BadgeList
                            securityRole={'DEPOSITOR_PAYMENT'}
                            partnerAccountantHasAccess={true}
                            selectedOpenRole={selectedOpenRole}
                            setSelectedOpenRole={setSelectedOpenRole}
                            depositor={depositor}
                            people={selectablePeople}
                            missingRoleFeedback={t('pages-organizations.orderStepMakePaymentMissingFeedback')}
                            onlyInvitesCoverRoleFeedback={t('pages-organizations.waitingInviteFeedback')}
                        />
                    </Step>
                </ul>
            </Stack>
            <Stack>
                <Stack direction={'row'} spacing={1} paddingBottom={1}>
                    <Typography variant='h2'>
                        <i className='ri-admin-line' />
                        <span>{t('pages-organizations.roleAdministratorTitle')}</span>
                    </Typography>
                </Stack>
                <p>
                    {t('pages-organizations.roleAdministratorDescription')} <HelpIcon supportArticleId={'1fbfbc92-3c54-4815-b25a-27470889ada6'} inline />
                </p>
                <BadgeList selectedOpenRole={selectedOpenRole} setSelectedOpenRole={setSelectedOpenRole} depositor={depositor} people={selectablePeople} securityRole={'DEPOSITOR_ADMIN'} row />
            </Stack>
            <Stack>
                <Stack direction={'row'} spacing={1} paddingBottom={1}>
                    <Typography variant='h2'>
                        <i className='ri-search-eye-line' />
                        <span>{t('pages-organizations.roleReadOnlyTitle')}</span>
                    </Typography>
                </Stack>
                <p>
                    {t('pages-organizations.roleReadOnlyDescription')} <HelpIcon supportArticleId={'1fbfbc92-3c54-4815-b25a-27470889ada6'} inline />
                </p>
                <BadgeList selectedOpenRole={selectedOpenRole} setSelectedOpenRole={setSelectedOpenRole} depositor={depositor} people={viewOnlyUsersAndInvites} securityRole='DEPOSITOR_VIEW' row />
            </Stack>
            <Stack>
                <Stack direction={'row'} spacing={1} paddingBottom={1}>
                    <Typography variant='h2'>
                        <i className='ri-file-chart-line' />
                        <span>{t('pages-organizations.roleAccountantTitle')}</span>
                    </Typography>
                </Stack>
                <p>
                    {t('pages-organizations.roleAccountantDescription')} <HelpIcon supportArticleId={'1fbfbc92-3c54-4815-b25a-27470889ada6'} inline />
                </p>
                <BadgeList selectedOpenRole={selectedOpenRole} setSelectedOpenRole={setSelectedOpenRole} depositor={depositor} people={selectablePeople} securityRole='DEPOSITOR_ACCOUNTANT' row />
            </Stack>
        </Stack>
    )
}

interface StepProps {
    title: string,
    icon: string,
    children: React.ReactNode,
}

const Step = ({title, icon, children}: StepProps) => (
    <li className={styles.step}>
        <div className={styles.stepHeader}>
            <h3 className={styles.title}>
                <i className={icon}/>
                <span>{title}</span>
            </h3>
            <i className={classNames(styles.nextStepIcon, "ri-arrow-right-s-line")}/>
        </div>
        {children}
    </li>
)
