import restEndpoint from '../rest/rest-endpoint'
import {StaggData} from '#state/stagg'
import { Currency } from '@fixrate/fixrate-query'
import { getCountryCodeFromCurrency } from '../currencyBenchmarkUtils'


export const getLastOrders = () => dispatch => {
    return restEndpoint(dispatch).getLastOrders()
}

export const getLastFullySubscribedAds = () => dispatch => {
    return restEndpoint(dispatch).getLastFullySubscribedAds()
}

export const getLastOrdersByCategory = () => dispatch => {
    return restEndpoint(dispatch).getLastOrdersByCategory()
}

export const getAllOrders = () => dispatch => {
    return restEndpoint(dispatch).getAllOrders()
}

export const getBusinessVolumeForBank = (bankId) => dispatch => {
    return restEndpoint(dispatch).getBusinessVolumeForBank(bankId)
}

export const getBusinessVolume = () => dispatch => {
    return restEndpoint(dispatch).getBusinessVolume()
}

export const getDepositorPortfolioByBank = (depositorId) => dispatch => {
    return restEndpoint(dispatch).getDepositorPortfolioByBank(depositorId)
}

export const getDepositorPortfolioByBankType = (depositorId) => dispatch => {
    return restEndpoint(dispatch).getDepositorPortfolioByBankType(depositorId)
}

export const getDepositorPortfolioByBankCounty = (depositorId) => dispatch => {
    return restEndpoint(dispatch).getDepositorPortfolioByBankCounty(depositorId)
}

export const getCurrentVolumeByBankForDepositor = (depositorId, productId, bankSize, sort) => dispatch => {
    return restEndpoint(dispatch).getCurrentVolumeByBankForDepositor(depositorId, productId, bankSize, sort)
}

export const getMeanDepositVolumeByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getMeanDepositVolumeByBankSegment(range)
}

export const getDepositVolumeByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositVolumeByBankSegment(range)
}

export const getDepositInterestForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestForBank(bankId, range)
}

export const getDepositInterestByProductForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestByProductForBank(bankId, range)
}

export const getDepositInterestForDepositor = (depositorId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestForDepositor(depositorId, range)
}

export const getDepositInterestByProductForDepositor = (depositorId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestByProductForDepositor(depositorId, range)
}

export const getDepositInterestByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestByBankSegment(range)
}

export const getDepositInterestByBankSegmentWithSsb = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestByBankSegmentWithSsb(range)
}

export const getDepositInterestWithSsbAndMajorBanks = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestWithSsbAndMajorBanks(range)
}

export const getDepositInterestForDepositorWithSsb = (depositorId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestForDepositorWithSsb(depositorId, range)
}

export const getEarningsForDepositor = (depositorId, range) => dispatch => {
    return restEndpoint(dispatch).getEarningsForDepositor(depositorId, range)
}

export const getDepositInterestByProduct = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositInterestByProduct(range)
}

export const getDepositMarginForBank = (bankId, range = null) => dispatch => {
    return restEndpoint(dispatch).getDepositMarginForBank(bankId, range) as Promise<StaggData>
}

export const getDepositMarginByProductForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositMarginByProductForBank(bankId, range)
}

export const getDepositMarginByProduct = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositMarginByProduct(range)
}

export const getDepositMarginByProductForDepositor = (depositorId, range) => dispatch => {
    return restEndpoint(dispatch).getDepositMarginByProductForDepositor(depositorId, range)
}

export const getDepositMarginByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositMarginByBankSegment(range)
}

export const getDepositDuration = (range) => dispatch => {
    return restEndpoint(dispatch).getDepositDuration(range)
}

export const getAdInterestByProduct = (range) => dispatch => {
    return restEndpoint(dispatch).getAdInterestByProduct(range)
}

export const getAdMarginByProduct = (range) => dispatch => {
    return restEndpoint(dispatch).getAdMarginByProduct(range)
}

export const getAdInterestByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getAdInterestByBankSegment(range)
}

export const getAdMarginByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getAdMarginByBankSegment(range)
}

export const getAdInterestForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getAdInterestForBank(bankId, range)
}

export const getAdMarginForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getAdMarginForBank(bankId, range)
}

export const getNibor = (range) => dispatch => {
    return restEndpoint(dispatch).getNibor(range)
}

export const getNiborAll = (range) => dispatch => {
    return restEndpoint(dispatch).getNiborAll(range)
}
export const getBenchmarkInterest = (currency: Currency, range?: string) => dispatch => {
    return restEndpoint(dispatch).getBenchmarkInterest(getCountryCodeFromCurrency(currency), range)
}

export const getDepth = (range) => dispatch => {
    return restEndpoint(dispatch).getDepth(range)
}

export const getDepthByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getDepthByBankSegment(range)
}

export const getDepthByProduct = (range) => dispatch => {
    return restEndpoint(dispatch).getDepthByProduct(range)
}

export const getDepthByInterestForNiborProducts = () => dispatch => {
    return restEndpoint(dispatch).getDepthByInterestForNiborProducts()
}

export const getDepthByInterestForFixedProducts = () => dispatch => {
    return restEndpoint(dispatch).getDepthByInterestForFixedProducts()
}

export const getDepthForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getDepthForBank(bankId, range)
}

export const getMeanDepthByBankSegment = (range) => dispatch => {
    return restEndpoint(dispatch).getMeanDepthByBankSegment(range)
}

export const getTurnover = (range) => dispatch => {
    return restEndpoint(dispatch).getTurnover(range)
}

export const getTurnoverByProduct = (range) => dispatch => {
    return restEndpoint(dispatch).getTurnoverByProduct(range)
}

export const getTurnoverForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getTurnoverForBank(bankId, range)
}

export const getTurnoverByProductForBank = (bankId, range) => dispatch => {
    return restEndpoint(dispatch).getTurnoverByProductForBank(bankId, range)
}

export const getVolumePerSegment = (range, interestRangeSize) => dispatch => {
    return restEndpoint(dispatch).getVolumePerSegment(range, interestRangeSize) as Promise<StaggData>
}
