import { Fragment } from 'react';
import {useSelector} from '#state/useSelector'
import {Animated, PageHeader, PageLayout} from '#components'
import {BuyOrderCard, SellOrderCard} from '../FundMarketplace/OrderCard/OrderCard'
import useSearchParams from '#services/useSearchParams'
import {Button, CircularProgress} from '@mui/material'
import {useTranslation} from 'react-i18next'

type Params = {
    documentId?: string
}

type Props = {
    type: 'BUY' | 'SELL'
}

export default function FundCheckoutConfirmation({type}: Props) {
    const {t} = useTranslation()
    const {documentId} = useSearchParams<Params>()

    const orders: {documentId: string, orderGroupId: string}[] = useSelector(state => {
        switch (type) {
            case 'BUY':
                return state.fundBuyOrders
            case 'SELL':
                return state.fundSellOrders
            default: {
                return []
            }
        }
    })

    const orderGroupId = orders.find(item => item.documentId === documentId)?.orderGroupId

    return (
        <Fragment>
            <PageHeader title={t('pagetitles.checkout')}/>
            {!orderGroupId && (
                <PageLayout>
                    <CircularProgress/>
                </PageLayout>
            )}
            {orderGroupId && (
                <PageLayout>
                    <Animated>
                        {type === 'BUY' && <BuyOrderCard orderGroupId={orderGroupId}/>}
                        {type === 'SELL' && <SellOrderCard orderGroupId={orderGroupId}/>}
                    </Animated>
                    <Button
                        data-cy="goToPortfolioLink"
                        href="/portfolio/overview/funds"
                        variant={'text'}
                    >
                        {t('components-OrderCard.goToPortfolio')}
                    </Button>
                </PageLayout>
            )}
        </Fragment>
    );
}

