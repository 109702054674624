
export function NotionBreadcrumb() {

    /*
    const breadcrumb = supportArticle?.breadcrumb?.length > 1 && supportArticle.breadcrumb.map((item, index) => (
        <a key={index}
           className={supportArticle.breadcrumb.length === (index + 1) ? styles.inactive : ''}
           onClick={supportArticle.breadcrumb.length === (index + 1) ? null : () => onLoad(item.id)}
        >
            {index !== 0 ? <i className="ri-arrow-right-s-line"/> : null} <span>{item.title}</span>
        </a>
    ))
     */

    return (
        null
    )

}