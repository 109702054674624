import * as React from 'react';
import DocumentIcon from '../icons/DocumentIcon'
import './DocumentLink.css'
import { Stack } from '@mui/material'

type DocumentLinkProps = {
    id?: string
    link: string
    name: string
    hideIcon?: boolean
    icon?: React.ReactNode
}

const DocumentLink = ({ id, link, name, hideIcon, icon = <DocumentIcon/> }: DocumentLinkProps) => (
    <Stack direction="row" className="documentlink">
        { !hideIcon && icon }
        <a {...(id ? {id: id} : {})} href={link} target="_blank" rel="noopener noreferrer">
            {name}
        </a>
    </Stack>
)

export default DocumentLink