import config from '#app/config'
import * as selectors from '#state/selectors'
import ActionBlock from '#components/ActionBlock/ActionBlock'
import Button from '#components/Button'
import LegacyCheckbox from '#components/LegacyCheckbox/LegacyCheckbox'
import {useFieldState} from '@fixrate/fieldstate'
import {useCommand} from '#command'
import {useSelector} from '#state/useSelector'
import {DepositDto} from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

export default function ConfirmSettlementAccount({deposit}: { deposit: DepositDto }) {

    const {t} = useTranslation()

    const {confirmSettlementAccountChanged} = useCommand()
    const settlementAccountChangedDocument = useSelector(state => selectors.documentIdToDocumentMapper(state)(deposit.documents).find(doc => doc.documentType === 'SETTLEMENT_ACCOUNT_CHANGED'))

    const confirmation = useFieldState(false, ({value}) => {
        if (!value) {
            return t('pages-portfolio-bank.settlementAccountChangeMustBeConfirmed')
        }
    })

    async function confirmChange() {
        if (confirmation.validate()) {
            const {waitForCommand} = await confirmSettlementAccountChanged(deposit.id, deposit.settlementAccountId)
            await waitForCommand()
        }
    }

    if (!deposit) return null

    if (deposit.changedSettlementAccountStatus !== 'DOCUMENT_SENT_TO_BANK') {
        return null
    }

    return (
        <ActionBlock header={t('pages-portfolio-bank.settlementAccountChangeHeader')}>
            <dl>
                <dt>
                    <a href={API_BASE_URL + settlementAccountChangedDocument.link} target="_blank" rel="noreferrer">
                        {settlementAccountChangedDocument.name}
                    </a>
                </dt>
                <dd>
                    <LegacyCheckbox
                        id="confirmNewSettlementAccountRegisteredCheckbox"
                        value={confirmation.value}
                        onChange={e => confirmation.setValue(e.target.checked)}
                        label={t('pages-portfolio-bank.settlementAccountChangeConfirmation')}
                    />
                    <p className="field-error-message">{confirmation.errorMessage}</p>
                </dd>
            </dl>
            <Button id="confirmNewSettlementAccountRegisteredButton"
                    onClick={confirmChange}>{t('pages-portfolio-bank.settlementAccountChangeButtonText')}</Button>
        </ActionBlock>
    )
}
