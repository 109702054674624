import {ReactNode} from 'react'
import * as Sentry from '@sentry/react'
import { PageHeader, PageLayout, Paper } from '#components'
import { useTranslation } from 'react-i18next'

export function ErrorFallback() {
    const { t } = useTranslation()

    return (
        <div>
            <PageHeader title={t('components-ErrorBoundary.heading')} />
            <PageLayout>
                <Paper title={t('components-ErrorBoundary.subHeading')}>
                    <p>{t('components-ErrorBoundary.msgPart1')}</p>
                    <p>{t('components-ErrorBoundary.msgPart2')}</p>
                    <a href="/">{t('components-ErrorBoundary.retry')}</a>
                </Paper>
            </PageLayout>
        </div>
    )
}



type Props = {
    children: ReactNode
}

export function ErrorBoundary ({ children } : Props) {
    return (
        <Sentry.ErrorBoundary fallback={<ErrorFallback/>}>
            {children}
        </Sentry.ErrorBoundary>
    )
}

