import { useState } from 'react';
import styles from './DocumentList.module.scss'
import DateOutput from '#components/DateOutput'
import config from '../../config'
import classNames from 'classnames'
import {DocumentDto} from '@fixrate/fixrate-query'
import {sortDatesAscending, sortDatesDescending} from '#services/dateandtime'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

type Params = {
    documents: Array<DocumentDto>,
    sortDirection?: 'asc' | 'desc',
    maxInitialLength?: number,
    smallText?: boolean,
    nameFn?: (DocumentDto) => string,
    sortFn?: (a: DocumentDto, b: DocumentDto) => number
}

export default function DocumentList({
                                         documents,
                                         sortDirection = 'asc',
                                         maxInitialLength = 0,
                                         smallText = false,
                                         nameFn = (document) => document.name,
                                         sortFn = (a, b) => (a.created && b.created) ? sortDatesAscending(a.created, b.created) : 0
                                     }: Params) {

    const {t} = useTranslation()

    const [showAll, setShowAll] = useState(false)

    const sortAscending = (sortDirection === 'asc')

    const customerDeclarationDocuments = documents.filter(document => document?.documentType === 'CUSTOMER_DECLARATION')

    let list = documents && documents
        .filter(document => !!document)
        .filter(document => document.documentType !== 'CUSTOMER_DECLARATION')
        .slice().sort((a, b) => sortAscending ? sortFn(a, b) : -sortFn(a, b))
        .map(document => (
            <tr className={styles.rows} key={document.id}>
                <td className={styles.name}>
                    <a
                        href={API_BASE_URL + document.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className={classNames('ri-file-line', styles.icon)}/>
                        <span>{nameFn(document)}</span>
                    </a>
                </td>
                <td className={styles.date}><DateOutput.VerboseMonth date={new Date(document.created)}/></td>
            </tr>
        ))

    const customerDeclarationList = customerDeclarationDocuments && customerDeclarationDocuments
        .filter(document => !!document)
        .sort( (a, b) => (a.created && b.created) ? sortDatesDescending(a.created, b.created) : 0)
        .map((document, index) =>  {
            const firstItem = (index === 0);
            const hasMultipleDeclarations = customerDeclarationDocuments.length > 1;
            return (
                <tr className={classNames(firstItem ? styles.latestCustomerDeclaration : styles.customerDeclarationSubItem, hasMultipleDeclarations ? styles.multipleVersions : '', styles.rows)} key={document.id}>
                    <td className={styles.name}>
                        <a
                            href={API_BASE_URL + document.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className={classNames('ri-file-line', styles.icon)}/>
                            <span>
                                <span>{nameFn(document)} </span>
                                { hasMultipleDeclarations &&
                                    <span>
                                        {firstItem &&
                                            <span>({t('components-DocumentList.current')})</span>
                                        }
                                        {!firstItem &&
                                            <span>({t('components-DocumentList.outdated')})</span>
                                        }
                                    </span>
                                }
                            </span>
                        </a>
                    </td>
                    <td className={styles.date}>
                        { firstItem &&
                            <DateOutput.VerboseMonth date={new Date(document.created)}/>
                        }
                        { !firstItem &&
                            <DateOutput.DateTime date={new Date(document.created)}/>
                        }
                    </td>
                </tr>
            )
        })
    if (!showAll && maxInitialLength && list.length > maxInitialLength) {
        list = list.slice(0, maxInitialLength - 1)
        list.push(
            <tr className={styles.rows} key="show-more">
                <td className={styles.showMore} colSpan={3} onClick={() => setShowAll(true)}>{t('components-DocumentList.showMore')}</td>
            </tr>
        )
    }

    if (!list || list.length === 0) {
        list = [
            <tr className={styles.rows} key="no-documents">
                <td className={styles.noDocuments} colSpan={3}>{t('components-DocumentList.noDocuments')}</td>
            </tr>
        ]
    }

    return (
        <table className={classNames(styles.table, smallText && styles.smallText)} cellPadding="0" cellSpacing="0">
            <thead>
            <tr className={styles.headers}>
                <th>{t('components-DocumentList.title')}</th>
                <th className={styles.dateHeader}>{t('components-DocumentList.date')}</th>
            </tr>
            </thead>
            <tbody>
            {list}
            {customerDeclarationList}
            </tbody>
        </table>
    )
}


