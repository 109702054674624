import { Fragment } from 'react';
import Paper from '#app/components/Paper/FxPaper'
import BankEmailRegistrationForm from './BankEmailRegistrationForm'
import {useTranslation} from '#components/i18n'
import {BankDto} from '@fixrate/fixrate-query'

export default function BankEmail({bank}: { bank: BankDto }) {

    const {t} = useTranslation('pages-mybank')

    return (
        <Fragment>
            <Paper id="email-settings" title={t('emailNotificationSettingsHeader')}>
                <p>{t('emailNotificationSettingsInfo')}</p>
                <BankEmailRegistrationForm bank={bank}/>
            </Paper>
        </Fragment>
    );
}
