import PropTypes from 'prop-types'
import styles from './Slider.module.scss'
import classNames from 'classnames'

const Slider = ({value, onChange, min, max, step, sliderText, darkBackground = false, showValueText = true, formatFunc = null, id = null}) => {
    var formatFunc = formatFunc
    if (!formatFunc) {
        formatFunc = function(value) {
            return value;
        }
    }
    return (
        <div className={styles.numberInputContainer}>
            <input type={'range'}
                   className={classNames(styles.numberInput, darkBackground && styles.darkBg)}
                   value={value}
                   onChange={onChange}
                   min={min}
                   max={max}
                   step={step}
                   id={id}
            />
            <span className={styles.numberInputLabel}>{(showValueText ? formatFunc(value) : '') + ' ' + (sliderText || '')}</span>
        </div>
    )
}

Slider.propTypes = {
    value: PropTypes.number || PropTypes.string,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    sliderText: PropTypes.string,
    darkBackground: PropTypes.bool,
    showValueText: PropTypes.bool,
    formatFunc: PropTypes.func,
    string: PropTypes.string,
}

export default Slider