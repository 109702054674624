import { useCommand } from '#command';
import Modal from '#components/Modal';
import { validateEmailAddress as ValidatorEmail } from '#services/validateFields';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './ResendUserInvite.module.scss';

interface ResendUserInviteProps {
    close?: () => void
    returnTo: string
    depositorId: string
    inviteId: string
    inviteEmail: string
}

export default function ResendUserInvite({depositorId, close = null, returnTo, inviteId, inviteEmail}: ResendUserInviteProps) {

    const {t} = useTranslation()

    const [email, setEmail] = useState(inviteEmail)
    const [error, setError] = useState({
        email: null,
    })

    const navigate = useNavigate()
    const {resendUserInvite} = useCommand()

    function validateEmail() {
        if (!email) {
            throw t('blocks-UsersList.inviteEmailMissing')
        }

        if (!ValidatorEmail(email)) {
            throw t('blocks-UsersList.inviteEmailInvalid')
        }
    }

    async function submit() {
        try {
            setError(prevState => ({...prevState, email: null}))
            validateEmail()
        } catch (err) {
            setError(prevState => ({...prevState, email: err}))
            return
        }

        try {
            const {waitForCommand} = await resendUserInvite(depositorId, inviteId, email)
            const success = await waitForCommand()
            if (success) {
                closeModal()
            }
        } catch (err) {
            console.error(err)
        }
    }

    function onBlur() {
        try {
            setError(prevState => ({...prevState, email: null}))
            validateEmail()
        } catch (err) {
            setError(prevState => ({...prevState, email: err}))
        }
    }

    const closeModal = () => {
        if (close) {
            close()
        } else {
            navigate(returnTo)
        }
    }

    return (
        <Modal
            header={t('blocks-UsersList.inviteResendHeading')}
            onSubmit={submit}
            onCancel={closeModal}
            width="40rem"
        >
            <div className={styles.newuser__form}>
                <label
                    className={styles.newuser__inputlabel}
                    htmlFor="emailField"
                >
                    {t('blocks-UsersList.inviteEmail')}
                </label>
                <input
                    className={styles.newuser__input}
                    id="emailField"
                    onBlur={onBlur}
                    onChange={e => setEmail(e.target.value)}
                    type="text"
                    value={email}
                />
                <p className="field-error-message">
                    {error.email}
                </p>
            </div>
        </Modal>
    )
}
