import EmptyState from '#app/components/EmptyState/EmptyState';
import { useSortedAds } from '#app/services/useSortedAds';
import * as selectors from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { Box, Button, Link } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AdCardDetails from './AdCardDetails/AdCardDetails';
import BillIllustration from './assets/IllustrationBills.svg?url';
import CoinIllustration from './assets/IllustrationCoins.svg?url';
import styles from './DepositMarketplaceOverview.module.scss';
import { GroupedAdsSwiper } from './GroupedAdsSwiper/GroupedAdsSwiper';

type Params = {
    adId?: string
}

export default function DepositMarketplaceOverview() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {adId} = useParams<Params>()

    const selectedCurrency = useSelector(state => state.session?.association?.currentCurrency)
    const associations = useSelector(state => state.session?.associations)
    const organisationType = useSelector(state => state.session?.organisationType)

    const [ads, unfilteredAds, adsLoaded] = useSelector(state => [
        selectors.filteredAndSortedAds(state),
        state.ads,
        state.loaded.ads,
    ])

    const adsOver20 = useSortedAds('OVER_20')
    const adsUnder20 = useSortedAds('UNDER_20')

    const isDepositorAndHasOrganisation = () => {
        const hasDepositorAssociation = associations?.some(a => a.organisation?.type === 'DEPOSITOR')
        const isDepositorAndHasOrganisation = organisationType === 'DEPOSITOR' && hasDepositorAssociation
        const isDepositor = organisationType === 'DEPOSITOR'

        return !isDepositor || isDepositorAndHasOrganisation
    }

    function openAd(id: string, url: string) {
        navigate('/marketplace/category/deposits/overview/' + id)
    }

    const marketplacePopup = ads.length > 0 && (
        <AdCardDetails
            key={adId}
            adId={adId}
            open={!!adId}
            backUrl={'/marketplace/category/deposits/overview'}
            isDepositorAndHasOrganisation={isDepositorAndHasOrganisation()}
        />
    )
    const goToMarketplaceButtonStyle = {
        marginTop: '2rem',
        marginBottom: '1rem'
    }

    const getEmptyIllustration = () => {
        if (selectedCurrency === 'NOK') {
            return 'currencyNOK'
        } else if (selectedCurrency === 'EUR') {
            return 'currencyEUR'
        } else if (selectedCurrency === 'USD') {
            return 'currencyUSD'
        } else if (selectedCurrency === 'SEK') {
            return 'currencySEK'
        }

        return 'manCoffeeDocument'
    }

    return (
        <Fragment>
            { unfilteredAds.length === 0 && adsLoaded ? (
                <EmptyState
                    variant='centered-on-background'
                    illustration={{name: getEmptyIllustration(), color: 'default'}}
                    title={t('pages-marketplace.noAdsInCurrency', {currency: selectedCurrency})}/>
            ) : (
                <Box className={styles.marketplaceOverview} mt={3}>
                    <section>
                        <div className={styles.categoryTitle} style={{backgroundImage: `url("${BillIllustration}")`}}>
                            <div>
                                <Link href="/marketplace/category/deposits/over-20">
                                    <h2>{t('pages-marketplace.over20Million')}</h2>
                                </Link>
                                <p>{t('pages-marketplace.over20MillionDescription')}</p>
                            </div>
                            <Button
                                sx={goToMarketplaceButtonStyle}
                                color="springGreen"
                                variant="contained" data-cy="show-over-20" href={'/marketplace/category/deposits/over-20'}
                            >
                                {t('pages-marketplace.allOffers')}
                            </Button>
                        </div>
                        <div className={styles.sliderContainer}>
                            <GroupedAdsSwiper marketplaceUrl="/marketplace/category/deposits/over-20" onAdClick={openAd} ads={adsOver20}/>
                        </div>
                    </section>
                    <section>
                        <div className={styles.categoryTitle} style={{backgroundImage: `url("${CoinIllustration}")`}}>
                            <div>
                                <Link href="/marketplace/category/deposits/under-20">
                                    <h2>{t('pages-marketplace.under20Million')}</h2>
                                </Link>
                                <p>{t('pages-marketplace.under20MillionDescription')}</p>
                            </div>
                            <Button
                                sx={goToMarketplaceButtonStyle}
                                color="springGreen"
                                variant="contained"
                                data-cy="show-under-20"
                                href={'/marketplace/category/deposits/under-20'}
                            >
                                <span>{t('pages-marketplace.allOffers')}</span>
                            </Button>
                        </div>
                        <div className={styles.sliderContainer}>
                            <GroupedAdsSwiper marketplaceUrl="/marketplace/category/deposits/under-20" onAdClick={openAd} ads={adsUnder20}/>
                        </div>
                        {marketplacePopup}
                    </section>
                </Box>
            )}
        </Fragment>
    );
}
