import { SUNSET_ORANGE } from "#app/colors/colors"
import { DepositDto } from "@fixrate/fixrate-query"
import { Box, Collapse, TableCell, TableRow } from "@mui/material"
import ProcessInformation, { depositWarnings } from "./ProcessInformation"
export default function TableRowWarning({deposit} : {deposit: DepositDto}) {
    const warnings = depositWarnings(deposit)
    return warnings.hasWarnings ? (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderLeft: "0.4rem solid" + SUNSET_ORANGE[600] }} colSpan={6}>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <Box>
                    <ProcessInformation deposit={deposit} showTags={true}/>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
    ) : null
}
