import hasInterestCalculation from '#app/services/hasInterestCalculation';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import HistoryPanel from '#blocks/HistoryPanel/HistoryPanel';
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner';
import PageHeader from '#components/PageHeader/PageHeader';
import PageLayout from '#components/PageLayout/PageLayout';
import NextFixedInterestPeriodTerms from '#pages/portfolio-bank/DepositDetailsBank/NextFixedInterestPeriodTerms';
import RejectIRCTerminationAction from '#pages/portfolio-bank/DepositDetailsBank/RejectIRCTerminationAction';
import { useAuthorization } from "#services/authorization";
import { formatOrganizationNumber } from '#services/formatnumber';
import { useSelector } from '#state/useSelector';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConditionalTerminationAction from './ConditionalTerminationAction';
import ConfirmSettlementAccount from './ConfirmSettlementAccount';
import styles from './DepositDetailsBank.module.scss';
import DepositDetailsBankInfo from './DepositDetailsBankInfo';
import DepositDetailsBankInterests from './DepositDetailsBankInterests';
import DepositDetailsBankNonConformanceHandling from './DepositDetailsBankNonConformanceHandling';
import ExtendDeposit from './ExtendDeposit';
import TerminateDepositAction from './TerminateDepositAction';


export const TerminationModeConstant = Object.freeze({
    HIDE: 'HIDE',
    SELECT: 'SELECT',
    UNCONDITIONAL: 'UNCONDITIONAL',
    CONDITIONAL: 'CONDITIONAL',
    REJECT_INTEREST_RATE_CHANGE: 'REJECT_INTEREST_RATE_CHANGE',
})

type Params = {
    depositId?: string
}

export default function DepositDetailsBank() {
    const {t} = useTranslation()

    const authorization = useAuthorization()
    const isCoordinator = authorization.bank.hasCoordinatorRole
    const organisationCountry = useCurrentCountryCode()

    const params = useParams<Params>()
    const deposit = useSelector(state => state.deposits.find(deposit => deposit.id === params.depositId))
    const interestRateChangeIdList = useSelector(state => state.interestRateChange.filter(irc => irc.depositId === deposit.id).map(irc => irc.id))
    const depositDeviationIdList = useSelector(state => state.depositDeviations.filter(deviation => deviation.depositId === deposit.id).map(deviation => deviation.id))
    const selectedCurrency = useSelector(state => state.session?.association?.currentCurrency)

    if (!deposit) {
        return (
            <div className="layout__center-content">
                <LoadingSpinner text={t('common.loadingData')}/>
            </div>
        )
    }

    const processIdList = [deposit.orderId, deposit.id, ...interestRateChangeIdList, ...depositDeviationIdList]

    return (
        <>
            <PageHeader title={deposit.depositor.name} subtitle={formatOrganizationNumber(deposit.depositor.nationalIdentity, organisationCountry)} useBackButton={true}/>
            <PageLayout>
                {isCoordinator && <DepositDetailsBankNonConformanceHandling deposit={deposit}/>}
                <div className={styles.flexRowHistoryRight}>
                    <div>
                        <NextFixedInterestPeriodTerms deposit={deposit}/>
                        <ExtendDeposit deposit={deposit}/>
                        <ConfirmSettlementAccount deposit={deposit}/>
                        <TerminateDepositAction deposit={deposit}/>
                        <ConditionalTerminationAction deposit={deposit}/>
                        <RejectIRCTerminationAction deposit={deposit}/>
                        <DepositDetailsBankInfo deposit={deposit}/>
                        { hasInterestCalculation(selectedCurrency) && <DepositDetailsBankInterests deposit={deposit}/> }
                    </div>
                    <div>
                        <HistoryPanel processId={processIdList}/>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}
