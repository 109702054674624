import { Fragment } from 'react';
import MenuItem from './MenuItem'
import {useSelector} from '#state/useSelector'
import {isBankWithAnalytics, isLoggedIn, messageTasksActionRequiredByUserCountSelector} from '#state/selectors'
import {useTranslation} from "react-i18next"

const BankMenu = () => {

    const {t} = useTranslation()

    const loggedIn = useSelector(isLoggedIn)
    const hasAnalytics = useSelector(isBankWithAnalytics)
    const newCustomerDeclarationCount = useSelector(({newCustomerDeclarations}) => newCustomerDeclarations ? newCustomerDeclarations.length : 0)
    const showNewCustomerDeclarationsMenuItem = newCustomerDeclarationCount > 0
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)
    const showDepositorOfferMenuItem = useSelector(state => {
        return state.depositorOffers.length > 0
    })

    const bankDashboardMenuItem = (
        <MenuItem
            id="bankDashboardMenuItem"
            link="/bank-dashboard"
            name={t('menu.bankDashboard')}
            remixIcon="ri-dashboard-line"
        />
    )

    const inboxMenuItem = (
        <MenuItem
            id="inboxMenuItem"
            link={'/inbox'}
            name={t('menu.inbox')}
            remixIcon="ri-inbox-line"
            counter={messageTasksActionRequiredByUserCount}
        />
    )

    const bankAdsMenuItem = (
        <MenuItem
            id="bankAdsMenuItem"
            link={'/ads'}
            name={t('menu.bankAds')}
            remixIcon="ri-layout-grid-line"
        />
    )

    const bankOrdersMenuItem = (
        <MenuItem
            id="bankOrdersMenuItem"
            link={'/bank-orders'}
            name={t('menu.orders')}
            remixIcon="ri-mail-send-line"
        />
    )

    const depositorOfferMenuItem = (
        <MenuItem
            id="depositorOfferMenuItem"
            link={'/depositoroffer-bank'}
            name={t('menu.offers')}
            remixIcon="ri-mail-send-line"
        />
    )

    const interestRateChangeMenuItem = (
        <MenuItem
            id="interestChangeMenuItem"
            link={'/interestratechange-bank'}
            name={t('menu.interestChange')}
            remixIcon="ri-percent-line"
        />
    )

    const newCustomerDeclarationsMenuItem = (
        <MenuItem
            id="newCustomerDeclarationsMenuItem"
            link={'/newcustomerdeclarations'}
            name={t('menu.customerDeclarations')}
            remixIcon="ri-file-shield-2-line"
        />
    )

    const portfolioMenuItem = (
        <MenuItem
            id="portfolioMenuItem"
            link={'/portfolio-bank'}
            name={t('menu.portfolio')}
            remixIcon="ri-briefcase-line"
        />
    )

    const reportsMenuItem = (
        <MenuItem
            id="reportsMenuItem"
            link={'/bank-reports/balance-reporting'}
            name={t('menu.reporting')}
            remixIcon="ri-file-chart-2-line" />
    )

    const analyticsMenuItem = (
        <MenuItem
            id="analyticsMenuItem"
            link={'/analytics'}
            name={t('menu.analytics')}
            remixIcon="ri-line-chart-line"
        >
            <MenuItem.SubNav id="analyticsDepositsLink" to="/analytics/bank/deposits">
                {t('menu.deposits')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="analyticsOverviewLink" to="/analytics/bank/ads">
                {t('menu.ads')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="analyticsNiborLink" to="/analytics/nibor">
                {t('menu.nibor')}
            </MenuItem.SubNav>
        </MenuItem>
    )

    const loginMenuItem = (
        <MenuItem
            id="loginMenuItem"
            key="login"
            link={'/marketplace?login'}
            name={t('menu.logIn')}
            remixIcon="ri-account-circle-line"/>
    )

    return (
        <Fragment>
            {loggedIn && bankDashboardMenuItem}
            <MenuItem
                id="marketPlaceMenuItem"
                link={'/marketplace'}
                name={t('menu.marketplace')}
                remixIcon="ri-store-2-line"
            />
            {loggedIn && inboxMenuItem}
            {loggedIn && bankAdsMenuItem}
            {loggedIn && bankOrdersMenuItem}
            {showDepositorOfferMenuItem && depositorOfferMenuItem}
            {loggedIn && interestRateChangeMenuItem}
            {showNewCustomerDeclarationsMenuItem && newCustomerDeclarationsMenuItem}
            {loggedIn && portfolioMenuItem}
            {loggedIn && reportsMenuItem}
            {hasAnalytics && analyticsMenuItem}
            {!loggedIn && loginMenuItem}
        </Fragment>
    );
}

export default BankMenu
