import { PURPLE, SILVER_GRAY } from '#app/colors/colors'
import { useCommand } from '#command'
import { DateOutput } from '#components'
import { useTranslation } from '#components/i18n'
import Modal from '#components/Modal'
import AutoCloseModalConfirmation from '#components/Modal/AutoCloseModalConfirmation'
import EditPersonModal from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/EditPersonModal'
import InviteModal from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/InviteModal'
import NameOutput from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/NameOutput'
import PersonWarnings from '#pages/organizations/OrganizationDetail/UsersAndRoles/views/PersonWarnings'
import { useAuthorization } from '#services/authorization'
import { organisationalRoleTMap, securityRoleTMap } from '#services/enumTranslationKeyMapping'
import { PersonValidator } from '#services/PersonValidator'
import { DepositorDto, OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { Card, CardActions, CardContent, Stack, Typography } from '@mui/material'
import isAfter from 'date-fns/isAfter'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CorneredMenuItem from './CorneredMenuItem'
import styles from './DepositorPersonList.module.scss'
import ResendUserInvite from './ResendUserInvite'

type Props = {
    person: OrganisationalPersonDto
    depositor: DepositorDto
    basePath?: string
}

export default function Person({ person, depositor, basePath }: Props) {
    const { t } = useTranslation('blocks-UsersList')
    const navigate = useNavigate()
    const { removeUserInvite, removeUserFromDepositor, removePerson } = useCommand()
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [sendInviteVisible, setSendInviteVisible] = useState(false)
    const [resendInviteVisible, setResendInviteVisible] = useState(false)
    const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)
    const [removeInviteModalVisible, setRemoveInviteModalVisible] = useState(false)
    const [removePersonModalVisible, setRemovePersonModalVisible] = useState(false)
    const [confirmCopyModalVisible, setConfirmCopyModalVisible] = useState(false)
    const auth = useAuthorization(depositor.id)

    const visibleSecurityRoles = person.securityRoles
        .filter((role) => role !== 'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
        .filter((role) => person.securityRoles.length === 1 || role !== 'DEPOSITOR_VIEW')

    const roles = [
        ...visibleSecurityRoles.map((role) => t(securityRoleTMap[role])),
        ...person.organisationalRoles.map((role) => t(organisationalRoleTMap[role])),
    ]
        .sort()
        .join(', ')

    const userInvite = depositor.userInvites.find((ui) => ui.id === person.associatedInviteId)

    const inviteNotificationIsSent = userInvite && !!userInvite.lastNotification
    const inviteExpired = userInvite && isAfter(new Date(), new Date(userInvite.expirationDate))
    const inviteInvalid = userInvite?.invalid
    const inviteLink =
        window.location.href.split(window.location.pathname)[0] + '/invite/' + userInvite?.id

    async function onRemoveUser() {
        try {
            await removeUserFromDepositor(depositor.id, person.associatedUserId)
        } catch (err) {
            console.error(err)
        }
        setRemoveUserModalVisible(false)
    }

    async function onRemovePerson() {
        try {
            await removePerson(depositor.id, person.personId)
        } catch (err) {
            console.error(err)
        }
        setRemovePersonModalVisible(false)
    }

    async function onRemoveInvite() {
        try {
            await removeUserInvite(depositor.id, person.associatedInviteId)
        } catch (err) {
            console.error(err)
        }
        setRemoveInviteModalVisible(false)
    }

    function copyLink(userInvite) {
        const copyText = document.getElementById('inviteLink' + userInvite.id) as HTMLInputElement
        copyText.select()
        copyText.setSelectionRange(0, 99999) //For mobile devices
        document.execCommand('copy')
        setConfirmCopyModalVisible(true)
    }

    const viewProfileButton = (
        <CorneredMenuItem
            id='ViewProfileMenuitem'
            onClick={() =>
                navigate(`/organizations/${depositor.id}/users/profile/${person.personId}`)
            }
            text={t('menuItemViewProfile')}
            icon={null}
        />
    )

    const sendInviteButton = (
        <CorneredMenuItem
            id='sendInvitationMenuitem'
            onClick={() => setSendInviteVisible(true)}
            text={t('menuItemSendInvite')}
            icon='ri-user-add-line'
        />
    )

    const resendInviteButton = (
        <CorneredMenuItem
            id='resendInvitationMenuitem'
            onClick={() => setResendInviteVisible(true)}
            text={
                inviteNotificationIsSent
                    ? t('menuItemResendInvite')
                    : t('menuItemSendInviteNotification')
            }
            icon='ri-mail-send-line'
        />
    )

    const removeInviteButton = (
        <CorneredMenuItem
            id='removeInvitationMenuitem'
            onClick={() => setRemoveInviteModalVisible(true)}
            text={t('menuItemRemoveInvite')}
            icon='ri-delete-bin-line'
        />
    )

    const removeUserButton = (
        <CorneredMenuItem
            id='removeInvitationMenuitem'
            onClick={() => setRemoveUserModalVisible(true)}
            text={t('menuItemRemoveUser')}
            icon='ri-delete-bin-line'
        />
    )

    const editUserButton = (
        <CorneredMenuItem
            id='editUserMenuitem'
            onClick={() => setEditModalVisible(true)}
            text={t('menuItemEditUser')}
            icon='ri-edit-line'
        />
    )

    const removePersonButton = (
        <CorneredMenuItem
            id='removePersonMenuitem'
            onClick={() => setRemovePersonModalVisible(true)}
            text={t('menuItemRemovePerson')}
            icon='ri-delete-bin-line'
        />
    )

    const copyButton = (
        <CorneredMenuItem
            id='copyLinkMenuitem'
            onClick={() => copyLink(userInvite)}
            text={t('copyToClipboard')}
            icon='ri-file-copy-line'
        >
            {confirmCopyModalVisible && (
                <AutoCloseModalConfirmation
                    text={'Invitasjonslenken ble kopiert'}
                    onClose={() => setConfirmCopyModalVisible(false)}
                />
            )}
        </CorneredMenuItem>
    )

    return (
        <>
            <Card sx={{maxWidth: "70rem"}} id={person.personId} data-cy='personElement'>
                <CardContent>
                    <Stack spacing={1}>
                        <Stack spacing={0.4}>
                            <Link
                                className={styles.profileLink}
                                to={`/organizations/${depositor.id}/users/profile/${person.personId}`}
                            >
                                <Typography variant='h3'>
                                    {NameOutput.withBirthYearOnDuplicates(person, depositor)}
                                </Typography>
                            </Link>
                            <Typography variant='body2'>{roles}</Typography>
                        </Stack>
                        {userInvite && (
                            <Stack>
                                <Typography variant='caption' sx={{ color: SILVER_GRAY[500] }}>
                                    <i className='ri-mail-send-line' />

                                    {inviteExpired &&
                                        t('inviteStatusExpired', {
                                            date: DateOutput.formatDate(userInvite.requested),
                                            expiryDate: DateOutput.formatDate(
                                                userInvite.expirationDate
                                            ),
                                        })}

                                    {!inviteExpired &&
                                        inviteNotificationIsSent &&
                                        t('inviteStatusActive', {
                                            date: DateOutput.formatDateTime(
                                                userInvite.lastNotification
                                            ),
                                            expiryDate: DateOutput.formatDate(
                                                userInvite.expirationDate
                                            ),
                                        })}

                                    {!inviteExpired &&
                                        !inviteNotificationIsSent &&
                                        t('inviteStatusNotSent', {
                                            expiryDate: DateOutput.formatDate(
                                                userInvite.expirationDate
                                            ),
                                        })}

                                    <input
                                        id={'inviteLink' + userInvite.id}
                                        readOnly
                                        style={{
                                            position: 'absolute',
                                            top: '-10000px',
                                            left: '-10000px',
                                        }}
                                        type='text'
                                        value={inviteLink}
                                    />
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </CardContent>
                <CardActions sx={{p: 0}}>
                    <Stack width={"100%"}>
                        <Stack px={2} direction="row" alignItems={"center"} sx={{overflowY: "auto", backgroundColor: PURPLE[50]}}>
                            {viewProfileButton}
                            {PersonValidator.isUser(person) && editUserButton}
                            {!PersonValidator.isPartner(person) &&
                                PersonValidator.isUser(person) &&
                                removeUserButton}
                            {PersonValidator.needsInvite(depositor, person) && sendInviteButton}
                            {userInvite && resendInviteButton}
                            {userInvite && !inviteExpired && !inviteInvalid && copyButton}
                            {userInvite &&
                                (auth.depositor.hasAdminRole || auth.depositor.hasPartnerRole) &&
                                removeInviteButton}
                            {!PersonValidator.isUser(person) &&
                                !PersonValidator.isInvite(person) &&
                                removePersonButton}
                        </Stack>
                        <PersonWarnings
                            person={person}
                            depositor={depositor}
                            onSendInvite={() => setSendInviteVisible(true)}
                            onResendInvite={() => setResendInviteVisible(true)}
                            onEditNow={() => setEditModalVisible(true)}
                            showRedLeftBorder={false}
                        />
                    </Stack>
                </CardActions>
            </Card>
            <>
                {editModalVisible &&
                    <EditPersonModal
                        open={editModalVisible}
                        depositorId={depositor.id}
                        person={person}
                        onClose={() => {
                            setEditModalVisible(false)
                        }}
                    />
                }

                {sendInviteVisible && (
                    <InviteModal personId={person.personId} close={() => setSendInviteVisible(false)} />
                )}

                {resendInviteVisible && (
                    <ResendUserInvite
                        depositorId={depositor.id}
                        inviteId={userInvite.id}
                        inviteEmail={userInvite.email}
                        close={() => setResendInviteVisible(false)}
                        returnTo={basePath}
                    />
                )}

                {removePersonModalVisible && (
                    <Modal
                        onCancel={() => setRemovePersonModalVisible(false)}
                        width='40rem'
                        header={t('removePersonHeading')}
                        onSubmit={() => onRemovePerson()}
                        className={styles.confirmation}
                    >
                        <div className={styles.innerWrapper}>
                            <p className={styles.body}>
                                {t('removePersonConfirmation', {
                                    userName: NameOutput.withBirthYearOnDuplicates(person, depositor),
                                    organizationName: depositor.name,
                                })}
                            </p>
                        </div>
                    </Modal>
                )}

                {removeUserModalVisible && (
                    <Modal
                        onCancel={() => setRemoveUserModalVisible(false)}
                        width='40rem'
                        header={t('removeUserHeading')}
                        onSubmit={() => onRemoveUser()}
                        className={styles.confirmation}
                    >
                        <div className={styles.innerWrapper}>
                            <p className={styles.body}>
                                {t('removeUserConfirmation', {
                                    userName: NameOutput.withBirthYearOnDuplicates(person, depositor),
                                    organizationName: depositor.name,
                                })}
                            </p>
                        </div>
                    </Modal>
                )}

                {removeInviteModalVisible && (
                    <Modal
                        onCancel={() => setRemoveInviteModalVisible(false)}
                        width='40rem'
                        header={t('removeInviteHeading')}
                        onSubmit={() => onRemoveInvite()}
                        className={styles.confirmation}
                    >
                        <div className={styles.innerWrapper}>
                            <p className={styles.body}>
                                {t('removeInviteConfirmation', {
                                    userName: NameOutput.withBirthYearOnDuplicates(person, depositor),
                                    organizationName: depositor.name,
                                })}
                            </p>
                        </div>
                    </Modal>
                )}
            </>
        </>
    )
}
