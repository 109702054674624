import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { Animated } from '#components'
import { useTranslation } from '#components/i18n'
import ModalConfirmation from '#components/ModalConfirmation/ModalConfirmation'
import { userIsPartner } from '#services/authorization'
import { sortDatesAscending } from '#services/dateandtime'
import { useSelector } from '#state/useSelector'
import {
    BankDto,
    BankUserDto,
    OrganisationUserInviteDto,
    PartnerDto,
    PartnerUserDto,
    SecurityRole
} from '@fixrate/fixrate-query'
import { Button } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import EditRole from './EditRole'
import NewUser from './NewUser'
import ResendUserInvite from './ResendUserInvite'
import User from './User'
import UserInvite from './UserInvite'
import styles from './UsersList.module.scss'

export type GenericOrgDto = BankDto | PartnerDto
export type GenericUser = (BankUserDto | PartnerUserDto)

export type UsersListParams = {
    user: GenericUser,
    organisation: GenericOrgDto,
    removeUser: (userId?: string) => void,
    users: Array<GenericUser>,
    userInvites: Array<OrganisationUserInviteDto>,
    isAdmin: boolean,
    basePath: string,
    missingRoles: Array<SecurityRole>,
    roles: Array<SecurityRole>
}

type Params = {
    showForm?: 'new-user' | 'edit-role' | 'remove-user' | 'resend-invite',
    userId?: string

}

type Props = {
    userListParams: UsersListParams
}

export default function UsersList({userListParams}: Props) {
    const {t} = useTranslation('blocks-UsersList')
    const params = useParams<Params>()
    const navigate = useNavigate()
    const session = useSelector(({session}) => session)

    const {
        user,
        organisation,
        removeUser,
        users,
        userInvites,
        isAdmin,
        basePath,
    } = userListParams

    const depositor = useCurrentDepositor()
    const userIsActiveAuthorizationSigner = useSelector(({documents}) => !user ? false : depositor?.users.flatMap(u => documents[u.authorizationDocumentId]?.allSignatures.map(s => s.userId)).filter(id => id === user.id).length > 0)
    const org = organisation.type === 'DEPOSITOR' ? depositor : organisation

    let modal
    switch (params.showForm) {
        case 'new-user': {
            modal = <NewUser organisationId={org.id} organisationType={org.type}/>
            break
        }

        case 'edit-role': {
            modal = <EditRole organisationType={org.type} userId={params.userId}/>
            break
        }

        case 'remove-user': {
            if (user) {
                if (userIsPartner(user)) {
                    modal = (
                        <ModalConfirmation
                            backUrl={basePath}
                            header={t('removeUserHeading')}
                            onSubmit={() => 0}
                            text={t('removeUserContactSupport', {userName: user.firstName + ' ' + user.lastName, organizationName: org.name})}
                        />
                    )
                } else {
                    modal = (
                        <ModalConfirmation
                            backUrl={basePath}
                            header={t('removeUserHeading')}
                            onSubmit={() => !userIsActiveAuthorizationSigner && removeUser()}
                            text={
                                userIsActiveAuthorizationSigner
                                ? t('removeUserIsActiveAuthorizationSigner', {userName: user.firstName + ' ' + user.lastName})
                                : t('removeUserConfirmation', {userName: user.firstName + ' ' + user.lastName, organizationName: org.name})
                            }
                        />
                    )
                }
            }
            break
        }

        case 'resend-invite': {
            const inviteId = params.userId
            modal = (
                <ResendUserInvite
                    organisationId={org.id}
                    organisationType={org.type}
                    inviteId={inviteId}
                    inviteEmail={Array.isArray(userInvites) ? userInvites.find(i => i.id === inviteId).email : ''}
                    returnTo={basePath}
                />
            )
            break
        }

    }

    const userList = users.slice().sort((a, b) => {
            if (a.id === session.id) return -1
            if (b.id === session.id) return 1
            return a.firstName?.localeCompare(b.firstName)
        })
        .map((user, i) => (
            <Animated key={user.id} i={i}>
                <User user={user} userListParams={userListParams}/>
            </Animated>
        ))

    const userInviteList = Array.isArray(userInvites) ?
        userInvites
            .slice()
            .sort((u1, u2) => sortDatesAscending(u1.requested, u2.requested))
            .map((userInvite, i) => (
                <Animated key={i} i={userList.length + i}>
                    <UserInvite userListParams={userListParams} userInvite={userInvite}/>
                </Animated>
            )) : []

    const allUserElements = [...userList, ...userInviteList]

    return (
        <>
            <ul className={styles.list}>
                <AnimatePresence>
                    {allUserElements}
                </AnimatePresence>
            </ul>
            {(isAdmin) && (
                <div className={styles.addnew}>
                    <Button
                        id="inviteUserButton"
                        variant="contained"
                        onClick={() => navigate(`${basePath}/new-user#!`)}
                    >
                        <i className="ri-user-add-line" style={{marginRight: '0.4rem'}}/>
                        <span>{t('inviteNewUser')}</span>
                    </Button>
                </div>
            )}
            {modal}
        </>
    )
}

