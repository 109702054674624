import {useState} from 'react'
import {DocumentDto} from '@fixrate/fixrate-query'
import FileUpload from '#components/FileUpload'
import {Button, Stack} from '@mui/material'
import {useCommand} from '#command'

type Props = {
    depositorId: string,
    currentContract: DocumentDto
}

export default function UploadFundContractAddendum({depositorId, currentContract}: Props) {
    const [showUpload, setShowUpload] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const {uploadFundContractAddendum} = useCommand()

    const onDrop = (files) => {
        setIsUploading(true)
        files.forEach(file => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                console.log('Uploading file ' + file?.path)
                uploadFundContractAddendum(depositorId, new Blob([reader.result], {type: file.type}), file?.path)
            }
            reader.readAsArrayBuffer(file)
        })
        setIsUploading(false)
        setShowUpload(false)
    }

    return (
        <Stack>
            {!showUpload && (
                    <Button variant={'outlined'} onClick={() => setShowUpload(true)}>{currentContract ? "Erstatt vedlegg" : "Last opp vedlegg"}</Button>
            )}
            {showUpload && (
                <>
                <Button variant={'contained'} onClick={() => setShowUpload(false)}>{currentContract ? "Erstatt vedlegg" : "Last opp vedlegg"}</Button>
                <FileUpload
                    uploadFiles={onDrop}
                    isUploadingDocument={isUploading}
                    acceptedType={{'application/pdf': ['.pdf'], 'application/json': ['.json']}}
                    multiple={true}
                />
                </>
            )}

        </Stack>
    )
}
