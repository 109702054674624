import { DateOutput } from '#components'
import { useTranslation } from "#components/i18n"
import * as selectors from '#state/selectors'
import { TerminationState } from '#state/selectors'
import { DepositDto } from '@fixrate/fixrate-query'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './DepositList.module.scss'


type LinkHelperProps = {
    depositId: string
    text: string
}

// stopPropagation is needed because the parent list element has a onClick function.
const LinkHelper = ({depositId, text}: LinkHelperProps) => (
    <Link to={{pathname: `/portfolio-bank/${depositId}`}}
          onClick={e => e.stopPropagation()}>
        {text}
    </Link>
)

type ProcessInformationProps = {
    depositId: string
    deposit: DepositDto,
    isDepositExpired: boolean
}

const ProcessInformation = (props: ProcessInformationProps) => {
    const {
        depositId,
        deposit: {
            terminationRequested,
            depositor: {name: depositorName},
            expires: depositExpires,
            changedSettlementAccountStatus,
            extensionOfferState,
            extensionRegistered
        },
        isDepositExpired
    } = props

    const {t} = useTranslation('pages-portfolio-bank')

    let status = null
    if (isDepositExpired && depositExpires.days > -3) {
        status = (
            <span>{t('depositExpired')}</span>
        )
    } else if (TerminationState.SENT_TO_BANK === props.deposit.terminationState) {
        status = (
            <p className="depositTerminationSentInfo">
                {t('terminationSent', {depositorName, date: DateOutput.formatDate(terminationRequested)})}
                &nbsp;<LinkHelper depositId={depositId} text={t('pleaseConfirm')}/>
            </p>
        )
    } else if (changedSettlementAccountStatus === 'DOCUMENT_SENT_TO_BANK') {
        status = (
            <p className="depositAccountChangeSentInfo">
                {t('settlementAccountChanged')}&nbsp;
                <LinkHelper text={t('pleaseConfirm')} depositId={depositId}/>
            </p>
        )
    } else if (extensionOfferState === 'ACCEPTED' && !extensionRegistered) {
        status = (
            <p className="depositExtensionAcceptedSentInfo">
                {t('extensionOfferAccepted')}&nbsp;
                <LinkHelper text={t('pleaseConfirm')} depositId={depositId}/>
            </p>
        )
    } else if (extensionOfferState === 'CREATED' && !extensionRegistered) {
        status = (
            <p className="depositExtensionSentInfo">
                {t('extensionOfferCreated')}&nbsp;
            </p>
        )
    } else if (extensionOfferState === 'READY_FOR_SIGNING' && !extensionRegistered) {
        status = (
            <p className="depositExtensionSentInfo">
                {t('extensionOfferSent')}&nbsp;
            </p>
        )
    } else if (extensionOfferState === 'REJECTED' && !isDepositExpired && !extensionRegistered) {
        status = (
            <p className="depositExtensionRejectedInfo">
                {t('extensionOfferRejected')}&nbsp;
            </p>
        )
    }

    if (!status) return null

    return (
        <section className={styles.depositList__elementProcess}>
            {status}
        </section>
    )
}

ProcessInformation.propTypes = {
    depositId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const deposit = selectors.depositsByIdSelector(state)[ownProps.depositId]
    return {
        deposit,
        isDepositExpired: deposit?.expires && deposit.expires.expired,
    }
}

export default connect(mapStateToProps)(ProcessInformation)
