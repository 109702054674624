import { LoadingSpinner } from '#app/components';
import { useSelector } from '#app/state/useSelector';
import FundCategoriesTable from '#pages/FundMarketplace/FundTable/FundCategoriesTable';
import OverviewTable from '#pages/FundMarketplace/FundTable/OverviewTable';
import ReturnsTable from '#pages/FundMarketplace/FundTable/ReturnsTable';
import RiskTable from '#pages/FundMarketplace/FundTable/RiskTable';
import { FundDto } from '@fixrate/fixrate-query';
import { MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterLayout from '../FilterLayout/FilterLayout';

type TableView = 'overview' | 'returns' | 'risk' | 'fundCategories'

type Props = {
    funds: FundDto[]
}

type MenuItemType = {
    value: TableView
    label: string
}

export default function FundTable({funds}: Props) {
    const {t} = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const fundsLoaded = useSelector(state => !!state.loaded.funds)
    const [tableView, setTableView] = useState<TableView>('overview')
    const menuItems: MenuItemType[] = [
        {value: 'overview', label: t('pages.fundMarketplace.overview')},
        {value: 'returns', label: t('pages.fundMarketplace.returns')},
        {value: 'risk', label: t('pages.fundMarketplace.risk')},
        {value: 'fundCategories', label: t('pages.fundMarketplace.fundCategories')}
    ]

    return (
        <Fragment>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mb={2}>
                { isMobile ? (
                    <Select sx={{ "& .MuiMenuItem-root": {
                        fontSize: '1.3rem',
                    }}} size="small" value={tableView} onChange={(e) => setTableView(e.target.value as TableView)}>
                        { menuItems.map(item => (
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                        ))}
                    </Select>
                    ) : (
                    <ToggleButtonGroup color="primary" size="small" value={tableView}>
                        { menuItems.map(item => (
                            <ToggleButton key={item.value} value={item.value} onClick={() => setTableView(item.value)}>{item.label}</ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                )}
                <FilterLayout />
            </Stack>
            { funds.length > 0 && (
                <>
                    {tableView === 'overview' && <OverviewTable funds={funds}/>}
                    {tableView === 'returns' && <ReturnsTable funds={funds}/>}
                    {tableView === 'risk' && <RiskTable funds={funds}/>}
                    {tableView === 'fundCategories' && <FundCategoriesTable funds={funds}/>}
                </>
            )}
            { !fundsLoaded && (
                <LoadingSpinner />
            )}
            { fundsLoaded && funds.length === 0 && (
                <Typography mt={4} variant="emptyState">{t('pages.fundMarketplace.noFunds')}</Typography>
            )}
        </Fragment>
    );
}
