import styles from './Status.module.css'
import {useSelector} from '#state/useSelector'
import {TFunction, useTranslation} from 'react-i18next'
import {DateOutput, InterestOutput} from "#components"
import {defaultBenchmark} from '#services/interestRateBenchmark'
import {Box, Typography} from "@mui/material";
import {SILVER_GRAY} from "#app/colors/colors";


function formatNumber(number: number, t: TFunction) {
    return number && (`${number} ${t('pages-offer.mill')}`)
}

function Row({ title, value }: { title: string, value: string | number }) {
    const {t} = useTranslation()
    return (
        <div className={styles.row}>
            <div className={styles.title}>{title}</div>
            {value ? <div className={styles.value}>{value}</div> : <div className={styles.notSet}>{t('pages-offer.notSet')}</div>}
        </div>
    )
}

export default function Status({ depositorOfferId }: { depositorOfferId: string }) {
    const {t} = useTranslation()
    const offer = useSelector(state => state.depositorOffers.find(offer => offer.depositorOfferId === depositorOfferId))
    const products = useSelector(state => state.products)

    if (!offer) {
        return null
    }

    const product = products[offer.productId]

    let interest = InterestOutput.format(offer.interestRate)
    let interestLabel = t('pages-offer.interestMargin')
    let terminationDateRow = null
    if (interest) {
        if (product?.type === 'FIXED') {
            interestLabel = t('pages-offer.fixedInterest')
            terminationDateRow = <Row title={t('pages-offer.duration')} value={DateOutput.formatDate(offer.terminationDate)}/>
        } else {
            interest = product?.type ? InterestOutput.formatMarginWithBenchmark(offer.interestRate, defaultBenchmark(offer.currency), t) : interest
        }
    }

    return (
        <div className={styles.body}>
            <h3 className={styles.header}>{t('pages-offer.wizardSummary')}</h3>
            <Row title={t('pages-offer.product')} value={t('common.productShortName' + product?.id, '')}/>
            <Row title={interestLabel} value={interest}/>
            {terminationDateRow}
            <Row title={t('pages-offer.totalVolume')} value={formatNumber(offer.totalVolume, t)}/>
            <Row title={t('pages-offer.minAmount')} value={formatNumber(offer.minVolume, t)}/>
            <Row title={t('pages-offer.maxAmount')} value={formatNumber(offer.maxVolume, t)}/>
            <Row title={t('pages-offer.nmbOfBanks')} value={offer.banks.length ? offer.banks.length : ''}/>
            <Row title={t('pages-offer.deadline')} value={DateOutput.formatDateTime(offer.deadline)}/>
            {offer.comment &&
                <div>
                    <Row title={t('pages-offer.comment')} value={t('common.yes')}/>
                    <Box
                        sx={{
                            marginTop: '1rem',
                            backgroundColor: SILVER_GRAY[50],
                            padding: '1rem 1.4rem',
                        }}
                    >
                        <Typography variant='body2'>
                            {offer.comment}
                        </Typography>
                    </Box>
                </div>
            }
        </div>
    )
}
