import { useCommand } from '#command'
import Modal from '#components/Modal'
import { useSelector } from '#state/useSelector'
import { MenuItem, Select } from '@mui/material'
import {useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomerDetailSelectAdvisor.module.css'
import {useFieldState} from '@fixrate/fieldstate'

type Params = {
    depositorId?: string
}

export default function CustomerDetailSelectAdvisor() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()
    const {addPartnerAdvisor} = useCommand()

    const selected = useFieldState('')

    const partner = useSelector(state => state.partner)
    const customer = useSelector((state => state.partner.customers.find(c => c.depositorId === params.depositorId)))
    const isAccountant = partner.partnerCategory === 'ACCOUNTANT'

    const options = useMemo(() => {
        return partner.users
            .filter(user => user.roles.includes('PARTNER_ADVISOR'))
            .filter(user => !customer.advisors.includes(user.id))
    }, [customer.advisors, partner.users])

    useEffect(() => {
        if (options.length === 1) {
            selected.fn.setValue(options[0].id)
        }
    }, [selected.fn, options])

    async function onSubmit() {
        if (selected.validate()) {
            const {waitForCommand} = await addPartnerAdvisor(partner.id, customer.depositorId, selected.value)
            const success = await waitForCommand()
            if (success) {
                navigate(`/customer/${customer.depositorId}`)
            }
        }
    }

    return (
        <Modal
            header={isAccountant ? t('pages-customers.customerDetailSelectAccountantHeading') : t('pages-customers.customerDetailSelectAdvisorHeading')}
            width={'48rem'}
            onSubmit={onSubmit}
            submitButtonText={t('pages-customers.add')}
            submitButtonDisabled={!selected.touched}
            cancel={`/customer/${customer.depositorId}`}
        >
            <div className={styles.body}>
                <p>
                    {isAccountant ? t('pages-customers.customerDetailSelectAccountantMessage') : t('pages-customers.customerDetailSelectAdvisorMessage')}
                </p>
                <Select
                    data-cy="selectAdvisorDropdown"
                    onChange={(e) => selected.setValue(e.target.value)}
                    value={selected.value}
                >
                    <MenuItem key="empty" value="" disabled>{isAccountant ? t('pages-customers.customerDetailSelectAccountantPlaceholder') : t('pages-customers.customerDetailSelectAdvisorPlaceholder')}</MenuItem>
                    {options.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.firstName} {user.lastName}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </Modal>
    )
}
