import {useSelector} from '#state/useSelector'
import config from '#app/config'
import ActionBlock from '#components/ActionBlock/ActionBlock'
import styles from './NextFixedInterestPeriodTerms.module.scss'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import LabeledInput from '#components/LabeledInput/LabeledInput'
import DatePicker from '#components/DatePicker'
import {useFieldState} from '@fixrate/fieldstate'
import {CurrencyOutput, DateOutput, LabeledInfo} from '#components'
import {useCommand} from '#command'
import format from 'date-fns/format'
import addMonths from 'date-fns/addMonths'
import {useConfirmationModal} from '#app/layers/ConfirmationModal/ConfirmationModal'
import Button from '#components/Button'
import DocumentLink from '#pages/common/DocumentLink/DocumentLink'
import classNames from 'classnames'
import {DepositDto} from '@fixrate/fixrate-query'
import {toDate} from '#services/dateandtime'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

export default function NextFixedInterestPeriodTerms({deposit}: { deposit: DepositDto }) {
    const {t} = useTranslation()
    const {setFixedInterestPeriodTerms, sendFixedInterestPeriodTermsToDepositor} = useCommand()
    const confirmationModal = useConfirmationModal()

    const document = useSelector(state => {
        return deposit.nextPeriod && state.documents[deposit.nextPeriod.documentId]
    })

    const interestRate = useFieldState('' + deposit.effectiveInterestRate.toFixed(2), ({value}) => {
        return !value && t('pages-portfolio-bank.nextPeriodInterestMissing')
    })
    const nextFixedInterestPeriodTerminationDate = useFieldState(defaultDate(), ({value}) => {
        return !value && t('pages-portfolio-bank.nextPeriodDateMissing')
    })

    if (!deposit.currentPeriod) {
        return null
    }

    const nextFixedInterestPeriodStartDate = deposit.currentPeriod.terminationDate

    function firstValidDate() {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        return date
    }

    function defaultDate() {
        return addMonths(new Date(), deposit.product.months || 3)
    }

    async function submitTerms() {
        if (interestRate.validate() && nextFixedInterestPeriodTerminationDate.validate()) {
            const {waitForCommand} = await setFixedInterestPeriodTerms(
                deposit.id,
                interestRate.value.replace(',','.'),
                format(toDate(nextFixedInterestPeriodStartDate), 'yyyy-MM-dd'),
                format(nextFixedInterestPeriodTerminationDate.value, 'yyyy-MM-dd')
            )
            await waitForCommand()
        }
    }

    async function submitDocument() {
        const {waitForCommand} = await sendFixedInterestPeriodTermsToDepositor(
            deposit.id,
            deposit.nextPeriod.fixedInterestPeriodId
        )
        const success = await waitForCommand()
        if (success) {
            confirmationModal(t('pages-portfolio-bank.nextPeriodMessageSentConfirmationHeader'), t('pages-portfolio-bank.nextPeriodMessageSentConfirmationInfo'), t('common.ok'))
        }
    }

    if (!deposit.fixedInterestPeriodTermsDue && !deposit.nextPeriod) {
        return null
    }

    const deadline = new Date()
    deadline.setDate(new Date(nextFixedInterestPeriodStartDate).getDate() - 1)

    const capitalizationDate = new Date(deposit.estimatedDate)
    capitalizationDate.setDate(capitalizationDate.getDate() + 1)

    if (!deposit.nextPeriod) {

        return (
            <ActionBlock header={t('pages-portfolio-bank.nextPeriodHeader')}>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo1', {date: DateOutput.formatVerboseMonth(nextFixedInterestPeriodStartDate)})}
                </p>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo2', {date: toDateAtNoon(nextFixedInterestPeriodStartDate)})}
                </p>
                <div className={styles.borderedSection}>

                    <LabeledInput label={t('pages-portfolio-bank.nextPeriodInterestLabel')}
                                  helpText={t('pages-portfolio-bank.nextPeriodInterestHelpText')}
                                  errorMessage={interestRate.errorMessage}
                    >
                        <input id={'interestRate'}
                               className={styles.number}
                               type="text"
                               onChange={(e) => interestRate.setValue(e.target.value)}
                               value={interestRate.value || ''}
                        /><span>%</span>
                    </LabeledInput>
                    <LabeledInput label={t('pages-portfolio-bank.nextPeriodEndDateLabel')}
                                  helpText={t('pages-portfolio-bank.nextPeriodIEndDateHelpText')}
                                  errorMessage={nextFixedInterestPeriodTerminationDate.errorMessage}
                    >
                        <div className={styles.date}>
                            <DatePicker
                                id="nextFixedInterestPeriodTerminationDate"
                                minDate={firstValidDate()}
                                selected={nextFixedInterestPeriodTerminationDate.value}
                                onChange={(date) => nextFixedInterestPeriodTerminationDate.setValue(date)}
                                filterDate={date => !(date < firstValidDate())}
                            />
                        </div>
                    </LabeledInput>
                    <LabeledInfo
                        label={t('pages-portfolio-bank.currentPeriodCapitalizedBalanceLabel',{calculatedDate: DateOutput.formatDate(capitalizationDate)})}
                        info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(deposit.estimatedTotalBalance)}
                        helpText={t('pages-portfolio-bank.currentPeriodCapitalizedBalanceHelpText',{calculatedDate: DateOutput.formatDate(capitalizationDate)})}
                    />
                </div>

                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo3', {date: DateOutput.formatVerboseMonth(deadline)})}
                </p>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo4', {date: DateOutput.formatVerboseMonth(deposit.currentPeriod.terminationDate)})}
                </p>

                <ButtonRow>
                    <Button
                        onClick={submitTerms}>
                        Lag dokument
                    </Button>
                </ButtonRow>

            </ActionBlock>
        )

    } else if (!deposit.nextPeriod.sentToDepositor){

        return (
            <ActionBlock header={t('pages-portfolio-bank.nextPeriodHeader')}>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo1', {date: DateOutput.formatVerboseMonth(nextFixedInterestPeriodStartDate)})}
                </p>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo2', {date: DateOutput.formatDateTime(toDateAtNoon(nextFixedInterestPeriodStartDate))})}
                </p>
                <div className={classNames(styles.borderedSection, styles.documentSection)}>

                    <p>
                        {t('pages-portfolio-bank.nextPeriodDocumentReady')}
                    </p>

                    <div className={styles.inputfields}>
                        <DocumentLink name={t('pages-portfolio-bank.nextPeriodDocumentName')} link={`${API_BASE_URL}${document?.link}`}/>
                    </div>
                </div>

                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo3', {date: DateOutput.formatVerboseMonth(deadline)})}
                </p>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo4', {date: DateOutput.formatVerboseMonth(deposit.currentPeriod.terminationDate)})}
                </p>

                <ButtonRow>
                    <Button onClick={submitDocument}>
                        {t('pages-portfolio-bank.nextPeriodSendButtonText')}
                    </Button>
                </ButtonRow>
            </ActionBlock>
        )

    } else {

        return (
            <ActionBlock header={t('pages-portfolio-bank.nextPeriodHeader')}>
                <p>
                    {t('pages-portfolio-bank.nextPeriodSentInfo1')}
                </p>
                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo2', {date: DateOutput.formatDateTime(toDateAtNoon(nextFixedInterestPeriodStartDate))})}
                </p>
                <div className={classNames(styles.borderedSection, styles.documentSection)}>

                    <div className={styles.inputfields}>
                        <DocumentLink name={t('pages-portfolio-bank.nextPeriodDocumentName')} link={`${API_BASE_URL}${document?.link}`}/>
                    </div>
                </div>

                <p>
                    {t('pages-portfolio-bank.nextPeriodInfo4', {date: DateOutput.formatVerboseMonth(deposit.currentPeriod.terminationDate)})}
                </p>
            </ActionBlock>
        )
    }
}


function toDateAtNoon(date: string | Date) {
    const newDate = new Date(date)
    newDate.setHours(12)
    return newDate
}
