import { SILVER_GRAY } from "#app/colors/colors";
import { InterestOutput, PageLayout } from "#app/components";
import restEndpoint from '#app/services/rest/rest-endpoint';
import useTableSort from "#app/services/useTableSort";
import { StaggData } from "#app/state/stagg";
import { Box, Checkbox, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatStaggData } from "./charts/formatStaggData";
import { LineChart } from "./charts/LineChart";
import MarketReportBarChart from "./charts/MarketReportBarChart";
import PPR1_2024_02 from "./images/2024-02/pengepolitisk-rapport-1.jpg";
import PPR2_2024_02 from "./images/2024-02/pengepolitisk-rapport-2.jpg";

const PPR_IMAGES = [
    {
        date: "2024-02",
        images: [PPR1_2024_02, PPR2_2024_02]
    }
]

type ReportDataType = {
    "#": number,
    "BANK": string,
    "SPAREKONTO_UTEN_BINDING": string,
    "SPAREKONTO_UTEN_BINDING_BETINGELSER": string,
    "SPAREKONTO_MED_BINDING": string,
    "SPAREKONTO_MED_BINDING_BETINGELSER": string,
    "FASTRENTE_6_MND": string,
    "FASTRENTE_6_MND_BETINGELSER": string,
    "FASTRENTE_12_MND": string,
    "FASTRENTE_12_MND_BETINGELSER": string,
    "KJERNEOMRÅDET_FYLKE": string,
    "KJERNEOMRÅDET_LANDSDEL": string,
    "LINK": string
}


type FilteredBanksType = Omit<ReportDataType, "SPAREKONTO_UTEN_BINDING" | "SPAREKONTO_MED_BINDING" | "FASTRENTE_6_MND" | "FASTRENTE_12_MND"> & {
    "SPAREKONTO_UTEN_BINDING": number,
    "SPAREKONTO_MED_BINDING": number,
    "FASTRENTE_6_MND": number,
    "FASTRENTE_12_MND": number
}
type BankTableRowType = {
    "BANK": string,
    "SPAREKONTO_UTEN_BINDING_BETINGELSER": string,
    "SPAREKONTO_MED_BINDING_BETINGELSER": string,
    "FASTRENTE_6_MND_BETINGELSER": string,
    "FASTRENTE_12_MND_BETINGELSER": string
    "SPAREKONTO_UTEN_BINDING": number,
    "SPAREKONTO_MED_BINDING": number,
    "FASTRENTE_6_MND": number,
    "FASTRENTE_12_MND": number,
    "KJERNEOMRÅDET_FYLKE": string,
    "KJERNEOMRÅDET_LANDSDEL": string
}

type InterestColumnsType = "SPAREKONTO_UTEN_BINDING" | "SPAREKONTO_MED_BINDING" | "FASTRENTE_6_MND" | "FASTRENTE_12_MND"
type BankTableKeysType = [
    "BANK",
    "SPAREKONTO_UTEN_BINDING",
    "SPAREKONTO_MED_BINDING",
    "FASTRENTE_6_MND",
    "FASTRENTE_12_MND",
    "KJERNEOMRÅDET_FYLKE",
    "KJERNEOMRÅDET_LANDSDEL"
]

type DescriptionField = "SPAREKONTO_UTEN_BINDING_BETINGELSER" | "SPAREKONTO_MED_BINDING_BETINGELSER" | "FASTRENTE_6_MND_BETINGELSER" | "FASTRENTE_12_MND_BETINGELSER"

const isDescription = (columnName: DescriptionField | string) => {
    return columnName === "SPAREKONTO_UTEN_BINDING_BETINGELSER" || columnName === "SPAREKONTO_MED_BINDING_BETINGELSER" || columnName === "FASTRENTE_6_MND_BETINGELSER" || columnName === "FASTRENTE_12_MND_BETINGELSER"
}

const getDescription = (columName: string) : DescriptionField | "" => {
    switch (columName) {
        case "SPAREKONTO_UTEN_BINDING":
            return "SPAREKONTO_UTEN_BINDING_BETINGELSER"
        case "SPAREKONTO_MED_BINDING":
            return "SPAREKONTO_MED_BINDING_BETINGELSER"
        case "FASTRENTE_6_MND":
            return "FASTRENTE_6_MND_BETINGELSER"
        case "FASTRENTE_12_MND":
            return "FASTRENTE_12_MND_BETINGELSER"
        default:
            return ""
    }
}

const xcelPrecentageToNumber = (percentage: string) : number => {
    return parseFloat(percentage.replace(",", ".").replace(" %", ""))
}

function excludeDataSeriesByName(data: StaggData, namesToExclude: string[]) : StaggData {
    return data.map(item => {
        const filteredSeries = item.series.filter(series => !namesToExclude.includes(series.tags.name?.toLowerCase()));
        return {
            ...item,
            series: filteredSeries
        }
    }).filter(item => item.series.length > 0);
}
export default function BankMarketReport({reportData, date} : {reportData: ReportDataType[], date: string}) {
    const dispatch = useDispatch()
    const [selectedRegion, setSelectedRegion] = useState<string[]>([])
    const [majorBankData, setMajorBankData] = useState<StaggData | null>()
    const [niborData, setNiborData] = useState<StaggData | null>()
    const navigate = useNavigate()
    const location = useLocation()

    const handleRegionChange = (event: SelectChangeEvent<typeof selectedRegion>) => {
        const {
            target: { value },
        } = event;
        setSelectedRegion(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        )
    };
    useEffect(() => {
        restEndpoint(dispatch).getDepositInterestWithSsbAndMajorBanks("startDate=2023-06-01&endDate=" + date).then((data: StaggData) => {
            setMajorBankData(excludeDataSeriesByName(data, ["fixrate"]))
        }).catch((error) => {
            console.log(error)
        })

        restEndpoint(dispatch).getNibor("startDate=2023-06-01&endDate=" + date).then((data: StaggData) => {
            setNiborData(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [dispatch, date])
    const regions = reportData
        .map((row) => {
            const regionString = row["KJERNEOMRÅDET_FYLKE"]

            // Check if the regionString contains a comma
            if (regionString.includes(",")) {
                // If multiple regions are listed, split them into an array
                return regionString.split(",").map((region) => region.trim());
            } else {
                // If only one region is listed, return it as an array with a single element
                return [regionString.trim()];
            }
        })
        .flat()

    const regionOptions = [...new Set(regions)]
    const filteredBanks = reportData.map((row) => {
        return {
            ...row,
            "FASTRENTE_12_MND": xcelPrecentageToNumber(row["FASTRENTE_12_MND"]),
            "FASTRENTE_6_MND": xcelPrecentageToNumber(row["FASTRENTE_6_MND"]),
            "SPAREKONTO_MED_BINDING": xcelPrecentageToNumber(row["SPAREKONTO_MED_BINDING"]),
            "SPAREKONTO_UTEN_BINDING": xcelPrecentageToNumber(row["SPAREKONTO_UTEN_BINDING"])
        } as FilteredBanksType
    }).filter((row) => selectedRegion.length === 0 || selectedRegion.find(region => region.toLowerCase() === row["KJERNEOMRÅDET_FYLKE"].toLowerCase()))

    const getHighestInterestRate = (rowName: InterestColumnsType) => {
        return filteredBanks
            .map((row) => row[rowName])
            .filter((interestRate) => !isNaN(interestRate))
            .reduce((acc, interest) => Math.max(acc, interest), 0)
    }

    const highestFloatingInterestRate = Math.max(getHighestInterestRate("SPAREKONTO_UTEN_BINDING"), getHighestInterestRate("SPAREKONTO_MED_BINDING"))
    const highestFixedInterestRate = Math.max(getHighestInterestRate("FASTRENTE_12_MND"), getHighestInterestRate("FASTRENTE_6_MND"))

    const getAverageInterest = (rowName: InterestColumnsType) => {
        const filteredArr = filteredBanks.filter((row) => !isNaN(row[rowName])).map((row) => row[rowName])
        return filteredArr.reduce((acc, interest) => acc + interest, 0) / filteredArr.length
    }

    const averageFloatingInterestRate = (getAverageInterest("SPAREKONTO_UTEN_BINDING") + getAverageInterest("SPAREKONTO_MED_BINDING")) / 2
    const averageFixedInterestRate = (getAverageInterest("FASTRENTE_12_MND") + getAverageInterest("FASTRENTE_6_MND")) / 2

    const interestData = {
        "Høyeste fastrente": highestFixedInterestRate,
        "Høyeste flytende rente": highestFloatingInterestRate,
        "Snittrente – flytende": averageFloatingInterestRate,
        "Snittrente – fastrente": averageFixedInterestRate
    }

    const bankTableRows : BankTableRowType[] = filteredBanks.map((row) => {
        return {
            "BANK": row["BANK"],
            "SPAREKONTO_UTEN_BINDING": row["SPAREKONTO_UTEN_BINDING"],
            "SPAREKONTO_UTEN_BINDING_BETINGELSER": row["SPAREKONTO_UTEN_BINDING_BETINGELSER"],
            "SPAREKONTO_MED_BINDING": row["SPAREKONTO_MED_BINDING"],
            "SPAREKONTO_MED_BINDING_BETINGELSER": row["SPAREKONTO_MED_BINDING_BETINGELSER"],
            "FASTRENTE_6_MND": row["FASTRENTE_6_MND"],
            "FASTRENTE_6_MND_BETINGELSER": row["FASTRENTE_6_MND_BETINGELSER"],
            "FASTRENTE_12_MND": row["FASTRENTE_12_MND"],
            "FASTRENTE_12_MND_BETINGELSER": row["FASTRENTE_12_MND_BETINGELSER"],
            "KJERNEOMRÅDET_FYLKE": row["KJERNEOMRÅDET_FYLKE"],
            "KJERNEOMRÅDET_LANDSDEL": row["KJERNEOMRÅDET_LANDSDEL"]
        }
    })

    const banksWithFloatingInterestRate = filteredBanks.filter(row => !isNaN(row["SPAREKONTO_UTEN_BINDING"]) || !isNaN(row["SPAREKONTO_MED_BINDING"]))
    const banksWithFixedInterestRate = filteredBanks.filter(row => !isNaN(row["FASTRENTE_6_MND"]) || !isNaN(row["FASTRENTE_12_MND"]))

    const bankTableKeys : BankTableKeysType = ["BANK", "SPAREKONTO_UTEN_BINDING", "SPAREKONTO_MED_BINDING", "FASTRENTE_6_MND", "FASTRENTE_12_MND", "KJERNEOMRÅDET_FYLKE", "KJERNEOMRÅDET_LANDSDEL"]
    const {sortedRows, sortDirection, sortKey, setSorting} = useTableSort<BankTableRowType>(bankTableRows, "SPAREKONTO_UTEN_BINDING", "desc")

    return (
        <>
            <Typography variant="h1" mb={2}>Markedsanalyse</Typography>
            <Stack mt={2} mb={4} maxWidth="75rem">
                <Typography variant="caption">Prisene som er hentet ut i denne rapporten er hentet fra bankenes egne nettsider {date}.</Typography>
                <Typography variant="caption">Enkelte banker oppgir ikke om rentetilbudene gjelder for bedriftsmarkedet. Der dette ikke er tilfelle så går Fixrate ut ifra at tilbudet på bankens nettsider gjelder for alle type kunder.</Typography>
            </Stack>
            <Stack spacing={3} maxWidth={"140rem"}>
                <Grid container spacing={2} pb={3}>
                    <Grid item xs={12} md={3}>
                        <InputLabel>Velg region</InputLabel>
                        <Select
                            value={selectedRegion}
                            labelId="multiple-checkbox-label"
                            id="multiple-checkbox"
                            placeholder="Velg region"
                            multiple
                            renderValue={(selected) => selected.join(', ')}
                            onChange={handleRegionChange}
                        >
                            {regionOptions.map((region, index) => (
                                <MenuItem value={region} key={index}>
                                    <Checkbox size="small" checked={!!selectedRegion.find(option => option.toLowerCase() === region.toLowerCase())} />
                                    <ListItemText sx={{"& .MuiListItemText-primary" : { fontSize: "1.4rem", fontWeight: "500" }}} primary={region} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputLabel>Periode</InputLabel>
                        <Select
                            value={location.pathname.split("/").pop()}
                            placeholder="Velg periode"
                            onChange={(event: SelectChangeEvent<string>) => {
                                navigate(`/analytics/bank/market-report/${event.target.value}`)
                            }}
                            >
                                <MenuItem value={"2024-01"}>Januar 2024</MenuItem>
                                <MenuItem value={"2024-02"}>Februar 2024</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {
                        Object.keys(interestData).map((key, index) => (
                            <Grid item xs={12} md={12 / Object.keys(interestData).length} key={index}>
                                <Paper sx={{p: 3}}>
                                    <Typography>{key}</Typography>
                                    <Typography variant="interestBig">{InterestOutput.format(interestData[key])}</Typography>
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>
                <Paper sx={{p: 4}}>
                    <Box mb={2}>
                        <Typography variant="h2">Prisoversikt</Typography>
                        <Typography color={SILVER_GRAY[500]}>Samling av ulike priser på rentetilbud offentlig tilgjengelig</Typography>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                { bankTableKeys.map((columnName, index) => (
                                    <TableCell key={index}>
                                        <TableSortLabel
                                            active={sortKey === columnName}
                                            direction={sortKey === columnName ? (sortDirection === 'asc' ? 'asc' : 'desc') : 'asc'}
                                            onClick={() => setSorting(columnName)}
                                        >
                                            {columnName.charAt(0).toUpperCase() + columnName.replace(/_/g, " ").toLowerCase().slice(1)}
                                            {sortKey === columnName ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedRows.map((row, index) => (
                                <TableRow key={index}>
                                    { Object.keys(row).map((columnName, index) => {
                                        if (isDescription(columnName)) {
                                            return null
                                        }
                                        const formattedValue = typeof row[columnName] === "number" && !isNaN(row[columnName]) ? InterestOutput.formatWithDecimals(row[columnName], 2) : row[columnName]
                                        const description = getDescription(columnName)
                                        return (
                                            <TableCell key={index}>
                                                {typeof row[columnName] === "number" && isNaN(row[columnName]) ? "-" : formattedValue}
                                                { row[description] && (
                                                    <Tooltip title={row[description]} key={index}>
                                                        <IconButton color="primary">
                                                            <i className="ri-information-line"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
                <Paper sx={{p: 4}}>
                    <Box mb={2}>
                        <Typography variant="h2">Fastrente</Typography>
                        <Typography color={SILVER_GRAY[500]}>Fastrentetilbud med 6 mnd og 12 mnd løpetid</Typography>
                    </Box>
                    <MarketReportBarChart
                        labels={banksWithFixedInterestRate.map((row) => row["BANK"])}
                        dataSets={[
                            {
                                data: banksWithFixedInterestRate.map((row) => row["FASTRENTE_6_MND"]),
                                label: "FASTRENTE_6_MND"
                            },
                            {
                                data: banksWithFixedInterestRate.map((row) => row["FASTRENTE_12_MND"]),
                                label: "FASTRENTE_12_MND"
                            }
                        ]} />
                </Paper>
                <Paper sx={{p: 4}}>
                    <Box mb={2}>
                        <Typography variant="h2">Flytende rente</Typography>
                        <Typography color={SILVER_GRAY[500]}>Rentenivå på sparekonto med og uten binding i ulike norske banker</Typography>
                    </Box>
                    <MarketReportBarChart
                        labels={banksWithFloatingInterestRate.map((row) => row["BANK"])}
                        dataSets={[
                            {
                                data: banksWithFloatingInterestRate.map((row) => row["SPAREKONTO_UTEN_BINDING"]),
                                label: "SPAREKONTO_UTEN_BINDING"
                            },
                            {
                                data: banksWithFixedInterestRate.map((row) => row["SPAREKONTO_MED_BINDING"]),
                                label: "SPAREKONTO_MED_BINDING"
                            }
                        ]} />
                </Paper>
                <Paper sx={{p: 4}}>
                    <Box mb={2}>
                        <Typography variant="h2">Prisutvikling hos storbankene</Typography>
                        <Typography color={SILVER_GRAY[500]}>Samling av rentetilbud for bedrifter fra et utvalg storbanker</Typography>
                    </Box>
                    { majorBankData && (
                        <LineChart
                            chartData={formatStaggData(
                                majorBankData,
                                [0],
                                true
                            )}
                        />
                    )}
                </Paper>
                <Paper sx={{p: 4}}>
                    <Box mb={2}>
                        <Typography variant="h2">NIBOR og styringsrente</Typography>
                        <Typography color={SILVER_GRAY[500]}>Utvikling i NIBOR3M og styringsrente i perioden</Typography>
                    </Box>
                    { niborData && (
                        <LineChart
                            chartData={formatStaggData(
                                niborData,
                                [],
                                true
                            )}
                        />
                    )}
                </Paper>
                <Paper sx={{p: 4}}>
                 <Box mb={2}>
                        <Typography variant="h2">Pengepolitisk rapport</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {PPR_IMAGES.find(item => item.date === location.pathname.split("/").pop())?.images.map((image, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <img style={{maxWidth: "100%"}} src={image} alt={`Pengepolitisk rapport ${index + 1}`} />
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Stack>
        </>
    );
}
