import PropTypes from 'prop-types'

const CheckedIcon = (props) => (
    <svg viewBox="0 0 50 50" className={props.className}>
        <circle cx="25" cy="25" r="25"/>
        <polyline style={{fill: 'none', stroke: '#FFFFFF', strokeWidth: 4, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10}} points="38,15 22,33 12,25 "/>
    </svg>
)

CheckedIcon.propTypes = {
    className: PropTypes.string,
}

export default CheckedIcon