import apiRequest from './apiRequest'

class PotentialInterestApi {
    static getPotentialInterest() {
        return apiRequest('pubstat/potential-interest')
            .then(res => res.ok ? res.json() : [])
            .catch((e) => console.error('Fixrate Query unavailable when trying to fetch potential interest', e))
    }

    static getCalculatedInterest(volume: number): Promise<number> {
        return apiRequest(`pubstat/potential-interest?potentialVolume=${volume}`)
            .then(response => {
                return response.ok && response.json()
            })
            .catch((e) => console.error('Fixrate Query unavailable when trying to fetch potential interest', e))
    }
}

export default PotentialInterestApi
