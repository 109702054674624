import scrollToAnchor from '#app/scroll-to-anchor'
import { PageHeader, PageLayout } from '#components'
import * as selectors from '#state/selectors'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IdentificationDocument from './IdentificationDocument'
import LanguageSettings from './LanguageSettings'
import NotificationSettings from './NotificationSettings'
import PinCodeSettings from './PinCodeSettings'
import ProfileEditor from './ProfileEditor'

interface ProfileProps {
    user: { fullName: string }
    session: { authenticated: boolean }
    userNeedsIdDoc: boolean
    profile: unknown
}

const Profile = ({user, session, userNeedsIdDoc, profile}: ProfileProps) => {
    const location = useLocation()
    const birthDate = '';

    //Scrolling down to the relevant section when # is provided (email notifications)
    useEffect(() => {
        scrollToAnchor()
    }, [location.hash])

    // We don't want to show profile info for unauthenticated users
    if (!session.authenticated) return null

    return (
        <>
            <PageHeader icon="ri-account-circle-line" title={user.fullName} subtitle={birthDate}/>
            <PageLayout>
                {profile && userNeedsIdDoc && <IdentificationDocument/>}
                {profile && <ProfileEditor/>}
                <LanguageSettings/>
                <PinCodeSettings/>
                <NotificationSettings/>
            </PageLayout>
        </>
    )
}

const mapStateToProps = state => ({
    user: selectors.profileSelector(state),
    session: state.session,
    profile: state.profile,
    userNeedsIdDoc: selectors.userNeedsIdDoc(state),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
