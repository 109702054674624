import { PURPLE } from '#app/colors/colors';
import { CurrencyTabber } from '#app/components/CurrencyTabber/CurrencyTabber';
import { useEndpoint } from '#app/services/beta';
import { sendExceptionToSentry } from '#app/services/sentry';
import usePortfolio from '#app/services/usePortfolio';
import { Animated, DateOutput, LoadingSpinner, PageHeader, PageLayout } from '#components';
import { CheckboxTask } from '#pages/Inbox/CheckboxTask';
import { sortDatesDescending } from '#services/dateandtime';
import { messageTaskActionRequiredByUserSelector } from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { MessageCategory, MessageDto, TaskDto } from '@fixrate/fixrate-query';
import { Button, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './Inbox.module.scss';
import useMessageData from './useMessageData';
import useUiSetting from "#services/useUiSetting";

interface CategoryConfig {
    remixIcon: string
    labelKey: string
    color: string
}

const CATEGORIES: { [category in MessageCategory]: CategoryConfig } = {
    ORDERS: {
        remixIcon: "ri-message-2-line",
        labelKey: "orders",
        color: PURPLE[500]
    },
    ORGANISATIONS: {
        remixIcon: "ri-building-line",
        labelKey: "organizations",
        color: PURPLE[500]
    },
    DEPOSIT_TERMINATIONS: {
        remixIcon: "ri-timer-line",
        labelKey: "terminations",
        color: PURPLE[500]
    },
    FIXRATE_NEWS: {
        remixIcon: "ri-notification-2-line",
        labelKey: "news",
        color: PURPLE[500]
    },
    CUSTOMER_DECLARATIONS: {
        remixIcon: "ri-file-list-3-line",
        labelKey: "customerDeclarations",
        color: PURPLE[500],
    },
    ADS: {
        remixIcon: "ri-price-tag-3-line",
        labelKey: "ads",
        color: PURPLE[500]
    },
    INTEREST_RATE_CHANGES: {
        remixIcon: "ri-percent-line",
        labelKey: "interestChanges",
        color: PURPLE[500]
    },
    DEPOSIT_EXTENSIONS: {
        remixIcon: "ri-arrow-go-forward-line",
        labelKey: "extensions",
        color: PURPLE[500]
    },
    SETTLEMENT_ACCOUNTS: {
        remixIcon: "ri-bank-line",
        labelKey: "settlementAccounts",
        color: PURPLE[500]
    },
    ACCOUNT_STATEMENTS: {
        remixIcon: "ri-hand-coin-line",
        labelKey: "accountReporting",
        color: PURPLE[500]
    },
    DEPOSITOR_OFFERS: {
        remixIcon: "ri-arrow-go-forward-line",
        labelKey: "depositorOffers",
        color: PURPLE[500]
    },
    DEPOSIT_DEVIATIONS: {
        remixIcon: "ri-alert-line",
        labelKey: "depositDeviations",
        color: PURPLE[500]
    },
    FUND: {
        remixIcon: "ri-building-line",
        labelKey: "fund",
        color: PURPLE[500]
    },
    SECURITY: {
        remixIcon: "ri-shield-line",
        labelKey: "security",
        color: PURPLE[500]
    },
};

const AVAILABLE_CATEGORIES = [
    'ORDERS',
    'DEPOSIT_TERMINATIONS',
    'CUSTOMER_DECLARATIONS',
    'ORGANISATIONS',
    'INTEREST_RATE_CHANGES',
    'DEPOSIT_EXTENSIONS',
    'ACCOUNT_STATEMENTS',
]

const BANK_CATEGORIES = [
    'ADS',
    'DEPOSITOR_OFFERS',
    'DEPOSIT_DEVIATIONS',
]

const DEPOSITOR_CATEGORIES = [
    'SETTLEMENT_ACCOUNTS',
]

export default function Inbox() {
    const {t} = useTranslation()

    const actionRequiredByUser: (task: TaskDto) => boolean = useSelector(messageTaskActionRequiredByUserSelector)

    const [selectedCategory, setSelectedCategory] = useUiSetting('inbox.filter.selectedCategory', 'ALL')
    const [justActiveTasks, setJustActiveTasks] = useState(true)
    const { setPortfolioDepositorSession } = useEndpoint()
    const bank = useSelector(state => state.bank)
    const messages = useSelector(state => state.messages)
    const loaded = useSelector(state => state.loaded)
    const organisationType = useSelector(state => state.session?.organisationType)
    const portfolios = useSelector(state => state.depositor?.portfolios)
    const portfolio = usePortfolio()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const association = useSelector(state => state.session?.association)
    const { setCurrencyBankSession } = useEndpoint()
    const currencies = bank?.currencies || portfolios?.map(p => p.currency) || []
    const selectedCurrency = association?.currentCurrency || portfolio?.currency

    const filteredMessages = messages
        .filter(message => !selectedCategory || selectedCategory === 'ALL' || message?.messageCategory === selectedCategory)
        .sort((m1, m2) => {
            if (justActiveTasks) {
                const m1Action = actionRequiredByUser(m1.task)
                const m2Action = actionRequiredByUser(m2.task)
                if (m1Action && !m2Action) return -1
                if (!m1Action && m2Action) return 1
            }

            return sortDatesDescending(m1.time, m2.time)
        })

    function handleCategoryChange(event) {
        if (event.target.value === 'active') {
            setJustActiveTasks(true)
        } else {
            setSelectedCategory(event.target.value)
        }
    }

    let categories = [...AVAILABLE_CATEGORIES]
    if (organisationType === 'DEPOSITOR') {
        categories = [...AVAILABLE_CATEGORIES, ...DEPOSITOR_CATEGORIES]
    } else if (organisationType === 'BANK') {
        categories = [...AVAILABLE_CATEGORIES, ...BANK_CATEGORIES]
    }

    const switchCurrency = (currency: string) => {
        if (association?.organisationType === "DEPOSITOR") {
            if (currency !== portfolio?.currency) {
                const portfolioWithCurrency = portfolios.find(p => p.currency === currency)
                setPortfolioDepositorSession(portfolioWithCurrency.id).catch((err) => {
                    sendExceptionToSentry(err, {currency: currency, reason: 'Failed to switch depositor currency / portfolio in session'})
                })
            }
        }
        if (association?.organisationType === "BANK") {
            if (currency !== association?.currentCurrency) {
                setCurrencyBankSession(currency).catch((err) => {
                    sendExceptionToSentry(err, {currency: currency, reason: 'Failed to switch bank currency in session'})
                })
            }
        }
    }

    return (
        <>
            <PageHeader icon="ri-inbox-fill" title={t('pages-inbox.inbox')}/>
            <PageLayout>
                <Stack mx={{xs: 2, md: 0}} spacing={2} flexWrap={"wrap"} direction="row" alignItems={"flex-end"} justifyContent="space-between" maxWidth={"105.8rem"}>
                    <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="space-between">
                        { currencies.length > 1 && ( <CurrencyTabber currencies={currencies} selectedCurrency={selectedCurrency} switchCurrency={switchCurrency}/>
                        )}
                        <Select sx={{maxWidth: { xs: "25rem", md: "none" }}} value={selectedCategory} onChange={handleCategoryChange}>
                            <MenuItem sx={{alignItems: "center"}} value={"ALL"}>
                                <ListItemIcon sx={{minHeight: "auto", minWidth: "auto", backgroundColor: "transparent", color: PURPLE[500]}}><i className={"ri-filter-line"}/></ListItemIcon>
                                <ListItemText sx={{display: "inline-flex", "& .MuiListItemText-primary": { fontSize: "1.4rem" }}}>{t('pages-inbox.allMessages')}</ListItemText>
                            </MenuItem>
                            {categories.map(category => {
                                const {labelKey, remixIcon} = CATEGORIES[category]
                                return (
                                    <MenuItem sx={{alignItems: "center"}} key={category} value={category}>
                                        <ListItemIcon sx={{minHeight: "auto", minWidth: "auto", backgroundColor: "transparent", color: PURPLE[500]}}><i className={remixIcon}/></ListItemIcon>
                                        <ListItemText sx={{display: "inline-flex", "& .MuiListItemText-primary": { fontSize: "1.4rem" }}}>{t('pages-inbox.' + labelKey)}</ListItemText>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Stack>
                    <Button startIcon={<i className='ri-mail-line'/>} href="/profile#email-notifications" size="small" variant={'outlined'}>{t('pages-inbox.emailNotification')}</Button>
                </Stack>
                <Stack maxWidth={"106rem"} mt={4}>
                    <Stack direction="row" mb={1} ml={1} mr={1}>
                        <span className={styles.icon}> </span>
                        <Typography variant="filterLabel" className={classNames(styles.messageTitle, styles.listHeading)}>{t('pages-inbox.message')}</Typography>
                        { !isMobile && <Typography variant="filterLabel" className={classNames(styles.timeText, styles.listHeading)}>{t('pages-inbox.time')}</Typography> }
                        <Typography variant="filterLabel" className={classNames(styles.categoryText, styles.listHeading)}>{t('pages-inbox.category')}</Typography>
                    </Stack>
                    {!loaded.messages && <LoadingSpinner text={t('pages-inbox.loadingMessages')}/>}
                    <Stack spacing={1} data-cy="inboxMessageList">
                        {loaded.messages && filteredMessages.length === 0 && <Typography variant="emptyState">{t('pages-inbox.noMessages')}</Typography>}
                        {filteredMessages.map(message => (
                            <Animated key={message.id}>
                                <Message message={message} justActiveTasks={justActiveTasks}/>
                            </Animated>
                        ))}
                    </Stack>
                </Stack>
            </PageLayout>
        </>
    )
}

function getMessageTaskIndicator(resolved, actionRequiredByUser, style) {
    if (resolved === null) return null

    if (!resolved) {
        return <div className={classNames(styles.taskIndicator, actionRequiredByUser ? styles.taskIndicatorActionRequired : styles.taskIndicatorUnsolved)} style={style}/>
    }

    if (resolved) {
        style.left -= 5
        style.top -= 0
        return (
            <div className={classNames(styles.taskIndicator, styles.taskIndicatorIcon)} style={style}>
                <i className={classNames(styles.taskDoneIcon, 'ri-checkbox-circle-line')}/>
            </div>
        )
    }

    return null
}

type MessageProps = {
    message: MessageDto
    justActiveTasks: boolean
}

function Message({message, justActiveTasks}: MessageProps) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const messageData = useMessageData(message)
    const {title, description, actionRequiredByUser, taskDescription, cta, checkboxTaskLabels, taskTarget, taskResolvedByUserName} = messageData
    const [expanded, setExpanded] = useState(justActiveTasks && actionRequiredByUser)

    useEffect(() => {
        if (expanded !== justActiveTasks && actionRequiredByUser) {
            setExpanded(justActiveTasks && actionRequiredByUser)
        }
    }, [justActiveTasks, expanded, actionRequiredByUser])

    const messageTime = new Date(message.time)
    const now = new Date()
    const minutes = differenceInMinutes(now, messageTime)
    const timeText = minutes < 60
        ? minutes === 0
            ? t('pages-inbox.now')
            : t('pages-inbox.minuteAgo', {count: minutes})
        : differenceInHours(now, startOfDay(messageTime)) < 48
            ? isSameDay(messageTime, now)
                ? t('pages-inbox.todayAt', {time: DateOutput.formatTime(messageTime)})
                : t('pages-inbox.yesterdayAt', {time: DateOutput.formatTime(messageTime)})
            : DateOutput.formatDate(messageTime)

    const category = CATEGORIES[message.messageCategory]

    return (
        <div
            id={`message-${message.id}`}
            data-cy="inboxMessage"
            className={classNames(
                styles.listElement,
                message.task.resolved === false ? styles.taskNotDone : undefined,
                justActiveTasks && actionRequiredByUser ? styles.actionFilter : undefined,
            )}
        >
            <div className={classNames(styles.messageTitleBase, expanded ? styles.messageTitleBaseExpanded : undefined)} onClick={() => setExpanded(!expanded)}>
                <div className={styles.messageTitleContainer}>
                    <i style={{color: category.color}} className={classNames(category.remixIcon, styles.remixIcon)}/>
                    <div className={styles.messageTitle}>
                        <span className={styles.titleText}>{title}</span>
                    </div>
                </div>
                <div className={styles.messageMetaContainer}>
                    <span className={classNames(styles.timeText, styles.listMeta)}>{timeText}</span>
                    <span className={classNames(styles.categoryText, styles.listMeta)}>{t('pages-inbox.' + category.labelKey)}</span>
                </div>
            </div>
            <div className={classNames(styles.messageExpansionBase, expanded ? styles.messageExpansionBaseExpanded : undefined)}>
                <div className={styles.expansionContent}>
                    <div className={styles.expansionContentInfo}>
                        {description ? description.split('\n').map((paragraph, i) => <p key={i}>{paragraph}</p>) : null}
                    </div>
                    {message.task.taskType && (
                        <div className={classNames(styles.expansionContentTask, message.task.resolved ? styles.resolvedContainer : '')}>
                            <div className={styles.contentTaskLeft}>
                                <div id={`task-${message.id}`} className={classNames(styles.taskDescriptionText, message.task.resolved && styles.resolved)}>
                                    {getMessageTaskIndicator(message.task.resolved, actionRequiredByUser, Object.assign(message.task.resolved ? {left: -26, top: -3} : {
                                        left: -26,
                                        top: 5,
                                    }, expanded ? {transform: 'scale(1,1)'} : {transform: 'scale(0,0)'}))}
                                    <span>{taskDescription}</span>
                                </div>
                                {message.task.resolved === false ? <div className={styles.taskResolveText}><i className="ri-user-line"/>
                                    <div data-cy="taskTargetText">{taskTarget}</div>
                                </div> : null}
                                {cta && (
                                    <Button
                                        data-cy="inboxActionLink"
                                        sx={{mt: '2rem', mb: '2rem'}}
                                        endIcon={<i className="ri-arrow-right-line"/>}
                                        onClick={() => {
                                            if (typeof cta.link === 'function') {
                                                cta.link()
                                            } else {
                                                navigate(cta.link)
                                            }
                                        }}
                                        variant={(message.task.resolved || !actionRequiredByUser) ? 'outlined' : 'contained'}
                                    >
                                        {cta.label}
                                    </Button>
                                )}
                                {checkboxTaskLabels && <CheckboxTask messageId={message.id} checkboxTaskLabels={checkboxTaskLabels}/>}
                            </div>
                            {message.task.resolved === true && (
                                <div className={styles.contentTaskRight}>
                                    <p className={styles.taskResolvedBy}>
                                        <i className="ri-user-line"/>
                                        <span>
                                            {t('pages-inbox.resolvedBy', {user: taskResolvedByUserName === '' ? t('common.fixrateUser') : taskResolvedByUserName})}
                                        </span>
                                    </p>
                                    <p className={styles.taskResolvedTime}><DateOutput.DateTime date={message.task.resolvedTime}/></p>
                                </div>
                            )}
                        </div>
                    )}
                    {!message.task.taskType && cta && (
                        <div className={styles.ctaContainer}>
                            <Button data-cy="viewLink"
                                sx={{mt: '2rem', mb: '2rem'}}
                                endIcon={<i className="ri-arrow-right-line"/>}
                                onClick={() => {
                                    if (typeof cta.link === 'function') {
                                        cta.link()
                                    } else {
                                        navigate(cta.link)
                                    }
                                }}
                                variant="outlined">
                                {cta.label}
                            </Button>
                        </div>
                    )}

                    {description === '---' && ( // DEBUG
                        <>
                            <span>{message.messageType} [ {Object.keys(message.messageParameters).filter(mp => !!message.messageParameters[mp]).join(' | ')} ]</span>
                            <textarea defaultValue={JSON.stringify(message, undefined, 2) + JSON.stringify(messageData, undefined, 2)}/>
                        </>
                    )}
                </div>
            </div>
            {getMessageTaskIndicator(message.task.resolved, actionRequiredByUser, Object.assign(message.task.resolved ? {top: 20, zIndex: -1} : {
                top: 25,
                zIndex: -1,
            }, expanded ? {left: 13, transform: 'scale(0,0)'} : {left: -30, transform: 'scale(1,1)'}))}
        </div>
    );
}
