import {useMemo} from "react";

type OrderAmountLimitProps = {
    // Order
    orderVolume: number,
    minOrderVolume?: number,
    // Placement
    currentPlacement?: FundPlacementSubset,
    // Share class
    selectedShareClass: FundShareClassSubset,
    otherShareClasses: FundShareClassSubset[],
}

export type OrderAmountLimitInfoResult = {
    // Order
    withinMinOrderVolume: boolean,
    // Placement
    newPlacementValue: number,
    // New placement value vs. share class limits
    withinMinInvestment: boolean,
    withinMaxInvestment: boolean,
    // Other
    otherShareClassMatching: FundShareClassSubset,
    orderIsValid: boolean
}

export interface FundPlacementSubset {
    id: string,
    fundShareClassId: string,
    currentValue: number
}

export interface FundShareClassSubset {
    id: string,
    minimumInvestment?: number,
    maximumInvestment?: number
}

export function calculateOrderLimitStatus(
    {
        orderVolume=0,
        minOrderVolume,
        currentPlacement,
        selectedShareClass,
        otherShareClasses,
    } : OrderAmountLimitProps) : OrderAmountLimitInfoResult {
    if (!selectedShareClass) {
        return {
            withinMinOrderVolume: false,
            newPlacementValue: 0,
            withinMinInvestment: false,
            withinMaxInvestment: false,
            otherShareClassMatching: null,
            orderIsValid: false
        }
    }
    // Order
    const hasMinOrderVolume = minOrderVolume !== undefined && minOrderVolume !== null
    const withinMinOrderVolume = !hasMinOrderVolume || orderVolume >= minOrderVolume
    // Placement
    const hasCurrentPlacement = currentPlacement !== undefined && currentPlacement !== null
    const currentPlacementValue = currentPlacement?.currentValue ?? 0
    const newPlacementValue = currentPlacementValue + orderVolume
    const selectedShareClassIsCurrent = hasCurrentPlacement && currentPlacement.fundShareClassId === selectedShareClass.id
    // New placement value compared to share class limits
    const minInvestment = selectedShareClass.minimumInvestment ?? 0
    const maxInvestment = selectedShareClass.maximumInvestment ?? Number.MAX_VALUE
    const withinMinInvestment = newPlacementValue >= minInvestment || (selectedShareClassIsCurrent && currentPlacementValue > 1000)
    const withinMaxInvestment = newPlacementValue <= maxInvestment
    // Other
    const otherShareClassMatching = otherShareClasses.find(sc =>
        newPlacementValue >= (sc.minimumInvestment ?? 0)
        && newPlacementValue <= (sc.maximumInvestment ?? Number.MAX_VALUE))

    const orderIsValid = (withinMaxInvestment // 1. The new fund placement must be below the maxInvestment.
            && (hasMinOrderVolume
                ? withinMinOrderVolume // 2. a) If the depositor has a minOrderVolume: the orderVolume must be at least that amount.
                : withinMinInvestment)) // 2. b) Else, if the shareclass has a minimum investment amount: the new fund placement must be at least that amount.


    return {
        withinMinOrderVolume: withinMinOrderVolume,
        newPlacementValue: newPlacementValue,
        withinMinInvestment: withinMinInvestment,
        withinMaxInvestment: withinMaxInvestment,
        otherShareClassMatching: otherShareClassMatching,
        orderIsValid: orderIsValid
    }
}

export function useOrderAmountLimitStatus(props: OrderAmountLimitProps) {
    return useMemo(() => calculateOrderLimitStatus(props), [props])
}
