import React, {useState} from "react";
import {useCommand} from "#command";
import {useDispatch} from "react-redux";
import {showConfirmationModal} from "#state/reducers/confirmationModal";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "#components";
import {LoadingButton} from "@mui/lab";
import {PlacementRow} from "#pages/FundAdminOverview/FundPlacements/AdminFundPlacementTable";
import {useTranslation} from "react-i18next";
import {FundShareClassDto} from "@fixrate/fixrate-query";

const translationPath = 'pages.adminFundPlacements.changeShareClassDialog'

const DetailRow = ({children, title} : {children: React.ReactNode, title: string}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', borderBottom: 'lightgray 1px solid', marginBottom: 4, paddingBottom: 4}}>
            <Typography variant={'body1'}>{title}:</Typography>
            {children}
        </Box>
    )
}

export default function ChangeFundShareClassDialog({pr, onClose}: {pr: PlacementRow, onClose: () => void}) {
    const {t} = useTranslation()
    const [fromShareClassId, setFromShareClassId] = useState<string | null>(pr.fundShareClassId)
    const [toShareClassId, setToShareClassId] = useState<string | null>(null)
    const [submitting, setSubmitting] = useState(false)
    const [transactionDate, setTransactionDate] = useState<Date | null>(null)
    const {changeFundPlacementShareClass} = useCommand()
    const dispatch = useDispatch()

    function closeDialog() {
        setFromShareClassId(pr.fundShareClassId)
        setToShareClassId(null)
        setTransactionDate(null)
        onClose()
    }

    function onFromShareClassIdChange(id: string) {
        if (pr.fundShareClasses.some(sc => sc.id === id)) {
            setFromShareClassId(id)
        }
    }

    function onToShareClassIdChange(id: string) {
        if (pr.fundShareClasses.some(sc => sc.id === id)) {
            setToShareClassId(id)
        }
    }

    async function handleSubmit() {
        if (!pr) return
        const fromShareClass = pr.fundShareClasses.find(sc => sc.id === fromShareClassId)
        const toShareClass = pr.fundShareClasses.find(sc => sc.id === toShareClassId)
        if (!fromShareClass || !toShareClass) {
            return
        }
        setSubmitting(true)
        const {waitForCommand} = await changeFundPlacementShareClass(pr.id, fromShareClass.id, toShareClass.id, transactionDate)
        const success = await waitForCommand()
        if (success) {
            dispatch(showConfirmationModal({
                title: t(`${translationPath}.confirmationModal.title`),
                text: t(`${translationPath}.confirmationModal.text`, {fromShareClassName: fromShareClass.fullName, toShareClassName: toShareClass.fullName}),
                buttonText: t(`${translationPath}.confirmationModal.buttonText`)
            }))
            closeDialog()
        }
        setSubmitting(false)
    }

    function isSubmitDisabled(): boolean {
        return !toShareClassId || toShareClassId === fromShareClassId || !transactionDate
    }

    const shareClassSelectionIsValid = toShareClassId && fromShareClassId && toShareClassId !== fromShareClassId

    return (
        <Dialog open={pr !== null} onClose={closeDialog} fullWidth maxWidth={'sm'}>
            <DialogTitle>{t(`${translationPath}.title`)}</DialogTitle>
            {pr && (
                <DialogContent>
                    <DetailRow title={t(`${translationPath}.depositor`)}>
                        {pr.depositorName}
                    </DetailRow>
                    <DetailRow title={t(`${translationPath}.fromShareClass`)}>
                        <ShareClassDropdown
                            shareClasses={pr.fundShareClasses}
                            value={fromShareClassId}
                            disabledItemText={t(`${translationPath}.selectFromShareClass`)}
                            onShareClassSelect={onFromShareClassIdChange}
                        />
                    </DetailRow>
                    <DetailRow title={t(`${translationPath}.toShareClass`)}>
                        <ShareClassDropdown
                            shareClasses={pr.fundShareClasses}
                            value={toShareClassId}
                            disabledItemText={t(`${translationPath}.selectToShareClass`)}
                            onShareClassSelect={onToShareClassIdChange}
                        />
                    </DetailRow>
                    {shareClassSelectionIsValid && (
                        <div>
                            <DetailRow title={t(`${translationPath}.transactionDate`)}>
                                <DatePicker
                                    id="transactionDateInput"
                                    selected={transactionDate}
                                    onChange={date => setTransactionDate(date)}
                                    customInput={<TextField label={t(`${translationPath}.transactionDate`)}
                                                            helperText={!transactionDate && t(`${translationPath}.transactionDateHelperText`)}
                                                            error={!transactionDate}
                                    />}
                                />
                            </DetailRow>
                            <DialogActions title={t(`${translationPath}.performChanges`)}>
                                <Button data-cy="cancelButton" onClick={closeDialog}>{t(`${translationPath}.cancel`)}</Button>
                                <LoadingButton
                                    variant={'contained'}
                                    disabled={isSubmitDisabled()}
                                    loading={submitting}
                                    onClick={handleSubmit}
                                >
                                    {t(`${translationPath}.performChanges`)}
                                </LoadingButton>
                            </DialogActions>
                        </div>
                    )}
                </DialogContent>
            )}
        </Dialog>
    )
}

type ShareClassDropdownProps = {
    shareClasses: FundShareClassDto[]
    value: string
    disabledItemText: string
    onShareClassSelect: (value: string) => void
}

function ShareClassDropdown({shareClasses, value, disabledItemText, onShareClassSelect}: ShareClassDropdownProps) {
    return (
        <Select onChange={(e: SelectChangeEvent) => onShareClassSelect(e.target.value)}
            displayEmpty={true}
            value={value ?? ''}
        >
            <MenuItem disabled value={''}>{disabledItemText}</MenuItem>
            {shareClasses
                .map(sc => (
                    <MenuItem key={sc.id} value={sc.id}>{sc.fullName}</MenuItem>
                ))}
        </Select>
    )
}
