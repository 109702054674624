import { Fragment } from 'react';
import SlidingPane from './SlidingPane'
import './SupportPane.raw.css'
import styles from './SupportPane.module.scss'
import {useTranslation} from '#components/i18n'
import SupportUsers from '#pages/organizations/OrganizationDetail/SupportUsers/SupportUsers'
import NotionTitle from '#app/layers/SupportPane/notion/NotionTitle'
import NotionBackIcon from '#app/layers/SupportPane/notion/NotionBackIcon'
import NotionBody from '#app/layers/SupportPane/notion/NotionBody'
import {NotionBreadcrumb} from '#app/layers/SupportPane/notion/NotionBreadcrumb'
import useSupportPane from '#services/useSupportPane'

function SupportPane() {
    const {t} = useTranslation('layers-SupportPane')

    const supportPane = useSupportPane()

    let content
    if (!supportPane.isLoaded) {
        content = <div/>
    } else {
        content = (
            <Fragment>
                <NotionBackIcon/>
                <NotionTitle/>
                <NotionBody/>
            </Fragment>
        )
    }

    const header = (
        <div className={styles.header}>
            <div className={styles.metaWrapper}>
                <a className={styles.helpTitle} onClick={() => supportPane.loadTopLevelPage()}>
                    <span>
                        <i className="ri-lifebuoy-line"/>
                        {t('common.help')}
                    </span>
                </a>
                <div className={styles.breadcrumb}>
                    <NotionBreadcrumb/>
                </div>
            </div>
            <div className={styles.buttonRow}>
                <div className={styles.closeSupport} style={{width: '25px', height: '25px', cursor: 'pointer'}}
                     onClick={supportPane.hide}>
                    <i className="ri-close-line"/>
                </div>
            </div>
        </div>
    )

    return (
        <SlidingPane overlayClassName={styles.overlay} isOpen={supportPane.isOpen} onRequestClose={supportPane.hide}>
            <div className={styles.inner}>
                {header}
                {content}
            </div>
            <SupportUsers/>
        </SlidingPane>
    )
}

export default SupportPane

