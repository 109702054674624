import { useEffect, useRef } from 'react';

export function useOnIdle(onIdle: () => void, idleTime = 10 * 60 * 1000) {
    const idleTimeoutId = useRef<number | null>(null);

    useEffect(() => {
        const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

        const resetIdleTimeout = () => {
            if (idleTimeoutId.current) {
                clearTimeout(idleTimeoutId.current);
            }
            idleTimeoutId.current = setTimeout(onIdle, idleTime) as unknown as number // The type specification is to avoid confusion between NodeJS and browser setTimeout
        };

        // On start, set the idle timeout
        resetIdleTimeout();

        for (const event of events) {
            window.addEventListener(event, resetIdleTimeout);
        }

        // Cleanup function
        return () => {
            // Clear the existing timeout
            if (idleTimeoutId.current) {
                clearTimeout(idleTimeoutId.current);
            }

            // Remove event listeners
            for (const event of events) {
                window.removeEventListener(event, resetIdleTimeout);
            }
        }
    }, [onIdle, idleTime]);
}

