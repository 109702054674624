import { SILVER_GRAY } from '#app/colors/colors'
import FixrateIcon from '#app/components/FixrateIcon/FixrateIcon'
import PartnerLogo from '#app/components/PartnerLogo/PartnerLogo'
import { Box, ButtonBase, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FixrateLogo from '../menu/FixrateLogo'
import read from '#app/config'

type Params = {
    partnerId?: string
}

export default function PartnerLogoutLanding() {
    const { partnerId } = useParams<Params>()
    const { t } = useTranslation()
    const config = read()
    return (
        <Stack overflow={"auto"} alignItems={"center"} justifyContent={"center"} bgcolor={"#F6F6F6"} left="0" top="0" width={"100%"} height={"100%"} position={"fixed"} zIndex={9999999}>
            <Stack alignItems="center" justifyContent="center" py={3} width="100%">
                <Stack alignItems={"center"} width="100%">
                    <Stack spacing={3} alignItems="center" width={"100%"}>
                        <Paper sx={{px: 3, pb: 3, pt: 0, width: "50rem", maxWidth: "100%", height: "42rem"}}>
                            <Stack justifyContent={"space-between"} height="100%">
                                <Box>
                                    <Stack alignItems={"center"} py={1}>
                                        <Box width="15rem">
                                            <PartnerLogo partnerId={partnerId} />
                                        </Box>
                                    </Stack>
                                    <Stack alignItems="center">
                                        <Box height="15rem">
                                            <FixrateIcon name="lockFill" />
                                        </Box>
                                        <Box textAlign={"center"}>
                                            <Typography variant="h2">{t('layers-PartnerLogout.loggedOut')}</Typography>
                                            <Typography variant="body2" color={SILVER_GRAY[500]}>{t('layers-PartnerLogout.youMayClose')}</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
                <Stack alignItems={"center"} justifyContent="center" pt={3} mt="auto">
                    <ButtonBase onClick={() => window.location.replace(config.homePageUrl)}>
                        <FixrateLogo maxWidth="9rem" />
                    </ButtonBase>
                </Stack>
            </Stack>
        </Stack>
    )
}
