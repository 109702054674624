import { switchOrganisation } from '#app/services/thunks/session'
import { PageHeader, PageLayout } from '#components'
import CustomerDetailAdvisors from '#pages/Customers/CustomerDetail/CustomerDetailAdvisors'
import CustomerDetailInfo from '#pages/Customers/CustomerDetail/CustomerDetailInfo'
import CustomerDetailInvite from '#pages/Customers/CustomerDetail/CustomerDetailInvite'
import CustomerDetailInviteResend from '#pages/Customers/CustomerDetail/CustomerDetailInviteResend'
import CustomerDetailPartnership from '#pages/Customers/CustomerDetail/CustomerDetailPartnership'
import CustomerDetailRemoveAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailRemoveAdvisor'
import CustomerDetailSelectAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailSelectAdvisor'
import CustomerDetailSetPrimaryAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailSetPrimaryAdvisor'
import CustomerDetailEndPartnership from '#pages/Customers/CustomerDetail/CustomerDetailEndPartnership'
import { useSelector } from '#state/useSelector'
import { DepositorWarningType } from '@fixrate/fixrate-query'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import StatusCard from './StatusCard'

type Params = {
    depositorId?: string
    modal: 'add' | 'set-primary' | 'remove' | 'resend-invite' | 'end-partnership'
}

type WarningCategory = 'ORGANISATIONAL_ROLES' | 'CUSTOMER_DECLARATION' | 'TERMS' | 'SETTLEMENT_ACCOUNTS' | 'USERS'

type WarningMapType = {
    [key in DepositorWarningType]: string;
}
type WarningMapCategoryType = {
    [key in DepositorWarningType]: WarningCategory
}

export default function CustomerDetail() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const DepositorWarningMessage = (warning: DepositorWarningType, count: number) : string => {
        const warningMap: WarningMapType = {
            'NEED_BOARD_CHAIRMAN': t('pages-customers.warningMissingBoardChairman'),
            'NEED_CEO': t('pages-customers.warningMissingCEO'),
            'NEED_ACCOUNT_HOLDER_ROLE': t('pages-customers.warningMissingAccountHolderRole'),
            'NEED_ORDER_ROLE': t('pages-customers.warningMissingOrderRole'),
            'NEED_PAYMENT_ROLE': t('pages-customers.warningMissingPaymentRole'),
            'MISSING_SECURITY_ROLES': t('pages-customers.warningMissingSecurityRoles'),
            'MISSING_ORGANISATIONAL_ROLES': t('pages-customers.warningMissingOrganisationalRoles'),
            'MISSING_OWNERS_DECLARATION': t('pages-customers.warningMissingOwnersDeclaration'),
            'CUSTOMER_DECLARATION_PERSON_GALLERY_CHANGED': t('pages-customers.warningCustomerDeclarationPersonGalleryChanged'),
            'MISSING_CUSTOMER_DECLARATION': t('pages-customers.warningMissingCustomerDeclaration'),
            'MISSING_SETTLEMENT_ACCOUNT': t('pages-customers.warningMissingSettlementAccount'),
            'TERMS_NOT_ACCEPTED': t('pages-customers.warningTermsNotAccepted'),
            'NEED_INFO_FOR_CUSTOMER_DECLARATION': t('pages-customers.warningNeedInfoForCustomerDeclaration'),
            'AUTHORIZATION_DOCUMENT_NOT_SIGNED': t('pages-customers.warningAuthorizationDocumentNotSigned'),
            'AUTHORIZATION_DOCUMENT_MISSING': t('pages-customers.warningAuthorizationDocumentMissing'),
            'PERSON_NEEDS_INVITE': t('pages-customers.warningPersonNeedsInvite'),
            'PERSON_NEEDS_INFO_FOR_INVITE': t('pages-customers.warningPersonNeedsInfoForInvite'),
            'AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE': t('pages-customers.warningAuthorizationSignerNeedsInfoForInvite'),
            'INVITE_HAS_EXPIRED': t('pages-customers.warningInviteHasExpired'),
            'INVITE_IS_NOT_SENT': t('pages-customers.warningInviteIsNotSent'),
            'MISSING_AUTHORIZATION_DOCUMENT': t('pages-customers.warningMissingAuthorizationDocument'),
            'MISSING_IDENTIFICATION_DOCUMENT': t('pages-customers.warningMissingIdentificationDocument'),
            'AUTHORIZATION_SIGNER_NEEDS_INVITE': t('pages-customers.warningAuthorizationSignerNeedsInvite'),
        }

        return count > 1 ? warningMap[warning] + " (" + count + ")" : warningMap[warning]
    }
    const DepositorWarningMessageCategory = (warning: DepositorWarningType) : string => {
        const warningMap: WarningMapCategoryType = {
            'NEED_BOARD_CHAIRMAN': 'ORGANISATIONAL_ROLES',
            'NEED_CEO': 'ORGANISATIONAL_ROLES',
            'NEED_ACCOUNT_HOLDER_ROLE': 'ORGANISATIONAL_ROLES',
            'NEED_ORDER_ROLE': 'ORGANISATIONAL_ROLES',
            'NEED_PAYMENT_ROLE': 'ORGANISATIONAL_ROLES',
            'MISSING_SECURITY_ROLES': 'ORGANISATIONAL_ROLES',
            'MISSING_ORGANISATIONAL_ROLES': 'ORGANISATIONAL_ROLES',
            'MISSING_OWNERS_DECLARATION': 'CUSTOMER_DECLARATION',
            'CUSTOMER_DECLARATION_PERSON_GALLERY_CHANGED': 'CUSTOMER_DECLARATION',
            'MISSING_CUSTOMER_DECLARATION': 'CUSTOMER_DECLARATION',
            'MISSING_SETTLEMENT_ACCOUNT': 'SETTLEMENT_ACCOUNTS',
            'TERMS_NOT_ACCEPTED': 'TERMS',
            'NEED_INFO_FOR_CUSTOMER_DECLARATION': 'CUSTOMER_DECLARATION',
            'AUTHORIZATION_DOCUMENT_NOT_SIGNED': 'USERS',
            'AUTHORIZATION_DOCUMENT_MISSING': 'USERS',
            'PERSON_NEEDS_INVITE': 'USERS',
            'PERSON_NEEDS_INFO_FOR_INVITE': 'USERS',
            'AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE': 'USERS',
            'INVITE_HAS_EXPIRED': 'USERS',
            'INVITE_IS_NOT_SENT': 'USERS',
            'MISSING_AUTHORIZATION_DOCUMENT': 'USERS',
            'MISSING_IDENTIFICATION_DOCUMENT': 'USERS',
            'AUTHORIZATION_SIGNER_NEEDS_INVITE': 'USERS',
        }

        return warningMap[warning]
    }

    const partner = useSelector(state => state.partner)
    const customer = partner?.customers.find(c => c.depositorId === params.depositorId)

    if (!customer) {
        return null
    }

    const invites = customer.userInvites.map(userInvite => <CustomerDetailInvite key={userInvite.id} userInvite={userInvite} depositorId={customer.depositorId}/>)

    function onSelectRole(navigateAfterSwitch: () => void) {
        dispatch(switchOrganisation('DEPOSITOR', customer.depositorId, navigateAfterSwitch))
    }

    const getWarnings = (type: WarningCategory) => {
        return customer.warnings
            .filter(warning => DepositorWarningMessageCategory(warning.type) === type)
            .map(warning => DepositorWarningMessage(warning.type, customer.warnings
                .filter(warn => warn.type === warning.type).length))
    }

    const termsWarnings = getWarnings("TERMS")
    const settlementAccountWarnings = getWarnings("SETTLEMENT_ACCOUNTS")
    const customerDeclarationWarnings = getWarnings("CUSTOMER_DECLARATION")
    const organisationalRolesWarnings = getWarnings("ORGANISATIONAL_ROLES")
    const usersWarnings = getWarnings("USERS")

    return (
        <>
            <PageHeader icon="ri-building-line" title={customer.name} backToLink={'/customer'}/>
            <PageLayout>
                <Stack spacing={2} maxWidth={'80rem'} px={{xs: 1, md: 0}}>
                    <CustomerDetailInfo/>
                    {customer.accepted === true && (
                        <Stack spacing={2} justifyContent={'space-between'} direction={'row'} maxWidth={'80rem'} flexWrap={'wrap'}>
                            <StatusCard
                                title={t('pages-customers.contractWithFixrate')}
                                description={t('pages-customers.agreementDescription')}
                                icon={'handshakeFill'}
                                buttonText={t('pages-customers.goToAgreements')}
                                onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/terms`))}
                                warnings={termsWarnings}
                            />
                            <StatusCard
                                title={t('pages-customers.settlementAccount')}
                                description={t('pages-customers.settlementAccountDescription')}
                                icon={'bankFill'}
                                buttonText={t('pages-customers.goToSettlementAccount')}
                                onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/accounts`))}
                                warnings={settlementAccountWarnings}
                            />
                            <StatusCard
                                title={t('pages-customers.usersAndRoles')}
                                description={t('pages-customers.usersAndRolesDescription')}
                                icon={'roleGroupFill'}
                                buttonText={t('pages-customers.goToUsersAndRoles')}
                                onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/users/all`))}
                                warnings={[...usersWarnings, ...organisationalRolesWarnings]}
                            />
                            <StatusCard
                                title={t('pages-customers.customerDeclaration')}
                                description={t('pages-customers.customerDeclarationDescription')}
                                icon={'secureDocumentFill'}
                                buttonText={t('pages-customers.goToCustomerDeclaration')}
                                onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/customerdecl`))}
                                warnings={customerDeclarationWarnings.filter((item,
                                    index) => customerDeclarationWarnings.indexOf(item) === index)}
                            />
                        </Stack>
                    )}
                    <CustomerDetailAdvisors/>
                    {(partner.partnerCategory === 'ACCOUNTANT' || partner.partnerCategory === 'ASSET_MANAGER') &&  <CustomerDetailPartnership/>}
                    {invites}
                </Stack>
            </PageLayout>
            {{
                'add': <CustomerDetailSelectAdvisor/>,
                'set-primary': <CustomerDetailSetPrimaryAdvisor/>,
                'remove': <CustomerDetailRemoveAdvisor/>,
                'resend-invite': <CustomerDetailInviteResend/>,
                'end-partnership': <CustomerDetailEndPartnership/>,
            }[params.modal]}
        </>
    )
}
