import useSupportPaneActions from '#services/useSupportPane'
import React from 'react'

type ChildProps = {
    block: {
        properties: {
            title: [[string]]
        }
    }
}

type Props = {
    href: string
    className: string
    children: React.ReactChild
}

export const CustomLink = (props: Props) => {

    const {href, className, children} = props

    const supportPane = useSupportPaneActions()

    // The children prop is a part of the notionRecordMap that contains the title of the link
    const childProps = React.isValidElement(children) ? children.props as ChildProps : null
    const title = childProps?.block?.properties?.title[0][0]

    return (
        <a className={['notion-custom-link', className].join(' ')} style={{cursor: 'pointer'}}
           onClick={() => supportPane.load(href)}>{title}</a>
    )
}


