import useCurrentDepositor from '#app/services/useCurrentDepositor';
import PageLayout from '#components/PageLayout/PageLayout';
import { useSelector } from '#state/useSelector';
import { useTranslation } from 'react-i18next';
import {
    DepositInterestForDepositorWithSsb, DepositInterestByProductForDepositor,
    DepositMarginByProductForDepositor
} from '@fixrate/fixrate-analytics-widgets';
import FxPaper from '#app/components/Paper/FxPaper';
import { AnalyticsPaperSx } from '../Analytics';

export default function AnalyticsDepositsDepositor() {
    const {t} = useTranslation()
    const deposits = useSelector(state => state.deposits)
    const depositor = useCurrentDepositor()

    const hasMultipleProducts = [...new Set(deposits.filter(deposit => deposit?.depositor?.id === depositor?.id).map(deposit => deposit.product?.id))].length > 1

    return (
        <>
            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsAllDepositor')}>
                <DepositInterestForDepositorWithSsb key={depositor?.id} depositorId={depositor?.id}/>
            </FxPaper>
            {hasMultipleProducts && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsProductsDepositor')}>
                    <DepositInterestByProductForDepositor key={depositor?.id} depositorId={depositor?.id}/>
                </FxPaper>
            )}
            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsMarginDepositor')}>
                <DepositMarginByProductForDepositor key={depositor?.id} depositorId={depositor?.id}/>
            </FxPaper>
        </>
    );
}
