import * as React from 'react'
import { useEffect } from 'react'
import styles from './Signature.module.css'

type Props = {
    signatureUrl: string,
    onSignatureSuccess: () => void,
}

// For Chrome, the origin property is in the event.originalEvent object.
type ExtendedMessageEvent = (MessageEvent & {originalEvent: MessageEvent})

export default function SignicatSignature(props: Props) {

    const [iframeIsLoaded, setIframeIsLoaded] = React.useState(false)

    useEffect(() => {

        const apiUrl = window.location.origin

        /*
         Listens for message sent by the javascript in the web page created by the saml response page
         upon a successfull login
         */
        const callback = (event: ExtendedMessageEvent) => {
            const origin = event.origin || event.originalEvent.origin // For Chrome, the origin property is in the event.originalEvent object.
            if (origin.startsWith(apiUrl) && (event.data === 'signature success' || event.data === 'signature abort')) {
                console.log('Got signature event from iframe', event)
                props.onSignatureSuccess()
            }
        }

        console.log('Registering the Signicat signature iframe window event listener')
        window.addEventListener('message', callback, false)

        return () => {
            console.log('Removes the Signicat signature iframe window event listener')
            window.removeEventListener('message', callback, false)
        }
    })


    const url = props.signatureUrl

    // Inline styles that match the hard coded l&f of the BankID widget
    const iframeStyle = {} as React.CSSProperties
    if (!iframeIsLoaded) {
        iframeStyle.display = 'none'
    }

    const waitMessageStyle = {
        width: 500,
        height: 216,
    }
    const waitMessageBoxStyle = {
        width: 500,
        height: 160,
        borderRadius: 3,
    }
    const waitMessage = iframeIsLoaded ? null : (
        <div style={waitMessageStyle}>
            <div style={waitMessageBoxStyle}></div>
        </div>
    )

    console.log('Opening iframe with URL to Signicat: ' + url)

    return (
        <div className={styles.signicat}>
            {waitMessage}
            <iframe style={iframeStyle} title={'login'} className={styles.signicatIframe} src={url} frameBorder="0"
                    onLoad={() => setIframeIsLoaded(true)}></iframe>
        </div>
    )

}

