import {useSelector} from '#state/useSelector'
import {ButtonRow, DateOutput} from '#components'
import {ActionBlock, HelpIcon} from '#components'
import styles from './TerminateDepositAction.module.css'
import SignedDocumentList from '#pages/orders/bank/OrderDetails/OrderDialog/SignedDocumentList'
import {TerminationState} from '#state/selectors'
import {TerminationModeConstant} from '#pages/portfolio-bank/DepositDetailsBank/DepositDetailsBank'
import Button from '#components/Button'
import {useCommand} from '#command'
import { DepositDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

export default function TerminateDepositAction({deposit} : { deposit: DepositDto }) {

    const {t} = useTranslation()
    const terminationDocument = useSelector(state => state.documents[deposit.terminationDocumentId])
    const {confirmDepositTermination} = useCommand()

    async function submitTerminationForm() {
        const {waitForCommand} = await confirmDepositTermination(deposit.id)
        await waitForCommand()
    }

    if (deposit.terminationState !== TerminationState.SENT_TO_BANK || deposit.terminationType !== TerminationModeConstant.UNCONDITIONAL) {
        return null
    }

    return (
        <ActionBlock header={t('pages-portfolio-bank.terminationHeader')}>
            <HelpIcon className={styles.helpIcon} supportArticleId={'c36d29b2-c3a5-43ee-a0b3-8b36217d5f6d'} helpContextPage="TerminateDepositAction"/>
            <p>{t('pages-portfolio-bank.terminationInfo1', {date: DateOutput.formatDateTime(deposit?.terminationRequested)})}</p>

            {deposit?.terminationDate && (
                <p>
                    {t('pages-portfolio-bank.terminationInfo2', {date: DateOutput.formatVerboseMonth(deposit?.terminationDate)})}
                </p>
            )}
            {deposit?.terminationReason && (
                <p>
                    {t('pages-portfolio-bank.terminationReason', {reason: deposit?.terminationReason})}
                </p>
            )}
            <dl>
                <dt>{t('pages-portfolio-bank.terminationStep1')}</dt>
                <dd>
                    <SignedDocumentList documents={[terminationDocument]}/>
                </dd>
            </dl>
            <dl>
                <dt>{t('pages-portfolio-bank.terminationStep2')}</dt>
                {deposit.terminationDate && (
                    <dd>
                        <DateOutput.VerboseMonth date={deposit?.terminationDate}/>
                    </dd>
                )}
            </dl>
            {deposit.nextPeriod && (
                <dl>
                    <dt>{t('pages-portfolio-bank.terminationStep3')}</dt>
                    <dd>
                        {t('pages-portfolio-bank.terminationInfo3', {date: DateOutput.formatVerboseMonth(deposit.nextPeriod.startDate)})}
                    </dd>
                </dl>
            )}
            <ButtonRow>
                <Button id="confirmDepositTerminationButton" onClick={submitTerminationForm}>
                    {t('pages-portfolio-bank.terminationButtonText')}
                </Button>
            </ButtonRow>
        </ActionBlock>
    )
}
