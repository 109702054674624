import styles from '#app/layers/SupportPane/SupportPane.module.scss'
import { Fragment } from 'react';
import useSupportPane from '#services/useSupportPane'


export default function NotionTitle() {

    const supportPane = useSupportPane()

    return (
        <Fragment>
            <h1 className={styles.title}>{supportPane.title}</h1>
        </Fragment>
    );

}
