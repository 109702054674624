import config from '#app/config'
import { useLocalStorage } from '#app/services/LocalStorageContext'
import { useTranslation } from '#components/i18n'
import { sortDatesDescending } from '#services/dateandtime'
import { DocumentDto, DocumentType } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'
import { Checkbox, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import styles from './SignedDocumentList.module.scss'

export type Props = {
    documents: DocumentDto[],
    documentTypes?: DocumentType[],
    removeDocumentType?: DocumentType,
    missingDataText?: string,
    checkDocuments?: boolean,
    orderId?: string,
    onAllDocumentsCheckedChanged?: (state: boolean) => void
}

function documentIcon(type: DocumentType) {
    if (type === 'AUTHORIZATION_DOCUMENT') {
        return 'ri-shield-check-line'
    }
    if (type === 'CUSTOMER_DECLARATION') {
        return 'ri-survey-line'
    }
    if (type === 'IDENTIFICATION_DOCUMENT') {
        return 'ri-shield-user-line'
    }
    if (type === 'ORDER_DOCUMENT') {
        return 'ri-file-text-line'
    }
}

const CustomerDeclarationDocumentList = ({documentsWithAuthDocs}: { documentsWithAuthDocs: DocumentDto[] }) : JSX.Element => {
    const {t} = useTranslation('pages-orders')

    const customerDeclarationDocuments = documentsWithAuthDocs
        .filter(document => document.documentType === 'CUSTOMER_DECLARATION')
        .sort((d1, d2) => sortDatesDescending(d1.created, d2.created))

    if (customerDeclarationDocuments.length <= 1) {
        return null
    }

    const subItems = customerDeclarationDocuments.map((document, index) => {
        const url = `${config().apiUrl}${document.signedByAny ? document.signedLink : document.unsignedLink}`
        return (
            <li className={styles.subListItem} key={document.id}>
                <a href={url} target="_blank" rel="noopener noreferrer">{document.name}</a>
                {index === 0 && <span>({t('current')})</span>}
                {index !== 0 && <span>({t('outdated')})</span>}
            </li>
        )
    })

    const lastDoc = customerDeclarationDocuments[0]
    const lastDocUrl = `${config().apiUrl}${lastDoc.signedByAny ? lastDoc.signedLink : lastDoc.unsignedLink}`

    return (
        <li className={styles.hasSubList}>
            <div className={styles.listTitle}>
                <span className={styles.title}>
                    <i className={'ri-survey-line'}/>
                    <a href={lastDocUrl} target="_blank" rel="noopener noreferrer">
                        {lastDoc.name}
                    </a>
                </span>
                <span className={styles.updated}>
                    <i className="ri-refresh-line"/>
                    <span>{t('updated')}</span>
                </span>
            </div>
            <ul className={styles.subList}>
                {subItems}
            </ul>
        </li>
    )
}

export default function SignedDocumentList(
    {
        documents,
        removeDocumentType = null,
        documentTypes = null,
        missingDataText = null,
        orderId = "",
        checkDocuments,
        onAllDocumentsCheckedChanged
    }: Props) {

    const {
        keys: { bankDocumentsChecked },
        dispatchLocalStorage,
    } = useLocalStorage()

    const documentsChecked = bankDocumentsChecked[orderId] || []

    // Creates a list of all documents to list with any authorization documents
    const documentsWithAuthDocs = useSelector(state => {
        let cleanedDocuments = documents.filter(document => document) // Removes non-existing documents

        if (documentTypes) {
            cleanedDocuments = cleanedDocuments.filter(document => documentTypes.includes(document.documentType))
        }

        if (removeDocumentType) {
            cleanedDocuments = cleanedDocuments.filter(document => document.documentType !== removeDocumentType)
        }

        // Finds all authorization documents and removes duplicates
        let authDocs = []
        cleanedDocuments.forEach(doc => {
            authDocs = authDocs.concat(doc.allSignatures
                .filter(signature => signature.authorizationDocumentId)
                .map(signature => (state.documents)[signature.authorizationDocumentId])
                .filter(authDoc => authDoc && authDoc.signedByAll),
            )
        })

        const documentsWithAuthDocs = [...cleanedDocuments]
        authDocs.forEach(authDoc => {
            if (!documentsWithAuthDocs.find(d => d.id === authDoc.id)) {
                documentsWithAuthDocs.push(authDoc)
            }
        })

        return documentsWithAuthDocs
    })

    const documentListElements = documentsWithAuthDocs.map(document => {
        const url = `${config().apiUrl}${document.signedByAny ? document.signedLink : document.unsignedLink}`
        return {
            id: document.id,
            url: url,
            name: document.name,
            documentType: document.documentType,
            created: document.created
        }
    })

    const hasMultipleCustomerDeclarations = documentListElements?.filter(document => document.documentType === 'CUSTOMER_DECLARATION').length > 1

    const onCheck = (documentId: string, checked: boolean) => {
        if (checked) {
            dispatchLocalStorage({ type: 'BANK_DOCUMENT_CHECKED', payload: {orderId: orderId, documentId: documentId} })
        } else {
            dispatchLocalStorage({ type: 'BANK_DOCUMENT_UNCHECKED', payload: {orderId: orderId, documentId: documentId} })
        }
    }

    useEffect(() => {
        if (checkDocuments) {
            onAllDocumentsCheckedChanged(documentsChecked.length >= documentListElements.length)
        }
    }, [documentListElements, checkDocuments, onAllDocumentsCheckedChanged, documentsChecked.length])

    return (
        <ul className={styles.linkList}>
            <CustomerDeclarationDocumentList documentsWithAuthDocs={documentsWithAuthDocs}/>
            { documentListElements.map(document => (
                <li key={document.id}>
                    <Stack width={"100%"} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                        <Stack direction="row" alignItems={"center"}>
                            <i className={documentIcon(document.documentType)}/>
                            <a href={document.url} target="_blank" rel="noopener noreferrer">
                                {document.name}
                            </a>
                            { hasMultipleCustomerDeclarations && document.documentType === "CUSTOMER_DECLARATION" && (
                                <Typography ml={0.5} variant={"body2"} color={"text.secondary"}>
                                    {document.created && `(${new Date(document.created).toLocaleDateString()})`}
                                </Typography>
                            )}
                        </Stack>
                        { checkDocuments && <Checkbox data-cy="document-reviewed-checkbox" checked={documentsChecked.find(docId => docId === document.id) ? true : false} onChange={(e) => onCheck(document.id, e.target.checked)} required/> }
                    </Stack>
                </li>
            ))}
            { documentsWithAuthDocs.length === 0 && missingDataText && (
                <li>{missingDataText}</li>
            )}
        </ul>
    )
}
