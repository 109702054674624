import {useEffect, useState} from 'react'
import {useSelector} from '#state/useSelector'
import {DashboardData} from '@fixrate/fixrate-query'

const UPDATE_FREQUENCY = 1000 * 3

function secondsSinceMidnight() {
    const d = new Date(), e = new Date(d)
    d.setHours(0, 0, 0, 0)
    return (e.getTime() - d.getTime()) / 1000
}

export type EstimatedDashboardData = {
    estimatedTotalBalance: number
    estimatedInterestThisMonth: number
    estimatedInterestSinceMidnight: number
    estimatedInterestRate: number
}

export default function calculateEstimatedDashboardData(dashboard: DashboardData) : EstimatedDashboardData {
    if (dashboard.total?.calculatedTime) {

        // Number of seconds between NOW and the time when the estimated values was calculated for.
        // Positive number if the estimation is in the past. Negative number if the estimation is in the future.
        const secondsBetweenCalculatedTimeAndNow = Math.round((new Date().getTime() - dashboard.total.calculatedTime) / 1000)

        // The current growth rate pr. second
        const interestGrowthPrSecond = dashboard.total.estimatedInterestToday / 86400

        // The difference between the estimated value and the current value
        // Positive number if the estimation is in the past. Negative number if the estimation is in the future.
        const interestGrowthBetweenCalculatedTimeAndNow = secondsBetweenCalculatedTimeAndNow * interestGrowthPrSecond

        const estimatedTotalBalance = dashboard.total.estimatedTotalBalance + interestGrowthBetweenCalculatedTimeAndNow
        const estimatedInterestThisMonth = dashboard.total.estimatedInterestThisMonth + interestGrowthBetweenCalculatedTimeAndNow
        const estimatedInterestSinceMidnight = secondsSinceMidnight() * interestGrowthPrSecond
        const estimatedInterestRate = dashboard.total.estimatedInterestRateToday

        return {
            estimatedTotalBalance,
            estimatedInterestThisMonth,
            estimatedInterestSinceMidnight,
            estimatedInterestRate,
        }
    }
}

export function useEstimatedDashboardData() {
    const dashboard = useSelector(state => state.dashboard)
    const [estimated, setEstimated] = useState(calculateEstimatedDashboardData(dashboard))

    useEffect(() => {
        const interval = setInterval(() => {
            setEstimated(calculateEstimatedDashboardData(dashboard))
        }, UPDATE_FREQUENCY)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [dashboard])

    return estimated

}
