import {CurrencyOutput, DateOutput, Table, TableCell, TableHeader, TableHeaderCell, TableRow} from '#components'
import {ImportableTransaction, SelectableTransactionType} from './FundTransactionsImportCustomer'
import styles from './FundTransactionsImport.module.scss'
import {useTranslation} from 'react-i18next'
import {TransactionTypeBadge} from "#components/TransactionTypeBadge/TransactionTypeBadge";
import {Alert, Box, Button, Checkbox, FormControlLabel, IconButton, MenuItem, Stack, Tooltip} from "@mui/material";
import classNames from 'classnames';
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput';
import {useState} from "react";
import {useFieldState} from '@fixrate/fieldstate'
import ChipSelect from "#pages/FundMarketplace/ChipSelect";
import {LoadingButton} from "@mui/lab";
import {fundPlacementTransactionTypeTMap} from "#services/enumTranslationKeyMapping";
import {FundPlacementTransactionType, FundShareClassDto} from "@fixrate/fixrate-query";

type FundTransactionsImportTableProps = {
    transactions: ImportableTransaction[]
    updateTransactions: (transactions: ImportableTransaction[]) => void
    isinCode: string
    shareClass?: FundShareClassDto
    decimalPrecision: number
}
export default function FundTransactionsImportTable({transactions, updateTransactions, isinCode, shareClass, decimalPrecision}: FundTransactionsImportTableProps) {
    const {t} = useTranslation()
    const [allSelected, setAllSelected] = useState(false)

    if (!transactions || transactions.length === 0) {
        return null
    }
    if (transactions.some(t => t.isinCode !== isinCode)) {
        return (
            <div className={styles.importShareClass}>
                <Stack>
                    <Box component="span" sx={{color: '#6F7271'}}>{isinCode}</Box>
                    {shareClass ? (
                        <Box component="span" sx={{fontWeight: 600}}>{shareClass.fullName}</Box>
                    ) : (
                        <Alert
                            sx={{'& .MuiAlert-message': { p: 0, width: '100%'}}}
                            severity={'error'}>
                            <Stack sx={{fontSize: {xs: '1.2rem', sm: '1.4rem'}}} width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <span>{t('pages-fundTransactionsImport.unexpectedIsin')}</span>
                            </Stack>
                        </Alert>
                    )}
                </Stack>
            </div>
        )
    }

    function onAllSelectedChange(value: boolean) {
        if (value && !canSelectAll()) {
            return
        }
        const updated = transactions.map(t => {
            t.selected = value
            return t
        })
        updateTransactions(updated)
        setAllSelected(value)
    }

    function canSelectAll() {
        return transactions.every(t => t.mappedType !== "UNKNOWN") && !!shareClass
    }

    return (
        <div className={styles.importShareClass}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                    <Box component="span" sx={{color: '#6F7271'}}>{isinCode}</Box>
                    {shareClass ? (
                        <Box component="span" sx={{fontWeight: 600}}>{shareClass.fullName}</Box>
                    ) : (
                        <Alert
                            sx={{'& .MuiAlert-message': { p: 0, width: '100%'}}}
                            severity={'warning'}>
                            <Stack sx={{fontSize: {xs: '1.2rem', sm: '1.4rem'}}} width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <span>{t('pages-fundTransactionsImport.unknownShareClass')}</span>
                            </Stack>
                        </Alert>
                    )}
                </Stack>

                <FormControlLabel sx={{padding: '0 4rem 0'}}
                                  labelPlacement="start"
                                  control={
                                      <Checkbox checked={allSelected && canSelectAll()}
                                                disabled={!canSelectAll()}
                                                onChange={(_e, newVal) => onAllSelectedChange(newVal)}
                                      />
                                  }
                                  label={t('pages-fundTransactionsImport.chooseAll')}
                />
            </Stack>

            {transactions && (
                <TransactionsImportTable
                    transactions={transactions}
                    updateTransactions={updateTransactions}
                    hasKnownShareClass={!!shareClass}
                    decimalPrecision={decimalPrecision}
                />
            )}
        </div>
    )
}

type TransactionsImportTableProps = {
    transactions: ImportableTransaction[]
    updateTransactions: (transactions: ImportableTransaction[]) => void
    hasKnownShareClass: boolean
    decimalPrecision: number
}

function TransactionsImportTable({transactions, updateTransactions, hasKnownShareClass, decimalPrecision}: TransactionsImportTableProps) {
    const {t} = useTranslation()

    function updateTransaction(tr: ImportableTransaction): void {
        const updated = transactions.map(t => {
            if (t.index === tr.index) {
                return tr
            }
            return t
        })
        updateTransactions(updated)
    }

    return (
        <Table className={styles.importTable}>
            <TableHeader>
                <TableHeaderCell>{t('pages-fundTransactions.transactionDate')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactions.settlementDate')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.navPrice')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.units')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.amount')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.rounding')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactions.type')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactionsImport.validated')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactionsImport.importCheckboxLabel')}</TableHeaderCell>
                <TableHeaderCell >{t('pages-fundTransactionsImport.adminControls')}</TableHeaderCell>
            </TableHeader>
            {transactions.map((tr) => (
                <ImportableTransactionRow
                    key={tr.index}
                    transaction={tr}
                    updateTransaction={updateTransaction}
                    hasKnownShareClass={hasKnownShareClass}
                    decimalPrecision={decimalPrecision}
                    isTableRow={true}/>
            ))}
        </Table>
    )
}

type ImportableTransactionRowProps = {
    transaction: ImportableTransaction
    updateTransaction: (tr: ImportableTransaction) => void
    hasKnownShareClass: boolean
    decimalPrecision: number
    isTableRow: true
}
function ImportableTransactionRow({transaction, updateTransaction, hasKnownShareClass, decimalPrecision}: ImportableTransactionRowProps) {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()
    const [editMode, setEditMode] = useState(transaction.mappedType === 'UNKNOWN')
    const typeField = useFieldState(transaction.mappedType, ({value, isEditing}) => {
        if (isEditing) {
            return
        }
        if (value === 'UNKNOWN') {
            return t('pages-fundTransactionsImport.selectTransactionType')
        }
    })
    const [saving, setSaving] = useState(false)
    const [changed, setChanged] = useState(false)
    const canSave = typeField.valid && changed && !saving


    function setType(value: SelectableTransactionType) {
        if (value === 'UNKNOWN') {
            return
        }
        typeField.setValue(value)
        if (value !== transaction.mappedType) {
            setChanged(true)
        } else {
            setChanged(false)
        }
    }

    function setSelected(value: boolean) {
        transaction.selected = canSelect() && value
        updateTransaction(transaction)
    }

    function canSelect() {
        return hasKnownShareClass && transaction.mappedType !== 'UNKNOWN' && typeField.value !== 'UNKNOWN'
    }

    function enterEditMode() {
        if (hasKnownShareClass) {
            resetForm()
            setEditMode(true)
        }
    }

    function exitEditMode() {
        if (!canExitEditMode()) {
            return
        }
        resetForm()
        setEditMode(false)
    }

    function canExitEditMode() {
        return transaction.mappedType !== 'UNKNOWN' && typeField.value !== 'UNKNOWN' && !saving
    }

    function resetForm() {
        typeField.setValue(transaction.mappedType)
        setChanged(false)
    }

    function save() {
        if (!canSave) {
            return
        }
        setSaving(true)
        transaction.mappedType = typeField.value
        updateTransaction(transaction)
        setSaving(false)
        exitEditMode()
    }

    function undoChanges() {
        if (!canUndoChanges()) {
            return
        }
        typeField.setValue(transaction.originalType)
        transaction.mappedType = transaction.originalType
        if (!canSelect()) {
            transaction.selected = false
        }
        updateTransaction(transaction)
        exitEditMode()
    }

    function canUndoChanges() {
        return typeField.value !== transaction.originalType
    }

    return (
        <TableRow key={transaction.index}>
            <TableCell>{DateOutput.formatDate(transaction.transactionDate)}</TableCell>
            <TableCell>{DateOutput.formatDate(transaction.settlementDate)}</TableCell>
            <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{Currency(transaction.unitPrice, { decimals: decimalPrecision })}</TableCell>
            <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{CurrencyOutput.formatNoCode(transaction.unitQuantity, decimalPrecision)}</TableCell>
            <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{Currency(transaction.amount)}</TableCell>
            <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{Currency(transaction.roundingError)}</TableCell>
            {editMode ? (
                <TableCell>
                    <ChipSelect
                        value={typeField.value}
                        onChange={e => setType(e.target.value)}
                    >
                        <MenuItem key={'UNKNOWN'} value={'UNKNOWN'} disabled>
                            Velg type
                        </MenuItem>
                        {['BUY', 'SELL', 'DIVIDEND', 'RETROCESSION', 'RETROCESSION_CASH', 'RETROCESSION_UNITS'].map(transactionType => (
                            <MenuItem key={transactionType} value={transactionType}>
                                {t(fundPlacementTransactionTypeTMap[transactionType])}
                            </MenuItem>
                        ))}
                    </ChipSelect>
                </TableCell>
            ) : (
                <TableCell>
                    <span className={styles.typeBadge}>
                        <TransactionTypeBadge transactionType={transaction.mappedType as FundPlacementTransactionType}/>
                    </span>
                </TableCell>
            )}
            <TableCell>
                {transaction.warnings?.length === 0 ? <i className="ri-check-line"/> : (
                    <Tooltip title={<Stack>{transaction.warnings.map(warning => (<span key={warning}>{warning}</span>))}</Stack>}>
                        <i className={classNames('ri-error-warning-line', styles.warningIcon)}/>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell>
                {transaction.mappedType === 'UNKNOWN' ? (
                    <Tooltip title={t('pages-fundTransactionsImport.selectTransactionType')}>
                        <i className={classNames('ri-error-warning-line', styles.warningIcon)}/>
                    </Tooltip>
                ) : hasKnownShareClass ? (
                    <Checkbox checked={transaction.selected}
                              disabled={!canSelect}
                              onChange={(_e, newVal) => setSelected(newVal)}/>
                ) : (
                    <Tooltip title={t('pages-fundTransactionsImport.unknownShareClass')}>
                        <i className={classNames('ri-error-warning-line', styles.warningIcon)}/>
                    </Tooltip>
                )}
            </TableCell>
            {editMode ? (
                <TableCell style={{position: 'relative'}}>
                    <Box>
                        <LoadingButton variant={'contained'} size={'small'} onClick={save} disabled={!canSave} loading={saving}>
                            {t('common.save')}
                        </LoadingButton>
                        <Button variant={'outlined'} size={'small'} sx={{ml: 1}} onClick={exitEditMode} disabled={!canExitEditMode()}>
                            {t('common.cancel')}
                        </Button>
                        <Button variant={'outlined'} size={'small'} sx={{ml: 1}} onClick={undoChanges} disabled={!canUndoChanges()}>
                            {t('common.undoChanges')}
                        </Button>
                    </Box>
                </TableCell>
            ) : (
                <TableCell style={{position: 'relative'}}>
                    <Tooltip title={t('common.edit')} arrow>
                        <IconButton onClick={enterEditMode}>
                            <i className="ri-edit-line"/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
    )
}
