import { Fragment, useState } from "react";
import { useSelector } from "#app/state/useSelector"
import { DepositCard } from "./DepositCard"
import { Avatar, Box, Button, Stack, Typography } from "@mui/material"
import { groupedDepositsByProductType } from "#app/state/selectors"
import { useTranslation } from "react-i18next"
import { SUCCESS_GREEN, SUNSET_ORANGE } from "#app/colors/colors"

export const DepositCards = () => {
    const {t} = useTranslation()
    const [showAllArchived, setShowAllArchived] = useState(false)
    const deposits = useSelector(state => state.deposits)
    const activeDeposits = deposits.filter(deposit => !deposit.terminationDate || new Date(deposit.terminationDate).getTime() > new Date().getTime())
    const groupedDeposits = useSelector(groupedDepositsByProductType)

    const hasActiveDeposits = activeDeposits.length > 0
    const hasArchivedDeposits = groupedDeposits.archived.length > 0

    return (
        <Fragment>
            { hasActiveDeposits &&
                (
                <Box sx={{mt: 3}}>
                    <Stack sx={{mb: 2}} spacing={1} direction="row" alignItems={'center'}>
                        <Avatar sx={{width: '3.6rem', height: '3.6rem', backgroundColor: SUCCESS_GREEN[50], color: SUCCESS_GREEN[500]}}><i className="ri-check-line"/></Avatar>
                        <Typography variant="h2" mb={0}>{t('pages-portfolio-depositor.activeDeposits')}</Typography>
                    </Stack>
                    <Stack spacing={0} direction={'row'} flexWrap={'wrap'}>
                        {activeDeposits.map(deposit => (
                            <DepositCard deposit={deposit} key={deposit.id} />
                        ))}
                    </Stack>
                </Box>
                )
            }
            { hasArchivedDeposits &&
                (
                    <Box sx={{mt: 3}}>
                        <Stack sx={{mb: 2}} spacing={1} direction="row" alignItems={'center'}>
                            <Avatar sx={{width: '3.6rem', height: '3.6rem', backgroundColor: SUNSET_ORANGE[50], color: SUNSET_ORANGE[600]}}><i className="ri-archive-line"/></Avatar>
                            <Typography variant="h2" mb={0}>{t('pages-portfolio-depositor.archivedDeposits')}</Typography>
                        </Stack>
                        <Stack spacing={0} direction={'row'} flexWrap={'wrap'}>
                            {groupedDeposits.archived.slice(0, showAllArchived ? groupedDeposits.archived.length : 3).map((deposit, index) => (
                                <DepositCard archived deposit={deposit} key={deposit.id} />
                            ))}
                        </Stack>
                        { (!showAllArchived && groupedDeposits.archived.length > 3) && (
                            <Button sx={{mt: 2}} variant={'outlined'} color='secondary' onClick={() => setShowAllArchived(!showAllArchived)}>
                                {t('pages-portfolio-depositor.showOlder')}
                            </Button>
                        )}
                    </Box>
                )
            }
        </Fragment>
    );

}
