import { PURPLE } from '#app/colors/colors'
import { Box, ButtonBase, Typography } from '@mui/material'
import { Stack, SxProps } from '@mui/material'
import FixrateIcon from '#components/FixrateIcon/FixrateIcon'
import { IconName } from '../FixrateIcon/FixrateIcon'

type FxIllustrationButtonProps = {
    onClick: () => void
    title: string
    description: string
    fixrateIcon: IconName
    sx?: SxProps,
    dataCy?: string
}
export default function FxIllustrationButton({ onClick, title, description, fixrateIcon, sx, dataCy }: FxIllustrationButtonProps) {
    return (
        <ButtonBase
            onClick={onClick}
            data-cy={dataCy}
            sx={{
                border: '0.1rem solid ' + PURPLE[500],
                pl: 0,
                pr: 3,
                py: 1,
                borderRadius: '0.5rem',
                ...sx,
            }}
        >
            <Stack spacing={2} direction='row' alignItems={'center'} justifyContent='space-between' width='100%'>
                <Stack direction='row' alignItems={'center'} spacing={1}>
                    <FixrateIcon name={fixrateIcon} sx={{ width: '8rem' }} />
                    <Stack justifyContent={'flex-start'} textAlign='left' spacing={0.5}>
                        <Typography variant='h3'>{title}</Typography>
                        <Typography>{description}</Typography>
                    </Stack>
                </Stack>
                <Box sx={{ fontSize: '2.4rem' }}>
                    <i className='ri-arrow-right-line' />
                </Box>
            </Stack>
        </ButtonBase>
    )
}
