import config from '#app/config'
import DocumentIcon from '../../common/icons/DocumentIcon'
import {Paper} from '#components'
import { DocumentDto } from '@fixrate/fixrate-query';
import { useTranslation } from 'react-i18next';

const API_BASE_URL = config().apiUrl

type Props = {
    authorizationDocument: DocumentDto,
    forUser: {
        firstName: string,
        lastName: string
    }
}

const ActiveAuthorization = function ({authorizationDocument = null, forUser}: Props) {

    const {t} = useTranslation()

    if (!authorizationDocument) {
        return null
    }

    const documentLink = `${API_BASE_URL}${authorizationDocument.link}`
    return (
        <Paper title={t('pages-organizations.powerOfAttorneyHeading')}>
            <p id="authorizationIsSignedAndValidInfo">
                {t('pages-organizations.powerOfAttorneyMessagePart1')}
            </p>
            <p>
                {t('pages-organizations.powerOfAttorneyMessagePart2', {userName: `${forUser.firstName} ${forUser.lastName}`})}
            </p>

            <div className="authorization__download-link">
                <DocumentIcon className="authorization__document-icon"/>

                <a href={documentLink} rel="noopener noreferrer" target="_blank">
                    {t('pages-organizations.downloadPowerOfAttorney')}
                </a>
            </div>

        </Paper>
    )
}

export default ActiveAuthorization

