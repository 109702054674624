import { LinkButton, PageHeader, PageLayout } from '#app/components'
import SuccessIcon from '#app/layers/ConfirmationModal/assets/SuccessIcon.svg?url'
import IdentificationDocument from '#app/pages/profile/IdentificationDocument'
import { useCommand } from '#app/services/beta'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useSelector } from '#app/state/useSelector'
import Confetti from '#components/Confetti/Confetti'
import { PersonValidator } from '#services/PersonValidator'
import * as selectors from '#state/selectors'
import { DepositorDto, DepositorSupportCategory, DocumentDto, SettlementAccountDto } from '@fixrate/fixrate-query'
import { ArrowForward, CheckCircle, ErrorOutline } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Box, Button, Dialog, DialogContent, FormControlLabel, Grid, LinearProgress, Link, Paper, Radio, RadioGroup, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Accounts from '../../OrganizationDetail/Accounts/Accounts'
import CustomerDeclarationInfo from '../../OrganizationDetail/CustomerDeclarationInfo/CustomerDeclarationInfo'
import NewAccount from '../../OrganizationDetail/Accounts/NewAccount'
import AcceptTerms from '../../OrganizationDetail/TermsAndConditions/AcceptTerms'
import Board from '../../OrganizationDetail/UsersAndRoles/views/Board'
import ConfettiIllustration from './Confetti.svg?url'
import FixrateManWaveImg from './fixrate-wave.svg?url'
import styles from './OrganizationWizard.module.scss'
import Philip from './philip-portrait.jpg'
import useUiSetting from "#services/useUiSetting";

type StepName = 'start' | 'terms' | 'identificationDocument' | 'board' | 'accounts' | 'customerDeclaration'

type Step = {
    index: number
    name: StepName
    done: boolean
}

function GET_STEPS(depositor: DepositorDto | undefined, identificationDocument: DocumentDto | undefined, settlementAccounts: SettlementAccountDto[], customerDeclaration: DocumentDto | undefined): Step[] {
    return [
        {
            index: 0,
            name: 'start',
            done: true,
        },
        {
            index: 1,
            name: 'terms',
            done: depositor?.termsAccepted,
        },
        {
            index: 2,
            name: 'identificationDocument',
            done: identificationDocument?.signedByAll,
        },
        {
            index: 3,
            name: 'board',
            done: PersonValidator.isDepositorReadyForCustomerDeclaration(depositor),
        },
        {
            index: 4,
            name: 'accounts',
            done: settlementAccounts?.length > 0,
        },
        {
            index: 5,
            name: 'customerDeclaration',
            done: customerDeclaration?.signedByAll && PersonValidator.isDepositorReadyForCustomerDeclaration(depositor) && !depositor.customerDeclarationExpired,
        },
    ]

}

type Params = {
    step?: StepName | string
}

export const OrganizationWizard = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {step} = useParams<Params>()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const {
        depositorHasSingleOwner: depositorHasSingleOwnerCommand,
        setDepositorSupportCategory: setDepositorSupportCategoryCommand,
    } = useCommand()

    const [onlyOwner, setOnlyOwner] = useState<'yes' | 'no' | null>(null)
    const [visibleErrorSteps, setVisibleErrorSteps] = useState<Array<number>>([])
    const [weHelpYouModal, setWeHelpYouModal] = useState(false)

    const [lastSavedProgress, setLastSavedProgress] = useState(0)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [hideSuccessModalForEver, setHideSuccessModalForEver] = useUiSetting('hideSuccessModalForEver', false)


    const selectedDepositor = useCurrentDepositor()
    const deposits = useSelector(state => state.deposits)
    const orders = useSelector(state => state.orders)
    const documents = useSelector(state => state.documents)
    const sessionId = useSelector(state => state.session?.id)

    const identificationDocument = useSelector(selectors.identificationDocument)
    const [submittingOnlyOwners, setSubmittingOnlyOwners] = useState(false)
    const depositorSettlementAccounts = useSelector(selectors.depositorSettlementAccounts)

    const steps = GET_STEPS(selectedDepositor, identificationDocument, depositorSettlementAccounts[selectedDepositor?.id], documents[selectedDepositor?.customerDeclarationId])
    const initialStep = steps.find(s => s.name === step)
    const [currentStepIndex, setCurrentStepIndex] = useState(initialStep?.index ?? 0)
    const currentStep = steps.find(s => s.index === currentStepIndex) ?? steps.at(1)
    const currentProgress = (steps.filter(step => step.done).length / steps.length) * 100

    useEffect(() => {
        // Navigates to the MANAGED organization view if the organization is 'MANAGED' (or changes to 'MANAGED')
        if (selectedDepositor?.depositorSupportCategory === 'MANAGED') {
            navigate('/organizations/' + selectedDepositor.id)
        }
    }, [navigate, selectedDepositor?.depositorSupportCategory, selectedDepositor.id])

    useEffect(() => {
        // Always show the first step until the organization is not 'UNKNOWN' anymore
        if (selectedDepositor?.depositorSupportCategory === 'UNKNOWN' && currentStepIndex !== 0) {
            setCurrentStepIndex(0)
        }

        if (selectedDepositor?.depositorSupportCategory !== 'UNKNOWN' && currentStepIndex === 0) {
            setCurrentStepIndex(1)
        }
    }, [currentStepIndex, selectedDepositor?.depositorSupportCategory])

    useEffect(() => {
        if (currentProgress === 100 && lastSavedProgress !== 100 && lastSavedProgress !== 0) {
            setShowSuccessDialog(!hideSuccessModalForEver)
            setHideSuccessModalForEver(true)
        } else if (lastSavedProgress === 100) {
            setHideSuccessModalForEver(true)
        }
        setLastSavedProgress(currentProgress)
    }, [currentProgress, hideSuccessModalForEver, lastSavedProgress, setHideSuccessModalForEver])

    if (!selectedDepositor) {
        return null
    }

    function changeStep(index: number) {
        setCurrentStepIndex(index)
        navigate('/organizations/onboarding/' + selectedDepositor.id + '/' + steps.find(s => s.index === index)?.name)
    }

    function goToNext(index: number) {
        if (currentStep.done !== true) {
            if (!visibleErrorSteps.includes(currentStepIndex)) {
                setVisibleErrorSteps(prev => [...prev, currentStepIndex])
            }
        } else {
            changeStep(index)
        }
    }

    async function startRegistration() {
        if (onlyOwner === null) return

        setSubmittingOnlyOwners(true)

        const category: DepositorSupportCategory = onlyOwner === 'yes' ? 'SELF_SERVICED' : 'MANAGED'
        const personId = selectedDepositor.people.find(person => person.associatedUserId === sessionId)?.personId

        const {waitForCommand} = await depositorHasSingleOwnerCommand(selectedDepositor.id, onlyOwner === 'yes', personId)
        const success = await waitForCommand()
        if (success) {
            if (category === 'SELF_SERVICED') {
                setCurrentStepIndex(1)
            } else {
                setWeHelpYouModal(true)
            }
        }
        setSubmittingOnlyOwners(false)
    }

    async function switchToManaged() {
        const {waitForCommand} = await setDepositorSupportCategoryCommand(selectedDepositor.id, 'MANAGED')
        const success = await waitForCommand()
        if (success) {
            window.location.reload()
        }
    }

    return (
        <>
            {showSuccessDialog && <Confetti/>}
            <PageHeader icon={'ri-building-line'} title={t('pages-OrganizationWizard.getStarted')}></PageHeader>
            <PageLayout className={styles.mobileWizardLayout}>
                <Dialog
                    maxWidth={'xs'}
                    fullWidth={true}
                    onClose={() => setShowSuccessDialog(false)}
                    open={showSuccessDialog}
                >
                    <DialogContent
                        sx={{
                            backgroundImage: 'url(' + ConfettiIllustration + '), url(' + SuccessIcon + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center -3rem, center 7rem',
                            padding: '5rem 4rem',
                            textAlign: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={styles.successModalContent}>
                            <h1>{t('pages-OrganizationWizard.registrationComplete')}</h1>
                            <p>{t('pages-OrganizationWizard.readyForFirstOrder')}</p>
                        </div>
                        <LinkButton data-cy="goToMarketplaceButton" to={'/marketplace'}>{t('pages-OrganizationWizard.goToMarketplace')}</LinkButton>
                        <Button onClick={() => setShowSuccessDialog(false)} sx={{marginTop: '1rem'}} fullWidth={true} variant={'text'}>{t('pages-OrganizationWizard.close')}</Button>
                    </DialogContent>
                </Dialog>
                <Dialog open={weHelpYouModal} maxWidth={'xs'} fullScreen={mobile}>
                    <img src={Philip} style={{maxWidth: '100%'}} alt={''}/>
                    <DialogContent
                        sx={{
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            padding: {md: '4rem'},
                            textAlign: 'center',
                        }}
                    >
                        <h1>{t('pages-OrganizationWizard.happyToHelp')}</h1>
                        <p className="gray">{t('pages-OrganizationWizard.helpDescription')}</p>
                        <p className={styles.contactLine}>
                        <span>
                            <i className="ri-mail-line"/>
                            <span>post@fixrate.no</span>
                        </span>
                            <span>
                            <i className="ri-phone-line"/>
                            <span>+47 412 51 918</span>
                        </span>
                        </p>
                        <Button data-cy="continueToRegistrationButton" fullWidth={true} variant={'contained'} onClick={() => window.location.reload()}>{t('pages-OrganizationWizard.continueToRegistration')}</Button>
                    </DialogContent>
                </Dialog>
                <Grid maxWidth={1000} className={styles.wizardContainer} container spacing={{xs: 0, md: 2}}>
                    {currentStepIndex === 0 && (
                        <Grid maxWidth={860} container sx={{flexDirection: mobile ? 'column' : null, padding: mobile ? '2rem' : null}}>
                            <Grid order={mobile ? 2 : 1} item md={8}>
                                <p className={styles.descriptionHeader}>{t('pages-OrganizationWizard.getStarted')}</p>
                                <h1>{t('pages-OrganizationWizard.completeRegistration')}</h1>
                                <p>{t('pages-OrganizationWizard.registrationInfo')}</p>
                                <h3>{t('pages-OrganizationWizard.onlyOwnerQuestion')}</h3>
                                <RadioGroup
                                    aria-labelledby="onlyOwner"
                                    defaultValue=""
                                    name="onlyOwnerGroup"
                                    value={onlyOwner}
                                    onChange={(e) => setOnlyOwner(e.target.value as 'yes' | 'no')}
                                >
                                    <FormControlLabel data-cy="yesRadio" value="yes" control={<Radio/>} label={t('pages-OrganizationWizard.onlyOwnerYes')}/>
                                    <FormControlLabel data-cy="noRadio" value="no" control={<Radio/>} label={t('pages-OrganizationWizard.onlyOwnerNo')}/>
                                </RadioGroup>
                                <LoadingButton data-cy="startRegistrationButton" loading={submittingOnlyOwners} sx={{marginTop: '2rem'}} variant={'contained'} onClick={startRegistration}>{t('pages-OrganizationWizard.startRegistration')}</LoadingButton>
                            </Grid>
                            <Grid order={mobile ? 1 : 2} className={styles.imageContainer} item md={3}>
                                <img src={FixrateManWaveImg} alt={''}/>
                            </Grid>
                        </Grid>
                    )}
                    {currentStepIndex !== 0 && (
                        <Grid maxWidth={'100%'}>
                            <Grid>
                                <Grid maxWidth={860} container>
                                    <Grid width={'100%'} item md={8}>
                                        <div className={styles.hideMobile}>
                                            <p className={styles.descriptionHeader}>{t('pages-OrganizationWizard.getStarted')}</p>
                                            <h1>{t('pages-OrganizationWizard.completeRegistration')}</h1>
                                            <p>{t('pages-OrganizationWizard.registrationInfo')}</p>
                                        </div>
                                        {currentStepIndex !== 0 && (
                                            <Box className={styles.progressWrapper}>
                                                <span className={styles.sliderLimit}>0%</span>
                                                <LinearProgress sx={{width: '100%', marginLeft: '1rem', marginRight: '1rem'}} variant={'determinate'} value={currentProgress}/>
                                                <span className={styles.sliderLimit}>100%</span>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid className={classNames(styles.imageContainer, styles.hideMobile)} item md={3}>
                                        <img src={FixrateManWaveImg} alt={''}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(currentProgress === 100 && deposits.length === 0 && orders.length === 0) && (
                                <Alert
                                    sx={{width: '100%', marginTop: '2rem', marginBottom: '2rem', boxShadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.2)'}} severity="success"
                                    action={
                                        <Button onClick={() => navigate('/marketplace')} color="inherit" size="small" variant={'outlined'} endIcon={<ArrowForward/>}>
                                            {t('pages-OrganizationWizard.goToMarketplace')}
                                        </Button>
                                    }
                                >
                                    {t('pages-OrganizationWizard.readyForFirstOrder')}
                                </Alert>
                            )}
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile={mobile} sx={{backgroundColor: 'white'}} textColor={'secondary'} value={currentStepIndex} onChange={(e, val) => changeStep(val)}>
                                    {steps.filter(step => step.name !== 'start').map(step => (
                                        <Tab
                                            key={step.name}
                                            data-cy={step.name + '-tab'}
                                            sx={{fontSize: {xs: '1.2rem', md: '1.4rem'}}}
                                            iconPosition="start"
                                            icon={step.done ? <CheckCircle sx={{fontSize: '1.8rem'}} color="success"/> : <ErrorOutline sx={{fontSize: '1.8rem'}} color="error"/>}
                                            label={t('pages-OrganizationWizard.' + step.name)}
                                            value={step.index}
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                            <Paper className={styles.wizardContent} sx={{position: 'relative', padding: '4rem 4rem 6rem 4rem'}}>
                                {currentStep.name === 'terms' && (
                                    <>
                                        <h2>{t('pages-OrganizationWizard.terms')}</h2>
                                        <Box sx={{paddingTop: '0'}}>
                                            <AcceptTerms inline />
                                        </Box>
                                    </>
                                )}
                                {currentStep.name === 'identificationDocument' && (
                                    <>
                                        <h2>{t('pages-OrganizationWizard.identificationDocument')}</h2>
                                        <p>{t('pages-OrganizationWizard.identificationDocumentExplanation')}</p>
                                        {!identificationDocument && <IdentificationDocument signContext={'/organizations/onboarding/' + selectedDepositor.id + '/identificationDocument'} inline showSign={false}/>}
                                        {identificationDocument && <IdentificationDocument signContext={'/organizations/onboarding/' + selectedDepositor.id + '/identificationDocument'} inline showSign={true} showAddress={false}/>}
                                    </>
                                )}
                                {currentStep.name === 'board' && (
                                    <>
                                        <Board inline={true} depositor={selectedDepositor}/>
                                    </>
                                )}
                                {currentStep.name === 'accounts' && (
                                    <>
                                        <h2>{t('pages-OrganizationWizard.accounts')}</h2>
                                        {selectedDepositor.settlementAccounts.length < 1 && (
                                            <NewAccount inline={true} depositorId={selectedDepositor?.id}/>
                                        )}
                                        {selectedDepositor.settlementAccounts.length > 0 && (
                                            <Accounts inline={true} allowMultipleAccounts={false}/>
                                        )}
                                    </>
                                )}
                                {currentStep.name === 'customerDeclaration' && (
                                    <>
                                        <CustomerDeclarationInfo inline/>
                                    </>
                                )}
                                {(currentStep.done === false) && visibleErrorSteps.includes(currentStepIndex) && (
                                    <Box className={styles.stickyBottom}>
                                        <Alert className={styles.slideUp} severity={'error'}>
                                            {t('pages-OrganizationWizard.stepNotFinishedMessage')}
                                        </Alert>
                                    </Box>
                                )}
                                {mobile && (
                                    <Box
                                        sx={{
                                            borderTop: '0.1rem solid',
                                            borderColor: 'rgba(0,0,0,0.1)',
                                            backgroundColor: '#f2f2f2',
                                            fontSize: '1.2rem',
                                            marginBottom: '2rem',
                                            padding: '1rem 2rem',
                                            position: 'absolute',
                                            bottom: '0',
                                            left: '0',
                                            width: '100%',
                                            margin: '0',
                                        }}
                                    >
                                        <p style={{fontSize: '1.2rem'}}>{t('pages-OrganizationWizard.inviteShortText')}<br/> <Link sx={{display: 'inline-flex', fontSize: '1.2rem', cursor: 'pointer'}} onClick={switchToManaged}>{t('pages-OrganizationWizard.turnOffSelfServicedShort')}</Link></p>
                                    </Box>
                                )}
                            </Paper>
                            <Box className={styles.bottomStepNavigation}>
                                {currentStepIndex !== 1 && (
                                    <Button
                                        data-cy="previous-step-button"
                                        sx={{fontSize: mobile ? '1.4rem' : null}}
                                        startIcon={<i className="ri-arrow-left-line"/>}
                                        variant={'outlined'}
                                        onClick={() => changeStep(Math.max(1, currentStepIndex - 1))}
                                    >
                                        {t('pages-OrganizationWizard.previousStep')}
                                    </Button>
                                )}
                                {currentStepIndex !== steps.length - 1 && (
                                    <Button
                                        data-cy="next-step-button"
                                        endIcon={<i className="ri-arrow-right-line"/>}
                                        sx={{marginLeft: 'auto', fontSize: mobile ? '1.4rem' : null}}
                                        variant={'contained'}
                                        onClick={() => goToNext(currentStepIndex + 1)}
                                    >
                                        {t('pages-OrganizationWizard.nextStep')}
                                    </Button>
                                )}
                                {currentStepIndex === steps.length - 1 && (
                                    <Button disabled={currentProgress !== 100} endIcon={<i className="ri-arrow-right-line"/>} sx={{marginLeft: 'auto', fontSize: mobile ? '1.4rem' : null}} variant={'contained'} onClick={() => navigate('/marketplace')}>
                                        {t('pages-OrganizationWizard.goToMarketplace')}
                                    </Button>
                                )}
                            </Box>
                            {!mobile && (
                                <Box sx={{fontSize: '1.4rem', marginBottom: '2rem'}}>
                                    <p>{t('pages-OrganizationWizard.inviteShortText')} <Link sx={{fontSize: '1.4rem', cursor: 'pointer'}} onClick={switchToManaged}>{t('pages-OrganizationWizard.turnOffSelfServicedLong')}</Link></p>
                                </Box>
                            )}
                        </Grid>
                    )}
                </Grid>
            </PageLayout>
        </>
    )
}
