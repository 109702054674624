import { PURPLE } from "#app/colors/colors";
import { PageHeader, PageLayout } from "#app/components";
import { InterestBadge } from "#app/components/InterestBadge/InterestBadge";
import PartnerLogo from "#app/components/PartnerLogo/PartnerLogo";
import { useSelector } from "#app/state/useSelector";
import { DepositorPartnerProposalDto, DepositorPartnerProposalStatus } from "@fixrate/fixrate-query";
import { Alert, AlertColor, AlertTitle, Box, Button, Card, CardActions, CardContent, Divider, List, ListItem, ListItemText, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { format } from "date-fns";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AdCard from "../marketplace/AdCard/AdCard";
import { getProductName } from "../PartnerDepositorProposalWizard/PartnerDepositorProposalWizard";

export default function DepositorAccountProposals() {
    const { t } = useTranslation()
    const [selectedTab, setSelectedTab] = useState<'active' | 'previous'>('active')
    const proposals = useSelector(state => state.depositor?.partnerRelations?.map(relation => relation?.partnerProposals)?.flat())
    const products = useSelector(state => state.products)
    const banks = useSelector(state => state.banks)
    const previousStatus: DepositorPartnerProposalStatus[] = ["ORDERED", "EXPIRED", "REJECTED", "DELETED"]
    const activeStatus: DepositorPartnerProposalStatus[] = ["ACCEPTED", "PENDING"]
    const activeProposals = proposals?.filter(p => activeStatus.includes(p.status))
    const previousProposals = proposals?.filter(p => previousStatus.includes(p.status))
    const visibleProposals = selectedTab === 'active' ? activeProposals : previousProposals
    const navigate = useNavigate()

    const termsList = (proposal: DepositorPartnerProposalDto) => {
        if (!proposal) {
            return []
        }

        const bankName = banks[proposal?.ad?.bankId]?.name
        const isFixed = products[proposal?.ad?.productId]?.type === 'FIXED'

        return [
            { primary: t('pages-proposal-overview.effectiveInterestRate'), secondary: format(new Date(), 'yyyy-MM-dd'), value: <InterestBadge interest={proposal?.ad?.interest} /> },
            { primary: t('common.interestRateType'), secondary: '', value: isFixed ? t('common.fixedInterest') : t('common.floatingInterest') },
            { primary: t('common.bank'), secondary: '', value: bankName },
            { primary: getProductName(proposal?.ad?.productId, t), secondary: '', value: t(`common.productLongName${proposal?.ad?.productId}`) },
        ]
    }

    type AlertMessageType = {
        status: DepositorPartnerProposalStatus
        title: string
        description: string
        severity: AlertColor
    }

    const alertMessages: AlertMessageType[] = [
        {
            status: "ACCEPTED",
            title: t("pages-partner-proposals.status.title.ACCEPTED"),
            description: t("pages-partner-proposals.status.description.ACCEPTED"),
            severity: "success"
        },
        {
            status: "REJECTED",
            title: t("pages-partner-proposals.status.title.REJECTED"),
            description: t("pages-partner-proposals.status.description.REJECTED"),
            severity: "warning"
        },
        {
            status: "DELETED",
            title: t("pages-partner-proposals.status.title.DELETED"),
            description: t("pages-partner-proposals.status.description.DELETED"),
            severity: "error"
        },
        {
            status: "EXPIRED",
            title: t("pages-partner-proposals.status.title.EXPIRED"),
            description: t("pages-partner-proposals.status.description.EXPIRED"),
            severity: "error"
        },
        {
            status: "ORDERED",
            title: t("pages-partner-proposals.status.title.ORDERED"),
            description: t("pages-partner-proposals.status.description.ORDERED"),
            severity: "success"
        }
    ]

    return (
        <Fragment>
            <PageHeader icon='ri-lightbulb-line' title={t('menu.accountProposals')} />
            <PageLayout>
                <Stack spacing={3} alignItems="flex-start" sx={{px: {xs: 2, md: 0}}}>
                    <Stack spacing={1} >
                        <Typography>{ t('pages-partner-proposals.aboutProposalOverview') }</Typography>
                    </Stack>
                    <ToggleButtonGroup color="primary" value={selectedTab}>
                        <ToggleButton value="active" onClick={() => setSelectedTab("active")}>{t('pages-partner-proposals.activeProposals')}</ToggleButton>
                        <ToggleButton value="previous" onClick={() => setSelectedTab("previous")}>{t('pages-partner-proposals.previousProposals')}</ToggleButton>
                    </ToggleButtonGroup>
                    <Stack direction="row" spacing={4} flexWrap={"wrap"} width={"100%"}>
                        { visibleProposals?.map(proposal => (
                            <Card sx={{width: "100%", maxWidth: "45rem"}} key={proposal.proposalId}>
                                <Stack justifyContent={"center"} alignItems="center">
                                    <PartnerLogo sx={{width: "18rem"}} partnerId={proposal.partnerId} />
                                </Stack>
                                <Stack sx={{ backgroundColor: PURPLE[50] }} p={{ xs: 1, md: 4 }} alignItems={'center'} justifyContent={'center'}>
                                    <Box sx={{ transform: { xs: 'scale(0.8)', md: 'scale(1)' } }}>
                                        <AdCard ad={proposal.ad} preview={true} product={products[proposal.ad.productId]} onAdClick={null} />
                                    </Box>
                                </Stack>
                                <CardContent>
                                    <List sx={{pt: 0}}>
                                        { termsList(proposal).map((term, index) => (
                                            <ListItem key={index} sx={{borderBottom: index < termsList(proposal).length - 1 ? "0.1rem solid rgba(0,0,0,0.1)" : null}}>
                                                <ListItemText>
                                                    { term.primary }
                                                </ListItemText>
                                                <ListItemText sx={{textAlign: "right", ml: "auto"}}>
                                                    { term.value }
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    { proposal?.status === "PENDING" ? (
                                        <Button variant="contained" onClick={() => navigate(proposal.link)} fullWidth>
                                            {t('pages-partner-proposals.goToProposal')}
                                        </Button>
                                    ) : (
                                        <Alert severity={alertMessages.find(message => proposal?.status === message.status).severity} sx={{width: "100%"}}>
                                            <AlertTitle>
                                                {alertMessages.find(message => proposal?.status === message.status).title}
                                            </AlertTitle>
                                            {alertMessages.find(message => proposal?.status === message.status).description}
                                        </Alert>
                                    )}
                                </CardActions>
                            </Card>
                        ))}
                    </Stack>
                </Stack>
            </PageLayout>
        </Fragment>
    );
}
