import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './PowerOfAttorney.module.scss'
import { useSelector } from '#app/state/useSelector'

interface Props {
    nationalIdentity: string
}

export function Signatories({ nationalIdentity }: Readonly<Props>) {
    const { t } = useTranslation()
    const signatories = useSelector(state => state.companyPublicInformation.find(c => c.nationalIdentity === nationalIdentity)?.signatories)

    return (
        <div className={styles.signingRights}>
            <p className={styles.signatureHeader}>
                <i className='ri-archive-drawer-line' />
                <span>{t('pages-organizations.poaRuleDescription')}</span>
            </p>
            {signatories && (
                <div className={classNames(styles.data, styles.signatureConfiguration)}>
                    <pre className={styles.signatories}>{signatories}</pre>
                </div>
            )}
            {!signatories && (
                <p className={classNames(styles.noData, styles.signatureConfiguration)}>
                    <i className={'ri-error-warning-line'} />
                    <span>{t('pages-organizations.poaRuleNA')}</span>
                </p>
            )}
        </div>
    )
}
