import { SILVER_GRAY, SPRING_GREEN, SUNSET_ORANGE } from '#app/colors/colors';
import { PageHeader, PageLayout } from '#app/components';
import {
    formatReportPeriodIdentifier, formatShortReportPeriod, getPeriodIdentifier,
    reportPeriodTypes, setReportPeriods
} from '#pages/portfolio-depositor/Reports/ReportPeriods';
import { lastNavDateSelector } from "#state/selectors";
import { useSelector } from '#state/useSelector';
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Mousewheel, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import InventoryReportCover from './report-cover-1.png';
import AccountingReportCover from './report-cover-2.png';
import useUiSetting from "#services/useUiSetting";

export default function FundReportOverview() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const fundPlacements = useSelector(state => state.fundPlacements)
    const lastNav = useSelector(lastNavDateSelector)

    const [reconciliationReportPeriodType, setReconciliationReportPeriodType] = useUiSetting('fundreport-reconciliation-type', 'M')
    const [inventoryReportPeriodType, setInventoryReportPeriodType] = useUiSetting('fundreport-inventory-type', 'M')

    const [reconciliationReportPeriods, setReconciliationReportPeriods] = useState<Date[]>([])
    const [inventoryReportPeriods, setInventoryReportPeriods] = useState<Date[]>([])

    useEffect(() => {
        setReportPeriods(inventoryReportPeriodType, setInventoryReportPeriods, fundPlacements, lastNav);
    }, [inventoryReportPeriodType, fundPlacements, lastNav])

    useEffect(() => {
        setReportPeriods(reconciliationReportPeriodType, setReconciliationReportPeriods, fundPlacements, lastNav);
    }, [reconciliationReportPeriodType, fundPlacements, lastNav])

    return (
        <Fragment>
            <PageHeader icon="ri-line-chart-line" title={t('pages-fund-reports.fundReports')}></PageHeader>
            <PageLayout>
                <Stack spacing={4} px={{xs: 2, md: 0}}>
                    <Stack spacing={2}>
                        <Stack spacing={0.5}>
                            <Typography variant={'h2'}>{t('pages-fund-reports.inventoryReport')}</Typography>
                            <Typography variant={'body1'}>{t('pages-fund-reports.inventoryReportDescription')}</Typography>
                            <Box sx={{marginTop: '1rem'}}>
                                <ToggleButtonGroup value={inventoryReportPeriodType} size={'small'} exclusive color={'primary'}>
                                    {reportPeriodTypes.map((option) => (
                                        <ToggleButton key={option} value={option} sx={{width: '8rem', whiteSpace: 'nowrap'}} onClick={() => setInventoryReportPeriodType(option)}>
                                            {t(`enum-ReportPeriod.${option}`)}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Box>
                        </Stack>
                        <Swiper
                            style={{width: '100%', paddingBottom: '2.4rem'}}
                            modules={[Mousewheel, Scrollbar]}
                            spaceBetween={24}
                            slidesPerView={'auto'}
                            scrollbar={{draggable: true}}
                            mousewheel={{forceToAxis: true}}
                            threshold={10}
                        >
                            {inventoryReportPeriods.map(periodStartDate => (
                                <SwiperSlide key={format(periodStartDate, 'MMMM yy')} style={{width: 'fit-content', padding: '0.8rem'}}>
                                    <ReportCover
                                        title={t('pages-fund-reports.inventoryAndReturns')}
                                        variant={'inventory'}
                                        periodStart={periodStartDate}
                                        periodType={inventoryReportPeriodType}
                                        onClick={() => navigate(`/reports/funds/inventory/${formatReportPeriodIdentifier(getPeriodIdentifier(periodStartDate, inventoryReportPeriodType))}`)}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Stack>

                    <Stack spacing={2}>
                        <Stack spacing={0.5}>
                            <Typography variant={'h2'}>{t('pages-fund-reports.reconciliationReport')}</Typography>
                            <Typography variant={'body1'}>{t('pages-fund-reports.reconciliationReportDescription')}</Typography>
                            <Box sx={{marginTop: '1rem'}}>
                                <ToggleButtonGroup value={reconciliationReportPeriodType} size={'small'} exclusive color={'primary'}>
                                    {reportPeriodTypes.map((option) => (
                                        <ToggleButton key={option} value={option} sx={{width: '8rem', whiteSpace: 'nowrap'}} onClick={() => setReconciliationReportPeriodType(option)}>
                                            {t(`enum-ReportPeriod.${option}`)}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Box>
                        </Stack>
                        <Swiper
                            style={{width: '100%', paddingBottom: '2.4rem'}}
                            modules={[Mousewheel, Scrollbar]}
                            spaceBetween={24}
                            slidesPerView={'auto'}
                            scrollbar={{draggable: true}}
                            mousewheel={{forceToAxis: true}}
                            threshold={10}
                        >
                            {reconciliationReportPeriods.map(periodStartDate => (
                                <SwiperSlide key={format(periodStartDate, 'MMMM yy')} style={{width: 'fit-content', padding: '0.8rem'}}>
                                    <ReportCover
                                        title={t('pages-fund-reports.reconciliationReport')}
                                        variant="accounting"
                                        periodStart={periodStartDate}
                                        periodType={reconciliationReportPeriodType}
                                        onClick={() => navigate(`/reports/funds/accounting/${formatReportPeriodIdentifier(getPeriodIdentifier(periodStartDate, reconciliationReportPeriodType))}`)}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Stack>
                </Stack>
            </PageLayout>
        </Fragment>
    );
}

type ReportCoverProps = {
    title: string
    variant: 'inventory' | 'accounting'
    periodStart: Date
    periodType: string
    onClick: () => void
}

function ReportCover({title, variant, periodStart, periodType, onClick}: ReportCoverProps) {
    let coverImage
    let titleColor
    let monthColor
    switch (variant) {
        case 'inventory':
            coverImage = InventoryReportCover
            titleColor = SILVER_GRAY[50]
            monthColor = SUNSET_ORANGE[500]
            break
        case 'accounting':
            coverImage = AccountingReportCover
            titleColor = SILVER_GRAY[700]
            monthColor = SPRING_GREEN[800]
            break
    }

    const {t} = useTranslation()

    return (
        <Box
            onClick={onClick}
            sx={{
                backgroundColor: 'lightgrey',
                width: '16rem',
                aspectRatio: '3/4',
                cursor: 'pointer',
                position: 'relative',
                padding: 0,
                boxShadow: '0.1rem 0.1rem 0.3rem rgba(0,0,0,0.3)',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'scale(1.05)',
                }
            }}
        >
            <img src={coverImage} alt="" style={{top: 0, left: 0, width: '100%', position: 'absolute'}}/>
            <Stack spacing={0.5} sx={{p: 1, top: 0, left: 0, width: '100%', position: 'absolute', pt: '7.2rem'}}>
                <Box sx={{color: titleColor, fontSize: '1.2rem', fontWeight: 700}}>{title}</Box>
                <Box sx={{color: monthColor, fontSize: '1.6rem', fontWeight: 900, textTransform: 'capitalize'}}>{formatShortReportPeriod(getPeriodIdentifier(periodStart, periodType), t)}</Box>
            </Stack>
        </Box>
    )
}
