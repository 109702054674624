import { Fragment, useState } from 'react';
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout'
import {Paper} from '#components'
import {IconButton, InputAdornment, TextField} from '@mui/material'
import {useSelector} from '#state/useSelector'
import {Clear} from '@mui/icons-material'
import RegisterFundCustomer from '#pages/FundCustomers/FundCustomerOverview/RegisterFundCustomer'
import FundCustomerList from '#pages/FundCustomers/FundCustomerOverview/FundCustomerList'
import {useTranslation} from 'react-i18next'

export default function FundCustomerOverview() {
    const {t} = useTranslation()

    const [customerSearchQuery, setCustomerSearchQuery] = useState('')

    const fundCustomers = useSelector(state => state.fundCustomers)

    const filteredFundCustomers = fundCustomers.filter(customer => !customerSearchQuery || sanitizeString(`${customer.name}${customer.nationalIdentity}`).includes(sanitizeString(customerSearchQuery))) ?? []

    return (
        <Fragment>
            <PageHeader title={t('pages.fundCustomers.customers')}/>
            <PageLayout>
                <Paper title={'Ny kunde'}>
                    <RegisterFundCustomer/>
                </Paper>
                <Paper title={t('pages.fundCustomers.header')} sx={{width: "100%"}}>
                    <TextField
                        placeholder={t('pages.fundCustomers.customerSearchPlaceholder')}
                        value={customerSearchQuery}
                        onChange={e => setCustomerSearchQuery(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{
                                        opacity: customerSearchQuery.length === 0 ? 0 : 1,
                                        transformOrigin: 'center',
                                        transform: customerSearchQuery.length === 0 ? 'scale(0)' : 'scale(1)',
                                        transition: '0.2s ease-in-out',
                                    }}
                                >
                                    <IconButton size={'small'} onClick={() => setCustomerSearchQuery('')}>
                                        <Clear fontSize={'small'}/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FundCustomerList customers={filteredFundCustomers}/>
                </Paper>
            </PageLayout>
        </Fragment>
    );
}

function sanitizeString(string: string): string {
    // lowercase, remove whitespace, convert accented characters to non-accented, convert ø to o, convert æ to e, remove special characters, remove double characters
    return string.toLowerCase().replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace('ø', 'o').replace('æ', 'e').replace(/[^a-z0-9]/g, '').replace(/(.)\1+/g, '$1');
}
