import {bring} from '../network/external-api-connection'
import * as messages from './infoMessages'
import * as session from './session'
import * as websocket from '../network/websocket-connection'
import command from '../command/command'
import restEndpoint from '../rest/rest-endpoint'
import {format} from "date-fns"
import * as depositorActions from '#state/reducers/depositor'
import * as depositorsActions from '#state/reducers/depositors'
import {setPostalCodeSearchData} from '#state/reducers/postalCodeSearchData'

export const createDepositor = (nationalIdentity) => dispatch => {
    return command(dispatch).createDepositor(nationalIdentity).then(() => {
        dispatch(session.syncSessionState())
    })
}

export const requestNewDepositorData = (nationalIdentity) => dispatch => {
    websocket.sendMessage(websocket.websockets.MARKETPLACE_DATA, {
        name: 'NewDepositor',
        filter: {nationalIdentity}
    })
}

export const setCustomerDeclarationValue = (depositorId, path, value) => (dispatch) => {
    dispatch(depositorActions.setCustomerDeclarationValue({depositorId, path, value}))
    dispatch(depositorsActions.setCustomerDeclarationValue({depositorId, path, value}))
    return command(dispatch).setCustomerDeclarationValue(depositorId, path, value)
}

export const createCustomerDeclarationDraft = (depositorId) => dispatch => {
    return command(dispatch).createCustomerDeclarationDraft(depositorId)
}

export const addCustomerDeclarationValue = (depositorId, path, value) => (dispatch) => {
    dispatch(depositorActions.setCustomerDeclarationValue({depositorId, path, value}))
    dispatch(depositorsActions.setCustomerDeclarationValue({depositorId, path, value}))
}

export const deleteCustomerDeclarationValues = (depositorId, paths) => (dispatch) => {
    dispatch(depositorActions.deleteCustomerDeclarationValues({depositorId, paths}))
    dispatch(depositorsActions.deleteCustomerDeclarationValues({depositorId, paths}))
    return command(dispatch).deleteCustomerDeclarationValues(depositorId, paths)
}

export const removeUserFromDepositor = (depositorId, userId) => dispatch => {
    return command(dispatch).removeUserFromDepositor(depositorId, userId).then(() => {
        dispatch(messages.addSelfRemovingInfoMessage('info', 'Brukeren ble fjernet'))
    })
}

export const createUserInvite = (depositorId, firstName, lastName, email, birthDate) => (dispatch) => {
    try {
        const birthday = format(birthDate, 'yyyy-MM-dd')
        return command(dispatch).createUserInvite(depositorId, 'DEPOSITOR', firstName, lastName, email, birthday)
    } catch (err) {
        // handle later on, see trello
    }
}

export const removeUserInvite = (depositorId, inviteId) => (dispatch) => {
    return command(dispatch).removeUserInvite(depositorId, inviteId)
}

export const removeUserRequestFromDepositor = (depositorId, nationality, nationalIdentity) => (dispatch) => {
    return command(dispatch).removeUserRequestFromDepositor(depositorId, nationalIdentity, nationality)
}

export const resendUserInvite = (depositorId, inviteId, email) => (dispatch) => {
    return command(dispatch).resendUserInvite(depositorId, inviteId, email)
}

export const setPinCodeAuthorization = (organisationId, organisationType, allow) => (dispatch) => {
    return command(dispatch).setPinCodeAuthorization(organisationId, organisationType, allow)
}

export const getBankName = account => dispatch => {
    return restEndpoint(dispatch).getBankName(account.substring(0, 4)).then(({bankName}) => {
        if (!bankName) throw new Error('Could not map bank name')
        return bankName
    })
}

export const addDepositorAccount = (depositorId, accountName, accountNumber, iban, bic) => (dispatch) => {
    return command(dispatch).addSettlementAccount(depositorId, accountName, accountNumber, iban, bic)
}

export const removeDepositorAccount = (depositorId, settlementAccountId) => (dispatch) => {
    return command(dispatch).removeSettlementAccount(depositorId, settlementAccountId)
}

export const generateCustomerDeclaration = (depositorId) => dispatch => {
    return command(dispatch).generateCustomerDeclaration(depositorId)
}

export const removeCustomerDeclaration = (depositorId) => dispatch => {
    return command(dispatch).removeCustomerDeclaration(depositorId)
}

export const distributeCustomerDeclaration = (depositorId) => dispatch => {
    return command(dispatch).distributeCustomerDeclaration(depositorId)
}

export const getPostalCode = (postalCode, countryCode) => dispatch => {

    let pattern
    switch (countryCode) {
        case 'NO':
            pattern = /\d{4}/
            break
        case 'SE':
            pattern = /\d{3}\s?\d{2}/
            break
    }
    if (!postalCode || !postalCode.match(pattern)) {
        dispatch(setPostalCodeSearchData({
            valid: false,
            result: undefined,
        }))
        return
    }

    return bring(dispatch).getPostalCode(postalCode, countryCode)
        .then(result => dispatch(setPostalCodeSearchData(result)))
}

export const generateAuthorizationDocument = (forUserId, fromUserIds, depositorId, multipleSigners) => dispatch => {
    return command(dispatch).generateAuthorizationDocument(forUserId, fromUserIds, depositorId, multipleSigners)
}

export const deleteAuthorizationDocument = (authorizedUserId, depositorId) => dispatch => {
    return command(dispatch).deleteAuthorizationDocument(authorizedUserId, depositorId)
}

export const acceptDepositorTerms = (depositorId, documentId) => dispatch => {
    return command(dispatch).acceptDepositorTerms(depositorId, documentId)
}

export const setUserInviteRoles = (inviteId, depositorId, roles) => dispatch => {
    return command(dispatch).setUserInviteRoles(inviteId, depositorId, roles)
}

export const setEscaliConfiguration = (depositorId, escaliConfiguration) => dispatch => {
    return command(dispatch).setEscaliConfiguration(depositorId, escaliConfiguration)
}

export const setDepositorShortName = (depositorId, shortName) => dispatch => {
    return command(dispatch).setDepositorShortName(depositorId, shortName)
}

