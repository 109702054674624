import {TerminationState} from '#state/selectors'
import { DepositDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from '@mui/material'


export const depositWarnings = (deposit: DepositDto) : { hasWarnings: boolean, isDepositExpired: boolean, isBeingTerminated: boolean, isChangingSettlementAccount: boolean, hasExtensionOffer: boolean } => {
    const isDepositExpired = deposit?.expires && deposit?.expires?.expired && deposit?.expires?.days > -3
    const isBeingTerminated = TerminationState.STARTED === deposit?.terminationState
    const isChangingSettlementAccount = deposit?.changedSettlementAccountStatus === 'DOCUMENT_SENT_TO_BANK'
    const hasExtensionOffer = deposit?.extensionOfferState === 'READY_FOR_SIGNING'

    return {
        hasWarnings: isDepositExpired || isBeingTerminated || isChangingSettlementAccount || hasExtensionOffer,
        isDepositExpired: isDepositExpired,
        hasExtensionOffer: hasExtensionOffer,
        isBeingTerminated: isBeingTerminated,
        isChangingSettlementAccount: isChangingSettlementAccount
    }
}

export default function ProcessInformation({deposit, showTags}:{deposit: DepositDto, showTags: boolean}) {
    const {t} = useTranslation()
    const warnings = depositWarnings(deposit)

    return warnings.hasWarnings ? (
        <Alert sx={{ py: 1, '& .MuiAlert-action': {py: 0} }} severity='warning' action={
            warnings.isBeingTerminated && (
                <Button variant='outlined' className="signAndSendDocumentLink" href={`/portfolio/id/${deposit?.id}`} color="inherit" size="small">
                    {t('pages-portfolio-depositor.signAndSendDocument')}
                </Button>
            )
        }>
            { warnings.isDepositExpired && <span>{t('pages-portfolio-depositor.depositExpired')}</span> }
            { warnings.isBeingTerminated && (
                <span className="depositTerminationDocumentCreatedInfo">
                    {t('pages-portfolio-depositor.terminationCreated')}
                </span>
            )}
            { warnings.isChangingSettlementAccount && (
                <span className="depositAccountChangeSentInfo">
                    {t('pages-portfolio-depositor.settlementAccountChangeSent')}
                </span>
            )}
            { warnings.hasExtensionOffer && (
                <span className="depositHasExtensionOffer">
                    {t('pages-portfolio-depositor.extensionOfferProcessInformation')}
                </span>
            )}
        </Alert>
    ) : null
}
