import IBAN from 'iban'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { CountryCode, RegulatoryRegion } from '@fixrate/fixrate-query'

export function formatNationalIdentity(nationalIdentity: string, nationality: string): string {
    switch (nationality) {
        case 'NO':
            return `${nationalIdentity.substring(0, 6)} ${nationalIdentity.substring(6, 11)}`
        case 'SE':
            return `${nationalIdentity.substring(0, 8)}-${nationalIdentity.substring(8, 12)}`
        default:
            return nationalIdentity
    }
}

export function formatOrganizationNumber(organizationNumber: string, country: RegulatoryRegion): string {
    if (!organizationNumber) {
        return null
    }
    
    const cleanedOrgNumber = organizationNumber.replace(/\s/g, '')

    if (country === "SE") {
        return `${cleanedOrgNumber.substring(0, 6)}-${cleanedOrgNumber.substring(6, 10)}`
    }
    
    if (country === "NO") {
        return `${cleanedOrgNumber.substring(0, 3)} ${cleanedOrgNumber.substring(3, 6)} ${cleanedOrgNumber.substring(6, 9)}`
    }
}

export function formatAccount(account: string, country: RegulatoryRegion): string {
    // mongo query to check if the field payload.toAccount contains .
    // db.transactions.find({ "payload.toAccount": { $regex: /\./ } })
    if (!account) return null
    if (country === "SE") {
        return `${account.substring(0, 4)}-${account.substring(4, 20)}`
    }
    return `${account.substring(0, 4)}.${account.substring(4, 6)}.${account.substring(6, 11)}`
}

export function formatIban(iban: string): string {
    if (!iban) return null
    return IBAN.printFormat(iban, ' ')
}

export function formatNumberRoundedToMillions(value: number) {
    if (!value) return null
    return Math.floor(value / 1e6)
}

export function formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
        return ""
    }
    const formatObj = parsePhoneNumber(phoneNumber)
    if (formatObj.possibility === "invalid-country-code") {
        return parsePhoneNumber(phoneNumber, {regionCode: "NO"})?.number?.national ?? phoneNumber
    }
    return parsePhoneNumber(phoneNumber)?.number?.international ?? phoneNumber
}

