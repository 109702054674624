import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import config from '#app/config'
import styles from '#app/layers/Login/Login.module.css'

const API_BASE_URL = config().apiUrl

type Props = {
    loginUrl: string,
    onLoginSuccess: () => void,
    onLoginSuccessForce: () => void,
    onLoginAbort?: () => void
}

export default function LoginPortalIframe({loginUrl, onLoginSuccess, onLoginSuccessForce, onLoginAbort = null} : Props) {
    const apiUrl = API_BASE_URL || window.location.origin

    const createHash = () => Math.random().toString(36).substr(5, 10)

    const [iframeReloadHash, setIframeReloadHash] = useState(createHash())
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const iframeRef = useRef(null)


    useEffect(() => {

        const _onLoginAbort = () => {
            setIframeLoaded(false)
            setIframeReloadHash(createHash())

            if (onLoginAbort) {
                onLoginAbort()
            }
        }

        /*
         Listens for message sent by the javascript in the web page created by the IdP response page
         upon a successful login
         */
        const callback = (event) => {
            const origin = event?.origin || event.originalEvent?.origin // For Chrome, the origin property is in the event.originalEvent object.
            if (origin.startsWith(apiUrl) && (event.data === 'login success' || event.data === 'login abort' || event.data === 'login success force')) {
                // console.log('Got login event from iframe: ' + event.data)
                if (event.data === 'login success') {
                    onLoginSuccess()
                } else if (event.data === 'login success force') {
                    onLoginSuccessForce()
                } else if (event.data === 'login abort') {
                    _onLoginAbort()
                }
            }
        }

        console.log('Registering the login iframe window event listener')
        window.addEventListener('message', callback, false)

        return () => {
            console.log('Removes the login iframe window event listener')
            window.removeEventListener('message', callback, false)
        }
    }, [apiUrl, onLoginAbort, onLoginSuccess, onLoginSuccessForce])

    const iframeIsLoaded = () => {
        setIframeLoaded(true)
        iframeRef.current.contentWindow.focus()
    }

    // Inline styles that matches the hard coded l&f of the BankID widget
    const iframeStyle = {
        width: '100%',
        height: 280,
        maxWidth: 500,
    } as React.CSSProperties

    if (!iframeLoaded) {
        iframeStyle.display = 'none'
    }

    const waitMessageStyle = {
        width: '100%',
        maxWidth: 500,
        height: 285,
    }
    const waitMessageBoxStyle = {
        width: '100%',
        maxWidth: 500,
        height: 160,
        backgroundColor: 'white',
        borderRadius: 3,
    }
    const waitMessage = iframeLoaded ? null : (
        <div style={waitMessageStyle}>
            <div style={waitMessageBoxStyle}/>
        </div>
    )

    return (
        <div className={styles.iframeLogin}>
            {waitMessage}
            <iframe title={'loginportal'} key={iframeReloadHash} ref={iframeRef} style={iframeStyle} src={loginUrl} frameBorder="0" onLoad={iframeIsLoaded} data-cy="loginPortalIframe"/>
        </div>
    )
}
