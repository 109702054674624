import { PageHeader, PageLayout } from '#app/components';
import { useEndpoint } from "#command";
import { CurrencyTabber } from "#components/CurrencyTabber/CurrencyTabber";
import usePortfolio from "#services/usePortfolio";
import { useSelector } from "#state/useSelector";
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function BankDepositReports() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { setPortfolioDepositorSession } = useEndpoint()

    const portfolios = useSelector(state => state.depositor?.portfolios)
    const currencies = portfolios?.map(p => p.currency) || []
    const portfolio = usePortfolio()
    const selectedCurrency = portfolio?.currency

    const switchCurrency = (currency: string) => {
        if (currency !== portfolio?.currency) {
            const portfolioWithCurrency = portfolios.find(p => p.currency === currency)
            setPortfolioDepositorSession(portfolioWithCurrency.id).catch((err) => {
                console.log(err)
            })
        }
    }

    return (
        <>
            <PageHeader icon="ri-file-chart-2-line" title={t('pagetitles.reports')}/>
            <PageLayout>
                <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="space-between">
                    { currencies.length > 1 && ( <CurrencyTabber currencies={currencies} selectedCurrency={selectedCurrency} switchCurrency={switchCurrency}/>
                    )}
                </Stack>
                <ToggleButtonGroup sx={{mt: 3, mb: 3, backgroundColor: 'white'}} value={location.pathname} size={'small'} color={'primary'}>
                    <ToggleButton sx={{px: 2}} value={'/reports/deposits/interest-balance'} onClick={(_, url) => navigate(url)}>
                        {t('pages-portfolio-depositor.reportsInterestAndBalanceHeader')}
                    </ToggleButton>
                    <ToggleButton sx={{px: 2}} value={'/reports/deposits/collected-statement'} onClick={(_, url) => navigate(url)}>
                        {t('pages-portfolio-depositor.reportsCollectedStatementHeader')}
                    </ToggleButton>
                    <ToggleButton sx={{px: 2}} value={'/reports/deposits/annual-statements'} onClick={(_, url) => navigate(url)}>
                        {t('pages-portfolio-depositor.annualStatementsHeader')}
                    </ToggleButton>
                </ToggleButtonGroup>
                <Outlet />
            </PageLayout>
        </>
    )
}
