import {useEffect, useState} from 'react'
import {correctDate} from '#services/dateandtime'
import {useTranslation} from 'react-i18next'

export function useAdCountDownText(ad: {validity: string}) {

    const {t} = useTranslation()
    const [adCountDown, setAdCountDown] = useState<string>(calculateAdCountDown(ad, t))

    useEffect(() => {
        const id = setInterval(() => {
            const newText = calculateAdCountDown(ad, t)
            setAdCountDown((currentText) => currentText !== newText ? newText : currentText)
        }, 1000)
        return () => {
            clearInterval(id)
        }
    }, [ad, t])

    return adCountDown
}

function calculateAdCountDown(ad, t) {

    const adValidity = typeof ad.validity === 'string' ? new Date(ad.validity) : ad.validity
    const timeCode = timeDifference(correctDate(), adValidity)
    return getCountdownText(timeCode, t)
}

export function timeDifference(date1, date2) {

    if (!date1 || !date2 || !date1.getTime || !date2.getTime) {
        return ''
    }

    // Convert both dates to milliseconds
    const date1_ms = date1.getTime()
    const date2_ms = date2.getTime()

    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms

    if (difference_ms < 0) {
        return ''
    }

    // Calculate seconds, minutes, hours, days etc.
    let difference = difference_ms / 1000
    const seconds = Math.floor(difference % 60)
    difference = difference / 60
    const minutes = Math.floor(difference % 60)
    difference = difference / 60
    const hours = Math.floor(difference)
    // Use this code if we want to show days as well
    //let hours = Math.floor(difference % 24);
    //let days = Math.floor(difference / 24);

    if (hours > 12) {
        return ''
    }
    if (hours > 1) {
        return hours + 'h'
    }
    if (hours === 1) {
        return '1h'
    }
    if (minutes > 1) {
        return minutes + 'm'
    }
    if (minutes === 1) {
        return '1m'
    }
    if (seconds > 1) {
        return seconds + 's'
    }
    if (seconds === 1) {
        return '1s'
    }
    return 'now'
}

function getCountdownText(timeCode, t) {
    switch (timeCode) {
        case 'now':
            return t('pages-marketplace.now')
        case '1h':
            return '1 ' + t('pages-marketplace.hour') + ' ' + t('pages-marketplace.remaining')
        case '1m':
            return '1 ' + t('pages-marketplace.minute') + ' ' + t('pages-marketplace.remaining')
        case '1s':
            return '1 ' + t('pages-marketplace.second') + ' ' + t('pages-marketplace.remaining')
        default:
            if (timeCode.endsWith('h')) {
                return timeCode.substr(0, timeCode.length - 1) + ' ' + t('pages-marketplace.hours') + ' ' + t('pages-marketplace.remaining')
            } else if (timeCode.endsWith('m')) {
                return timeCode.substr(0, timeCode.length - 1) + ' ' + t('pages-marketplace.minutes') + ' ' + t('pages-marketplace.remaining')
            } else if (timeCode.endsWith('s')) {
                return timeCode.substr(0, timeCode.length - 1) + ' ' + t('pages-marketplace.seconds') + ' ' + t('pages-marketplace.remaining')
            } else {
                return ''
            }
    }
}
