import { useCommand } from '#command';
import Modal from '#components/Modal';
import { DepositorDto } from '@fixrate/fixrate-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './EditShortName.module.css';

type Props = {
    depositor: DepositorDto
}

export default function EditShortName({depositor}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {setDepositorShortName} = useCommand()

    const [shortName, setShortName] = useState(depositor?.shortName ?? '')

    async function onSubmit() {
        try {
            await setDepositorShortName(depositor.id, shortName != null && shortName.length > 0 ? shortName : null)
            navigate(`/organizations/${depositor.id}`)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Modal
            cancel={`/organizations/${depositor.id}`}
            header={t('pages-organizations.shortNameHeading', {depositorName: depositor?.name})}
            onSubmit={onSubmit}
            width="60rem"
        >

            <div className={styles.modalContent}>
                <p>{t('pages-organizations.shortNameMessage')}</p>

                <div className={styles.checkElement}>
                    <input
                        checked={shortName && shortName.length > 0} id="useShortNameCheckbox"
                        onChange={(e) => {
                            setShortName(e.target.checked ? depositor?.name ?? '' : '')
                        }}
                        type="checkbox"
                    />

                    <label htmlFor="useShortNameCheckbox">
                        {t('pages-organizations.useShortName')}
                    </label>
                </div>

                <input
                    className={styles.inputField}
                    name="shortName"
                    onChange={e => setShortName(e.target.value)}
                    required={true}
                    type="text"
                    value={shortName}
                />

            </div>
        </Modal>
    )
}
