import {CustomLink} from '#app/layers/SupportPane/notion/CustomLink'
import {NotionRenderer} from 'react-notion-x'
import useSupportPane from '#services/useSupportPane'
import {LoadingSpinner} from '#app/components'

export default function NotionBody() {

    const supportPane = useSupportPane()

    if (supportPane.isLoading) {
        return (
            <LoadingSpinner/>
        )
    }

    // Removes all images with block.value.version === 2
    // This is a workaround for a bug in react-notion-x
    const filteredBlocks = {}
    Object.values(supportPane.notionRecordMap.block).forEach(block => {
        if (block.value.type === 'image' && block.value.version === 2) {
            console.log('Ignoring block ' + block.value.id)
        } else {
            filteredBlocks[block.value.id] = block
        }
    })
    const notionRecordMap = {
        ...supportPane.notionRecordMap,
        block: filteredBlocks
    }

    return (
        <NotionRenderer recordMap={notionRecordMap} components={{PageLink: CustomLink}}
                        isImageZoomable={false}/>
    )

}