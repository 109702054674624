import {useJwt} from '#services/useJwt'
import {useQuery} from 'react-query'
import format from 'date-fns/format'

import {DateAsString, FundReturnPeriod} from '@fixrate/fixrate-query'
import {subDays} from 'date-fns'
import subYears from 'date-fns/subYears'

export interface NavAndReturn {
    returnRates: { [P in FundReturnPeriod]?: number };
    annualReturnRates: { [P in FundReturnPeriod]?: number };
    navWithPerformance: NavWithPerformance[];
}

export interface NavWithPerformance {
    date: DateAsString | null;
    nav: number | null;
    accrual: number | null;
    units: number | null;
    value: number | null;
}

const MAX_DATE = subDays(new Date(), 1)
const MIN_DATE = subYears(MAX_DATE, 10)


export function useFundMarketData(isin: string, minDate= MIN_DATE, maxDate= MAX_DATE) {
    const {jwt, isJwtLoading} = useJwt()
    const {data, isLoading, isSuccess} = useQuery({
        queryFn: async () => {
            if(isin === null){
                return Promise.reject('No isin provided')
            }
            const start = format(minDate, 'yyyy-MM-dd')
            const end = format(maxDate, 'yyyy-MM-dd')

            try {
                const data = await fetch(
                    `/api/nav/${isin}/return?startDate=${start}&endDate=${end}`
                    , {headers: {Authorization: 'Bearer ' + jwt}})

                if(data.status !== 200){
                    return Promise.reject(data.status + ' ' + data.statusText)
                }

                const jsonData = await data.json() as NavAndReturn
                if (!jsonData) {
                    return Promise.reject('No data')
                }
                return jsonData as NavAndReturn
            } catch (error) {
                return Promise.reject(error)
            }
        },
        queryKey: ['FundReturnsChart', isin, minDate, maxDate ],
        staleTime: 1000 * 60 * 60, // Every hour
        cacheTime: 1000 * 60 * 61, // Every hour + a little extra
        enabled: !!jwt && !isJwtLoading, // Only run the query if jwt exists
    })

    return {data, isLoading, isSuccess, minDate, maxDate}
}
