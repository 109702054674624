import * as React from 'react';
import {Card, CardContent, Typography, useMediaQuery, useTheme} from '@mui/material'
import {Intl, Temporal} from '@js-temporal/polyfill'
import DateTimeFormatOptions = Intl.DateTimeFormatOptions


//
type Props = {
    date: Temporal.PlainDate
    dim: boolean
    children?: React.ReactNode
}
const CalendarDay = ({date,dim,children}: Props): JSX.Element => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSunday = date.dayOfWeek === 7
    const isToday = date.equals(Temporal.Now.plainDateISO())
    const localeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as DateTimeFormatOptions;

    return (
        <Card variant="outlined" sx={
            {
                ...(dim && { opacity: 0.5 }),
                ...{borderRadius:0,flex:1}
            }
        }>
            <CardContent sx={{...{p:1},}}>
                <Typography sx={{...{ fontSize: '15px', fontWeight: 700 }, ...(isToday && { borderRadius: 100, width: '22px', height:'22px', backgroundColor: 'lightblue', textAlign: 'center' }), }} color={isSunday && 'red'} >
                    {isMobile? date.toLocaleString('nb-NO',localeOptions):date.day}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">

                </Typography>
                <Typography variant="body2">
                    {children}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default CalendarDay;
