import { Fragment, useEffect, useState } from 'react';
import styles from './LoginPortal.module.scss'
import classNames from 'classnames'
import config from '#app/config'
import LoginPortalIframe from '#app/layers/Login/LoginPortalIframe'
import useRealm from '#services/useRealm'
import {useTranslation} from 'react-i18next'

type Props = {
    method: string[] | null,
    onLoginSuccess: () => void,
    onLoginSuccessForce: () => void,
}

const BASE_LOGIN_URL = '/auth/portal'

export default function LoginPortal({method = null, onLoginSuccess, onLoginSuccessForce} : Props) {

    const {t, i18n} = useTranslation()
    const realm = useRealm()
    const [bankIdMessage, setBankIdMessage] = useState('')

    let url = BASE_LOGIN_URL
    if (realm) {
        url = url + (url.indexOf('?') > 0 ? '&' : '?') + `realm=${realm}&lang=${i18n.language}`
    }
    if (method) {
        url = url + (url.indexOf('?') > 0 ? '&' : '?') + `method=${method.join(',')}&lang=${i18n.language}`
    }

    useEffect(() => {
        fetch(config().bankIdStatusUrl)
            .then(response => response.ok && response.text())
            .then(result => {
                setBankIdMessage(result.trim())
            })
            .catch(console.error)
    }, [])

    return (
        <Fragment>
            <LoginPortalIframe loginUrl={url}
                               onLoginSuccess={onLoginSuccess}
                               onLoginSuccessForce={onLoginSuccessForce}
            />
            {bankIdMessage && (
                <div className={styles.infoFooter}>
                    <div className={styles.title}>
                        <div className={styles.ballContainer}>
                            <div className={styles.ball}/>
                            <div className={classNames(styles.ball, styles.pulse)}/>
                        </div>
                        <p>{t('layers-Login.messageFromBankID')}</p>
                    </div>
                    <div className={styles.messageBox}>
                        <p className={styles.message}>{bankIdMessage}</p>
                    </div>
                    <p>{t('layers-Login.bankIDLink')} <a target="_blank" rel="noopener noreferrer" href="https://bankid.no/status">BankID</a>.</p>
                </div>
            )}
        </Fragment>
    );
}
