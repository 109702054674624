import StatusMessageBanner from '#blocks/StatusMessageBanner/StatusMessageBanner'
import { Button, Dialog, useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './Welcome.module.scss'

export default function Welcome() {
    const {t} = useTranslation()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const navigate = useNavigate()

    useEffect(() => {
        document.getElementById('loginButton')?.focus()
    }, [])

    const registrationMsg = (
        <>
            <h3>{t('layers-Signup.welcomeHeading')}</h3>
            <p className={styles.message}>
                {t('layers-Signup.welcomeMessage')}
            </p>
            <Button variant={'outlined'} data-cy="startRegistrationButton"
                    className={styles.registerButton}
                    onClick={() => navigate('/marketplace?register')}>{t('layers-Signup.startRegistration')}</Button>
        </>
    )

    function navigateToLogin(realm?: string) {
        if (!realm) {
            navigate('/marketplace?login')
        } else {
            navigate(addRealm('/marketplace?login', 'bank'))
        }
    }

    return (
        <Dialog fullScreen={fullScreen} className={styles.dialogContainer} open={true} fullWidth={true}>
            <div className={styles.body}>
                <h1>{t('layers-Signup.logInToFixrate')}</h1>
                <Button variant={'contained'} id="loginButton" className={styles.loginButton}
                        onClick={() => navigateToLogin()}>{t('layers-Signup.logIn')}</Button>
                <div style={{marginTop: 32}}>
                    <StatusMessageBanner/>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.footerBlock}>
                        {registrationMsg}
                    </div>
                </div>
            </div>
        </Dialog>
    )

    function addRealm(url: string, realm: string) {
        const separator = url.indexOf('?') > -1 ? '&' : '?'
        return url + separator + 'realm=' + realm
    }

}
