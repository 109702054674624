import { useCommand } from '#command'
import ModalConfirmation from '#components/ModalConfirmation/ModalConfirmation'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

type Params = {
    id?: string
    depositorId?: string
}

export default function CustomerDetailRemoveAdvisor() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const partner = useSelector(state => state.partner)
    const customer = partner?.customers.find(c => c.depositorId === params.depositorId)
    const {removePartnerAdvisor} = useCommand()
    const userFullName = partner.knownUserFullNames[params.id]


    async function remove() {
        const {waitForCommand} = await removePartnerAdvisor(partner.id, customer.depositorId, params.id)
        const success = await waitForCommand()
        if (success) {
            navigate(`/customer/${customer.depositorId}`)
        }
    }

    return (
        <ModalConfirmation
            backUrl={`/customer/${customer.depositorId}`}
            header={t('pages-customers.customerDetailRemoveAdvisorHeading')}
            onSubmit={remove}
            text={t('pages-customers.customerDetailRemoveAdvisorMessage', {userFullName, customerName: customer.name})}
        />
    )
}
