import DocumentList from '#components/DocumentList/DocumentList'
import Paper from '#app/components/Paper/FxPaper'
import { bankDocumentTypeTMap } from '#services/enumTranslationKeyMapping'
import { BankDocumentDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const DepositDetailsBankDocuments = ({ bankDocuments }: {bankDocuments: BankDocumentDto[] }) => {
    const {t} = useTranslation()

    const formattedBankDocuments = bankDocuments.map(bankDocument => {
        return {
            ...bankDocument,
            name: bankDocument.type ? t(bankDocumentTypeTMap[bankDocument.type]) : t('common.document'),
            link: '/api/document/bank_document/' + bankDocument.documentId + '/data'
        }
    })

    return (
        <Paper title={t('pages-portfolio-depositor.bankDocumentsHeader')}>
            <p>
                {t('pages-portfolio-depositor.bankDocumentsInfo')}
            </p>
            <p>
                <NavLink to={'/reports/deposits/collected-statement'}>{t('pages-portfolio-depositor.monthlyReportsLink')}</NavLink>
            </p>
            <DocumentList documents={formattedBankDocuments} sortDirection={'desc'} maxInitialLength={10}/>
        </Paper>
    )
}

export default DepositDetailsBankDocuments
