import styles from "./AIIcon.module.scss";
import classNames from "classnames";

export default function AIIcon({loading, height} : {loading: boolean, height?: number}) {
    const heightValue = height || 105
    const widthValue = height ? height * 1.02 : 107
    return (
        <svg className={classNames(styles.AIIcon, loading && styles.loading)} width={widthValue} height={heightValue} viewBox="0 0 107 105" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M79.1438 81.7386C63.5057 95.9487 39.2857 94.7678 25.047 79.0982C17.8913 71.2234 12.8827 61.3506 11.6187 51.689C10.3565 42.0408 12.8263 32.6197 20.6054 25.551C30.7832 16.3027 44.4561 14.3105 57.4253 16.6263C70.4004 18.9432 82.5862 25.5601 89.7143 33.4046C96.803 41.2057 98.0798 49.684 95.6523 58.0023C93.2147 66.3552 87.0306 74.5721 79.1438 81.7386Z" stroke="url(#paint0_linear_2_26)"/>
            <path d="M84.8835 31.3057C96.2918 47.3422 92.5158 69.6097 76.4468 81.0411C68.3683 86.7881 58.6804 90.3265 49.6049 90.4247C40.5429 90.5228 32.1075 87.1953 26.4347 79.2211C19.0106 68.7852 18.682 55.9194 22.2622 44.1778C25.8443 32.4303 33.3169 21.891 41.362 16.1678C49.3594 10.4785 57.3366 10.2377 64.757 13.4031C72.2106 16.5825 79.1263 23.2129 84.8835 31.3057Z" stroke="url(#paint1_linear_2_26)"/>
            <defs>
            <linearGradient id="paint0_linear_2_26" x1="82.1401" y1="24.3258" x2="23.2371" y2="77.8498" gradientUnits="userSpaceOnUse">
                <stop stopColor="#95E1BF"/>
                <stop offset="0.541667" stopColor="#B5FAAF"/>
                <stop offset="1" stopColor="#ADF2FC"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2_26" x1="32.0983" y1="22.1443" x2="75.1101" y2="82.6056" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4D2A98"/>
                <stop offset="0.484375" stopColor="#FF8383"/>
                <stop offset="0.958333" stopColor="#FFDC83"/>
            </linearGradient>
            </defs>
        </svg>
    )
}
