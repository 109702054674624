import { Fragment } from 'react';
import styles from './DepositorOfferCard.module.scss'
import {useSelector} from '#state/useSelector'
import classNames from 'classnames'
import {DateOutput, InterestOutput} from "#components"
import { useTranslation } from 'react-i18next'
import { DepositorOfferDto } from '@fixrate/fixrate-query';
import {defaultBenchmark} from '#services/interestRateBenchmark'
import {formatOrganizationNumber} from "#services/formatnumber";
import useCurrentCountryCode from "#services/useCurrentCountryCode";
import {Box, Divider, Stack, Typography} from "@mui/material";
import {PURPLE, SUNSET_ORANGE} from "#app/colors/colors";

export default function DepositorOfferCard({ depositorOffer, onClick }: { depositorOffer: DepositorOfferDto, onClick: (depositorOfferId: string) => void }) {
    const {t} = useTranslation()
    const organisationCountry = useCurrentCountryCode()
    const product = useSelector(state => state.products[depositorOffer.productId])
    const benchmark = defaultBenchmark(depositorOffer.currency)
    const benchmarkInterestRate = useSelector(state => state.interestRateBenchmarks.defaultBenchmarkInterestRate)

    const deadlineDate = new Date(depositorOffer.deadline);
    const now = new Date();
    const twentyFourHoursFromNow = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    const isExpired = deadlineDate < now
    const nearlyExpired = deadlineDate > now && deadlineDate <= twentyFourHoursFromNow;
    const isEmpty = depositorOffer.minVolume > depositorOffer.remainingVolume

    const deadlineText = DateOutput.formatDateTime(depositorOffer.deadline)
    const isExpiredText = isExpired && t('pages-offer.expired')
    const isEmptyText = isEmpty && t('pages-offer.filled')

    let maxVolume
    if (isExpiredText || isEmpty) {
        maxVolume = depositorOffer.maxVolume
    } else {
        maxVolume = Math.max(Math.min(depositorOffer.maxVolume, depositorOffer.remainingVolume), depositorOffer.minVolume)
    }

    let volumeElem
    if (maxVolume === depositorOffer.minVolume) {
        volumeElem = (
                <div>{depositorOffer.minVolume} {t('pages-offer.mill')}</div>
        )
    } else {
        volumeElem = (
                <div>{depositorOffer.minVolume}-{maxVolume} {t('pages-offer.mill')}</div>
        )
    }

    function getIcon() {
        if (isEmpty) return 'ri-stop-circle-line'
        if (isExpired) return 'ri-stop-circle-line'
        if (nearlyExpired) return 'ri-timer-line'
        return 'ri-time-line'
    }
    function getProductName(productId) {
        const fixedProducts = [1, 2, 3, 4, 11, 12]
        const regularProducts = [5, 6, 7, 10]
        const frnProducts = [8, 9]
        return (
            <div className={styles.constraints}>
                <span className={styles.contstraints__title}>
                    {regularProducts.includes(parseInt(productId)) && t('pages-marketplace.noticePeriod')}
                    {fixedProducts.includes(parseInt(productId)) && t('pages-marketplace.duration')}
                    {frnProducts.includes(parseInt(productId)) && t('pages-marketplace.product')}
                </span>
                <span className={styles.constraint__value}>
                    <span>{t(`common.productLongName${productId}`)}</span>
                </span>
            </div>
        )
    }
    return (
        <div className={styles.card} onClick={() => onClick(depositorOffer.depositorOfferId)}>
            <p className={styles.name}>
                <span className={styles.depositorIcon}>
                    <i className={'ri-building-line'}/>
                </span>
                <span>
                    <span>
                        <span className={styles.depositorName}>{depositorOffer.depositorName}</span>
                    </span>
                    <span className={styles.capital}>
                        {t('pages-offer.orgNo')} {formatOrganizationNumber(depositorOffer.depositorNationalIdentity, organisationCountry)}
                    </span>
                </span>
            </p>
            <div className={styles.interestgroup}>
                {product?.type === 'FIXED' ? (
                    <p className={styles.interest}>{InterestOutput.format(depositorOffer.interestRate)}</p>
                ) : (
                    <p className={styles.interest}>{InterestOutput.format(depositorOffer.interestRate+benchmarkInterestRate)}</p>
                )}
                <Box sx={{
                    backgroundColor: product?.type === 'FIXED' ? SUNSET_ORANGE[50] : PURPLE[50],
                    color: product?.type === 'FIXED' ? SUNSET_ORANGE[800] : PURPLE[500],
                    py: 0.6,
                    px: 1,
                }}>
                    {product?.type === 'FIXED' ? (
                        <Typography px={1} fontSize={'1.4rem'} fontWeight={'600'}>{t('pages-marketplace.fixedInterest')}</Typography>
                    ) : (
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            <Typography fontSize={'1.2rem'} fontWeight={'600'}>{InterestOutput.formatMarginWithBenchmarkObj(depositorOffer.interestRate, benchmark, t)?.label}</Typography>
                            <Divider sx={{borderColor: PURPLE[100], height: '1.8rem'}} orientation="vertical" />
                            <Typography fontSize={'1.4rem'} fontWeight={'700'}>{InterestOutput.formatMarginWithBenchmarkObj(depositorOffer.interestRate, benchmark, t)?.interest}</Typography>
                        </Stack>
                    )}
                </Box>
            </div>
            <div className={styles.productInfo}>
                <div className={styles.constraints}>
                    <Fragment>
                        <span className={styles.contstraints__title}>{t('pages-offer.volume')}</span>
                        <span className={styles.constraint__value}>
                            {volumeElem}
                        </span>
                    </Fragment>
                </div>
                {getProductName(product?.id)}
            </div>
            {(
                <p
                    className={classNames(
                        styles.message,
                        isEmpty && styles.message__filled,
                        isExpired && styles.message__expired,
                        nearlyExpired && styles.message__alert,

                    )}
                >
                    <span className={classNames(styles.bl, styles.corneredAngle)}></span>
                    <span className={styles.messageText}>
                        <i className={getIcon()}/>
                        <span className={styles.text}>{isEmptyText || isExpiredText || deadlineText}</span>
                    </span>
                </p>
                )}
        </div>
    )
}
