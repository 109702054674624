import {Fragment, useState} from 'react'
import config from '#app/config'
import {PageHeader, PageLayout, Paper} from '#components'
import styles from './Documents.module.css'
import {useTranslation} from 'react-i18next'

const API_BASE_URL = config().apiUrl

export default function Documents() {

    const {t} = useTranslation()
    const [search, setSearch] = useState('')

    const onSearchChange = (e) => {
        setSearch(e.target.value)
    }


    const unsignedLink = `${API_BASE_URL}/api/document/search/${search}/data`
    const signedLink = `${API_BASE_URL}/api/document/search/${search}/signeddata`

    const openUnsigned = search.length === 36 ?
        <a className={styles.openbutton} href={unsignedLink} target="_blank" rel="noreferrer">{t('pages-documents.openUnsigned')}</a>
        : <span className={styles.openbutton}>{t('pages-documents.openUnsigned')}</span>

    const openSigned = search.length === 36 ?
        <a className={styles.openbutton} href={signedLink} target="_blank" rel="noreferrer">{t('pages-documents.openSigned')}</a>
        : <span className={styles.openbutton}>{t('pages-documents.openSigned')}</span>

    return (
        <Fragment>
            <PageHeader title={t('pages-documents.documentsPageHeader')}/>

            <PageLayout>
                <Paper title={t('pages-documents.documentsHeader')}>
                    <p>
                        {t('pages-documents.openDocumentInfo')}
                    </p>

                    <p>
                        <label>{t('pages-documents.documentIdLabel')}:
                            <br/>
                            <input className={styles.search}
                                   name="documentSearch"
                                   value={search}
                                   onChange={onSearchChange}
                            />
                        </label>
                    </p>
                    <p className={styles.buttonrow}>
                        {openUnsigned}
                        {openSigned}
                    </p>
                </Paper>

                <Paper title={t('pages-documents.howTitle')}>
                    <p>
                        {t('pages-documents.howInfo1-1')} <a href="https://log.fixrate.no">log.fixrate.no</a> {t('pages-documents.howInfo1-2')}
                    </p>
                    <p>
                        {t('pages-documents.howInfo2')}
                    </p>
                    <ul>
                        <li><a target="_blank"
                               href="https://log.fixrate.no/search?relative=86400&q=source%3A+app-prod*+AND+message%3A+DocumentCreated"
                               rel="noreferrer">source:
                            app-prod* AND message: DocumentCreated</a></li>
                        <li><a target="_blank"
                               href="https://log.fixrate.no/search?relative=86400&q=source%3A+app-prod*+AND+message%3A+DocumentCreated+AND+documentType%3A+TERMS_DEPOSITOR"
                               rel="noreferrer">source:
                            app-prod* AND message: DocumentCreated AND documentType: TERMS_DEPOSITOR</a></li>
                    </ul>
                </Paper>

            </PageLayout>
        </Fragment>
    )
}

