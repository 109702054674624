import config from '#app/config'
import { PageHeader, PageLayout } from '#components'
import CoreCapitalCoverageChart from '#components/Charts/CoreCapitalCoverageChart'
import DepositCoverageChart from '#components/Charts/DepositCoverageChart'
import TotalAssetsChart from '#components/Charts/TotalAssetsChart'
import LoadingSpinner from '#components/LoadingSpinner'
import ColumnSplitter from '#components/PageLayout/ColumnSplitter'
import Paper from '#components/Paper'
import { getBankPresentation } from '#services/thunks/bank'
import { useSelector } from '#state/useSelector'
import { BankPresentationDto } from '@fixrate/fixrate-query'
import { parse } from 'query-string'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useLocation, useParams } from 'react-router-dom'
import BankPresentation from './BankPresentation'
import './carousel.css'

const BASE_URL = config().apiUrl

type Params = {
    id: string
}

export default function Bank() {
    const dispatch = useDispatch<(arg0: unknown) => Promise<BankPresentationDto>>()
    const {id: bankId} = useParams<Params>()
    const location = useLocation()

    const [bankPresentation, setBankPresentation] = useState<BankPresentationDto | undefined>()

    const bank = useSelector(state => state.banks[bankId])

    useEffect(() => {
        if (bank?.id) {
            dispatch(getBankPresentation(bank.id))
                .then(setBankPresentation)
                .catch(console.log)
        }
    }, [bank.id, dispatch])

    if (!bank) {
        return (
            <>
                <PageHeader/>
                <PageLayout/>
            </>
        )
    }

    const query = parse(location.search)
    const imageUrl = `${BASE_URL}/api/bank/${bank.id}/image/`

    return (
        <>
            <PageHeader title={bank.name} backToLink={query.context}/>
            <PageLayout>
                <Paper title={<img src={imageUrl + 'logo'} alt={bank.name} height={40}/>}>
                    {bankPresentation ? <BankPresentation bankPresentation={bankPresentation}/> : <LoadingSpinner />}
                </Paper>
                <ColumnSplitter/>
                <Paper title={'Nøkkelinfo'}>
                    {bankPresentation?.comment && bankPresentation.comment.split('\n').filter(s => !!s).map((line, i) => <p key={i}>{line}</p>)}
                    <TotalAssetsChart bankId={bank.id}/>
                    <CoreCapitalCoverageChart bankId={bank.id}/>
                    <DepositCoverageChart bankId={bank.id}/>
                </Paper>
            </PageLayout>
        </>
    )
}
