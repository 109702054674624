import { PURPLE } from '#app/colors/colors'
import { DateOutput, TableRow } from '#components'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import ProductBadge from '#components/ProductBadge/ProductBadge'
import { OrderDto, Product } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './OrdersBankListElement.module.scss'

export type Props = {
    order: OrderDto,
    product: Product,
    interest: number,
    isTableRow?: boolean
}

export default function OrdersBankListElement({order, product, interest, isTableRow = true /* isTableRow is required by Table.js to recognize this as a table row element */}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    let statusText
    let statusClass
    switch (order.orderState) {
        case 'INITIAL_PROCESSING':
        case 'READY_FOR_APPROVAL':
            statusText = t('pages-orders.bankStartProcessing')
            break
        case 'CREATED':
        case 'READY_FOR_SIGNING':
            statusText = t('pages-orders.bankWaitingForDepositor')
            statusClass = styles.listStatusNoaction
            break
        case 'READY_FOR_TRANSACTION':
        case 'READY_FOR_TRANSACTION_CONFIRMATION':
            statusText = t('common.confirm')
            break
        case 'CANCELED':
            statusText = t('pages-orders.bankRejected')
            statusClass = styles.listStatusNoaction
            break
        case 'COMPLETED':
            statusText = t('pages-orders.bankCompleted')
            statusClass = styles.listStatusNoaction
            break
    }

    const actionRequired = !statusClass

    function handleClick() {
        navigate(`/bank-orders/${order.id}`)
    }

    return (
        <TableRow
            key={order.id}
            style={{cursor: 'pointer'}}
            onClick={handleClick}
            data-cy={actionRequired ? 'orderListAction' : 'orderListElement'}
        >
            <td style={actionRequired ? {borderLeft: '4px solid ' + PURPLE[500]} : null}><ProductBadge productId={product?.id ?? '---'}/></td>
            <td><InterestBadge interest={interest}/></td>
            <td className={styles.listDepositor}>{order.depositor.name}</td>
            <td className={styles.listVolume}>{order.currency ?? 'NOK'} {order.volume} 000 000</td>
            <td className={styles.listDate}><DateOutput.DateTime date={order.created}/></td>
            <td className={classNames(styles.listStatus, statusClass)}>{statusText}</td>
        </TableRow>
    )
}
