import { PageHeader, PageLayout } from '#app/components'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useSelector } from '#app/state/useSelector'
import * as selectors from '#state/selectors'
import { hasFundAccessSelector } from '#state/selectors'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { OrganizationWizard } from './AddNewOrganization/OrganizationWizard/OrganizationWizard'
import Authorization from './Authorization/Authorization'
import {CustomerDeclaration} from './CustomerDeclaration/CustomerDeclaration'
import OrganisationDetail from './OrganizationDetail/OrganisationDetail'
import UserProfile from './OrganizationDetail/UsersAndRoles/views/UserProfile'
import './Organizations.scss'

export default function Organizations() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()
    const isLoggedIn = useSelector(selectors.isLoggedIn)
    const hasMadeDeposits = useSelector(state => state.deposits.length > 0)
    const hasMadeOrders = useSelector(state => state.orders.length > 0)
    const hasSingleDepositor = useSelector(state => state.session.associations.filter(o => o.organisationType === 'DEPOSITOR').length === 1)
    const numberOfAssociations = useSelector(state => state.session.associations?.length)
    const organisationType = useSelector(state => state.session.organisationType)
    const fundAvailable = useSelector(hasFundAccessSelector)

    if (organisationType !== 'DEPOSITOR' && numberOfAssociations > 0) {
        navigate('/marketplace')
        return null
    }

    const onboardingWizardBasePath = '/organizations/onboarding/' + depositor?.id
    const getWizardPath = (path: string) => {
        return depositor?.depositorSupportCategory === "UNKNOWN" ? onboardingWizardBasePath : onboardingWizardBasePath + path
    }

    // A new customer which is not managed that has not completed any orders or have multiple organizations
    // If customer has fund access, we need to give them full access to the organization menu to be able to sign the fund agreement.
    if (hasSingleDepositor && !hasMadeOrders && !hasMadeDeposits && depositor?.depositorSupportCategory !== 'MANAGED' && isLoggedIn && !fundAvailable) {
        return (
            <Routes>
                <Route path="onboarding/:id" element={<OrganizationWizard/>} />
                <Route path="onboarding/:id/:step" element={<OrganizationWizard/>} />
                <Route path=":id/customerdeclaration" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
                <Route path=":id/customerdeclaration/:fieldId" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
                <Route path=":id/users/board" element={<Navigate to={{ pathname: getWizardPath('/board') }} />} />
                <Route path=":id/accounts" element={<Navigate to={{ pathname: getWizardPath('/accounts') }} />} />
                <Route path=":id/customerdecl" element={<Navigate to={{ pathname: getWizardPath('/customerDeclaration') }} />} />
                <Route path=":id/terms" element={<Navigate to={{ pathname: getWizardPath('/terms') }} />} />
                <Route path={'' + depositor?.id + '/users/profile/:personId'} element={
                    <>
                        <PageHeader backToLink={onboardingWizardBasePath} title={t('pages-organizations.profileInformation')}/>
                        <PageLayout>
                            <UserProfile depositorId={depositor?.id} basePath={onboardingWizardBasePath}/>
                        </PageLayout>
                    </>
                } />
                <Route path="*" element={<Navigate to={{ pathname: onboardingWizardBasePath }} />} />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route
                path=""
                element={
                    <Navigate to={{
                        pathname: depositor ? `/organizations/${depositor.id}/users/all` : '/marketplace',
                        search: depositor ? "" : "registerCompany"}} />
                }
            />
            <Route path="onboarding/:id" element={<OrganizationWizard/>} />
            <Route path=":id/customerdeclaration/owner/:ownerKey" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
            <Route path=":id/customerdeclaration" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
            <Route path=":id/customerdeclaration/:fieldId" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
            <Route path=":organisationId/users/authorization/:userId/:showForm" element={<Authorization/>} />
            <Route path=":organisationId/users/authorization/:userId" element={<Authorization/>} />
            <Route path=":organisationId/users/authorization" element={<Authorization/>} />
            <Route path=":organisationId/users/authorizationforinvite/:inviteId/:showForm" element={<Authorization/>} />
            <Route path=":organisationId/users/authorizationforinvite/:inviteId" element={<Authorization/>} />
            <Route path=":organisationId/users/authorizationforinvite" element={<Authorization/>} />
            <Route path=":id/shortname/:showForm" element={<OrganisationDetail/>} />
            <Route path=":id/shortname" element={<OrganisationDetail/>} />
            <Route path=":id/*" element={<OrganisationDetail/>} />
        </Routes>
    )
}
