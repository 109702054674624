import {useTranslation} from 'react-i18next'
import {FundCategory, FundCustomerDto} from '@fixrate/fixrate-query'
import {fundCategoryTMap} from '#services/enumTranslationKeyMapping'
import SetPlatformFee from '#pages/FundCustomers/FundCustomerDetail/PlatformFee/SetPlatformFee'
import PlatformFeeHistory from '#pages/FundCustomers/FundCustomerDetail/PlatformFee/PlatformFeeHistory'
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from '@mui/material'
import {
    SetFixedMonthlyPlatformFee
} from "#pages/FundCustomers/FundCustomerDetail/PlatformFee/SetFixedMonthlyPlatformFee";
import {
    FixedMonthlyPlatformFeeHistory
} from "#pages/FundCustomers/FundCustomerDetail/PlatformFee/FixedMonthlyPlatformFeeHistory";

type Props = {
    customer: FundCustomerDto
}

export default function PlatformFee({customer}: Props) {
    const {t} = useTranslation()

    return (
        <Stack spacing={2}>
            {Object.keys(fundCategoryTMap).map((fundCategory: FundCategory) => (
                <dl key={fundCategory}>
                    <dt>
                        <h3><i className="ri-funds-box-line" style={{marginRight: '0.4rem'}}/><span>{t(fundCategoryTMap[fundCategory])}</span></h3>
                    </dt>
                    <dd>
                        <h4>{t('pages.fundCustomers.customerDetail.platformFee.editPriceSteps')}</h4>
                        <SetPlatformFee customer={customer} fundCategory={fundCategory}/>
                    </dd>
                    <dd style={{marginBottom: 32}}>
                        <h4>{t('pages.fundCustomers.customerDetail.platformFee.history')}</h4>
                        <PlatformFeeHistory depositorId={customer.depositorId} fundCategory={fundCategory} platformFeeHistory={customer.specialPlatformFeeHistory[fundCategory]}/>
                    </dd>
                </dl>
            ))}
            <Typography variant={'h2'}>
                <i className="ri-funds-box-line" style={{marginRight: '0.4rem'}}/>
                <Box component={'span'}>{t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFee.title')}</Box>
            </Typography>
            <Accordion defaultExpanded={false}>
                <AccordionSummary>
                    <i className="ri-arrow-down-s-line" style={{marginRight: '0.4rem'}}/>
                    <Box component={'span'}>{t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFee.explanation.header')}</Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction={'column'} spacing={1}>
                        <Typography variant={'body2'}>
                            {t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFee.explanation.p1')}
                        </Typography>
                        <Typography variant={'body2'}>
                            {t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFee.explanation.p2')}
                        </Typography>
                        <Typography variant={'body2'}>
                            {t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFee.explanation.p3')}
                        </Typography>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <FixedMonthlyPlatformFeeHistory customer={customer} />
            <SetFixedMonthlyPlatformFee customer={customer} />
        </Stack>
    )
}
