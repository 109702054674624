import AsyncButton from '#app/components/Button/AsyncButton'
import config from '#app/config'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import {useCommand} from '#command'
import {DateOutput} from '#components'
import DocumentLink from '#components/DocumentLink'
import {useTranslation} from 'react-i18next'
import LegacyCheckbox from '#components/LegacyCheckbox/LegacyCheckbox'
import Paper from '#components/Paper'
import {Changelog} from '#pages/newcustomerdeclarations/Changelog/Changelog'
import {formatOrganizationNumber} from '#services/formatnumber'
import {useFieldState} from '@fixrate/fieldstate'
import {useSelector} from '#state/useSelector'
import {DocumentDto, NewCustomerDeclarationDto} from '@fixrate/fixrate-query'
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import styles from './NewCustomerDeclarations.module.scss'
import {useCallback} from 'react'

const API_BASE_URL = config().apiUrl

interface Props {
    selectedCustomerDeclaration: NewCustomerDeclarationDto,
    document: DocumentDto,
}

export default function SelectedCustomerDeclaration({selectedCustomerDeclaration, document}: Props) {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const documents = useSelector(state => state.documents)
    const bankInSession = useSelector(state => state.bank)
    const session = useSelector(state => state.session)
    const {registerCustomerDeclaration} = useCommand()
    const organisationCountry = useCurrentCountryCode()
    const userHasCustomerServiceRole = bankInSession.users.some(
        (user) =>
            user.roles.includes("BANK_CUSTOMER_SERVICE") &&
            user.id === session.id
    )

    const authDocElements = document?.allSignatures
        .filter(signature => !!signature.authorizationDocumentId)
        .map(signature => documents[signature.authorizationDocumentId])
        .map(doc => <DocumentLink key={doc.id} name={doc.name} link={`${API_BASE_URL}${doc.link}`}/>)

    const confirmationValidation = useCallback(({value}) => {
        if(!userHasCustomerServiceRole){
            const names = bankInSession.users
                .filter((user) => user.roles.includes("BANK_CUSTOMER_SERVICE"))
                .map((user) => `${user.firstName} ${user.lastName}`)
                .join(", ");
            const lastIndex = names.lastIndexOf(",");
            const namesString =
                lastIndex !== -1
                    ? names.slice(0, lastIndex) +
                    " " +
                    t('common.and') +
                    names.slice(lastIndex + 1)
                    : names;
            return names.length > 0
                ? t('pages-newcustomerdeclarations.selectedCustomerDeclarationNoAccess', {names: namesString})
                : t('pages-newcustomerdeclarations.noCustomerService')
        }
        if (!value) {
            return t('pages-newcustomerdeclarations.selectedCustomerDeclarationCheckboxNeedsConfirmation')
        }
    }, [bankInSession.users, t, userHasCustomerServiceRole])
    const confirmation = useFieldState(false, confirmationValidation)

    async function onRegisterCustomerDeclaration(depositorId, bankId, documentId) {
        const valid = confirmation.validate()

        if (valid) {
            const {waitForCommand} = await registerCustomerDeclaration(depositorId, bankId, documentId)
            const success = await waitForCommand()
            if (success) {
                navigate('/newcustomerdeclarations#!')
                confirmation.setValue(false)
            }
        }
    }

    return (
        <Paper title={t('pages-newcustomerdeclarations.selectedCustomerDeclarationTitle')}
               className={styles.updatedCustomerDeclaration}
               transparent={true}
        >
            <div className={styles.content}>
                <p>{t('pages-newcustomerdeclarations.selectedCustomerDeclarationIngress')}</p>
                <div className={styles.customerInfo}>
                    <div className={styles.customerInfoHeader}>
                        <span>{t('pages-newcustomerdeclarations.selectedCustomerDeclarationOrganization')}</span>
                        <span>{t('pages-newcustomerdeclarations.selectedCustomerDeclarationChangeTime')}</span>
                    </div>
                    <div className={styles.customerInfoBody}>
                        <span className={styles.organisation}>
                            <span className={styles.name}>{selectedCustomerDeclaration.name}</span>
                            <span className={styles.orgNumber}>{formatOrganizationNumber(selectedCustomerDeclaration.nationalIdentity, organisationCountry)}</span>
                        </span>
                        <span>{DateOutput.formatDate(document.created)}</span>
                    </div>
                </div>
                <h4 className={styles.taskTitle}>
                    <i className="ri-download-line"/>
                    <span>{t('pages-newcustomerdeclarations.selectedCustomerDeclarationDownloadTitle')}</span>
                </h4>
                <Button variant={'contained'} startIcon={<i className="ri-file-line"/>}
                        target={"_blank"}
                        id={'newCustomerDeclarationDocumentLink'} href={`${API_BASE_URL}${documents[selectedCustomerDeclaration.documentId].link}`}>
                    {t('pages-newcustomerdeclarations.selectedCustomerDeclarationDownloadButton')}
                </Button>
                {authDocElements.length > 0 && (
                    <div className={styles.authDocs}>
                        {authDocElements}
                    </div>
                )}
                <h4 className={styles.taskTitle}>
                    <i className="ri-edit-line"/>
                    <span>{t('pages-newcustomerdeclarations.selectedCustomerDeclarationProcessChanges')}</span>
                </h4>
                <Changelog depositorId={selectedCustomerDeclaration.depositorId}/>
                <LegacyCheckbox id="confirmCustomerDeclarationIsRegisteredCheckbox"
                                className={styles.confirmationCheck}
                                value={confirmation.value}
                                onChange={e => confirmation.setValue(e.target.checked)}
                                label={t('pages-newcustomerdeclarations.selectedCustomerDeclarationCheckbox')}
                />
                {confirmation.errorMessage && <p className="field-error-message">{confirmation.errorMessage}</p>}
                <AsyncButton id="confirmCustomerDeclarationIsRegisteredButton"
                        onClick={() => onRegisterCustomerDeclaration(selectedCustomerDeclaration.depositorId, bankInSession.id, selectedCustomerDeclaration.documentId)}
                >
                    {t('pages-newcustomerdeclarations.selectedCustomerDeclarationSubmitButton')}
                </AsyncButton>
            </div>
        </Paper>
    )
}
