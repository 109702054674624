import { Paper } from '#components'
import { useTranslation } from 'react-i18next'
import FundPlatformFeeTable from '#components/FundPlatformFeeTable/FundPlatformFeeTable'

export default function FundStatus() {
    const {t} = useTranslation()

    return (
        <>
            <Paper title={t('pages-organizations.fundPlatformFeeHeading')}>
                <p>{t('pages-organizations.fundPlatformFeeMessage1')}</p>
                <p>
                    {t('pages-organizations.fundPlatformFeeMessage2')}<br/>
                    <strong>{t('pages-organizations.fundPlatformFeeMessage3')}</strong>
                </p>
                <p>{t('pages-organizations.fundPlatformFeeMessage4')}</p>
                <FundPlatformFeeTable/>
            </Paper>
        </>
    )
}
