import styles from './Empty.module.css'

const Empty = function({ predicate = false, children = null }) {
    return predicate ? (
        <p className={styles.empty}>
            {children}
        </p>
    ) : null
}

export default Empty