import OrdersBankListSection from './OrdersBankListSection'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout/PageLayout'
import { useTranslation } from 'react-i18next'


export default function OrdersBankList() {
    const { t } = useTranslation()

    return (
        <>
            <PageHeader title={t('pages-orders.bankOrdersHeading')}/>
            <PageLayout>
                <OrdersBankListSection header={t('pages-orders.bankNewOrdersTitle')}
                                       ingress={t('pages-orders.bankNewOrdersIngress')}
                                       state={['INITIAL_PROCESSING', 'CREATED']}
                                       dataCy="bank-created-orders"
                />

                <OrdersBankListSection header={t('pages-orders.bankSignedOrdersTitle')}
                                       ingress={t('pages-orders.bankSignedOrdersIngress')}
                                       state={['READY_FOR_APPROVAL', 'READY_FOR_SIGNING']}
                                       dataCy="bank-ready-for-approval"
                />

                <OrdersBankListSection header={t('pages-orders.bankReadyForConfirmationTitle')}
                                       ingress={t('pages-orders.bankReadyForConfirmationIngress')}
                                       state={['READY_FOR_TRANSACTION', 'READY_FOR_TRANSACTION_CONFIRMATION']}
                                       dataCy="bank-ready-for-transaction"
                />

                <OrdersBankListSection header={t('pages-orders.bankRejectedOrdersTitle')}
                                       ingress={t('pages-orders.bankRejectedOrdersIngress')}
                                       state={['CANCELED']}
                                       dataCy="bank-rejected-orders"
                />
            </PageLayout>
        </>
    );
}
