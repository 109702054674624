import { useEffect, useState } from 'react';
import {useFieldState} from '@fixrate/fieldstate'
import {useCommand} from '#command'
import {useSelector} from '#state/useSelector'
import {useTranslation} from 'react-i18next'
import useCurrentDepositor from '#app/services/useCurrentDepositor';
import {replaceComma, toString} from '#services/parse'

export default function useDepositorOfferWizardFields(depositorOfferId: string) {
    const {t} = useTranslation()
    const products = useSelector(state => state.products)
    const isLoaded = useSelector(state => state.loaded.depositorOffers)
    const [initialized, setInitialized] = useState(false)
    const depositorOffer = useSelector(state => state.depositorOffers.find(offer => offer.depositorOfferId === depositorOfferId))
    const {updateDepositorOffer} = useCommand()
    const depositor = useCurrentDepositor()

    const productId = useFieldState(depositorOffer?.productId, ({value}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationProduct')
        }
    }, {}, (newValue) => update({productId: newValue}))
    const depositorId = useFieldState(depositor?.id, ({value}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationOrganization')
        }
    }, {}, update)
    const accountHolderUserId = useFieldState(depositorOffer?.accountHolderUserId, ({value}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationAccountHolder')
        }
    }, {}, (newValue) => update({accountHolderUserId: newValue}))
    const interestRate = useFieldState(toString(depositorOffer?.interestRate), ({value, isEditing}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationInterestRate')
        }
        if (!isEditing) {
            if (value === '-' || !value.match(/^-?\d{0,2}([.,]\d\d?)?$/)) {
                return t('pages-offer.wizardFieldValidationInterestRateMatch')
            }
        }
    }, {}, (newValue) => update({interestRate: newValue}))
    const terminationDate = useFieldState(depositorOffer?.terminationDate, ({value}) => {
        if (productId.value && products[productId.value].type === 'FIXED') {
            if (!value) {
                return t('pages-offer.wizardFieldValidationTerminationDate')
            }
        }
    }, {}, (newValue) => update({terminationDate: newValue}))
    const settlementAccountId = useFieldState(depositorOffer?.settlementAccountId, ({value}) => {
        if (productId.value && products[productId.value].type === 'FIXED') {
            if (!value) {
                return t('pages-offer.wizardFieldValidationSettlementAccount')
            }
        }
    }, {}, (newValue) => update({settlementAccountId: newValue}))
    const totalVolume = useFieldState(toString(depositorOffer?.totalVolume), ({value, isEditing}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationTotalVolume')
        }
        if (!isEditing) {
            if (value === '-' || !value.match(/^-?\d+([.,]\d{1,2})?$/)) {
                return t('pages-offer.wizardFieldValidationTotalVolumeMatch')
            }
        }
    }, {}, (newValue) => update({totalVolume: newValue}))
    const minVolume = useFieldState(toString(depositorOffer?.minVolume), ({value, isEditing}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationMinAmount')
        }
        if (!isEditing) {
            if (value === '-' || !value.match(/^-?\d+([.,]\d{1,2})?$/)) {
                return t('pages-offer.wizardFieldValidationMinAmountMatch')
            }
        }
    }, {}, (newValue) => update({minVolume: newValue}))
    const maxVolume = useFieldState(toString(depositorOffer?.maxVolume), ({value, isEditing}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationMaxAmount')
        }
        if (!isEditing) {
            if (value === '-' || !value.match(/^-?\d+([.,]\d{1,2})?$/)) {
                return t('pages-offer.wizardFieldValidationMaxAmountMatch')
            }
        }
    }, {}, (newValue) => update({maxVolume: newValue}))
    const expectedDuration = useFieldState(depositorOffer?.expectedDuration, null, {}, (newValue) => update({expectedDuration: newValue}))
    const comment = useFieldState(depositorOffer?.comment, null, {}, (newValue) => update({comment: newValue}))
    const wantExtensionOffer = useFieldState(depositorOffer?.wantExtensionOffer, null, {}, (newValue) => update({wantExtensionOffer: newValue}))
    const deadline = useFieldState(depositorOffer?.deadline, ({value}) => {
        if (!value) {
            return t('pages-offer.wizardFieldValidationDeadline')
        }
    }, {}, (newValue) => update({deadline: newValue}))
    const banks = useFieldState<{ bankId: string, minVolume: number, maxVolume: number }[]>(depositorOffer?.banks, ({value}) => {
        if (!value || value.length === 0) {
            return t('pages-offer.wizardFieldValidationBanks')
        }
    }, {}, (newValue) => update({banks: newValue}))

    useEffect(() => {
        if (isLoaded && !initialized) {
            setInitialized(true)
            // Loads the initial values if the component is rendered before redux is initialized
            productId.setValue(depositorOffer?.productId)
            depositorId.setValue(depositor?.id)
            accountHolderUserId.setValue(depositorOffer?.accountHolderUserId)
            interestRate.setValue(toString(depositorOffer?.interestRate))
            terminationDate.setValue(toString(depositorOffer?.terminationDate))
            settlementAccountId.setValue(depositorOffer?.settlementAccountId)
            totalVolume.setValue(toString(depositorOffer?.totalVolume))
            minVolume.setValue(toString(depositorOffer?.minVolume))
            maxVolume.setValue(toString(depositorOffer?.maxVolume))
            expectedDuration.setValue(depositorOffer?.expectedDuration)
            comment.setValue(depositorOffer?.comment)
            wantExtensionOffer.setValue(depositorOffer?.wantExtensionOffer)
            deadline.setValue(depositorOffer?.deadline)
            banks.setValue(depositorOffer?.banks?.map(bank => ({
                bankId: bank.bankId,
                minVolume: bank.minVolume,
                maxVolume: bank.maxVolume
            })))
        }
    }, [isLoaded, initialized, depositorOffer, depositor, products, productId, depositorId, accountHolderUserId, interestRate, terminationDate, settlementAccountId, totalVolume, minVolume, maxVolume, expectedDuration, comment, wantExtensionOffer, deadline, banks])

    async function update(newValues) {
        await updateDepositorOffer({
            depositorOfferId: depositorOffer?.depositorOfferId,
            productId: productId.value,
            depositorId: depositorId.value,
            accountHolderUserId: accountHolderUserId.value,
            interestRate: replaceComma(interestRate.value),
            terminationDate: terminationDate.value,
            settlementAccountId: settlementAccountId.value,
            totalVolume: totalVolume.value,
            minVolume: minVolume.value,
            maxVolume: maxVolume.value,
            expectedDuration: expectedDuration.value,
            comment: comment.value,
            wantExtensionOffer: wantExtensionOffer.value,
            deadline: deadline.value,
            banks: banks.value?.map(bank => ({
                bankId: bank.bankId,
                minVolume: bank.minVolume,
                maxVolume: bank.maxVolume,
            })),
            currency: 'NOK',
            ...newValues,
        })
    }

    function allValid() {
        const fields = [
            productId,
            depositorId,
            accountHolderUserId,
            interestRate,
            terminationDate,
            settlementAccountId,
            totalVolume,
            minVolume,
            maxVolume,
            expectedDuration,
            comment,
            wantExtensionOffer,
            deadline,
            banks,
        ]
        return fields.reduce((all, field) => {
            return all && field.validate()
        }, true)
    }

    function getFirstFieldErrorMessage(): string {
        const fields = [
            productId,
            depositorId,
            accountHolderUserId,
            interestRate,
            terminationDate,
            settlementAccountId,
            totalVolume,
            minVolume,
            maxVolume,
            expectedDuration,
            comment,
            wantExtensionOffer,
            deadline,
            banks,
        ]
        return fields.reduce((first, field) => {
            return first || field.errorMessage
        }, '')
    }


    return {
        productId,
        depositorId,
        accountHolderUserId,
        interestRate,
        terminationDate,
        settlementAccountId,
        totalVolume,
        minVolume,
        maxVolume,
        expectedDuration,
        comment,
        wantExtensionOffer,
        deadline,
        banks,
        isLoaded,
        allValid,
        getFirstFieldErrorMessage,
    }
}
