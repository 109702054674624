import { useState } from 'react';
import {Checkbox, FormControlLabel} from '@mui/material'
import {useCommand} from '#command'
import {CheckboxTaskLabels} from '#pages/Inbox/useMessageData'
import AsyncButton from '#components/Button/AsyncButton'

type Props = {
    messageId: string
    checkboxTaskLabels: CheckboxTaskLabels
}

export const CheckboxTask = ({messageId, checkboxTaskLabels}: Props) => {
    const [checked, setChecked] = useState(false)
    const {completeCheckboxTask} = useCommand()

    async function handleClick() {
        const {waitForCommand} = await completeCheckboxTask(messageId)
        await waitForCommand()
    }

    return (
        <div style={{padding: '1.6rem 0'}}>
            <FormControlLabel label={checkboxTaskLabels.pledge} control={
                <Checkbox data-cy="taskCheckbox" style={{marginTop: '0.8rem', marginBottom: '1.6rem'}} checked={checked} onChange={(event) => setChecked(event.target.checked)}/>
            }/>
            <AsyncButton data-cy="taskConfirmButton" disabled={!checked} onClick={handleClick}>{checkboxTaskLabels.buttonLabel}</AsyncButton>
        </div>
    )
}
