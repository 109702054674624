import {TFunction, useTranslation} from 'react-i18next'
import * as selectors from '#state/selectors'
import {useSelector} from '#state/useSelector'
import {LoadingButton} from '@mui/lab'
import {
    Alert,
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import {Fragment, useEffect, useState} from 'react';
import styles from './NewAdModals.module.scss'
import NewOfferAccordion from './NewOfferAccordion'
import {NewOfferDto, PRICING_STEPS} from './PricingSteps'
import useSubmitOffers from './useSubmitOffers'
import FxDialogTitle from '#components/FxDialog/FxDialogTitle'
import FxDialog from '#components/FxDialog/FxDialog'
import FxDialogContent from '#components/FxDialog/FxDialogContent'
import FxDialogActions from '#components/FxDialog/FxDialogActions'
import { MovieViewer } from '#app/components/MovieViewer/MovieViewer'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import ChipSelect from "#pages/FundMarketplace/ChipSelect";

type SelectedDepositor = {
    value: string
    label: string
}

interface NewNoticeAdModalProps {
    open: boolean,
    onClose: () => void,
    urlContext: string,
    productIdDefault: string,
}

export default function NewOfferModal({open, onClose, urlContext, productIdDefault}: NewNoticeAdModalProps) {
    const {t} = useTranslation()
    const PRODUCT_ID_FIXED = '3'
    const PRODUCT_ID_NOTICE = '5'

    const bank = useSelector(selectors.bankInSession)
    const isFixrateUser = useSelector(selectors.isFixrateUser)
    const products = useSelector(state => state.products)
    const depositorOptions = useSelector(state => !isFixrateUser ? [] : Object.entries(state.depositorNames).map(([id, name]) => ({value: id, label: name})).sort((a, b) => a.label.localeCompare(b.label)))
    const submitOffers = useSubmitOffers()
    const currency = useSelector(state => state.session?.association?.currentCurrency)

    const [selectedDepositor, setSelectedDepositor] = useState<SelectedDepositor | null>(null)
    const [offers, setOffers] = useState([...PRICING_STEPS[currency]])
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false)
    const [triedPublish, setTriedPublish] = useState(false)
    const [publishing, setPublishing] = useState(false)
    const [infoVideo, setInfoVideo] = useState(false)
    const isFixedInterest = products[productIdDefault]?.type === 'FIXED'
    const initialProductId = isFixedInterest ? PRODUCT_ID_FIXED : PRODUCT_ID_NOTICE
    const [selectedProductId, setSelectedProductId] = useState(initialProductId)

    // Ensure selectedProductId is set correctly on initial render
    useEffect(() => {
        setSelectedProductId(initialProductId);
    }, [initialProductId]);

    const invalidOffers = offers.filter((offer) => (
        !offer.selectedMin
        || !offer.selectedMax
        || !offer.volume
        || offer.selectedMin < offer.min
        || offer.selectedMin > offer.selectedMax
        || offer.selectedMax > offer.max
        || offer.selectedMax > offer.volume
    ))

    if (!bank) {
        return null
    }

    async function submit() {
        setTriedPublish(true)
        if (invalidOffers.length === 0) {
            await submitOffers({offers: offers, urlContext: urlContext, defaultProductId: selectedProductId, selectedDepositorValue: selectedDepositor ? selectedDepositor.value : null, setPublishing})
        } else {
            setShowValidationMessage(true)
        }
    }

    const handleProductIdChange = (value: string) => {
        setSelectedProductId(value)
    }
    return (
        <FxDialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth sx={{ p: 0 }} className={styles.newNoticeAdModal}>
            <FxDialogTitle onClose={onClose}>
                <Stack>
                    <Typography variant='h1' sx={{ color: PURPLE[800], p: '1rem', whiteSpace: 'nowrap' }}>
                        {t('pages-BankAds.newAdHeader')}
                    </Typography>
                    <Stack>
                        <ChipSelect value={selectedProductId} onChange={(event) => handleProductIdChange(event.target.value)} >
                            <MenuItem value={PRODUCT_ID_FIXED}>{t('pages-BankAds.newAdFixedHeader')}</MenuItem>
                            <MenuItem value={PRODUCT_ID_NOTICE}>{t('pages-BankAds.newAdNoticeHeader')}</MenuItem>
                        </ChipSelect>
                    </Stack>
                </Stack>
            </FxDialogTitle>
            <FxDialogContent disablePadding={true}>
                {infoVideo && (
                    <MovieViewer
                        videoActive={setInfoVideo}
                        vimeoUrl='https://player.vimeo.com/video/794407686?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no'
                    />
                )}

                <div className={styles.titleOfferContainer}>
                    <div>
                        <Typography variant='h4' sx={{ color: 'white', pr: '1ch' }} display={'inline-flex'}>
                            {t('pages-BankAds.marketplaceTitle')}
                        </Typography>
                        <Typography variant='h4' sx={{ color: SPRING_GREEN[500] }} display={'inline-flex'}>
                            {t('pages-BankAds.marketplaceUnder20')}
                        </Typography>
                    </div>
                    <div className={styles.rightAlignContainer}>
                        <div>{t('pages-BankAds.highestInterest')}</div>
                        <div>{t('pages-BankAds.lastTrade')}</div>
                    </div>
                </div>

                <NewOfferAccordion offers={offers} marketplaceOver20={false} setOffers={setOffers} defaultProductId={selectedProductId} />
                <div className={styles.titleOfferContainer}>
                    <div>
                        <Typography variant='h4' sx={{ color: 'white', pr: '1ch' }} display={'inline-flex'}>
                            {t('pages-BankAds.marketplaceTitle')}
                        </Typography>
                        <Typography variant='h4' sx={{ color: SPRING_GREEN[500] }} display={'inline-flex'}>
                            {t('pages-BankAds.marketplaceOver20')}
                        </Typography>
                    </div>
                    <div className={styles.rightAlignContainer}>
                        <div>{t('pages-BankAds.highestInterest')}</div>
                        <div>{t('pages-BankAds.lastTrade')}</div>
                    </div>
                </div>

                <NewOfferAccordion offers={offers} marketplaceOver20={true} setOffers={setOffers} defaultProductId={selectedProductId} />
                <Dialog open={showValidationMessage} onClose={() => setShowValidationMessage(false)}>
                    <DialogTitle>Vennligst rett følgende før publisering</DialogTitle>
                    <DialogContent>
                        {invalidOffers.map((offer: NewOfferDto) => (
                            <Fragment key={offer.id}>
                                <p style={{ marginBottom: 0 }}>
                                    <strong>
                                        Annonse {offer.min}-{offer.max} millioner
                                    </strong>
                                </p>
                                <List>
                                    {getErrorList(offer, t).map(errorMessage => (
                                        <Fragment key={errorMessage}>
                                            <ListItem sx={{ pl: 0 }}>
                                                <ListItemIcon sx={{ backgroundColor: '#B44A0620', minWidth: '4rem', minHeight: '4rem' }}>
                                                    <i className='red ri-error-warning-line' />
                                                </ListItemIcon>
                                                <ListItemText sx={{ '& .MuiListItemText-primary': { fontSize: '1.4rem', fontWeight: '500' } }}>{errorMessage}</ListItemText>
                                            </ListItem>
                                        </Fragment>
                                    ))}
                                </List>
                            </Fragment>
                        ))}
                        <Button sx={{ marginTop: '2rem', marginBottom: '2rem' }} variant={'contained'} onClick={() => setShowValidationMessage(false)}>
                            {t('pages-BankAds.close')}
                        </Button>
                    </DialogContent>
                </Dialog>
            </FxDialogContent>
            <FxDialogActions>
                {triedPublish && offers.filter(offer => offer.active).length === 0 && (
                    <Alert sx={{ mr: 'auto' }} severity='error'>
                        {t('pages-BankAds.errorMustSelectOffer')}
                    </Alert>
                )}
                {isFixrateUser && (
                    <dl style={{ position: 'relative', width: '30rem', height: '12rem', margin: '0' }}>
                        <dt>
                            <p>{t('pages-BankAds.specialOfferLabel')}</p>
                        </dt>
                        <dd>
                            <Autocomplete
                                id='specialOfferDepositorSelect'
                                sx={{ mr: 2 }}
                                className={styles.depositorSelect}
                                value={selectedDepositor}
                                options={depositorOptions}
                                onChange={(_: unknown, value: SelectedDepositor | null) => setSelectedDepositor(value)}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={params => <TextField placeholder={t('pages-BankAds.forAllDepositors')} {...params} />}
                            />
                        </dd>
                    </dl>
                )}
                <LoadingButton loading={publishing} disabled={publishing} variant='contained' id='createNewAdButton' data-cy='createNewAdButton' onClick={submit}>
                    {offers.filter(offer => offer.active).length === 1 ? t('pages-BankAds.createAddButtonText') : t('pages-BankAds.createAddMultipleButtonText')}
                </LoadingButton>
            </FxDialogActions>
        </FxDialog>
    )
}

function getErrorList(offer: NewOfferDto, t: TFunction): string[] {
    const errorList = []
    if (!offer.selectedMin) {
        errorList.push(t('pages-BankAds.missingMin'))
    }
    if (!offer.selectedMax) {
        errorList.push(t('pages-BankAds.missingMax'))
    }
    if (!offer.volume) {
        errorList.push(t('pages-BankAds.missingVolume'))
    }
    if (offer.selectedMin < offer.min) {
        errorList.push(t('pages-BankAds.minTooLow'))
    }
    if (offer.selectedMin > offer.selectedMax) {
        errorList.push(t('pages-BankAds.minBiggerThanMax'))
    }
    if (offer.selectedMax > offer.max) {
        errorList.push(t('pages-BankAds.maxTooHigh'))
    }
    if (offer.selectedMax > offer.volume) {
        errorList.push(t('pages-BankAds.maxBiggerThanVolume'))
    }
    return errorList
}
