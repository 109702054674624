import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput';
import config from "#app/config";
import ShareClassSelect from '#app/pages/FundMarketplace/FundTable/TableComponents/ShareClassSelect';
import { fundDetailsLink } from '#app/services/fundDetailsLink';
import { DateOutput, InterestOutput, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from '#components';
import { FundDto, FundShareClassDto } from '@fixrate/fixrate-query';
import { Button, Link } from "@mui/material";
import { useTranslation } from 'react-i18next';
import useFundShareClassSelected from "#services/useFundShareClassSelected";

const API_BASE_URL = config().apiUrl

type FundRowProps = {
    fund: FundDto
    fundShareClasses: FundShareClassDto[]
    isTableRow: true
}

function FundRow({fund, fundShareClasses}: FundRowProps) {
    const {t} = useTranslation()
    const [fundClassId] = useFundShareClassSelected(fund.id, fundShareClasses?.[0]?.id)
    const date = Object.keys(fund.monthlyReports).sort().pop()
    const currentReport = fund.monthlyReports[date]
    const selectedFundClass = fundShareClasses?.find(fundClass => fundClass?.id === fundClassId)

    return (
        <TableRow>
            <TableCell>
                {fund.id && fund.name && (
                    <Link href={fundDetailsLink(fund)} underline={'hover'}>{fund.name}</Link>
                )}
            </TableCell>
            <TableCell>
                {fund.totalAssets && CurrencyOutputObject(fund.totalAssets)}
            </TableCell>
            <TableCell>
                {fund.interestRateSensitivity && InterestOutput.format(fund.interestRateSensitivity)}
            </TableCell>
            <TableCell>
                {fund.creditSensitivity && InterestOutput.format(fund.creditSensitivity)}
            </TableCell>
            <TableCell>
                {selectedFundClass && selectedFundClass.effectiveInterestRate !== null && InterestOutput.format(selectedFundClass.effectiveInterestRate)}
            </TableCell>
            <TableCell>
                {currentReport && (
                    <Button startIcon={<i className={'ri-eye-line'}/>} variant={'outlined'} size={'small'} href={API_BASE_URL + '/api/document/id/' + currentReport + '/data'} rel="noopener noreferrer" target="_blank">
                        {t('pages.fundOverview.admin.showReport')}
                    </Button>
                )}
            </TableCell>
            <TableCell>
                {fund && <ShareClassSelect fund={fund}/>}
            </TableCell>
            <TableCell>
                {selectedFundClass && selectedFundClass.standardDeviation !== null && InterestOutput.format(selectedFundClass.standardDeviation)}
            </TableCell>
            <TableCell>
                {fund.holdingDate && DateOutput.format(fund.holdingDate, 'yyyy-MM-dd')}
            </TableCell>
        </TableRow>
    )
}

type FundAdminOverviewTableProps = {
    funds: FundDto[]
}

export default function FundAdminOverviewTable({funds}: FundAdminOverviewTableProps) {
    const {t} = useTranslation()
    const sortedFunds = [...funds].sort((a, b) => a.name.localeCompare(b.name))

    return (
        <Table>
            <TableHeader>
                <TableHeaderCell>{t('pages.fundOverview.admin.fund')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.totalAssets')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.interestRateSensitivity')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.creditSensitivity')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.effectiveInterestRate')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.monthlyReports')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.fundShareClass')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.standardDeviation')}</TableHeaderCell>
                <TableHeaderCell>{t('pages.fundOverview.admin.updatedPortfolio')}</TableHeaderCell>
            </TableHeader>
            {sortedFunds && sortedFunds.map(fund =>
                <FundRow
                    key={fund.name}
                    fund={fund}
                    fundShareClasses={fund.fundShareClasses}
                    isTableRow
                />
            )}
        </Table>
    )
}
