import {SortDirection} from '@mui/material'
import {useMemo, useState} from 'react'

export interface Column<Row> {
    id: keyof Row
    label: string
    align?: 'left' | 'right'
    sortable?: boolean
    className?: string
    tooltip?: string
    longTooltip?: string
}

type SortFunction<Row> = (row1: Row, row2: Row) => number

type TableSortResult<Row> = {
    sortedRows: Row[]
    sortDirection: SortDirection
    sortKey: keyof Row
    setSorting: (key: keyof Row, direction?: SortDirection) => void
}

export default function useTableSort<Row>(rows: Row[], initialSortKey: keyof Row, initialSortDirection: SortDirection, secondarySortKey?: keyof Row, secondarySortDirection?: SortDirection): TableSortResult<Row> {
    const [sortDirection, setSortDirection] = useState<SortDirection>(initialSortDirection)
    const [sortKey, setSortKey] = useState<keyof Row>(initialSortKey)

    function setSorting(key: keyof Row, direction?: SortDirection): void {
        if (direction) {
            setSortKey(key)
            setSortDirection(direction)
            return
        }

        if (key === sortKey) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
        } else {
            setSortDirection(initialSortDirection)
        }
        setSortKey(key)
    }

    const sortedRows: Row[] = useMemo(() => {
        const sortFunction = getSortFunction(sortKey, sortDirection, secondarySortKey, secondarySortDirection)
        return rows.slice().sort(sortFunction)
    }, [sortKey, sortDirection, secondarySortKey, secondarySortDirection, rows])

    return {
        sortedRows,
        sortDirection,
        sortKey,
        setSorting,
    }
}

function getSortFunction<Row>(sortKey: keyof Row, sortDirection: SortDirection, secondarySortKey?: keyof Row, secondarySortDirection?: SortDirection): SortFunction<Row> {
    return sortDirection === 'desc' ? (a: Row, b: Row) => sortDesc(a, b, sortKey, secondarySortKey, secondarySortDirection) : (a: Row, b: Row) => sortAsc(a, b, sortKey, secondarySortKey, secondarySortDirection)
}

function sortAsc<Row>(row1: Row, row2: Row, sortKey: keyof Row, secondarySortKey?: keyof Row, secondarySortDirection?: SortDirection): number {
    let sortResult
    const v1 = row1[sortKey]
    const v2 = row2[sortKey]
    if (valueMissing(v1) && valueMissing(v2) && secondarySortKey) {
        return getSortFunction(secondarySortKey, secondarySortDirection)(row1, row2)
    }
    if (valueMissing(v1)) {
        return 1
    }
    if (valueMissing(v2)) {
        return -1
    }

    if (typeof v1 === 'number' || typeof v2 === 'number') {
        if (v2 < v1) {
            sortResult = 1
        } else if (v2 > v1) {
            sortResult = -1
        } else {
            sortResult = 0
        }
    } else if (typeof v1 === 'object' || typeof v2 === 'object') { // Date
        if (v2 < v1) {
            sortResult = 1
        } else if (v2 > v1) {
            sortResult = -1
        } else {
            sortResult = 0
        }
    } else {
        sortResult = `${v1}`.trim().localeCompare(`${v2}`.trim(), 'nb', {sensitivity: 'base'})
    }

    if (sortResult === 0 && secondarySortKey) {
        return getSortFunction(secondarySortKey, secondarySortDirection)(row1, row2)
    }

    return sortResult
}

function sortDesc<Row>(row1: Row, row2: Row, sortKey: keyof Row, secondarySortKey?: keyof Row, secondarySortDirection?: SortDirection): number {
    let sortResult
    const v1 = row1[sortKey]
    const v2 = row2[sortKey]
    if (valueMissing(v1) && valueMissing(v2) && secondarySortKey) {
        return getSortFunction(secondarySortKey, secondarySortDirection)(row1, row2)
    }
    if (valueMissing(v2)) {
        return -1
    }
    if (valueMissing(v1)) {
        return 1
    }

    if (typeof v1 === 'number' || typeof v2 === 'number') {
        if (v2 < v1) {
            sortResult = -1
        } else if (v2 > v1) {
            sortResult = 1
        } else {
            sortResult = 0
        }
    } else if (typeof v1 === 'object' || typeof v2 === 'object') { // Date
        if (v2 < v1) {
            sortResult = -1
        } else if (v2 > v1) {
            sortResult = 1
        } else {
            sortResult = 0
        }
    } else {
        sortResult = `${v2}`.trim().localeCompare(`${v1}`.trim(), 'nb', {sensitivity: 'base'})
    }

    if (sortResult === 0 && secondarySortKey) {
        return getSortFunction(secondarySortKey, secondarySortDirection)(row1, row2)
    }

    return sortResult
}

function valueMissing(value: any): boolean {
    return value === undefined || value === null || (typeof value === 'number' && isNaN(value))
}
