import { Fragment } from 'react';
import CustomerDeclarationDocumentLink from './CustomerDeclarationDocumentLink'
import styles from './CustomerDeclarationInfo.module.scss'
import Button from '#components/Button'
import {If, Paper, Title} from '#components'
import {useTranslation} from 'react-i18next'
import {useAuthorization} from '#services/authorization'
import useCurrentDepositor from '#app/services/useCurrentDepositor'

type Props = {
    wizardIsVisible: boolean
    setForceShowWizard: (value: boolean) => void
    inline?: boolean
}

export default function CustomerDeclarationDocumentSection({wizardIsVisible, setForceShowWizard, inline = false}: Props) {
    const {t} = useTranslation()
    const depositor = useCurrentDepositor()

    const auth = useAuthorization(depositor.id)
    const hasCustomerDeclarationPermission = auth.depositor.hasAdminRole
        || auth.depositor.hasOrderRole
        || auth.depositor.hasAccountHolderRole
        || auth.depositor.hasPartnerRole

    if (!depositor) {
        return null
    }

    if (inline) {
        return (
            <Fragment>
                <h2>{t('pages-organizations.customerDeclarationHeading')}</h2>
                <p className={styles.paragraph}>
                    {t('pages-organizations.customerDeclarationMessagePart1')}
                </p>
                <p className={styles.paragraph}>
                    {t('pages-organizations.customerDeclarationMessagePart2')}
                </p>
                <CustomerDeclarationDocumentLink document={depositor.customerDeclarationId} depositor={depositor}/>
                <p>
                    {t('pages-organizations.customerDeclarationUpdateMessage')}
                </p>
                <If condition={!wizardIsVisible && hasCustomerDeclarationPermission}>
                    <div className={styles.actionButtons}>
                        <Button
                            id="createNewCustomerDeclarationButton"
                            onClick={() => setForceShowWizard(true)}
                        >
                            {t('pages-organizations.createNewCustomerDeclaration')}
                        </Button>
                    </div>
                </If>
            </Fragment>
        );
    }

    return (
        <Paper supportArticleId={'070a8270-f4de-463e-b492-e4361d775446'}>
            <Title>{t('pages-organizations.customerDeclarationHeading')}</Title>
            <p className={styles.paragraph}>
                {t('pages-organizations.customerDeclarationMessagePart1')}
            </p>
            <p className={styles.paragraph}>
                {t('pages-organizations.customerDeclarationMessagePart2')}
            </p>
            <CustomerDeclarationDocumentLink document={depositor.customerDeclarationId} depositor={depositor}/>
            <p>
                {t('pages-organizations.customerDeclarationUpdateMessage')}
            </p>
            <If condition={!wizardIsVisible && hasCustomerDeclarationPermission}>
                <div className={styles.actionButtons}>
                    <Button
                        id="createNewCustomerDeclarationButton"
                        onClick={() => setForceShowWizard(true)}
                    >
                        {t('pages-organizations.createNewCustomerDeclaration')}
                    </Button>
                </div>
            </If>
        </Paper>
    )
}
