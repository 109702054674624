function scrollToAnchor() {
    const {hash} = window.location
    const preservePosition = hash === '#!'
    if (hash !== '' && !preservePosition) {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        setTimeout(() => {
            const id = hash.replace('#', '')
            const element = document.getElementById(id)
            if (element) element.scrollIntoView({behavior: "smooth", block: "start"})
        }, 0)
    } else if (!preservePosition) {
        // Scroll to top
        window.scrollTo(0, 0)
    }
}

export default scrollToAnchor