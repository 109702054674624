import {useDispatch} from 'react-redux'
import {useSelector} from '#state/useSelector'
import {closeConfirmModal, showConfirmModal} from '#state/reducers/confirmModal'
import styles from './ConfirmModal.module.scss'
import Modal from '#components/Modal'

export default function ConfirmModal() {
    const dispatch = useDispatch()
    const confirmModal = useSelector(state => state.confirmModal)

    // Should not be visible if showModal is false
    if (!confirmModal.showModal) return null

    async function handleSubmit() {
        await confirmModal.submitAction()
        dispatch(closeConfirmModal({}))
    }

    async function handleCancel() {
        await confirmModal.cancelAction()
        dispatch(closeConfirmModal({}))
    }

    return (
        <Modal onSubmit={handleSubmit}
               onCancel={handleCancel}
               header={confirmModal.title}
               submitButtonText={confirmModal.submitButtonText}
               submitButtonId={'modalConfirmButton'}
        >
            <div className={styles.content}>
                <p>{confirmModal.text}</p>
            </div>
        </Modal>
    )
}

export interface ConfirmModalOptions {
    title: string
    text: string
    submitButtonText?: string
    submitAction?: () => void | Promise<void>
    cancelAction?: () => void | Promise<void>
}

export function useConfirmModal() {
    const dispatch = useDispatch()

    return function confirmModal(options: ConfirmModalOptions) {
        dispatch(showConfirmModal(options))

        // Returns a function that will close the modal
        return async () => dispatch(closeConfirmModal({}))
    }
}


