import FxPaper from '#app/components/Paper/FxPaper'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import {
    AllDepositsInterest, BusinessVolume, BusinessVolumeForBank, DepositInterestByBankSegment, DepositInterestByProductForBank, DepositInterestForBank, DepositMarginByBankSegment, DepositMarginByProduct, DepositMarginByProductForBank, DepositMarginForBank,
    TurnoverByProduct, TurnoverByProductForBank
} from '@fixrate/fixrate-analytics-widgets'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { AnalyticsPaperSx } from '../Analytics'

export default function AnalyticsDepositsBank() {
    const {t} = useTranslation()
    const isFixrateRole = useSelector(selectors.isFixrateRole)
    const isBankWithAnalytics = useSelector(selectors.isBankWithAnalytics)
    const isBankWithExtendedAnalytics = useSelector(selectors.isBankWithExtendedAnalytics)
    const bank = useSelector(state => {
        const bank = state.bank
        // Returns a new bank object with only the relevant attributes to avoid unneccesary re-rendering
        return bank && {
            id: bank.id,
            name: bank.name
        }
    }, shallowEqual)

    return (
        <>
            {(isFixrateRole || isBankWithExtendedAnalytics) && (
                <>
                    <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsAllProduct')}>
                        <AllDepositsInterest/>
                    </FxPaper>
                    <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsMarginProduct')}>
                        <DepositMarginByProduct/>
                    </FxPaper>
                    <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsTurnoverProduct')}>
                        <TurnoverByProduct/>
                    </FxPaper>
                    { bank && (
                        <>
                            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsProductsBank', {bankName: bank.name})}>
                                <DepositInterestByProductForBank bankId={bank.id}/>
                            </FxPaper>
                            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsAllBank')}>
                                <DepositInterestForBank bankId={bank.id}/>
                            </FxPaper>
                            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsMarginProductsBank', {bankName: bank.name})}>
                                <DepositMarginByProductForBank bankId={bank.id}/>
                            </FxPaper>
                            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsMarginBank')}>
                                <DepositMarginForBank bankId={bank.id}/>
                            </FxPaper>
                            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsTurnoverProductBank', {bankName: bank.name})}>
                                <TurnoverByProductForBank bankId={bank.id}/>
                            </FxPaper>
                        </>
                    )}
                </>
            )}
            {(isFixrateRole || isBankWithAnalytics) && (
                <>
                    <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsBusinessVolume')}>
                        <BusinessVolume/>
                    </FxPaper>
                    { bank && (
                        <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsBusinessVolumeBank', {bankName: bank.name})}>
                            <BusinessVolumeForBank bankId={bank.id}/>
                        </FxPaper>
                    )}
                </>
            )}

            {isFixrateRole && (
                <>
                    <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsMarginBankSegment')}>
                        <DepositMarginByBankSegment/>
                    </FxPaper>
                    <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.depositsAllBankSegment')}>
                        <DepositInterestByBankSegment/>
                    </FxPaper>
                </>
            )}
        </>
    );
}
