import ChangeFundShareClassDialog from "#pages/FundAdminOverview/FundPlacements/ChangeFundShareClassDialog";
import {useTranslation} from "react-i18next";
import {Fragment, useState} from "react";
import {
    Collapse,
    IconButton,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    Box,
    Typography, TableBody
} from "@mui/material";
import {CurrencyOutputObject} from "#components/CurrencyOutput/CurrencyOutput";
import EditSharp from "@mui/icons-material/EditSharp";
import {FundDto, FundPlacementDto, FundShareClassDto} from "@fixrate/fixrate-query";
import {ArrowDropDown, ArrowDropUp, PriorityHigh} from "@mui/icons-material";

export type PlacementRow = FundPlacementDto & {
    depositorName: string
    fund: FundDto
    fundShareClasses: FundShareClassDto[]
    shareClassName: string
}

export type PlacementTableProps = {
    placementRows: PlacementRow[]
}

const translationPath = 'pages.adminFundPlacements'

export default function AdminFundPlacementTable({placementRows}: PlacementTableProps) {
    const {t} = useTranslation()
    const [fundPlacementToChangeShareClass, setFundPlacementToChangeShareClass] = useState<PlacementRow | null>(null)
    return (
        <TableContainer>
            {fundPlacementToChangeShareClass && <ChangeFundShareClassDialog pr={fundPlacementToChangeShareClass} onClose={() => setFundPlacementToChangeShareClass(null)}/>}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>{t(`${translationPath}.depositor`)}</TableCell>
                        <TableCell>{t(`${translationPath}.fundShareClass`)}</TableCell>
                        <TableCell>{t(`${translationPath}.unitQuantity`)}</TableCell>
                        <TableCell>{t(`${translationPath}.currentValue`)}</TableCell>
                        <TableCell>{t(`${translationPath}.changeFundShareClass`)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {placementRows && placementRows
                        .slice()
                        .sort((a, b) => a.depositorName.localeCompare(b.depositorName) || a.shareClassName.localeCompare(b.shareClassName))
                        .map(pr => (
                        <CollapsibleFundPlacement key={pr.id} pr={pr} onEdit={setFundPlacementToChangeShareClass}/>
                    ))}
                    <TotalsRow placementRows={placementRows}/>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function TotalsRow({placementRows}: {placementRows: PlacementRow[]}) {
    const currencyOptions = {
        millionFormat: false,
        maximumDecimals: 2,
        minimumDecimals: 2,
        withCurrency: true
    }
    const amountOptions = {
        millionFormat: false,
        maximumDecimals: 4,
        minimumDecimals: 4,
        withCurrency: false
    }
    return (
        <TableRow>
            <TableCell/>
            <TableCell/>
            <TableCell sx={{fontWeight: 700}}>Total</TableCell>
            <TableCell sx={{fontWeight: 700}}>{CurrencyOutputObject(placementRows.reduce((sum, pr) => sum + pr.unitQuantity, 0), amountOptions)}</TableCell>
            <TableCell sx={{fontWeight: 700}}>{CurrencyOutputObject(placementRows.reduce((sum, pr) => sum + pr.currentValue, 0), currencyOptions)}</TableCell>
            <TableCell/>
        </TableRow>
    )

}

function CollapsibleFundPlacement({pr, onEdit}: {pr: PlacementRow, onEdit: (pr: PlacementRow) => void}) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const currencyOptions = {
        millionFormat: false,
        maximumDecimals: 2,
        minimumDecimals: 2,
        withCurrency: true
    }
    const amountOptions = {
        millionFormat: false,
        maximumDecimals: 4,
        minimumDecimals: 4,
        withCurrency: false
    }
    // How many distinct share classes are there across the placement parts?
    const distinctShareClasses = [...new Set(pr.placementParts.map(pp => pp.fundShareClassId))]
    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    {distinctShareClasses.length > 1 && <PriorityHigh color={'error'}></PriorityHigh>}
                    <IconButton
                        size={'small'}
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ArrowDropUp/> : <ArrowDropDown/>}
                    </IconButton>
                </TableCell>
                <TableCell>{pr.depositorName}</TableCell>
                <TableCell>{pr.shareClassName}</TableCell>
                <TableCell>{CurrencyOutputObject(pr.unitQuantity, amountOptions)}</TableCell>
                <TableCell>{CurrencyOutputObject(pr.currentValue, currencyOptions)}</TableCell>
                <TableCell>
                    <IconButton size={'small'} sx={{ml:1}} onClick={() => onEdit(pr)}>
                        <EditSharp/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant={'h4'} gutterBottom component={'div'}>
                                Andelsklasser i fondsplasseringen
                            </Typography>
                            <Table size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t(`${translationPath}.fundShareClass`)}</TableCell>
                                        <TableCell>{t(`${translationPath}.unitQuantity`)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                {distinctShareClasses.map(fscId => {
                                    const placementPartsForShareClass = pr.placementParts.filter(pp => pp.fundShareClassId === fscId)
                                    const shareClassName = pr.fundShareClasses?.find(fsc => fsc.id === fscId)?.fullName
                                    return (
                                        <TableRow key={fscId}>
                                            <TableCell>{shareClassName}</TableCell>
                                            <TableCell>{CurrencyOutputObject(placementPartsForShareClass.reduce((sum, pp) => sum + pp.unitQuantity, 0), amountOptions)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}
