import { Animated } from "#app/components"
import { Link, Stack, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import DownloadLink from '#app/pages/common/DownloadLink/DownloadLink'
import FixrateIcon from '#app/components/FixrateIcon/FixrateIcon'
import { PURPLE } from '#app/colors/colors'

type ReportDownloadProps = {
    keyValue: string
    link: string
    name: string
    description?: string
    dataCy: string
    excel?: boolean
    pdf?: boolean
}

export const ReportDownload = ({ keyValue, link, name, description, dataCy, excel = true, pdf = true } : ReportDownloadProps) => {
    const { t } = useTranslation()

    return (
        <Animated key={keyValue} data-cy={dataCy}>
            <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'}>
                <Link data-cy="reportLink" href={link} target="_blank" rel="noopener noreferrer">
                    <FixrateIcon sx={{width: '9rem'}} name='documentAnalyzeFill'/>
                    <Stack>
                        <Typography sx={{color: PURPLE[800], fontWeight: '700'}}>{name}</Typography>
                        {description && <Typography sx={{color: PURPLE[300]}}>{description}</Typography>}
                    </Stack>
                </Link>
                <Stack direction={'row'} spacing={1}>
                    { pdf === true && (
                        <Tooltip title={t('pages-portfolio-depositor.reportsDownloadPdf')}>
                            <DownloadLink
                                link={`${link}&download=attachment`}
                                icon={<i className="ri-file-download-line"/>}
                            />
                        </Tooltip>
                    )}
                    { excel === true && (
                        <Tooltip title={t('pages-portfolio-depositor.reportsDownloadExcel')}>
                            <DownloadLink
                                link={`${link}&download=attachment&type=excel`}
                                icon={<i className='ri-file-excel-2-line'/>}
                            />
                        </Tooltip>
                    )}
                </Stack>
            </Stack>
        </Animated>
    )
}
