import { useEffect, useState } from 'react';
import config from '#app/config'
import {useSelector} from '#state/useSelector'
import {Alert, Button, Snackbar} from '@mui/material'
import {useTranslation} from 'react-i18next'

const marketplaceVersion = config().marketplaceVersion

type VersionFile = {
    version: string,
    buildTimestamp: string,
    branch: string
}

function getServerVersion() {
    const options: RequestInit = {
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'include',
    }
    return fetch('/version.json', options)
        .then((response: Response) => {
            return response.json()
        })
        .then((versionFile: VersionFile) => {
            //console.log('Loaded version.json', versionFile)
            return versionFile.version
        })
        .catch(e => {
            console.warn('Failed while accessing version.json', e)
        })
}

export default function useReload() {

    const {t} = useTranslation()
    const [shouldReload, setShouldReload] = useState<boolean>(false)
    const authenticated = useSelector(state => state.session.authenticated)

    function reload() {
        console.log('Reloading outdated application')
        window.location.reload();
    }

    useEffect(() => {
        const id = setInterval(() => {
            getServerVersion().then(version => {
                const versionIsDifferent = !!version && (version !== marketplaceVersion)
                setShouldReload(versionIsDifferent)
                if (versionIsDifferent && !authenticated) {
                    reload()
                }
            })
        }, 60_000)
        return () => {
            clearInterval(id);
        };
    }, [authenticated])

    return (
        <Snackbar open={shouldReload}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <Alert severity="error" sx={{ width: '100%', boxShadow: '0.1rem 0.1rem 0.3rem rgba(0,0,0,0.3)' }} action={
                <Button color="inherit" size="small" onClick={reload} sx={{textDecoration: 'underline'}}>
                    {t('components-Reload.button')}
                </Button>
            }>
                {t('components-Reload.warning')}
            </Alert>
        </Snackbar>
    )

}
