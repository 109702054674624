import styles from './FundPlacements.module.scss'
import {BuySellOrderAccordion} from './FundPlacementAccordion'
import {useSelector} from '#state/useSelector'
import {DateAsString, FundBuyOrderDto, FundBuyOrderState, FundSellOrderDto, FundSellOrderState, RegulatoryRegion, SettlementAccountDto} from '@fixrate/fixrate-query'
import {useTranslation} from '#components/i18n'
import * as selectors from '#state/selectors'
import {formatAccount, formatIban} from '#services/formatnumber'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'

export type FundOrderState = FundBuyOrderState | FundSellOrderState | ''

export interface FundOrder {
    type: 'BUY' | 'SELL'
    id: string
    fundId: string
    fundShareClassId: string
    state: FundOrderState
    stateHistory: { [P in FundOrderState]?: DateAsString };
    amount: number
    created: DateAsString
    createdBy: string
    documentId: string
    depositorId: string
    orderGroupId: string
    accountNumber: string
    depositorFundPortfolioClientAccount: boolean
    iban: string
    bic: string
}

type Props = {
    depositorFilter?: string,
    hideCompleted?: boolean,
    showTitle?: boolean,
    onlyOrderState?: 'COMPLETED' | 'IN_PROGRESS' | 'AWAITING_PAYMENT' | 'CANCELLED' | 'EXECUTING_IN_MARKET',
    showEmptyMessage?: boolean,
    emptyMessage?: string
}

export default function FundOrders({depositorFilter, hideCompleted, showTitle, onlyOrderState, showEmptyMessage, emptyMessage = ""}: Props) {
    const {t} = useTranslation('pages-FundOverview')
    const organisationCountry = useCurrentCountryCode()
    const settlementAccountMap = useSelector(selectors.settlementAccountMap)
    const fundBuyOrders = useSelector(state => state.fundBuyOrders.map(mapBuyOrderToFundOrder))
    const fundSellOrders = useSelector(state => state.fundSellOrders.map(o => mapSellOrderToFundOrder(o, settlementAccountMap, organisationCountry)))

    const allOrders = [...fundBuyOrders, ...fundSellOrders].sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())

    const statusCheck = (status) => {
        if (hideCompleted && (status === 'COMPLETED' || status === 'CANCELLED')) {
            return false
        }
        if (onlyOrderState === 'COMPLETED' && status === 'CANCELLED') {
            return true
        }
        if (onlyOrderState && status !== onlyOrderState) {
            return false
        }
        return true
    }

    const filteredOrders = allOrders.filter(order => (!depositorFilter || order.depositorId === depositorFilter) && statusCheck(order.state))

    const groupedOrders: { [orderGroupId: string]: FundOrder[] } = filteredOrders.reduce((acc, order) => {
        const groupId = order.orderGroupId
        if (!acc[groupId]) {
            acc[groupId] = []
        }
        acc[groupId].push(order)
        return acc
    }, {})

    if (Object.keys(groupedOrders).length === 0 && showEmptyMessage) {
        return <p>{emptyMessage}</p>
    }

    return Object.keys(groupedOrders).length > 0 && (
        <div className={styles.fundOrders}>
            {showTitle && (
                <h3 className={styles.title}>
                    <i className="ri-arrow-left-right-line"/>
                    <span>{t('tradeOrders')}</span>
                </h3>
            )}
            <ul className={styles.orderList}>
                <li className={styles.accordionDescription}>
                    <span className={styles.orderName}>{t('name')}</span>
                    <span className={styles.orderType}>{t('orderType')}</span>
                    <span className={styles.orderStatus}>{t('status')}</span>
                    <span className={styles.orderValue}>{t('amount')}</span>
                </li>
                {Object.keys(groupedOrders).map((orderGroupId) => <BuySellOrderAccordion key={orderGroupId} orderGroup={groupedOrders[orderGroupId]}/>)}
            </ul>
        </div>
    )
}

function mapBuyOrderToFundOrder(fundBuyOrderDto: FundBuyOrderDto): FundOrder {
    return {
        type: 'BUY',
        id: fundBuyOrderDto.id,
        fundShareClassId: fundBuyOrderDto.fundShareClassId,
        fundId: fundBuyOrderDto.fundId,
        state: fundBuyOrderDto.state,
        stateHistory: fundBuyOrderDto.stateHistory,
        amount: fundBuyOrderDto.amount,
        created: fundBuyOrderDto.created,
        createdBy: fundBuyOrderDto.createdBy,
        documentId: fundBuyOrderDto.documentId,
        depositorId: fundBuyOrderDto.depositorId,
        orderGroupId: fundBuyOrderDto.orderGroupId,
        accountNumber: fundBuyOrderDto.accountNumber,
        depositorFundPortfolioClientAccount: fundBuyOrderDto.depositorFundPortfolioClientAccount,
        iban: fundBuyOrderDto.iban,
        bic: fundBuyOrderDto.bic,
    }
}

function mapSellOrderToFundOrder(fundSellOrderDto: FundSellOrderDto, settlementAccountMap: { [key: string]: SettlementAccountDto }, organisationCountry: RegulatoryRegion): FundOrder {

    const settlementAccount = settlementAccountMap[fundSellOrderDto.settlementAccountId]
    const accountNumber = settlementAccount ? `${settlementAccount?.name} (${formatAccount(settlementAccount?.account, organisationCountry) || formatIban(settlementAccount?.iban)})` : ''

    return {
        type: 'SELL',
        id: fundSellOrderDto.id,
        fundShareClassId: fundSellOrderDto.fundShareClassId,
        fundId: fundSellOrderDto.fundId,
        state: fundSellOrderDto.state,
        stateHistory: fundSellOrderDto.stateHistory,
        amount: fundSellOrderDto.unitQuantity,
        created: fundSellOrderDto.created,
        createdBy: fundSellOrderDto.createdBy,
        documentId: fundSellOrderDto.documentId,
        depositorId: fundSellOrderDto.depositorId,
        orderGroupId: fundSellOrderDto.orderGroupId,
        accountNumber: accountNumber,
        depositorFundPortfolioClientAccount: fundSellOrderDto.depositorFundPortfolioClientAccount,
        iban: settlementAccount?.iban || '',
        bic: settlementAccount?.bic || '',
    }
}
