import useShoppingCart from '#services/useShoppingCart'
import usePortfolio from '#services/usePortfolio'
import {useSelector} from '#state/useSelector'
import {FundDto, FundPlacementDto, FundShareClassDto} from '@fixrate/fixrate-query'
import {useMemo} from 'react'

/**
 * A record of fund ids and their validity state.
 */
export function useValidState(): Record<string, boolean> {
    const shoppingCart = useShoppingCart<FundDto>('FUNDS')
    const depositor = useSelector(state => state.depositor)
    const currentFundPlacements = useSelector(state => state.fundPlacements)
    const portfolio = usePortfolio()

    return useMemo(() => {
        const validState = {}
        shoppingCart.getObjectReferences().forEach(fund => {
            const shareClassId = shoppingCart.getItemSubId(fund.id)
            const shareClass = fund.fundShareClasses.find(fc => fc.id === shareClassId)
            const depositorMinOrder = depositor?.portfolios.find(p => p.id === portfolio.id)?.fundData?.minOrderVolume || undefined
            const orderAmount = shoppingCart.getItemById(fund.id)?.amount
            const currentPlacement = currentFundPlacements.find(fp => fp.fundId === fund.id)
            validState[fund.id] = isValidOrder(orderAmount, currentPlacement, depositorMinOrder, shareClass)
            /*
            if (!valid) {
                // Log why order is not valid
                console.warn(`Fund ${fund.id} is not valid`,
                    'shareClassId: ' + shoppingCart.getItemSubId(fund.id),
                    'shareClass: ' + fund.fundShareClasses.find(fc => fc.id === shareClassId),
                    'depositorMinOrder: ' + depositor?.portfolios.find(p => p.id === portfolio.id)?.fundData?.minOrderVolume || undefined,
                    'orderAmount: ' + shoppingCart.getItemById(fund.id)?.amount,
                    'currentPlacement: ' + currentFundPlacements.find(fp => fp.fundId === fund.id)
                )
            }
            */
        })

        return validState
    }, [shoppingCart, depositor, currentFundPlacements, portfolio])
}


function isValidOrder(orderAmount: number | undefined,
                      currentPlacement: FundPlacementDto | undefined,
                      minOrder: number | undefined,
                      selectedShareClass: FundShareClassDto | undefined): boolean {
    if (orderAmount === undefined || orderAmount === null || orderAmount <= 0) {
        return false
    }
    if (!selectedShareClass) {
        return false
    }
    const currentPlacementValue = currentPlacement?.currentValue || 0
    const newPlacementValue = currentPlacementValue + orderAmount
    const minInvestment = selectedShareClass.minimumInvestment || 0
    const maxInvestment = selectedShareClass.maximumInvestment || Number.MAX_VALUE
    const withinMinOrderVolume = !minOrder || orderAmount >= minOrder
    const selectedShareClassIsCurrent = currentPlacement?.fundShareClassId === selectedShareClass.id
    const withinMinInvestment = !minInvestment || newPlacementValue >= minInvestment
        || (selectedShareClassIsCurrent && currentPlacementValue > 1000) // We allow the user to buy more in a fund they have already been invested in.
    const withinMaxInvestment = !maxInvestment || newPlacementValue <= maxInvestment
    return withinMaxInvestment // 1. The new fund placement must be below the maxInvestment.
        && (minOrder
            ? withinMinOrderVolume // 2. a) If the depositor has a minOrderVolume: the orderAmount must be at least that.
            : withinMinInvestment) // 2. b) Else, if the shareclass has a minimum investment amount: the new fund placement must be at least that amount.
}
