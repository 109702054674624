import { PURPLE } from "#app/colors/colors";
import { Box, Skeleton, Stack } from "@mui/material";

export default function AdCardSkeleton() {
    return (
        <Box width={"34rem"} height={"24rem"} sx={{backgroundColor: "white", p: "2rem 2.7rem", boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)"}}>
            <Stack direction="row" spacing={0.6} alignItems="center" mb={1}>
                <Skeleton variant="circular" width={30} height={30} />
                <Stack>
                    <Skeleton variant="text" width={140} height={17} />
                    <Skeleton variant="text" width={180} height={18} />
                </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent="space-between" height="6rem">
                <Skeleton variant="text" width={"40%"} height={"100%"} sx={{backgroundColor: PURPLE[100]}} />
                <Skeleton variant="text" width={"55%"} height={"100%"} sx={{backgroundColor: PURPLE[50]}} />
            </Stack>
            <Stack direction="row" mt={"3rem"}>
                <Stack width={"50%"}>
                    <Skeleton variant="text" width={100} height={15} />
                    <Skeleton variant="text" width={60} height={20} />
                </Stack>
                <Stack>
                    <Skeleton variant="text" width={100} height={15} />
                    <Skeleton variant="text" width={60} height={20} />
                </Stack>
            </Stack>
        </Box>
    )
}