import { Fragment, useRef } from 'react';
import styles from '../InfoTabs.module.scss'
import {FundDto} from '@fixrate/fixrate-query'
import {InterestOutput} from '#app/components'
import classNames from 'classnames'
import {Chart} from 'chart.js'
import {useTranslation} from 'react-i18next'

const colors = [
    '#142459',
    '#176BA0',
    '#19AADE',
    '#1AC9E6',
    '#1DE4BD',
    '#6DFDD2',
    '#29066B',
    '#7D3AC1',
    '#AF4BCE',
    '#DB4CB2',
    '#EB548C',
    '#EA7369',
    '#FDA58F',
    '#FCEAE6',
    '#820401',
    '#DE542C',
    '#EE9A3A',
    '#E7E34E',
]

const AssetAllocationChart = ({fund}: { fund: FundDto }) => {
    const chartRef = useRef<Chart<'doughnut', number[]> | null>(null)
    const assetAllocation = fund.holdings
    const labels = Object.keys(assetAllocation)
    const assetData = Object.values(assetAllocation)
    const otherPositions = 100 - assetData.reduce((asset, acc) => asset + acc, 0)
    assetData.push(otherPositions)
    labels.push('Andre innehav')

    const slicedColors = [...colors.slice(0, (assetData.length - 1)), '#f2f2f2']

    const data = [
        {
            label: 'Asset allocation',
            backgroundColor: slicedColors,
            hoverBackgroundColor: slicedColors,
            borderColor: 'transparent',
            data: assetData,
        },
    ]

    //.reduce((acc, score) => acc + score, 0)

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: data,
                },
                options: {
                    color: '#666',
                    maintainAspectRatio: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            displayColors: false,
                        },
                    },
                },
            })
        }
    }
    return (
        <Fragment>
            <div className={styles.assetAllocationChartWrapper}>
                <canvas className={styles.assetAllocationChart} ref={canvasCallback}/>
            </div>
        </Fragment>
    );
}

type Props = { fund: FundDto }

export default function FundPortfolio({fund}: Props) {
    const {t} = useTranslation()
    const showAllHoldings = true
    return (
        <div className={styles.portfolio}>
            <div>
                <h4>{t('pages-fund-details.holdings')}</h4>
                <ul className={classNames(styles.keyValueList, styles.holdings)}>
                    {Object.entries(fund.holdings).slice(0, showAllHoldings ? -1 : 5).map(([name, value]) => (
                        <li key={name}>
                            <span className={styles.key}>{name}</span>
                            <span className={styles.value}>{InterestOutput.format(value)}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <AssetAllocationChart fund={fund}/>
            </div>
        </div>
    )
}
