import Modal from '#components/Modal'
import styles from './SignableDocument.module.scss'
import {useTranslation} from "#components/i18n"

type Props = {
    onConfirm: () => void
    onCancel: () => void
}

export default function RemoveDocumentConfirmation({onConfirm, onCancel}: Props) {
    const {t} = useTranslation('components-SignableDocument')

    return (
        <Modal header={t('confirmDeletionHeading')}
               onSubmit={onConfirm}
               onCancel={onCancel}
        >
            <div className={styles.removeDocumentBody}>
                <p>{t('confirmDeletionMessage')}</p>
            </div>
        </Modal>
    )
}