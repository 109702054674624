import { BURNT_ORANGE, FOREST_GREEN, SILVER_GRAY, SUNSET_ORANGE } from '#app/colors/colors'
import { InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { getIconText } from '#app/components/PageHeader/PageHeader'
import useTableSort, { Column } from '#services/useTableSort'
import { PartnerCustomerDto, PartnerDto, PartnerUserDto } from '@fixrate/fixrate-query'
import { Avatar, Box, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useUiSetting from '#services/useUiSetting'

type Row = PartnerCustomerDto & {
    primaryAdvisorName: string | null
}

type Props = {
    partner: PartnerDto
    customers: PartnerCustomerDto[]
}

export default function CustomerList({partner, customers}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const Currency = useCurrencyOutput()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useUiSetting('FundCustomerList-rowsPerPage', 50)

    const rows: Row[] = customers.map(customer => {
        const primaryAdvisor: PartnerUserDto | undefined = partner.users.find(user => user.id === customer.primaryAdvisor)
        let status = t('common.ok')
        if (!customer.primaryAdvisor) {
            status = t('pages-customers.lacksAdvisor')
        }
        if (customer.accepted === null) {
            status = t('pages-customers.notAccepted')
        }
        if (customer.accepted === false) {
            status = t('pages-customers.rejected')
        }
        return {
            ...customer,
            primaryAdvisorName: primaryAdvisor ? `${primaryAdvisor.firstName} ${primaryAdvisor.lastName}` : '',
            statusText: status,
            unresolvedTasks: customer.unresolvedTasks,
            calculatedBalance: customer.calculatedBalance,
        }
    }) ?? []

    const {sortedRows, sortDirection, sortKey, setSorting} = useTableSort<Row>(rows, 'name', 'asc', 'name', 'asc')

    const columns: readonly Column<Row>[] = [
        {
            id: 'name',
            label: t('pages-customers.customer'),
        },
        {
            id: 'primaryAdvisorName',
            label: partner?.partnerCategory === 'ACCOUNTANT' ? t('pages-customers.accountant') : t('pages-customers.advisor'),
        },
        {
            id: 'unresolvedTasks',
            label: t('pages-customers.status'),
        },
        {
            id: 'calculatedBalance',
            label: t('pages-customers.calculatedBalance'),
        },
        {
            id: 'interestRate',
            label: t('pages-customers.averageInterestRate'),
        },
    ]

    const Pagination = (
        <TablePagination
            labelRowsPerPage={t('pages-customers.customersPerPage')}
            labelDisplayedRows={({from, to, count}) => t('pages-customers.customersDisplayed', {from, to, count})}
            rowsPerPageOptions={[50, 100, 250, 500]}
            component="div"
            count={customers.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value)
                setPage(0)
            }}
        />
    )

    return (
        customers.length > 0 ? (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={sortKey === column.id ? sortDirection : false}
                                >
                                    <TableSortLabel
                                        active={sortKey === column.id}
                                        direction={sortKey === column.id ? (sortDirection === 'asc' ? 'asc' : 'desc') : 'asc'}
                                        onClick={() => setSorting(column.id)}
                                    >
                                        {column.label}
                                        {sortKey === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer) => (
                                <TableRow
                                    data-cy="customerTableRow"
                                    key={customer.depositorId}
                                    sx={{'&:hover': {backgroundColor: 'rgba(77, 42, 152, 0.05)', cursor: 'pointer'}}}
                                    onClick={() => navigate('/customer/' + customer.depositorId)}
                                >
                                    <TableCell sx={{fontWeight: '600', fontSize: '1.4rem', width: '40rem'}}>{customer.name}</TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            {(!customer.primaryAdvisorName) ?
                                                <Chip sx={{backgroundColor: BURNT_ORANGE[50], color: BURNT_ORANGE[500], fontSize: '1.2rem', fontWeight: '600'}} variant="filled" label={partner?.partnerCategory === 'ACCOUNTANT' ? t('pages-customers.missingAccountant') : t('pages-customers.missingAdvisor')}/> :
                                                <Stack direction={'row'} spacing={0.5} alignItems="center">
                                                    <Avatar sx={{width: '2.6rem', height: '2.6rem', fontSize: '1.2rem', fontWeight: '600', backgroundColor: SILVER_GRAY[500]}}>{getIconText(customer.primaryAdvisorName)}</Avatar>
                                                    <span>{customer.primaryAdvisorName}</span>
                                                </Stack>
                                            }
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        {customer.accepted === null && (
                                            <Stack direction={'row'} spacing={0.5} alignItems="center">
                                                <Avatar sx={{width: '2.6rem', height: '2.6rem', fontSize: '1.2rem', backgroundColor: SUNSET_ORANGE[100], color: FOREST_GREEN[500]}}><i className="ri-mail-line"/></Avatar>
                                                <span>{t('pages-customers.awaitingApproval')}</span>
                                            </Stack>
                                        )}
                                        {customer.accepted === false && (
                                            <Stack direction={'row'} spacing={0.5} alignItems="center">
                                                <Avatar sx={{width: '2.6rem', height: '2.6rem', fontSize: '1.2rem', backgroundColor: BURNT_ORANGE[50], color: BURNT_ORANGE[500]}}><i className="ri-close-line"/></Avatar>
                                                <span>{t('pages-customers.requestDeclined')}</span>
                                            </Stack>
                                        )}
                                        {customer.accepted === true && (
                                            customer.warnings.length > 0 ?
                                                <Stack direction={'row'} spacing={0.5} alignItems="center">
                                                    <Avatar sx={{width: '2.6rem', height: '2.6rem', fontSize: '1.2rem', fontWeight: '600', backgroundColor: BURNT_ORANGE[50], color: BURNT_ORANGE[500]}}>{customer.warnings.length}</Avatar>
                                                    <span>{customer.warnings.length === 1 ? t('pages-customers.warning') : t('pages-customers.warnings')}</span>
                                                </Stack> :
                                                <Stack direction={'row'} spacing={0.5} alignItems="center">
                                                    <Avatar sx={{width: '2.6rem', height: '2.6rem', fontSize: '1.2rem', fontWeight: '600', backgroundColor: '#eaf7ec'}}><i className="ri-check-line green"/></Avatar>
                                                    <span>{t('pages-customers.allGood')}</span>
                                                </Stack>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {Currency(customer.calculatedBalance || 0)}
                                    </TableCell>
                                    <TableCell>
                                        {customer.interestRate !== null ? InterestOutput.format(customer.interestRate) : t('pages-customers.noDeposits')}
                                    </TableCell>
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
                {customers.length > 10 && Pagination}
            </TableContainer>
        ) : (
            <Typography variant="emptyState" fontSize={'2rem'} color={SILVER_GRAY[500]}>{t('pages-customers.noCustomers')}</Typography>
        )
    )
}
