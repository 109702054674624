import usePortfolio from '#app/services/usePortfolio'
import { useCommand } from '#command'
import { DateOutput, InterestOutput, NumberInput } from '#components'
import ActionBlock from '#components/ActionBlock/ActionBlock'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import DatePicker from '#components/DatePicker/DatePicker'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import LabeledInput from '#components/LabeledInput/LabeledInput'
import Modal from '#components/Modal'
import { SignableDocument } from '#components/SignableDocument/SignableDocument'
import { TerminationMode } from '#pages/portfolio-depositor/DepositDetails/DepositTermination/DepositTermination'
import { useAuthorization } from '#services/authorization'
import { formatAccount, formatIban } from '#services/formatnumber'
import { useBankCalendar } from '#services/useBankCalendar'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { useTermination } from '#services/useTermination'
import * as selectors from '#state/selectors'
import { TerminationState } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import {Alert, Button, InputLabel, MenuItem, Select, Stack, Typography} from '@mui/material'
import classNames from 'classnames'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import {useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import styles from './DepositTermination.module.css'
import {useSignableDocumentListInstruction} from '#components/SignableDocument/SignableDocumentList'
import AsyncButton from '#components/Button/AsyncButton'
import useCurrentDepositor from '#services/useCurrentDepositor'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { JSX } from 'react/jsx-runtime'

type Props = {
    deposit: DepositDto,
    terminationMode: TerminationMode,
    setTerminationMode: (arg0: TerminationMode) => void
}

export default function DepositTerminationStart({deposit, terminationMode, setTerminationMode}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {firstValidTerminationDate, lastValidTerminationDate} = useTermination()
    const {isValidBankDay} = useBankCalendar()
    const auth = useAuthorization(deposit?.depositor.id)
    const {terminateDeposit, cancelDepositTermination, startElectronicSignature} = useCommand()
    const portfolio = usePortfolio()
    const portfolioCurrency = portfolio?.currency
    const organisationCountry = useCurrentCountryCode()
    const [showSignModal, setShowSignModal] = useState(false)

    const depositorAccounts = useSelector(state => selectors.depositorSettlementAccounts(state)[deposit?.depositor.id])
    const accountsInCorrectCurrency = depositorAccounts?.filter(account => account.currency === portfolioCurrency || (account.currency === null && portfolioCurrency === 'NOK'))
    const hasOrderPermissions = auth.depositor.hasOrderRole || auth.depositor.hasPartnerAccountantRole
    const signedAuthDocIsMissing = useSelector(state => selectors.signedAuthorizationDocumentIsMissing(state)[deposit?.depositor.id])
    const _firstValidTerminationDate = startOfDay(firstValidTerminationDate(deposit, terminationMode))
    const _lastValidTerminationDate = endOfDay(lastValidTerminationDate(deposit, terminationMode))

    const terminationDocument = useSelector(state => deposit.terminationDocumentId && state.documents[deposit.terminationDocumentId])
    const isCheckingSignatureStatus = useSelector(state => deposit.terminationDocumentId && selectors.lookupSignatureStatus(state)(deposit.terminationDocumentId))
    const terminationDocumentSignatureInstructions = useSignableDocumentListInstruction([terminationDocument])
    const depositor = useCurrentDepositor()

    const connectedDepositAccount = depositorAccounts?.find(account => account.id === deposit.settlementAccountId)

    const interestRateRequirementValidator = useCallback(({isEditing, value}) => {
        if (isEditing) return

        if (value == null) {
            return t('pages-portfolio-depositor.terminationMarginMissing')
        }
        if (!`${value}`.match(/^-?(\d{0,2}([.,]\d\d?)?)$/)) {
            return t('pages-portfolio-depositor.terminationMarginInvalid')
        }
    }, [t])
    const interestRateRequirement = useFieldState<number | null>(null, interestRateRequirementValidator)

    const settlementAccountValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('pages-portfolio-depositor.terminationSettlementAccountMissing'))
    const settlementAccount = useFieldState<string>('', settlementAccountValidator)

    const terminationDateValidator = useStableValidator('NOT_EMPTY', t('pages-portfolio-depositor.terminationTerminationDateInvalid'))
    const terminationDate = useFieldState<Date>(_firstValidTerminationDate, terminationDateValidator)

    const otherReason = t('pages-portfolio-depositor.terminationReasonOther')
    const reasons = [t('pages-portfolio-depositor.terminationReason1'), t('pages-portfolio-depositor.terminationReason2'), t('pages-portfolio-depositor.terminationReason3'), otherReason]

    const selectedReasonValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('pages-portfolio-depositor.terminationReasonMissing'))
    const selectedReason = useFieldState<string>('', selectedReasonValidator)

    const statedReason = useFieldState<string>('')

    async function onStartSignature() {
        if (signedAuthDocIsMissing) {
            return
        }
        setShowSignModal(false)
        const processId = uuidv4()
        await startElectronicSignature(processId, 'SINGLE_DOCUMENT', terminationDocument.id)
        navigate(`/signature/${processId}?context=/portfolio/id/${deposit.id}`)
    }

    async function onCancelTermination() {
        const {waitForCommand} = await cancelDepositTermination(deposit.id)
        await waitForCommand()
        setShowSignModal(false)
    }

    function terminationDateFilter(date: Date) {
        return isValidBankDay(date) && !isBefore(date, _firstValidTerminationDate) && !isAfter(date, _lastValidTerminationDate)
    }

    async function onStartTermination() {
        const isValidSettlementAccount = connectedDepositAccount ? true : settlementAccount.validate()
        const isValidTerminationDate = terminationDate.validate()
        const isValidInterestRateRequirement = terminationMode !== 'CONDITIONAL' || interestRateRequirement.validate()

        const settlementAccountValue = connectedDepositAccount?.id ?? settlementAccount.value

        if (isValidSettlementAccount && isValidTerminationDate && isValidInterestRateRequirement) {
            const {waitForCommand} = await terminateDeposit(
                terminationMode,
                deposit.id,
                settlementAccountValue,
                (terminationMode === 'REJECT_INTEREST_RATE_CHANGE') ? undefined : format(terminationDate.value, 'yyyy-MM-dd'),
                terminationMode === 'CONDITIONAL' ? interestRateRequirement.value : undefined,
                selectedReason.value === otherReason ? statedReason.value : selectedReason.value,
            )
            const success = await waitForCommand()
            if (success) {
                setShowSignModal(true)
            }
        }
    }

    const signModal = (
        <Modal className={styles.modal} header={t('pages-portfolio-depositor.terminationDocumentCreated')}>
            <div className={styles.modalBody}>
                <div className={styles.modalContent}>
                    {!auth.depositor.hasAccountHolderRole && (
                        <p>
                            {t('pages-portfolio-depositor.terminationMustBeSignedByAccountHolder')}
                        </p>
                    )}
                    {signedAuthDocIsMissing && (
                        <p>
                            {t('pages-portfolio-depositor.signedAuthDocIsMissing')}
                        </p>
                    )}
                    {auth.depositor.hasAccountHolderRole && (
                        <p>
                            {t('pages-portfolio-depositor.terminationMustBeSignedAndSent')}
                        </p>
                    )}
                </div>

                <ButtonRow className={styles.buttonRow}>
                    <AsyncButton
                        variant={'outlined'}
                        autoFocus
                        onClick={onCancelTermination}
                    >
                        {t('pages-portfolio-depositor.terminationCancelAndRemove')}
                    </AsyncButton>

                    {!auth.depositor.hasAccountHolderRole && !signedAuthDocIsMissing && (
                        <Button variant={'contained'} onClick={() => setShowSignModal(false)}
                                data-cy="okButton">{t('common.ok')}</Button>
                    )}
                    {auth.depositor.hasAccountHolderRole && !signedAuthDocIsMissing && (
                        <Button variant={'contained'} onClick={onStartSignature}
                                data-cy="signDocumentButton">{t('pages-portfolio-depositor.terminationSign')}</Button>
                    )}
                </ButtonRow>
            </div>
        </Modal>)

    if (!hasOrderPermissions && deposit.terminationState === TerminationState.NO_ACTIVE_TERMINATION) {
        if (deposit.product.termsType === 'NOTICE' && terminationMode !== 'HIDE') {
            return (
                <ActionBlock header={t('pages-portfolio-depositor.terminationStartHeader')}>
                    <LabeledInfo
                        info={t('pages-portfolio-depositor.terminationStartInfo1', {count: deposit.product.days})}
                        helpText={t('pages-portfolio-depositor.noticeHelpText', {count: deposit.product.days})}/>
                    <p>{t('pages-portfolio-depositor.terminationStartInfo2')}</p>
                    <p>{t('pages-portfolio-depositor.terminationStartInfo3')}</p>
                </ActionBlock>
            )
        }
        if (deposit.product.termsType === 'RECURRING_FIXED_TERMS' && terminationMode !== 'HIDE') {
            return (
                <ActionBlock
                    header={t('pages-portfolio-depositor.terminationStartRecurringHeader')}
                >
                    <p>{t('pages-portfolio-depositor.terminationStartRecurringInfo1', {count: deposit.product.days})}</p>
                    <p>{t('pages-portfolio-depositor.terminationStartRecurringInfo2')}</p>
                    <p>{t('pages-portfolio-depositor.terminationStartRecurringInfo3')}</p>
                </ActionBlock>
            )
        }
    }

    const settlementAccountOptions = accountsInCorrectCurrency?.map(account =>
        <MenuItem key={account.id} value={account.id}>
            {account.name}&nbsp;({account.account ? formatAccount(account.account, organisationCountry) : formatIban(account.iban)})
        </MenuItem>
    )

    let header: string, instructions: JSX.Element, signatureElement: JSX.Element

    if (terminationMode === 'CONDITIONAL') {
        header = t('pages-portfolio-depositor.terminationConditionalHeader')
        instructions = (
            <>
                <p>{t('pages-portfolio-depositor.terminationConditionalInfo1', {count: deposit.product.days})}</p>
                <p>{t('pages-portfolio-depositor.terminationConditionalInfo2')}</p>
                <p>{t('pages-portfolio-depositor.terminationConditionalInfo3')}</p>
            </>
        )
    }
    if (terminationMode === 'REJECT_INTEREST_RATE_CHANGE') {
        header = t('pages-portfolio-depositor.terminationStartConditionalHeader')
        instructions = (
            <>
                <p>{t('pages-portfolio-depositor.terminationStartConditionalInfo1')}</p>
                <p>{t('pages-portfolio-depositor.terminationStartConditionalInfo2')}</p>
            </>
        )
    }
    if (terminationMode === 'UNCONDITIONAL' && deposit.product.termsType === 'NOTICE') {
        header = t('pages-portfolio-depositor.terminationStartHeader')
        instructions = (
            <>
                <LabeledInfo
                    info={t('pages-portfolio-depositor.terminationStartInfo1', {count: deposit.product.days})}
                    helpText={t('pages-portfolio-depositor.noticeHelpText', {count: deposit.product.days})}/>
                <p>{t('pages-portfolio-depositor.terminationStartInfo2')}</p>
            </>
        )
    }
    if (terminationMode === 'UNCONDITIONAL' && deposit.product.termsType === 'RECURRING_FIXED_TERMS') {
        header = t('pages-portfolio-depositor.terminationStartHeader')
        instructions = (
            <>
                <p>{t('pages-portfolio-depositor.terminationStartInfo2')}</p>
            </>
        )
    }

    if (deposit.terminationState === TerminationState.STARTED) {
        const isConditionalTermination = deposit.terminationDocumentType === 'CONDITIONAL_TERMINATION'
        const documentLinkText = isConditionalTermination ? t('pages-portfolio-depositor.conditionalTerminationDocumentName') : t('pages-portfolio-depositor.unconditionalTerminationDocumentName')

        signatureElement = (
            <SignableDocument
                isCheckingSignatureStatus={isCheckingSignatureStatus}
                linkText={documentLinkText}
                document={terminationDocument}
                onStartSignature={onStartSignature}
                onRemoveDocument={hasOrderPermissions && onCancelTermination}
                signingErrorText={signedAuthDocIsMissing ? t('pages-portfolio-depositor.terminationDocumentSigningErrorMissingAuthorization') : t('pages-portfolio-depositor.terminationDocumentSigningErrorNotAccountHolder')}
                showSigningError={!auth.depositor.hasAccountHolderRole || signedAuthDocIsMissing}
            />
        )

        if (isConditionalTermination) {
            header = t('pages-portfolio-depositor.conditionalTerminationCreatedHeader')
            instructions = (
                <>
                    <p data-cy="terminationDocumentCreatedInfo">{t('pages-portfolio-depositor.conditionalTerminationCreatedInfo1')}</p>
                    <p>{t('pages-portfolio-depositor.conditionalTerminationCreatedInfo2')}</p>
                </>
            )
        } else {
            header = t('pages-portfolio-depositor.unconditionalTerminationCreatedHeader')
            instructions = <p data-cy="terminationDocumentCreatedInfo">{t('pages-portfolio-depositor.unconditionalTerminationCreatedInfo', {signInstructions: terminationDocumentSignatureInstructions})}</p>
        }
    }

    const startTerminationBlock = (
        <Stack spacing={2} className={styles.inputfields}>
            {terminationMode === 'CONDITIONAL' && (
                <Stack spacing={1}>
                    <div className={styles.inputfieldRow}>

                        <LabeledInput
                            label={t('pages-portfolio-depositor.terminationStartNewMarginRequirementLabel')}
                            helpText={t('pages-portfolio-depositor.terminationStartNewMarginRequirementHelpText')}
                            errorMessage={!interestRateRequirement.valid && interestRateRequirement.errorMessage}
                            className={styles.input}
                        >
                            <Stack direction={'row'} alignItems={'center'}>
                                <span>{t('pages-portfolio-depositor.terminationStartNewMarginRequirementInfo', {margin: InterestOutput.format(deposit.nominalInterestRate)})}</span>
                                <NumberInput
                                    id="newInterestInput"
                                    className={styles.inputInterest}
                                    size={'small'}
                                    sx={{pl: 1}}
                                    value={interestRateRequirement.value}
                                    onChange={interestRateRequirement.setValue}
                                    onBlur={interestRateRequirement.onBlur}
                                    formatFn={v => v == null ? '' : `${v}`.replace('.', ',')}
                                />
                                <span className={styles.interestUnit}>%</span>
                            </Stack>
                        </LabeledInput>
                    </div>
                    {Math.abs(interestRateRequirement.value) >= 1 && (
                        <Alert severity={'warning'}>{t('pages-portfolio-depositor.terminationStartNewMarginRequirementWarning', {margin: InterestOutput.format(interestRateRequirement.value)})}</Alert>
                    )}
                </Stack>
            )}

            { connectedDepositAccount ? (
                <Stack>
                    <InputLabel>{t('pages-portfolio-depositor.terminationStartSettlementAccountLabel')}</InputLabel>
                    <Typography>{formatAccount(connectedDepositAccount?.account, organisationCountry)}</Typography>
                </Stack>
            ) : (
                <div className={styles.inputfieldRow}>
                    <LabeledInput
                        label={t('pages-portfolio-depositor.terminationStartSettlementAccountLabel')}
                        helpText={t('pages-portfolio-depositor.terminationStartSettlementAccountHelpText')}
                        errorMessage={!settlementAccount.valid && settlementAccount.errorMessage}
                        className={styles.input}
                    >
                        <Select
                            displayEmpty={true}
                            id="DepositTerminationStart_SettlementAccount"
                            value={settlementAccount.value}
                            onChange={e => settlementAccount.setValue(e.target.value)}
                            onBlur={settlementAccount.onBlur}
                        >
                            <MenuItem key={''} value={''}>{t('common.selectOption')}</MenuItem>
                            {settlementAccountOptions}
                        </Select>
                    </LabeledInput>
                </div>
            )}
            {(terminationMode !== 'REJECT_INTEREST_RATE_CHANGE') && (deposit.product.termsType !== 'RECURRING_FIXED_TERMS') &&
                <div className={styles.inputfieldRow}>
                    <LabeledInput
                        label={t('pages-portfolio-depositor.terminationStartSettlementDateLabel')}
                        helpText={t('pages-portfolio-depositor.terminationStartSettlementDateHelpText')}
                        errorMessage={!terminationDate.valid && terminationDate.errorMessage}
                        className={styles.input}
                    >
                        <DatePicker
                            id="terminationDateDatePicker"
                            className={classNames(styles.datepicker)}
                            selected={terminationDate.value}
                            onChange={terminationDate.setValue}
                            onBlur={terminationDate.onBlur}
                            filterDate={terminationDateFilter}
                        />
                    </LabeledInput>
                </div>
            }
            {(terminationMode !== 'REJECT_INTEREST_RATE_CHANGE') && (deposit.product.termsType === 'RECURRING_FIXED_TERMS') &&
                <div className={styles.inputfieldRow}>
                    <LabeledInput label={t('pages-portfolio-depositor.terminationStartDisbursementDateLabel')} className={styles.input}>
                        {DateOutput.formatVerboseMonth(terminationDate.value)}
                    </LabeledInput>
                </div>
            }

            {terminationMode === 'UNCONDITIONAL' && (
                <div className={styles.inputfieldRow}>
                    <LabeledInput
                        label={t('pages-portfolio-depositor.terminationReasonLabel')}
                        helpText={t('pages-portfolio-depositor.terminationReasonHelpText')}
                        errorMessage={!selectedReason.valid && selectedReason.errorMessage}
                        className={styles.input}
                    >
                        <Select displayEmpty id="reasonSelect" onChange={e => selectedReason.setValue(e.target.value)} value={selectedReason.value}>
                            <MenuItem key={''} value={''}>{t('common.selectOption')}</MenuItem>
                            {reasons.map(reason => <MenuItem key={reason} value={reason}>{reason}</MenuItem>)}
                        </Select>
                        {selectedReason.value === otherReason && (
                            <textarea
                                name="reason"
                                className={styles.input} value={statedReason.value}
                                onChange={e => statedReason.setValue(e.target.value)}
                            />
                        )}
                    </LabeledInput>
                </div>
            )}
        </Stack>
    )

    return (
        <>
            {showSignModal && signModal}
            {!showSignModal && (
                <ActionBlock header={header}>
                    {instructions}
                    {depositor.customerDeclarationExpired && <Alert severity={'warning'}>{t('pages-portfolio-depositor.terminationWhenCustomerDeclIsMissing')}</Alert>}
                    {deposit.terminationState === TerminationState.NO_ACTIVE_TERMINATION && startTerminationBlock}
                    {deposit.terminationState !== TerminationState.NO_ACTIVE_TERMINATION && signatureElement}
                    {deposit.terminationState === TerminationState.NO_ACTIVE_TERMINATION && (
                        <ButtonRow>
                            <Button variant={'outlined'} onClick={() => setTerminationMode('HIDE')}>
                                {t('common.cancel')}
                            </Button>
                            <AsyncButton variant={'contained'} id="createTerminationDocumentButton" onClick={onStartTermination}>
                                {t('pages-portfolio-depositor.terminationStartButtonText')}
                            </AsyncButton>
                        </ButtonRow>
                    )}
                </ActionBlock>
            )}
        </>
    )
}
