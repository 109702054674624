import { useCommand } from '#command'
import Modal from '#components/Modal'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { useSelector } from '#state/useSelector'
import { MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomerDetailSelectAdvisor.module.css'


type Params = {
    depositorId?: string
}

export default function CustomerDetailSetPrimaryAdvisor() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const {setPartnerPrimaryAdvisor} = useCommand()

    const partner = useSelector(state => state.partner)
    const customer = useSelector((state => state.partner.customers.find(c => c.depositorId === params.depositorId)))

    const selectedValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('pages-customers.customerDetailSelectAdvisorValidation'))
    const selected = useFieldState('', selectedValidator)

    const isAccountant = partner.partnerCategory === 'ACCOUNTANT'

    const options = partner.users
        .filter(user => user.roles.includes('PARTNER_ADVISOR'))
        .filter(user => customer.advisors.includes(user.id))
        .map(user => (
            <MenuItem key={user.id} value={user.id} disabled={customer.primaryAdvisor === user.id}>
                {user.firstName} {user.lastName} {customer.primaryAdvisor === user.id ? `(${t('pages-customers.primary')})` : ''}
            </MenuItem>
        ))

    async function onSubmit() {
        if (selected.validate()) {
            const {waitForCommand} = await setPartnerPrimaryAdvisor(partner.id, customer.depositorId, selected.value)
            const success = await waitForCommand()
            if (success) {
                navigate(`/customer/${customer.depositorId}`)
            }
        }
    }

    return (
        <Modal header={isAccountant ? t('pages-customers.customerDetailSelectPrimaryContactHeading') : t('pages-customers.customerDetailSelectPrimaryAdvisorHeading')}
                width={'40rem'}
                onSubmit={onSubmit}
                submitButtonText={t('pages-customers.select')}
                cancel={`/customer/${customer.depositorId}`}
        >
            <div className={styles.body}>
                <Select displayEmpty value={selected.value} onChange={e => selected.setValue(e.target.value)}>
                    <MenuItem
                        key="empty"
                        value=""
                        disabled>{isAccountant ? t('pages-customers.customerDetailSelectPrimaryContactPlaceholder') : t('pages-customers.customerDetailSelectAdvisorPlaceholder')}
                    </MenuItem>
                    {options}
                </Select>
            </div>
        </Modal>
    )
}
