import useStatusMessage from '#services/useStatusMessage'
import classNames from 'classnames'
import styles from './StatusMessageBanner.module.scss'

export default function StatusMessageBanner() {

    const message = useStatusMessage()

    if (!message) {
        return null
    }

    return (
        <div className={styles.banner}>
            <div className={styles.ballContainer}>
                <div className={styles.ball}/>
                <div className={classNames(styles.ball, styles.pulse)}/>
            </div>
            <span>{message}</span>
        </div>
    )
}