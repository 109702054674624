import styles from './Pagination.module.scss'

type Props = {
    totalItems: number
    itemsPerPage: number
    selectedPage: number
    setPage: (page: number) => void
}

export default function Pagination({totalItems, itemsPerPage, selectedPage, setPage}: Props) {
    return (
        <ul className={styles.pagination}>
            {[...new Array(Math.ceil(totalItems / itemsPerPage))].map((_, i) => (
                <li key={i} className={selectedPage === i ? styles.activePagination : undefined} onClick={() => setPage(i)}>
                    <span>{i + 1}</span>
                </li>
            ))}
        </ul>
    )
}