import { useLocalStorage } from '#app/services/LocalStorageContext'
import { useSelector } from '#app/state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, Stack } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FxDialog from '../FxDialog/FxDialog'
import FxDialogContent from '../FxDialog/FxDialogContent'
import { getOrganisationSelectorOptions } from '../PageHeader/PageHeader'

type Props = {
    newOrgId: string
    closeFn: () => void
    switchOrganisation: (organisationType: OrganisationType, orgId: string) => void
}

export default function ChangeOrgDialog({newOrgId, closeFn, switchOrganisation}: Props) {
    const {t} = useTranslation()
    const {
        keys: { doNotShowInboxSwitchMessage },
        dispatchLocalStorage,
    } = useLocalStorage()
    const [doNotShowAgain, setDoNotShowAgain] = useState(false)
    const associations = useSelector(state => state.session?.associations)
    const association = useSelector(state => state.session?.association)
    const orgOptions = getOrganisationSelectorOptions(associations, association)
    const newOrg = orgOptions.find(org => org.organisationId === newOrgId)
    const currentOrg = orgOptions.find(org => org.selected)

    function onSelectRole(organisationType: OrganisationType, orgId: string) {
        if (!doNotShowInboxSwitchMessage) {
            dispatchLocalStorage({ type: 'HIDE_INBOX_SWITCH_MESSAGE', payload: doNotShowAgain })
        }
        switchOrganisation(organisationType, orgId)
    }

    if (doNotShowInboxSwitchMessage) {
        return (
            <Dialog open={true}>
                <Backdrop
                    open={true}
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <CircularProgress color={'inherit'} />
                </Backdrop>
            </Dialog>
        )
    }

    return (
        <FxDialog open={true} maxWidth={'xs'} onClose={closeFn}>
            <FxDialogContent sx={{padding: '4rem', textAlign: 'center'}}>
                <h1>{t('pages-organizations.changeOrg')}</h1>
                <p>{t('pages-organizations.changeOrgExplanation', {currentOrg: currentOrg?.name, newOrg: newOrg?.name })}</p>
                <Stack spacing={1} mt={2} mb={2} direction={'row'} alignItems='center' justifyContent={'center'}>
                    <Avatar sx={{width: '8rem', height: '8rem', backgroundColor: currentOrg?.iconColor}}>{ currentOrg?.iconName }</Avatar>
                    <i style={{fontSize: '2.8rem'}} className='ri-arrow-right-line'/>
                    <Avatar sx={{width: '8rem', height: '8rem', backgroundColor: newOrg?.iconColor}}>{ newOrg?.iconName }</Avatar>
                </Stack>
                <FormControlLabel control={<Checkbox checked={doNotShowAgain} onChange={(e: ChangeEvent<HTMLInputElement>) => setDoNotShowAgain(e.target.checked)} />} label={t('common.doNotShowMessage')} />
                <Stack spacing={1} mt={1}>
                    <Button variant='contained' onClick={() => onSelectRole(currentOrg?.organisationType, newOrgId)}>
                        <Box maxWidth="100%" overflow="hidden" textOverflow="ellipsis">{t('pages-organizations.changeToOrg', {org: newOrg?.name})}</Box>
                    </Button>
                    <Button variant='outlined' onClick={closeFn}>{t('common.cancel')}</Button>
                </Stack>
            </FxDialogContent>
        </FxDialog>
    )
}
