import { useSelector } from '#app/state/useSelector'
import CustomerDetail from '#pages/Customers/CustomerDetail/CustomerDetail'
import CustomerOverview from '#pages/Customers/CustomerOverview/CustomerOverview'
import { Route, Routes, useNavigate } from 'react-router-dom'

export default function Customers() {
    const navigate = useNavigate()
    const sessionOrgType = useSelector(state => state.session.organisationType)

    if (sessionOrgType !== 'PARTNER') {
        navigate('/marketplace')
        return null
    }

    return (
        <Routes>
            <Route path={''} element={<CustomerOverview openRegistration={false}/>} />
            <Route path={'register'} element={<CustomerOverview openRegistration={true}/>} />
            <Route path={':depositorId'} element={<CustomerDetail/>} />
            <Route path={':depositorId/:modal'} element={<CustomerDetail/>} />
            <Route path={':depositorId/:modal/:id'} element={<CustomerDetail/>} />
        </Routes>
    )
}
