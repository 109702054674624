import AsyncButton from '#app/components/Button/AsyncButton'
import RegistrationProgress from '#app/layers/Signup/RegistrationProgress'
import { syncSessionState } from '#app/services/thunks/session'
import { showConfirmationModal } from '#app/state/reducers/confirmationModal'
import { useSelector } from '#app/state/useSelector'
import { useCommand } from '#command'
import { ButtonRow } from '#components'
import CompanySelect from '#components/CompanySelect'
import { CompanyInfo, useWarningMessageOnRegisterCompany } from '#components/CompanySelect/CompanySelect'
import OnboardingFrame from '#components/OnboardingFrame'
import { Alert, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import styles from './RegisterCompany.module.scss'

export default function RegisterCompanyNO() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { createDepositor } = useCommand()
    const { setProspectiveCompany } = useCommand()
    const session = useSelector((state) => state.session)
    const { trackEvent } = useTracking({ page: 'ChooseOrganization', session })
    const [company, setCompany] = useState<CompanyInfo | null>(null)
    const [error, setError] = useState('')

    function cancel() {
        navigate('/marketplace/')
    }

    async function submit() {
        if (!company) {
            setError(t('layers-Signup.registerCompanyValidation'))
        } else {
            const { waitForCommand: waitForData } = await setProspectiveCompany(
                company.nationalIdentity,
                company.name
            )
            await waitForData()

            const { waitForCommand: waitForDepositorData } = await createDepositor(company.nationalIdentity, company.nationality)
            const success = await waitForDepositorData()
            if (success) {
                trackEvent({ event: 'registerNewOrganization' })
                dispatch(syncSessionState())
                navigate('/marketplace?welcome')
            } else {
                dispatch(
                    showConfirmationModal({
                        title: t('command.error.organisationAlreadyRegistered'),
                        text: t('layers-Signup.registerCompanyAlreadyExistsMessage', {
                            companyName: company.name,
                        }),
                        buttonText: t('common.ok'),
                    })
                )
                navigate('/marketplace?welcome')
            }
        }
    }

    const getWarningMessage = useWarningMessageOnRegisterCompany(company, session)

    const registrationProgress = <RegistrationProgress step={2} />
    return (
        <OnboardingFrame
            visibleOverflow={true}
            header={t('layers-Signup.registerCompanyHeading')}
            cancel={'/marketplace'}
            progress={registrationProgress}
        >
            <p>{t('layers-Signup.registerCompanyMessage')}</p>

            <div>
                <label className={styles.inputLabel}>{t('layers-Signup.registerCompanyLabel')}</label>
                <CompanySelect
                    onChange={setCompany}
                    setErrorMessage={setError}
                    placeholder={t('common.search')}
                />
                {getWarningMessage && (
                    <Alert sx={{ mt: 1 }} severity='warning'>
                        {getWarningMessage}
                    </Alert>
                )}

                <div className='field-error-message'>{error}</div>
            </div>

            <ButtonRow>
                <Button variant={'outlined'} data-cy='signupCancelButton' onClick={cancel}>
                    {t('layers-Signup.registerCompanyPostpone')}
                </Button>
                <AsyncButton
                    data-cy='signupContinueButton'
                    variant='contained'
                    onClick={submit}
                    disabled={getWarningMessage !== null}
                >
                    {t('layers-Signup.registerCompanyComplete')}
                </AsyncButton>
            </ButtonRow>
        </OnboardingFrame>
    )
}
