import { useEffect, useState } from 'react';
import styles from './OrderProcess.module.scss'
import {DepositorDto, OrganisationalPersonDto, OrganisationalRole, SecurityRole} from '@fixrate/fixrate-query'
import BadgeList from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/BadgeList'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import BrregChanges from "#pages/organizations/OrganizationDetail/UsersAndRoles/views/BrregChanges"
import BrregBox from "#pages/organizations/OrganizationDetail/UsersAndRoles/views/BrregBox"
import BrregOwners from "#pages/organizations/OrganizationDetail/UsersAndRoles/views/BrregOwners"
import {Checkbox, FormControlLabel, FormGroup, Stack, Typography} from '@mui/material'
import {useCommand} from "#command"
import {PersonValidator} from "#services/PersonValidator"
import {useAuthorization} from '#services/authorization'
import {HelpIcon} from "#components";
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';

interface Props {
    depositor: DepositorDto,
    inline?: boolean
}

export default function Owners({depositor, inline = false}: Props) {
    const {t} = useTranslation()

    const confirmChangesVisible = depositor.importedPeopleChanges?.includes('OWNERS')
    const hasBeneficialOwners = PersonValidator.hasBeneficialOwners(depositor)
    const organisationCountry = useCurrentCountryCode()

    const [brregInfoVisible, setBrregInfoVisible] = useState(!!confirmChangesVisible)

    const auth = useAuthorization(depositor.id)
    const canEdit = auth.depositor.hasAdminRole || auth.depositor.hasPartnerRole || auth.depositor.hasAccountHolderRole

    // Using state for immediate response, but...
    const [noOwnersToSpecify, setNoOwnersToSpecify] = useState(depositor.noBeneficialOwners)
    useEffect(() => {
        setNoOwnersToSpecify(depositor.noBeneficialOwners) // Updates from server overrides local state
    }, [depositor.noBeneficialOwners])

    // Only a single AddUserButton can be open at the same time in this context
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const closeOpenRoleOnClickOutside = () => {
        if (selectedOpenRole != null) {
            setSelectedOpenRole(null)
        }
    }

    const {setDepositorNoBeneficialOwners} = useCommand()

    const submitNoOwnersToSpecify = async (checked) => {
        setNoOwnersToSpecify(checked)
        const {waitForCommand} = await setDepositorNoBeneficialOwners(depositor.id, checked)
        await waitForCommand()
    }

    const numRegEx = /^[0-9]{1,2}(.|,)?[0-9]{0,20}$/g
    const getControlShareHtml = (person: OrganisationalPersonDto) => person.controlShare ?
        <span>
            Eierandel: {person.controlShare.match(numRegEx) ? parseFloat(person.controlShare.replace(',', '.')).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : person.controlShare}
            {person.controlShare.match(numRegEx) ? '%' : ''}
        </span> :
        <span><i className="ri-error-warning-line" />Eierandel ukjent</span>
    
    const nonSelectableRoles = ['DEPOSITOR_PARTNER_SUPPORT']

    const selectablePeople = depositor.people.filter(person => person.securityRoles.every(role => !nonSelectableRoles.includes(role)))


    return (
        <Stack spacing={4} className={classNames(inline ? styles.inline : null)} onClick={closeOpenRoleOnClickOutside}>
            {organisationCountry === 'NO' && (
                <Stack>
                    {confirmChangesVisible && <BrregChanges depositor={depositor} changeType={'OWNERS'} />}
                    <BrregBox header={t('pages-organizations.boardBeneficialOwnersRegisterDescription')} infoVisible={brregInfoVisible} setInfoVisible={setBrregInfoVisible}>
                        <BrregOwners depositor={depositor} />
                        {/*Vi henter informasjon vedrørende aksjonærer og eierposter fra Skattetatens Aksjonærregister via Proff®. Informasjonen gjenspeiler aksjonærer ved utgangen av siste regnskapsår, og oppdateres automatisk på proff.no innen tre uker etter at foregående års Aksjonærregister offentliggjøres – normalt i slutten av mai.*/}
                    </BrregBox>
                </Stack>
            )}
            <Stack>
                <Stack direction={'row'} spacing={1} paddingBottom={1}>
                    <Typography variant='h2'>
                        <i className='ri-auction-line' />
                        <span>{t('pages-organizations.boardBeneficialOwners')}</span>
                    </Typography>
                    <HelpIcon sx={{ width: '2.2rem', height: '2.2rem' }} supportArticleId={'162a599bc9264377aefec42d7d4991ab'} />
                </Stack>
                <p>{t('pages-organizations.boardBeneficialOwnersDescription')}</p>
                <BadgeList
                    selectedOpenRole={selectedOpenRole}
                    setSelectedOpenRole={setSelectedOpenRole}
                    depositor={depositor}
                    people={selectablePeople}
                    organisationalRole='BENEFICIAL_OWNER'
                    getHeader={getControlShareHtml}
                    row
                />

                {!hasBeneficialOwners && !noOwnersToSpecify && <p className={classNames('field-error-message')}>{t('pages-organizations.noBeneficialOwnersFeedback')}</p>}

                {!hasBeneficialOwners && (
                    <FormGroup>
                        <FormControlLabel
                            disabled={!canEdit}
                            control={<Checkbox checked={noOwnersToSpecify} data-cy='noBeneficialOwnersCheckbox' onChange={e => submitNoOwnersToSpecify(e.target.checked)} />}
                            label={<span>{t('pages-organizations.noBeneficialOwnersStatement')} </span>}
                        />
                    </FormGroup>
                )}
            </Stack>
        </Stack>
    )
}
