import { PURPLE } from '#app/colors/colors'
import { InterestOutput, NumberInput } from '#app/components'
import AsyncButton from '#app/components/Button/AsyncButton'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import FixrateIcon from '#app/components/FixrateIcon/FixrateIcon'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { InterestBadge } from '#app/components/InterestBadge/InterestBadge'
import PartnerLogo from '#app/components/PartnerLogo/PartnerLogo'
import { SignableDocument } from '#app/components/SignableDocument/SignableDocument'
import config from '#app/config'
import { useCommand } from '#app/services/beta'
import { logout } from '#app/services/thunks/session'
import usePartnerInformation from '#app/services/usePartnerInformation'
import { isLoggedIn, lookupSignatureStatus } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { DepositorPartnerProposalDto, DepositorPartnerProposalStatus } from '@fixrate/fixrate-query'
import { Alert, AlertTitle, Box, Button, Card, CardContent, Divider, InputAdornment, List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Fragment, useState } from 'react'
import { useTranslation, WithTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import FixrateLogo from '../menu/FixrateLogo'
import RejectedIllustration from './Rejected.svg?react'

type Params = {
    partnerId?: string
    proposalId?: string
    step?: "1" | "2" | "3"
}

type Term = {
    primary: string
    secondary?: string
    value: string | JSX.Element
}

type WizardStep = {
    label: string
    content: JSX.Element
    backButton?: boolean
    actionButton: {
        label: string
        onClick: () => void
        dataCy?: string
    } | null
}

export function getProductName(productId: string, t: WithTranslation['t']) {
    const fixedProducts = [1, 2, 3, 4, 11, 12]
    const regularProducts = [5, 6, 7, 10]
    const frnProducts = [8, 9]
    if (regularProducts.includes(parseInt(productId))) {
        return t('pages-marketplace.noticePeriod')
    }
    if (fixedProducts.includes(parseInt(productId))) {
        return t('pages-marketplace.duration')
    }
    if (frnProducts.includes(parseInt(productId))) {
        return t('pages-marketplace.product')
    }
}

const BankCard = ({ selectedOffer, amount } : { selectedOffer: DepositorPartnerProposalDto, amount: number } ) => {
    const { t } = useTranslation()
    const banks = useSelector(state => state.banks)
    const products = useSelector(state => state.products)
    const API_BASE_URL = config().apiUrl
    const getBankLogoUrl = bankId => `${API_BASE_URL}/api/bank/${bankId}/image/logo`
    const bankName = banks[selectedOffer?.ad?.bankId]?.name
    const isFixed = products[selectedOffer?.ad?.productId]?.type === 'FIXED'

    return (
        <Card sx={{my: 2, width: "45rem", maxWidth: "100%"}}>
            <CardContent sx={{ py: 1, pr: 1 }}>
                <Stack flexWrap={'wrap'} direction={'row'} justifyContent='space-between' alignItems='center'>
                    <Typography variant='h3'>{bankName}</Typography>
                    <img style={{ width: '14rem' }} src={getBankLogoUrl(selectedOffer?.ad?.bankId)} alt='' />
                </Stack>
            </CardContent>
            <Divider />
            <CardContent>
                <Stack spacing={1} alignItems="flex-start">
                    <Typography>
                        {getProductName(selectedOffer?.ad?.productId, t)}: {t(`common.productLongName${selectedOffer?.ad?.productId}`)}
                    </Typography>
                    <Typography>
                        <span>{InterestOutput.format(selectedOffer?.ad?.interest)} </span>
                        <span>{isFixed ? t('common.fixedInterest') : t('common.floatingInterest')}</span>
                    </Typography>
                    <Box>
                        <Typography fontWeight='700' mt={1}>
                            <span>{t('layers-proposalWizard.depositAmount')} </span>
                            <span data-cy="amountOrdered">
                                {CurrencyOutputObject((amount) * 1e6, {
                                    minimumDecimals: 0,
                                    maximumDecimals: 0,
                                })}
                            </span>
                        </Typography>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default function PartnerDepositorProposalWizard() {
    const { t } = useTranslation()
    const { updatePartnerProposal, startElectronicSignature } = useCommand()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector(state => state?.session?.id)
    const { partnerId, proposalId } = useParams<Params>()
    const [searchParams, setSearchParams] = useSearchParams()
    const step = searchParams.get('step')
    const [currentStep, setCurrentStep] = useState(step ? parseInt(step) : 1)
    const [orderVolume, setOrderVolume] = useState(0)
    const [triedAccept, setTriedAccept] = useState(false)
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
    const [showDocumentNotSignedError, setShowDocumentNotSignedError] = useState(false)
    const [waitingForResponse, setWaitingForResponse] = useState(false)
    const products = useSelector(state => state.products)
    const banks = useSelector(state => state.banks)
    const signatureStatus = useSelector(lookupSignatureStatus)
    const loggedIn = useSelector(isLoggedIn)
    const { authorizationDocument, selectedPartner } = usePartnerInformation(partnerId)
    const selectedOffer = selectedPartner?.partnerProposals.find(proposal => proposal.proposalId === proposalId)

    const documentSignedByCurrentUser = authorizationDocument?.allSignatures?.find(signature => signature?.userId === userId)

    const bankName = banks[selectedOffer?.ad?.bankId]?.name
    const isFixed = products[selectedOffer?.ad?.productId]?.type === 'FIXED'

    const orderVolumeInRange = (orderVolume >= selectedOffer?.ad?.min && orderVolume <= selectedOffer?.ad?.max)

    const documentSigned = documentSignedByCurrentUser || authorizationDocument?.signedByAll

    const changeStep = (step: number) => {
        setCurrentStep(step)
        navigate(`/partner/proposals/${partnerId}/${proposalId}`)
        setSearchParams({ step: step.toString() })
    }

    async function startSignature() {
        const processId = uuidv4()
        try {
            await startElectronicSignature(processId, 'SINGLE_DOCUMENT', authorizationDocument?.id)
            navigate(`/signature/${processId}?context=/partner/proposals/${selectedOffer?.partnerId}/${selectedOffer?.proposalId}/signing-step`)
        } catch (err) {
            console.error(err)
        }
    }

    async function rejectProposal() {
        const status: DepositorPartnerProposalStatus = 'REJECTED'

        const { waitForCommand } = await updatePartnerProposal(proposalId, partnerId, selectedOffer?.depositorId, status, 0)
        const success = await waitForCommand()
        if (success) {
            console.log(success)
        }
    }

    async function acceptProposal() {
        setTriedAccept(true)
        setWaitingForResponse(true)
        if (orderVolumeInRange && authorizationDocument && !signatureStatus(authorizationDocument?.id)) {
            const status: DepositorPartnerProposalStatus = 'ACCEPTED'

            const { waitForCommand } = await updatePartnerProposal(proposalId, partnerId, selectedOffer?.depositorId, status, orderVolume)
            const success = await waitForCommand()
            if (success) {
                console.log(success)
                changeStep(4)
            }
        }

        setWaitingForResponse(false)
    }

    const terms: Term[] = [
        { primary: t('common.effectiveInterest'), secondary: format(new Date(), 'yyyy-MM-dd'), value: <InterestBadge interest={selectedOffer?.ad?.interest} /> },
        { primary: t('common.interestRateType'), secondary: '', value: isFixed ? t('common.fixedInterest') : t('common.floatingInterest') },
        { primary: t('common.bank'), secondary: '', value: bankName },
        { primary: getProductName(selectedOffer?.ad?.productId, t), secondary: '', value: t(`common.productLongName${selectedOffer?.ad?.productId}`) },
    ]

    const wizardSteps: WizardStep[] = [
        {
            label: t('layers-proposalWizard.labelProposal'),
            content: (
                <Fragment>
                    <Box>
                        <Typography variant='h2'>{t('layers-proposalWizard.creationOfNewAccountTitle', { depositorName: selectedOffer?.depositorName })}</Typography>
                        <Typography>{t('layers-proposalWizard.aboutTheOffer', {
                            initiator: selectedOffer?.initiatorName,
                            partner: selectedPartner?.name,
                            bank: bankName,
                            interest: InterestOutput.format(selectedOffer?.ad?.interest)
                        })}</Typography>
                    </Box>
                    <Stack spacing={1}>
                        <Typography variant='h3'>{t('layers-proposalWizard.accountDetails')}</Typography>
                        <List sx={{ mt: 1, py: 0, maxWidth: '35rem', border: '0.1rem solid rgba(0,0,0,0.15)', borderRadius: '0.5rem' }}>
                            {terms.map((term, index) => (
                                <Fragment key={index}>
                                    <ListItem sx={{ minHeight: '6rem', py: 0 }}>
                                        <ListItemText primary={term.primary} secondary={term.secondary} />
                                        {typeof term.value === 'string' ? <Typography>{term.value}</Typography> : term.value}
                                    </ListItem>
                                    {index + 1 < terms.length && <Divider />}
                                </Fragment>
                            ))}
                        </List>
                    </Stack>
                </Fragment>
            ),
            actionButton: {
                label: t('layers-proposalWizard.goToSigningOfAuthorization'),
                onClick: () => changeStep(2),
                dataCy: 'goToSigning'
            },
        },
        {
            label: t('layers-proposalWizard.labelAuthorization'),
            content: (
                <Fragment>
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant='h2'>{t('layers-proposalWizard.documentsToBeSigned')}</Typography>
                            <Typography>{t('layers-proposalWizard.documentsToBeSignedDescription', {partner: selectedPartner?.name})}</Typography>
                        </Box>
                        <Stack maxWidth={'45rem'}>
                            { authorizationDocument ? (
                                <SignableDocument
                                    document={authorizationDocument}
                                    hideRemoveButton={true}
                                    isCheckingSignatureStatus={signatureStatus(authorizationDocument?.id)}
                                    linkText={t('pages-organizations.powerOfAttorneyDocument')}
                                    onStartSignature={startSignature}
                                    showSigningError={false}
                                />
                            ) : (
                                <Alert severity="warning">
                                    <AlertTitle>{t('layers-proposalWizard.missingDocument')}</AlertTitle>
                                    {t('layers-proposalWizard.missingDocumentDescription', {partner: selectedPartner?.name})}
                                </Alert>
                            )}
                        </Stack>
                        { (!documentSigned && showDocumentNotSignedError) && (
                            <Alert severity="error">{t('layers-proposalWizard.mustSignDocumentMessage')}</Alert>
                        )}
                    </Stack>
                </Fragment>
            ),
            backButton: true,
            actionButton: {
                label: t('layers-proposalWizard.goToOrder'),
                onClick: () => {
                    if (!documentSigned) {
                        setShowDocumentNotSignedError(true)
                        return
                    }
                    changeStep(3)
                },
                dataCy: 'goToOrder'
            },
        },
        {
            label: t('layers-proposalWizard.labelOrder'),
            content: (
                <Fragment>
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant='h2'>{t('layers-proposalWizard.orderAmount')}</Typography>
                            <Typography>{t('layers-proposalWizard.orderAmountDescription', {min: selectedOffer?.ad?.min, max: selectedOffer?.ad?.max})}</Typography>
                        </Box>
                        <NumberInput
                            value={orderVolume}
                            onChange={val => setOrderVolume(val)}
                            noDecimals
                            data-cy='orderVolume'
                            sx={{ width: '17rem' }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>{t('common.millionShort')}</InputAdornment>,
                            }}
                            formatFn={v => CurrencyOutputObject(v, { withCurrency: false, minimumDecimals: 0, maximumDecimals: 0})}
                        />
                        { (!orderVolumeInRange && triedAccept) && (
                            <Alert severity="error">
                                {t('layers-proposalWizard.orderAmountAlert', {min: selectedOffer?.ad?.min, max: selectedOffer?.ad?.max})}
                            </Alert>
                        )}
                    </Stack>
                </Fragment>
            ),
            backButton: true,
            actionButton: {
                label: t('layers-proposalWizard.confirmOrder'),
                onClick: () => {
                    acceptProposal()
                },
                dataCy: 'confirmOrder'
            },
        },
        {
            label: t('layers-proposalWizard.confirmation'),
            content: (
                <Stack alignItems='center' justifyContent='center' spacing={3} mb={2}>
                    <Stack maxWidth={'60rem'} justifyContent='center' textAlign='center'>
                        <FixrateIcon name='handshakeFill' sx={{ '& img': { transform: 'scale(1.2)' } }} />
                        <Typography variant='h2'>{t('layers-proposalWizard.confirmationTitle')}</Typography>
                        <Typography>
                            {t('layers-proposalWizard.confirmationDescription', {depositor: selectedOffer?.depositorName, partner: selectedPartner?.name})}
                        </Typography>
                    </Stack>
                    <BankCard selectedOffer={selectedOffer} amount={selectedOffer?.amount || orderVolume} />
                    <Button variant='contained' onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                        {t('layers-proposalWizard.exitAndLogout')}
                    </Button>
                </Stack>
            ),
            actionButton: null,
        },
    ]

    const currentWizardStep = wizardSteps[currentStep - 1]
    const showWizard = selectedOffer?.status === 'PENDING' || (selectedOffer?.status === 'ACCEPTED' && currentStep === 4)

    return (
        <Stack overflow={'auto'} bgcolor={'#F6F6F6'} left='0' top='0' width={'100%'} height={'100%'} position={'fixed'} zIndex="2">
            { (loggedIn && showWizard) && (
                <Stack alignItems='center' justifyContent='center' mt='auto' py={3}>
                    <Stack alignItems={'center'} width='100%'>
                        <Paper sx={{ maxWidth: '100%', width: '85rem', minHeight: '60rem', p: 4 }}>
                            <Stack justifyContent={'space-between'} height='100%' overflow='auto'>
                                <Stack spacing={3}>
                                    <Stack direction={{ md: 'row' }} justifyContent={'space-between'}>
                                        <Stack direction='row' spacing={0.5}>
                                            {wizardSteps.map((step, index) => (
                                                <Stack sx={{ opacity: currentStep === index + 1 ? '1' : '0.5' }} key={step.label} direction={'row'} alignItems={'center'}>
                                                    <Stack direction="row" alignItems="center" sx={{gap: 0.8, p: 1}}>
                                                        <Stack
                                                            alignItems={'center'}
                                                            justifyContent='center'
                                                            height={{ xs: '2rem', md: '3rem' }}
                                                            width={{ xs: '2rem', md: '3rem' }}
                                                            border={'0.1rem solid'}
                                                            color={PURPLE[500]}
                                                            borderRadius='10rem'
                                                        >
                                                            <Typography fontWeight={'600'} fontSize={{ xs: '1.2rem', md: '1.4rem' }}>
                                                                {index + 1}
                                                            </Typography>
                                                        </Stack>
                                                        <Typography fontWeight={'600'} fontSize={{ xs: '1.2rem', md: '1.4rem' }}>
                                                            {step.label}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ))}
                                        </Stack>
                                        <Box width='16rem'>
                                            <PartnerLogo partnerId={partnerId} />
                                        </Box>
                                    </Stack>
                                    {currentWizardStep?.content}
                                </Stack>
                                {currentWizardStep?.actionButton && (
                                    <Stack direction={{ md: 'row' }} justifyContent="space-between" gap={2} mt={4}>
                                        { currentWizardStep?.backButton && (
                                            <Button sx={{textDecoration: "none", "&:hover": { textDecoration: "none" }}} startIcon={<i className='ri-arrow-left-line'/>} onClick={() => changeStep(currentStep - 1)}>
                                                {t('common.previousStep')}
                                            </Button>
                                        )}
                                        <Button sx={{ml: "auto"}} variant='contained' data-cy={currentWizardStep?.actionButton?.dataCy} onClick={currentWizardStep?.actionButton?.onClick}>
                                            {currentWizardStep?.actionButton?.label}
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        </Paper>
                        <Stack direction="row" spacing={1} mt={3}>
                            { selectedOffer?.status === "PENDING" && (
                                <AsyncButton data-cy="rejectProposal" variant='outlined' color='secondary' size='small' onClick={() => setRejectDialogOpen(true)}>
                                    {t('layers-proposalWizard.rejectProposal')}
                                </AsyncButton>
                            )}
                            <AsyncButton variant='outlined' color='secondary' size='small' onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </AsyncButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}
            <FxDialog open={selectedOffer?.status === "ORDERED" && !waitingForResponse}>
                <FxDialogContent sx={{py: 4}}>
                    <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                        <FixrateIcon name="balloonsFill" />
                        <Stack mt={3} maxWidth="45rem">
                            <Typography variant="h2">{t('layers-proposalWizard.depositOrdered')}</Typography>
                            <Typography>
                                {t('layers-proposalWizard.depositOrderedDescription', {bank: bankName, depositor: selectedOffer?.depositorName, partner: selectedPartner?.name})}
                            </Typography>
                        </Stack>
                        <BankCard selectedOffer={selectedOffer} amount={selectedOffer?.amount} />
                        <Stack spacing={1} mt={2}>
                            <Button color="secondary" variant="outlined" onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </Button>
                        </Stack>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
            <FxDialog open={selectedOffer?.status === "ACCEPTED" && currentStep !== 4 && !waitingForResponse}>
                <FxDialogContent sx={{py: 4}}>
                    <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                        <FixrateIcon name="timeMoneyFill" />
                        <Stack mt={3} maxWidth="45rem">
                            <Typography variant="h2">{t('layers-proposalWizard.orderBeingProcessed')}</Typography>
                            <Typography>
                                {t('layers-proposalWizard.orderBeingProcessedDescription', {bank: bankName, partner: selectedPartner?.name})}
                            </Typography>
                        </Stack>
                        <BankCard selectedOffer={selectedOffer} amount={selectedOffer?.amount} />
                        <Stack spacing={1} mt={2}>
                            <Button color="secondary" variant="outlined" onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </Button>
                        </Stack>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
            <FxDialog open={selectedOffer?.status === "EXPIRED"}>
                <FxDialogContent sx={{py: 4}}>
                    <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                        <RejectedIllustration />
                        <Stack mt={3} maxWidth="45rem">
                            <Typography variant="h2">{t('layers-proposalWizard.proposalNoLongerAvailable')}</Typography>
                            <Typography>
                                {t('layers-proposalWizard.proposalNoLongerAvailableDescription', {bank: bankName})}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <Button color="secondary" variant="outlined" onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </Button>
                        </Stack>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
            <FxDialog open={selectedOffer?.status === "DELETED"}>
                <FxDialogContent sx={{py: 4}}>
                    <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                        <RejectedIllustration />
                        <Stack mt={3} maxWidth="45rem">
                            <Typography variant="h2">{t('layers-proposalWizard.proposalDeleted')}</Typography>
                            <Typography>
                                {t('layers-proposalWizard.proposalDeletedDescription', {bank: bankName})}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <Button color="secondary" variant="outlined" onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </Button>
                        </Stack>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
            <FxDialog open={rejectDialogOpen && selectedOffer?.status !== "REJECTED"} onClose={() => setRejectDialogOpen(false)}>
                <FxDialogContent>
                    <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                        <FixrateIcon name="supportNotificationFill" />
                        <Stack>
                            <Typography variant="h2">{t('layers-proposalWizard.rejectionTitle')}</Typography>
                            <Typography>
                                {t('layers-proposalWizard.rejectionDescription')}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <AsyncButton variant="outlined" data-cy="confirmRejection" color="error" onClick={rejectProposal}>
                                {t('layers-proposalWizard.yesDecline')}
                            </AsyncButton>
                            <Button color="secondary" onClick={() => setRejectDialogOpen(false)}>
                                {t('layers-proposalWizard.noCancel')}
                            </Button>
                        </Stack>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
            <FxDialog open={selectedOffer?.status === "REJECTED"}>
                <FxDialogContent data-cy="rejectionDialog" sx={{py: 4}}>
                    <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                        <RejectedIllustration />
                        <Stack mt={3} maxWidth="45rem">
                            <Typography variant="h2">{t('layers-proposalWizard.proposalRejected')}</Typography>
                            <Typography>
                                {t('layers-proposalWizard.proposalRejectedDescription', {bank: bankName, partner: selectedPartner?.name})}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <Button color="secondary" variant="outlined" onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </Button>
                        </Stack>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
            <Stack alignItems={'center'} width='100%' mt='auto' mb={2}>
                <Divider sx={{ width: '100%', maxWidth: '120rem' }} />
                <Box sx={{ '& svg': { maxWidth: '100%' } }} maxWidth={'14rem'}>
                    <FixrateLogo />
                </Box>
            </Stack>
        </Stack>
    )
}
