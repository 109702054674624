import {Currency, FundCustomerDto} from "@fixrate/fixrate-query";
import {useTranslation} from "react-i18next";
import {Fragment, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useCommand} from "#command";
import {useConfirmModal} from "#app/layers/ConfirmModal/ConfirmModal";
import {LoadingButton} from "@mui/lab";
import {CurrencyOutputObject} from "#components/CurrencyOutput/CurrencyOutput";

type FixedMonthlyPlatformFeeHistoryProps = {
    customer: FundCustomerDto
}

export function FixedMonthlyPlatformFeeHistory({customer}: FixedMonthlyPlatformFeeHistoryProps) {
    const {t} = useTranslation()

    const fixedMonthlyFeeHistory: {[p: string]: number} = customer.fixedMonthlyFeeHistory
    const hasHistory = Object.keys(fixedMonthlyFeeHistory).length > 0

    return hasHistory ? (
        <Fragment>
            <Typography variant={'h3'}>{t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFeeHistory.header')}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFeeHistory.startDate')}</TableCell>
                        <TableCell>{t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFeeHistory.fee')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(fixedMonthlyFeeHistory).reverse().map(([startDate, fee]) => (
                        <TableRow key={startDate}>
                            <TableCell>{startDate}</TableCell>
                            <TableCell>
                                {CurrencyOutputObject(fee, {
                                    withCurrency: true,
                                    currency: 'NOK' as Currency,
                                    minimumDecimals: 0,
                                    maximumDecimals: 2,
                                    millionFormat: false
                                })}
                            </TableCell>
                            <TableCell><RemoveFixedMonthlyPlatformFeeEntry customer={customer} startDate={startDate}/></TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </Fragment>
    ) : (
        <p>{t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFeeHistory.noHistory')}</p>
    )
}

type RemoveFixedMonthlyPlatformFeeEntryProps = {
    customer: FundCustomerDto
    startDate: string
}
function RemoveFixedMonthlyPlatformFeeEntry({customer, startDate}: RemoveFixedMonthlyPlatformFeeEntryProps) {
    const {t} = useTranslation()
    const {changeDepositorFundPlatformFixedMonthlyFee} = useCommand()
    const confirmModal = useConfirmModal()
    const [submitting, setSubmitting] = useState(false)

    function displayConfirmationModal() {
        confirmModal({
            title: t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFeeHistory.confirmRemove.title'),
            text: t('pages.fundCustomers.customerDetail.platformFee.fixedMonthlyPlatformFeeHistory.confirmRemove.description', {date: startDate}),
            submitButtonText: t('common.remove'),
            submitAction: submitRemoval,
        })
    }
    async function submitRemoval() {
        setSubmitting(true)
        try {
            const {waitForCommand} = await changeDepositorFundPlatformFixedMonthlyFee(
                customer.depositorId,
                startDate,
                null,
            )
            await waitForCommand()
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <LoadingButton
            size={'small'}
            variant={'outlined'}
            color={'info'}
            disabled={submitting}
            loading={submitting}
            onClick={displayConfirmationModal}
        >
            {t('common.remove')}
        </LoadingButton>
    )
}
