import { Fragment } from 'react';
import {PageHeader, PageLayout, Paper} from '#components'
import {useSelector} from '#state/useSelector'
import FundAdminOverviewTable from "#pages/FundAdminOverview/FundAdminOverviewTable";
import {useTranslation} from "react-i18next";
import FundNavOverviewCalendar from '#pages/FundNavOverview/FundNavOverviewCalendar'

export default function FundAdminOverview() {

    const {t} = useTranslation()
    const funds = useSelector(state => state.funds)

    if (!funds) {
        return null
    }

    const availableFunds = funds
        .filter(f => f.fundShareClasses[0]?.id)
    return (
        <Fragment>
            <PageHeader title={t('pages.fundOverview.admin.title')}/>
            <PageLayout>
                <Paper title={t('pages.fundOverview.admin.title')} sx={{width: "100%"}}>
                    <FundAdminOverviewTable funds={availableFunds}/>
                </Paper>
                <Paper sx={{width: "100%"}}>
                    <FundNavOverviewCalendar/>
                </Paper>
            </PageLayout>

        </Fragment>
    );
}
