import { useSelector } from '#app/state/useSelector'
import { RegulatoryRegion } from '@fixrate/fixrate-query'
import { useMemo } from 'react'

export default function useCurrentCountryCode(): RegulatoryRegion {
    const regulatoryRegion = useSelector(state => state.depositor?.regulatoryRegion || state.bank?.regulatoryRegion)
    const primaryDomain = useSelector(state => state.session?.primaryDomain)

    return useMemo(() => {
        if (regulatoryRegion) {
            return regulatoryRegion
        }

        switch (primaryDomain) {
            case 'fixrate.no':
                return 'NO'
            case 'fixrate.se':
                return 'SE'
            default :
                return 'NO'
        }
    }, [regulatoryRegion, primaryDomain])

}
