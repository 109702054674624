import { Fragment } from 'react';
import {ChangelogObject, UpdatedObject} from '#pages/newcustomerdeclarations/Changelog/types'
import styles from '#pages/newcustomerdeclarations/NewCustomerDeclarations.module.scss'
import {ATTRIBUTES_WITH_LIST_TYPE, containsEmptyValue, formatTranslationKey, formatValue, hasNoChildren, isLastChangeObject} from '#pages/newcustomerdeclarations/Changelog/Changelog'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'

interface Props {
    parentKey: string,
    translationKey: string,
    currentKey: string,
    changelogObject: ChangelogObject,
    isNested?: boolean,
}

export function DiffRow({parentKey, translationKey, currentKey, changelogObject, isNested}: Props) {
    const {t} = useTranslation()
    const {changeType} = changelogObject

    let title
    if (ATTRIBUTES_WITH_LIST_TYPE[parentKey]) {
        title = changelogObject.value[ATTRIBUTES_WITH_LIST_TYPE[parentKey]].value
    } else {
        title = t(`pages-newcustomerdeclarations.field-${translationKey}`)
    }

    const isLastObject = isLastChangeObject(changelogObject)

    if (changeType === 'UNCHANGED' || containsEmptyValue(changelogObject)) {
        return null
    }

    const headerClass = {
        'UPDATED': styles.changed,
        'CHANGED': styles.changed,
        'ADDED': styles.added,
        'DELETED': styles.deleted,
    }[changeType]

    if (isLastObject) {
        return (
            <li key={currentKey} className={headerClass} style={{width: '100%'}}>
                {isNested && <i className={'ri-arrow-right-line'}/>}
                {changeType === 'UPDATED' && (
                    <Fragment>
                        <div className={styles.old}>
                            <h5>{title}</h5>
                            <p>{formatValue((changelogObject as UpdatedObject).oldValue, t)}</p>
                        </div>
                        <div className={styles.arrow}>
                            <i className={'ri-arrow-right-line'}/>
                        </div>
                    </Fragment>
                )}
                {changeType === 'DELETED' && (
                    <div className={styles.old}>
                        <h5>{title}</h5>
                        <p>{formatValue(changelogObject.value, t)}</p>
                    </div>
                )}
                {(changeType === 'ADDED' || changeType === 'UPDATED') && (
                    <div className={styles.new}>
                        <h5>{title}</h5>
                        <p>{formatValue(changelogObject.value, t)}</p>
                    </div>
                )}
            </li>
        );
    }

    if (hasNoChildren(changelogObject)) {
        return null
    }

    return (
        <li key={currentKey} className={classNames(headerClass, styles.withList)}>
            <h5>{isNested && <i className={'ri-arrow-right-line'}/>}<span>{title}</span></h5>
            <ul className={styles.diffRow}>
                {Object.keys(changelogObject.value).map(childKey => (
                    <DiffRow
                        key={`${translationKey}.${childKey}`}
                        translationKey={formatTranslationKey(translationKey, childKey)}
                        parentKey={currentKey}
                        currentKey={childKey}
                        changelogObject={changelogObject.value[childKey]}
                        isNested={true}
                    />
                ))}
            </ul>
        </li>
    )
}
