import {ReactElement, ReactNode, useState} from 'react';
import styles from './List.module.scss'
import classNames from 'classnames'
import DateOutput from '../DateOutput'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import LongTooltip from '../LongTooltip/LongTooltip'
import {PURPLE} from "#app/colors/colors";

type Props = {
    title?: string
    children?: ReactNode
}

export default function List({ title, children }: Props) {
    return (
        <ul className={styles.list}>
            {title && <span className={styles.title}>{title}</span>}
            {children}
        </ul>
    )
}

type ListItemProps = {
    label: string
    value?: ReactElement | string
    disabled?: boolean
    date?: Date | string
    tooltip?: string
    longTooltip?: string,
    infoText?: string
}

const filled = <i className={classNames('ri-information-fill')}></i>
const unfilled = <i className={classNames('ri-information-line')}></i>

export function ListItem({ label, date = null, value, disabled, tooltip, longTooltip, infoText }: ListItemProps) {
    const { t } = useTranslation()
    const [isOpenInfo, setIsOpenInfo] = useState(false)

    return (
        <li className={classNames(styles.listItem, disabled ? styles.disabled : undefined)}>
            <Stack width={"100%"}>
                <Stack width={"100%"} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                    <span className={styles.listLabel}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <span>{label}</span>
                            {tooltip && (
                                <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                    <Tooltip title={tooltip}>
                                        <IconButton color='primary'>
                                            {unfilled}
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                            {longTooltip && (
                                <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                    <LongTooltip title={label} description={longTooltip} />
                                </Stack>
                            )}
                            {infoText && (
                                <Stack justifyContent={'center'} maxHeight={'1.6rem'}>
                                    <IconButton color='primary' onClick={() => setIsOpenInfo(!isOpenInfo)}>
                                        {isOpenInfo ? filled : unfilled}
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                        {date && (
                            <span className={styles.date}>
                                {t('common.updated')} {DateOutput.formatDate(date)}
                            </span>
                        )}
                    </span>
                    <span className={styles.value}>{value}</span>
                </Stack>
                {isOpenInfo && (
                    <Box sx={{p: 1, pl: 1.5, mt: 1, borderRadius: '0.4rem', backgroundColor: PURPLE[50], lineHeight: '1rem' }}>
                        <Typography color={PURPLE[900]} variant={"caption"}>{infoText}</Typography>
                    </Box>
                )}
            </Stack>
        </li>
    )
}
