import command from '../command/command'
import restEndpoint from '../rest/rest-endpoint'
import {BankPresentationDto} from '@fixrate/fixrate-query'

export const registerCustomerDeclaration = (depositorId, bankId, documentId) => dispatch => {
    return command(dispatch).registerCustomerDeclaration(depositorId, bankId, documentId)
}

export const setBankEmail = (bankId, email) => dispatch => {
    return command(dispatch).setBankEmail(bankId, email)
}

export const setBankInfo = (bankId, quarter, info) => dispatch => {
    return command(dispatch).setBankInfo(bankId, quarter, info)
}

export const uploadQuarterlyReport = (bankId, quarter, files) => dispatch => {
    return command(dispatch).uploadBankReport(bankId, quarter, 'QUARTERLY_REPORT', files)
}

export const uploadYearlyReport = (bankId, year, files) => dispatch => {
    return command(dispatch).uploadBankReport(bankId, year + 'Q4', 'YEARLY_REPORT', files)
}

export const removeQuarterlyReport = (bankId, quarter, documentId) => dispatch => {
    return command(dispatch).removeBankReport(bankId, quarter, 'QUARTERLY_REPORT', documentId)
}

export const removeYearlyReport = (bankId, year, documentId) => dispatch => {
    return command(dispatch).removeBankReport(bankId, year + 'Q4', 'YEARLY_REPORT', documentId)
}

export const getBankPresentation = (bankId) => dispatch => {
    return restEndpoint(dispatch).getBankPresentation(bankId) as Promise<Array<BankPresentationDto>>
}

export const getInvoicesByBank = (bankId) => dispatch => {
    return restEndpoint(dispatch).getInvoicesByBank(bankId)
}
