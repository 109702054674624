import { useTranslation } from 'react-i18next'
import styles from './ShowInterestSelector.module.css'

const ShowInterestSelector = ({showInterest, onSetShowInterest, enabled = true}: { showInterest: boolean, onSetShowInterest: (isChecked: boolean) => void, enabled: boolean }) => {
    const {t} = useTranslation()

    return (
        <div className={styles.selectorFrame}>

            <input
                type="checkbox"
                checked={showInterest}
                disabled={!enabled}
                onChange={(e) => onSetShowInterest(e.target.checked)}
            />
            <span className={!enabled ? styles.disabled : undefined}>
                {t('pages-analytics.exposureFilterShowMargin')}
            </span>
        </div>
    )
}

export default ShowInterestSelector