import { useState } from 'react';
import styles from './MyAds.module.scss'
import sharedStyles from '../BankDashboard.module.scss'
import classNames from 'classnames'
import {useSelector} from '#state/useSelector'
import {useTranslation} from '#components/i18n'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import {AdDto, Product} from '@fixrate/fixrate-query'
import {Animated, InterestOutput} from '#components'
import { differenceInDays } from 'date-fns/esm'
import {useTracking} from "react-tracking"
import { Typography } from '@mui/material';

const ADS_PER_PAGE = 3

export const MyAds = () => {
    const {t} = useTranslation('pages-BankDashboard')

    const session = useSelector(state => state.session)
    const { trackEvent } = useTracking({ page: 'BankDashboard', session })

    const products = useSelector(state => state.products)
    const ads = useSelector(state => state.ads.filter(ad => ad.bankId === state.bank?.id))
    const [page, setPage] = useState(0)

    const activeAds = ads.filter(ad => !ad.validity || isAfter(new Date(ad.validity), new Date()))
        .slice().sort((ad1, ad2) => isAfter(new Date(ad2.published), new Date(ad1.published)) ? 1 : -1)

    const lastInactiveAds = ads.filter(ad => ad.validity || isBefore(new Date(ad.validity), new Date()))
        .slice().sort((ad1, ad2) => isAfter(new Date(ad2.validity), new Date(ad1.validity)) ? 1 : -1)
        .slice(0, 3)

    const Pagination = () => (
        <ul className={styles.pagination}>
            {[...new Array(Math.ceil(activeAds.length / ADS_PER_PAGE))].map((_, i) => (
                <li key={i} className={page === i ? styles.activePagination : undefined} onClick={() => {
                    setPage(i)
                    trackEvent({event: 'paginateActiveAds'})
                }}>{i + 1}</li>
            ))}
        </ul>
    )

    const selectedActiveAds = activeAds.slice(page * ADS_PER_PAGE, (page * ADS_PER_PAGE) + ADS_PER_PAGE)

    return (
        <div className={classNames(styles.myAds)}>
            <div className={styles.adsGroup}>
                <Typography variant="h2" mb={2}>
                    <i className="ri-play-circle-line"/>
                    <span>{t('activeAds')}</span>
                </Typography>
                {activeAds.length > 0 && (
                    <ul className={styles.adList}>
                        {selectedActiveAds.map(ad => <AdListItem key={ad.id} ad={ad} product={products[ad.productId]}/>)}
                    </ul>
                )}
                {activeAds.length === 0 && <p>{t('noActiveAds')}</p>}
                {activeAds.length > ADS_PER_PAGE && <Pagination/>}
            </div>
            {lastInactiveAds.length > 0 && (
                <div className={styles.adsGroup}>
                    <Typography variant="h2" mb={2}>
                        <i className="ri-archive-line"/>
                        <span>{t('inactiveAds')}</span>
                    </Typography>
                    <ul className={styles.adList}>
                        {lastInactiveAds.map(ad => <AdListItem key={ad.id} inactive ad={ad} product={products[ad.productId]}/>)}
                    </ul>
                </div>
            )}
        </div>
    )
}

interface AdListItemProps {
    ad: AdDto,
    product: Product,
    inactive?: boolean,
}

const AdListItem = ({ad, product, inactive}: AdListItemProps) => {
    const {t} = useTranslation('pages-BankDashboard')
    const volumeOrdered = ad.volume - ad.remaining
    const daysActive = differenceInDays(new Date(ad.validity), new Date(ad.published))

    return (
        <Animated tag="li" className={inactive ? styles.inactive : undefined}>
            <div>
                <h3>{t(`common.volume${ad.volume === 1 ? '' : '_other'}`, {count: ad.volume})}</h3>
                <div className={styles.fillBar}>
                    <p>{t(`volumeOrdered${volumeOrdered === 1 ? '' : '_other'}`, {count: volumeOrdered})}</p>
                    <div className={styles.bar}>
                        <div className={styles.value} style={{width: (volumeOrdered / ad.volume) * 100 + '%'}}/>
                    </div>
                </div>
                { inactive &&
                    <p className={styles.timePeriod}>
                        {t(`activeDays${daysActive === 1 ? '' : '_other'}`, {count: daysActive})}
                    </p>
                }
            </div>
            <div className={classNames(sharedStyles.row, styles.adDetails)}>
                <p className={styles.nibor}>
                    <span>
                        {product?.type !== 'FIXED' && t('common.interestRateBenchmark.' + ad?.interestRateBenchmark) + ' + '}
                    </span>
                    <span className={sharedStyles.numberHighlight}>{InterestOutput.format(product?.type === 'FIXED' ? ad.interest : ad.nominalInterestRate)}</span>
                </p>
                <p className={styles.days}>{t(`common.productLongName${ad.productId}`)}</p>
            </div>
        </Animated>
    )
}
