import {FundDto} from '@fixrate/fixrate-query'
import {useTranslation} from 'react-i18next'
import {Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery} from '@mui/material'
import {InterestBadge} from '#app/components/InterestBadge/InterestBadge'
import List, {ListItem} from '#app/components/List/List'
import useFundShareClassSelected from '#services/useFundShareClassSelected'
import {useFundMarketData} from '#blocks/FundReturnsChart/useFundMarketData'

type Props = { fund: FundDto }

export default function FundReturns({fund}: Props) {
    const {t} = useTranslation()
    const [fundClassId] = useFundShareClassSelected(fund?.id, fund?.fundShareClasses[0]?.id)
    const isList = useMediaQuery('(max-width: 1280px)')

    const selectedShareClass = fund?.fundShareClasses.find(f => f?.id === fundClassId)
    const {data: fundMarketData, isLoading, isSuccess} = useFundMarketData(selectedShareClass?.isin)


    return selectedShareClass ? (
        <>
            <h4>{t('pages-fund-details.historicReturns')}</h4>
            {isList ? (
                <Stack spacing={1} mt={2} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                    <Box width={{xs: '100%', sm: '30rem'}} maxWidth={'100%'}>
                        <Typography fontWeight={'600'}>{t('pages-fund-details.annualizedReturn')}</Typography>
                        {(!isLoading && isSuccess) && (
                            <List>

                                <ListItem label={t('pages-fund-details.3yAnnualized')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_3Y}/>}/>
                                <ListItem label={t('pages-fund-details.5yAnnualized')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_5Y}/>}/>
                                <ListItem label={t('pages-fund-details.10yAnnualized')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_10Y}/>}/>
                            </List>)}

                    </Box>
                    <Box width={{xs: '100%', sm: '30rem'}} maxWidth={'100%'}>
                        <Typography fontWeight={'600'}>{t('pages-fund-details.accumulatedReturn')}</Typography>
                        {(!isLoading && isSuccess) && (<List>
                            <ListItem label={t('components-DateRangePicker.YTD')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_YTD}/>}/>
                            <ListItem label={t('components-DateRangePicker.1M')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_1M}/>}/>
                            <ListItem label={t('components-DateRangePicker.3M')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_3M}/>}/>
                            <ListItem label={t('components-DateRangePicker.6M')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_6M}/>}/>
                            <ListItem label={t('components-DateRangePicker.1Y')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_1Y}/>}/>
                            <ListItem label={t('components-DateRangePicker.3Y')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_3Y}/>}/>
                            <ListItem label={t('components-DateRangePicker.5Y')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_5Y}/>}/>
                            <ListItem label={t('components-DateRangePicker.10Y')} value={<InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_10Y}/>}/>
                        </List>)}
                    </Box>
                </Stack>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{t('components-DateRangePicker.YTD')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.1M')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.3M')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.6M')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.1Y')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.3Y')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.5Y')}</TableCell>
                            <TableCell>{t('components-DateRangePicker.10Y')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {(!isLoading && isSuccess) && (<TableBody>
                        <TableRow>
                            <TableCell>
                                {t('pages-fund-details.annualizedReturn')}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_1Y}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_3Y}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_5Y}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.annualReturnRates.PERIOD_10Y}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t('pages-fund-details.accumulatedReturn')}
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_YTD}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_1M}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_3M}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_6M}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_1Y}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_3Y}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_5Y}/>
                            </TableCell>
                            <TableCell>
                                <InterestBadge sx={{fontSize: '1.2rem'}} interest={fundMarketData.returnRates.PERIOD_10Y}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>)}
                </Table>
            )}
        </>
    ) : null
}
