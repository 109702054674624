import classNames from 'classnames'
import styles from './MenuItem.module.scss'
import LinkButton from '#components/LinkButton/LinkButton'
import Button from '#components/Button'

interface MenuItemProps {
    url?: string
    id: string
    emphasized?: boolean
    text: string
    onClick?: () => void
    icon: string
}

const MenuItem = function({
    url,
    id,
    emphasized,
    text,
    onClick,
    icon
}: MenuItemProps) {

    if (url) {
        return (
            <li className={styles.menuItem}>
                <LinkButton
                    variant={'secondary'}
                    className={classNames(styles.link, {[styles.linkRed]: emphasized})}
                    id={id}
                    to={url}
                >
                    <span className={styles.corneredAngle}></span>
                    <span className={styles.inner}>
                        {icon && <i className={icon}/> }
                        <span>{text}</span>
                    </span>
                </LinkButton>
            </li>
        )
    }

    if (onClick) {
        return (
            <li className={styles.menuItem}>
                <Button
                    variant={'secondary'}
                    className={classNames(styles.link, styles.button)}
                    id={id}
                    onClick={onClick}
                >
                    <span className={styles.corneredAngle}></span>
                    <span className={styles.inner}>
                        {icon && <i className={icon}/> }
                        <span>{text}</span>
                    </span>
                </Button>
            </li>
        )
    }

    return null
}

export default MenuItem