import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './SlidingPane.raw.css'

const CLOSE_TIMEOUT = 500;

export default function SlidingPane({
                                        isOpen,
                                        onRequestClose,
                                        onAfterOpen,
                                        children,
                                        className,
                                        overlayClassName,
                                        from = 'right',
                                        width
                                    }) {
    const directionClass = `slide-pane_from_${from}`;

    const style = width ? {width: width} : {}

    return <Modal
        className={`slide-pane ${directionClass} ${className || ''}`}
        style={style}
        overlayClassName={`slide-pane__overlay ${overlayClassName || ''}`}
        closeTimeoutMS={CLOSE_TIMEOUT}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        contentLabel={`Modal ""`}>
        <div className='slide-pane__content'>
            {children}
        </div>
    </Modal>;
}

SlidingPane.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
    onAfterOpen: PropTypes.func,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    from: PropTypes.oneOf(['left', 'right', 'bottom']),
    width: PropTypes.string,
    closeIcon: PropTypes.any
};

