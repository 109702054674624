import HistoryPanel from '#blocks/HistoryPanel/HistoryPanel';
import TagSelector from '#blocks/TagSelector/TagSelector';
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner';
import PageHeader from '#components/PageHeader/PageHeader';
import PageLayout from '#components/PageLayout/PageLayout';
import DepositDetailsInterests from '#pages/portfolio-depositor/DepositDetails/DepositDetailsInterests';
import DepositDetailsIRC from '#pages/portfolio-depositor/DepositDetails/DepositIRC/DepositDetailsIRC';
import NextFixedInterestPeriodTerms from '#pages/portfolio-depositor/DepositDetails/NextFixedInterestPeriodTerms';
import * as selectors from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AcceptExtensionOffer from './AcceptExtensionOffer';
import DepositChangeSettlementAccount from './DepositChangeSettlementAccount';
import styles from './DepositDetails.module.scss';
import DepositDetailsAnnualStatements from './DepositDetailsAnnualStatements';
import DepositDetailsBankDocuments from './DepositDetailsBankDocuments';
import DepositDetailsDocuments from './DepositDetailsDocuments';
import DepositDetailsInfo from './DepositDetailsInfo';
import DepositTermination, { TerminationMode } from './DepositTermination/DepositTermination';

type Params = {
    depositId?: string
}

export default function DepositDetails() {
    const {t} = useTranslation()
    const {depositId} = useParams<Params>()

    const deposit = useSelector(state => state.deposits.find(deposit => deposit.id === depositId))
    const documents = useSelector(state => deposit && deposit.documents.map(documentId => state.documents[documentId]))
    const bankDocuments = useSelector(state => selectors.lookupBankDocumentsForDeposit(state)(depositId))
    const interestRateChange = useSelector(state => state.interestRateChange)

    const [terminationMode, setTerminationMode] = useState<TerminationMode>('HIDE')
    const [showChangeSettlementAccount, setChangeSettlementAccount] = useState(false)

    function toggleChangeSettlementAccount() {
        setChangeSettlementAccount(!showChangeSettlementAccount)
    }

    if (!deposit) {
        return (
            <div className="layout__center-content">
                <LoadingSpinner text={t('common.loadingData')}/>
            </div>
        )
    }

    const ircProcessIdList = interestRateChange.filter(irc => irc.depositId === deposit.id).map(irc => irc.id)

    const processIdList = [deposit.orderId, deposit.id, ...ircProcessIdList]

    return (
        <>
            <PageHeader useBackButton={true}>
                <Box pr={1}>
                    <TagSelector deposit={deposit}/>
                </Box>
            </PageHeader>

            <PageLayout>
                <div className={styles.flexRowHistoryRight}>
                    <div>
                        <NextFixedInterestPeriodTerms deposit={deposit}/>
                        <DepositDetailsIRC
                            deposit={deposit}
                            setTerminationMode={setTerminationMode}
                            terminationMode={terminationMode}
                        />
                        <AcceptExtensionOffer deposit={deposit}/>
                        <DepositChangeSettlementAccount
                            deposit={deposit}
                            toggleChangeSettlementAccount={toggleChangeSettlementAccount}
                            showChangeSettlementAccount={showChangeSettlementAccount}
                        />
                        <DepositTermination
                            deposit={deposit}
                            setTerminationMode={setTerminationMode}
                            terminationMode={terminationMode}
                        />
                        <DepositDetailsInfo
                            deposit={deposit}
                            setTerminationMode={setTerminationMode}
                            terminationMode={terminationMode}
                            toggleChangeSettlementAccount={toggleChangeSettlementAccount}
                            showChangeSettlementAccount={showChangeSettlementAccount}
                        />
                        <DepositDetailsDocuments documents={documents}/>
                        <DepositDetailsBankDocuments bankDocuments={bankDocuments}/>
                        <DepositDetailsAnnualStatements />
                        <DepositDetailsInterests deposit={deposit}/>
                    </div>
                    <div>
                        <HistoryPanel processId={processIdList}/>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}
