import {Box, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from '@mui/material'
import {FundCustomerDto} from '@fixrate/fixrate-query'
import DocumentLink from '#components/DocumentLink'
import {useSelector} from '#state/useSelector'
import {formatAccount, formatIban} from '#services/formatnumber'
import {SILVER_GRAY} from '#app/colors/colors'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'

type Props = {
    customer: FundCustomerDto
}

export default function Onboarding({customer}: Props) {
    const organisationCountry = useCurrentCountryCode()

    const customerDeclarations = useSelector(state => {
        return customer.customerDocuments
            .map(documentId => state.documents[documentId])
            .filter(d => d?.documentType === 'CUSTOMER_DECLARATION')
    })

    const identificationDocuments = useSelector(state => {
        return customer.customerDocuments
            .map(documentId => state.documents[documentId])
            .filter(d => d?.documentType === 'IDENTIFICATION_DOCUMENT')
    })

    const authorizationDocuments = useSelector(state => {
        return customer.customerDocuments
            .map(documentId => state.documents[documentId])
            .filter(d => d?.documentType === 'AUTHORIZATION_DOCUMENT')
    })

    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Typography variant={'h4'}>Kundeerklæring</Typography>
                {customerDeclarations.map(doc => {
                    return (
                        <Box key={doc.id} data-fx={doc.id}>
                            {doc && <DocumentLink link={doc.link} name={`${doc.name}`} signatureTime={doc.lastSignatureTime}/>}
                            {!doc && (
                                <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{pl: 0.5}}>
                                    <CircularProgress size={24}/>
                                    <Typography variant={'body1'} sx={{color: SILVER_GRAY[400], fontWeight: 600}}>ID – {doc.name}</Typography>
                                </Stack>
                            )}
                        </Box>
                    )
                })}
                {customerDeclarations.length === 0 && (
                    <Typography variant={'body1'}>Ingen dokumenter</Typography>
                )}
            </Stack>
            <Stack spacing={1}>
                <Typography variant={'h4'}>Identifikasjonsdokumenter</Typography>
                {identificationDocuments.map(idDoc => {
                    return (
                        <Box key={idDoc.id} data-fx={idDoc.id}>
                            {idDoc && <DocumentLink link={idDoc.link} name={`${idDoc.name}`} signatureTime={idDoc.lastSignatureTime}/>}
                            {!idDoc && (
                                <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{pl: 0.5}}>
                                    <CircularProgress size={24}/>
                                    <Typography variant={'body1'} sx={{color: SILVER_GRAY[400], fontWeight: 600}}>ID – {idDoc.name}</Typography>
                                </Stack>
                            )}
                        </Box>
                    )
                })}
                {identificationDocuments.length === 0 && (
                    <Typography variant={'body1'}>Ingen dokumenter</Typography>
                )}
            </Stack>
            <Stack spacing={1}>
                <Typography variant={'h4'}>Fullmakter</Typography>
                {authorizationDocuments.map(authDoc => {
                    return (
                        <Box key={authDoc.id} data-fx={authDoc.id}>
                            {authDoc && <DocumentLink link={authDoc.link} name={`${authDoc.name}`}  signatureTime={authDoc.lastSignatureTime}/>}
                            {!authDoc && (
                                <Stack direction={'row'} spacing={1} sx={{pl: 0.5}}>
                                    <CircularProgress size={24}/>
                                    <Typography variant={'body1'} sx={{color: SILVER_GRAY[400], fontWeight: 600}}>{authDoc.name}</Typography>
                                </Stack>
                            )}
                        </Box>
                    )
                })}
                {authorizationDocuments.length === 0 && (
                    <Typography variant={'body1'}>Ingen dokumenter</Typography>
                )}
            </Stack>
            <Stack spacing={1}>
                <Typography variant={'h4'}>Oppgjørskontoer</Typography>
                <List>
                    {customer.settlementAccounts.map(account => {
                        let accountNumber, accountBankName
                        if (account.accountNumber) {
                            accountNumber = formatAccount(account.accountNumber, organisationCountry)
                            accountBankName = account.bankName
                        } else {
                            accountNumber = formatIban(account.ibanNumber)
                            accountBankName = 'BIC (SWIFT): ' + account.bic
                        }

                        return (
                            <ListItem key={account.id}>
                                <ListItemIcon>
                                    <i className="ri-bank-line"/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={account.name}
                                    secondary={
                                        <Stack component={'span'}>
                                            <span>{accountNumber}</span>
                                            <span>{accountBankName}</span>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        )
                    })}
                </List>
                {customer.settlementAccounts.length === 0 && (
                    <Typography variant={'body1'}>Ingen oppgjørskontoer</Typography>
                )}
            </Stack>
        </Stack>
    );
}
