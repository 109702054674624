import Modal from '#components/Modal'
import {useCommand} from '#command'
import {DatePicker} from '#components'
import classNames from 'classnames'
import {useFieldState} from '@fixrate/fieldstate'
import styles from './InviteDataModal.module.scss'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import {useTranslation} from 'react-i18next'
import { Box, InputLabel, Stack, TextField, Typography } from '@mui/material'
import DateInput from '#app/components/DateInput'

interface Props {
    depositorId: string,
    personId: string,
    onClose: () => void,
}

export default function InviteDataModal({depositorId, personId, onClose}: Props) {

    const {t} = useTranslation()

    const {updatePerson} = useCommand()
    const depositor = useCurrentDepositor()
    const person = depositor?.people.find(p => p.personId === personId)

    const birthDate = useFieldState(person.birthDate ? new Date(person.birthDate) : null, ({value}) => {
        return !value && t('common.mandatoryField')
    })
    const email = useFieldState(person.email, ({value}) => {
        return !value && t('common.mandatoryField')
    })

    async function submit() {
        if (birthDate.validate() && email.validate()) {
            const {waitForCommand} = await updatePerson(
                depositorId,
                personId,
                person.firstName,
                person.lastName,
                birthDate.value,
                email.value,
                person.address,
                person.taxResidencies,
                person.americanCitizenship,
                person.pep,
                person.pepPosition,
                person.pepInvestingPrivateFunds,
                person.controlShare,
                person.keyPersonRole
        )
            const success = await waitForCommand()
            if (success) {
                onClose()
            }
        }
    }

    return (
        <Modal 
            onCancel={onClose}
            onSubmit={submit}
            header={t('pages-organizations.birthDateFor', {firstName: person.firstName, lastName: person.lastName})}>
            <Stack spacing={2} maxWidth="40rem">
                <Typography>{t('pages-organizations.registerBirthdateInstructions')}</Typography>
                <Box>
                    <InputLabel htmlFor="emailField">{t('common.email')}</InputLabel>
                    <TextField
                        id="emailField"
                        sx={{width: "30rem", maxWidth: "100%"}}
                        onBlur={email.onBlur}
                        onChange={e => email.setValue(e.target.value)}
                        type="email"
                        value={email.value}
                    />
                    <p className="field-error-message">
                        {email.errorMessage}
                    </p>
                </Box>
                <Box>
                    <InputLabel htmlFor="birthDateDatePicker">{t('pages-organizations.personBirthDate')}</InputLabel>
                    <DateInput
                        className={classNames(styles.datepicker)}
                        id="birthDateDatePicker"
                        onChange={date => birthDate.setValue(date)}
                        value={birthDate.value}
                    />
                    <p className="field-error-message">
                        {birthDate.errorMessage}
                    </p>
                </Box>
            </Stack>
        </Modal>
    )
}
