import config from '#app/config'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import {useCommand} from '#command'
import {PageHeader, PageLayout} from '#components'
import {useSelector} from '#state/useSelector'
import {DepositorDto} from '@fixrate/fixrate-query'
import { Grid } from '@mui/material'
import format from 'date-fns/format'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import ActiveAuthorization from './ActiveAuthorization'
import './Authorization.scss'
import AuthorizationDocument from './AuthorizationDocument'
import AuthorizationDocumentPreview from './AuthorizationDocumentPreview'
import DeleteAuthorization from './DeleteAuthorization'
import RequestAuthorization, {PrincipalCandidate} from './RequestAuthorization'
import SignAuthorization from './SignAuthorization'

const API_BASE_URL = config().apiUrl

function getPrincipalCandidates(depositor: DepositorDto): PrincipalCandidate[] {
    return (
        depositor.people
            .filter(person => person.securityRoles.includes('DEPOSITOR_SIGN_AUTHORIZATION'))
            .map(person => ({
                personId: person.personId,
                birthDate: person.birthDate,
                email: person.email,
                name: person.firstName + ' ' + person.lastName,
            }))
    )
}

type Params = {
    userId?: string
    inviteId?: string
    organisationId?: string
}

export default function Authorization() {
    const {t} = useTranslation()
    const params = useParams<Params>()
    const navigate = useNavigate()

    const {
        generateAuthorizationDocument: generateAuthorizationDocumentCommand,
        deleteAuthorizationDocument: deleteAuthorizationDocumentCommand,
        startElectronicSignature: startElectronicSignatureCommand,
    } = useCommand()

    const documents = useSelector(({documents}) => documents)
    const depositor = useCurrentDepositor()
    const principalCandidates: PrincipalCandidate[] = depositor ? getPrincipalCandidates(depositor) : []

    // Finds the user or invite that represents the attorney
    const attorney = useMemo(() => {
        return params.userId
            ? {...depositor?.users?.find(user => user.id === params.userId), ...{isUser: true}}
            : {...depositor?.userInvites?.find(invite => invite.id === params.inviteId), ...{isUser: false}}
    }, [depositor?.userInvites, depositor?.users, params.inviteId, params.userId])

    const authorizationDocument = attorney?.authorizationDocumentId ? documents[attorney.authorizationDocumentId] : null

    const [isWorking, setWorking] = useState(false)
    const [principalPersonIds, setPrincipalPersonIds] = useState([])
    const [notifiedUserId, setNotifiedUserId] = useState(null)
    const [language, setLanguage] = useState(depositor?.language)

    useEffect(() => {
        if (!!depositor && (!attorney || !attorney.id)) {
            navigate(`/organizations/${depositor.id}/users/power-of-attorney`)
        }
    }, [attorney, depositor, navigate])

    async function generateAuthorizationDocument() {
        try {
            setWorking(true)
            const {waitForCommand} = await generateAuthorizationDocumentCommand(
                params.userId,
                params.inviteId,
                principalPersonIds,
                params.organisationId,
                notifiedUserId,
                language,
            )

            await waitForCommand()
            setWorking(false)
        } catch (err) {
            console.error(err)
            setWorking(false)
        }
    }

    async function deleteAuthorizationDocument() {
        try {
            setWorking(true)
            const {waitForCommand} = await deleteAuthorizationDocumentCommand(params.userId, params.inviteId, params.organisationId)
            await waitForCommand()
            setWorking(false)
        } catch (err) {
            console.error(err)
            setWorking(false)
        }
    }

    function generatePreviewLink() {
        const depositorNameEncoded = encodeURIComponent(depositor?.name)

        const attorneyEncoded = encodeURIComponent(JSON.stringify({
            name: attorney.firstName + ' ' + attorney.lastName,
            birthDate: attorney.birthDate,
        }))

        const principalParameters = principalPersonIds.map(principalPersonId => {
            const principal = principalCandidates.find(person => person.personId === principalPersonId)
            return `&from-users=${encodeURIComponent(JSON.stringify({
                name: principal.name,
                birthDate: format(new Date(principal.birthDate), 'yyyy-MM-dd'),
            }))}`
        })

        return ({
            inline: `${API_BASE_URL}/document/preview/authorization-document?inline=true&depositor-name=${depositorNameEncoded}&for-user=${attorneyEncoded}${principalParameters}&language=${language}`,
            attachment: `${API_BASE_URL}/document/preview/authorization-document?inline=false&depositor-name=${depositorNameEncoded}&for-user=${attorneyEncoded}${principalParameters}&language=${language}`,
        })
    }

    async function startElectronicSignature(processId, documentType, identity) {
        await startElectronicSignatureCommand(processId, documentType, identity)
    }

    if (!depositor) {
        return null
    }

    const previewLink = generatePreviewLink()

    return (
        <>
            <PageHeader
                title={t('pages-organizations.authorizationForUserHeading', {
                    userName: `${attorney.firstName} ${attorney.lastName}`,
                    organizationName: depositor.name,
                })}
                backToLink={`/organizations/${depositor.id}/users/power-of-attorney`}
            />
            <PageLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        {!authorizationDocument &&
                            <RequestAuthorization
                                depositor={depositor}
                                attorney={attorney}
                                principalCandidates={principalCandidates}
                                principalPersonIds={principalPersonIds}
                                generateAuthorizationDocument={generateAuthorizationDocument}
                                isGenerating={isWorking}
                                setPrincipalPersonIds={setPrincipalPersonIds}
                                setNotifiedUserId={setNotifiedUserId}
                                setLanguage={setLanguage}
                                language={language}
                            />
                        }
                        {!authorizationDocument?.signedByAll &&
                            <SignAuthorization
                                authorizationDocument={authorizationDocument}
                                depositorId={params.organisationId}
                                forUser={attorney}
                                startElectronicSignature={startElectronicSignature}
                            />
                        }
                        {authorizationDocument?.signedByAll &&
                            <ActiveAuthorization
                                authorizationDocument={authorizationDocument}
                                forUser={attorney}
                            />
                        }
                        {authorizationDocument &&
                            <DeleteAuthorization
                                deleteAuthorizationDocument={deleteAuthorizationDocument}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {authorizationDocument &&
                            <AuthorizationDocument
                                document={authorizationDocument}
                            />
                        }
                        {!authorizationDocument &&
                            <AuthorizationDocumentPreview
                                previewLink={previewLink}
                            />
                        }
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    )
}
