import { InterestOutput } from '#components'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import isAfter from 'date-fns/isAfter'
import { useTranslation } from 'react-i18next'
import sharedStyles from '../BankDashboard.module.scss'
import { BenchmarkGraph } from './BenchmarkGraph'
import styles from './Header.module.scss'

const PRODUCT_31D_ID = '5'

export const Header = () => {
    const {t} = useTranslation()
    const defaultInterestRateBenchmark = useSelector(state => state.interestRateBenchmarks.defaultInterestRateBenchmark)

    const activeDeposits = useSelector(state => state.deposits.filter(deposit => !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date())))
    const active31dDeposits = activeDeposits.filter(deposit => deposit.product.id === PRODUCT_31D_ID)

    // Using calculatedTotalBalance here. This is the balance with accrued interests. This includes all interest the customer has earned on the deposit
    // since the deposit was created until today.
    const activeDepositsBalance = activeDeposits.reduce((acc, deposit) => acc + deposit.calculatedBalance, 0)

    // activeDepositsTotalBalance rounded down to millions
    const activeDepositsBalanceMillions = Math.floor(activeDepositsBalance / 1000000)

    // Using calculatedBalance here. This is the balance without accrued interests. This is the balance that is used to calculate the interest rate.
    // For most deposits, this is the balance pr. Jan 1st (or when the deposit was created).
    const active31dDepositsCalculatedBalance = active31dDeposits.reduce((acc, deposit) => acc + deposit.calculatedBalance, 0)

    const average31dInterestRatePerVolume = active31dDeposits.reduce((acc, deposit) => acc + deposit.calculatedBalance * deposit.effectiveInterestRate, 0) / active31dDepositsCalculatedBalance

    return (
        <div className={styles.headerSection}>
            <div className={classNames(sharedStyles.wrapper, styles.innerWrapper)}>
                <div>
                    <div className={styles.item}>
                        <p className={styles.description}>{t('pages-BankDashboard.banksDeposits')}</p>
                        <p className={styles.value}>{t(`common.volume${activeDepositsBalanceMillions === 1 ? '' : '_other'}`, {count: activeDepositsBalanceMillions})}</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.description}>{t('pages-BankDashboard.averageInterestRate')}</p>
                        <p className={styles.value}>{isNaN(average31dInterestRatePerVolume) ? '-' : InterestOutput.format(average31dInterestRatePerVolume)}</p>
                    </div>
                </div>
                <div className={styles.benchmarkWrapper}>
                    { defaultInterestRateBenchmark && (
                        <p>{t('pages-BankDashboard.benchmarkHeader.' + defaultInterestRateBenchmark)}</p>
                    )}
                    <div className={styles.benchmarkInnerWrapper}>
                        <BenchmarkGraph/>
                    </div>
                </div>
            </div>
        </div>
    )
}
