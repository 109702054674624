import { useMediaQuery, useTheme } from "@mui/material"
import { useEffect } from "react"

const useBottomNavigation = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const hubspotChatBubble = document.getElementById("hubspot-conversations-iframe") || document.getElementById("hubspot-messages-iframe-container")

    useEffect(() => {
        if (isMobile && hubspotChatBubble) {
            hubspotChatBubble.style.bottom = "6.5rem"
        }
        return () =>  {
            if (hubspotChatBubble) {
                hubspotChatBubble.style.bottom = "1.5rem"
            }
        }
    },[isMobile, hubspotChatBubble])
}

export { useBottomNavigation }