import { useState } from 'react';
import config from '#app/config'
import Paper from '#app/components/Paper/FxPaper'
import styles from './BankTerms.module.css'
import Button from '#components/Button'
import {useCommand} from '#command'
import {useTranslation} from 'react-i18next'
import {DateOutput} from '#components'
import {BankContractDto, BankDto, DocumentType} from '@fixrate/fixrate-query'
import {useSelector} from '#state/useSelector'
import {useAuthorization} from '#services/authorization'
import {Stack} from '@mui/material'

const API_BASE_URL = config().apiUrl

type Props = {
    bank: BankDto,
    contract: BankContractDto
}

const DOCUMENT_TYPES_THAT_MUST_BE_ACCEPTED: DocumentType[] = [
    'BANK_CONTRACT',
    'BANK_CONTRACT_ADDENDUM_PRICE_LIST',
    'BANK_CONTRACT_ADDENDUM_DEPOSITOR_OFFERS',
]

export default function BankTerms({bank, contract}: Props) {
    const {t} = useTranslation()
    const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false)
    const auth = useAuthorization()
    const {acceptBankTerms} = useCommand()

    const document = useSelector(state => state.documents[contract.documentId])

    const isAddendum = contract?.documentType?.startsWith('BANK_CONTRACT_ADDENDUM')
    const hasSubcontractorsAddendum = contract?.documentType === 'BANK_DPA' && bank.bankGroup !== "Sparebank 1"

    const dataProcessingAgreementSubcontractorsUrl = `${API_BASE_URL}/document/template/bank/BANK_DPA_SUBCONTRACTORS_ADDENDUM`
    const contractUrl = `${API_BASE_URL}/api/document/id/${contract.documentId}/data`

    async function accept() {
        if (acceptedTermsOfUse) {
            const {waitForCommand} = await acceptBankTerms(bank.id, contract.documentId)
            await waitForCommand()
        }
    }

    let body
    if (!DOCUMENT_TYPES_THAT_MUST_BE_ACCEPTED.includes(contract.documentType)) {
        body = (
            <Paper className={styles.body}>
                <Stack spacing={2}>
                    <div className={styles.contractLink}>
                        <a href={contractUrl} target="_blank" rel="noopener noreferrer">{document?.name ?? ''}</a>
                    </div>
                    {hasSubcontractorsAddendum && (
                        <div className={styles.contractLink}>
                            <a href={dataProcessingAgreementSubcontractorsUrl} target="_blank" rel="noopener noreferrer">{t('pages-mybank.termsDataProcessingAgreementSubcontractorsDocumentName')}</a>
                        </div>
                    )}
                </Stack>
            </Paper>
        )
    } else if (contract.acceptedAt) {
        body = (
            <Paper className={styles.body}>
                <div className={styles.contractLink}>
                    <a href={contractUrl} target="_blank" rel="noopener noreferrer">{document?.name ?? ''}</a>
                </div>
                <div className={styles.contractAcceptedBy}>{t('pages-mybank.termsAcceptedInfo', {
                    name: bank.knownUserFullNames[contract.acceptedBy],
                    date: DateOutput.formatDateTime(contract.acceptedAt),
                })}</div>
            </Paper>
        )
    } else if (auth.bank.hasAdminRole || (isAddendum && auth.bank.hasAdManagerRole)) {
        body = (
            <Paper className={styles.body}>
                <a href={contractUrl} target="_blank" rel="noopener noreferrer">{document?.name ?? ''}</a>
                <label className={styles.checkbox}>
                    <input
                        type="checkbox"
                        value="yes"
                        onChange={e => setAcceptedTermsOfUse(e.target.checked)}
                        checked={acceptedTermsOfUse}
                    />
                    {t('pages-mybank.termsConfirmation')}
                </label>
                <Button onClick={accept}>
                    {t('common.submit')}
                </Button>
            </Paper>
        )
    } else {
        body = (
            <Paper className={styles.body}>
                <a href={contractUrl} target="_blank" rel="noopener noreferrer">{document?.name ?? ''}</a>
                <label className={styles.checkbox}>
                    <input type="checkbox" value="yes" disabled={true} checked={false}/>
                    {t('pages-mybank.termsConfirmation')}
                </label>
                <div className={styles.contractAcceptedBy}>
                    {isAddendum
                        ? t('pages-mybank.mustBeAccepted')
                        : t('pages-mybank.mustBeAcceptedByAnAdministrator')
                    }
                </div>
            </Paper>
        )
    }

    return body
}
