import useShoppingCart from '#services/useShoppingCart'
import usePortfolio from '#services/usePortfolio'
import {useCurrencyOutput} from '#components/CurrencyOutput/useCurrencyOutput'
import {useSelector} from '#state/useSelector'
import {NumberInput} from '#components'
import styles from './ShoppingCartPane.module.scss'
import {FundDto} from '@fixrate/fixrate-query'
import {InputAdornment} from '@mui/material'
import {useValidState} from './useValidState'

type Props = {
    fund: FundDto
    touched: boolean
    setTouched: () => void
}

export function OrderAmountInput({fund, touched, setTouched}: Props) {
    const shoppingCart = useShoppingCart<FundDto>('FUNDS')
    const portfolio = usePortfolio()
    const Currency = useCurrencyOutput()
    const depositor = useSelector(state => state.depositor)
    const valid = useValidState()[fund.id]

    if (!portfolio || !depositor || !fund) {
        return null
    }
    const shoppingCartItem = shoppingCart.getItemById(fund.id)
    const shareClassId = shoppingCart.getItemSubId(fund.id)
    if (!shoppingCartItem || !shareClassId) {
        return null
    }
    const shareClass = fund.fundShareClasses.find(fc => fc.id === shareClassId)
    if (!shareClass) {
        return null
    }
    const orderAmount = shoppingCartItem.amount

    const isErrorState = touched && !valid

    function handleOnChange(value: number) {
        if (value && value > 0) {
            shoppingCart.updateItemAmount(fund.id, value * 1_000_000)
        } else {
            shoppingCart.updateItemAmount(fund.id, 0)
        }
    }

    return (
        <NumberInput
            sx={{
                width: '14rem'
            }}
            InputProps={
                {
                    endAdornment: <InputAdornment position="end">{'M' + portfolio.currency}</InputAdornment>
                }
            }
            data-cy="orderAmountInput"
            value={orderAmount ? orderAmount / 1_000_000 : null}
            onChange={handleOnChange}
            onBlur={setTouched}
            className={isErrorState ? styles.error : null}
            placeholder={'0'}
            formatFn={value => Currency(value, {withCurrency: false, decimalRange: {minimum: 0, maximum: 6}})}
        />
    )
}
