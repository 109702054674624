import {DepositorDto} from "@fixrate/fixrate-query";
import {useTranslation} from "react-i18next";
import {useCommand} from "#command";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {showConfirmationModal} from "#state/reducers/confirmationModal";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Switch
} from "@mui/material";
import AsyncButton from "#components/Button/AsyncButton";

const tPath = 'pages-organizations.swimbird'

export type SwimbirdProps = {
    depositor: DepositorDto,
    onClose: () => void
}

export default function SwimbirdConfigurationDialog({ depositor, onClose }: SwimbirdProps) {
    const {t} = useTranslation()
    const {updateDepositorSwimbirdConfiguration} = useCommand()
    const dispatch = useDispatch()
    const [integrationEnabled, setIntegrationEnabled] = useState(depositor.swimbirdEnabled)
    const [integrationTermsAccepted, setIntegrationTermsAccepted] = useState(depositor.swimbirdEnabled)

    function handleIntegrationEnabledChange(checked: boolean) {
        setIntegrationEnabled(checked)
        if (!checked) {
            setIntegrationTermsAccepted(false)
        }
    }

    async function handleSubmit() {
        const {waitForCommand} = await updateDepositorSwimbirdConfiguration(depositor.id, integrationEnabled)

        const success = await waitForCommand()
        if (success) {
            dispatch(showConfirmationModal({
                title: t(`${tPath}.confirmationModal.title`),
                text: t(`${tPath}.confirmationModal.text`),
                buttonText: t('common.continue'),
            }))
            onClose()
        }
    }

    const canSubmit = () => {
        if (integrationEnabled) {
            return integrationTermsAccepted
        }
        return true
    }

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{t(`${tPath}.configureIntegration.heading`)}</DialogTitle>
            <DialogContent>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 2}}>
                    <FormControlLabel
                        control={
                            <Switch
                                id={'swimbirdEnabledSwitch'}
                                checked={integrationEnabled}
                                onChange={(e) => {handleIntegrationEnabledChange(e.target.checked)}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t(`${tPath}.configureIntegration.enabled`)}
                    />
                    {integrationEnabled && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={'swimbirdCheckbox'}
                                    checked={integrationTermsAccepted}
                                    onChange={(e) => {setIntegrationTermsAccepted(e.target.checked)}}
                                />
                            }
                            label={t(`${tPath}.configureIntegration.terms`, {depositorName: depositor.name})}
                        />
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    id={'swimbirdCancelButton'}
                    onClick={onClose}
                >
                    {t(`${tPath}.configureIntegration.cancel`)}
                </Button>
                <AsyncButton
                    variant={'contained'}
                    disabled={!canSubmit()}
                    onClick={handleSubmit}
                >
                    {t(`${tPath}.configureIntegration.save`)}
                </AsyncButton>
            </DialogActions>
        </Dialog>
    )

}
