import { useEffect, useRef, useState } from 'react';
import styles from './Confetti.module.css'

const Confetti = (props) => {

    const canvasRef = useRef(null)
    const [canvasVisible, setCanvasVisible] = useState(true)

    const confetti = []
    const confettiCount = 200
    const gravity = 0.8
    const terminalVelocity = 10
    const drag = 0.03
    const colors = [
        {front: '#95E1BF', back: '#caf5de'},
        {front: '#95E1BF', back: '#caf5de'},
        {front: '#B44A06', back: '#ce763d'},
        {front: '#3479BD', back: '#66a6e7'},
        {front: '#4D2A98', back: '#8864d9'},
        {front: '#FFA621', back: '#ffca7e'},
    ]

    const randomRange = (min, max) => Math.random() * (max - min) + min

    function draw(canvas) {

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        const rotation = randomRange(0, 2 * Math.PI)
        confetti.forEach((confetto, index) => {
            const width = confetto.dimensions.x * confetto.scale.x
            const height = confetto.dimensions.y * confetto.scale.y

            // Move canvas to position and rotate
            ctx.translate(confetto.position.x, confetto.position.y)
            ctx.rotate(rotation)

            // Apply forces to velocity
            confetto.velocity.x -= confetto.velocity.x * drag
            confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity)
            confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()

            // Set position
            confetto.position.x += confetto.velocity.x
            confetto.position.y += confetto.velocity.y

            // Delete confetti when out of frame
            if (confetto.position.y >= canvas.height) confetti.splice(index, 1)

            // Loop confetto x position
            if (confetto.position.x > canvas.width) confetto.position.x = 0
            if (confetto.position.x < 0) confetto.position.x = canvas.width

            // Spin confetto by scaling y
            confetto.scale.y = Math.cos(confetto.position.y * 0.1)
            ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back

            // Draw confetto
            ctx.fillRect(-width / 2, -height / 2, width, height)

            // Reset transform matrix
            ctx.setTransform(1, 0, 0, 1, 0, 0)
        })

    }

    const initConfetti = (canvas) => {
        for (let i = 0; i < confettiCount; i++) {
            confetti.push({
                color: colors[Math.floor(randomRange(0, colors.length))],
                dimensions: {
                    x: randomRange(8, 12),
                    y: randomRange(8, 24),
                },
                position: {
                    x: canvas.width/2,
                    y: canvas.height/3,
                },
                rotation: randomRange(0, 2 * Math.PI),
                scale: {
                    x: 1,
                    y: 1,
                },
                velocity: {
                    x: randomRange(-25, 25) * 2,
                    y: randomRange(-4 * terminalVelocity, 0),
                },
            })
        }
    }

    useEffect(() => {
        const canvas = canvasRef.current
        let animationFrameId
        let timeoutId
        let dead = false
        let killTimeoutId = setTimeout(() => {
            dead = true
            setCanvasVisible(false)
        }, 10_000)

        initConfetti(canvas)

        // Our draw came here
        const render = () => {
            if (dead) return

            draw(canvas)
            setTimeout(() => {
                animationFrameId = window.requestAnimationFrame(render)
            }, 1000 / 60)
        }
        render()

        return () => {
            window.cancelAnimationFrame(animationFrameId)
            clearTimeout(timeoutId)
            clearTimeout(killTimeoutId)
        }
    }, [])

    if (!canvasVisible) return null

    return <canvas ref={canvasRef} {...props} width={window.innerWidth} height={window.innerHeight} className={styles.canvas}/>
}

export default Confetti
