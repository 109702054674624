import { Route, Routes } from 'react-router-dom'
import OrderDetails from './OrderDetails/OrderDetails'
import OrdersBankList from './OrdersBankList/OrdersBankList'

const OrdersBank = () => {
    return (
        <Routes>
            <Route path='' element={<OrdersBankList />} />
            <Route path=':id' element={<OrderDetails />} />
        </Routes>
    )
}

export default OrdersBank
