import { Fragment } from 'react';
import MenuItem from './MenuItem'
import {useSelector} from '#state/useSelector'
import {isLoggedIn, messageTasksActionRequiredByUserCountSelector} from '#state/selectors'
import {useTranslation} from "react-i18next"

export default function PartnerMenu() {
    const {t} = useTranslation()
    const loggedIn = useSelector(isLoggedIn)
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)

    return (
        <Fragment>
            <MenuItem id='marketPlaceMenuItem' link={'/marketplace'} name={t('menu.marketplace')} remixIcon='ri-store-2-line' />
            {loggedIn && (
                <Fragment>
                    <MenuItem id='inboxMenuItem' link={'/inbox'} name={t('menu.inbox')} remixIcon='ri-inbox-line' counter={messageTasksActionRequiredByUserCount} />
                    <MenuItem id='customerMenuItem' link={'/customer'} name={t('menu.customers')} remixIcon='ri-briefcase-line' />
                    <MenuItem id='proposals' link={'/partner/proposals'} name={t('menu.proposals')} remixIcon='ri-mail-send-line' />
                </Fragment>
            )}
            {!loggedIn && <MenuItem id='loginMenuItem' key='login' link={'/marketplace?login'} name={t('menu.logIn')} remixIcon='ri-account-circle-line' />}
        </Fragment>
    )
}

