import AsyncButton from '#app/components/Button/AsyncButton'
import PartnerLogo from '#app/components/PartnerLogo/PartnerLogo'
import { SignableDocument } from '#app/components/SignableDocument/SignableDocument'
import { useCommand } from '#app/services/beta'
import { logout } from '#app/services/thunks/session'
import usePartnerInformation from '#app/services/usePartnerInformation'
import { isLoggedIn, lookupSignatureStatus } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { Alert, AlertTitle, Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import FixrateLogo from '../menu/FixrateLogo'
import SuccessIllustration from './SuccessIllustration.svg?url'

type Params = {
    partnerId?: string
    authorizationId?: string
}
export default function DepositorPartnerSignature() {
    const { t } = useTranslation()
    const { startElectronicSignature } = useCommand()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { partnerId } = useParams<Params>()
    const depositor = useSelector(state => state.depositor)
    const signatureStatus = useSelector(lookupSignatureStatus)
    const loggedIn = useSelector(isLoggedIn)
    const userId = useSelector(state => state.session?.id)
    const { authorizationDocument, partnerUser, selectedPartner } = usePartnerInformation(partnerId)

    const documentSignedByCurrentUser = authorizationDocument?.allSignatures?.find(signature => signature?.userId === userId)

    async function startSignature() {
        const processId = uuidv4()
        try {
            await startElectronicSignature(processId, 'SINGLE_DOCUMENT', authorizationDocument?.id)
            navigate(`/signature/${processId}?context=/partner/signature/${partnerId}`)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Stack overflow={'auto'} bgcolor={'#F6F6F6'} left='0' top='0' width={'100%'} height={'100%'} position={'fixed'} zIndex="2">
            { loggedIn && (
                <Stack alignItems='center' justifyContent='center' mt='auto' py={3}>
                    <Stack alignItems={'center'} width='100%'>
                        <Paper sx={{ maxWidth: '100%', width: '85rem', minHeight: '60rem', p: 0 }}>
                            <Stack justifyContent={'space-between'} height='100%' overflow='auto'>
                                <Stack spacing={3}>
                                    <Box>
                                        <Stack py={1.5} px={4} alignItems={"center"} direction={{ md: 'row' }} justifyContent={'space-between'}>
                                            <Typography variant='h2' mb={0}>Signering av fullmakt</Typography>
                                            <Box width='16rem'>
                                                <PartnerLogo partnerId={partnerId} />
                                            </Box>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    <Box px={4}>
                                        { !documentSignedByCurrentUser ? (
                                            <Stack spacing={2}>
                                                <Box>
                                                    <Typography>{t('layers-proposalWizard.authorizationDocumentSign', {name: partnerUser?.firstName + " " + partnerUser?.lastName, partner: selectedPartner?.name, depositor: depositor?.name})}</Typography>
                                                </Box>
                                                <Stack maxWidth={'45rem'}>
                                                    { authorizationDocument ? (
                                                        <SignableDocument
                                                            document={authorizationDocument}
                                                            hideRemoveButton={true}
                                                            isCheckingSignatureStatus={signatureStatus(authorizationDocument?.id)}
                                                            linkText={t('pages-organizations.powerOfAttorneyDocument')}
                                                            onStartSignature={startSignature}
                                                            showSigningError={false}
                                                        />
                                                    ) : (
                                                        <Alert severity="warning">
                                                            <AlertTitle>{t('layers-proposalWizard.missingDocument')}</AlertTitle>
                                                            {t('layers-proposalWizard.missingDocumentDescription', {partner: selectedPartner?.name})}
                                                        </Alert>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center">
                                                <Box maxWidth={"25rem"}>
                                                    <img style={{maxWidth: "100%"}} src={SuccessIllustration} alt="" />
                                                </Box>
                                                <Stack mt={3} maxWidth="45rem">
                                                    <Typography variant="h2">{t('layers-partnerSignature.authorizationDocumentSigned')}</Typography>
                                                    <Typography>{t('layers-partnerSignature.authorizationDocumentSignedDescription')}</Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                    </Box>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Stack direction="row" spacing={1} mt={3}>
                            <AsyncButton variant='outlined' color='secondary' size='small' onClick={() => dispatch(logout(navigate, "/partner/logged-out/" + partnerId))}>
                                {t('common.logOut')}
                            </AsyncButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}
            <Stack alignItems={'center'} width='100%' mt='auto' mb={3}>
                <Divider sx={{ width: '100%', maxWidth: '120rem', mb: 2 }} />
                <Box sx={{ '& svg': { maxWidth: '100%' } }} maxWidth={'14rem'}>
                    <FixrateLogo />
                </Box>
            </Stack>
        </Stack>
    )
}
