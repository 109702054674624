import ActionBlock from '#components/ActionBlock/ActionBlock'
import styles from './NextFixedInterestPeriodTerms.module.scss'
import { DateOutput, InterestOutput } from '#components'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import DocumentLink from '#pages/common/DocumentLink/DocumentLink'
import config from '#app/config'
import { useSelector } from '#state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

export default function NextFixedInterestPeriodTerms({ deposit }: { deposit: DepositDto }) {

    const {t} = useTranslation()

    const document = useSelector(state => {
        return deposit.nextPeriod && state.documents[deposit.nextPeriod.documentId]
    })

    if (!deposit.nextPeriod || deposit.terminationDate || deposit.terminationRequested) {
        return null
    }

    return (
        <ActionBlock header={t('pages-portfolio-depositor.nextPeriodInterestHeader')}>
            <p>
                {t('pages-portfolio-depositor.nextPeriodInterestInfo')}
            </p>
            <div className={styles.borderedSection}>
                <LabeledInfo label={t('pages-portfolio-depositor.nextPeriodStartDate')}
                             info={DateOutput.formatVerboseMonth(deposit.nextPeriod.startDate)}/>
                <LabeledInfo label={t('pages-portfolio-depositor.nextPeriodTerminationDate')}
                             info={DateOutput.formatVerboseMonth(deposit.nextPeriod.terminationDate)}/>
                <LabeledInfo label={t('pages-portfolio-depositor.nextPeriodInterestRate')}
                             info={InterestOutput.format(deposit.nextPeriod.interestRate)}/>
                <LabeledInfo label={t('pages-portfolio-depositor.nextPeriodTerminationDeadline')}
                             info={DateOutput.formatDateTime(toDateAtNoon(deposit.nextPeriod.startDate))}/>
                <LabeledInfo label={t('pages-portfolio-depositor.nextPeriodDocument')}
                             info={<DocumentLink name={t('pages-portfolio-depositor.nextPeriodDocumentName')} link={`${API_BASE_URL}${document?.link}`} hideIcon={true}/>}/>
            </div>

            <p>
                {t('pages-portfolio-depositor.nextPeriodInterestInfo2', {date: DateOutput.formatDateTime(toDateAtNoon(deposit.nextPeriod.startDate))})}
            </p>
            <p>
                {t('pages-portfolio-depositor.nextPeriodInterestInfo3', {date: DateOutput.formatDateTime(toDateAtNoon(deposit.nextPeriod.startDate))})}
            </p>
        </ActionBlock>
    )
}

function toDateAtNoon(date: string | Date) {
    const newDate = new Date(date)
    newDate.setHours(12)
    return newDate
}
