import { useEffect, useRef, ReactElement, useContext, ReactChild, useCallback, createContext } from 'react';
import styles from './Navigation.module.scss'
import {useSpring} from 'framer-motion'
import {fromEvent, Subject} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import classNames from 'classnames'

const NavigationContext = createContext<React.RefObject<HTMLElement> | null>(null);

type NavigationProps = {
    children?: ReactElement<NavigationLinkProps> | ReactElement<NavigationLinkProps>[],
    className?: string,
    lastInteraction?: string,
    scrollableContainer: React.RefObject<HTMLElement>
}

/**
 * The navigation menu.
 * The menu can hold NavigationLink components.
 * The menu has a ref to a scrollable container. Each NavigationLink menu item is connected to a target element that lives in the scrollable container.
 * When a NavigationLink is clicked, the scrollable container will scroll to the target element.
 */
export default function Navigation({children = null, className: propsClassName = '', scrollableContainer,  ...props} : NavigationProps) {
    return (
        <NavigationContext.Provider value={scrollableContainer}>
            <ul className={classNames(styles.links, styles[propsClassName] || propsClassName)} {...props}>
                {children}
            </ul>
        </NavigationContext.Provider>
    )
}

type NavigationLinkProps = {
    children?: ReactChild,
    error?: boolean,
    success?: boolean,
    useIcon?: boolean,
    style?: object,
    active?: boolean,
    onClick?: () => void,
    to: React.RefObject<HTMLElement>,
    className?: string
}

/**
 * A link in the navigation menu. It is connected to a target element that lives in a scrollable container.
 * The "to" parameter is a ref to the linked target element.
 * When this link is clicked, the scrollable container will scroll to the target element.
 */
export function NavigationLink({
    children = null,
    error = false,
    success = false,
    useIcon = true,
    style = {},
    active = null,
    onClick = null,
    to, // A ref to the target element. This ref will be used to calculate the scroll position.
    className: propsClassName = '',
    ...props
}: NavigationLinkProps) {
    
    const parent = useContext(NavigationContext)

    const motionValue = useSpring(0, { stiffness: 100, damping: 20 })

    // Calculates the scroll position of the target element and activates motionValue
    // The motionValue will start an animated value that can be used to change the scroll position
    const scrollToTarget = useCallback(() => {
        if (motionValue.isAnimating()) {
            return
        }

        if (to.current) {
            to.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [to, motionValue])

    // Creates a rxjs subject that accepts and queues up requests to scroll to target
    // When a new request is pushed to the subject, it will call scrollToTarget
    const scrollToTargetRef = useRef(null)
    useEffect(() => {
        scrollToTargetRef.current = new Subject().subscribe(() => {
            scrollToTarget()
        })
        return () => {
            scrollToTargetRef.current.unsubscribe()
        }
    }, [scrollToTarget])

    useEffect(() => {
        // Listens to scroll events from window and call motionValue.stop()
        // This lets the user scroll the container manually without the animation interrupting
        // The debounceTime is used to prevent the scroll event from being called too frequently
        const $scroll = fromEvent(window, 'scroll')
            .pipe(debounceTime(10))
            .subscribe(() => {
                motionValue.stop()
            })
        return () => {
            $scroll.unsubscribe()
        }
    }, [motionValue])

    useEffect(() => {
        // Subscribes to changes in motionValue and updates the scroll position of the container
        // This is what actually performs the scroll animation
        const start = parent.current.scrollTop
        const unsubscribe = motionValue.onChange(change => {
            if (parent.current.scrollTop >= parent.current.scrollHeight) {
                motionValue.stop()
            } else {
                parent.current.scrollTop = start + change
            }
        })
        return unsubscribe
    }, [motionValue, parent])

    function startScrolling() {
        // Pushes a new request to scroll to target to the rxjs subject stored in scrollToTargetRef
        if (scrollToTargetRef.current) {
            scrollToTargetRef.current.next()
        }
    }

    function handleKeyPress(e) {
        const key = e?.keyCode || e?.which
        if (key === 32 || key === 13) {
            e?.preventDefault?.()
            startScrolling()
        }
    }

    return (
        <li
            {...props}
            className={classNames(styles.link, styles[propsClassName] || propsClassName)}
            tabIndex={0}
            data-success={success ? 'true' : 'false'}
            data-error={error ? 'true' : error}
            data-active={active ? 'true' : active}
            role='button'
            onClick={startScrolling}
            onKeyDown={handleKeyPress}
        >
            {useIcon && <i className={error ? 'ri-error-warning-line red' : 'ri-checkbox-circle-line green'} />}
            <span>{children}</span>
        </li>
    )
}

