import useSupportPane from '#services/useSupportPane'
import styles from '../SupportPane.module.scss'

export default function NotionBackIcon() {

    const supportPane = useSupportPane()

    if (supportPane.isTopLevelPage) {
        return <div/>
    }

    return (
        <div className={styles.backLink}
            onClick={() => supportPane.loadParentPage()}>
            <i className={'ri-arrow-left-line'}/>
            <span>Gå tilbake</span>
        </div>
    )

}