import * as session from './session'
import * as marketplacefilterActions from '../../state/reducers/marketplacefilter'
import restEndpoint from '../rest/rest-endpoint'
import command from '../command/command'
import {setDashboardPotential} from '#state/reducers/dashboard'
import {AdNotificationFrequency, Product} from '@fixrate/fixrate-query'


export const registerUser = (user) => (dispatch) => {
    return restEndpoint(dispatch).registerUser(user).then(() => {
        dispatch(session.syncSessionState())
    })
}

export const getInvite = (inviteId: string, signal: AbortSignal) => (dispatch) => {
    return restEndpoint(dispatch, signal).getInvite(inviteId)
}

export const generateIdentificationDocument = (update) => dispatch => {
    return command(dispatch).generateIdentificationDocument(update)
}

export const removeIdentificationDocument = () => dispatch => {
    return command(dispatch).removeIdentificationDocument()
}

export const setPinCode = (pinCode) => dispatch => {
    return command(dispatch).setPinCode(pinCode)
}

export const deletePinCode = () => dispatch => {
    return command(dispatch).deletePinCode()
}

export const setPotential = (potentialVolume, historicInterestRate) => (dispatch, getState) => {
    if (getState().session.authenticated) {
        return command(dispatch).setPotential(potentialVolume, historicInterestRate)
    } else {
        restEndpoint(dispatch).potentialInterest(potentialVolume, historicInterestRate).then(result => {
            dispatch(setDashboardPotential({
                potentialVolume,
                historicInterestRate,
                potentialMonthlyInterest: result.potentialMonthlyInterest,
                todaysMonthlyInterest: result.todaysMonthlyInterest
            }))
        })
    }
}

export const setCustomUserFilter = (filterId: string | null, products: string[], minDepositAmount: number, maxDepositAmount: number | null, totalAssets: number, minInterestRate: number, minInterestRateMargin: number, notify: boolean) => dispatch => {
    return command(dispatch).setCustomUserFilter(filterId, products, minDepositAmount, maxDepositAmount, totalAssets, minInterestRate, minInterestRateMargin, notify)
}

export const setUserFilterNotify = (filterId: string, notify: boolean) => dispatch => {
    return command(dispatch).setUserFilterNotify(filterId, notify)
}

export const setMatchAllFilterFrequency = (filterId: string, frequency: AdNotificationFrequency) => dispatch => {
    return command(dispatch).setUserFilterFrequency(filterId, frequency)
}

export const setFilterProduct = (id: string, state: boolean) => (dispatch, getState) => {
    const activeFilter = getState().marketplacefilter.activeFilter
    dispatch(marketplacefilterActions.setFilterProduct({id, state}))

    // Set filterMinInterestRateMargin = 0 if no floating product is selected
    const products: {[key: string]: Product} = getState().products
    const aFloatingProductIsSelected = Object.keys(activeFilter.products)
        .filter(key => id !== key && activeFilter.products[key])
        .some(key => products[key].type === 'FLOATING')
    if (!aFloatingProductIsSelected && activeFilter.minInterestRateMargin) {
        dispatch(marketplacefilterActions.setFilterMinInterestRateMargin(0))
    }
}
