import { useTranslation } from "#components/i18n";
import { PersonValidator } from "#services/PersonValidator";
import { DepositorDto } from "@fixrate/fixrate-query";
import { Box, Icon, MenuItem, Select, Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function TabSelector({depositor}: {depositor: DepositorDto}) {
    const {t} = useTranslation('pages-organizations')
    const navigate = useNavigate()
    const location = useLocation()
    const depositorId = depositor?.id
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

    const tabSelect = (newValue: string) => navigate(newValue)

    const menuOptions = [
        {
            label: t('tabRoles'),
            value: `/organizations/${depositorId}/users/roles`,
            dataCy: 'selectFixrateRolesLink',
            warning: !PersonValidator.areSecurityRolesCovered(depositor),
            visible: true,
        },
        {
            label: t('tabBoard'),
            value: `/organizations/${depositorId}/users/board`,
            dataCy: 'selectBoardAndManagementLink',
            warning: !PersonValidator.areOrganisationalRolesCovered(depositor) || depositor?.importedPeopleChanges?.includes('ROLES'),
            visible: true,
        },
        {
            label: t('tabOwners'),
            value: `/organizations/${depositorId}/users/owners`,
            dataCy: 'selectOwnersLink',
            warning: !PersonValidator.areOwnersCovered(depositor) || depositor?.importedPeopleChanges?.includes('OWNERS'),
            visible: true,
        },
        {
            label: t('tabAuthorizations'),
            value: `/organizations/${depositorId}/users/power-of-attorney`,
            dataCy: 'selectAuthorizations',
            warning: PersonValidator.anyMissingAuthDocsOrSignatures(depositor),
            visible: depositor?.depositorSupportCategory !== 'SELF_SERVICED',
        },
        {
            label: t('tabAllPersons'),
            value: `/organizations/${depositorId}/users/all`,
            dataCy: 'selectAllPeople',
            warning: !PersonValidator.arePeopleComplete(depositor),
            visible: true,
        },
    ]

    return (
        <Fragment>
            { isMobile ? (
                <Box>
                    <Select value={location.pathname} onChange={(e) => tabSelect(e.target.value)}>
                        { menuOptions.filter(o => o.visible).map((option, index) => (
                            <MenuItem key={index} value={option.value} data-cy={option.dataCy}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    { option.warning && (
                                        <Icon sx={{display: "flex", alignItems: "center", fontSize: "1.8rem"}}>
                                            <i className="red ri-error-warning-line" />
                                        </Icon>
                                    )}
                                    {option.label}
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            ) : (
                <Tabs value={location.pathname} onChange={(e, newVal) => tabSelect(newVal)}>
                    { menuOptions.filter(o => o.visible).map((option, index) => (
                        <Tab
                            key={index}
                            icon={option.warning ? (
                                <i className="red ri-error-warning-line"/>
                            ) : null }
                            label={option.label}
                            value={option.value}
                            data-cy={option.dataCy}
                        />
                    )) }
                </Tabs>
            )}
        </Fragment>
    );
}
