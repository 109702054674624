import Paper from "#app/components/Paper"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

const DepositDetailsAnnualStatements = () => {
    const { t } = useTranslation()

    return (
        <Paper title={t('pages-portfolio-depositor.annualStatementsHeader')}>
            <p>{t('pages-portfolio-depositor.annualStatementsInfo2')}</p>
            <p><NavLink to={'/reports/deposits/annual-statements'}>{t('pages-portfolio-depositor.annualStatementsLink')}</NavLink></p>
        </Paper>
    )
}

export default DepositDetailsAnnualStatements