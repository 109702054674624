import { useCommand } from '#command'
import { DateOutput, InterestOutput } from '#components'
import Button from '#components/Button'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import If from '#components/If'
import DepositorOfferConfirm from '#pages/DepositorOfferBank/DepositorOfferConfirm'
import { useAuthorization } from '#services/authorization'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import { useFieldState } from '@fixrate/fieldstate'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './DepositorOfferDetails.module.css'
import {useCallback, useState} from 'react'
import {Box, IconButton, Typography} from "@mui/material";
import {FOREST_GREEN, PURPLE, SILVER_GRAY} from "#app/colors/colors";


type Params = {
    depositorOfferId?: string
    modal?: 'confirm'
}

export default function DepositorOfferDetails() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const bank = useSelector(state => state.bank)
    const depositorOffer = useSelector(state => state.depositorOffers.find(depositorOffer => depositorOffer.depositorOfferId === params.depositorOfferId))
    const product = useSelector(state => state.products[depositorOffer?.productId])
    const auth = useAuthorization()

    const [isVisible, setIsVisible] = useState(true);

    const volumeFieldValidator = useCallback(({value, isEditing}) => {
        if (!auth.bank.hasAdManagerRole) {
            return t('pages-offer.requiredRoleAdManager')
        }
        if (!value) {
            return t('common.mandatoryField')
        }
        if (!value.match(/^\d+$/)) {
            return t('pages-offer.wantedVolumeValidationMatch')
        }
        if (isEditing) return
        const valueAsInt = parseInt(value)
        if (valueAsInt < depositorOffer.minVolume) {
            return t('pages-offer.wantedVolumeValidationMin', {minVolume: depositorOffer.minVolume})
        }
        if (valueAsInt > depositorOffer.maxVolume) {
            return t('pages-offer.wantedVolumeValidationMax', {maxVolume: depositorOffer.maxVolume})
        }
    }, [auth.bank.hasAdManagerRole, depositorOffer.maxVolume, depositorOffer.minVolume, t])
    const volumeField = useFieldState('', volumeFieldValidator)

    const {acceptDepositorOffer} = useCommand()

    if (!depositorOffer || !bank) {
        return null
    }

    const handleClose = () => {
        setIsVisible(false);
        navigate(`/depositoroffer-bank/`, { replace: true });
    };

    if (!isVisible) return null;

    const isUsedAlready = !!depositorOffer.banks[0].acceptedAt
    const isExpired = new Date(depositorOffer.deadline) < new Date()
    const isEmpty = depositorOffer.minVolume > depositorOffer.remainingVolume

    let customerPlan
    if (depositorOffer.expectedDuration && depositorOffer.expectedDuration !== 'UNKNOWN') {
        customerPlan = t('pages-offer.planForDepositMessageWithDuration', {expectedDuration: depositorOffer.expectedDuration})
    } else if (depositorOffer.wantExtensionOffer) {
        customerPlan = t('pages-offer.planForDepositMessageWithExtension')
    } else {
        customerPlan = t('pages-offer.planForDepositMessageNoPlan')
    }

    let distribution
    if (depositorOffer.numberOfBanks === 1) {
        distribution = t('pages-offer.exclusiveOffer', {bankName: bank.name})
    } else {
        distribution = t('pages-offer.notExclusiveOffer', {numberOfBanks: depositorOffer.numberOfBanks})
    }

    function onAccept() {
        const isValid = volumeField.validate()
        if (isValid && !isUsedAlready && !isExpired) {
            navigate(`/depositoroffer-bank/${depositorOffer.depositorOfferId}/confirm`)
        }
    }

    async function onConfirmed() {
        const {waitForCommand} = await acceptDepositorOffer(depositorOffer.depositorOfferId, bank.id, volumeField.value)
        const success = await waitForCommand()
        if (success) {
            navigate('/bank-orders')
            dispatch(showConfirmationModal({
                title: t('pages-offer.offerAcceptedHeading'),
                text: t('pages-offer.offerAcceptedMessage'),
                buttonText: t('common.continue')
            }))
        }
    }

    function onCancel() {
        navigate(`/depositoroffer-bank/${depositorOffer.depositorOfferId}`)
    }

    const showConfirmModal = params.modal === 'confirm'

    const isUsedWarning = isUsedAlready && (
        <p>{t('pages-offer.alreadyUsedByBank')}</p>
    )
    const isExpiredWarning = isExpired && (
        <p>{t('pages-offer.expiredMessage')}</p>
    )
    const isEmptyWarning = isEmpty && (
        <p>{t('pages-offer.filledMessage')}</p>
    )

    let interestLabel = t('pages-offer.fixedInterest')
    let interest = InterestOutput.format(depositorOffer.interestRate)
    if (product.type !== 'FIXED' ) {
        interestLabel = t('pages-offer.interestRate')
        interest = InterestOutput.formatMarginWithBenchmark(depositorOffer.interestRate, defaultBenchmark(depositorOffer.currency), t)
    }

    let terminationDateInfo = null
    if (product.type === 'FIXED' ) {
        terminationDateInfo = <Row title={t('pages-offer.duration')} value={DateOutput.formatDate(depositorOffer.terminationDate)}/>
    }

    function Row({ title, value }: { title: string, value: string | number }) {
        return (
            <div className={styles.row}>
                <div className={styles.title}>{title}</div>
                <div className={styles.value}>{value}</div>
            </div>
        )
    }

    return (
        <div className={styles.body}>
            <Box sx={{ position: 'absolute', right: '1.6rem', top: '1.6rem' }}>
                <IconButton sx={{ fontSize: '2.4rem', color: FOREST_GREEN[500] }} onClick={handleClose}>
                    <i className='ri-close-line' />
                </IconButton>
            </Box>
            <h2>{depositorOffer.depositorName}</h2>
            <hr className={styles.compactHr}/>
            <p>{t('pages-offer.offerBankDetailsHeading', {depositorName: depositorOffer.depositorName})}</p>
            <div>
                <Row title={t('pages-offer.customer')} value={depositorOffer.depositorName}/>
                <Row title={t('pages-offer.organizationNumber')} value={depositorOffer.depositorNationalIdentity}/>
                <Row title={t('pages-offer.sent')} value={DateOutput.formatDateTime(depositorOffer.publishedAt)}/>
                <hr className={styles.compactHr}/>
                <Row title={t('pages-offer.product')} value={product.name}/>
                <Row title={interestLabel} value={interest}/>
                {terminationDateInfo}
                <Row title={t('pages-offer.volume')} value={t('pages-offer.volumeValue', {minVolume: depositorOffer.minVolume, maxVolume: depositorOffer.maxVolume})}/>
                <hr className={styles.compactHr}/>
            </div>
            <h4>{t('pages-offer.planForDepositHeading')}</h4>
            <div>{customerPlan}</div>
            {depositorOffer.comment &&
                <Box sx={{marginTop: '1rem'}}>
                    <div>{t('pages-offer.commentFromCustomer')}</div>
                    <Box
                        sx={{
                            marginTop: '1rem',
                            backgroundColor: SILVER_GRAY[50],
                            padding: '1rem 1.4rem',
                        }}
                    >
                        <Typography variant='body2'>
                            {depositorOffer.comment}
                        </Typography>
                    </Box>
                </Box>
            }
            <hr className={styles.compactHr}/>
            <h4>{t('pages-offer.deadlineHeading')}</h4>
            <p>{distribution}</p>
            <Row title={t('pages-offer.deadlineMessage')} value={DateOutput.formatDateTime(depositorOffer.deadline)}></Row>
            <Box
                sx={{
                    borderTop: 'solid',
                    backgroundColor: PURPLE[50],
                    color: PURPLE[800],
                    margin: '1.6rem -3.2rem',
                    padding: '1.6rem 3.2rem',
                }}>
                <If condition={!isUsedAlready && !isExpired && !isEmpty}>
                    <div className={styles.volumeInput}>
                        <label>
                            {t('pages-offer.wantedVolume')}
                            <input value={volumeField.value}
                                   id="volume"
                                   type="text"
                                   className={styles.volume}
                                   onChange={(e) => volumeField.setValue(e.target.value)}
                                   onBlur={volumeField.onBlur}
                            />
                            {t('pages-offer.mill')}
                        </label>
                        {volumeField.errorMessage && <p className="field-error-message">{volumeField.errorMessage}</p>}
                    </div>
                    <ButtonRow align={'right'}>
                        <Button id="acceptDepositorOfferButton" onClick={onAccept} disabled={!volumeField.valid}>{t('pages-offer.acceptOffer')}</Button>
                    </ButtonRow>
                </If>
                {isUsedWarning || isEmptyWarning || isExpiredWarning}
            </Box>

            {showConfirmModal && <DepositorOfferConfirm depositorOffer={depositorOffer} volume={volumeField.value as unknown as number} onAccept={onConfirmed} onCancel={onCancel}/>}
        </div>
    )
}
