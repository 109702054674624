import { DateOutput, InterestOutput } from '#app/components'
import { useSelector } from '#app/state/useSelector'
import { LoadingSpinner } from '#components'
import { useAuthorization } from '#services/authorization'
import * as selectors from '#state/selectors'
import { AdDto } from '@fixrate/fixrate-query'
import { Alert, AlertTitle, Box, Button, LinearProgress, Tab, Tabs, Tooltip, useMediaQuery } from '@mui/material'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AdCard from '../marketplace/AdCard/AdCard'
import styles from './ActiveAd.module.scss'
import { OrderList } from './OrderList'

type TabValues = 'ORDERS' | 'AD_DETAILS'

type Props = {
    ad: AdDto
}

export const ActiveAd = ({ad}: Props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [tab, setTab] = useState<TabValues>("ORDERS")

    const bank = useSelector(selectors.bankInSession)
    const auth = useAuthorization()
    const orders = useSelector(state => state.orders)
    const product = useSelector(state => state.products[ad.productId])
    const adStatus = useSelector(state => state.adStatus[ad.id])

    const isMobile = useMediaQuery('(max-width: 1300px)')

    if (!product || !adStatus) {
        return <LoadingSpinner/>
    }

    return (
        <div className={styles.activeAd} data-cy="activeAdRow">
            <div className={styles.cardContainer}>
                <AdCard className={styles.adCard} ad={ad} onAdClick={() => false} product={product}/>
                {ad.depositorName && (
                    <Tooltip title={ad.depositorName}>
                        <p className="field-info-message" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '30.4rem'}}>
                            {ad.depositorName}
                        </p>
                    </Tooltip>
                )}
            </div>
            {isMobile && (
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tab} sx={{paddingLeft: '2.6rem'}} onChange={(e, val) => setTab(val)}>
                        <Tab label={t('pages-BankAds.orders')} value="ORDERS"/>
                        <Tab label={t('pages-BankAds.adDetails')} value="AD_DETAILS"/>
                    </Tabs>
                </Box>
            )}
            <div className={classNames(styles.tabSection, tab === 'ORDERS' ? styles.active : null)}>
                <h3 className={styles.tabTitle}>{t('pages-BankAds.orders')}</h3>
                <LinearProgress sx={{height: '1.2rem', backgroundColor: 'rgb(220 214 234)'}} variant="determinate" value={(ad.volume - ad.remaining) / ad.volume * 100}/>
                <div className={styles.volumeProgressLabel}>
                    <div>
                        <h4 className={styles.title}>{t('pages-BankAds.deposits')}</h4>
                        <p className={styles.value}>{t('common.volume_short', {count: ad.volume - ad.remaining})}</p>
                    </div>
                    <div className={styles.last}>
                        <h4 className={styles.title}>{t('pages-BankAds.totalVolume')}</h4>
                        <p className={styles.value}>{t('common.volume_short', {count: ad.volume})}</p>
                    </div>
                </div>
                <OrderList orders={orders.filter(o => o.ad.id === ad.id)}/>
            </div>
            <div className={classNames(styles.tabSection, tab === 'AD_DETAILS' ? styles.active : null)}>
                <h3 className={styles.tabTitle}>{t('pages-BankAds.adDetails')}</h3>
                {(product.type !== 'FIXED') && (
                    <ul className={styles.keyValueList}>
                        <li>
                            <span>{t('pages-BankAds.marginAddition')}</span>
                            <span className={styles.value}>
                                {InterestOutput.format(ad.nominalInterestRate)}
                            </span>
                        </li>
                        <li>
                            <span>{t('pages-BankAds.platformFeeIncludingMVA')}</span>
                            <span className={styles.value}>{InterestOutput.format((ad.platformFee / 4 * 5) / 100)}</span>
                        </li>
                        <li className={styles.sumTotal}>
                            <span>
                                <span>{t('pages-BankAds.totalCost')}</span>
                                <Tooltip title={t('pages-BankAds.totalCostExplanationFloating')}>
                                    <i className="ri-information-line"/>
                                </Tooltip>
                            </span>
                            <span className={styles.value}>{InterestOutput.format(ad.nominalInterestRate + (ad.platformFee / 4 * 5) / 100)}</span>
                        </li>
                    </ul>
                )}
                {product.type === 'FIXED' && (
                    <ul className={styles.keyValueList}>
                        <li>
                            <span>{t('pages-BankAds.interest')}</span>
                            <span className={styles.value}>{InterestOutput.format(ad.interest)}</span>
                        </li>
                        <li>
                            <span>{t('pages-BankAds.platformFeeIncludingMVA')}</span>
                            <span className={styles.value}>{InterestOutput.format((ad.platformFee / 4 * 5) / 100)}</span>
                        </li>
                        <li className={styles.sumTotal}>
                            <span>
                                <span>{t('pages-BankAds.totalCost')}</span>
                                <Tooltip title={t('pages-BankAds.totalCostExplanationFixed')}>
                                    <i className="ri-information-line"/>
                                </Tooltip>
                            </span>
                            <span className={styles.value}>{InterestOutput.format(ad.interest + (ad.platformFee / 4 * 5) / 100)}</span>
                        </li>
                        {ad.termination && (
                            <li>
                                <span>{t('pages-BankAds.givenTerminationDate')}</span>
                                <span className={styles.value}>{DateOutput.formatDate(ad.termination)}</span>
                            </li>
                        )}
                    </ul>
                )}
                <Alert sx={{fontSize: '1.2rem', mb: 1}} severity={'success'} variant={'outlined'}>
                    {t('pages-BankAds.publishedBy')} {bank.knownUserFullNames?.[ad.createdBy]} <DateOutput.DateTime date={ad.published}/>
                </Alert>
                {adStatus.canDeactivate && (
                    <div>
                        {ad.validity && (
                            <Alert sx={{fontSize: '1.2rem', mb: 1}} severity="info" variant={'outlined'}>
                                {t('pages-BankAds.deactivatesAutomatically', {date: DateOutput.formatDateTime(ad.validity)})}
                            </Alert>
                        )}
                        {auth.bank.hasAdManagerRole && (
                            <Button
                                data-cy="deactivateAdButton"
                                size={'small'}
                                variant={'outlined'}
                                color={'error'}
                                onClick={() => navigate(`/ads/deactivate/${ad.id}#!`)}>
                                {adStatus.canDeactivateInstantly ? t('pages-BankAds.deactivateNow') : (adStatus.canRegretActivation ? t('pages-BankAds.undoPublish') : t('pages-BankAds.deactivateIn6Hours'))}
                            </Button>
                        )}
                    </div>
                )}
                {!adStatus.canDeactivate && (
                    <Alert sx={{border: '#B44A0630 1px solid', fontSize: '1.2rem'}} severity="error">
                        <AlertTitle sx={{fontSize: '1.2rem'}}>{t('pages-BankAds.deactivates')} <DateOutput.Time date={ad.validity}/></AlertTitle>
                        {bank.knownUserFullNames?.[ad.deactivatedBy] || t('pages-BankAds.bySystem')}
                    </Alert>
                )}
            </div>
        </div>

    )
}
