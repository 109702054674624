import styles from './BrregBox.module.scss'
import classNames from "classnames";
import { Dispatch, SetStateAction } from 'react';

interface Props {
    header: string,
    infoVisible: boolean,
    setInfoVisible: Dispatch<SetStateAction<boolean>>,
    children: React.ReactNode,
}

export default function Brregbox({header, infoVisible = true, setInfoVisible, children}: Props) {
    return (
        <div className={styles.changes}>
            <div className={classNames(styles.content, infoVisible ? styles.openContent : styles.closedContent)}>
                <div
                    className={classNames(styles.header, styles.openHeader)}
                    onClick={() => setInfoVisible(!infoVisible)}
                >
                    <p>
                        <i className="ri-archive-drawer-line"/>
                        <span>{header}</span>
                    </p>
                    <i className={classNames(styles.closeOpenIcon, infoVisible ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line")}/>
                </div>

                { infoVisible && children }
            </div>
        </div>
    )
}