import Modal from './Modal'
import styles from './ModalConfirmation.module.css'

type Props = {
    onSubmit: () => void,
    onCancel: () => void,
    text,
    header
}

export default function ModalConfirmation({onSubmit, onCancel, text, header}: Props) {

    async function submit() {
        await onSubmit()
    }

    return (
        <Modal onCancel={onCancel}
               width="40rem"
               header={header}
               onSubmit={submit}>

            <p className={styles.body}>
                {text}
            </p>
        </Modal>
    )
}
