import { UpdatePersonInfo } from '#app/services/beta/command/useCommand.types'
import { formatPhoneNumber } from '#app/services/formatnumber'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useCommand } from '#command'
import { DateOutput } from '#components'
import DateInput from '#components/DateInput'
import HelpPopup from '#components/HelpPopup'
import { isAfter, isBefore } from '#services/dateandtime'
import { PersonValidator } from "#services/PersonValidator"
import { useFieldState } from '@fixrate/fieldstate'
import { validateEmailAddress } from "#services/validateFields"
import { OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { Grid, InputLabel, TextField, Typography } from '@mui/material'
import { format } from 'date-fns/esm'
import { ChangeEvent, FocusEvent } from 'react'
import { useTranslation } from 'react-i18next'

type PersonInputCommon = {
    label: string,
    additionalInfo?: string,
    visible: boolean,
    valid?: boolean,
    dataCy?: string
    helpText?: {
        description: string,
        supportArticleId: string,
    }
}

type PersonInputText = PersonInputCommon & {
    required: boolean,
    value: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
    inputType: "text" | "textarea" | "email",
    errorMessage: string,
}

type PersonInputDate = PersonInputCommon & {
    required: boolean,
    value: Date,
    onChange: (value: Date) => void,
    onBlur?: (e: FocusEvent) => void,
    inputType: "date",
    errorMessage: string,
}

type PersonReadOnly = PersonInputCommon & {
    value: string,
    inputType: "readOnly",
}

type PersonElement = PersonInputCommon & {
    element: JSX.Element,
    inputType: "element",
}

type PersonInput = PersonInputText | PersonInputDate | PersonReadOnly | PersonElement

export default function Contact({person, setUpdating} : {person: OrganisationalPersonDto, setUpdating: (value: boolean) => void}) {
    const {t} = useTranslation()
    const {updatePersonInfo} = useCommand()
    const depositor = useCurrentDepositor()
    const user = person?.associatedUserId && depositor?.users.find(user => user.id === person.associatedUserId)
    const isUser = person?.associatedUserId?.length > 0
    const isInCustomerDeclaration = PersonValidator.isCustomerDeclarationPerson(person)
    const needsInvite = PersonValidator.needsInvite(depositor, person)
    const isInvite = PersonValidator.isInvite(person)
    const isKeyPerson = PersonValidator.isKeyPerson(person)

    const firstName = useFieldState(person.firstName || '', ({value, isEditing, isSet}) => {
        if (!isSet) {
            return t('pages-organizations.personFormFirstNameMissing')
        }
    }, {validateInitially: true})

    const lastName = useFieldState(person.lastName || '', ({value, isEditing, isSet}) => {
        if (!isSet) {
            return t('pages-organizations.personFormLastNameMissing')
        }
    }, {validateInitially: true})

    const birthDate = useFieldState(person.birthDate ? new Date(person.birthDate) : null, ({value, isEditing, isSet}) => {
        if (!isSet && PersonValidator.isAuthorizationSigner(person)) {
            return t('pages-organizations.personFormBirthDateRequiredForAuthorizationSigners')
        }
        if (!isSet && (needsInvite || isInvite)) {
            return t('pages-organizations.personFormBirthDateRequiredForInvitations')
        }
        if (!isEditing) {
            if (isAfter(value, new Date())) {
                return t('components-DateInput.mustBeInPast')
            } else if (isBefore(value, new Date(1900, 0, 1))) {
                return t('components-DateInput.mustBeAfter1900')
            }
        }
    }, {validateInitially: true})

    const email = useFieldState(person.email || '', ({value, isEditing, isSet}) => {
        if (!isSet && (isUser || isInvite || needsInvite)) {
            return t('pages-organizations.personFormEmailMissing')
        }
        if (!isEditing && isSet && !validateEmailAddress(value)) {
            return t('pages-organizations.personFormEmailInvalid')
        }
    }, {validateInitially: true})

    const address = useFieldState(person.address || '', ({value, isEditing, isSet}) => {
        if (!isSet && isInCustomerDeclaration) {
            return t('pages-organizations.personFormMustBeGivenForCustomerDeclaration')
        }
    }, {validateInitially: true})

    const keyRole = useFieldState(person.keyPersonRole || '', ({value, isEditing, isSet}) => {
        if (!isSet && isKeyPerson) {
            return t('pages-organizations.boardOtherKeyRoleNotSpecified')
        }
    }, {validateInitially: true})

    async function updateGeneralInfo(updatedFields?: Partial<UpdatePersonInfo>) {
        const newFields = {
            firstName: firstName.value,
            lastName: lastName.value,
            birthDate: birthDate.value ? format(birthDate.value, 'yyyy-MM-dd') : null,
            email: email.value,
            address: address.value,
            keyPersonRole: keyRole.value,
            ...updatedFields
        }

        const fieldsHaveChanged = Object.keys(newFields).some(key => newFields[key] !== person[key])

        if (!fieldsHaveChanged) {
            return
        }

        setUpdating(true)
        const {waitForCommand} = await updatePersonInfo({
            depositorId: depositor?.id,
            personId: person.personId,
            ...newFields
        })
        await waitForCommand()
        setUpdating(false)
    }

    const personInformation: PersonInput[] = [
        {
            label: t('pages-organizations.personFormFirstName'),
            required: true,
            valid: firstName.valid,
            value: firstName.value,
            onChange: (e) => firstName.setValue(e.target.value),
            onBlur: () => {
                firstName.validate()
                if (firstName.valid) {
                    updateGeneralInfo()
                }
            },
            errorMessage: firstName.errorMessage,
            visible: !isUser && !isInvite,
            inputType: "text",
            dataCy: "firstNameField",
        },
        {
            label: t('pages-organizations.personFormLastName'),
            required: true,
            valid: lastName.valid,
            value: lastName.value,
            onChange: (e) => lastName.setValue(e.target.value),
            onBlur: () => {
                lastName.validate()
                if (lastName.valid) {
                    updateGeneralInfo()
                }
            },
            errorMessage: lastName.errorMessage,
            visible: !isUser && !isInvite,
            inputType: "text",
            dataCy: "lastNameField",
        },
        {
            label: t('pages-organizations.personFormDateOfBirth'),
            required: true,
            valid: birthDate.valid,
            value: birthDate.value,
            onChange: birthDate.setValue,
            onBlur: () => {
                birthDate.validate()
                if (birthDate.valid) {
                    updateGeneralInfo()
                }
            },
            errorMessage: birthDate.errorMessage,
            visible: !!(needsInvite || isInvite || !isUser),
            inputType: "date",
            dataCy: "birthDateField",
        },
        {
            label: t('pages-organizations.personBirthDate'),
            value: DateOutput.formatDate(person.birthDate),
            visible: !!(isUser && person.birthDate),
            inputType: "readOnly",
            valid: true,
            dataCy: "birthDateField",
        },
        {
            label: t('pages-organizations.personFormContactEmail'),
            required: true,
            value: email.value,
            valid: email.valid,
            onChange: (e) => email.setValue(e.target.value),
            onBlur: (e) => {
                email.validate()
                if (email.valid) {
                    updateGeneralInfo()
                }
            },
            errorMessage: email.errorMessage,
            inputType: "email",
            dataCy: "emailField",
            visible: true
        },
        {
            label: t('pages-organizations.personPhone'),
            value: formatPhoneNumber(user?.phone),
            valid: true,
            visible: !!user?.phone,
            inputType: "readOnly",
            dataCy: "phoneField",
        },
        {
            label: t('pages-organizations.personInfoKeyPersonRole'),
            required: true,
            valid: keyRole.valid,
            value: keyRole.value,
            onChange: (e) => keyRole.setValue(e.target.value),
            onBlur: (e) => {
                updateGeneralInfo()
            },
            errorMessage: keyRole.errorMessage,
            visible: isKeyPerson,
            inputType: "text",
            dataCy: "keyRoleField",
        },
        {
            label: t('pages-organizations.personAddress'),
            required: true,
            valid: address.valid,
            value: address.value,
            onChange: (e) => address.setValue(e.target.value),
            onBlur: (e) => {
                updateGeneralInfo()
            },
            errorMessage: address.errorMessage,
            visible: isInCustomerDeclaration,
            inputType: "textarea",
            dataCy: "addressField",
        },
    ]

    return (
        <Grid container gap={3}>
            { personInformation.filter(info => info.visible).map((input, index) => (
                <Grid item xs={12} md={5} key={index + input.label}>
                    <InputLabel sx={{whiteSpace: "unset"}}>
                        { input.helpText ? (
                            <HelpPopup text={input.helpText?.description} supportArticleId={input.helpText?.supportArticleId}>
                                {input.label}
                            </HelpPopup>
                        ) : input.label }
                    </InputLabel>
                    { input.additionalInfo && (
                        <Typography variant="caption">{ input.additionalInfo }</Typography>
                    )}
                    { input.inputType === "element" && (
                        input.element
                    )}
                    { input.inputType === "date" && (
                        <DateInput
                            value={input.value}
                            data-cy={input.dataCy}
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                        />
                    )}
                    { (input.inputType === "text" || input.inputType === "textarea" || input.inputType === "email") && (
                        <TextField
                            required
                            id={input.inputType === "email" ? "email" : ""}
                            sx={{width: "100%", maxWidth: "70rem"}}
                            type={input.inputType === "email" ? "email" : "text"}
                            value={input.value || ""}
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            data-cy={input.dataCy}
                            multiline={input.inputType === "textarea"}
                            rows={input.inputType === "textarea" ? 4 : 1}
                        />
                    )}
                    { input.inputType === "readOnly" && (
                        <TextField sx={{width: "100%", maxWidth: "70rem"}} variant="filled" disabled value={input.value} InputProps={{
                            readOnly: true,
                            }} />
                    )}
                    { (input.inputType !== "readOnly" && input.inputType !== "element") && (
                        <p className="field-error-message">
                            {input.errorMessage}
                        </p>
                    )}
                </Grid>
            ))}
        </Grid>
    );
}
