import config from '#app/config'
import { useCommand } from '#command'
import Button from '#components/Button'
import HelpPopup from '#components/HelpPopup'
import { useAuthorization } from '#services/authorization'
import { useFieldState } from '@fixrate/fieldstate'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { OrderDto, ProcessHistoryDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './OrdersDepositorReadyForTransaction.module.scss'
import { useCallback } from 'react'

const API_BASE_URL = config().apiUrl

export default function OrdersDepositorReadyForTransaction( {order }: { order: OrderDto & { lastHistoryAction?: ProcessHistoryDto } }) {

    const {t} = useTranslation()

    const {sendToBank} = useCommand()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const auth = useAuthorization(order.depositor.id)
    const hasPaymentRole = auth.depositor.hasPaymentRole || auth.depositor.hasPartnerAccountantRole
    const isLoaded = useSelector(state => state.loaded.documents)
    const documents = useSelector(state => selectors.documentIdToDocumentMapper(state)(order.documents))

    const confirmedValidator = useCallback(({value}) => {
        return !value && t('pages-orders.depositorYouMustConfirmTransaction')
    }, [t])
    const confirmed = useFieldState(false, confirmedValidator)

    async function onSubmit() {
        if (confirmed.validate()) {
            const {waitForCommand} = await sendToBank(order.id)
            const success = await waitForCommand()
            if (success) {
                navigate('/orders/transaction')
                dispatch(showConfirmationModal({
                    title: t('pages-orders.depositorConfirmTransactionModalTitle'),
                    text: t('pages-orders.depositorConfirmTransactionModalText'),
                    buttonText: t('common.continue'),
                }))
            }
        }
    }

    if (!isLoaded) return null

    const accountAgreement = documents.find(doc => doc.documentType === 'ACCOUNT_AGREEMENT')
    const orderDocument = documents.find(doc => doc.documentType === 'ORDER_DOCUMENT')

    const roleWarning = <p>{t('pages-orders.depositorConfirmTransactionWarning')}</p>

    const confirmationAction = (
        <dd>
            <label>
                <input name="confirmBox"
                       type="checkbox"
                       onChange={e => confirmed.setValue(e.target.checked)}
                       checked={confirmed.value}
                       onBlur={confirmed.onBlur}
                       disabled={!hasPaymentRole}
                />
                &nbsp;{t('pages-orders.depositorConfirmTransaction')}
                <p className="field-error-message">{confirmed.errorMessage}</p>
            </label>
        </dd>
    )

    return (
        <div>
            <dl className="actionframe__labeledinput">
                <dt className="actionframe__labeledinput-label">
                    <HelpPopup text={t('pages-orders.depositorConfirmTransactionPaymentHelp')}>
                        {t('pages-orders.depositorConfirmTransactionPayment')}
                    </HelpPopup>
                </dt>
                <dd>
                    <a className={styles.documentlink} href={`${API_BASE_URL}${accountAgreement.signedLink}`} target="_blank" rel="noopener noreferrer">
                        <i className="ri-file-text-line"></i>
                        <span>{accountAgreement.name} {t('pages-orders.depositorConfirmTransactionPostfix')}</span>
                    </a>
                    <a className={styles.documentlink} href={`${API_BASE_URL}${orderDocument.unsignedLink}`} target="_blank" rel="noopener noreferrer">
                        <i className="ri-file-text-line"></i>
                        <span>{orderDocument.name}</span>
                    </a>
                </dd>
                {hasPaymentRole ? confirmationAction : roleWarning}
            </dl>
            {hasPaymentRole && <Button id="confirmPaymentButton" onClick={onSubmit}>{t('common.confirm')}</Button>}
        </div>
    )
}
