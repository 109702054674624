import { useCommand } from '#command'
import ActionBlock from '#components/ActionBlock'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout'
import Paper from '#components/Paper'
import DepositorOfferTable from '#pages/DepositorOfferDepositor/DepositorOfferOverview/DepositorOfferTable'
import { isAfter, isBefore, sortDatesAscending } from '#services/dateandtime'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {Box, List, ListItem, ListItemText, Tooltip} from "@mui/material";
import {DateOutput, InterestOutput} from '#components'
import AsyncButton from '#app/components/Button/AsyncButton'
import { DepositorOfferDto } from '@fixrate/fixrate-query'
import {defaultBenchmark} from '#services/interestRateBenchmark'

export default function DepositorOfferOverview() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const depositorOffers = useSelector(state => state.depositorOffers)
    const {createDepositorOffer: createDepositorOfferCommand} = useCommand()
    const titles = useDepositorOfferTitles(depositorOffers)
    const users = useSelector(state => state.depositor?.users)

    if (!users) {
        return null
    }


    async function createDepositorOffer() {
        const newDepositorOfferId = uuidv4()
        const {waitForCommand} = await createDepositorOfferCommand(newDepositorOfferId)
        const success = await waitForCommand()
        if (success) {
            navigate(`/depositoroffer/${newDepositorOfferId}/edit/0`)
        }
    }

    function onSelect(depositorOfferId: string) {
        const depositorOffer = depositorOffers.find(offer => offer.depositorOfferId === depositorOfferId)
        if (depositorOffer) {
            if (depositorOffer.published) {
                navigate(`/depositoroffer/${depositorOfferId}`)
            } else {
                navigate(`/depositoroffer/${depositorOfferId}/edit/0`)
            }
        }
    }

    const sortedDepositorOffers = depositorOffers.slice().sort((d1, d2) => sortDatesAscending(d1.createdAt, d2.createdAt))

    const now = new Date()
    const activeDepositorOffers = sortedDepositorOffers.filter(d => d.published && d.deadline && isAfter(d.deadline, now))
    const inactiveDepositorOffers = sortedDepositorOffers.filter(d => d.published && d.deadline && isBefore(d.deadline, now))
    const editableDepositorOffers = sortedDepositorOffers.filter(d=>!d.published)

    const createButtonText = t('pages-offer.createNewOffer')

    return (
        <>
            <PageHeader icon="ri-mail-send-line" title={t('pages-offer.offerToBankHeading')}/>
            <PageLayout>
                <ActionBlock header={t('pages-offer.newOfferHeading')} size={'large'}>
                    <p>
                        {t('pages-offer.newOfferMessagePart1')}
                    </p>
                    <p>
                        {t('pages-offer.newOfferMessagePart2')}
                    </p>
                    <Box width={"100%"} overflow="auto">
                        <List>
                            { editableDepositorOffers.map((offer) => {
                                const user = users.find(u => u.id === offer.createdBy)
                                const title = titles[offer.depositorOfferId]
                                return (
                                    <ListItem key={offer.depositorOfferId} secondaryAction={<AsyncButton variant={"outlined"} onClick={() => onSelect(offer.depositorOfferId)}>{t('pages-offer.continueEditingOffer')}</AsyncButton>}>
                                        <Tooltip title={title}>
                                            <ListItemText
                                                primary={title}
                                                secondary={t('pages-offer.offerCreatedBy', {name: `${user?.firstName} ${user?.lastName}`}) + ' ' + DateOutput.formatDateTime(offer.createdAt)}/>
                                        </Tooltip>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                    <ButtonRow align="left">
                        <AsyncButton id="createDepositorOffer" onClick={createDepositorOffer}>{createButtonText}</AsyncButton>
                    </ButtonRow>

                </ActionBlock>
                <Paper title={t('pages-offer.activeOffersHeading')} size={'large'}>
                    <DepositorOfferTable depositorOffers={activeDepositorOffers} emptyTableText={t('pages-offer.noActiveOffers')} onSelect={onSelect}/>
                </Paper>
                <Paper title={t('pages-offer.previousOffersHeading')} size={'large'}>
                    <DepositorOfferTable depositorOffers={inactiveDepositorOffers} emptyTableText={t('pages-offer.noPreviousOffers')} onSelect={onSelect}/>
                </Paper>
            </PageLayout>
        </>
    )
}

function useDepositorOfferTitles(offers: DepositorOfferDto[]) {
    const {t} = useTranslation()
    const banks = useSelector(state => state.banks)
    const products = useSelector(state => state.products)
    const titles = {}
    if (!banks || Object.keys(banks).length === 0) {
        return {}
    }

    offers.forEach(offer => {
        const title: string[] = []
        if (offer.banks?.length === 1) {
            title.push(banks[offer.banks[0].bankId].shortName || banks[offer.banks[0].bankId].name)
        }
        if (offer.banks?.length > 1) {
            title.push(t('pages-offer.banks', {count: offer.numberOfBanks}))
        }
        if (offer.totalVolume) {
            title.push(`${offer.totalVolume} ${t('pages-offer.mill')}`)
        }
        if (offer.interestRate) {
            if (offer.productId && products[offer.productId].type !== 'FIXED') {
                title.push(InterestOutput.formatMarginWithBenchmark(offer.interestRate, defaultBenchmark(offer.currency), t))
            } else {
                title.push(InterestOutput.format(offer.interestRate))
            }
        }
        if (title.length === 0) {
            title.push(t('pages-offer.newOfferHeading'))
        }
        titles[offer.depositorOfferId] = title.join(', ')
    })
    return titles
}
