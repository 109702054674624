export default function uuidToColor(uuid: string): string {
    let hash = 0
    for (let i = 0; i < uuid.length; i++) {
        hash = uuid.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF
        const colorString = ('00' + value.toString(16))
        color += colorString.substring(colorString.length - 2)
    }
    return color
}