import {useState} from 'react'
import {useCommand} from '#command'
import {useTranslation} from 'react-i18next'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material'
import {useFieldState} from '@fixrate/fieldstate'
import {DepositorDto} from '@fixrate/fixrate-query'
import {showConfirmationModal} from '#state/reducers/confirmationModal'
import {useDispatch} from 'react-redux'
import {LoadingButton} from '@mui/lab'

export type EscaliProps = {
    depositor: DepositorDto,
    onClose: () => void
}
export default function EscaliConfigurationDialog({ depositor, onClose }: EscaliProps) {
    const {t} = useTranslation()
    const {updateDepositorEscaliConfiguration} = useCommand()
    const dispatch = useDispatch()

    const [submitting, setSubmitting] = useState(false)

    const [integrationEnabled, setIntegrationEnabled] = useState(depositor.escaliEnabled)
    const containerField = useFieldState(depositor.escaliContainer, ({value, isEditing}) => {
        if (!value && !isEditing) {
            return t(`common.mandatoryField`)
        }
        return ''
    })
    const clientIdField = useFieldState(depositor.escaliClientId, ({value, isEditing}) => {
        if (!value && !isEditing) {
            return t(`common.mandatoryField`)
        }
        if (!(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(value))) {
            return t(`pages-organizations.invalidField`)
        }
        return ''
    })
    const [editSecret, setEditSecret] = useState(false)
    const secretField = useFieldState('', ({value, isEditing}) => {
        if (!value && !isEditing && editSecret) {
            return t(`common.mandatoryField`)
        }
        return ''
    })

    async function handleSubmit() {

        if (!containerField.validate() || !clientIdField.validate() || !secretField.validate()) {
            return
        }

        setSubmitting(true)
        try {
            const secret = editSecret ? secretField.value : null
            const {waitForCommand} = await updateDepositorEscaliConfiguration(depositor.id, integrationEnabled, containerField.value, clientIdField.value, editSecret, secret)
            const success = await waitForCommand()
            if (success) {
                dispatch(showConfirmationModal({
                    title: t(`pages-organizations.escaliIntegrationConfirmationModalTitle`),
                    text: t(`pages-organizations.escaliIntegrationConfirmationModalText`),
                    buttonText: t('common.continue'),
                }))
                onClose()
            }
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>{t(`pages-organizations.configureEscaliIntegrationHeading`)}</DialogTitle>
            <DialogContent>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 2}}>
                    <FormControlLabel
                        control={
                            <Switch
                                id={'escaliEnabledSwitch'}
                                checked={integrationEnabled}
                                onChange={(e) => {setIntegrationEnabled(e.target.checked)}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t(`pages-organizations.escaliEnabled`)}
                    />
                    <TextField
                        id={'escaliContainerField'}
                        label={t(`pages-organizations.escaliContainer`)}
                        value={containerField.value}
                        onChange={e => containerField.setValue(e.target.value)}
                        onBlur={() => containerField.onBlur}
                        error={!containerField.valid}
                    />
                    <TextField
                        id={'escaliClientIdField'}
                        label={t(`pages-organizations.escaliClientId`)}
                        value={clientIdField.value}
                        onChange={e => clientIdField.setValue(e.target.value)}
                        onBlur={() => clientIdField.onBlur}
                        error={!clientIdField.valid}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id={'escaliEditSecretSwitch'}
                                checked={editSecret}
                                onChange={(e) => {setEditSecret(e.target.checked)}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t(`pages-organizations.escaliEditSecret`)}
                    />
                    {editSecret &&
                        <TextField
                            id={'escaliSecretField'}
                            label={t(`pages-organizations.escaliSecret`)}
                            value={secretField.value}
                            onChange={e => secretField.setValue(e.target.value)}
                            onBlur={() => secretField.onBlur}
                            error={!secretField.valid}
                        />
                    }
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    id={'escaliCancelButton'}
                    onClick={onClose}
                >
                    {t(`pages-organizations.escaliCancel`)}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    disabled={submitting}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    {t(`pages-organizations.escaliSave`)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

