import { copyTextToClipboard } from '#app/components/ClipboardCopyButton/ClipboardCopyButton'
import { Button } from '@mui/material'
import { useState } from 'react'

export default function CopyLink({ link }: Readonly<{ link: string }>) {
    const [linkCopied, setLinkCopied] = useState(false)
    const copyLink = (link: string) => {
        copyTextToClipboard(link)
            .then(() => {
                setLinkCopied(true)
                setTimeout(() => setLinkCopied(false), 3000)
            })
            .catch(console.error)
    }
    return (
        <Button size='small' variant='outlined' startIcon={<i className={linkCopied ? 'ri-check-line' : 'ri-link'} />} onClick={() => copyLink(link)}>
            {linkCopied ? 'Kopiert!' : 'Tilbudslenke'}
        </Button>
    )
}
