import { SPRING_GREEN, PURPLE } from '#app/colors/colors'
import { FullySubscribedAd } from '../BankDashboard/components/FullySubscribedAds'
import { DateOutput,  InterestOutput } from '#app/components'
import { Typography, Grid, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NewOfferDto } from './PricingSteps'
import { AdDto, BankLimitedDto } from '@fixrate/fixrate-query'
import { AllOrders } from './NewOfferAccordion'

type Props = {
    offer: NewOfferDto
    banks: { [key: string]: BankLimitedDto }
    allOrders: AllOrders[]
    adsSameCategory: AdDto[]
    fullySubscribedAd: FullySubscribedAd[]
    products: { [key: string]: { days: number; type: string } }
}

export default function NewAdMarketplaceData({ offer, banks, allOrders, adsSameCategory, fullySubscribedAd, products }: Readonly<Props>) {
    const { t } = useTranslation()

    const calculationOfInterest = (ad: AdDto | FullySubscribedAd) => {
        const product = products[ad.productId]
        if (product.type === 'FIXED') {
            return `${t('pages-marketplace.fixedInterest')}: ${InterestOutput.format(ad.interest)}`
        }
        if (ad.interestRateBenchmark === 'NIBOR_3M') {
            if ('fullySubscribed' in ad) {
                return (
                    <span>
                        <Typography sx={{ color: PURPLE[800] }} variant={'body2'} display={'inline-flex'}>
                            {`${products[ad.productId].days} ${t('pages-marketplace.days')}, ${t('common.interestRateBenchmark.' + ad.interestRateBenchmark)}`}
                        </Typography>
                        <Typography sx={{ color: PURPLE[800] }} variant={'body2'} display={'inline-flex'} mx={0.2}>
                            {ad.interest > 0 ? '+' : '-'}
                        </Typography>
                        <Typography sx={{ color: PURPLE[800] }} variant={'body2'} display={'inline-flex'}>
                            {InterestOutput.format(ad.interest).replace('-', '')}
                        </Typography>
                    </span>
                )
            } else {
                return (
                    <span>
                        <Typography sx={{ color: PURPLE[800] }} variant={'body2'} display={'inline-flex'}>
                            {`${products[ad.productId].days} ${t('pages-marketplace.days')}, ${ad.interest}% (${t('common.interestRateBenchmark.' + ad.interestRateBenchmark)}`}
                        </Typography>
                        <Typography sx={{ color: PURPLE[800] }} variant={'body2'} display={'inline-flex'} mx={0.2}>
                            {ad.interest > 0 ? '+' : '-'}
                        </Typography>
                        <Typography sx={{ color: PURPLE[800] }} variant={'body2'} display={'inline-flex'}>
                            {InterestOutput.format(ad.nominalInterestRate).replace('-', '') + ')'}
                        </Typography>
                    </span>
                )
            }
        } else {
            return `${t('common.interestRateBenchmark.' + ad.interestRateBenchmark)} + ${InterestOutput.format(ad.interest)}`
        }
    }

    const productIds = products ? Object.keys(products) : []

    const averageVolume = (adsSameCategory.reduce((acc, ad) => acc + ad.volume, 0) / adsSameCategory.length).toFixed(0)

    const highestInterestAd = adsSameCategory.sort((a, b) => b.interest - a.interest)[0]
    const lastOrderHighestInterestAd = highestInterestAd ? allOrders.filter(order => order.adId === highestInterestAd.id).sort((a, b) => b.created.localeCompare(a.created))[0] : undefined
    const numberOfOrders = allOrders.filter(order => order.adId === highestInterestAd?.id).length

    const lastFullySubscribedAds = fullySubscribedAd.filter(ad => productIds.includes(ad.productId)).filter(ad => ad.min >= offer.min && ad.max <= offer.max).sort((a, b) => b.fullySubscribed.localeCompare(a.fullySubscribed))[0]
    let daysUntilFullySubscribed = 0

    if (lastFullySubscribedAds) {
        const fullySubscribedDate = new Date(lastFullySubscribedAds.fullySubscribed).getTime()
        const publishedDate = new Date(lastFullySubscribedAds.published).getTime()

        const diffTime = Math.abs(fullySubscribedDate - publishedDate)
        daysUntilFullySubscribed = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    }

    return (
        <>
            <Typography
                variant={'h3'}
                sx={{
                    mt: '4rem',
                    color: PURPLE[800],
                }}
            >
                {t('pages-BankAds.priceInfo')}
            </Typography>
            <Grid container spacing={3} sx={{ pt: '1rem', height: '20rem' }}>
                <Grid item xs={4}>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            height: '18rem',
                            borderRadius: '0.6rem',
                            boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{
                                p: '1rem 1.6rem',
                                color: SPRING_GREEN[200],
                                backgroundColor: PURPLE[800],
                                borderTopLeftRadius: '0.6rem',
                                borderTopRightRadius: '0.6rem',
                            }}
                        >
                            {t('pages-BankAds.bestOfferCategory')}
                        </Typography>
                        <Typography sx={{ p: '1rem 1.6rem' }}>
                            {highestInterestAd ? (
                                <>
                                    <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {banks[highestInterestAd.bankId].name}
                                    </Typography>
                                    <Typography variant={'body2'} sx={{ width: 'fit-content', color: PURPLE[800], px: '0.5rem', backgroundColor: SPRING_GREEN[100], borderRadius: '0.5rem' }}>
                                        {calculationOfInterest(highestInterestAd)}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {t('pages-BankAds.published')}
                                        <DateOutput.Date date={highestInterestAd.published} />
                                    </Typography>{' '}
                                    {lastOrderHighestInterestAd && (
                                        <><Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                            {t('pages-BankAds.dateLastOrder')} <DateOutput.Date date={lastOrderHighestInterestAd.created} />
                                        </Typography><Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                                {t('pages-BankAds.numberOfOrders')} {numberOfOrders}
                                            </Typography></>
                                    )}
                                    <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {t('pages-BankAds.remainingVolume')} {(((highestInterestAd.volume - highestInterestAd.remaining) / highestInterestAd.volume) * 100).toFixed(2)}%
                                    </Typography>
                                </>
                            ) : (
                                <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                    {t('pages-marketplace.noAdsInCategory')}
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4} sx={{ height: '20rem' }}>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            height: '18rem',
                            borderRadius: '0.6rem',
                            boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{
                                p: '1rem 1.6rem',
                                color: SPRING_GREEN[200],
                                backgroundColor: PURPLE[800],
                                borderTopLeftRadius: '0.6rem',
                                borderTopRightRadius: '0.6rem',
                            }}
                        >
                            {t('pages-BankAds.lastFullySubscribedAd')}
                        </Typography>
                        <Typography sx={{ p: '1rem 1.6rem', color: PURPLE[800] }}>
                            {lastFullySubscribedAds ? (
                                <>
                                    <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {banks[lastFullySubscribedAds.bankId].name},
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {calculationOfInterest(lastFullySubscribedAds)}
                                    </Typography>
                                    <Typography display={'inline-flex'} sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {t('pages-BankAds.daysFullySubscribed')}
                                    </Typography>
                                    <Typography
                                        display={'inline-flex'}
                                        variant={'body2'}
                                        sx={{ width: 'fit-content', color: PURPLE[800], px: '0.5rem', backgroundColor: SPRING_GREEN[100], borderRadius: '0.5rem' }}
                                    >
                                        {`${daysUntilFullySubscribed} ${t('pages-marketplace.days')}`}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                        {t('pages-BankAds.dateFullySubscribed')}
                                        <DateOutput.Date date={lastFullySubscribedAds.fullySubscribed} />
                                    </Typography>
                                </>
                            ) : (
                                <Typography sx={{ color: PURPLE[800] }} variant={'body2'}>
                                    {t('pages-marketplace.noFullySubscribedAds')}
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            height: '18rem',
                            borderRadius: '0.6rem',
                            boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{
                                p: '1rem 1.6rem',
                                color: SPRING_GREEN[200],
                                backgroundColor: PURPLE[800],
                                borderTopLeftRadius: '0.6rem',
                                borderTopRightRadius: '0.6rem',
                            }}
                        >
                            {t('pages-BankAds.averageVolumeCategory')}
                        </Typography>
                        <Typography variant={'body2'} sx={{ p: '1rem 1.6rem', color: PURPLE[800] }}>
                            {t('pages-BankAds.averageVolume')}

                            {averageVolume !== 'NaN' ? (
                                <Typography variant={'body2'} sx={{ width: 'fit-content', px: '0.5rem', color: PURPLE[800], backgroundColor: SPRING_GREEN[100], borderRadius: '0.5rem' }}>
                                    {averageVolume} {t('pages-marketplace.millions')}
                                </Typography>
                            ) : (
                                <Typography variant={'body2'} sx={{ color: PURPLE[800] }}>
                                    {t('pages-marketplace.noAdsInCategory')}
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
