import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { getIconText } from '#app/components/PageHeader/PageHeader'
import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { InterestOutput } from '#components'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { formatOrganizationNumber } from '#services/formatnumber'
import { useSelector } from '#state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Alert, Avatar, Button, Divider, Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomerInfo.module.scss'


type Params = {
    depositorId?: string
}

export default function CustomerDetailInfo() {
    const {t} = useTranslation()
    const params = useParams<Params>()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Currency = useCurrencyOutput()
    const organisationCountry = useCurrentCountryCode()

    const session = useSelector(state => state.session)
    const customer = useSelector((state => state.partner?.customers.find(c => c.depositorId === params.depositorId)))
    const canAccessCustomerAccount = session.associations.find(a => a.organisation?.id === params.depositorId)

    function selectRole(organisationType: OrganisationType, orgId: string) {
        dispatch(switchOrganisation(organisationType, orgId, () => navigate('/organizations')))
    }

    if (!customer) {
        return null
    }

    return (
        <Paper sx={{px: 4, py: 3}}>
            {customer.accepted == null && (
                <Alert icon={<i className="ri-mail-line"/>} severity="info" sx={{mb: 2}}>
                    {t('pages-customers.hasNotYetAccepted')}
                </Alert>
            )}
            {customer.accepted === false && (
                <Alert icon={<i className="ri-close-line"/>} severity="error" sx={{mb: 2}}>
                    {t('pages-customers.declinedRequest')}
                </Alert>
            )}
            <Stack spacing={2} divider={<Divider/>}>
                <Stack direction={{xs: 'column-reverse', md: 'row'}} spacing={2} justifyContent="space-between" alignItems="flex-start">
                    <Stack spacing={2} direction={'row'} alignItems="flex-start">
                        <Avatar sx={{width: '8rem', height: '8rem', fontSize: '2.6rem'}}>{getIconText(customer.name)}</Avatar>
                        <Stack>
                            <h3 className={styles.customerName}>{customer.name}</h3>
                            <p className={styles.orgNumber}>
                                <i className="ri-building-line"/>
                                <span>{formatOrganizationNumber(customer.nationalIdentity, organisationCountry)}</span>
                            </p>
                        </Stack>
                    </Stack>
                    {customer.accepted && (
                        <Button data-cy="logInAsCustomerButton" disabled={!canAccessCustomerAccount} endIcon={<i className="ri-arrow-right-line"/>} variant="contained" size="small" onClick={() => selectRole('DEPOSITOR', customer.depositorId)}>
                            {t('pages-customers.logInAsCustomer')}
                        </Button>
                    )}
                </Stack>
                {customer.accepted && (
                    <Stack direction="row" flexWrap={'wrap'} alignItems="center" justifyContent={'space-between'} spacing={2}>
                        <Stack spacing={0.5} maxWidth={'30rem'}>
                            <p className={styles.customerKeyPointLabel}>{t('pages-customers.averageInterestRate')}</p>
                            {customer.interestRate && <p className={styles.customerKeyPointValue}>{InterestOutput.format(customer.interestRate || 0)}</p>}
                            {!customer.interestRate && <p className={styles.customerKeyPointEmptyValue}>{t('pages-customers.noDeposits')}</p>}
                        </Stack>
                        <Stack spacing={0.5} maxWidth={'30rem'}>
                            <p className={styles.customerKeyPointLabel}>{t('pages-customers.calculatedBalance')}</p>
                            {customer.calculatedBalance && <p className={styles.customerKeyPointValue}>{Currency(customer.calculatedBalance || 0)}</p>}
                            {!customer.calculatedBalance && <p className={styles.customerKeyPointEmptyValue}>{t('pages-customers.noDeposits')}</p>}
                        </Stack>
                        <Stack spacing={0.5} flexWrap={'wrap'} maxWidth={'30rem'}>
                            <p className={styles.customerKeyPointLabel}>{t('pages-customers.status')}</p>
                            {customer.warnings.length > 0 ? (
                                <Alert
                                    severity={'error'}
                                    sx={{'& .MuiAlert-icon': {mr: "0.5rem", fontSize: "1.8rem"}, py: 0, px: 1}}>{t('pages-customers.numberOfWarnings', {count: customer.warnings.length})}</Alert>
                            ) : (
                                <Alert
                                    severity={'success'}
                                    sx={{'& .MuiAlert-icon': {mr: "0.5rem", fontSize: "1.8rem"}, py: 0, px: 1}}>{t('pages-customers.allGood')}</Alert>
                            )}
                        </Stack>
                    </Stack>
                )}
                {customer.contactName && (
                    <Stack direction={{md: "row"}} alignItems={{md: "center"}} justifyContent="space-between">
                        <LabeledInfo label={t('pages-customers.customerDetailInfoContactPerson')} info={customer.contactName || '-'}/>
                        <LabeledInfo label={t('pages-customers.customerDetailInfoEmailAddress')} info={customer.contactEmail || '-'}/>
                        <LabeledInfo label={t('pages-customers.customerDetailInfoPhone')} info={customer.contactPhone || '-'}/>
                    </Stack>
                )}
            </Stack>
        </Paper>
    )
}
