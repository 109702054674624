export const apiRequest = (resourceUrl: string): Promise<Response> => {
    const url = '/api/' + resourceUrl
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    const options = {
        method: 'GET',
        headers: headers,
        body: null
    };

    return fetch(url, options)
}

export default apiRequest
