import AsyncButton from '#app/components/Button/AsyncButton';
import {Paper} from '#components'
import { useTranslation } from 'react-i18next';

const DeleteAuthorization = function({
    deleteAuthorizationDocument,
}: { deleteAuthorizationDocument: () => void }) {

    const { t } = useTranslation()

    return (
        <Paper title={t('pages-organizations.deletePowerOfAttorneyHeading')}>
            <p>
                {t('pages-organizations.deletePowerOfAttorneyMessage')}
            </p>
            <AsyncButton
                variant="outlined"
                color="error"
                onClick={deleteAuthorizationDocument}
            >
                {t('pages-organizations.delete')}
            </AsyncButton>
        </Paper>
    )
}

export default DeleteAuthorization

