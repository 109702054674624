import classNames from 'classnames'
import ProcessInformation from './ProcessInformation'
import styles from './DepositList.module.scss'
import {TerminationState} from '#state/selectors'
import {CurrencyOutput, DateOutput, InterestOutput} from '#components'
import {useTranslation} from '#components/i18n'
import {DepositDto} from '@fixrate/fixrate-query'
import subDays from 'date-fns/subDays'
import isAfter from 'date-fns/isAfter'
import {formatAccount} from "#services/formatnumber"
import getDepositValue from '#services/getDepositValue'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'


export const isOver180DaysOld = (deposit: DepositDto): boolean => deposit.terminationDate ? isAfter(subDays(new Date(), 180), new Date(deposit.terminationDate)) : false

const ExpiresField = ({deposit}:{deposit:DepositDto}) => {
    if (deposit.expires) {
        return <DateOutput.Date date={deposit.expires.date}/>
    } else if (deposit.product.days) {
        return <span/>
    }
}

type Props = {
    deposit: DepositDto
    onSelectDeposit: (depositId: string) => void
    expiresSoon?: boolean
    expired?: boolean
    bankNames: { [bankId: string]: string }
    archived?: boolean
    showTags: boolean
}

export default function Deposit(props: Props) {
    const {deposit, onSelectDeposit, expiresSoon, expired, bankNames, archived, showTags} = props

    const {t} = useTranslation('pages-portfolio-depositor')
    const organisationCountry = useCurrentCountryCode()

    const balance = getDepositValue(deposit)

    const productBadgeClasses = classNames(styles.elementProductBadge, expired && styles.elementProductBadgeExpired)
    const interestBadgeClasses = classNames(styles.elementInterestBadge, expired && styles.elementInterestBadgeExpired)
    const expiresClasses = classNames(styles.elementExpires, (expiresSoon || expired) && styles.elementExpiresWarn)
    const tagBadgeClasses = classNames(styles.elementTagBadge, !deposit.tagText && styles.elementEmptytag)

    const actionRequired = deposit.extensionOfferState === 'READY_FOR_SIGNING' || TerminationState.STARTED === deposit.terminationState

    const bankName = bankNames[deposit.bankId]

    return (bankName ? (
        <li className={classNames(styles.element, archived && styles.elementArchived, actionRequired && styles.elementAction, actionRequired && 'cyActionRequired', isOver180DaysOld(deposit) && styles.old)}
            data-cy="portfolioListItem" onClick={() => onSelectDeposit(deposit.id)}>
            <ul className={classNames(styles.elementInfo, showTags && styles.elementInfoWithtags)}>
                <li className={styles.elementBankName} data-cy="bankNameItem">
                    <span className={styles.name}>{bankName}</span>
                    <span>{formatAccount(deposit.account, organisationCountry)}</span>
                </li>
                <li className={styles.elementInterest}>
                    <span className={interestBadgeClasses}>{InterestOutput.format(deposit.effectiveInterestRate)}</span>
                </li>
                <li className={styles.elementInterest}>
                    {deposit.product.type !== 'FIXED' && (
                        <span className={interestBadgeClasses}>{InterestOutput.format(deposit.nominalInterestRate)}</span>
                    )}
                </li>
                <li className={styles.elementProduct}>
                    {deposit.product ? <span className={productBadgeClasses}>{t('common.productShortName' + deposit.product.id)}</span> : null}
                </li>
                {showTags && (
                    <li className={styles.elementTag}>
                        <span className={tagBadgeClasses} style={{backgroundColor: deposit.tagColor ? deposit.tagColor : 'white'}}>
                            {deposit.tagText}
                        </span>
                    </li>
                )}
                <li className={styles.elementVolume}>
                    {CurrencyOutput.formatNoCode(balance, 0)}
                </li>
                <li className={expiresClasses}>
                    <ExpiresField deposit={deposit}/>
                </li>
            </ul>
            <ProcessInformation deposit={deposit} showTags={showTags}/>
        </li>
    ) : (
        <div className={styles.placeholder} style={{minHeight: '30px', minWidth: '90rem', maxWidth: '90rem', padding: '0.80rem', background: 'white', margin: '2.5px', borderRadius: '4px'}}/>
    ))
}
