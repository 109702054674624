import { useState } from 'react';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@mui/material'
import * as selectors from '#state/selectors'
import useTableSort, {Column} from '#services/useTableSort'
import {visuallyHidden} from '@mui/utils'
import {FundSellOrderDto} from '@fixrate/fixrate-query'
import {useSelector} from '#state/useSelector'
import {CurrencyOutput} from '#components'
import uuidToColor from '#services/uuidToColor'
import DateOutput from '#components/DateOutput'
import DocumentLink from '#components/DocumentLink'
import RegisterInFaDialog from '#pages/FundSellOrderOverview/RegisterInFaDialog'
import UpdateStatusDialog from '#pages/FundSellOrderOverview/UpdateStatusDialog'
import EditSharp from '@mui/icons-material/EditSharp'
import {fundSellOrderStateTMap} from "#services/enumTranslationKeyMapping";
import {useTranslation} from "react-i18next";

const EditIcon = EditSharp

export type FundSellOrderRow = FundSellOrderDto & {
    fundName: string
    depositorName: string
}

type Props = {
    fundSellOrders: FundSellOrderDto[]
}

export default function FundSellOrderTable({fundSellOrders}: Props) {
    const {t} = useTranslation()
    const [fundSellOrderToRegister, setFundSellOrderToRegister] = useState<FundSellOrderRow | null>(null)
    const [fundSellOrderToSetStatus, setFundSellOrderToSetStatus] = useState<FundSellOrderRow | null>(null)

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)

    const fundNameMap = useSelector(state => Object.assign({}, ...state.funds.flatMap(fund => fund.fundShareClasses.map(sc => ({[sc.id]: `${sc.isin} : ${sc.fullName}`})))))
    const depositorNames = useSelector(state => state.depositorNames)
    const lookupDocument = useSelector(selectors.lookupDocument)

    const rows: FundSellOrderRow[] = fundSellOrders.map(fundSellOrder => ({
        ...fundSellOrder,
        fundName: fundNameMap[fundSellOrder.fundShareClassId] || '',
        depositorName: depositorNames[fundSellOrder.depositorId] || '',
    }))

    const columns: Column<FundSellOrderRow>[] = [
        {id: 'fundName', label: t('pages.fundOverview.table.fund')},
        {id: 'depositorName', label: t('pages.fundOverview.table.depositor')},
        {id: 'unitQuantity', label: t('pages.fundOverview.table.unitQuantity'), align: 'right'},
        {id: 'created', label: t('pages.fundOverview.table.order')},
        {id: 'registeredInFa', label: t('pages.fundOverview.table.registeredInFA')},
        {id: 'state', label: t('pages.fundOverview.table.status')},
    ]

    const {sortedRows, sortKey, sortDirection, setSorting} = useTableSort<FundSellOrderRow>(rows, 'created', 'desc')

    return (
        <TableContainer>
            <RegisterInFaDialog fundSellOrderRow={fundSellOrderToRegister} onClose={() => setFundSellOrderToRegister(null)}/>
            <UpdateStatusDialog fundSellOrderRow={fundSellOrderToSetStatus} onClose={() => setFundSellOrderToSetStatus(null)}/>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} sortDirection={sortKey === column.id ? sortDirection : false} align={column.align}>
                                <TableSortLabel
                                    active={sortKey === column.id}
                                    direction={sortKey === column.id ? (sortDirection === 'asc' ? 'asc' : 'desc') : 'asc'}
                                    onClick={() => setSorting(column.id)}
                                >
                                    {column.label}
                                    {sortKey === column.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: FundSellOrderRow) => (
                        <TableRow data-cy="sellOrderItem" key={row.id}>
                            <TableCell>{row.fundName}</TableCell>
                            <TableCell>{row.depositorName}</TableCell>
                            <TableCell sx={{whiteSpace: 'nowrap'}} align={'right'}>
                                {CurrencyOutput.formatNoCode(row.unitQuantity,4)}
                            </TableCell>
                            <TableCell>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{
                                        backgroundImage: `linear-gradient(-45deg, ${uuidToColor(row.orderGroupId)}, ${uuidToColor(row.documentId)})`,
                                        height: '1.2rem',
                                        aspectRatio: '1/1',
                                        borderRadius: '100%'
                                    }}/>
                                    <DocumentLink link={lookupDocument(row.documentId)?.link}
                                                  name={DateOutput.formatDateTime(row.created)}/>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span>{row.registeredInFa ? `✅ ${DateOutput.formatDateTime(row.registeredInFa)}` : '❌'}</span>
                                    {(!row.registeredInFa && row.state !== 'CANCELLED') &&
                                        <IconButton data-cy="registerInFaButton" size={'small'} sx={{ml: 1}}
                                                    onClick={() => setFundSellOrderToRegister(row)}><EditIcon/></IconButton>}
                                </div>
                            </TableCell>
                            <TableCell sx={{whiteSpace: 'nowrap'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '0.3rem'}}>
                                            {[...new Array(5)].map((_, i) => (
                                                <div key={i} style={{
                                                    backgroundColor: i <= {
                                                        AWAITING_SIGNATURE: 0,
                                                        IN_PROGRESS: 1,
                                                        EXECUTING_IN_MARKET: 2,
                                                        TRANSFERRING_MONEY: 3,
                                                        COMPLETED: 4,
                                                        CANCELLED: 4,
                                                    }[row.state] ? `${row.state === 'CANCELLED' ? '#d3a3a3' : '#000'}` : '#e0e0e0',
                                                    height: '.5rem',
                                                    width: '.5rem',
                                                    borderRadius: '100%',
                                                    marginRight: '.5rem',
                                                }}/>
                                            ))}
                                        </div>
                                        <span>{t(fundSellOrderStateTMap[row.state])}</span>
                                    </div>
                                    <IconButton data-cy="editStatusButton" size={'small'} sx={{ml: 1}}
                                                onClick={() => setFundSellOrderToSetStatus(row)}><EditIcon/></IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                labelRowsPerPage={t('pages.fundOverview.table.ordersPerPage')}
                labelDisplayedRows={({
                                         from,
                                         to,
                                         count
                                     }) => `${from}-${to === -1 ? count : to} ${t('common.pagination.of')} ${count !== -1 ? count : `${t('common.pagination.moreThan')} ${to}`}`}
                rowsPerPageOptions={[100, 250, 500]}
                component="div"
                count={rows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={(event, page) => setPage(page)}
                onRowsPerPageChange={event => {
                    setRowsPerPage(+event.target.value)
                    setPage(0)
                }}
            />
        </TableContainer>
    )
}
