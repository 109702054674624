import { useEffect, useState } from 'react';
import {useSelector} from '#state/useSelector'
import styles from './TimeoutWarning.module.scss'
import {useTranslation} from "#components/i18n";

const TimeoutWarning = () => {
    const { t } = useTranslation('layers-TimeoutWarning')

    const [timeoutCounter, setTimeoutCounter] = useState(-1)
    const timeoutTimestamp = useSelector(({ timeoutCountdown }) => timeoutCountdown)

    useEffect(()=>{

        const countDown = () => {
            const timeUntilTimeout = timeoutTimestamp - Date.now()
            if (timeUntilTimeout < 60 * 1000) {
                setTimeoutCounter(Math.ceil((timeUntilTimeout) / 1000))
            } else {
                setTimeoutCounter(-1)
            }
        }

        let intervalHandler: number
        countDown()
        if(timeoutTimestamp > 0){
            intervalHandler = window.setInterval(countDown,1000);
        }
        return ()=>clearInterval(intervalHandler)
    },[timeoutTimestamp])


    return timeoutCounter >= 0 ? (
        <div className={styles.timeoutwarningContainer}>
            <div className={styles.timeoutwarning}>
                <h1>{t('inactiveHeading')}</h1>
                <div>{t('inactiveMessage', {count: timeoutCounter})}</div>
            </div>
        </div>
    ) : null
}

export default TimeoutWarning
