import { Fragment } from 'react';
import ActionBlock from '#components/ActionBlock/ActionBlock'
import styles from './DepositTermination.module.css'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import {TerminationMode} from './DepositTermination'
import {useTranslation} from 'react-i18next'
import Button from '#components/Button'
import {DepositDto} from '@fixrate/fixrate-query'
import LabeledInfo from "#components/LabeledInfo/LabeledInfo";

type Props = {
    deposit: DepositDto,
    terminationMode: TerminationMode,
    setTerminationMode: (TerminationMode) => void
}

export default function DepositTerminationSelectType({terminationMode, setTerminationMode, deposit}: Props) {

    const {t} = useTranslation()

    return terminationMode !== 'HIDE' ? (
        <ActionBlock header={t('pages-portfolio-depositor.startTermination')}
                     onClose={() => setTerminationMode('HIDE')}>

            {deposit.product.termsType === 'NOTICE' &&
            <Fragment>
                <div className={styles.sectionWithBorder}>
                    <h4>{t('pages-portfolio-depositor.terminationUnconditionalHeader')}</h4>
                    <LabeledInfo
                        info={t('pages-portfolio-depositor.terminationUnconditionalInfo1', {count: deposit.product.days})}
                        helpText={t('pages-portfolio-depositor.noticeHelpText', {count: deposit.product.days})}/>
                    <p>{t('pages-portfolio-depositor.terminationUnconditionalInfo2')}</p>
                    <ButtonRow>
                        <Button id="terminateNowButton"
                                onClick={() => setTerminationMode('UNCONDITIONAL')}>{t('pages-portfolio-depositor.terminationUnconditionalButtonText')}</Button>
                    </ButtonRow>
                </div>

                <div className={styles.sectionWithBorder}>
                    <h4>{t('pages-portfolio-depositor.terminationConditionalHeader')}</h4>
                    <p>{t('pages-portfolio-depositor.terminationConditionalInfo1', {count: deposit.product.days})}</p>
                    <p>{t('pages-portfolio-depositor.terminationConditionalInfo2')}</p>
                    <p>{t('pages-portfolio-depositor.terminationConditionalInfo3')}</p>
                    <ButtonRow>
                        <Button id="askForBetterInterestButton"
                                onClick={() => setTerminationMode('CONDITIONAL')}>{t('pages-portfolio-depositor.terminationConditionalButtonText')}</Button>
                    </ButtonRow>
                </div>
            </Fragment>
            }
            {deposit.product.termsType === 'RECURRING_FIXED_TERMS' &&
            <Fragment>
                <div className={styles.sectionWithBorder}>
                    <h4>{t('pages-portfolio-depositor.terminationRecurringHeader')}</h4>
                    <p>{t('pages-portfolio-depositor.terminationRecurringInfo1', {count: deposit.product.days})}</p>
                    <p>{t('pages-portfolio-depositor.terminationRecurringInfo2')}</p>
                    <ButtonRow>
                        <Button id="terminateNowButton"
                                onClick={() => setTerminationMode('UNCONDITIONAL')}>{t('pages-portfolio-depositor.terminationRecurringButtonText')}</Button>
                    </ButtonRow>
                </div>
            </Fragment>
            }
        </ActionBlock>
    ) : null
}
