import FundCustomerDetail from '#pages/FundCustomers/FundCustomerDetail/FundCustomerDetail'
import FundCustomerOverview from '#pages/FundCustomers/FundCustomerOverview/FundCustomerOverview'
import { Route, Routes } from 'react-router-dom'

export default function FundCustomers() {
    return (
        <Routes>
            <Route path={''} element={<FundCustomerOverview />} />
            <Route path={':depositorId'} element={<FundCustomerDetail />} />
        </Routes>
    )
}
