import { useState } from 'react';
import styles from './topic-compact.module.scss'
import classNames from 'classnames'
import DocumentList from '#components/DocumentList/DocumentList'
import {InterestOutput} from "#components"
import {Alert} from '@mui/material'
import { DocumentDto, OrderDto, OrderState, Product } from '@fixrate/fixrate-query';
import { useTranslation } from 'react-i18next';

interface TopicCompactProps {
    interestRate: number
    actionRequired?: boolean
    documents?: DocumentDto[]
    volume: number
    bankName: string
    order: OrderDto
    orderState: OrderState
    product: Product
    children: React.ReactNode
    'data-cy'?: string
}
export default function TopicCompact({interestRate, actionRequired, documents, volume, bankName, order, orderState, product, children, ...cypressData}: TopicCompactProps) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    let interestText
    if (order.interestRateBenchmark) {
        interestText = (t('common.interestRateBenchmark.' + order.interestRateBenchmark) +  ' + ' + InterestOutput.format(interestRate)).replace('+ -', '- ')
    } else {
        interestText = InterestOutput.format(interestRate)
    }

    function handleClick() {
        setOpen(prevState => !prevState)
    }

    function getStatusMessage() {
        if (orderState && actionRequired) {
            let message = '';
            if (orderState === 'READY_FOR_SIGNING') {
                message = 'documentsSignedSent'
            }

            if (orderState === 'READY_FOR_TRANSACTION') {
                message = 'payConfirm'
            }
            return (
                <div className={styles.actionLabel}>
                    <i className="ri-information-line"></i>
                    <span>{t('blocks-TopicCompact.' + message)}</span>
                </div>
            )
        }
    }

    return (
        <div className={classNames(styles.orderCard)} data-cy={cypressData['data-cy']}>
            <p className={styles.bankName}>
                <i className="ri-bank-line"></i>
                <span>{bankName}</span>
            </p>
            <div className={classNames(styles.card, actionRequired && styles.action)}>
                {orderState === 'CANCELED' && <Alert severity="error">Bestillingen ble avvist av banken</Alert>}
                <div className={styles.section}>
                    <p className={styles.description}>Beløp</p>
                    <p className={styles.amount}>
                        {order?.currency} {volume} 000 000
                    </p>
                </div>
                <div className={styles.highlightSection}>
                    <span className={styles.interest}>{interestText}</span><br/>
                    <span className={styles.productName}>{t('common.product-group.productId' + product?.id)}</span>
                </div>
                {getStatusMessage()}
                <div className={styles.section}>
                    {children}
                </div>
                <a
                    className={styles.dropDownButton}
                    onClick={handleClick}
                >
                    <span>{open ? t('blocks-TopicCompact.hideDocuments') : t('blocks-TopicCompact.showDocuments')}</span>
                    <i className={open ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                </a>
                <div className={styles.documents}>
                    {open ? <DocumentList smallText={true} documents={documents}/> : null}
                </div>
            </div>
        </div>
    )
}
