import { PageHeader, PageLayout, Paper } from '#components'
import { useSelector } from '#state/useSelector'
import { Button } from "@mui/material"
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './FundTransactionsImport.module.scss'

export default function FundTransactionsImport() {
    const {t} = useTranslation()
    const [selectedCustomer, setSelectedCustomer] = useState('')
    const navigate = useNavigate()
    const fundCustomers = useSelector(state => state.fundCustomers)

    return (
        <>
            <PageHeader title={t('pages-fundTransactionsImport.header')}/>
            <PageLayout>
                <Paper sx={{width: "100%"}} title={t('pages-fundTransactionsImport.selectCustomer')}>
                    <div className={styles.selectCustomer}>
                        <select className={styles.toolbarSelect} onChange={e => setSelectedCustomer(e.target.value)} value={selectedCustomer}>
                            <option value="">{t('pages-fundTransactionsImport.selectCustomer')}</option>
                            {[...fundCustomers].sort((a, b) => a.name.localeCompare(b.name)).map(customer => (
                                <option key={customer.depositorId} value={customer.depositorId}>{customer.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <Button variant="contained" color="primary" onClick={() => selectedCustomer && navigate('/fund-transactions/import/' + selectedCustomer)}>{t('pages-fundTransactionsImport.fetchFromFA')}</Button>
                </Paper>
            </PageLayout>
        </>
    )
}
