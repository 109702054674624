import { Box, SxProps } from "@mui/material"
import { useState } from "react"
import config from "./../../config"
export default function PartnerLogo({partnerId, sx} : {partnerId: string, sx?: SxProps}) {
    const API_BASE_URL = config().apiUrl
    const getBankLogoUrl = (partnerId: string) => `${API_BASE_URL}/api/partner/${partnerId}/logo`
    const [imageError, setImageError] = useState(false)

    return (
        <Box sx={{...sx}}>
            { !imageError && <img style={{maxWidth: "100%"}} src={getBankLogoUrl(partnerId)} alt="Partner logo" onError={(event) => setImageError(true)} /> }
        </Box>
    )
}
