import { useCallback } from 'react';
import {useValidateAll} from '../Validate'
import styles from './Question.module.scss'
import Enter from './Enter'

type Props = {
    title?: string,
    path: string[] | string,
    children: React.ReactNode,
    hide?: boolean,
    className?: string
}

export default function Question({title, path, children, hide = false, className: propsClassName = ''}: Props) {
    const {areAllValid, areAnyTouched} = useValidateAll()
    const Title = useCallback(() => title ? <h4>{title}</h4> : null, [title])

    const paths = Array.isArray(path) ? path : [path]

    const borderStyle = () => {
        const valid = areAllValid({paths})
        if (valid) {
            return styles.validOk
        } else if (areAnyTouched({paths}) && !valid) {
            return styles.validError
        } else {
            return styles.validNeutral
        }
    }

    const className = [styles.body, borderStyle(), propsClassName].filter(Boolean).join(' ').trim()

    return (
        <Enter hide={hide}>
            <div className={className}>
                <Title/>
                {children}
            </div>
        </Enter>
    )
}

