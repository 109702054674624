import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import {useCallback, useEffect, useRef} from 'react'
import {Chart} from 'chart.js'
import 'chartjs-adapter-date-fns'
import {Options} from '#blocks/FundReturnsChart/FundReturnsChart'
import {InterestOutput} from '#components'
import {useTranslation} from 'react-i18next'
import {useCurrencyOutput} from '#components/CurrencyOutput/useCurrencyOutput'
import format from 'date-fns/format'
import {NavWithPerformance} from '#blocks/FundReturnsChart/useFundMarketData'

type Props = {
    data: NavWithPerformance[]
    startDate: Date
    endDate: Date
    simulateBeforeDate?: string
    selectedOptions: Options
}


type LineChart = Chart<'line', string[] | number[]>

const COLORS = {
    FOREST_GREEN: '#21362C',
    SILVER_GRAY: '#6F7271',
    PURPLE: '#4D2A98',
    SUNSET_ORANGE: '#FFA621',
}

export function FundReturnsChartCanvas({data, startDate, endDate, simulateBeforeDate, selectedOptions}: Props) {
    const chartRef = useRef<LineChart | null>(null)
    const {t} = useTranslation()

    const Currency = useCurrencyOutput()

    const isSimulatedNavData = useCallback((date: Date)=>{
        if (!simulateBeforeDate) {
            return false
        }
        const cutOffDate = new Date(simulateBeforeDate).getTime()
        return cutOffDate && date.getTime() < cutOffDate
    },[simulateBeforeDate])


    // number of days between the first date in the dataset and the selected start date to get index of new start
    const firstDateInDataSet = data[0].date
    const daysBeforeStart = differenceInCalendarDays(startDate, new Date(firstDateInDataSet))
    const indexOfNewStart = daysBeforeStart > 0 ? daysBeforeStart : 0

    // number of days between the selected end date and the last date in the dataset
    const lastDateInDataSet = data[data.length - 1].date
    const daysAfterEnd = differenceInCalendarDays(new Date(lastDateInDataSet), endDate)
    const indexOfNewEnd = daysAfterEnd > 0 ? data.length - daysAfterEnd : data.length

    const d = data.slice(indexOfNewStart, indexOfNewEnd) // slice the data to only include the selected start and end date

    const reverseData = d.reverse() // reverse data for best rendering animation effect
    const valuesAtStart = reverseData[reverseData.length - 1] //valuesAtStart?.value ?? 0
    const initialValue = valuesAtStart.value
    // add datapoint if not present, make sure the chart starts at the selected start date
    if (valuesAtStart.date !== startDate.toISOString().slice(0, 10)) {
        const cappedStartData = {date: format(startDate, 'yyyy-MM-dd')} as NavWithPerformance
        reverseData.push(cappedStartData)
    }

    const labels = reverseData.map(navData => navData.date)

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.data.datasets[0].data = reverseData.map((nav) => (nav.value - initialValue) / initialValue * 100)
            chartRef.current.data.datasets[0].segment = {
                backgroundColor: (context) => {
                    const end = chartRef.current.data.labels[context.p1DataIndex] as string
                    const endDate = new Date(end)
                    if (isSimulatedNavData(endDate)) {
                        return COLORS.SILVER_GRAY + '20'
                    }
                    return COLORS.PURPLE + '20'
                },
                borderColor: (context) => {
                    const end = chartRef.current.data.labels[context.p1DataIndex] as string
                    const endDate = new Date(end)
                    if (isSimulatedNavData(endDate)) {
                        return COLORS.SILVER_GRAY
                    }
                    return COLORS.PURPLE
                },
                borderDash: (context) => {
                    const end = chartRef.current.data.labels[context.p1DataIndex] as string
                    const endDate = new Date(end)
                    if (isSimulatedNavData(endDate)) {
                        return [5, 5]
                    }
                    return [0, 0]
                },
            }

            chartRef.current.data.datasets[1].data = reverseData.map(navData => navData.nav)
            chartRef.current.data.labels = labels
            chartRef.current.update()
        }
    }, [chartRef, isSimulatedNavData, reverseData, initialValue, labels])


    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: t('blocks-FundReturnsChart.returns'),
                            data: [],
                            backgroundColor: COLORS.PURPLE + '20',
                            borderColor: COLORS.PURPLE,
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: 'transparent',
                            pointBorderColor: 'transparent',
                            spanGaps: true,
                            tension: 0.1,
                            fill: 'start',
                        },
                        {
                            hidden: true,
                            label: 'NAV',
                            data: [],
                        },
                    ],

                },
                options: {
                    color: COLORS.SILVER_GRAY,
                    aspectRatio: selectedOptions.aspectRatio.width / selectedOptions.aspectRatio.height,
                    maintainAspectRatio: true,
                    responsive: true,
                    animation: selectedOptions.showAxes ? {
                        duration: 500,
                    } : false,
                    hover: selectedOptions.showAxes ? {
                        mode: 'nearest',
                        intersect: true,
                    } : undefined,
                    scales: {
                        x: {
                            display: selectedOptions.showAxes,
                            type: 'time',
                            ticks: {
                                font: {
                                    size: 10,
                                    family: '\'Montserrat\'',
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                                maxTicksLimit: selectedOptions.maxTicksLimit.x,
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                            time: {
                                minUnit: 'day',
                                displayFormats: {
                                    day: 'dd. MMM',
                                    month: 'MMM yy',
                                },
                                tooltipFormat: 'dd. MMMM yyyy',
                            },
                        },
                        y: {
                            ticks: {
                                display: selectedOptions.showAxes,
                                font: {
                                    family: '\'Montserrat\'',
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                                maxTicksLimit: selectedOptions.maxTicksLimit.y,
                                callback: (value) => InterestOutput.format(value),
                            },
                            grid: {
                                drawBorder: false,
                                borderDash: selectedOptions.showAxes ? undefined : [4, 4],
                                color: context => context.tick.value === 0 ? '#00000025' : '#00000000',
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: selectedOptions.showAxes,
                            axis: 'x',
                            mode: 'nearest',
                            intersect: false,
                            displayColors: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    if (tooltipItem.datasetIndex === 2) {
                                        return
                                    }
                                    const navDataset = chartRef.current.data.datasets[1]
                                    const nav = navDataset?.data[tooltipItem.dataIndex]

                                    const end = chartRef.current.data.labels[tooltipItem.dataIndex] as string
                                    const endDate = new Date(end)
                                    if (isSimulatedNavData(endDate)) {
                                        return [t('blocks-FundReturnsChart.simulatedReturns.line1'), t('blocks-FundReturnsChart.simulatedReturns.line2')]
                                    }

                                    return nav !== undefined && nav !== null ? `${navDataset?.label}: ${Currency(typeof nav === 'string' ? parseFloat(nav) : nav)}` : ''
                                },
                                footer: (tooltipItems) => {
                                    const returnDataset = chartRef.current.data.datasets[0]
                                    const returnValue = returnDataset?.data[tooltipItems[0]?.dataIndex]
                                    return returnValue !== undefined ? `${returnDataset?.label}: ${InterestOutput.format(returnValue)}` : ''
                                },
                            },
                        },
                    },
                },
            })
        }
    }

    return <canvas ref={canvasCallback} style={{maxWidth: '100%'}}/>
}
