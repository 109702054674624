import { Animated, PageHeader } from '#app/components';
import WebinarCard from '#app/customerEvents/WebinarCard';
import { Webinar } from '#app/services/payloadCMS/payload-types';
import { getBankWebinars } from '#app/services/payloadCMS/payloadCMS';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { isFuture, isPast } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BankDashboard.module.scss';
import { BankMarginSegment } from './components/BankMarginSegment';
import { FullySubscribedAds } from './components/FullySubscribedAds';
import { Header } from './components/Header';
import { LastOrders } from './components/LastOrders';
import { MyAds } from './components/MyAds';
import { VolumePerSegment } from './components/VolumePerSegment';

export const BankDashboard = () => {
    const organisationCountry = useCurrentCountryCode()
    const {t} = useTranslation()
    const [showPastWebinars, setShowPastWebinars] = useState(false)
    const [webinars, setWebinars] = useState<Webinar[]>([])
    const pastWebinarsSorted = webinars.filter(e => isPast(new Date(e.dateTo)))?.sort((a, b) => new Date(b.dateTo).getTime() - new Date(a.dateTo).getTime())
    const upcomingWebinarsSorted = webinars.filter(e => isFuture(new Date(e.dateTo)))?.sort((a, b) => new Date(a.dateTo).getTime() - new Date(b.dateTo).getTime())

    useEffect(() => {
        getBankWebinars().then(data => {
            if (data) {
                setWebinars(data)
            } else {
                setWebinars([])
            }
        }).catch((e) => console.error(e))
    }, [])
        

    return (
        <Fragment>
            <PageHeader icon="ri-dashboard-line" title="Dashboard"/>
            <Header/>
            <div className={styles.wrapper}>
                { organisationCountry === "NO" && (
                    <Box>
                        { upcomingWebinarsSorted?.length > 0 && (
                            <Stack mb={4} spacing={2}>
                                <Typography variant='h2'>
                                    <i className="ri-calendar-line"/>
                                    <span>{t('pages-BankDashboard.upcomingWebinar')}</span>
                                </Typography>
                                <Stack spacing={2}>
                                    { upcomingWebinarsSorted?.map(webinar => (
                                        <WebinarCard key={webinar.id} webinar={webinar}/>
                                    ))}
                                </Stack>
                            </Stack>
                        )}
                        { pastWebinarsSorted?.length > 0 && (
                            <Stack mb={4} spacing={2}>
                                <Box>
                                    <Typography variant='h2'>
                                        <i className="ri-calendar-check-line"/>
                                        <span>{t('pages-BankDashboard.pastWebinars')}</span>
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={showPastWebinars}
                                                id={'showPastWebinarsCheckbox'}
                                                onChange={(e) => setShowPastWebinars(!showPastWebinars)}/>
                                        }
                                        label={ upcomingWebinarsSorted.length === 0 ? t('pages-BankDashboard.showMorePastWebinars') : t('pages-BankDashboard.showPastWebinars') }
                                    />
                                </Box>
                                <Stack spacing={2}>
                                    { pastWebinarsSorted?.slice(0, showPastWebinars ? pastWebinarsSorted.length : 1)?.map(webinar => (
                                        <Animated key={webinar.id}>
                                            <WebinarCard webinar={webinar}/>
                                        </Animated>
                                    ))}
                                </Stack>
                            </Stack>
                        )}
                    </Box>
                )}
                <div className={styles.row}>
                    <MyAds/>
                </div>
            </div>
            {organisationCountry === "NO" && (
                <div className={styles.darkMode}>
                    <div className={styles.wrapper}>
                        <div className={styles.row}>
                            <LastOrders/>
                        </div>
                        <div className={styles.row}>
                            <FullySubscribedAds/>
                        </div>
                        <div className={styles.row}>
                            <VolumePerSegment/>
                            <BankMarginSegment/>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}
