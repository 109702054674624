import FundIntermediaryMenu from '#pages/menu/menus/FundIntermediaryMenu';
import { useSelector } from '#state/useSelector';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FixrateLogo from './FixrateLogo';
import { CloseIcon, OpenIcon } from './Icons';
import styles from './Menu.module.scss';
import { BankMenu, DepositorMenu, PartnerMenu, SystemUserMenu, UnknownMenu } from './menus';

const usePrevious = (value) => {
    const previous = useRef(null)

    useEffect(() => {
        previous.current = value
    })

    return previous.current
}

const Menu = () => {
    const [isExpanded, expand] = useState(false)
    const {pathname} = useLocation()
    const session = useSelector(({session}) => session)
    const previous = usePrevious(pathname)
    const ref = useRef(null)
    const [height, setHeight] = useState(0)
    function toggleMenu({target}) {
        expand(target.checked)
        fixHeightOnMobile(target.checked)
    }

    function fixHeightOnMobile(isOpen) {
        if (ref.current instanceof HTMLElement) {
            if (isOpen) {
                setHeight(window.innerHeight - ref.current.getBoundingClientRect().height)
            } else {
                setHeight(0)
            }
        }
    }

    useEffect(() => {
        if (previous !== pathname) {
            expand(false)
            fixHeightOnMobile(false)
        }
    }, [pathname, previous])

    const MenuList = useCallback(() => {
        if (session.authenticated || session.authenticationLevel === 'KNOWN') {
            switch (session.organisationType) {
                case 'BANK': {
                    return (
                        <ul className={styles.menu__list}>
                            <BankMenu/>
                        </ul>
                    )
                }
                case 'PARTNER': {
                    return (
                        <ul className={styles.menu__list}>
                            <PartnerMenu/>
                        </ul>
                    )
                }
                case 'DEPOSITOR': {
                    return (
                        <ul className={styles.menu__list}>
                            <DepositorMenu/>
                        </ul>
                    )
                }
                case 'FUND_INTERMEDIARY': {
                    return (
                        <ul className={styles.menu__list}>
                            <FundIntermediaryMenu/>
                        </ul>
                    )
                }
                case 'FIXRATE':
                    return (
                        <ul className={styles.menu__list}>
                            <SystemUserMenu/>
                        </ul>
                    )
                default:
                    return (
                        <ul className={styles.menu__list}>
                            <DepositorMenu/>
                        </ul>
                    )
            }
        }

        if (session.authenticationLevel === 'UNKNOWN') {
            return (
                <ul className={styles.menu__list}>
                    <UnknownMenu/>
                </ul>
            )
        }

        return <ul className={[styles.menu__list, styles.menu__list__closed].join(' ').trim()}/>
    }, [session.authenticationLevel, session.authenticated, session.organisationType])

    return (
        <nav className={styles.menu}>
            <div ref={ref} className={styles.menu__top}>
                <Link to={'/marketplace'} className={styles.menu__title__link}>
                    <FixrateLogo className={styles.fixrate__logo} />
                </Link>
                <label className={styles.menu__toggle}>
                    <input className={styles.menu__toggle__checkbox} type='checkbox' checked={isExpanded} onChange={toggleMenu} />
                    {isExpanded ? <CloseIcon /> : <OpenIcon />}
                </label>
            </div>
            <div style={isExpanded ? { height: height + 'px' } : null} className={isExpanded ? [styles.menu__wrapper, styles.menu__wrapper__expanded].join(' ').trim() : styles.menu__wrapper}>
                <MenuList />
            </div>
        </nav>
    )
}

export default Menu
