import { IconButton, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment } from 'react';

export default function LongTooltip({ title, description, inline }: { title: string; description: string; inline?: boolean}) {
    const content = (
        <Fragment>
            <Typography fontWeight={'600'} mb={'0.7rem'} variant='h2' color='inherit' align='left'>
                {title}
            </Typography>
            <Typography fontWeight={'500'} mb={'0.7rem'} variant='body1' color='inherit' align='left'>
                {description}
            </Typography>
        </Fragment>
    )
    const HtmlTooltip = styled(({ className, color, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme, color }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: color,
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 500,
            padding: 40,
            boxShadow: theme.shadows[8],
        },
        [`& .${tooltipClasses.tooltipArrow}`]: {
            backgroundColor: color,
        },
        [`& .${tooltipClasses.arrow}`]: {
            '&:before': {
                border: `1px solid ${color}`,
                color: color,
            },
        },
    }))

    return (
        <HtmlTooltip title={content} arrow color='white'>
            <IconButton sx={inline && {lineHeight: 0}} color='primary'>
                <i className='ri-question-line' />
            </IconButton>
        </HtmlTooltip>
    )
}
