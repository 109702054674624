import PhoneNumber from '#app/components/PhoneNumber';
import RegistrationProgress from '#app/layers/Signup/RegistrationProgress';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import { useEndpoint } from '#command';
import { ButtonRow, LoadingSpinner } from '#components';
import AsyncButton from '#components/Button/AsyncButton';
import EmptyState from '#components/EmptyState/EmptyState';
import OnboardingFrame from '#components/OnboardingFrame';
import { openChatBubble } from '#services/hubspot';
import { logout, syncSessionState } from '#services/thunks/session';
import { useFieldState } from '@fixrate/fieldstate'
import { validateEmailAddress } from '#services/validateFields';
import { useSelector } from '#state/useSelector';
import { SecurityRole, UserInviteDto } from '@fixrate/fixrate-security';
import { Button, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import {useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import styles from './Signup.module.scss';

function getInviteIdIfPresent(url: string): string | null {
    const regex1 = /.*invite\//gi
    const regex2 = /\?.*/gi
    return url.match(regex1) ? url.replace(regex1, '').replace(regex2, '') : null
}

export default function Signup() {
    const {t} = useTranslation()
    const session = useSelector(state => state.session)
    const organisationCountry = useCurrentCountryCode()
    const {trackEvent} = useTracking({page: 'Signup', session})
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const {registerUser, getInvite} = useEndpoint()
    const acceptedTermsOfUse = useFieldState(false, ({value}) => !value && 'Du må bekrefte at du har lest personvernerklæringen')
    const [userInvite, setUserInvite] = useState<UserInviteDto>(null)
    const isAuthorizationSignerInvite = userInvite?.roles?.every((role: SecurityRole) => role === 'DEPOSITOR_SIGN_AUTHORIZATION')
    const inviteId = getInviteIdIfPresent(location.pathname)

    const emailValidator = ({value, isEditing}) => {
        if (!value) {
            return t('layers-Signup.signupEmailMissing')
        }
        if (!isEditing && !validateEmailAddress(value)) {
            return t('layers-Signup.signupEmailInvalid')
        }
    }
    const email = useFieldState('', emailValidator)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [validPhoneNumber, setValidPhoneNumber] = useState(false)

    useEffect(() => {
        (async function () {
            if (inviteId && (!userInvite || userInvite.id !== inviteId)) {
                try {
                    const data = await getInvite(inviteId)
                    setUserInvite(data)
                    email.setValue(data.email)
                    if (data.status === 'ACCEPTED') {
                        console.log('User has already accepted this invite. Redirects to /marketplace')
                        navigate('/marketplace')
                    } else if (data.status === 'NOT_FOR_SUBJECT') {
                        console.error('The invite was not generated for this subject')
                    }
                } catch (e) {
                    console.error('Error fetching invite', e)
                    setUserInvite(null)
                }
            }
        })()
    }, [userInvite, email, getInvite, inviteId, navigate])

    function cancel() {
        dispatch(logout(navigate))
    }

    async function onSubmitForm() {
        const isValidEmail = inviteId || email.validate()
        const isValidAcceptedTermsOfUse = acceptedTermsOfUse.validate()

        if (isValidEmail && validPhoneNumber && isValidAcceptedTermsOfUse) {
            const response = await registerUser({
                email: email.value,
                phone: phoneNumber,
                acceptedTermsOfUse: acceptedTermsOfUse.value,
                userInviteId: inviteId,
            })

            if (response.emailError === "ALREADY_REGISTERED") {
                email.setErrorMessage(t('layers-Signup.signupEmailAlreadyRegistered'))
                return
            }

            dispatch(syncSessionState())
            trackEvent({event: 'userRegistered'})
            if (isAuthorizationSignerInvite) {
                navigate('/sign')
            } else if (inviteId) {
                navigate('/marketplace')
            } else {
                navigate('/marketplace?registerCompany')
            }
        }
    }

    if (userInvite?.status === 'NOT_FOR_SUBJECT') {
        trackEvent({action: 'inviteNotForSubject'})
        return (
            <OnboardingFrame header={t('layers-Signup.signupHeading')} onCancel={cancel}>
                <EmptyState
                    illustration={{name: "brokenLink", color: "default"}}
                    variant="centered-on-background"
                    title={t('error.header')}
                    button={{
                        text: t('layers-Signup.continueToFixrate'),
                        onClick: () => document.location.assign('https://fixrate.no')
                    }}
                    link={{
                        text: t('blocks-UsersList.talkToSupport'),
                        onClick: openChatBubble
                    }}
                    description={{
                        dataCy: "signupInviteNotForSubject",
                        text: t('layers-Signup.signupInviteNotForSubject')
                    }}>
                </EmptyState>
            </OnboardingFrame>
        )
    }

    return (
        <OnboardingFrame
            header={session?.firstName?.length < 8 ? t('layers-Signup.signupHeadingWithName', {name: session?.firstName}) : t('layers-Signup.signupHeading')}
            onCancel={cancel}
            progress={!inviteId && <RegistrationProgress step={1}/>}>
                { inviteId && !userInvite ? (
                    <LoadingSpinner text={t('layers-Signup.signupLoading')}/>
                ) : (
                <>
                    {isAuthorizationSignerInvite && (
                        <p>{t('layers-Signup.signupPowerOfAttorneyMessage')}</p>
                    )}
                    {!isAuthorizationSignerInvite && (
                        inviteId ? (
                            <p>{t('layers-Signup.signupInviteMessage')}</p>
                        ) : (
                            <p>{t('layers-Signup.signupMessage')}</p>
                        )
                    )}

                    {!inviteId && (
                        <div className={styles.textInput}>
                            <label htmlFor="signup__input-email">
                                {t('layers-Signup.signupEmail')}
                            </label>
                            <input id="signup__input-email"
                                data-cy="email"
                                type="email"
                                value={email.value}
                                onBlur={email.onBlur}
                                onChange={e => email.setValue(e.target.value)}
                            />
                            { email.errorMessage && <p className={styles.fieldErrorMessage}>{email.errorMessage}</p> }
                        </div>
                    )}

                    <div className={styles.textInput}>
                        <label htmlFor="signup__input-phone">{t('layers-Signup.signupPhone')}</label>
                        <PhoneNumber
                            initialCountry={organisationCountry}
                            setNumber={number => setPhoneNumber(number)}
                            setValid={valid => setValidPhoneNumber(valid)}/>
                        {validPhoneNumber === false && phoneNumber && (
                            <p className={styles.fieldErrorMessage}>{t('layers-Signup.signupPhoneInvalid')}</p>
                        )}
                    </div>
                    <Stack direction={"row"} alignItems="center" mb={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="signup__input-privacy"
                                    data-cy="acceptedTermsOfUse"
                                    onChange={e => acceptedTermsOfUse.setValue(e.target.checked)}
                                    checked={acceptedTermsOfUse.value} />
                            }
                            label={
                                <Typography lineHeight={1.4}>
                                    <span>{t('layers-Signup.signupPrivacyPolicyDescription')} </span>
                                    <Link
                                        href="https://fixrate.no/privacypolicy/"
                                        color="primary"
                                        underline="always"
                                        sx={{display: 'inline'}}
                                        target="_blank">
                                            {t('layers-Signup.signupPrivacyPolicyLinkText')}
                                    </Link>
                                </Typography>
                            }
                        />
                    </Stack>
                    { acceptedTermsOfUse.errorMessage && <p className={styles.fieldErrorMessage}>{acceptedTermsOfUse.errorMessage}</p> }

                    <ButtonRow>
                        <Button variant={'outlined'} data-cy="signupCancelButton"
                                onClick={cancel}>
                            {t('common.cancel')}
                        </Button>
                        <AsyncButton data-cy="signupContinueButton"
                                variant={'contained'}
                                onClick={onSubmitForm}>
                            {t('common.continue')}
                        </AsyncButton>
                    </ButtonRow>
                </>
            )}
        </OnboardingFrame>
    )
}
