import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateOutput } from "#app/components";
import { getIconText } from "#app/components/PageHeader/PageHeader";
import styles from "../AcceptTerms.module.scss";
import useCurrentDepositor from "#app/services/useCurrentDepositor";

const PartnerAgreements = () => {
    const { t } = useTranslation();
    const depositor = useCurrentDepositor();
    if (depositor.partnerRelations.length === 0 || !depositor) {
        return null;
    }
    return (
        <>
            { depositor.partnerRelations
                .filter((pr) => pr.accepted)
                .filter(
                    (pr) =>
                        pr.partnerCategory !== 'SUPPORT'
                ) // Remove Fixrate Support
                .map((pr) => (
                    <Card key={pr.partnerId} sx={{ maxWidth: "80rem", marginBottom: "3rem" }}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    sx={{
                                        backgroundColor: "#4D2A9830",
                                        color: "#4D2A98",
                                        fontSize: "2.4rem",
                                        width: "8rem",
                                        height: "8rem",
                                    }}
                                >
                                    {getIconText(pr.name)}
                                </Avatar>
                            }
                            title={pr.name}
                            subheader={
                                pr.accepted === true &&
                                t(
                                    "pages-organizations.partnerAgreementAcceptedBy",
                                    {
                                        userName:
                                            depositor.knownUserFullNames[
                                                pr.acceptedOrRejectedBy
                                            ] || "",
                                        date: DateOutput.formatDate(
                                            pr.acceptedOrRejectedAt
                                        ),
                                    }
                                )
                            }
                        />
                        <Divider />
                        <CardContent>
                            {pr.partnerCategory === "ACCOUNTANT" && (
                                <Stack sx={{ fontSize: "1.4rem" }}>
                                    <p>
                                        {t(
                                            "pages-organizations.accountantPartnerAgreementMessage",
                                            {
                                                partnerName: pr.name,
                                                depositorName: depositor.name,
                                            }
                                        )}
                                    </p>
                                </Stack>
                            )}
                            {pr.partnerCategory === "ASSET_MANAGER" && (
                                <Stack sx={{ fontSize: "1.4rem" }}>
                                    <p>
                                        {t(
                                            "pages-organizations.partnerAgreementMessagePart1",
                                            {
                                                partnerName: pr.name,
                                                depositorName: depositor.name,
                                            }
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "pages-organizations.partnerAgreementMessagePart2",
                                            { partnerName: pr.name }
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "pages-organizations.partnerAgreementMessagePart3",
                                            {
                                                partnerName: pr.name,
                                                depositorName: depositor.name,
                                            }
                                        )}
                                    </p>
                                </Stack>
                            )}
                        </CardContent>
                        <Divider />
                        <CardContent>
                            <Stack
                                direction={"row"}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    {pr.advisors.map((advisor) => (
                                        <Stack
                                            key={advisor.userId}
                                            className={styles.advisorBadge}
                                            spacing={1}
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <i className="ri-user-follow-line purple" />
                                            <span>
                                                {advisor.firstName +
                                                    " " +
                                                    advisor.lastName}
                                            </span>
                                        </Stack>
                                    ))}
                                </Box>
                                <Box>
                                    {pr.accepted === true && (
                                        <Stack
                                            className={styles.advisorBadge}
                                            spacing={1}
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: "3rem",
                                                    height: "3rem",
                                                    backgroundColor:
                                                        "#40a25120",
                                                }}
                                            >
                                                <i className="ri-check-line green" />
                                            </Avatar>
                                            <span>
                                                {t(
                                                    "pages-organizations.activated"
                                                )}
                                            </span>
                                        </Stack>
                                    )}
                                    {pr.accepted === false && (
                                        <Stack
                                            className={styles.advisorBadge}
                                            spacing={1}
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: "3rem",
                                                    height: "3rem",
                                                    backgroundColor:
                                                        "#B44A0620",
                                                }}
                                            >
                                                <i className="ri-close-line red" />
                                            </Avatar>
                                            <span>
                                                {t(
                                                    "pages-organizations.rejected"
                                                )}
                                            </span>
                                        </Stack>
                                    )}
                                    {pr.accepted === undefined ||
                                        (pr.accepted == null && (
                                            <Stack
                                                className={styles.advisorBadge}
                                                spacing={1}
                                                direction={"row"}
                                                alignItems={"center"}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: "3rem",
                                                        height: "3rem",
                                                        backgroundColor:
                                                            "#FFA62140",
                                                    }}
                                                >
                                                    <i className="ri-error-warning-line forestGreen" />
                                                </Avatar>
                                                <span>
                                                    {t(
                                                        "pages-organizations.notActivated"
                                                    )}
                                                </span>
                                            </Stack>
                                        ))}
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                )
            )}
        </>
    );
};

export default PartnerAgreements;
