import { Fragment, useState } from 'react';
import styles from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/PersonBadge.module.scss'
import {OrganisationalPersonDto, OrganisationalRole, SecurityRole} from '@fixrate/fixrate-query'
import {useCommand} from '#command'
import {useTranslation} from "#components/i18n"
import NewPerson from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/NewPerson'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import NameOutput from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/NameOutput';
import { Button, CircularProgress, Divider, List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { FOREST_GREEN, PURPLE } from '#app/colors/colors';

interface Props {
    securityRole: SecurityRole,
    organisationalRole: OrganisationalRole,
    selectedOpenRole: SecurityRole | OrganisationalRole | null,
    setSelectedOpenRole: (role: SecurityRole | OrganisationalRole | null) => void,
    depositorId: string,
    canAddRole: boolean,
    text: string,
    people: OrganisationalPersonDto[],
    highlight?: boolean,
    hasHeader?: boolean,
    onlyItem?: boolean
}

export default function AddUserButton({people, organisationalRole, securityRole, selectedOpenRole, setSelectedOpenRole, canAddRole, text, highlight, hasHeader, onlyItem, ...otherProps}: Props) {
    const {t} = useTranslation('pages-organizations')
    const [loading, setLoading] = useState<string | boolean>(false)
    const [newUserModalOpen, setNewUserModalOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };

    const depositor = useCurrentDepositor()
    const {
        setPersonSecurityRoles: setPersonSecurityRolesCommand,
        setPersonOrganisationalRoles: setPersonOrganisationalRolesCommand,
    } = useCommand()

    const availableUsers = people
        .filter(person => (organisationalRole && !securityRole) || (!person.securityRoles.includes(securityRole)))
        .filter(person => (securityRole && !organisationalRole) || !person.organisationalRoles.includes(organisationalRole))

    const numberOfUsersWithRole = people.filter(user => (securityRole && user.securityRoles.includes(securityRole)) || (organisationalRole && user.organisationalRoles.includes(organisationalRole))).length

    const popoverOpen = Boolean(anchorEl)

    async function addUserToRole(person: OrganisationalPersonDto) {
        setLoading(person.personId)

        if (securityRole) {
            const updatedRoles = [...person.securityRoles, securityRole]
            const {waitForCommand} = await setPersonSecurityRolesCommand(depositor?.id, person.personId, updatedRoles)
            const success = await waitForCommand()
            if (success) {
                // console.log("Added person to security role")
            } else {
                console.error("Did not update")
            }

        } else if (organisationalRole) {
            const updatedRoles = [...person.organisationalRoles, organisationalRole]
            const {waitForCommand} = await setPersonOrganisationalRolesCommand(depositor?.id, person.personId, updatedRoles)
            const success = await waitForCommand()
            if (success) {
                // console.log("Added person to organisational role")
            } else {
                console.error("Did not update")
            }
        }

        setLoading(false)
    }

    if (canAddRole === false) {
        if (numberOfUsersWithRole < 1) {
            return <li className={styles.emptyText}>{t('noUserInRole')}</li>
        }
        return null
    }

    return (
        <Fragment>
            <li data-cy={otherProps['data-cy']}>
                <Button
                    size="small"
                    startIcon={<i className={"ri-add-line"}/>}
                    variant={"outlined"}
                    onClick={handleClick}
                    sx={{height: "5rem", pr: "1.5rem", mt: hasHeader ? "2.8rem" : 0}}
                    data-cy="editRole">
                    {text}
                </Button>
                <div className={styles.openSquare}/>
                <Popover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    open={popoverOpen}>
                    <List sx={{py: 0}} data-id="adduser-dropdown" data-cy="adduser-dropdown">
                        <ListItemButton
                            sx={{backgroundColor: PURPLE[50] + "50"}}
                            onClick={() => {
                                setNewUserModalOpen(true)
                                setAnchorEl(null)
                            }}
                            data-cy="addNewUser">
                            <ListItemIcon sx={{backgroundColor: "transparent", minWidth: "2rem", minHeight: "3rem", "& i": {color: PURPLE[500]}}}>
                                <i className="ri-user-add-line"/>
                            </ListItemIcon>
                            <ListItemText sx={{'& .MuiListItemText-primary': { color: PURPLE[500], fontSize: "1.4rem"}}}>
                                {t('registerNewPerson')}
                            </ListItemText>
                        </ListItemButton>
                        {availableUsers.map((person) => (
                            <Fragment key={person.personId}>
                                <ListItemButton key={person.personId} onClick={() => {
                                        addUserToRole(person)
                                        setAnchorEl(null)
                                    }} data-cy="addUserToRole">
                                    <ListItemIcon sx={{backgroundColor: "transparent", color: FOREST_GREEN[500], minWidth: "2rem", minHeight: "3rem"}}>
                                        <i className={isInvite(person) ? 'ri-mail-line' : 'ri-user-line'}/>
                                    </ListItemIcon>
                                    <ListItemText sx={{'& .MuiListItemText-primary': { fontSize: "1.4rem" }}}>{NameOutput.withBirthYearOnDuplicates(person, depositor)}</ListItemText>
                                    <ListItemIcon sx={{backgroundColor: "transparent", minWidth: "3rem", minHeight: "3rem"}}>
                                        { loading === person.personId && <CircularProgress size={20} /> }
                                    </ListItemIcon>
                                </ListItemButton>
                                <Divider/>
                            </Fragment>
                        ))}
                    </List>
                </Popover>
            </li>
            {newUserModalOpen && (
                <NewPerson
                    securityRoles={securityRole ? [securityRole] : []}
                    organisationalRoles={organisationalRole ? [organisationalRole] : []}
                    close={() => setNewUserModalOpen(false)}
                />
            )}
        </Fragment>
    );
}

function isInvite(person: OrganisationalPersonDto): boolean {
    return !!person.associatedInviteId
}
