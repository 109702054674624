/*
    Functions for sending commands to the command endpoint
*/

import {defaultHandleErrors, defaultHandleStatusCodeErrors} from '../network/rest-connection'
import config from '../../config'
import {commandDone} from '../session/commandDone'
import {AdNotificationFrequency} from '@fixrate/fixrate-query'
import { create } from 'domain'

const API_BASE_URL = config().apiUrl

export function postCommand(dispatch, commandName, parameters) {
    const options: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: parameters && JSON.stringify({
            name: commandName,
            parameters: {...parameters},
        }),
    }

    return fetch(API_BASE_URL + '/api/command', options)
        .then((response) => {
            defaultHandleStatusCodeErrors(dispatch, response)
            if (!response.ok) {
                return false
            }
            return response
        })
        .then(response => handleCommandIdResponse(response, commandName))
        .catch((ex) => {
            defaultHandleErrors(dispatch, ex)
            return false
        })
}


function handleCommandIdResponse(response, commandName = '') {
    return new Promise((resolve, reject) => {
        if (response?.ok) {
            response.json().then(json => {
                console.log(`Command ${commandName} returned; Command ID: ${json.commandId}`)
                commandDone(json.commandId, () => {
                    console.log(`Command ${commandName} processed; Command ID: ${json.commandId}`)
                    resolve(true)
                })
            })
        } else {
            console.log(`Command ${commandName} returned without command id`)
            resolve(false)
        }
    })
}

const command = (dispatch) => ({

    createDeposit: (deposit) => postCommand(dispatch, 'CreateUnmanagedDeposit', deposit),

    createOrder: (ad, volume, organisation, accountCreatorUserId, settlementAccountId) => postCommand(dispatch, 'CreateOrder',
        {
            ad,
            volume,
            depositorId: organisation,
            accountCreator: accountCreatorUserId,
            settlementAccountId,
        },
    ),

    setToAccountOnOrder: (orderId, toAccount) => postCommand(dispatch, 'SetToAccount',
        {
            orderId: orderId,
            account: toAccount,
        },
    ),

    setSettlementDateOnOrder: (orderId, settlementDate) => postCommand(dispatch, 'SetSettlementDate',
        {
            orderId: orderId,
            settlementDate: settlementDate,
        },
    ),

    sendToBank: (orderId) => postCommand(dispatch, 'SendOrderToBank', {orderId}),

    setAdActiveStatus: (adId, active) => postCommand(dispatch, 'SetAdActive',
        {
            adId,
            active,
        },
    ),

    generateIdentificationDocument: (update) => postCommand(dispatch, 'GenerateIdentificationDocument', update),

    removeIdentificationDocument: () => postCommand(dispatch, 'RemoveIdentificationDocument', {}),

    generateAuthorizationDocument: (forUserId, fromUserIds, depositorId, multipleSigners) => postCommand(dispatch, 'GenerateAuthorizationDocument', {
        forUserId,
        fromUserIds,
        depositorId,
        multipleSigners,
    }),

    removeDocumentFromOrder: (orderId, documentId) => postCommand(dispatch, 'RemoveDocumentFromOrder',
        {
            orderId,
            documentId,
        },
    ),

    sendToDepositor: (orderId) => postCommand(dispatch, 'SendOrderToDepositor', {orderId}),

    completeOrder: (orderId, volume, startDate, comment) => postCommand(dispatch, 'CompleteOrder', {
        orderId,
        volume,
        startDate,
        comment,
    }),

    cancelOrder: (orderId, category, reason) => postCommand(dispatch, 'CancelOrder',
        {
            orderId,
            category,
            reason,
        },
    ),

    createDepositor: (nationalIdentity) => postCommand(dispatch, 'CreateDepositor',
        {
            nationality: 'NO',
            nationalIdentity
        },
    ),

    generateCustomerDeclaration: (depositorId) => postCommand(dispatch, 'GenerateCustomerDeclaration',
        {
            depositorId,
        },
    ),

    setCustomerDeclarationValue: (depositorId, key, value) => postCommand(dispatch, 'SetCustomerDeclarationValue',
        {
            depositorId,
            key,
            value,
        },
    ),

    createCustomerDeclarationDraft: (depositorId) => postCommand(dispatch, 'CreateCustomerDeclarationDraft',
        {
            depositorId,
        },
    ),

    deleteCustomerDeclarationValues: (depositorId, keys) => postCommand(dispatch, 'DeleteCustomerDeclarationValues',
        {
            depositorId,
            keys,
        },
    ),

    removeCustomerDeclaration: (depositorId) => postCommand(dispatch, 'RemoveCustomerDeclaration',
        {
            depositorId,
        },
    ),

    distributeCustomerDeclaration: (depositorId) => postCommand(dispatch, 'DistributeCustomerDeclaration',
        {
            depositorId,
        },
    ),

    registerCustomerDeclaration: (depositorId, bankId, documentId) => postCommand(dispatch, 'RegisterCustomerDeclaration',
        {
            depositorId,
            bankId,
            documentId,
        },
    ),

    startElectronicSignature: (processId, target, identity, onSuccess, onAbort) => postCommand(dispatch, 'StartElectronicSignature',
        {
            processId,
            target,
            identity,
            onSuccess,
            onAbort,
        },
    ),

    updateSignatureStatus: () => postCommand(dispatch, 'UpdateSignatureStatus', {}),

    createUserInvite: (organisationId, organisationType, firstName, lastName, email, birthDate) => postCommand(dispatch, 'CreateUserInvite',
        {
            organisationId,
            organisationType,
            firstName,
            lastName,
            email,
            birthDate,
        },
    ),

    removeUserRequestFromDepositor: (depositorId, nationalIdentity, nationality) => postCommand(dispatch, 'RemoveUserRequestFromDepositor',
        {
            depositorId,
            nationalIdentity,
            nationality,
        },
    ),
    removeUserInvite: (depositorId, inviteId) => postCommand(dispatch, 'RemoveUserInvite',
        {
            depositorId,
            inviteId,
        },
    ),

    resendUserInvite: (depositorId, inviteId, email) => postCommand(dispatch, 'ResendUserInvite',
        {
            depositorId,
            inviteId,
            email,
        },
    ),

    removeUserFromDepositor: (depositorId, userToRemoveId) => postCommand(dispatch, 'RemoveUserFromDepositor',
        {
            depositorId,
            userToRemoveId,
        },
    ),

    addSettlementAccount: (depositorId, name, accountNumber, iban, bic) => postCommand(dispatch, 'AddDepositorSettlementAccount',
        {
            depositorId,
            name,
            accountNumber,
            iban,
            bic,
        },
    ),

    removeSettlementAccount: (depositorId, settlementAccountId) => postCommand(dispatch, 'RemoveDepositorSettlementAccount', {
        depositorId,
        settlementAccountId,
    }),

    createAd: (newAd) => postCommand(dispatch, 'CreateAd', newAd),

    terminateDeposit: (terminationType, depositId, settlementAccountId, terminationDate, interestRateRequirement) => postCommand(dispatch, 'TerminateDeposit', {
        terminationType,
        depositId,
        settlementAccountId,
        terminationDate,
        interestRateRequirement,
    }),

    acceptUserInvite: (inviteId, accept) => postCommand(dispatch, 'AcceptInvite', {inviteId, accept}),

    setUserInviteRoles: (inviteId, depositorId, roles) => postCommand(dispatch, 'SetUserInviteRoles', {
        depositorId,
        inviteId,
        roles,
    }),

    acceptBankTerms: (bankId, documentId) => postCommand(dispatch, 'AcceptBankTerms', {
        bankId,
        documentId,
    }),

    acceptDepositorTerms: (depositorId, documentId) => postCommand(dispatch, 'AcceptDepositorTerms', {
        depositorId,
        documentId,
    }),

    deleteAuthorizationDocument: (authorizedUserId, depositorId) => postCommand(dispatch, 'DeleteAuthorizationDocument', {
        depositorId,
        authorizedUserId,
    }),

    sendTerminatedDepositToBank: depositId => postCommand(dispatch, 'SendTerminationToBank', {
        depositId,
    }),

    confirmDepositTermination: (depositId) => postCommand(dispatch, 'ConfirmDepositTermination', {
        depositId,
    }),

    confirmInterestRateChange: (depositId) => postCommand(dispatch, 'ConfirmInterestRateChange', {
        depositId,
    }),

    setSettlementAccount: (depositId, settlementAccountId) => postCommand(dispatch, 'SetSettlementAccount', {
        depositId,
        settlementAccountId,
    }),

    sendSettlementAccountChangeToBank: (depositId, settlementAccountId) => postCommand(dispatch, 'SendChangeSettlementAccountToBank', {
        depositId,
        settlementAccountId,
    }),

    confirmSettlementAccountChanged: (depositId, settlementAccountId) => postCommand(dispatch, 'ConfirmSettlementAccountChanged', {
        depositId,
        settlementAccountId,
    }),

    cancelDepositTermination: depositId => postCommand(dispatch, 'CancelDepositTermination', {
        depositId,
    }),

    cancelChangeSettlementAccount: depositId => postCommand(dispatch, 'CancelChangeSettlementAccount', {
        depositId,
    }),

    editDeposit: update => postCommand(dispatch, 'EditDeposit', update),

    updateProfile: update => postCommand(dispatch, 'UpdateProfile', update),

    setPinCode: pinCode => postCommand(dispatch, 'SetPinCode', {pinCode}),

    deletePinCode: () => postCommand(dispatch, 'DeletePinCode', {}),

    createInterestRateChange: (depositId, newInterestRate, changeDate) => postCommand(dispatch, 'CreateInterestRateChange', {
        depositId,
        newInterestRate,
        changeDate,
    }),

    sendInterestRateChangeToDepositor: (interestRateChangeId) => postCommand(dispatch, 'SendInterestRateChangeToDepositor', {
        interestRateChangeId,
    }),

    cancelInterestRateChange: (interestRateChangeId, reason) => postCommand(dispatch, 'CancelInterestRateChange', {
        interestRateChangeId,
        reason,
    }),

    setNotificationSetting: (notificationType, associationType, value) => postCommand(dispatch, 'SetNotificationSetting', {
        notificationType,
        associationType,
        value,
    }),

    setCustomUserFilter: (filterId: string | null, products: string[], minDepositAmount: number, maxDepositAmount: number | null, totalAssets: number, minInterestRate: number, minInterestRateMargin: number, notify: boolean) => postCommand(dispatch, 'SetCustomUserFilter', {
        filterId,
        products,
        minDepositAmount,
        maxDepositAmount,
        totalAssets,
        minInterestRate,
        minInterestRateMargin,
        notify,
    }),

    setUserFilterNotify: (filterId: string, value: boolean) => postCommand(dispatch, 'SetUserFilterNotification', {
        filterId,
        value,
    }),

    setUserFilterFrequency: (filterId: string, frequency: AdNotificationFrequency) => postCommand(dispatch, 'SetUserFilterFrequency', {
        filterId,
        frequency,
    }),

    deleteSavedUserFilter: (filterId) => postCommand(dispatch, 'RemoveUserFilter', {
        filterId,
    }),

    setTag: ({orderId, depositId, text, color}) => postCommand(dispatch, 'SetTag', {
        orderId,
        depositId,
        text,
        color,
    }),

    uploadAccountAgreement(orderId, fileList) {

        const formData = new FormData()
        formData.append('content', fileList[0], fileList[0].name)
        formData.append('type', 'ACCOUNT_AGREEMENT')
        formData.append('orderId', orderId)

        const options: RequestInit = {
            credentials: 'include',
            method: 'POST',
            body: formData,
        }

        return fetch(`${API_BASE_URL}/api/upload`, options)
            .then((response) => defaultHandleStatusCodeErrors(dispatch, response))
            .then(handleCommandIdResponse)
            .catch((ex) => {
                defaultHandleErrors(dispatch, ex)
                return false
            })

    },

    createExtensionOffer: (depositId, interestRate, terminationDate) => postCommand(dispatch, 'CreateExtensionOffer',
        {
            depositId,
            interestRate,
            terminationDate,
        },
    ),

    deleteExtensionOffer: (depositId) => postCommand(dispatch, 'DeleteExtensionOffer',
        {
            depositId,
        },
    ),

    registerExtension: (depositId) => postCommand(dispatch, 'RegisterExtension',
        {
            depositId,
        },
    ),

    sendExtensionOfferToDepositor: (depositId) => postCommand(dispatch, 'SendExtensionOfferToDepositor',
        {
            depositId,
        },
    ),

    acceptExtensionOffer: (depositId, documentId) => postCommand(dispatch, 'AcceptExtensionOffer',
        {
            depositId,
            documentId,
        },
    ),

    rejectExtensionOffer: (depositId, documentId) => postCommand(dispatch, 'RejectExtensionOffer',
        {
            depositId,
            documentId,
        },
    ),

    setPotential: (potentialVolume, historicInterestRate) => postCommand(dispatch, 'SetPotential',
        {
            potentialVolume,
            historicInterestRate,
        },
    ),

    setBankEmail: (bankId, email) => postCommand(dispatch, 'SetBankEmail', {
        bankId,
        email,
    }),

    setBankInfo: (bankId, quarter, {totalAssets, totalAssetsIncludingMortgageCredit, depositCoverage, coreCapitalCoverage}) => postCommand(dispatch, 'SetBankInfo', {
        bankId,
        quarter,
        totalAssets,
        totalAssetsIncludingMortgageCredit,
        depositCoverage,
        coreCapitalCoverage,
    }),

    uploadBankReport: (bankId, quarter, documentType, files) => {
        const formData = new FormData()
        formData.append('bankId', bankId)
        formData.append('quarter', quarter)
        formData.append('type', documentType)
        formData.append('content', files[0], files[0].name)

        const options: RequestInit = {
            credentials: 'include',
            method: 'POST',
            body: formData,
        }

        return fetch(`${API_BASE_URL}/api/upload`, options)
            .then((response) => defaultHandleStatusCodeErrors(dispatch, response))
            .then(handleCommandIdResponse)
            .catch((ex) => {
                defaultHandleErrors(dispatch, ex)
                return false
            })
    },

    removeBankReport: (bankId, quarter, documentType, documentId) => postCommand(dispatch, 'RemoveBankReport', {
        bankId,
        quarter,
        documentType,
        documentId,
    }),


    setEscaliConfiguration: (depositorId, escaliConfiguration) => {
        return postCommand(dispatch, 'UpdateDepositorIntegrations', {
            depositorId,
            integrationType: 'ESCALI',
            configuration: escaliConfiguration,
        })
    },

    setPinCodeAuthorization: (organisationId, organisationType, allow) => postCommand(dispatch, 'SetPinCodeAuthorization', {
        organisationId,
        organisationType,
        allow,
    }),

    setDepositorShortName: (depositorId, shortName) => postCommand(dispatch, 'SetDepositorShortName', {
        depositorId,
        shortName,
    }),

    setProspectiveCompany: (companyNationalIdentity, companyName) => postCommand(dispatch, 'SetUserProspectiveCompany', {
        companyNationalIdentity, companyName
    }),

})

export default command
