import { OrganisationalPersonDto } from '@fixrate/fixrate-query';
import PersonalInformation from './PersonalInformation/PersonalInformation';

interface Props {
    depositorId: string,
    person: OrganisationalPersonDto,
    onClose: () => void,
    open?: boolean
}

export default function EditPersonModal({depositorId, person, onClose, open = true}: Props) {

    return (
        <PersonalInformation person={person} onClose={onClose} open={open}/>
    )
}
