import {Box} from '@mui/material'
import {BURNT_ORANGE, PURPLE} from '#app/colors/colors'
import {useTranslation} from 'react-i18next'
import {fundPlacementTransactionTypeTMap} from "#services/enumTranslationKeyMapping"
import { FundPlacementTransactionType, FundPortfolioTransactionDto } from '@fixrate/fixrate-query'

export function TransactionTypeBadge({ transactionType }: { transactionType: FundPlacementTransactionType | FundPortfolioTransactionDto['type'] }) {
    const {t} = useTranslation()
    const typeBadgeStyle = {
        backgroundColor: transactionType === 'SELL' ? BURNT_ORANGE[50] : PURPLE[50],
        color: transactionType === 'SELL' ? BURNT_ORANGE[600] : PURPLE[500],
        padding: '0.6rem 1.8rem',
        fontWeight: 700,
        fontSize: '1.4rem',
    }

    return (
        <Box component="span" sx={{...typeBadgeStyle}}>
            {t(fundPlacementTransactionTypeTMap[transactionType])}
        </Box>
    )
}
