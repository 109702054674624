import { UnresolvedTaskCount } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'

type UnresolvedTasks = {
    total: number
    organisations: {
        [organisationId: string]: number
    }
    portfolios: {
        [portfolioId: string]: number
    }
}

export default function useUnresolvedTasks(): UnresolvedTasks {
    const unresolvedTasks: UnresolvedTaskCount[] = useSelector((state) => state.unresolvedTasks)
    const result: UnresolvedTasks = { total: 0, organisations: {}, portfolios: {} }

    if (typeof unresolvedTasks === 'undefined') return result

    unresolvedTasks.forEach((task) => {
        result.total += task.count
        if (!result.organisations[task.organisationId]) {
            result.organisations[task.organisationId] = task.count
        } else {
            result.organisations[task.organisationId] += task.count
        }
        result.portfolios[task.portfolioId] = task.count
    })
    return result
}
