import classNames from 'classnames'
import styles from './OrderDetails.module.scss'
import {useTranslation} from "#components/i18n"
import {OrderState} from '@fixrate/fixrate-query'

interface StepProps {
    icon: string
    topText: string
    bottomText: string
    done: boolean
    active: boolean
}

interface OrderProgressProps {
    orderState: OrderState
}

const Step = ({ icon, topText, bottomText, done, active }: StepProps) => (
    <div className={styles.orderDetails__progressStep}>
        <div className={styles.orderDetails__progressToptext}>
            <i className={icon}/>
            <span>{topText}</span>
        </div>
        <div className={styles.orderDetails__progressBottomtext}>{bottomText}</div>
        <div className={styles.orderDetails__progressGraphics}>
            <div className={classNames(styles.orderDetails__progressBar, styles.orderDetails__progressBarLeft)}/>
            <div className={classNames(styles.orderDetails__progressBar, styles.orderDetails__progressBarRight)}/>
            <div className={classNames(
                styles.orderDetails__progressCircle,
                done ? styles.orderDetails__done : '',
                active ? styles.orderDetails__active : '')}>
                {done && <i className="ri-check-line"/>}
            </div>
        </div>
    </div>
)

export default function OrderProgress({orderState}: OrderProgressProps) {

    // OrderState's in sorted order
    const states: OrderState[] = ['INITIAL_PROCESSING', 'READY_FOR_SIGNING', 'READY_FOR_APPROVAL', 'READY_FOR_TRANSACTION', 'READY_FOR_TRANSACTION_CONFIRMATION', 'CANCELED', 'COMPLETED']

    const {t} = useTranslation('pages-orders')

    let step = states.indexOf(orderState)

    if (orderState === 'CANCELED') {
        step = null;
    }

    const steps = [
        {
            title: 'bankOrdering',
            bankStep: false,
        },
        {
            title: 'bankCreatingAccountAgreement',
            bankStep: true,
        },
        {
            title: 'bankSigningAccountAgreement',
            bankStep: false,
        },
        {
            title: 'bankActivatingAccount',
            bankStep: true,
        },
        {
            title: 'bankTransferringFunds',
            bankStep: false,
        },
    ]

    return (
        <section className={classNames(styles.orderDetails__progress, orderState === 'CANCELED' ? styles.cancelled : '')}>
            {steps.map((item, i) => (
                <Step key={item.title}
                      icon={item.bankStep ? "ri-bank-line" : "ri-building-line"}
                      topText={t(item.bankStep ? "bankBank" : "bankDepositor")}
                      bottomText={t(item.title)}
                      done={step > i - 1}
                      active={step === i - 1}
                />
            ))}
        </section>
    )
}
