import { PURPLE } from "#app/colors/colors"
import useShoppingCart from "#app/services/useShoppingCart"
import { Avatar, Box, Button, useMediaQuery } from "@mui/material"
import { useTranslation } from "react-i18next"

export const FundCartButton = ({ setCartOpen, cartOpen } : { setCartOpen: (val: boolean) => void, cartOpen: boolean }) => {
    const { t } = useTranslation()
    const shoppingCart = useShoppingCart('FUNDS')
    const isMobile = useMediaQuery('(max-width: 600px)')

    return (
        <Button
            data-cy="cartButton"
            variant={cartOpen ? 'contained' : 'outlined'}
            startIcon={<i className="ri-shopping-cart-2-line"/>}
            sx={{
                ml: 'auto',
                borderColor: 'rgba(0,0,0,0.1)',
                borderRight: 'none',
                borderTop: 'none',
                borderBottom: 'none',
                borderRadius: '0',
                '& .MuiButton-startIcon i': {
                    fontSize: '2rem',
                    lineHeight: 1,
                },
                '& .MuiButton-startIcon': {
                    xs: {
                        marginRight: '0'
                    },
                    sm: {
                        marginRight: '0.8rem'
                    }
                },
                '&:hover': {
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderRight: 'none',
                    borderTop: 'none',
                    borderBottom: 'none',
                }
            }}
            endIcon={shoppingCart.isLoaded &&
                <Avatar
                    sx={{width: '2rem', height: '2rem', backgroundColor: PURPLE[500]}}>
                    <Box sx={{fontSize: '1.6rem'}}>{shoppingCart.getItemCount()}</Box>
                </Avatar>
            }
            onClick={() => setCartOpen(!cartOpen)}>
            { isMobile ? null : t('pages-fund-details.shoppingCart')}
        </Button>
    )
}
