import { Currency } from "@fixrate/fixrate-query"

const currencyHasBenchmarkInterest = (currency: Currency): boolean => {
    const currenciesWithBenchmarkInterest = ["NOK", "SEK"]
    return currenciesWithBenchmarkInterest.includes(currency)
}

function getCountryCodeFromCurrency(currency: Currency): "NO" | "SE" {
    switch (currency) {
        case 'NOK':
            return 'NO'
        case 'SEK':
            return 'SE'
        default:
            return 'NO'
    }
}


export { currencyHasBenchmarkInterest, getCountryCodeFromCurrency }