import Modal from '#components/Modal'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AsyncButton from '../Button/AsyncButton'
import ButtonRow from '../ButtonRow/ButtonRow'
import styles from './OnboardingFrame.module.scss'

type Props = {
    className?: string,
    cancel?: string,
    onCancel?: () => void,
    header?: ReactNode,
    showLogo?: boolean,
    visibleOverflow?: boolean,
    progress?: ReactNode,
    children: ReactNode,
    buttons?: ReactNode,
    showCancelButton?: boolean,
    nextUrl?: string,
    nextButtonText?: string,
    onNext?: () => void,
    footerBlock?: ReactNode
}


export default function OnboardingFrame(props: Props) {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <Modal className={classNames(styles.modal, props.className)}
               cancel={props.cancel}
               onCancel={props.onCancel}
               header={props.header}
               showLogo={props.showLogo}
               visibleOverflow={props.visibleOverflow}
        >
            <div className={classNames(styles.body, props.className)}>
                {props.progress &&
                    <div className={styles.progress}>
                        {props.progress}
                    </div>
                }
                {props.progress &&
                    <div className={styles.progressMobile}>
                        {props.progress}
                    </div>
                }
                <div className={styles.content}>
                    {props.children}
                </div>
                <ButtonRow className={styles.buttonRow}>
                    {props.buttons}
                    {props.showCancelButton ? <AsyncButton variant="outlined" onClick={() => {
                        navigate(props.cancel)
                    }}>{t('components-OnboardingFrame.postpone')}</AsyncButton> : ''}
                    {props.nextUrl ? <AsyncButton id="nextButton" onClick={() => {
                        navigate(props.nextUrl)
                    }}>{props.nextButtonText || t('components-OnboardingFrame.next')}</AsyncButton> : ''}
                    {props.onNext ? <AsyncButton id="nextButton"
                                            onClick={() => props.onNext()}>{props.nextButtonText || t('components-OnboardingFrame.next')}</AsyncButton> : ''}
                </ButtonRow>
            </div>

            {props.footerBlock &&
                <div className={styles.footerBlock}>
                    {props.footerBlock}
                </div>
            }
        </Modal>
    )

}




