import { useEffect, useRef, useState } from 'react'
import {Doughnut} from 'react-chartjs-2'
import {useSelector} from '#state/useSelector'
import LoadingSpinner from "#components/LoadingSpinner/LoadingSpinner"
import styles from './SimpleExposureChart.module.scss'
import {SINGLE_CATEGORY_COLORS} from '#app/colors/colors'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import {StaggDataItem} from '#state/stagg'

type Props = {
    getData: () => Promise<StaggDataItem>
    depositorId: string
}

const SimpleExposureChart = ({ getData, depositorId }: Props) => {
    const {t} = useTranslation()
    const session = useSelector(state => state.session)
    const deposits = useSelector(state => state.deposits)
    const banks = useSelector(state => state.banks)

    const chartRef = useRef();

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);
        getData()
            .then(setData)
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }, [getData, session.authenticated])

    const formatVolume = (volume) => volume.toLocaleString('no-NO', {maximumFractionDigits: 0})
    const namesEqual = ((name1, name2) => name1?.toLowerCase().trim() === name2.toLowerCase().trim())
    const balance = (deposit) => deposit.calculatedTotalBalance ? deposit.calculatedTotalBalance : deposit.calculatedFinalBalance

    const depositsForDepositor = deposits.filter(deposit => deposit.depositor.id === depositorId && !deposit.expires?.expired)
    const totalCalculatedBalance = () => depositsForDepositor.reduce((acc, deposit) => acc + balance(deposit), 0)
    const calculatedBalance = (name) => depositsForDepositor.filter(deposit => getBankIds(name).includes(deposit.bankId)).reduce((acc, deposit) => acc + balance(deposit), 0)

    const getBankIds = (nameOrType) => Object.values(banks)
        .filter(bank => namesEqual(bank.name, nameOrType) || namesEqual(bank.bankType, nameOrType))
        .map(bank => bank.id)

    const {series} = !!data && data.length > 0 && data[0]
    const typedData = series
        ?.filter(s => s.values[s.values.length-1][1])
        .map(s => ({name: s.tags.name, volume: s.values[s.values.length-1][1], interest: s.values[s.values.length-1][2]}));

    const total = typedData?.reduce((acc, s) => acc + s.volume, 0)

    return (
        <div ref={chartRef} style={{overflow: 'hidden'}}>
            <div style={{float: 'left', clear: 'both', width: '100%'}}>
                <div style={{float: 'left'}}>
                    {typedData !== undefined && typedData?.length > 0 && (
                    <table className={styles.exposureTable}>
                        <thead>
                            <tr>
                                <th>{t('pages-analytics.exposureTableSimpleBank')}</th>
                                <th><Tooltip title={t('pages-analytics.exposureTableSimpleVolumeTooltip')}><span>{t('pages-analytics.exposureTableSimpleVolume')}</span></Tooltip></th>
                                <th>{t('pages-analytics.exposureTableSimpleShare')}</th>
                                <th>{t('pages-analytics.exposureTableSimpleWeightedInterestRate')}</th>
                                <th><Tooltip title={t('pages-analytics.exposureTableSimpleCalculatedBalanceTooltip')}><span>{t('pages-analytics.exposureTableSimpleCalculatedBalance')}</span></Tooltip></th>
                            </tr>
                        </thead>

                        <tbody>
                            {typedData?.sort((a, b) => b.volume - a.volume).map((data, index) => (
                                <tr key={data.name}>
                                    <td><span style={{color: SINGLE_CATEGORY_COLORS[index]}}>&#9646;</span> {data.name}</td>
                                    <td className={styles.valueCell}>{data.volume % 1 === 0 ? data.volume : data.volume?.toFixed(2)}M</td>
                                    <td className={styles.valueCell}>{(data.volume*100/total)?.toFixed(1)}%</td>
                                    <td className={styles.valueCell}>{data.interest?.toFixed(2)}%</td>
                                    <td style={{width: '15rem'}} className={styles.valueCell}>kr {formatVolume(calculatedBalance(data.name))}</td>
                                </tr>
                            ))}
                        </tbody>

                        <tfoot>
                        <tr>
                            <td style={{paddingLeft: '22px'}}>{t('pages-analytics.exposureTotally')}</td>
                            <td className={styles.valueCell}>{total.volume % 1 === 0 ? total : total.toFixed(2)}M</td>
                            <td/>
                            <td/>
                            <td className={styles.valueCell}>kr {formatVolume(totalCalculatedBalance())}</td>
                        </tr>
                        </tfoot>
                    </table>
                    )}
                    {(typedData === undefined || typedData?.length === 0) && (
                        <p className={styles.description}>{t('pages-analytics.exposureTableEmptyDescription')}</p>
                    )}
                </div>

                {typedData?.length > 0 && (
                <div className={styles.chart} style={{float: 'right'}}>
                    {loading && <LoadingSpinner text={'Henter data...'}/>}

                    {typedData && <Doughnut
                        data={{
                            labels: typedData?.map(s => s.name + ': ' + s.volume + 'M'),
                            datasets: [{
                                data: typedData?.map(s => s.volume),
                                backgroundColor: SINGLE_CATEGORY_COLORS,
                                hoverBackgroundColor: SINGLE_CATEGORY_COLORS.map(color => color + "99")
                            }],
                        }}

                        options={{
                            maintainAspectRatio: true,
                            aspectRatio: 1.6,
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'left',
                                    labels: {
                                        usePointStyle: true,
                                        padding: 20
                                    },
                                },
                                tooltip: {
                                    callbacks: {
                                        label: (item) => item?.label + ': ' + item?.parsed + 'M' + (total > 0 && (', ' + (100 * item?.parsed / total).toFixed(1) + '%'))
                                    }
                                }
                            }
                        }}
                    />}
                </div>
                )}
            </div>
        </div>
    )
}

export default SimpleExposureChart
