import { BURNT_ORANGE, PURPLE, SPRING_GREEN, SUNSET_ORANGE } from "#app/colors/colors";
import { Box, ButtonBase, Stack, SxProps, Typography } from "@mui/material";

type Props = {
    onClick: () => void
    sx?: SxProps
    title: string
    description?: string
    icon?: string
    dataCy?: string
    color?: 'PURPLE' | 'SPRING_GREEN' | 'SUNSET_ORANGE' | 'BURNT_ORANGE'
}

const COLOR_VARIABLES = {
    PURPLE: PURPLE,
    SPRING_GREEN: SPRING_GREEN,
    SUNSET_ORANGE: SUNSET_ORANGE,
    BURNT_ORANGE: BURNT_ORANGE
}

export default function ListButton({ onClick, sx, title, description, icon, dataCy, color }: Props) {
    const colorVariable = COLOR_VARIABLES[color || 'SPRING_GREEN']

    return (
        <ButtonBase data-cy={dataCy} onClick={onClick} tabIndex={0} sx={{
            ...sx,
            borderRadius: '0.4rem',
            border: '0.2rem solid transparent',
            '&:hover': {
                borderColor: PURPLE[500]
            },
            '&:focus': {
                borderColor: PURPLE[500]
            }
        }}>
            <Stack
                maxWidth={"40rem"}
                textAlign={"left"}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
                sx={{
                    backgroundColor: colorVariable[50],
                    p: "2rem",
                }}
            >
                <Box>
                    <Typography fontWeight={"700"} mb={"0.7rem"} variant="h3">
                        { icon && <i className={icon} /> }
                        <span>{title}</span>
                    </Typography>
                    { description && (
                        <Typography lineHeight={"1.2"} fontSize={'1.4rem'}>
                            {description}
                        </Typography>
                    )}
                </Box>
                <Stack
                    sx={{
                        backgroundColor: colorVariable[100],
                        aspectRatio: "1/1"
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"50rem"}
                    minWidth={"5rem"}
                    minHeight={"5rem"}
                >
                    <i
                        className="ri-arrow-right-line"
                        style={{ fontSize: "3rem" }}
                    />
                </Stack>
            </Stack>
        </ButtonBase>
    )
}
