import TradeOrder from '#app/pages/orders/depositor/TradeOrder/TradeOrder'
import { PageHeader } from '#components'
import FundOrderOverview from '#pages/portfolio-depositor/FundPlacements/FundOrderOverview'
import * as selector from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import OrdersList from './OrdersList'

export default function OrdersDepositor() {
    const { t } = useTranslation()

    const canceledOrdersSortFunction = (a, b) =>
        new Date(b.lastHistoryAction?.time || b.created).getTime() -
        new Date(a.lastHistoryAction?.time || a.created).getTime()

    const ordersSortFunction = (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()

    const ordersWithStateInitialProcessing = useSelector(({ orders }) =>
        orders.filter((order) => order.orderState === 'INITIAL_PROCESSING')
    ).sort(ordersSortFunction)

    const ordersWithStateReadyForSigning = useSelector(selector.ordersWithStateReadyForSigning).sort(ordersSortFunction)
    const ordersWithStateReadyForApproval = useSelector(selector.ordersWithStateReadyForApproval).sort(ordersSortFunction)
    const ordersWithStateReadyForTransaction = useSelector(selector.ordersWithStateReadyForTransaction).sort(ordersSortFunction)
    const ordersWithStateReadyForTransactionConfirmation = useSelector(selector.ordersWithStateReadyForTransactionConfirmation).sort(ordersSortFunction)
    const newCanceledOrders = useSelector(selector.newOrdersWithStateCancelled).sort(canceledOrdersSortFunction)
    const oldCanceledOrders = useSelector(selector.oldOrdersWithStateCancelled).sort(canceledOrdersSortFunction)

    const RedirectComponent = () => {
        // Calculates correct sub-route based on the following:
        // 1. Redirect to /signing if there are orders ready for signing.
        // 2. Redirect to /transaction if there are orders ready for transaction.
        // 3. Redirect to /waiting if there are waiting orders.
        // 4. Redirect to /approval if there are orders waiting for approval.
        // 5. Redirect to /waiting if there are no orders.

        if (ordersWithStateReadyForSigning.length > 0) {
            return <Navigate to={{ pathname: '/orders/signing' }} />
        } else if (ordersWithStateReadyForTransaction.length > 0) {
            return <Navigate to={{ pathname: '/orders/transaction' }} />
        } else if (ordersWithStateInitialProcessing.length + newCanceledOrders.length > 0) {
            return <Navigate to={{ pathname: '/orders/waiting' }} />
        } else if (ordersWithStateReadyForApproval.length > 0) {
            return <Navigate to={{ pathname: '/orders/approval' }} />
        } else if (ordersWithStateReadyForTransactionConfirmation.length > 0) {
            return <Navigate to={{ pathname: '/orders/transaction-confirmation' }} />
        } else {
            return <Navigate to={{ pathname: '/orders/waiting' }} />
        }
    }

    return (
        <>
            <PageHeader icon='ri-checkbox-circle-line' title={t('pages-orders.depositorOrdersHeading')} />
            <div className='layout__main-content'>
                <Routes>
                    <Route path='funds' element={<FundOrderOverview />} />
                    <Route path='funds/:id' element={<TradeOrder />} />
                    <Route path='' element={<RedirectComponent />} />
                    <Route
                        path='waiting'
                        element={
                            <OrdersList
                                orders={[...ordersWithStateInitialProcessing, ...newCanceledOrders]}
                                ingress={t('pages-orders.depositorBankProcessingIngress')}
                            />
                        }
                    />
                    <Route
                        path='signing'
                        element={
                            <OrdersList
                                orders={ordersWithStateReadyForSigning}
                                showSignAllButton={ordersWithStateReadyForSigning.length > 0}
                                ingress={t('pages-orders.depositorReadyForSigningIngress')}
                            />
                        }
                    />
                    <Route
                        path='approval'
                        element={
                            <OrdersList
                                orders={ordersWithStateReadyForApproval}
                                ingress={t('pages-orders.depositorReadyForApprovalIngress')}
                            />
                        }
                    />
                    <Route
                        path='transaction'
                        element={
                            <OrdersList
                                orders={ordersWithStateReadyForTransaction}
                                ingress={t('pages-orders.depositorReadyForTransactionIngress')}
                            />
                        }
                    />
                    <Route
                        path='transaction-confirmation'
                        element={
                            <OrdersList
                                orders={ordersWithStateReadyForTransactionConfirmation}
                                ingress={t('pages-orders.depositorReadyForTransactionConfirmationIngress')}
                            />
                        }
                    />
                    <Route
                        path='canceled'
                        element={
                            <OrdersList
                                orders={newCanceledOrders}
                                hiddenOrders={oldCanceledOrders}
                                ingress={t('pages-orders.depositorCanceledOrdersIngress')}
                                showNavigation={false}
                            />
                        }
                    />
                </Routes>
            </div>
        </>
    )
}
