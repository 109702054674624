import { useCommand } from '#command'
import { LoadingSpinner, PageHeader, PageLayout, Paper } from '#components'
import FundTransactionsImportTable from '#pages/FundTransactions/FundTransactionsImportTable'
import { useSelector } from '#state/useSelector'
import {
    FundDto, FundPlacementTransactionType,
    FundPortfolioTransactionDto,
    FundPortfolioTransactionTypeDto,
    FundShareClassDto
} from '@fixrate/fixrate-query'
import {Button} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export type ImportableTransaction = FundPortfolioTransactionDto & {
    index: number
    selected: boolean
    originalType: SelectableTransactionType
    mappedType: SelectableTransactionType
}

export type SelectableTransactionType = FundPlacementTransactionType | "UNKNOWN"

type Params = {
    depositorId?: string
}

export default function FundTransactionsImportCustomer() {
    const {t} = useTranslation()
    const {depositorId} = useParams<Params>()
    const navigate = useNavigate()
    const {importFundTransactions} = useCommand();

    const [importableTransactions, setImportableTransactions] = useState<ImportableTransaction[]>([])
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fundCustomers = useSelector(state => state.fundCustomers)
    const customer = fundCustomers.find(c => c.depositorId === depositorId)
    const funds = useSelector(state => state.funds)

    function mapTransactionType(type: FundPortfolioTransactionTypeDto): SelectableTransactionType {
        if (type === 'BUY') {
            return 'BUY'
        }
        if (type === 'SELL') {
            return 'SELL'
        }
        if (type === 'DIVIDEND') {
            return 'DIVIDEND'
        }
        if (type === 'ADD') {
            return 'UNKNOWN'
        }
    }

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true)
            try {
                const response = await fetch(`/api/fund/fund-portfolio-transactions/${depositorId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })

                const faTransactions = (await response.json() as FundPortfolioTransactionDto[])
                const transactionsForImport: ImportableTransaction[] = faTransactions.map((t, index) => ({
                    ...t,
                    index: index,
                    selected: false,
                    originalType: mapTransactionType(t.type),
                    mappedType: mapTransactionType(t.type)
                }))
                setImportableTransactions(transactionsForImport)
                setLoaded(true)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        if (!loading && !loaded) {
            fetchTransactions().catch(console.error)
        }
    }, [depositorId, loaded, loading])

    const fundShareClassesByIsin = funds.flatMap(f => f.fundShareClasses).reduce((acc, fsc) => {
        acc[fsc.isin] = fsc
        return acc
    }, {} as {[isinCode: string]: FundShareClassDto})
    const fundsByIsin = funds.flatMap(f => f.fundShareClasses).reduce((acc, fsc) => {
        acc[fsc.isin] = funds.find(f => f.id === fsc.fundId)
        return acc
    }, {} as {[isinCode: string]: FundDto})

    const uniqueIsinCodes = Array.from(new Set<string>(importableTransactions.map(t => t.isinCode)))

    const handleImport = async () => {
        if (selectedTransactions.length > 0 && canImportSelected()) {
            const {waitForCommand} = await importFundTransactions(customer.depositorId, selectedTransactions
                .map(t => ({
                    fundShareClassId: fundShareClassesByIsin[t.isinCode].id,
                    transactionDate: t.transactionDate,
                    settlementDate: t.settlementDate,
                    unitPrice: t.unitPrice,
                    unitQuantity: t.unitQuantity,
                    amount: t.amount,
                    roundingError: t.roundingError,
                    type: t.mappedType as FundPlacementTransactionType,
                })))
            const success = await waitForCommand()
            if (success) {
                navigate(`/customer/${customer.depositorId}`)
            }

            navigate('/fund-transactions')
        }
    }

    function canImportSelected() {
        return selectedTransactions.every(t => t.mappedType !== 'UNKNOWN')
    }

    const canImport = importableTransactions.some(t => t.selected)
    const selectedTransactions = importableTransactions.filter(t => t.selected)

    return (
        <>
            <PageHeader title={t('pages-fundTransactionsImport.header')} backToLink={'/fund-transactions/import'}/>
            <PageLayout>
                { loading ? <LoadingSpinner /> : (
                    <Paper sx={{width: "100%"}} title={`${customer?.name} - ${t('pages-fundTransactionsImport.cid')}:${customer?.portfolios
                        .filter(p => p.fundData.cid != null)
                        .map(p => p.fundData.cid)
                        .reduce((acc, cid) => acc + ", " + cid)}`}>
                        {importableTransactions.length === 0 && (
                            <p>{t('pages-fundTransactionsImport.noTransactionsFound')}</p>
                        )}

                        {uniqueIsinCodes.map(isinCode => {
                            function updateTransactions(updatedTransactions: ImportableTransaction[]) {
                                const nextTransactions = importableTransactions.map(t => {
                                    if (t.isinCode === isinCode) {
                                        return updatedTransactions.find(ut => ut.index === t.index) || t
                                    }
                                    return t
                                })
                                setImportableTransactions(nextTransactions)
                            }
                            const shareClass = fundShareClassesByIsin[isinCode]
                            const fund = fundsByIsin[isinCode]
                            const decimalPrecision = fund?.decimalPrecision ?? 4
                            const transactionsForShareClass = importableTransactions.filter(t => t.isinCode === isinCode)
                            return transactionsForShareClass && (
                                <FundTransactionsImportTable key={isinCode}
                                                             transactions={transactionsForShareClass}
                                                             updateTransactions={updateTransactions}
                                                             isinCode={isinCode}
                                                             shareClass={shareClass}
                                                             decimalPrecision={decimalPrecision}
                                />
                            )
                        })}

                        <p>{t('pages-fundTransactionsImport.numberOfSelectedTransactions', {count: selectedTransactions.length})}</p>
                        <Button variant="contained" color="primary" onClick={handleImport} disabled={!canImport}>
                            {t('pages-fundTransactionsImport.importButtonLabel')}
                        </Button>
                    </Paper>
                )}
            </PageLayout>
        </>
    )
}
