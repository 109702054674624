import AccountStatementListWithUpload from '#pages/portfolio-bank/AccountStatements/AccountStatementListWithUpload'
import AccountStatementManual from '#pages/portfolio-bank/AccountStatements/AccountStatementManual'
import { Route, Routes } from 'react-router-dom'

const ReportsBank = () => {
    return (
        <Routes>
            <Route path='balance-reporting/:documentId' element={<AccountStatementManual />} />
            <Route path='balance-reporting' element={<AccountStatementListWithUpload />} />
        </Routes>
    )
}

export default ReportsBank
