import { PURPLE, SUNSET_ORANGE } from '#app/colors/colors'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import ListButton from '#app/components/ListButton/ListButton'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const NewAdModal = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <FxDialog maxWidth='md' open={true}>
            <FxDialogTitle onClose={() => navigate('/ads')}>{t('pages-BankAds.newAdHeader')}</FxDialogTitle>
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: SUNSET_ORANGE[50] }}>
                <FixrateIllustration width='45rem' name='manCoffeeDocument' color='multiColor' />
            </Stack>
            <FxDialogContent sx={{ maxWidth: '80rem' }}>
                <Typography mb={0.5} variant='h3' fontWeight={'700'}>
                    {t('pages-BankAds.newAdChooseHeader')}
                </Typography>
                <Typography mb={1}>{t('pages-BankAds.newAdInfo1')}</Typography>
                <Accordion sx={{ p: 0, boxShadow: 'none' }}>
                    <AccordionSummary
                        sx={{ p: 0, color: PURPLE[500], alignItems: 'center', justifyContent: 'center', display: 'inline-flex', '& .MuiSvgIcon-root': { color: PURPLE[800] } }}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <Typography color={PURPLE[800]} variant='h4'>
                            {t('pages-BankAds.whatHappensWhenYouCreateAd')}
                        </Typography>
                        <Stack
                            alignItems={'center'}
                            justifyContent='center'
                            mr={1}
                            sx={{ ml: '5px', backgroundColor: PURPLE[50], color: PURPLE[500], aspectRatio: '1/1', width: '2.4rem', height: '2.4rem', borderRadius: '10rem' }}
                        >
                            <i className='ri-information-line'  />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ py: 2, borderRadius: '0.4rem', backgroundColor: PURPLE[50] }}>
                        <Typography mb={1} color={PURPLE[800]}>
                            {t('pages-BankAds.newAdInfo2')}
                        </Typography>
                        <Typography color={PURPLE[800]}>{t('pages-BankAds.newAdInfo3')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Stack gap={2} mt={2} direction={{ md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }} mb={2}>
                    <ListButton
                        onClick={() => navigate('/ads/new-ad/notice')}
                        title={t('pages-BankAds.newAdNoticeHeader')}
                        description={t('pages-BankAds.newAdNoticeDescription')}
                        icon={'ri-exchange-funds-line'}
                        sx={{ alignItems: 'stretch' }}
                        dataCy='newNoticeAd'
                    />
                    <ListButton
                        onClick={() => navigate('/ads/new-ad/fixed')}
                        title={t('pages-BankAds.newAdFixedHeader')}
                        description={t('pages-BankAds.newAdFixedDescription')}
                        icon={'ri-calendar-event-line'}
                        sx={{ alignItems: 'stretch' }}
                        dataCy='newFixedTerminationAd'
                    />
                </Stack>
            </FxDialogContent>
        </FxDialog>
    )
}
