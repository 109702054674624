import Paper from '#app/components/Paper/FxPaper'
import DocumentLink from '../../common/DocumentLink/DocumentLink'
import {DepositDto} from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

export default function DepositDetailsInterests({deposit}: { deposit: DepositDto }) {
    const {t} = useTranslation()

    return (
        <Paper title={t('pages-portfolio-depositor.interestCalculationsTitle')}>
            <p>{t('pages-portfolio-depositor.interestCalculationsInfo1')}</p>
            <DocumentLink name={t('pages-portfolio-depositor.interestCalculationsDocumentName')} link={`/document/deposit/${deposit.id}/interests`}/>
        </Paper>
    )
}
