import Modal from '#components/Modal'
import { useNavigate } from 'react-router-dom'
import styles from './ModalConfirmation.module.css'

type Props = {
    onSubmit: () => void,
    backUrl: string,
    text: string,
    header: string
}

/**
 * A modal dialog designed to ask the user for confirmation and then execute a function
 */
export default function ModalConfirmation({onSubmit, backUrl, text, header}: Props) {
    const navigate = useNavigate()

    async function submit() {
        await onSubmit()
        navigate(backUrl + '#!', { replace: true })
    }

    return (
        <Modal cancel={backUrl}
               width="40rem"
               header={header}
               onSubmit={submit}>

            <p className={styles.body}>
                {text}
            </p>

        </Modal>
    )
}
