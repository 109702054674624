import config from '#app/config'
import { DateOutput } from '#components'
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner'
import Paper from '#app/components/Paper/FxPaper'
import PeriodPicker from '#components/PeriodPicker/PeriodPicker'
import { useSelector } from '#state/useSelector'
import { Alert, AlertTitle, Divider } from '@mui/material'
import format from 'date-fns/format'
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { ReportDownload } from '../../ReportDownload'
import styles from './MonthlyReports.module.css'
import usePortfolio from '#services/usePortfolio'


export default function MonthlyReports() {
    const {t} = useTranslation()

    const [interestAndBalancePeriod, setInterestAndBalancePeriod] = useState(format(new Date(), 'yyyy-MM'))
    const [fundPeriod, setFundPeriod] = useState(format(new Date(), 'yyyy-MM'))

    const depositor = useSelector(state => state.depositor)
    const portfolio = usePortfolio()

    const calculatedDates = useSelector(state => state.deposits.map(deposit => deposit.product.type !== 'FIXED' && deposit.calculatedDate ? Date.parse(deposit.calculatedDate) : null))
    const maxDate = DateOutput.formatDate(new Date(Math.max(...calculatedDates)))

    // todo switch
//    const showFundReports = useSelector(hasFundAccessSelector)
    const session = useSelector(({session}) => session)
    const showFundReports = !config().isProduction && session.firstName === 'Bjørn Oddvar' && session.lastName === 'Landsem'

    return (
        <Fragment>
            <Paper>
                <h3>{t('pages-portfolio-depositor.reportsInterestAndBalanceHeader')}</h3>
                <p>{t('pages-portfolio-depositor.reportsInterestAndBalanceInfo1')}</p>
                <p>{t('pages-portfolio-depositor.reportsInterestAndBalanceInfo2')}</p>
                <p>{t('pages-portfolio-depositor.reportsInterestAndBalanceInfo4')}</p>

                <PeriodPicker value={interestAndBalancePeriod} onChange={setInterestAndBalancePeriod}/>

                <div className={styles.updatedDate}>{t('pages-portfolio-depositor.reportsInterestAndBalanceUpdatedDate', {date: maxDate})}</div>

                {!depositor && <LoadingSpinner text={t('pages-portfolio-depositor.reportsLoading')}/>}
                <Divider sx={{mt: 2, mb: 1}}/>
                <ul style={{marginBottom: '0'}}>
                    {depositor && (
                        <ReportDownload
                            name={t('pages-portfolio-depositor.interestAndBalanceReport')}
                            description={interestAndBalancePeriod}
                            link={`/document/depositor/${depositor?.id}/overview?period=${interestAndBalancePeriod}&portfolioId=${portfolio?.id}`}
                            dataCy='depositorSection'
                            keyValue={"interestAndBalance" + depositor?.id + interestAndBalancePeriod}
                        />
                    )}
                </ul>
            </Paper>

            <Alert elevation={1} sx={{maxWidth: '60rem'}} severity='info'>
                <AlertTitle>{t('pages-portfolio-depositor.somethingWrongWithReportTitle')}</AlertTitle>
                {t('pages-portfolio-depositor.somethingWrongWithReportDescription')}
            </Alert>

            {showFundReports && (
                <Paper>
                    <h3>{t('pages-portfolio-depositor.reportsFundHeader')}</h3>
                    <p>{t('pages-portfolio-depositor.reportsFundInfo1')}</p>

                    <PeriodPicker
                        value={fundPeriod}
                        onChange={setFundPeriod}
                        showPeriods="YM"
                    />

                    {!depositor && <LoadingSpinner text={t('pages-portfolio-depositor.reportsLoading')}/>}

                    <ul>
                        {depositor && (
                            <ReportDownload
                                name={t('pages-portfolio-depositor.reportsFundReportFor', {period: fundPeriod})}
                                link={`/document/depositor/${depositor.id}/fund?period=${fundPeriod}&portfolioId=${portfolio.id}`}
                                dataCy='depositorFundSection'
                                keyValue={"fund" + depositor.id}
                            />
                        )}
                    </ul>
                </Paper>
            )}
        </Fragment>
    );
}
