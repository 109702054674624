import { Fragment } from 'react';
import SignedDocuments from './SignedDocuments'
import InitialProcessing from './InitialProcessing'
import OrderReadyForTransaction from './OrderReadyForTransaction'
import {ActionBlock} from "#components"
import styles from './OrderDialog.module.scss'
import {useTranslation} from "#components/i18n"
import {DocumentDto, OrderDto} from '@fixrate/fixrate-query'

export type Props = {
    order: OrderDto,
    documents: DocumentDto[]
}

export default function OrderDialog(props: Props) {

    const {t} = useTranslation('pages-orders')

    let header
    let content
    let icon
    let iconColor

    const reasonCategoryText = (status) => {
        if (status === 'CUSTOMER_DECLARATION') {
            return t('customerDeclaration')
        }
        if (status === 'OVEREXPOSED') {
            return t('overexposed')
        }
        if (status === 'SEGMENT_NOT_ALLOWED') {
            return t('notAllowedSegment')
        }
        if (status === 'OTHER') {
            return t('otherReasons')
        }
    }

    switch (props.order.orderState) {
        case 'INITIAL_PROCESSING':
            content = <InitialProcessing orderId={props.order.id}/>
            header = t('bankInitialProcessingHeader')
            break
        case 'READY_FOR_SIGNING':
            content = (
                <section className={styles.waiting}>
                    <p className={styles.waitingMessage}>
                        {t('bankReadyForSigningText')}
                    </p>
                </section>
            )
            header = t('bankReadyForSigningHeader')
            break
        case 'READY_FOR_APPROVAL':
            content = <SignedDocuments order={props.order} documents={props.documents}/>
            header = t('bankReadyForApprovalHeader')
            break
        case 'READY_FOR_TRANSACTION':
        case 'READY_FOR_TRANSACTION_CONFIRMATION':
            content = <OrderReadyForTransaction order={props.order} documents={props.documents}/>
            header = t('bankReadyForTransactionConfirmationHeader')
            break
        case 'CANCELED':
            header = t('bankRejectedHeader')
            icon = 'ri-error-warning-line'
            iconColor = '#B44A06'

            content = (
                <Fragment>
                    <p>{t('bankRejectedText')}</p>
                    <h4>{t('rejectReason')}</h4>
                    <p>{reasonCategoryText(props.order.cancellationCategory)}</p>
                    <h4>{t('rejectReasonDescription')}</h4>
                    <p id="reasonParagraph">{props.order.reasonForCancelling}</p>
                </Fragment>
            )
            break
        case 'COMPLETED':
            content = <p>{t('bankCompletedText')}</p>
            header = t('bankCompletedHeader')
            break
    }

    return (
        <ActionBlock iconColor={iconColor} icon={icon} className={styles.orderDialog} header={header}>
            {content}
        </ActionBlock>
    )
}
