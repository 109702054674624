import { formatOrganizationNumber } from '#app/services/formatnumber'
import { useSelector } from '#app/state/useSelector'
import useTableSort, { Column } from '#services/useTableSort'
import { FundCustomerDto } from '@fixrate/fixrate-query'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useUiSetting from '#services/useUiSetting'

type Row = FundCustomerDto & {
    signedContracts: boolean
}

type Props = {
    customers: FundCustomerDto[]
}

export default function FundCustomerList({customers}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useUiSetting('FundCustomerList-rowsPerPage', 50)
    const documents = useSelector(state => state.documents)

    const rows: Row[] = customers.map(customer => ({
        ...customer,
        signedContracts: customer.contracts.length > 0 && customer.contracts.every(contract => documents[contract.documentId]?.signedByAll),
    }))

    const {sortedRows, sortDirection, sortKey, setSorting} = useTableSort<Row>(rows, 'name', 'asc')

    // TODO Needs to respect multiple portfolios; have removed cid and minOrderVolume temporarily.
    const columns: readonly Column<Row>[] = [
        {
            id: 'name',
            label: t('pages.fundCustomers.customerList.tableHeaders.name'),
        },
        {
            id: 'nationalIdentity',
            label: t('pages.fundCustomers.customerList.tableHeaders.orgNumber'),
        },
        {
            id: 'enabled',
            label: t('pages.fundCustomers.customerList.tableHeaders.enabled'),
        },
        {
            id: 'signedContracts',
            label: t('pages.fundCustomers.customerList.tableHeaders.signedContracts'),
        },
        {
            id: 'buyEnabled',
            label: t('pages.fundCustomers.customerList.tableHeaders.buyEnabled'),
        },
        // {
        //     id: 'portfolios.fundData.cid',
        //     label: t('pages.fundCustomers.customerList.tableHeaders.cid'),
        // },
        // {
        //     id: 'minOrderVolume',
        //     label: t('pages.fundCustomers.customerList.tableHeaders.minOrderVolume'),
        // },
    ]

    const Pagination = (
        <TablePagination
            labelRowsPerPage={t('pages.fundCustomers.customerList.rowsPerPage')}
            labelDisplayedRows={({from, to, count}) => t('pages.fundCustomers.customerList.displayedRows', {from, to, count})}
            rowsPerPageOptions={[50, 100, 250, 500]}
            component="div"
            count={customers.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value)
                setPage(0)
            }}
        />
    )
    return (
        <TableContainer>
            {Pagination}
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                sortDirection={sortKey === column.id ? sortDirection : false}
                            >
                                <TableSortLabel
                                    active={sortKey === column.id}
                                    direction={sortKey === column.id ? (sortDirection === 'asc' ? 'asc' : 'desc') : 'asc'}
                                    onClick={() => setSorting(column.id)}
                                >
                                    {column.label}
                                    {sortKey === column.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer) => (
                        <TableRow
                            key={customer.depositorId}
                            sx={{"&:hover": {backgroundColor: 'rgba(77, 42, 152, 0.05)', cursor: 'pointer'}}}
                            onClick={() => navigate('/fund-customers/' + customer.depositorId)}
                        >
                            <TableCell sx={{fontWeight: '600', width: '40rem'}}>{customer.name}</TableCell>
                            <TableCell>{formatOrganizationNumber(customer.nationalIdentity, "NO")}</TableCell>
                            <TableCell>{customer.enabled ? <i className="ri-check-line green"/> : <i className="ri-close-line red"/>}</TableCell>
                            <TableCell>{customer.signedContracts ? <i className="ri-check-line green"/> : <i className="ri-close-line red"/>}</TableCell>
                            <TableCell>{customer.buyEnabled ? <i className="ri-check-line green"/> : <i className="ri-close-line red"/>}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {Pagination}
        </TableContainer>
    )
}
