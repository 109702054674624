import { useState } from 'react';
import {Checkbox, Tooltip} from '@mui/material'
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

type Props = {
    inputText?: string
}

export default function ClipboardCopyButton({inputText}: Props) {
    const [isCopied, setIsCopied] = useState(false)
    const text = inputText ?? ''

    function handleCopyClick() {
        copyTextToClipboard(text)
            .then(() => {
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 3000)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <Tooltip title={isCopied ? 'Kopiert!' : 'Kopier'} placement="right">
            <Checkbox
                size={'small'}
                checked={isCopied}
                onChange={handleCopyClick}
                icon={<ContentCopySharpIcon />}
                checkedIcon={<CheckCircleSharpIcon />}
            />
        </Tooltip>
    )
}

export async function copyTextToClipboard(text: string): Promise<void> {
    return await navigator.clipboard?.writeText(text)
}