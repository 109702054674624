import { updateSignatureStatus } from '#services/thunks/documents'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from './Signature.module.css'
import SignicatSignature from './SignicatSignature'

type Params = {
    processId?: string
}
export default function Signature() {
    const navigate = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const signatureProcess = useSelector(state => state.signatureProcess)
    const {processId} = useParams<Params>()

    const onSignatureSuccess = () => {
        dispatch(updateSignatureStatus())
        const query = parse(location.search)
        navigate(query.context)
    }

    if (!signatureProcess || signatureProcess.processId !== processId) {
        return (
            <p className={classNames(styles.loadingDocuments, 'loading')}>
                {t('pages-signature.loading')}
            </p>
        )
    } else {
        switch (signatureProcess.status) {

            case 'SENT_TO_SIGNICAT':
                return (
                    <div className={styles.signature}>
                        <SignicatSignature
                            onSignatureSuccess={onSignatureSuccess}
                            signatureUrl={signatureProcess.signatureUrl}
                        />
                    </div>
                )

            case 'SIGNED':
                return (
                    <p className={styles.loadingDocuments}>
                        <p>{t('pages-signature.alreadySigned')}</p>
                    </p>
                )

            default:
                return (
                    <p className={classNames(styles.loadingDocuments, 'loading')}>
                        <p>{t('pages-signature.preparingDocument')}</p>
                    </p>
                )
        }
    }
}



