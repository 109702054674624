import PropTypes from 'prop-types'
import config from '#app/config'
import DownloadIcon from '../../common/icons/DownloadIcon'
import {Paper} from '#components'
import {Trans} from "#components/i18n";
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

const API_BASE_URL = config().apiUrl

const AuthorizationDocument = function({ document: authorizationDocument = null }) {

    const { t } = useTranslation()

    if (!authorizationDocument) {
        return null
    }

    const documentLink = `${API_BASE_URL}${authorizationDocument.link}?inline=true`
    const downloadLink = `${API_BASE_URL}${authorizationDocument.link}?inline=false`

    return (
        <Paper 
            title={t('pages-organizations.powerOfAttorneyDocument')}
            titleActionElement={
                <Button
                    size="small"
                    variant="outlined" 
                    startIcon={<i className="ri-download-line" />} 
                    onClick={() => window.location.href = downloadLink}>
                    {t('pages-organizations.download')}
                </Button>
            }>
            <Box height="70rem">
                <object className="authorization__document-object" data={documentLink} type="application/pdf">
                    <div className="authorization__document-object-fallback">
                        <p>{t('pages-organizations.previewFallbackMessagePart1')}</p>
                        <p>
                            <Trans t={t} i18nKey={'pages-organizations.previewFallbackMessagePart2'}>
                                Du kan installere Adobe Reader ved å følge <a href="http://get.adobe.com/reader/" rel="noopener noreferrer" target="_blank">denne lenken</a>,
                                eller ta kontakt med IT-support i din organisasjon for å få hjelp til å installere PDF-støtte.
                            </Trans>
                        </p>
                    </div>
                    <embed src={documentLink} type="application/pdf"/>
                </object>
            </Box>
        </Paper>
    )
}

AuthorizationDocument.propTypes = {
    document: PropTypes.object,
}

export default AuthorizationDocument