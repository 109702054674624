import { MarketReport, Media, Webinar } from "./payload-types"

type Child = {
    detail: number,
    format: number,
    mode: string,
    style: string,
    text: string,
    type: string,
    version: number,
    fields?: {
        url: string,
        newTab: boolean,
        linkType: "custom" | "internal",
        doc?: {
            value: Webinar | MarketReport
        }
    },
    children?: Child[]
}
export type Content = {
    children: Child[],
    direction: string,
    format: string,
    indent: number,
    type: string,
    version: number,
    tag?: string,
    value?: Media,
    listType?: "bullet" | "number"
}

const fixrateCMSgraphQL = "/fixrate-cms/api/graphql"

export async function getMarketReports() : Promise<MarketReport[] | null> {
    const res = await fetch(fixrateCMSgraphQL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: `{
                MarketReports(limit: 50) {
                    docs {
                        id,
                        title,
                        commentary,
                        date,
                        reportSlug
                    }
                }
            }`,
            variables: {},
        }),
    }).then(data => data.json()).catch(() => null)

    const data = await res
    
    if (data) {
        const sortedReports = data?.data?.MarketReports.docs.sort((a: MarketReport, b: MarketReport) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
        return sortedReports as MarketReport[]
    } else {
        return null
    }
}

export async function getWebinars() : Promise<Webinar[] | null> {
    const res = await fetch(fixrateCMSgraphQL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: `{
                Webinars(limit: 50) {
                    docs {
                        id,
                        title,
                        description,
                        descriptionPast,
                        webinarCategory,
                        episodeNumber,
                        country,
                        customerSegment,
                        dateTo,
                        dateFrom,
                        eventUrl,
                        guests {
                            name,
                            id,
                            title,
                            image {
                            	url
                            },
                        },
                        hosts {
                            employee {
                              name,
                              title,
                              image {
                                url
                              }
                            }
                        }
                        vimeoID
                    }
                }
            }`,
            variables: {},
        }),
    }).then(data => data.json()).catch(() => null)

    const data = await res
    
    if (data) {
        const sortedReports = data?.data?.Webinars?.docs?.sort((a: Webinar, b: Webinar) => {
            return new Date(b.dateTo).getTime() - new Date(a.dateTo).getTime()
        })
        return sortedReports as Webinar[]
    } else {
        return null
    }
}

export async function getBankWebinars() : Promise<Webinar[] | null> {
    const webinars = await getWebinars()

    return webinars?.filter(e => e.customerSegment === "bank") || null
}