import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import styles from './NewAdModals.module.scss'
import {Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Tooltip, Typography} from '@mui/material'
import AdCard from '../marketplace/AdCard/AdCard'
import { DateOutput, DatePicker, InterestOutput, NumberInput} from '#app/components'
import {useSelector} from '#app/state/useSelector'
import {bankInSession, isFixrateUser} from '#app/state/selectors'
import {useTranslation} from 'react-i18next'
import { NewOfferDto } from './PricingSteps'
import {useBankCalendar} from '#app/services/useBankCalendar'
import addMonths from 'date-fns/addMonths'
import { Product} from '@fixrate/fixrate-query'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import { getAllOrders, getLastFullySubscribedAds, getLastOrdersByCategory } from '#services/thunks/statistics'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors';
import EditIcon from '@mui/icons-material/Edit';
import NewAdMarketplaceData from './NewAdMarketPlaceData';
import isAfter from 'date-fns/isAfter'
import { FullySubscribedAd } from '../BankDashboard/components/FullySubscribedAds';

type Props = {
    offers: NewOfferDto[]
    setOffers: (offers: NewOfferDto[]) => void
    defaultProductId: string
    marketplaceOver20: boolean
}

interface LastOrdersByCategory {
    business: string
    created: string
    interest: number
    nibor: number
    productId: string
    totalAssets: number
    volume: number
    max: number
    min: number
    interestType: string
}

export interface AllOrders {
    created: string
    interest: number
    nibor: number
    volume: number
    adId: string
}

type ProductMap = {[key: string]: Product}

export default function NewOfferAccordion({offers, setOffers, defaultProductId, marketplaceOver20}: Props) {
    const {t} = useTranslation()
    const bank = useSelector(bankInSession)
    const ads = useSelector(state => state.ads)
    const banks = useSelector(state => state.banks)
    const isFixedInterest = useSelector(state => state.products[defaultProductId]?.type === 'FIXED')
    const products: ProductMap = useSelector(state => {
        const showSpecialOffers = isFixrateUser(state)
        return Object.values(state.products)
            .filter(product => product.visibility !== 'NONE')
            .filter(product => showSpecialOffers || product.visibility !== 'SPECIAL_OFFER')
            .filter(product => (isFixedInterest ? product.termsType === 'FIXED_TERMS' : product.termsType === 'NOTICE'))
            .reduce((acc, product) => {
                acc[product.id] = product
                return acc
            }, {})
    })
    const benchmarkInterestRate = useSelector(state => state.interestRateBenchmarks.defaultBenchmarkInterestRate)
    const { nextBankDay, isValidBankDay } = useBankCalendar()
    const currency = useSelector(state => state.session?.association?.currentCurrency)
    const interestRateBenchmark = useSelector(state => defaultBenchmark(state.session?.association?.currentCurrency))
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<LastOrdersByCategory[] | FullySubscribedAd[] | AllOrders[]>>()
    const [fullySubscribedAd, setFullySubscribedAd] = useState<FullySubscribedAd[]>([])
    const [allOrders, setAllOrders] = useState<AllOrders[]>([])


    const [orders, setOrders] = useState<LastOrdersByCategory[]>([])
    const [, setError] = useState(false)

    useEffect(() => {
        dispatch(getLastOrdersByCategory())
            .then(data => setOrders(data as LastOrdersByCategory[]))
            .catch(() => setError(true))
        dispatch(getAllOrders())
            .then(orders => setAllOrders(orders as AllOrders[]))
            .catch(() => setError(true))
        dispatch(getLastFullySubscribedAds())
            .then(ads => {
                const sorted = ads.slice().sort((ad1, ad2) => (isAfter(new Date(ad2.validity), new Date(ad1.validity)) ? 1 : -1))
                setFullySubscribedAd(sorted as FullySubscribedAd[])
            })
            .catch(() => setError(true))
    }, [dispatch])

    const toggleActiveOffer = (id: string) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, active: !o.active }
        })
        setOffers(newOffers)
    }

    const setMin = (id: string, value: number) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, selectedMin: value }
        })
        setOffers(newOffers)
    }

    const setMax = (id: string, value: number) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, selectedMax: value }
        })
        setOffers(newOffers)
    }

    const setVolume = (id: string, value: number) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, volume: value }
        })
        setOffers(newOffers)
    }

    const setTotalCost = (id: string, value: number) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, totalCost: +value.toFixed(2) }
        })
        setOffers(newOffers)
    }

    const setProduct = (id: string, value: string) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, productId: value, termination: isFixedInterest ? getTerminationDate(value) : null }
        })
        setOffers(newOffers)
    }

    const setTermination = (id: string, value: Date) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, termination: value }
        })
        setOffers(newOffers)
    }

    const setIndividualTermination = (id: string, value: boolean) => {
        const newOffers = offers.map(o => {
            if (o.id !== id) {
                return o
            }
            return { ...o, individualTermination: value, termination: !value ? getTerminationDate(o.productId ? o.productId : defaultProductId) : null }
        })
        setOffers(newOffers)
    }

    const getTerminationDate = (productId: string) => {
        const terminationDate = addMonths(new Date(), products[productId].months)
        return nextBankDay(terminationDate)
    }


    return (
        <>
            {offers.filter(o => (marketplaceOver20 ? o.min >= 20 : o.min < 20)).map(offer => {
                const interestRate = isFixedInterest ? offer.totalCost - offer.platformFee : offer.totalCost - offer.platformFee + (benchmarkInterestRate || 0)

                const productIds = products ? Object.keys(products) : []
                const lastOrder = orders.filter(order => isFixedInterest ? order.interestType === 'FIXED' : order.interestType === 'FLOATING').filter(order => order.min === offer.min && order.max === offer.max)[0]

                const adsSameCategory = ads.filter(ad => productIds.includes(ad.productId)).filter(ad => ad.min >= offer.min && ad.max <= offer.max)
                const highestInterestAd = adsSameCategory.sort((a, b) => b.interest - a.interest)[0]

                return (
                    <Accordion data-cy={'offer-step-' + offer.id} key={offer.id} onChange={() => toggleActiveOffer(offer.id)} className={styles.adAccordion} expanded={offer.active}>
                        <AccordionSummary sx={{ justifyContent: 'space-between' }}>
                            <div className='flex' style={{ width: '100%' }}>
                                <div className={styles.columnsContainer}>
                                    <Switch checked={offer.active} />
                                    <div className={styles.titleContainer}>
                                        <h3 className={styles.title}>
                                            {offer.min}-{offer.max} {t('pages-BankAds.millions')}
                                        </h3>
                                        <p>{t('pages-BankAds.minMaxDetails', { min: offer.min, max: offer.max })}</p>
                                    </div>

                                    <div className={styles.rightContainer}>
                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                width: '7rem',
                                                padding: '0.6rem 1rem',
                                                borderRadius: '3px',
                                                backgroundColor: SPRING_GREEN[100],
                                                color: PURPLE[800],
                                                fontWeight: '600',
                                            }}
                                        >
                                            {highestInterestAd ? `${parseFloat(highestInterestAd.interest.toFixed(2))}%` : '-'}
                                        </Box>
                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                width: '7rem',
                                                padding: '0.6rem 1rem',
                                                borderRadius: '3px',
                                                backgroundColor: PURPLE[50],
                                                color: PURPLE[800],
                                                fontWeight: '600',
                                            }}
                                        >
                                            {lastOrder ? (
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <Box display='flex' flexDirection='column'>
                                                            <span>{`${t('pages-BankAds.volumeHeader')}: ${lastOrder.volume}`}</span>
                                                            <span>{`${t('pages-BankAds.productHeader')}: ${t(`common.productLongName${lastOrder.productId}`)}`}</span>
                                                            <span>{`${t('pages-BankAds.date')}: `}<DateOutput.Date date={lastOrder.created} /></span>
                                                        </Box>
                                                    }
                                                >
                                                    <div>{parseFloat(lastOrder.interest.toFixed(2))}%</div>
                                                </Tooltip>
                                            ) : (
                                                '-'
                                            )}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDetails} sx={{ padding: '4rem 6rem', backgroundColor: '#f1f1f1', position: 'relative' }}>
                            <Grid container>
                                <Grid item md={5}>
                                    <AdCard
                                        product={products[offer.productId ?? defaultProductId]}
                                        className={styles.previewCard}
                                        ad={{
                                            interest: interestRate,
                                            nominalInterestRate: offer.totalCost - offer.platformFee,
                                            min: offer.selectedMin,
                                            max: offer.selectedMax,
                                            remaining: offer.volume,
                                            productId: offer.productId ? offer.productId : defaultProductId,
                                            bankId: bank.id,
                                            adTag: null,
                                            depositorId: null,
                                            openOrders: [],
                                            interestRateBenchmark: interestRateBenchmark,
                                            validity: null
                                        }}
                                        onAdClick={null}
                                    />
                                </Grid>
                                <Grid item md={7}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            <label className={styles.label}>
                                                <span>{t('pages-BankAds.minVolumeHeader')}</span>
                                                <Tooltip arrow title={t('pages-BankAds.minVolumeHelpText')}>
                                                    <i className='ri-information-line purple' />
                                                </Tooltip>
                                            </label>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <NumberInput
                                                    sx={{
                                                        '& .MuiInputBase-input': { color: PURPLE[800] },
                                                        '& .MuiInputBase-root': {
                                                            height: '4.7rem',
                                                        },
                                                    }}
                                                    data-cy='smallestDepositInput'
                                                    className={styles.volumeInput}
                                                    value={offer.selectedMin}
                                                    onChange={value => setMin(offer.id, value)}
                                                />
                                                <span className={styles.unit}>{t('common.million_other', { currency })}</span>
                                            </Box>
                                            {offer.selectedMin < offer.min && (
                                                <p className='field-error-message' style={{ fontSize: '1.2rem' }}>
                                                    {t('pages-BankAds.minimumWarning', { min: offer.min })} {t('common.million_other', { currency })}{' '}
                                                </p>
                                            )}
                                            {offer.selectedMax && offer.selectedMin > offer.selectedMax && (
                                                <p className='field-error-message' style={{ fontSize: '1.2rem' }}>
                                                    {t('pages-BankAds.minimumOverMaxWarning')}
                                                </p>
                                            )}
                                        </Grid>
                                        <Grid item md={6}>
                                            <label className={styles.label}>
                                                <span>{t('pages-BankAds.maxVolumeHeader')}</span>
                                                <Tooltip arrow title={t('pages-BankAds.maxVolumeHelpText')}>
                                                    <i className='ri-information-line purple' />
                                                </Tooltip>
                                            </label>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <NumberInput
                                                    sx={{
                                                        '& .MuiInputBase-input': { color: PURPLE[800] },
                                                        '& .MuiInputBase-root': {
                                                            height: '4.7rem',
                                                        },
                                                    }}
                                                    data-cy='largestDepositInput'
                                                    className={styles.volumeInput}
                                                    value={offer.selectedMax}
                                                    onChange={value => setMax(offer.id, value)}
                                                />
                                                <span className={styles.unit}>{t('common.million_other', { currency })}</span>
                                            </Box>
                                            {offer.selectedMax > offer.max && (
                                                <p className='field-error-message' style={{ fontSize: '1.2rem' }}>
                                                    {t('pages-BankAds.maxWarning', { max: offer.max })} {t('common.million_other', { currency })}{' '}
                                                </p>
                                            )}
                                            {offer.selectedMax < offer.min && (
                                                <p className='field-error-message' style={{ fontSize: '1.2rem' }}>
                                                    {t('pages-BankAds.maxBelowMinimumWarning', { min: offer.min })} {t('common.million_other', { currency })}{' '}
                                                </p>
                                            )}
                                        </Grid>
                                        <Grid item md={6}>
                                            <label className={styles.label}>
                                                <span>{t('pages-BankAds.totalDemandHeader')}</span>
                                                <Tooltip arrow title={t('pages-BankAds.totalDemandHelpText')}>
                                                    <i className='ri-information-line purple' />
                                                </Tooltip>
                                            </label>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <NumberInput
                                                    sx={{
                                                        '& .MuiInputBase-input': { color: PURPLE[800] },
                                                        '& .MuiInputBase-root': {
                                                            height: '4.7rem',
                                                        },
                                                    }}
                                                    data-cy='volumeInput'
                                                    className={styles.volumeInput}
                                                    value={offer.volume}
                                                    onChange={value => setVolume(offer.id, value)}
                                                />
                                                <span className={styles.unit}>{t('common.million_other', { currency })}</span>
                                            </Box>
                                            {(offer.volume < offer.selectedMax || !offer.volume) && (
                                                <p className='field-error-message' style={{ fontSize: '1.2rem' }}>
                                                    {t('pages-BankAds.totalVolumeWarning')}
                                                </p>
                                            )}
                                        </Grid>
                                        <Grid item md={6}>
                                            <label className={styles.label}>
                                                <span>{isFixedInterest ? t('pages-BankAds.durationHeader') : t('pages-BankAds.noticePeriodHeader')}</span>
                                                <Tooltip arrow title={isFixedInterest ? t('pages-BankAds.durationHelpText') : t('pages-BankAds.noticePeriodHelpText')}>
                                                    <i className='ri-information-line purple' />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                name='product'
                                                data-cy='productSelect'
                                                defaultValue={products[0]?.id}
                                                value={offer.productId ?? defaultProductId}
                                                onChange={e => setProduct(offer.id, e.target.value)}
                                                autoFocus
                                                sx={{ width: '12rem' }}
                                            >
                                                {Object.values(products).map(product => (
                                                    <MenuItem data-cy={'productSelectItem-' + product.name} key={product.id} value={product.id}>
                                                        {t('common.productLongName' + product?.id)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item md={12}>
                                            <label className={styles.label} style={{ marginBottom: 0, marginTop: '4rem' }}>
                                                <span>{t('pages-BankAds.bankTotalCost')}</span>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        isFixedInterest
                                                            ? t('pages-BankAds.fixedInterestHelpText')
                                                            : t('pages-BankAds.marginHelpText', { base: t('common.interestRateBenchmark.' + interestRateBenchmark) })
                                                    }
                                                >
                                                    <i className='ri-information-line purple' />
                                                </Tooltip>
                                            </label>
                                            <p className={styles.labelMeta}>{isFixedInterest ? t('pages-BankAds.interestPlusPlatformfee') : t('pages-BankAds.interestMarginPlusPlatformfee')}</p>
                                            <div className={styles.interestInputWrapper}>
                                                <div className={styles.inputContainer}>
                                                    <FormControl
                                                        variant='standard'
                                                        sx={{
                                                            mt: 1,
                                                            width: '18rem',
                                                            border: '0.2rem solid ' + PURPLE[500],
                                                            borderRadius: '0.5rem',
                                                            '& .MuiOutlinedInput-root': {
                                                                backgroundColor: 'white'
                                                            }
                                                        }}
                                                    >
                                                        <NumberInput
                                                            data-cy='interestInput'
                                                            value={offer.totalCost}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        {/* <Typography sx={{ color: PURPLE[800] }}>%</Typography> */}
                                                                        <EditIcon sx={{ color: PURPLE[800] }} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            formatFn={value => InterestOutput.format(value.toFixed(2))}
                                                            onChange={value => (value === null ? setTotalCost(offer.id, 0.0) : setTotalCost(offer.id, value))}
                                                            sx={{
                                                                '& .MuiInputBase-input': { color: PURPLE[800]},
                                                                '& .MuiOutlinedInput-root': {
                                                                    backgroundColor: '#ffffff',
                                                                    border: 'none',
                                                                    '&.Mui-focused': {
                                                                        outline: 'none',
                                                                    },
                                                                    '&:hover': {
                                                                        border: 'none'
                                                                    },
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                        borderRadius: '0',
                                                                        borderTop: 'none',
                                                                        borderLeft: 'none',
                                                                        borderRight: 'none',
                                                                    },
                                                                },
                                                                '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
                                                                    padding: 1,
                                                                },
                                                            }}
                                                        />
                                                        <FormHelperText id='interestInput-helper-text' sx={{ p: 1, color: PURPLE[800], backgroundColor: '#f3f1f9' }}>
                                                            {t('pages-BankAds.enterBankTotalCost')}
                                                        </FormHelperText>
                                                    </FormControl>

                                                    <span className={styles.mathSymbol}>
                                                        <i className='ri-subtract-line'></i>
                                                    </span>
                                                    <FormControl
                                                        variant='standard'
                                                        sx={{
                                                            mt: 1,
                                                            width: '18rem',
                                                            border: '0.2rem solid ' + PURPLE[500],
                                                            borderRadius: '0.5rem',
                                                            backgroundColor: '#f3f1f9',
                                                        }}
                                                    >
                                                        <TextField
                                                            label=''
                                                            id='platform-fee-info'
                                                            sx={{
                                                                '& .MuiInputBase-input.Mui-disabled': {
                                                                    borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                    borderRadius: '0',
                                                                    borderTop: 'none',
                                                                    borderLeft: 'none',
                                                                    borderRight: 'none',
                                                                    p: '0.9rem',
                                                                    '-webkit-text-fill-color': PURPLE[800],
                                                                    '&:focus, &:active': {
                                                                        borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                        borderRadius: '0',
                                                                        borderTop: 'none',
                                                                        borderLeft: 'none',
                                                                        borderRight: 'none',
                                                                    },
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                    borderRadius: '0',
                                                                    borderTop: 'none',
                                                                    borderLeft: 'none',
                                                                    borderRight: 'none',
                                                                },
                                                            }}
                                                            value={InterestOutput.format(offer.platformFee)}
                                                            variant='standard'
                                                            disabled
                                                        />
                                                        <FormHelperText id='platform-fee-helper-text' sx={{ p: 1, color: PURPLE[800] }}>
                                                            {t('pages-BankAds.totalCostInterestExplanation')}
                                                        </FormHelperText>
                                                    </FormControl>
                                                    <span className={styles.mathSymbol}>
                                                        <i className='ri-equal-line'></i>
                                                    </span>
                                                    <FormControl
                                                        variant='standard'
                                                        sx={{
                                                            mt: 1,
                                                            width: '18rem',
                                                            backgroundColor: '#f3f1f9',
                                                            border: '0.2rem solid ' + PURPLE[500],
                                                            borderRadius: '0.5rem',
                                                        }}
                                                    >
                                                        <TextField
                                                            label=''
                                                            id='interest-info'
                                                            sx={{
                                                                '& .MuiInputBase-input.Mui-disabled': {
                                                                    borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                    borderRadius: '0',
                                                                    borderTop: 'none',
                                                                    borderLeft: 'none',
                                                                    borderRight: 'none',
                                                                    p: '0.9rem',
                                                                    '-webkit-text-fill-color': PURPLE[800],
                                                                    '&:focus, &:active': {
                                                                        borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                        borderRadius: '0',
                                                                        borderTop: 'none',
                                                                        borderLeft: 'none',
                                                                        borderRight: 'none',
                                                                    },
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderBottom: '0.2rem solid ' + PURPLE[500],
                                                                    borderRadius: '0',
                                                                    borderTop: 'none',
                                                                    borderLeft: 'none',
                                                                    borderRight: 'none',
                                                                },
                                                            }}
                                                            variant='standard'
                                                            disabled
                                                            value={InterestOutput.format(offer.totalCost - offer.platformFee)}
                                                        />
                                                        <FormHelperText id='interest-info-helper-text' sx={{ p: 1, color: PURPLE[800] }}>
                                                            {t('pages-BankAds.interestMarginCustomer')}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </Grid>
                                        {isFixedInterest && (
                                            <Grid item md={12}>
                                                <FormControl>
                                                    <FormLabel className={styles.label} id={'terminationDate-label-' + offer.id}>
                                                        {t('pages-BankAds.selectSettlementDate')}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby={'terminationDate-label-' + offer.id}
                                                        name={'terminationDate-group-' + offer.id}
                                                        value={offer.individualTermination ? 'true' : 'false'}
                                                        onChange={e => setIndividualTermination(offer.id, e.target.value === 'true')}
                                                    >
                                                        <FormControlLabel
                                                            sx={{ marginBottom: 0, '& .MuiTypography-root': { fontSize: '1.4rem' } }}
                                                            disabled={products[offer.productId]?.termsType === 'RECURRING_FIXED_TERMS'}
                                                            value='true'
                                                            control={<Radio />}
                                                            label={
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                                    <span>{t('pages-BankAds.automaticTerminationDate')}</span>
                                                                    <Tooltip arrow title={t('pages-BankAds.automaticTerminationDateInfo')}>
                                                                        <i className='ri-information-line purple' />
                                                                    </Tooltip>
                                                                </Box>
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            sx={{ '& .MuiTypography-root': { fontSize: '1.4rem' } }}
                                                            disabled={products[offer.productId]?.termsType === 'RECURRING_FIXED_TERMS'}
                                                            value='false'
                                                            control={<Radio />}
                                                            label={t('pages-BankAds.givenTerminationDate')}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                {!offer.individualTermination && (
                                                    <DatePicker
                                                        disabled={products[offer.productId]?.termsType === 'RECURRING_FIXED_TERMS'}
                                                        selected={offer.termination}
                                                        onChange={date => setTermination(offer.id, date)}
                                                        filterDate={isValidBankDay}
                                                        className={styles.terminationDateInput}
                                                    />
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>

                                <NewAdMarketplaceData offer={offer} banks={banks} allOrders={allOrders} adsSameCategory={adsSameCategory} fullySubscribedAd={fullySubscribedAd} products={products} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
                })}
        </>
    )
}
