import { Route, Routes } from 'react-router-dom'
import DocumentList from "./DocumentList"
import ViewDocument from "./ViewDocument"

const SignatureInbox = () => {
    return (
        <Routes>
            <Route path=':documentId' element={<ViewDocument/>} />
            <Route path='' element={<DocumentList/>} />
        </Routes>
    )
}

export default SignatureInbox