import { Fragment, useState } from 'react';
import {PageHeader, PageLayout} from '#components'

import '#components/Confetti/Confetti.module.css'
import Confetti from '#components/Confetti/Confetti'
import Button from '#components/Button'
import Paper from '#components/Paper'
import {useTranslation} from "#components/i18n"

const Settings = () => {

    const {t} = useTranslation('pages-settings')
    const [spawnCount, spawnConfetti] = useState(0)

    return (
        <Fragment>
            <PageHeader title={t('header')}/>
            <PageLayout>
                <Paper title={t('fun')}>
                    <Button onClick={() => spawnConfetti(prev => prev + 1)}>{t('joy')}</Button>
                </Paper>
            </PageLayout>
            {spawnCount > 0 && <Confetti key={spawnCount}/>}
        </Fragment>
    );
}

export default Settings
