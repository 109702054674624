import config from '#app/config'
import ActionBlock from '#components/ActionBlock'
import {Button, DateOutput, DatePicker} from '#components'
import LabeledInput from '#components/LabeledInput/LabeledInput'
import styles from './ExtendDeposit.module.css'
import ButtonRow from '#components/ButtonRow'
import classNames from 'classnames'
import DocumentLink from '../../common/DocumentLink/DocumentLink'
import HelpPopup from '#components/HelpPopup'
import format from 'date-fns/format'
import isWeekend from 'date-fns/isWeekend'
import {DepositDto} from '@fixrate/fixrate-query'
import {useSelector} from '#state/useSelector'
import {useFieldState} from '@fixrate/fieldstate'
import {useCommand} from '#command'
import {isAfter, isBefore, toDate} from '#services/dateandtime'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

export default function ExtendDeposit({deposit}: { deposit: DepositDto }) {

    const {t} = useTranslation()
    const document = useSelector(state => state.documents[deposit.extensionOfferDocumentId])
    const product = useSelector(state => state.products[deposit.product.id])
    const bankCalendar = useSelector(state => state.bankCalendar)

    const interest = useFieldState('', ({isEditing, value}) => {
        if (!value) {
            return t('pages-portfolio-bank.interestMissing')
        }
        if (isEditing) return
        if (!value.match(/^\d{0,2}([.,]\d\d?){0,1}$/)) {
            return t('pages-portfolio-bank.interestMustBeANumber')
        }
        const newValue = parseFloat(value.replace(',', '.'))
        if (newValue <= 0) {
            return t('pages-portfolio-bank.interestMustBeGreaterThanZero')
        }
    })

    const termination = useFieldState(null, ({isEditing, value}) => {
        if (!isEditing && !value) {
            return t('pages-portfolio-bank.dateMustBeABusinessDay')
        }
    })

    const submitting = false

    const {createExtensionOffer, sendExtensionOfferToDepositor, deleteExtensionOffer, registerExtension} = useCommand()

    async function doCreateExtensionOffer(e) {
        let isValid = interest.validate()
        isValid = termination.validate() && isValid
        if (isValid) {
            const formattedTerminationDate = format(termination.value, 'yyyy-MM-dd')
            const interestRate = interest.value.replace(',', '.')
            const {waitForCommand} = await createExtensionOffer(deposit.id, interestRate, formattedTerminationDate)
            await waitForCommand()
        }
    }

    async function doSendExtensionOfferToDepositor(e) {
        const {waitForCommand} = await sendExtensionOfferToDepositor(deposit.id)
        await waitForCommand()
    }

    async function doDeleteExtensionOffer(e) {
        const {waitForCommand} = await deleteExtensionOffer(deposit.id)
        await waitForCommand()
    }

    async function doRegisterExtension(e) {
        const {waitForCommand} = await registerExtension(deposit.id)
        await waitForCommand()
    }

    function isAfterCurrentTerminationDate(date) {
        return isAfter(date, deposit.terminationDate)
    }

    if (!deposit || !product) return null

    const isExtendable = deposit.terminationDate
        && isAfter(deposit.terminationDate, new Date())
        && deposit.extendableFrom
        && isBefore(deposit.extendableFrom, new Date())


    switch (deposit.extensionOfferState) {
        case 'NOT_OFFERED':
        case 'NO_ACTIVE_OFFER': {

            // An extension process must start in the period where the deposit is extendible
            if (!isExtendable) return null

            return (
                <ActionBlock header={t('pages-portfolio-bank.extensionOfferHeader')}>
                    <p>{t('pages-portfolio-bank.extensionOfferNewInfo1', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferNewInfo2')}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferNewInfo3')}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferNewInfo4', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}</p>

                    <div className={styles.borderedSection}>
                        <h4>{t('pages-portfolio-bank.extensionNewTermsHeader')}</h4>
                        <p>{t('pages-portfolio-bank.extensionNewTermsInfo')}</p>
                        <div className={styles.inputfields}>
                            <LabeledInput label={t('pages-portfolio-bank.extensionNewTerminationDateLabel')}
                                          helpText={t('pages-portfolio-bank.extensionNewTerminationDateHelpText')}
                                          errorMessage={!termination.valid && termination.errorMessage}
                                          className={styles.input}>
                                <DatePicker
                                    id="newTerminationDateDatePicker"
                                    className={classNames(styles.datepicker)}
                                    selected={termination.value}
                                    openToDate={toDate(deposit.terminationDate)}
                                    onChange={date => termination.setValue(date)}
                                    onBlur={termination.onBlur}
                                    filterDate={date => !isWeekend(date) && !bankCalendar.includes(date.getTime()) && isAfterCurrentTerminationDate(date)}/>
                            </LabeledInput>

                            <LabeledInput label={t('pages-portfolio-bank.extensionNewFixedInterestLabel')}
                                          helpText={t('pages-portfolio-bank.extensionNewFixedInterestHelpText', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}
                                          errorMessage={!interest.valid && interest.errorMessage}
                                          className={styles.input}>
                                <input className={styles.inputInterest}
                                       data-cy="newInterestInput"
                                       id="newInterestInput"
                                       type="text"
                                       value={interest.value}
                                       onChange={e => interest.setValue(e.target.value)}
                                       onBlur={interest.onBlur}/>
                                <span className={styles.interestUnit}>%</span>
                            </LabeledInput>
                        </div>
                    </div>
                    <ButtonRow>
                        <Button id="createExtensionOfferButton"
                                onClick={doCreateExtensionOffer}>{t('pages-portfolio-bank.extensionCreateOfferButtonText')}</Button>
                    </ButtonRow>
                </ActionBlock>
            )
        }

        case 'CREATED': {
            return (
                <ActionBlock header={t('pages-portfolio-bank.extensionOfferHeader')}>
                    <p>{t('pages-portfolio-bank.extensionOfferNewInfo1', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferNewInfo2')}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferCreatedInfo3', {deadline: DateOutput.formatVerboseMonth(deposit.extensionDeadline)})}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferCreatedInfo4', {
                        deadline: DateOutput.formatVerboseMonth(deposit.extensionDeadline),
                        date: DateOutput.formatVerboseMonth(deposit.terminationDate)
                    })}</p>

                    <div className={styles.borderedSection}>
                        <h4>{t('pages-portfolio-bank.extensionOfferDocumentHeader')}</h4>
                        <p>{t('pages-portfolio-bank.extensionOfferDocumentCreatedInfo')}</p>

                        <div className={styles.inputfields}>
                            <DocumentLink name={t('pages-portfolio-bank.extensionOfferDocumentName')}
                                          link={`${API_BASE_URL}${document.link}`}/>
                        </div>
                    </div>

                    <ButtonRow>
                        <Button variant="secondary" disabled={submitting}
                                onClick={doDeleteExtensionOffer}>{t('common.cancel')}</Button>
                        <Button id="sendExtensionOfferToCustomerButton"
                                onClick={doSendExtensionOfferToDepositor}>{t('pages-portfolio-bank.extensionOfferSendButtonText')}</Button>
                    </ButtonRow>
                </ActionBlock>
            )
        }

        case 'READY_FOR_SIGNING': {
            return (
                <ActionBlock header={t('pages-portfolio-bank.extensionOfferHeader')}>
                    <p>{t('pages-portfolio-bank.extensionOfferReadyInfo1', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferReadyInfo2')}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferCreatedInfo3', {deadline: DateOutput.formatVerboseMonth(deposit.extensionDeadline)})}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferCreatedInfo4', {
                        deadline: DateOutput.formatVerboseMonth(deposit.extensionDeadline),
                        date: DateOutput.formatVerboseMonth(deposit.terminationDate)
                    })}</p>

                    <div className={styles.borderedSection}>
                        <h4>{t('pages-portfolio-bank.extensionOfferDocumentHeader')}</h4>
                        <p>{t('pages-portfolio-bank.extensionOfferDocumentSentInfo')}</p>

                        <div className={styles.inputfields}>
                            <DocumentLink id="extensionOfferDocumentLink" name={t('pages-portfolio-bank.extensionOfferDocumentName')}
                                          link={`${API_BASE_URL}${document.link}`}/>
                        </div>
                    </div>
                </ActionBlock>
            )
        }

        case 'ACCEPTED': {

            if (deposit.extensionRegistered) return null

            return (
                <ActionBlock header={t('pages-portfolio-bank.extensionOfferHeader')}>
                    <p>{t('pages-portfolio-bank.extensionOfferAcceptedInfo1')}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferAcceptedInfo2')}</p>

                    <dl className="actionframe__labeledinput">
                        <dt className="actionframe__labeledinput-label">
                            <HelpPopup text={t('pages-portfolio-bank.extensionOfferAcceptedStep1HelpText')}>
                                {t('pages-portfolio-bank.extensionOfferAcceptedStep1')}
                            </HelpPopup>
                        </dt>
                        <dd className="actionframe__labeledinput-value">
                            <DocumentLink name={t('pages-portfolio-bank.extensionOfferDocumentName')}
                                          link={`${API_BASE_URL}${document.link}`}/>
                        </dd>
                    </dl>

                    <dl className="actionframe__labeledinput">
                        <dt className="actionframe__labeledinput-label">
                            <HelpPopup text={t('pages-portfolio-bank.extensionOfferAcceptedStep2HelpText')}>
                                {t('pages-portfolio-bank.extensionOfferAcceptedStep2')}
                            </HelpPopup>
                        </dt>
                        <dd className="actionframe__labeledinput-value">
                            <ul className={styles.todoList}>
                                <li>{t('pages-portfolio-bank.extensionOfferAcceptedTodo1', {date: DateOutput.formatVerboseMonth(deposit.extensionStartDate)})}</li>
                                <li>{t('pages-portfolio-bank.extensionOfferAcceptedTodo2', {
                                    date: DateOutput.formatVerboseMonth(deposit.extensionStartDate),
                                    rate: deposit.extensionInterestRate
                                })}</li>
                                <li>{t('pages-portfolio-bank.extensionOfferAcceptedTodo3', {date: DateOutput.formatVerboseMonth(deposit.terminationDate)})}</li>
                            </ul>
                        </dd>
                    </dl>

                    <ButtonRow>
                        <Button id="confirmDepositExtensionButton"
                                onClick={doRegisterExtension}>{t('pages-portfolio-bank.extensionOfferAcceptedButtonText')}</Button>
                    </ButtonRow>

                </ActionBlock>
            )
        }

        case 'REJECTED': {
            return (
                <ActionBlock header={t('pages-portfolio-bank.extensionOfferHeader')}>
                    <p>{t('pages-portfolio-bank.extensionOfferRejectedInfo1')}</p>
                    <p>{t('pages-portfolio-bank.extensionOfferRejectedInfo2')}</p>

                    <div className={styles.borderedSection}>
                        <h4>{t('pages-portfolio-bank.extensionOfferDocumentHeader')}</h4>
                        <p>{t('pages-portfolio-bank.extensionOfferRejectedDocumentInfo')}</p>

                        <div className={styles.inputfields}>
                            <DocumentLink name={t('pages-portfolio-bank.extensionOfferDocumentName')}
                                          link={`${API_BASE_URL}${document.link}`}/>
                        </div>
                    </div>
                </ActionBlock>
            )
        }

        default:
            // The switch statement should cover all cases.
            console.error('Uncovered extensionOfferState: ' + deposit.extensionOfferState)
    }

    return null
}
