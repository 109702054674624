import { useSelector } from '#app/state/useSelector'
import NIBORPortfolioInterestRatesChart from '#pages/portfolio-depositor/PortfolioInterestRatesChart'
import { Box, Stack, Typography } from '@mui/material'
import isAfter from 'date-fns/isAfter'
import { useTranslation } from 'react-i18next'
import SegmentChart from '../SegmentChart'

export default function DepositsOverview() {
    const {t} = useTranslation()

    const deposits = useSelector(state => state.deposits)
    const numberOfBanks = [...new Set(deposits.map(deposit => deposit.bankId))].length
    const showSegmentChart = deposits.length > 1 && numberOfBanks > 1

    const activeDeposits = deposits.filter(deposit => !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date()))

    const currency = useSelector(state => state.session?.association?.currentCurrency)

    return (
        <Stack direction={{xs: 'column', md: 'row'}} spacing={4}>
            {showSegmentChart && (
                <Box>
                    <SegmentChart deposits={activeDeposits} />
                </Box>
            )}
            { currency === "NOK" && (
                <Stack spacing={1} sx={{maxWidth: '68rem'}}>
                    <Typography variant={'subtitle1'} sx={{fontWeight: 600}}>{t('pages-portfolio-depositor.historicalInterestRate')}</Typography>
                    <NIBORPortfolioInterestRatesChart />
                </Stack>
            )}
        </Stack>
    )
}
