import AsyncButton from '#app/components/Button/AsyncButton'
import { LanguageSelect } from '#app/components/LanguageSelect/LanguageSelect'
import { Paper } from '#components'
import InviteDataModal from '#pages/organizations/Authorization/InviteDataModal'
import { PersonValidator } from '#services/PersonValidator'
import { DateAsString, DepositorDto } from '@fixrate/fixrate-query'
import { Box, Button, Checkbox, Divider, FormControlLabel, InputLabel, List, ListItem, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export type PrincipalCandidate = {
    personId: string,
    birthDate: DateAsString,
    email: string,
    name: string
}

type Props = {
    generateAuthorizationDocument,
    depositor: DepositorDto,
    principalCandidates: PrincipalCandidate[],
    principalPersonIds: string[],
    isGenerating: boolean,
    setPrincipalPersonIds,
    attorney,
    setNotifiedUserId,
    setLanguage,
    language
}

export default function RequestAuthorization({
        generateAuthorizationDocument,
        depositor,
        principalCandidates,
        principalPersonIds,
        isGenerating,
        setPrincipalPersonIds,
        attorney,
        setNotifiedUserId,
        setLanguage,
        language
    }: Props) {

    const {t} = useTranslation()

    const [birthDateModal, setBirthDateModal] = useState(null)

    if (!depositor) {
        return null
    }

    let error = null
    if (principalCandidates.length === 0) {
        error = t('pages-organizations.noPrincipalCandidates')
    } else if (principalPersonIds.length === 0) {
        error = t('pages-organizations.selectAtLeastOnePrincipal')
    }

    const modal = birthDateModal &&
        <InviteDataModal depositorId={depositor.id} personId={birthDateModal} onClose={() => setBirthDateModal(null)}/>

    const principalInputs = principalCandidates.map(principalCandidate => {
        const isComplete = PersonValidator.isCompleteForAuthorizationCreation(principalCandidate)
        return (
            <ListItem data-cy="authorizationSigner" key={principalCandidate.personId}>
                <FormControlLabel 
                    label={principalCandidate.name}
                    control={
                        <Checkbox
                            edge="start"
                            checked={principalPersonIds.includes(principalCandidate.personId)}
                            id={principalCandidate.personId}
                            className="authorizationSignerCheckbox"
                            onChange={e => {
                                setPrincipalPersonIds(prevState => (
                                    prevState.includes(principalCandidate.personId) ?
                                        prevState.filter(id => id !== principalCandidate.personId) :
                                        [...prevState, principalCandidate.personId]
                                ))
                                e.target.checked = !e.target.checked
                            }}
                            disabled={!isComplete}
                        />
                    } />
                { !isComplete && (
                    <Button sx={{ml: 2}} size="small" variant="outlined" color="error" data-cy="editPersonLink" onClick={() => setBirthDateModal(principalCandidate.personId)}>
                        {t('pages-organizations.missingInformation')}
                    </Button>
                )}
            </ListItem>
        );
    })

    return (
        <Paper title={t('pages-organizations.createAuthorizationHeading')} supportArticleId="02be945c-fdd0-4352-b45d-dbe8d30fa1c3">
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography>
                        {t('pages-organizations.userNeedsAuthorization', {userName: `${attorney.firstName} ${attorney.lastName}`})}
                    </Typography>
                    <Typography>
                        {t('pages-organizations.selectPrincipalsMessagePart1')}
                    </Typography>
                    <Typography>
                        {t('pages-organizations.selectPrincipalsMessagePart2')}
                    </Typography>
                </Stack>
                <Stack>
                    <InputLabel sx={{mb: 0}}>
                        {t('pages-organizations.selectPrincipals')}
                    </InputLabel>
                    <List disablePadding>
                        {principalInputs}
                    </List>
                    <p className="field-error-message">
                        {error}
                        {principalCandidates.length === 0 &&
                            <>
                                <br/>
                                <Link to={`/organizations/${depositor.id}/users/power-of-attorney`}>{t('pages-organizations.addPrincipalCandidates')}</Link>
                            </>
                        }
                    </p>
                </Stack>
                <Stack>
                    <InputLabel>
                        {t('pages-organizations.selectNotifiedUser')}
                    </InputLabel>
                    <Select onChange={(e) => setNotifiedUserId(e.target.value !== 'nobody' ? e.target.value : null)}>
                        <MenuItem value="nobody">{t('common.nobody')}</MenuItem>
                        {depositor?.users.map(user => (
                            <MenuItem key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Stack>
                    <InputLabel>
                        {t('pages-organizations.selectLanguage')}
                    </InputLabel>
                    <LanguageSelect language={language} setLanguage={setLanguage} />
                </Stack>
                <Box>
                    <AsyncButton
                        disabled={isGenerating || error}
                        id="createAuthorizationDocumentButton"
                        loading={isGenerating}
                        onClick={generateAuthorizationDocument}
                    >
                        {t('pages-organizations.createAuthorization')}
                    </AsyncButton>
                </Box>
            </Stack>
            {modal}
        </Paper>
    )
}


