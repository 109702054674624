import {useBankCalendar} from '#services/useBankCalendar'
import addDays from 'date-fns/addDays'
import {DepositDto} from '@fixrate/fixrate-query'
import {TerminationMode} from '#pages/portfolio-depositor/DepositDetails/DepositTermination/DepositTermination'
import {isAfter} from '#services/dateandtime'
import startOfDay from 'date-fns/startOfDay'

export function useTermination() {

    const {nextBankDay} = useBankCalendar()

    function firstValidTerminationDate(deposit: DepositDto, terminationMode: TerminationMode = 'UNCONDITIONAL') {

        const {product, currentPeriod} = deposit
        let terminationDate = startOfDay(new Date())

        // Adds the number of bank days the bank is entitled to process the request after it was sent
        const initialProcessingTime = (terminationMode === 'CONDITIONAL') ? 3 : 1
        terminationDate = nextBankDay(terminationDate, initialProcessingTime)

        // Adds countdown days (31 or 90)
        if (product && product.days) terminationDate.setDate(terminationDate.getDate() + product.days)

        // Add days if required until firstDate is a bank day
        terminationDate = nextBankDay(terminationDate, 0)

        if (product.termsType === 'RECURRING_FIXED_TERMS') {
            // If this is the first period,
            // or if the periods termination date is before the calculated date,
            // then use the periods termination date
            if (isAfter(currentPeriod.terminationDate, terminationDate)) {
                terminationDate = nextBankDay(new Date(currentPeriod.terminationDate), 0)
            }
        }

        return terminationDate
    }

    function lastValidTerminationDate(deposit: DepositDto, terminationMode: TerminationMode = 'UNCONDITIONAL') {
        if (deposit.product.termsType === 'RECURRING_FIXED_TERMS') {
            return firstValidTerminationDate(deposit, terminationMode)
        } else {
            return addDays(firstValidTerminationDate(deposit, terminationMode), 14)
        }
    }


    return {
        firstValidTerminationDate,
        lastValidTerminationDate
    }
}
