import {DepositorDto, DocumentSubscriptionType} from '@fixrate/fixrate-query'
import {useTranslation} from 'react-i18next'
import {useCommand} from '#command'
import {useDispatch} from 'react-redux'
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    FormGroup,
    TextField
} from '@mui/material'
import AsyncButton from '#components/Button/AsyncButton'
import {Email} from '@mui/icons-material'
import {useFieldState} from '@fixrate/fieldstate'
import {validateEmailAddress} from '#services/validateFields'
import {showConfirmationModal} from '#state/reducers/confirmationModal'
import {documentSubscriptionTypeTMap} from "#services/enumTranslationKeyMapping";
import {useState} from "react";

export type EmailDistributionEditTargetConfigurationProps = {
    depositor: DepositorDto,
    targetId: string | null,
    onClose: () => void
}

const AVAILABLE_SUBSCRIPTIONS: DocumentSubscriptionType[] = [
    'ACCOUNT_STATEMENTS',
    'INTEREST_AND_BALANCE',
]

export default function EmailDistributionEditTargetConfiguration({depositor, targetId=null, onClose}: EmailDistributionEditTargetConfigurationProps) {
    const {t} = useTranslation()
    const {editDepositorEmailDistributionTarget} = useCommand()
    const dispatch = useDispatch()
    const target = depositor.emailDistributionConfiguration.targets?.find(target => target.targetId === targetId)
    const emailField = useFieldState(target?.email ?? '', ({value, isEditing}) => {
        if (isEditing) return
        if (value === '') return t(`pages-organizations.integrationOverview.emailDistribution.emailMissing`)
        if (!validateEmailAddress(value)) return t(`pages-organizations.integrationOverview.emailDistribution.emailInvalid`)
        for (const target of depositor.emailDistributionConfiguration.targets || []) {
            if (target.email === value && target.targetId !== targetId) {
                return t(`pages-organizations.integrationOverview.emailDistribution.emailDuplicate`)
            }
        }
    })
    const [selectedSubscriptions, setSelectedSubscriptions] = useState(AVAILABLE_SUBSCRIPTIONS.map((sub, idx) => ({sub, idx, checked: target?.subscriptions.some(s => s === sub) ?? false})))

    async function handleSubmit() {
        emailField.validate({isEditing: false})
        if( emailField.value === '') return
        if (!emailField.valid) return

        const {waitForCommand} = await editDepositorEmailDistributionTarget(depositor.id, targetId, emailField.value, selectedSubscriptions.filter(sub => sub.checked).map(sub => sub.sub))

        const success = await waitForCommand()
        if (success) {
            dispatch(showConfirmationModal({
                title: t(`pages-organizations.integrationOverview.emailDistribution.confirmationModal.title`),
                text: t(`pages-organizations.integrationOverview.emailDistribution.confirmationModal.text`),
                buttonText: t('common.continue'),
            }))
            onClose()
        }

    }

    function updateSelection(idx: number, checked: boolean) {
        setSelectedSubscriptions(selectedSubscriptions.map(sub => ({...sub, checked: sub.idx === idx ? checked : sub.checked})))
    }

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle> {t(targetId===null?`pages-organizations.integrationOverview.emailDistribution.add`:`pages-organizations.integrationOverview.emailDistribution.edit`)}</DialogTitle>
            <DialogContent>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 2}}>
                    <TextField
                        autoFocus
                        fullWidth
                        autoComplete={'off'}
                        id={'emailDistributionEmails'}
                        label={t(`pages-organizations.integrationOverview.emailDistribution.email`)}
                        value={emailField.value}
                        onBlur={emailField.onBlur}
                        error={!emailField.valid}
                        helperText={emailField.errorMessage}
                        onChange={(e) => {
                            emailField.setValue(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <Email/>
                            ),
                        }}
                    />
                </FormControl>
                <FormGroup>
                    {AVAILABLE_SUBSCRIPTIONS.map((sub, idx) => (
                        <FormControlLabel key={idx}
                                          control={<Checkbox checked={selectedSubscriptions[idx].checked} onChange={event => updateSelection(idx, event.target.checked)}/>}
                                          label={t(documentSubscriptionTypeTMap[sub])}/>
                    ))}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    id={'emailDistributionCancelButton'}
                    onClick={onClose}
                >
                    {t('common.cancel')}
                </Button>
                <AsyncButton
                    variant={'contained'}
                    onClick={handleSubmit}
                >
                    {t('common.save')}
                </AsyncButton>

            </DialogActions>

        </Dialog>
    )

}
