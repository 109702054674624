import { CHART_COLORS } from "#app/colors/colors";
import { InterestOutput } from "#app/components";
import { Chart } from "chart.js"
import { useRef } from "react"

type BarChart = Chart<'bar', number[]>

type DatasetType = {
    label: string;
    data: number[];
}

const MarketReportBarChart = ({ labels, dataSets } : { labels: string[], dataSets: DatasetType[] }) => {
    const chartRef = useRef<BarChart | null>(null)

    const styledDataSets = dataSets.map((dataSet, index) => {
        return {
            ...dataSet,
            backgroundColor: CHART_COLORS[index] + 50,
            borderColor: CHART_COLORS[index],
            borderWidth: 1
        }
    })

    const chartData = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: styledDataSets,
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                callback: (value) => InterestOutput.format(value),
                            },
                        },
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.dataset.label || ''
                                    const value = context.parsed.y || 0
                                    return `${label}: ${InterestOutput.format(value)}`
                                },
                            },
                        },
                    }
                },
            })
        } else {
            chartRef.current.data.labels = labels
            chartRef.current.data.datasets = styledDataSets
            chartRef.current.update()
        }
    }
    return (
        <canvas ref={chartData} />
    )
}

export default MarketReportBarChart