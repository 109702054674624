import { useQuery } from 'react-query';

const fetchJWT = async (): Promise<string> => {
    const response = await fetch('/api/session/jwt/issue', {method: 'POST'});
    return await response.text();
};

export function useJwt(): {jwt: string, isJwtLoading: boolean, isJwtError: boolean}  {
    const { data, isLoading, isError } = useQuery<string>('jwt', fetchJWT, {
        staleTime: 25 * 60 * 1000, // 25 minutes
        refetchInterval: 25 * 60 * 1000, // 25 minutes
    });

    return {
        jwt: data,
        isJwtLoading: isLoading,
        isJwtError: isError
    };
}
