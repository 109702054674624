import { BuyOrderCard, SellOrderCard } from '#pages/FundMarketplace/OrderCard/OrderCard'
import { useSelector } from '#state/useSelector'
import { Link, useParams } from 'react-router-dom'
import styles from './TradeOrder.module.scss'

type Params = {
    id: string
}

export default function TradeOrder() {
    const { id: groupId } = useParams<Params>()
    const isBuyOrder = useSelector((state) => state.fundBuyOrders.find((f) => f.orderGroupId === groupId))

    return (
        <>
            <Link to='/orders/funds' className={styles.backLink}>
                <i className='ri-arrow-left-line' />
                <span>Gå tilbake til alle fondsbestillinger</span>
            </Link>
            {isBuyOrder ? <BuyOrderCard orderGroupId={groupId} /> : <SellOrderCard orderGroupId={groupId} />}
        </>
    )
}
