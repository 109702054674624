import { useEffect, useState } from 'react'
import {useDispatch} from 'react-redux'
import {getLastOrders} from '#services/thunks/statistics'
import { Alert, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { InterestBadge } from '#app/components/InterestBadge/InterestBadge'
import { useTranslation } from 'react-i18next'
import { CurrencyOutput, DateOutput } from '#app/components'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import { Currency } from '@fixrate/fixrate-query'
import { useSelector } from '#app/state/useSelector'

export interface LastOrdersData {
    business: string,
    created: string,
    interest: number,
    nibor: number,
    productId: string,
    totalAssets: number,
    volume: number,
    currency: Currency
}

export const LastOrders = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<LastOrdersData[]>>()

    const [orders, setOrders] = useState<LastOrdersData[]>([])
    const [error, setError] = useState(false)
    const currency = useSelector(state => state.session?.association?.currentCurrency)

    useEffect(() => {
        dispatch(getLastOrders())
            .then(setOrders)
            .catch(() => setError(true))
    }, [dispatch, currency])

    const assetsToText = (assets) => {
        if (assets <= 5000) {
            return t('common.billionsBetween', {min: 0, max: 5})
        } else if (assets <= 15000) {
            return t('common.billionsBetween', {min: 5, max: 15})
        } else {
            return t('common.billionsMoreThan', {count: 15})
        }
    }

    return orders?.length > 0 ? (
        <Paper sx={{ p: 3, width: "100%"}}>
            <Typography variant='h3' mb={3}>
                <i className="ri-flashlight-fill"/>
                <span>{t('pages-BankDashboard.lastOrders')}</span>
            </Typography>
            { !error ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "10rem"}}>{t('pages-BankDashboard.interest')}</TableCell>
                            <TableCell style={{textAlign: "right", width: "12rem"}}>{t('pages-BankDashboard.volume')}</TableCell>
                            <TableCell>{t('pages-BankDashboard.product')}</TableCell>
                            <TableCell>{t('pages-BankDashboard.bankSegment')}</TableCell>
                            <TableCell>{t('pages-BankDashboard.sector')}</TableCell>
                            <TableCell>{t('pages-BankDashboard.date')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders?.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).map(order => (
                            <TableRow key={Object.values(order).join('-')}>
                                <TableCell style={{width: "10rem"}}>
                                    <InterestBadge interest={order.interest} />
                                </TableCell>
                                <TableCell style={{textAlign: "right", width: "12rem"}}>
                                    {CurrencyOutput.formatMillion(order.volume * 1e6, order.currency)}
                                </TableCell>
                                <TableCell>{t(`common.productLongName${order.productId}`)}</TableCell>
                                <TableCell>{assetsToText(order.totalAssets)}</TableCell>
                                <TableCell>{order.business}</TableCell>
                                <TableCell>{DateOutput.format(new Date(order.created), "yyyy-MM-dd")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Alert severity="warning">{t('pages-BankDashboard.loadingFailed')}</Alert>
            )}
        </Paper>
    ) : null
}