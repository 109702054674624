import {Alert, Stack} from "@mui/material";
import {FundShareClassDto} from "@fixrate/fixrate-query";
import {useTranslation} from "react-i18next";
import {useSelector} from "#state/useSelector";

type FundReturnsDisclaimerProps = {
    shareClass: FundShareClassDto;
}
export default function FundReturnsDisclaimer({shareClass}: FundReturnsDisclaimerProps) {
    const {t} = useTranslation()
    const session = useSelector(state => state.session)
    if (!shareClass) {
        return null
    }
    function getAdditionalDisclaimer()  {
        if (session.language === 'nb') {
            return shareClass.additionalDisclaimerNB
        }
        if (session.language === 'en') {
            return shareClass.additionalDisclaimerEN
        }
        if (session.language === 'sv') {
            return shareClass.additionalDisclaimerSV
        }
        return false
    }
    const additionalDisclaimer = getAdditionalDisclaimer()


    return (
        <Alert severity='info' sx={{width: '100%', fontSize: '1.2rem', mt: '2rem'}}>
            {additionalDisclaimer && (
                <Stack spacing={1}>
                    <span>{additionalDisclaimer}</span>
                    <span>{t('pages-fund-details.returnsExplanation')}</span>
                </Stack>
            )}
            {!additionalDisclaimer && (
                <span>{t('pages-fund-details.returnsExplanation')}</span>
            )}
        </Alert>
    )
}
