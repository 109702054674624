import { PURPLE, SILVER_GRAY } from "#app/colors/colors";
import { MovieViewer } from "#app/components/MovieViewer/MovieViewer";
import { RichTextContent, RichTextRenderer } from "#app/components/PayloadCMS/RichTextRenderer/RichTextRenderer";
import { Employee, Media, Webinar } from "#app/services/payloadCMS/payload-types";
import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import { format, isFuture } from "date-fns";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { hvelvetsKvalerDescription } from "./customerEvents";

const localeLang = "nb-NO"

function getWebinarCategoryName(webinar: Webinar) {
    if (webinar.webinarCategory === "fixrateBankVaultWebinars") {
        return "Hvelvets kvaler"
    }

    return "Webinar"
}

function getWebinarCategoryDescription(webinar: Webinar) {
    if (webinar.webinarCategory === "fixrateBankVaultWebinars") {
        return hvelvetsKvalerDescription
    }

    return ""
}

function getPayloadImage(img: Media | string) {
    if (typeof img === "string") {
        return img
    } else {
        return img.url
    }
}

function getPayloadEmployee(employee: Employee | string) {
    if (typeof employee === "string") {
        return null
    } else {
        return employee
    }
}

export default function WebinarCard({ webinar } : { webinar: Webinar }) {
    const {t} = useTranslation()
    const eventDay = new Date(webinar.dateFrom).toLocaleString(localeLang, { weekday: "long" })
    const eventMonth = new Date(webinar.dateFrom).toLocaleString(localeLang, { month: "short" })
    const isUpcoming = isFuture(new Date(webinar.dateTo))
    const [videoVisible, setVideoVisible] = useState(false)
    const [readMore, setReadMore] = useState(false)
    const episodeNumber = webinar?.episodeNumber ? webinar?.episodeNumber : null
    const hasEpisodeNumber = episodeNumber !== null

    const toggleReadMore = () => {
        setReadMore(!readMore);
    }
    return (
        <Card key={webinar.eventUrl} sx={{maxWidth: "84rem"}}>
            <CardContent>
                <Stack sx={{py: 2}} direction={"row"} spacing={3} alignItems="flex-start" flexWrap={{xs: "wrap", md: "nowrap"}}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Stack borderRadius="100rem" alignItems="center" justifyContent="center" height={"6rem"} width={"6rem"} sx={{backgroundColor: PURPLE[50]}}>
                            <Typography lineHeight={"1"} fontWeight="800" fontSize={"2rem"}>{format(new Date(webinar.dateFrom), "d")}</Typography>
                            <Typography lineHeight={"1"} fontWeight="500" fontSize={"1.4rem"}>{eventMonth}.</Typography>
                        </Stack>
                        <Stack>
                            <Typography fontWeight="700" color={PURPLE[500]}>{eventDay.charAt(0).toUpperCase() + eventDay.slice(1)}</Typography>
                            <Typography noWrap fontSize={"1.4rem"} fontWeight="600" color={SILVER_GRAY[500]}>{new Date(webinar.dateFrom).toLocaleTimeString(localeLang, { timeStyle: "short", timeZone: "Europe/Oslo" })} – {new Date(webinar.dateTo).toLocaleTimeString(localeLang, { timeStyle: "short", timeZone: "Europe/Oslo" })}</Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Stack>
                                <Typography color={PURPLE[500]} fontSize="1.6rem">
                                    {getWebinarCategoryName(webinar)}
                                    {hasEpisodeNumber ? (" – " + t('pages-BankDashboard.episode') + " " + episodeNumber) : ""}
                                    { getWebinarCategoryDescription(webinar) && (
                                        <Tooltip sx={{fontSize: "1.6rem"}} title={getWebinarCategoryDescription(webinar)}>
                                            <i className="ri-question-line" style={{fontSize: "1.8rem", marginLeft: "0.5rem"}}/>
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Typography fontWeight="700" fontSize="2.2rem">{webinar.title}</Typography>
                                <Stack direction="row" mt={2} spacing={2}>
                                    <Button onClick={toggleReadMore} variant="outlined" size="small" color="primary">
                                        { !readMore? t('pages-BankDashboard.readMore') : t('pages-BankDashboard.hide') }
                                    </Button>
                                    { isUpcoming && (
                                        <Button variant={"contained"} href={webinar.eventUrl} target="_blank" size="small">{t('pages-BankDashboard.joinWebinar')}</Button>
                                    )}
                                    { !isUpcoming && webinar.vimeoID && (
                                        <Button variant={"contained"} size="small" onClick={() => setVideoVisible(true)}>{t('pages-BankDashboard.watchWebinar')}</Button>
                                    )}
                                </Stack>
                            </Stack>
                            { readMore && (
                                <Stack spacing={2} mt={2}>
                                    { isUpcoming && (
                                        webinar?.description?.root?.children?.map((block: RichTextContent, key) => {
                                            return (
                                                <RichTextRenderer 
                                                    key={key} 
                                                    content={block}
                                                />
                                            )
                                        })
                                    )}
                                    { !isUpcoming && (
                                        webinar?.descriptionPast?.root?.children?.map((block: RichTextContent, key) => {
                                            return (
                                                <RichTextRenderer 
                                                    key={key} 
                                                    content={block}
                                                />
                                            )
                                        })
                                    )}
                                </Stack>
                            )}
                        </Stack>
                        { readMore && (
                            <Stack spacing={2}>
                                { isUpcoming && webinar.guests.length > 0 && (
                                    <Stack spacing={1.5}>
                                        <Typography fontWeight="700" fontSize="1.8rem">{ webinar.guests.length > 1 ? t('pages-BankDashboard.guests') : t('pages-BankDashboard.guest') }</Typography>
                                        <Stack spacing={1.5}>
                                            { webinar.guests.map(guest => (
                                                <Stack key={webinar?.id + guest?.id} direction="row" alignItems="center" spacing={1}>
                                                    <Stack alignItems="center" justifyContent="center">
                                                        <img src={getPayloadImage(guest.image)} alt={guest.name} style={{width: "5rem", height: "5rem", borderRadius: "100rem"}}/>
                                                    </Stack>
                                                    <Box>
                                                        <Typography fontWeight="700" fontSize="1.6rem">{guest.name}</Typography>
                                                        <Typography fontSize="1.4rem" color={SILVER_GRAY[500]}>{guest.title}</Typography>
                                                    </Box>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Stack>
                                )}
                                { isUpcoming && webinar.hosts.length > 0 && (
                                    <Stack spacing={1.5}>
                                        <Typography fontWeight="700" fontSize="1.8rem">{ webinar.hosts.length > 1 ? t('pages-BankDashboard.hosts') : t('pages-BankDashboard.host') }</Typography>
                                        <Stack spacing={1.5}>
                                            { webinar.hosts.map(host => {
                                                const employee = getPayloadEmployee(host.employee)
                                                const employeeImage = getPayloadImage(employee?.image) !== null ? getPayloadImage(employee?.image) : ""
                                                return (
                                                    <Stack key={getPayloadEmployee(host.employee).id} direction="row" alignItems="center" spacing={1}>
                                                        <Stack alignItems="center" justifyContent="center">
                                                            <img src={employeeImage} alt={employee.id} style={{width: "5rem", height: "5rem", borderRadius: "100rem"}}/>
                                                        </Stack>
                                                        <Box>
                                                            <Typography fontWeight="700" fontSize="1.6rem">{employee.name}</Typography>
                                                            <Typography fontSize="1.4rem" color={SILVER_GRAY[500]}>{employee.title}</Typography>
                                                        </Box>
                                                    </Stack>
                                                )
                                            })}
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>
                        )}
                        { videoVisible && <MovieViewer videoActive={setVideoVisible} vimeoUrl={`https://player.vimeo.com/video/${webinar.vimeoID}?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no`} /> }
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}
