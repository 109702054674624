import config from '#app/config'
import { useSelector } from '#app/state/useSelector'
import { Paper } from '#app/components'
import DocumentLink from '#app/components/DocumentLink'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

const DpaAgreement = () => {
    const {t} = useTranslation()
    const dpaDocumentId = useSelector(state => state.depositor.contracts.filter(c => c.documentType === 'DEPOSITOR_DPA')?.[0]?.documentId)
    const dpaUrl = `${API_BASE_URL}/api/document/id/${dpaDocumentId}/data`
    const dpaSubcontractorsUrl = `${API_BASE_URL}/document/template/depositor/DEPOSITOR_DPA_ADDENDUM_SUBCONTRACTORS`
    return (
        dpaDocumentId ? (
            <Paper title={t('pages-organizations.dpaAgreementHeading')}>
                <p>{t('pages-organizations.dpaAgreementMessage')}</p>
                <DocumentLink link={dpaUrl} name={t('pages-organizations.dpaLink')}/>
                <DocumentLink link={dpaSubcontractorsUrl} name={t('pages-organizations.dpaSubcontractorsLink')}/>
            </Paper>
        ) : null
    )
}

export default DpaAgreement