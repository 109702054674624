import { PURPLE, SPRING_GREEN } from "#app/colors/colors";
import { FundDto } from "@fixrate/fixrate-query";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ScaleArrow from '../scale-arrow.svg?react';
export default function RiskScale({ fund }: { fund: FundDto }) {
    const {t} = useTranslation()
    return (
        <Stack spacing={1.5} mt={2}>
            <Stack direction="row">
                {[...new Array(7)].map((_, i) => (
                    <Box
                        key={i}
                        height={"4rem"}
                        flexGrow={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bgcolor={i + 1 === fund.risk ? PURPLE[800] : PURPLE[50]}
                        fontWeight={i + 1 === fund.risk ? 700 : 500}
                        color={i + 1 === fund.risk ? SPRING_GREEN[500] : PURPLE[800]}
                        position="relative"
                    >
                        { i + 1 === fund.risk && <Box mt={"0.5rem"} top={"-1.8rem"} color={PURPLE[800]} fontSize="1.2rem" position="absolute">▼</Box> }
                        <span>{i + 1}</span>
                    </Box>
                ))}
            </Stack>
            <Stack>
                <ScaleArrow />
            </Stack>
            <Stack justifyContent={"space-between"} direction="row">
                <Stack>
                    <Typography variant="caption" fontWeight={700}>{t("pages-fund-details.lowerRisk")}</Typography>
                    <Typography variant="caption">{t("pages-fund-details.lowerReturn")}</Typography>
                </Stack>
                <Stack textAlign={"right"}>
                <Typography variant="caption" fontWeight={700}>{t("pages-fund-details.higherRisk")}</Typography>
                    <Typography variant="caption">{t("pages-fund-details.higherReturn")}</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}
