import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import TagSelector from '#blocks/TagSelector/TagSelector'
import TopicCompact from '#blocks/TopicCompact/TopicCompact'
import { Animated, DateOutput } from "#components"
import Button from "#components/Button"
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner'
import { startElectronicSignature } from '#services/thunks/documents'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { BankLimitedDto, DocumentDto, OrderDto, OrderState, ProcessHistoryDto, Product } from '@fixrate/fixrate-query'
import { addMonths, startOfDay } from "date-fns"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import OrderNavigation from './OrderNavigation'
import OrdersDepositorReadyForTransaction from './OrdersDepositorReadyForTransaction'
import OrdersDepositorSignature from './OrdersDepositorSignature'
import OrdersDepositorWaiting from './OrdersDepositorWaiting'
import styles from './OrdersList.module.scss'

type Props = {
    ingress: string
    orders: OrderDto[]
    hiddenOrders?: OrderDto[]
    showSignAllButton?: boolean
    showNavigation?: boolean
}

export default function OrdersList({ingress, orders, hiddenOrders, showSignAllButton, showNavigation = true}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isCheckingSignatureStatusForAnyDocument = useSelector(selectors.isCheckingSignatureStatusForAnyDocument)
    const banks = useSelector(state => state.banks)
    const products = useSelector(state => state.products)
    const documentIdToDocumentMapper = useSelector(selectors.documentIdToDocumentMapper)

    if (Object.keys(banks).length === 0 || Object.keys(products).length === 0) {
        return (
            <div className="layout__center-content">
                <LoadingSpinner text={t('common.loadingData')}/>
            </div>
        )
    }

    function onSignAll() {
        const processId = uuidv4()
        dispatch(startElectronicSignature(processId, 'ALL_ORDER_DOCUMENTS', null))
        navigate(`/signature/${processId}?context=/orders`)
    }

    let signAllElement = null
    if (showSignAllButton) {
        const documentsToSign = orders
            .flatMap(order => documentIdToDocumentMapper(order.documents).filter(document => (document.documentType === 'ACCOUNT_AGREEMENT' || document.documentType === 'ACCOUNT_TERMINATION')))
            .filter(document => document.userCanSign)

        const canSignMoreThanOne = documentsToSign.length > 1

        signAllElement = canSignMoreThanOne && !isCheckingSignatureStatusForAnyDocument && (
            <Button onClick={onSignAll} className={styles.signAll}>
                <i className="ri-quill-pen-line"/>
                <span>{t('pages-orders.depositorSignAll')}</span>
            </Button>
        )
    }

    return (
        <section>
            {showNavigation && <OrderNavigation/>}
            {orders.length > 0 && (
                <>
                    <p className={styles.ingress}><span>{ingress}</span></p>
                    <div>{signAllElement}</div>
                    <OrderSection
                        orders={orders}
                        hiddenOrders={hiddenOrders}
                        banks={banks}
                        products={products}
                        documentIdToDocumentMapper={documentIdToDocumentMapper}
                    />
                </>
            )}
            {orders.length === 0 && (
                <h2 className={styles.emptyText}>
                    <span>{t('pages-orders.noOrdersInCategory')}</span>
                </h2>
            )}
        </section>
    );
}

type OrderSectionProps = {
    orders: OrderDto[]
    hiddenOrders?: OrderDto[]
    banks: {[key: string]: BankLimitedDto}
    products: {[key: string]: Product}
    documentIdToDocumentMapper: (documentIds: string[]) => DocumentDto[]
}

function OrderSection({orders, hiddenOrders, banks, products, documentIdToDocumentMapper}: OrderSectionProps) {
    const {t} = useTranslation()

    const displayShowAllButton = hiddenOrders?.length > 0
    const [showAll, setShowAll] = useState(!displayShowAllButton)
    const showAllDateLimit = displayShowAllButton && startOfDay(addMonths(new Date(), -1))

    const orderList = (orders: OrderDto[]) => orders.map(order => {
        const actionRequired = ['READY_FOR_SIGNING', 'READY_FOR_TRANSACTION'].includes(order.orderState)

        const documents = documentIdToDocumentMapper(order.documents)

        return (
            <Animated key={order.id}>
                <TopicCompact
                    data-cy="orderCard"
                    actionRequired={actionRequired}
                    volume={order.volume}
                    bankName={banks[order.ad.bankId].name}
                    interestRate={order.ad.nominalInterestRate}
                    product={products[order.ad.productId]}
                    documents={documents}
                    order={order}
                    orderState={order.orderState}
                >
                    <OrderInput order={order} bank={banks[order.ad.bankId]}/>
                    {order.orderState !== 'CANCELED' && <div className={styles.tags}>
                        <TagSelector order={order}/>
                    </div>}
                </TopicCompact>
            </Animated>
        )
    })

    return (
        <div>
            {orders.length > 0 && (
                <div className={styles.orderList}>
                    <div className={styles.depositorList}>
                        {orderList(orders)}
                    </div>
                </div>
            )}
            {orders.length === 0 && !showAll && (
                <p className={styles.empty}>{t('pages-orders.noOrders')}</p>
            )}
            {!showAll && displayShowAllButton && (
                <Button onClick={() => setShowAll(true)}>{t('pages-orders.depositorShowRejectedBefore', {date: DateOutput.formatDate(showAllDateLimit)})}</Button>
            )}
            {showAll && displayShowAllButton && (
                <Button onClick={() => setShowAll(false)}>{t('pages-orders.depositorHideRejectedBefore', {date: DateOutput.formatDate(showAllDateLimit)})}</Button>
            )}
            {showAll && hiddenOrders?.length > 0 && (
                <>
                    <p></p>
                    <div className={styles.orderList}>
                        <div className={styles.depositorList}>
                            {orderList(hiddenOrders)}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

type OrderInputProps = {
    order: OrderDto & {lastHistoryAction?: ProcessHistoryDto}
    bank: BankLimitedDto
}

function OrderInput({order, bank}: OrderInputProps) {
    const {t} = useTranslation()

    const getContentByState = (state: OrderState) => {
        switch (state) {
            case 'INITIAL_PROCESSING':
            case 'READY_FOR_APPROVAL':
            case 'CANCELED':
                return <OrdersDepositorWaiting order={order} bank={bank}/>
            case 'READY_FOR_SIGNING':
                return <OrdersDepositorSignature order={order}/>
            case 'READY_FOR_TRANSACTION':
                return <OrdersDepositorReadyForTransaction order={order}/>
            case 'READY_FOR_TRANSACTION_CONFIRMATION':
                return <OrdersDepositorWaiting order={order} bank={bank}/>
            case 'COMPLETED':
                return <p>{t('pages-orders.depositorOrderCompleted')}</p>
        }
    }

    return <div className={styles.orderContent}>{getContentByState(order.orderState)}</div>
}
