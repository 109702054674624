import { useSelector } from "#state/useSelector";
import { DepositorDto, DepositorWarningType, OrganisationalPersonDto } from "@fixrate/fixrate-query";
import { Alert, Button, Stack } from '@mui/material';
import isAfter from "date-fns/isAfter";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
    person : OrganisationalPersonDto,
    depositor : DepositorDto
    onSendInvite : () => void
    onResendInvite : () => void
    onEditNow : () => void
    showRedLeftBorder : boolean
}

type WarningElementProps = {
    warningText: string
    linkText?: string
    dataCy?: string
    onSendInvite?: () => void
    showRedLeftBorder: boolean
}

export const WarningElement = ({warningText, linkText, dataCy, onSendInvite, showRedLeftBorder} : WarningElementProps) : JSX.Element => {
    return (
        <Alert
            sx={{'& .MuiAlert-icon': { display: { xs: "none", md: "flex" } }, '& .MuiAlert-message': { px: 0, width: '100%'}}}
            severity={showRedLeftBorder ? 'error' : 'warning'}>
            <Stack spacing={3} sx={{fontSize: {xs: '1.2rem', sm: '1.4rem'}}} width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <span>{warningText}</span>
                { onSendInvite && (
                    <Button sx={{flexShrink: '0', whiteSpace: 'nowrap', px: 2}} color='inherit' variant='outlined' size='small' onClick={onSendInvite} data-cy={dataCy}>
                        {linkText}
                    </Button>
                )}
            </Stack>
        </Alert>
    )
}

export default function PersonWarnings({person, depositor, onSendInvite, onResendInvite, onEditNow, showRedLeftBorder}: Props) {
    const {t} = useTranslation()

    const session = useSelector(({session}) => session)
    const navigate = useNavigate()

    const userInvite = depositor.userInvites.find(ui => ui.id === person.associatedInviteId)
    const inviteExpired = userInvite && isAfter(new Date(), new Date(userInvite.expirationDate))
    const inviteInvalid = userInvite?.invalid

    const hasWarning = (type: DepositorWarningType): boolean =>
        depositor.warnings.some(warning => warning.type === type && (!warning.personId || warning.personId === person.personId))

    return (
        <>
            {hasWarning('NEED_INFO_FOR_CUSTOMER_DECLARATION') &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningCustomerDeclarationInfoMissing')}
                    linkText={t('blocks-UsersList.menuItemAddInformation')}
                    dataCy="editNow"
                    onSendInvite={onEditNow}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE') &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningNeedsInfoForAuthorizationsDocuments')}
                    linkText={t('blocks-UsersList.menuItemAddInformation')}
                    dataCy="editNow"
                    onSendInvite={onEditNow}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('INVITE_IS_NOT_SENT') &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningInvitationIsNotSent')}
                    linkText={t('blocks-UsersList.menuItemSendInviteNotification')}
                    dataCy="sendInviteNow"
                    onSendInvite={onResendInvite}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('PERSON_NEEDS_INVITE') &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningNeedsInvite')}
                    linkText={t('blocks-UsersList.menuItemInviteNow')}
                    dataCy="inviteNowLink"
                    onSendInvite={onSendInvite}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('AUTHORIZATION_SIGNER_NEEDS_INVITE') &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningNeedsInviteForAuthorizations')}
                    linkText={t('blocks-UsersList.menuItemInviteNow')}
                    dataCy="inviteNowLink"
                    onSendInvite={onSendInvite}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {inviteExpired &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningInviteExpired')}
                    linkText={t('blocks-UsersList.menuItemResendInvite')}
                    dataCy="resendNowLink"
                    onSendInvite={onResendInvite}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {inviteInvalid && !inviteExpired &&
                <WarningElement
                    warningText={t('blocks-UsersList.warningInviteInvalid')}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('MISSING_IDENTIFICATION_DOCUMENT') &&
                <WarningElement
                    warningText={t('blocks-UsersList.userNeedsIdentityDocument')}
                    linkText={t('blocks-UsersList.menuItemCreateIdDocument')}
                    dataCy="createIdDocumentNowLink"
                    onSendInvite={session.id === person.associatedUserId ? () => navigate('/profile') : undefined}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('MISSING_AUTHORIZATION_DOCUMENT') &&
                <WarningElement
                    warningText={t('blocks-UsersList.userNeedsAuthorizationDocument')}
                    linkText={t('components-ShoppingCart.goToAuthorizations')}
                    onSendInvite={() => navigate(`/organizations/${depositor.id}/users/power-of-attorney`)}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

            {hasWarning('AUTHORIZATION_DOCUMENT_NOT_SIGNED') &&
                <WarningElement
                    warningText={t('blocks-UsersList.userNeedsAuthorizationDocument')}
                    linkText={t('components-ShoppingCart.goToAuthorizations')}
                    onSendInvite={() => navigate(`/organizations/${depositor.id}/users/power-of-attorney`)}
                    showRedLeftBorder={showRedLeftBorder}
                />
            }

        </>
    )
}
