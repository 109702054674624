import Paper from '#app/components/Paper/FxPaper'
import DocumentList from '#components/DocumentList/DocumentList'
import {DocumentDto} from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

export default function DepositDetailsDocuments(props: { documents: Array<DocumentDto> }) {

    const {t} = useTranslation()

    return (
        <Paper title={t('pages-portfolio-depositor.documentsHeader')}>
            <p>
                {t('pages-portfolio-depositor.documentsInfo')}
            </p>
            <DocumentList documents={props.documents} sortDirection={'desc'}/>
        </Paper>
    )
}
