import {
    FundDto,
    FundEsgCategory,
} from '@fixrate/fixrate-query'
import {useEffect, useState} from 'react'
import useUiSetting from "#services/useUiSetting";


const ESG_ARTICLES: FundEsgCategory[] = ['ARTICLE_6', 'ARTICLE_8', 'ARTICLE_9']

type FundFilter = (fund: FundDto) => boolean

export type FundFilterStorage = ReturnType<typeof useFundFilterStorage>

export default function useFundFilterStorage() {

    const [filters, setFilters] = useState<FundFilter[]>([])

    const [esgFilter, setEsgFilter] = useUiSetting('esgFilter', ESG_ARTICLES.reduce((acc, category) => {
        acc[category] = false
        return acc
    }, {}))
    const [morningStarCategories, setMorningStarCategories] = useUiSetting('fundCategoriesFilter', [])
    const [vffCategories, setVffCategories] = useUiSetting('fundVffCategoriesFilter', [])
    const [filterOmf, setFilterOmf] = useUiSetting('fundOmfFilter', false)
    const [filterSubOrdinatedLoans, setFilterSubOrdinatedLoans] = useUiSetting('fundSubordinateLoanFilter', false)
    const [filterMinimumAum, setFilterMinimumAum] = useUiSetting('fundMinimumAumFilter', 0, 1000)
    const [filterCreditDurationCategory, setFilterCreditDurationCategory] = useUiSetting('fundCreditDurationCategoryFilter', '')


    useEffect(() => {
        function updateFilters() {
            const filters: FundFilter[] = []

            if (ESG_ARTICLES.some(category => esgFilter[category])) {
                filters.push(fund => esgFilter[fund.esgCategory])
            }

            if (morningStarCategories.length > 0) {
                filters.push(fund => morningStarCategories.includes(fund.morningstarCategory))
            }

            if (vffCategories.length > 0) {
                filters.push(fund => vffCategories.includes(fund.vffCategory))
            }

            if (filterOmf) {
                filters.push(fund => fund.omf)
            }

            if (filterMinimumAum > 0) {
                filters.push(fund => fund.totalAssets >= filterMinimumAum)
            }

            if (filterSubOrdinatedLoans) {
                filters.push(fund => !fund.subOrdinatedLoans)
            }

            if (filterCreditDurationCategory) {
                filters.push(fund => fund.creditDurationCategory === filterCreditDurationCategory)
            }

            setFilters(filters)
        }

        updateFilters()
    }, [esgFilter, morningStarCategories, vffCategories, filterOmf, filterMinimumAum, filterSubOrdinatedLoans, filterCreditDurationCategory])


    function reset() {
        setEsgFilter(ESG_ARTICLES.reduce((acc, category) => {
            acc[category] = false
            return acc
        }, {}))
        setMorningStarCategories([])
        setVffCategories([])
        setFilterOmf(false)
        setFilterSubOrdinatedLoans(false)
        setFilterMinimumAum(0)
        setFilterCreditDurationCategory(null)
    }

    return {
        filters,
        reset,
        // State fields
        esgFilter,
        setEsgFilter,
        morningStarCategories,
        setMorningStarCategories,
        vffCategories,
        setVffCategories,
        filterOmf,
        setFilterOmf,
        filterSubOrdinatedLoans,
        setFilterSubOrdinatedLoans,
        filterMinimumAum,
        setFilterMinimumAum,
        filterCreditDurationCategory,
        setFilterCreditDurationCategory,
    }
}
