import classNames from 'classnames'
import styles from './ProgressBar.module.scss'

type StepProps = {
    current: boolean
    currentStepNumber: number
    stepNumber: number
    text: string
    fillArrow: boolean
}

const Step = ({current, currentStepNumber, stepNumber, text, fillArrow}: StepProps) => (
    <li className={classNames(current && styles.active)}>
        <div className={styles.content}>
            {(currentStepNumber > stepNumber) && <i className="green ri-checkbox-circle-line"/>}
            <div>
                <span className={styles.title}>{text}</span>
            </div>
        </div>
    </li>
)

type Props = {
    currentStep: number
    texts: string[]
}

export default function ProgressBar(props: Props) {
    const stepElements = []
    for (let i = 0; i < props.texts.length; i++) {
        stepElements.push(
            <Step key={i}
                  stepNumber={i + 1}
                  currentStepNumber={props.currentStep + 1}
                  text={props.texts[i]}
                  current={props.currentStep === i}
                  fillArrow={(props.currentStep + 1) === i}
            />
        )
    }

    return (
        <div className={classNames(styles.progress)}>
            <ul className={styles.progressBar}>
                {stepElements}
            </ul>
        </div>
    )
}

