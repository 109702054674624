import { Progress, ProgressStep } from "#components/Progress/Progress"
import { useTranslation } from "react-i18next"

export default function RegistrationProgress({ step }: { step: number }) {
    const { t } = useTranslation()

    return (
        <Progress step={step} edgeBar={true}>
            <ProgressStep bottomText={t('layers-Signup.progressStep1')} />
            <ProgressStep bottomText={t('layers-Signup.progressStep2')}/>
            <ProgressStep bottomText={t('layers-Signup.progressStep3')}/>
        </Progress>
    )
}
