
import { InterestOutput } from '#app/components'
import { Chart, ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { nb } from 'date-fns/locale'
import { useRef } from 'react'
interface LineChartProps {
    chartData: ChartData,
    yMin?: number,
}

const LineChart = ({chartData, yMin=0}: LineChartProps) => {
    const chartRef = useRef<Chart | null>(null)

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        if (!canvas) return;

        const context = canvas.getContext("2d");
        if (context) {
            chartRef.current?.destroy()
            chartRef.current = new Chart(context, {
                type: "line",
                data: chartData,
                options: {
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                padding: 20
                            },
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                tooltipFormat: 'dd.MM.yyyy',
                                unit: 'month',
                                displayFormats: {
                                    month: 'MMM yyyy'
                                },
                            },
                            ticks: {
                                autoSkipPadding: 30,
                            },
                            adapters: {
                                date: {
                                    locale: nb
                                }
                            }
                        },
                        y: {
                            ticks: {
                                precision: 1,
                                callback: function(value) {
                                    const val = typeof value === "number" ? value : parseFloat(value)
                                    return InterestOutput.format(val)
                                }
                            },
                            beginAtZero: true,
                            min: yMin
                        }
                    },
                    onResize: (chart, size) => {
                        if (size.height < 300) {
                            chart.options.maintainAspectRatio = false
                        }
                    },
                },
            })
        }
    }

    return (
        <canvas ref={canvasCallback}/>
    )
}

export { LineChart }
