import { Fragment } from 'react';
import MenuItem from './MenuItem'
import config from '#app/config'
import {useSelector} from '#state/useSelector'
import {isLoggedIn, messageTasksActionRequiredByUserCountSelector} from '#state/selectors'
import {useTranslation} from 'react-i18next'

const ADMIN_URL = config().adminUrl
const KPI_DASHBOARD_URL = config().kpiDashboardUrl + '/kpi-dashboard'

const SystemUserMenu = () => {
    const {t} = useTranslation()

    const loggedIn = useSelector(isLoggedIn)
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)

    const association = useSelector(({session}) => session.association)
    const isAdminUser = association.organisationType === 'FIXRATE' && (association.roles.some(role => role === 'FIXRATE_ADMIN' || role === 'FIXRATE_OPERATIONS' || role === 'FIXRATE_SALES'))
    const isManagementUser = association.organisationType === 'FIXRATE' && association.roles.includes('FIXRATE_MANAGEMENT')

    const inboxMenuItem = (
        <MenuItem
            id="inboxMenuItem"
            link={'/inbox'}
            name={t('menu.inbox')}
            remixIcon="ri-inbox-line"
            counter={messageTasksActionRequiredByUserCount}
        />
    )

    const analyticsMenuItem = (
        <MenuItem
            id="analyticsMenuItem"
            link={'/analytics'}
            name={t('menu.analytics')}
            remixIcon="ri-file-chart-line">
            <MenuItem.SubNav id="portfolioOverviewLink" to="/analytics/bank/ads">
                {t('menu.ads')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="portfolioReportsLink" to="/analytics/bank/deposits">
                {t('menu.deposits')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="portfolioReportsLink" to="/analytics/nibor">
                {t('menu.nibor')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="analyticsLastOrders" to="/analytics/last-orders">
                {t('menu.lastOrders')}
            </MenuItem.SubNav>
        </MenuItem>
    )

    const reportsMenuItem = (
        <MenuItem
            id="reportsMenuItem"
            link={'/report'}
            name={t('menu.reports')}
            remixIcon="ri-file-chart-2-line"
        />
    )

    const settingMenuItem = (
        <MenuItem
            id="settingsMenuItem"
            link={'/settings'}
            name={t('menu.settings')}
            remixIcon="ri-settings-3-line"
        />
    )

    const documentsMenuItem = (
        <MenuItem
            id="documentsMenuItem"
            link={'/documents'}
            name={t('menu.documents')}
            remixIcon="ri-file-copy-2-line"
        />
    )

    const adminMenuItem = (
        <MenuItem
            id="adminMenuItem"
            link={ADMIN_URL || 'ukjent'}
            externalLink={true}
            name={t('menu.admin')}
            remixIcon="ri-admin-line"
        />
    )

    const kpiDashboardMenuItem = (
        <MenuItem
            id="kpiDashboardMenuItem"
            link={KPI_DASHBOARD_URL || 'ukjent'}
            externalLink={true}
            name={t('menu.kpiDashboard')}
            remixIcon="ri-file-chart-line"
        />
    )

    const fixturesMenuItem = (
        <MenuItem
            id="fixturesMenuItem"
            link={'/fixtures'}
            name={'Fixtures'}
            remixIcon="ri-save-line"
        />
    )

    const loginMenuItem = (
        <MenuItem
            id="loginMenuItem"
            key="login"
            link={'/marketplace?login'}
            name={t('menu.logIn')}
            remixIcon="ri-account-circle-line"/>
    )

    return (
        <Fragment>
            <MenuItem
                id="marketPlaceMenuItem"
                link={'/marketplace'}
                name={t('menu.marketplace')}
                remixIcon="ri-store-2-line"
            />
            {inboxMenuItem}
            {loggedIn && analyticsMenuItem}
            {loggedIn && reportsMenuItem}
            {loggedIn && settingMenuItem}
            {loggedIn && documentsMenuItem}
            {loggedIn && isAdminUser && adminMenuItem}
            {loggedIn && (isManagementUser || isAdminUser) && kpiDashboardMenuItem}
            {loggedIn && config().enableFixtureReload && fixturesMenuItem}
            {!loggedIn && loginMenuItem}
        </Fragment>
    )
}

export default SystemUserMenu
