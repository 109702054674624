import { Fragment } from 'react';
import {PageHeader, PageLayout} from '#components'

export default function IndexRedirectPage() {
    return (
        <Fragment>
            <PageHeader/>
            <PageLayout/>
        </Fragment>
    );
}