import { PageHeader, PageLayout } from '#app/components'
import EmptyState from '#app/components/EmptyState/EmptyState'
import AnalyticsAds from '#app/pages/Analytics/bank/AnalyticsAds'
import AnalyticsDepositsBank from '#app/pages/Analytics/bank/AnalyticsDepositsBank'
import AnalyticsExposure from '#app/pages/Analytics/depositor/AnalyticsExposure'
import usePortfolio from '#app/services/usePortfolio'
import { useSelector } from '#app/state/useSelector'
import AnalyticsNibor from '#pages/Analytics/AnalyticsNibor'
import { Box, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import AnalyticsDashboard from './dashboard/AnalyticsDashboard'
import AnalyticsLastOrders from './AnalyticsLastOrders'
import BankMarketReport from './bank/MarketReport/BankMarketReport'
import januaryData from "./bank/MarketReport/data/2024-01-18.json"
import februaryData from "./bank/MarketReport/data/2024-02-20.json"
import AnalyticsDepositsDepositor from './depositor/AnalyticsDepositsDepositor'

export const AnalyticsPaperSx: SxProps = {
    width: "100%"
}

export const NotAvailableInCurrency = () => {
    const { t } = useTranslation()
    const portfolio = usePortfolio()
    const currency = portfolio?.currency
    return (
        <PageLayout>
            <EmptyState illustration={{name: "manAnalyzing", color: "default"}} title={t('pages-analytics.analyticsNotAvailableInCurrency', {currency: currency})} />
        </PageLayout>
    )
}

function Analytics() {
    const isDepositor = useSelector(state => state.session.organisationType === "DEPOSITOR")
    const portfolio = usePortfolio()
    const currency = portfolio?.currency
    const { t } = useTranslation()

    return (
        <>
            <PageHeader icon="ri-bar-chart-line" title={t('menu.analytics')}/>
            <PageLayout>
                <Box width="110rem" maxWidth="100%">
                    <Routes>
                        <Route path='' element={<Navigate to={{ pathname: isDepositor ? '/analytics/depositor/dashboard' : '/analytics/bank/deposits' }} />} />
                        <Route path='ads' element={<Navigate to={{ pathname: '/analytics/bank/ads' }} />} />
                        <Route path='deposits' element={<Navigate to={{ pathname: isDepositor ? '/analytics/depositor/deposits' : '/analytics/bank/deposits' }} />} />
                        <Route path='bank/ads' element={<AnalyticsAds/>} />
                        <Route path='bank/deposits' element={<AnalyticsDepositsBank/>} />
                        <Route path='last-orders' element={<AnalyticsLastOrders/>} />
                        <Route path='nibor' element={<AnalyticsNibor/>} />
                        <Route path='depositor/deposits' element={ currency === 'NOK' ? <AnalyticsDepositsDepositor/> : <NotAvailableInCurrency />} />
                        <Route path='depositor/exposure' element={ currency === 'NOK' ? <AnalyticsExposure/> : <NotAvailableInCurrency />} />
                        <Route path='bank/market-report/2024-01' element={<BankMarketReport reportData={januaryData} date={"2024-01-18"} />} />
                        <Route path='bank/market-report/2024-02' element={<BankMarketReport reportData={februaryData} date={"2024-02-20"} />} />
                    </Routes>
                </Box>
            </PageLayout>
        </>
    )
}


export default Analytics
