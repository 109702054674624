import Paper from '#app/components/Paper/FxPaper'
import ToggleSwitch from '#components/ToggleSwitch'
import styles from './BankInfo/BankInfo.module.css'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import config from '#app/config'
import {setPinCodeAuthorization} from '#services/thunks/depositor'
import * as selectors from '#state/selectors'
import {useSelector} from '#state/useSelector'
import {useAuthorization} from '#services/authorization'

export default function PinCodeAdmin() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const bank = useSelector(state => selectors.bankInSession(state))
    const isBankAdmin = useAuthorization().bank.hasAdminRole

    if (!isBankAdmin || !config().enableAdvancedSessions) return null

    return (
        <Paper id="pinCodeAdmin" title={t('pages-mybank.pinCodesHeader')} supportArticleId={''}>
            <div className={styles.settingWithToggle}>
                <div>
                    <label className="" htmlFor={'All'}>
                        {t('pages-mybank.pinCodesAllow')}
                    </label>
                    <p className={styles.settingDescription}>{t('pages-mybank.pinCodesInfo')}</p>
                </div>

                <ToggleSwitch
                    id="allowPinCodes"
                    checked={bank.allowPincodes}
                    onChange={() => {
                        dispatch(setPinCodeAuthorization(bank.id, 'BANK', !bank.allowPincodes))
                    }}
                />
            </div>
        </Paper>
    )
}
