import { LoadingSpinner, Paper } from "#app/components";
import PeriodPicker from "#app/components/PeriodPicker/PeriodPicker";
import { useSelector } from "#app/state/useSelector";
import { Box, Divider } from '@mui/material';
import { format, subMonths } from "date-fns";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportDownload } from "../../ReportDownload";
import usePortfolio from '#services/usePortfolio'

export default function CollectedStatement() {
    const {t} = useTranslation()

    const [collectedStatementPeriod, setCollectedStatementPeriod] = useState(format(subMonths(new Date(), 1), 'yyyy-MM'))

    const depositor = useSelector(state => state.depositor)
    const portfolio = usePortfolio()

    return (
        <Paper>
            <h3>{t('pages-portfolio-depositor.reportsCollectedStatementHeader')}</h3>
            <p>{t('pages-portfolio-depositor.reportsCollectedStatementInfo1')}</p>
            <p>{t('pages-portfolio-depositor.reportsCollectedStatementInfo2')}</p>
            <p>{t('pages-portfolio-depositor.reportsCollectedStatementInfo3')}</p>

            <PeriodPicker value={collectedStatementPeriod} onChange={setCollectedStatementPeriod}/>

            {!depositor && <LoadingSpinner text={t('pages-portfolio-depositor.reportsLoading')}/>}
            <Divider sx={{mt: 3, mb: 1}}/>
            <Box>
                {depositor && (
                    <ReportDownload
                        name={t('pages-portfolio-depositor.collectedStatement')}
                        excel={false}
                        description={collectedStatementPeriod}
                        link={`/document/depositor/${depositor?.id}/inventory?period=${collectedStatementPeriod}&portfolioId=${portfolio?.id}`}
                        dataCy='depositorInventorySection'
                        keyValue={"depositorInventorySection" + depositor?.id + collectedStatementPeriod}
                    />
                )}
            </Box>
        </Paper>
    )
}
