import { Fragment } from 'react';
import DepositList from './DepositList/DepositList'
import DepositFilter from './DepositFilter'
import ReportDownloadExcel from '#components/ReportDownloadExcel/ReportDownloadExcel'
import {PageHeader, PageLayout} from '#components'
import {useTranslation} from 'react-i18next'
import { Stack, Typography } from '@mui/material';

export default function PortfolioBankIndex() {
    const {t} = useTranslation()
    return (
        <Fragment>
            <PageHeader
                title={
                    <Stack direction="row" spacing={2}>
                        <DepositFilter/>
                        <ReportDownloadExcel report="DEPOSITS"/>
                    </Stack>
                }
            />
            <PageLayout>
                <Typography variant="h2" mb={2}>{t('pages-portfolio-bank.activeDeposits')}</Typography>
                <DepositList variant={'ACTIVE'}/>
                <Typography variant="h2" mb={2}>{t('pages-portfolio-bank.archivedDeposits')}</Typography>
                <DepositList variant={'INACTIVE'}/>
            </PageLayout>
        </Fragment>
    )
}
