import { CHART_COLORS } from "#app/colors/colors"
import { StaggData } from "#app/state/stagg"
import { ChartData } from "chart.js"

export const formatStaggData = (
    input: StaggData,
    fixrateGraphIndexes: number[] = [],
    includeDashedGaps = false
): ChartData => {
    const series = input[0]?.series
    const chartData: ChartData = {
        datasets: [],
    }

    if (!series) {
        return chartData
    }

    for (let i = 0; i < series.length; i++) {
        const seriesHasDashedGaps =
            includeDashedGaps &&
            series[i].values.filter((point) => point[1] === null).length > 0

        let tag = series[i].tags[Object.keys(series[i].tags)[0]]
        switch (tag) {
            case "FOLIO":
                tag = "Styringsrente"
                break
            case "SSB":
                tag = "Gjennomsnittsrente på innskudd (SSB)"
                break
            default:
        }

        chartData.datasets.push({
            label: tag,
            data: series[i].values.map(([date, value]) => ({
                x: new Date(date).getTime(),
                y: value,
            })),
            borderColor: CHART_COLORS[i],
            backgroundColor: CHART_COLORS[i],
            spanGaps: true,
            pointRadius: seriesHasDashedGaps ? 4 : 1,
            borderDash: seriesHasDashedGaps ? [6, 6] : [0, 0],
        })
    }

    fixrateGraphIndexes.forEach((index) => {
        chartData.datasets[index].borderColor = "#4D2A98"
        chartData.datasets[index].backgroundColor = "#4D2A98"
    })

    return chartData
}