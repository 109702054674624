import { PURPLE } from '#app/colors/colors'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import useShoppingCart from '#app/services/useShoppingCart'
import {DepositorDto, FundDto, FundShareClassDto} from '@fixrate/fixrate-query'
import { Chip, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChipSelect from '../../ChipSelect'
import usePortfolio from "#services/usePortfolio";
import useFundShareClassSelected from "#services/useFundShareClassSelected";

type Props = {
    fund: FundDto
    depositor?: DepositorDto
}

export default function ShareClassSelect({ fund, depositor }: Props) {
    const { t } = useTranslation()
    const shoppingcart = useShoppingCart('FUNDS')
    const portfolio = usePortfolio()
    const [fundClassId, setFundClassId] = useFundShareClassSelected(
        fund.id,
        fund.fundShareClasses?.[0]?.id
    )
    if (!fund.fundShareClasses || fund.fundShareClasses.length === 0 || !portfolio) {
        return null
    }

    const fundShareClasses: FundShareClassDto[] = Object.assign([], Object.values(fund.fundShareClasses
        .filter((shareClass) => shareClass.currency === portfolio.currency // Share class currency must match portfolio currency.
            && (shareClass.published || shareClass.tradableByDepositorIds?.includes(depositor?.id)) // Share class must be published or tradable by depositor.
        )
    )).sort((a, b) => {
            return (a.minimumInvestment ?? 0) - (b.minimumInvestment ?? 0)
    })
    if (fundShareClasses.length === 0) {
        return null
    }

    const handleFundClassIdChange = (value: string) => {
        setFundClassId(value)
        if (shoppingcart.getItemById(fund.id)) {
            shoppingcart.updateItemSubId(fund.id, value)
        }
    }
    const currencyOptions = {
        millionFormat: true,
        maximumDecimals: 0,
        minimumDecimals: 0,
        withCurrency: false,
    }

    return fundShareClasses.length > 1 ? (
        <ChipSelect
            value={fundClassId}
            onChange={(event) => handleFundClassIdChange(event.target.value)}
        >
            {fundShareClasses.map((shareClass) => (
                <MenuItem key={shareClass.id} value={shareClass.id}>
                    {shareClass.name === null
                        ? t('components-ShareClassSelect.from')
                        : shareClass.name + ': '}
                    {shareClass.minimumInvestment &&
                        shareClass.maximumInvestment &&
                        CurrencyOutputObject(shareClass.minimumInvestment, currencyOptions)}
                    {shareClass.minimumInvestment &&
                        shareClass.maximumInvestment &&
                        '-' +
                            CurrencyOutputObject(shareClass.maximumInvestment, currencyOptions) +
                            'M.'}
                    {shareClass.minimumInvestment &&
                        !shareClass.maximumInvestment &&
                        t('components-ShareClassSelect.from') +
                            CurrencyOutputObject(shareClass.minimumInvestment, currencyOptions) +
                            'M.'}
                </MenuItem>
            ))}
        </ChipSelect>
    ) : (
        <Chip
            sx={{
                backgroundColor: PURPLE[50],
                color: PURPLE[800],
                fontWeight: '600',
                height: '3.5rem',
                my: 0.5,
            }}
            label={
                (!fundShareClasses[0].name || fundShareClasses[0].maximumInvestment == null
                    ? t('components-ShareClassSelect.from')
                    : fundShareClasses[0]?.name + ': ') +
                CurrencyOutputObject(fundShareClasses[0].minimumInvestment, currencyOptions)
                + 'M.'
            }
        />
    )
}
