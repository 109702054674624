import { useCallback } from 'react';
import Paper from '#app/components/Paper/FxPaper'
import styles from './BankEmailDomain.module.css'
import {useTranslation} from '#components/i18n'
import {BankDto} from '@fixrate/fixrate-query'
import {LabeledInput} from "#components";
import { useFieldState } from '@fixrate/fieldstate';
import classNames from "classnames";
import {useCommand} from "#command";
import {useConfirmationModal} from '#app/layers/ConfirmationModal/ConfirmationModal'
import AsyncButton from '#app/components/Button/AsyncButton'

type Props = {
    bank: BankDto
}

const validateEmailDomain = function (emailDomain: string) {
    if (!emailDomain) return false
    return !!emailDomain.match(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i);
}

export default function BankEmailDomain({bank}: Props) {

    const {t} = useTranslation('pages-mybank')
    const {setBankEmailDomain} = useCommand()
    const confirmationModal = useConfirmationModal()

    const validateFunction = useCallback(({value, isEditing, isSet}) => {
        if (isEditing) return
        if (!isSet) return t('emailDomainMissing')
        if (!validateEmailDomain(value)) return t('emailDomainInvalid')
    }, [t])

    const emailDomainField = useFieldState(bank.emailDomain || '', validateFunction)

    async function submitEmailDomain() {
        if (!emailDomainField.touched || !emailDomainField.validate()) {
            return
        }

        const {waitForCommand} = await setBankEmailDomain(bank.id, emailDomainField.value)
        const success = await waitForCommand();

        if (success) {
            emailDomainField.untouch()
            confirmationModal(
                bank.emailDomain ? t('emailDomainUpdated') : t('emailDomainRegistered'),
                t('emailDomainConfirmation', {emailDomain: emailDomainField.value}),
                t('common.continue')
            )

        }
    }

    async function removeEmailDomain() {
        if (emailDomainField.value) {
            emailDomainField.setValue('')

            const {waitForCommand} = await setBankEmailDomain(bank.id, '')
            const success = await waitForCommand()

            if (success) {
                emailDomainField.untouch()
                confirmationModal(
                    t('emailDomainRemoved'),
                    t('emailDomainRemovedConfirmation'),
                    t('common.continue')
                )
            }
        }
    }

    const emailDomainRegistered = !!bank.emailDomain

    return (
        <Paper id="email-domain" title={t('emailDomainNotificationSettingsHeader')}>
            <p>{t('emailDomainNotificationSettingsInfo')}</p>
            <LabeledInput className={styles.input} label={t('emailDomainLabel')} errorMessage={emailDomainField.errorMessage}>
                <input type={"text"}
                       value={emailDomainField.value}
                       onChange={(event) => emailDomainField.setValue(event.target.value)}
                       onBlur={emailDomainField.onBlur}
                />
                <i className={classNames('ri-close-circle-line', styles.closeIcon)} onClick={removeEmailDomain}/>
            </LabeledInput>
            <AsyncButton onClick={submitEmailDomain} disabled={!emailDomainField.touched || !emailDomainField.valid}>
                {emailDomainRegistered ? t('updateEmailDomainButtonText') : t('registerEmailDomainButtonText')}
            </AsyncButton>
        </Paper>
    )

}
