import { useState } from 'react';
import {useDispatch} from 'react-redux'
import jsonpath from 'jsonpath'
import {useCustomerDeclaration} from '../CustomerDeclarationContext'
import styles from './CheckboxWithLabel.module.scss'
import {setCustomerDeclarationValue} from '#services/thunks/depositor'
import classNames from 'classnames'
import {Checkbox, FormControlLabel} from '@mui/material'
import {CustomerDeclaration} from '@fixrate/fixrate-query'

type Props = {
    id?,
    path,
    label,
    className?: string,
    onChange?: (arg0: boolean) => void,
    textColor?: string | ((id) => string),
    'data-cy'?: string
}

export default function CheckboxWithLabel({
                                              id,
                                              path,
                                              label,
                                              className: propsClassName = '',
                                              onChange: propsOnChange = () => void (0),
                                              textColor = '',
                                              ...props
                                          }: Props) {
    const customerDecleration = useCustomerDeclaration<CustomerDeclaration>()
    const initValue = jsonpath.value(customerDecleration, path)
    const [selected, setSelected] = useState<boolean>(initValue)
    const dispatch = useDispatch()

    function onChange() {
        const newValue = !selected
        dispatch(setCustomerDeclarationValue(customerDecleration.depositorId, path, newValue))
        setSelected(newValue)
        propsOnChange(newValue)
    }

    function color() {
        return ({
            color: (
                textColor && textColor instanceof Function ?
                    textColor(selected) :
                    selected ? '#219653' : 'rgba(0,0,0,.55)'
            ),
        })
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    data-cy={props['data-cy']}
                    name={path}
                    onChange={onChange}
                    checked={selected}
                    data-state={selected ? 'checked' : 'unchecked'}
                />
            }
            label={label}
            className={classNames(propsClassName, styles.checkboxLabel)}
            style={color()}
        />
    );
}
