import { Currency } from '@fixrate/fixrate-query';

export default function usePlatformFeeVirtualValuta() {
    return {
        convertToVirtual,
        convertFromVirtual
    }
}

export function convertToVirtual(currency: Currency, amount: number): number {
    switch (currency) {
        case "NOK":
            return amount;
        case "SEK":
            return amount;
        case "USD":
            return amount * 10;
        case "EUR":
            return amount * 10;
        case "CHF":
            return amount * 10;
        default:
            throw new Error("Unknown currency: " + currency);
    }
}

export function convertFromVirtual(currency: Currency, amount: number): number {
    switch (currency) {
        case "NOK":
            return amount;
        case "SEK":
            return amount;
        case "USD":
            return amount / 10;
        case "EUR":
            return amount / 10;
        case "CHF":
            return amount * 10;
        default:
            throw new Error("Unknown currency: " + currency);
    }
}
