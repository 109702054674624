import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import {Line} from 'react-chartjs-2'
import {dateFormatString, formatBenchmark, formatData, getBanksInSameSegment, labelFormatString} from './chartData'
import {useTranslation} from "#components/i18n";

const DepositCoverageChart = (props) => {
    if (!props.bank) return null

    const { t } = useTranslation('components-Charts')

    const data = formatData(props.bank.bankInfoList, ['depositCoverage'])

    const datasets = [
        {
            label: t('depositCoverage'),
            data: data.map(d => d.depositCoverage),
            fill: false,
            radius: 4,
            borderColor: '#0062ff',
            backgroundColor: '#0062ff',
            spanGaps: true,
        },
    ]

    const banksInSameSegment = getBanksInSameSegment(props.bank, Object.values(props.banks))

    const benchmark = formatBenchmark(banksInSameSegment, data)

    if (benchmark.length > 0) {
        datasets.push({
            label: t('benchmark'),
            data: benchmark.map(b => b.depositCoverage),
            fill: false,
            radius: 4,
            borderColor: 'rgba(0,98,255,0.3)',
            backgroundColor: 'rgba(0,98,255,0.3)',
            spanGaps: true,
        })
    }

    return (
        <Line
            data={{
                labels: data.map(d => format(parse(d.quarter, dateFormatString, new Date()), labelFormatString)),
                datasets,
            }}
            options={{
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        title: {
                            display: false,
                        },
                        ticks: {
                            callback: function(value, index, values) {
                                return value + '%'
                            },
                        },
                    },
                },
            }}
        />
    )
}

DepositCoverageChart.propTypes = {
    bankId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => ({
    bank: state.banks[props.bankId],
    banks: state.banks,
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DepositCoverageChart)