import { OrderDto } from '@fixrate/fixrate-query'
import styles from './ActiveAd.module.scss'
import { useState } from 'react';

export const OrderList = ({orders} : {orders: OrderDto[]}) => {
    const [visibleOrders, setVisibleOrders] = useState(orders.slice(0, 5))
    const [currentPage, setCurrentPage] = useState(1)
    const needsPagination = orders.length / 5 > 1;
    const numberOfPages = Math.ceil(orders.length / 5)
    const changePage = (page: number) => {
        const start = (page - 1) * 5
        const end = start + 5
        setVisibleOrders(orders.slice(start, end))
        setCurrentPage(page)
    }
    return (
        <div>
                <ul className={styles.keyValueList}>
                    { visibleOrders.map(order => (
                        <li key={order.id}>
                            <span className={styles.key}>{order.depositor.name}</span>
                            <span className={styles.value}>{order.volume} mill.</span>
                        </li>
                    ))}
                </ul>
            {needsPagination && (
                <ul className={styles.pagination}>
                    { Array(numberOfPages).fill(0).map((_, i) => (
                        <li key={i} className={(currentPage === i + 1) ? styles.active : null} onClick={() => changePage(i + 1)}>{i + 1}</li>
                    ))}
                </ul>
            )}
        </div>
    )
}
