import { Fragment } from 'react';
import config from '../../config'
import styles from './SignableDocumentList.module.scss'
import DocumentLink from '../DocumentLink'
import {useTranslation} from 'react-i18next'
import {DocumentDto} from '@fixrate/fixrate-query'
import {useSelector} from '#state/useSelector'
import {SignableDocument} from './SignableDocument'
import {PURPLE} from "#app/colors/colors";
import {Box, Divider, Paper, Stack} from "@mui/material";
import AsyncButton from '../Button/AsyncButton';

const API_BASE_URL = config().apiUrl

type Props = {
    documents: DocumentDto[]
    onStartSignature: (documents: DocumentDto[]) => void
    showSigningError?: boolean
    className?: string
}

export function SignableDocumentList({documents, onStartSignature, showSigningError, className}: Props) {
    const {t} = useTranslation()
    const signatureStatusIsLoading = useSelector(state => state.signatureStatusIsLoading)

    const userCanSignAll = documents.every(d => d.userCanSign)

    if (userCanSignAll && documents.length >= 2) {
        return (
            <Stack spacing={2}>
                <Stack sx={{borderRadius: 3}}>
                    <Paper sx={{backgroundColor: PURPLE[50] + "50"}}>
                        {documents.map((document, index) => {
                            const link = document.signedByAny ? document.signedLink : document.unsignedLink
                            const documentLink = API_BASE_URL + link
                            const last = index === documents.length - 1

                            return (
                                <>
                                    <Stack pl={1} direction="row" key={document.id}>
                                        <DocumentLink link={documentLink} name={document.name}/>
                                        <SignatureStatus document={document}/>
                                    </Stack>
                                    { !last && <Divider/> }
                                </>
                            )

                        })}
                    </Paper>
                </Stack>
                <Box>
                    {documents.every(d => d.userCanSign) && (
                        <AsyncButton
                            id="signDocumentButton"
                            data-cy="signDocumentButton"
                            onClick={() => onStartSignature(documents)}
                        >
                            {documents.length === 2 ? t('components-SignableDocument.signBoth') : t('components-SignableDocument.signAll')}
                        </AsyncButton>
                    )}
                </Box>
            </Stack>
        )
    } else {
        return (
            <Fragment>
                {documents.map(d => (
                    <SignableDocument
                        key={d.id}
                        document={d}
                        hideRemoveButton={true}
                        onStartSignature={() => onStartSignature([d])}
                        isCheckingSignatureStatus={signatureStatusIsLoading[d.id]}
                        showSigningError={showSigningError}
                    />
                ))}
            </Fragment>
        );
    }
}

function SignatureStatus({document}: { document: DocumentDto }) {
    const {t} = useTranslation()
    const signatureStatusIsLoading = useSelector(state => state.signatureStatusIsLoading)

    if (signatureStatusIsLoading[document.id]) {
        return (
            <p data-cy="signedStatus" className="loading">{t('components-SignableDocument.checkingStatus')}</p>
        )
    } else if (document.allSignatures.length > 0 && document.signedByAll) {
        return (
            <p data-cy="signedStamp" className={styles.signedStamp}>
                <i className="ri-check-line"></i>
                <span>{t('components-SignableDocument.signed')}</span>
            </p>
        )
    } else if (document.allSignatures.length > 0 && document.signedByUser) {
        return (
            <p data-cy="signedStamp" className={styles.signedStamp}>
                <i className="ri-check-line"></i>
                <span>{t('components-SignableDocument.signedByYou')}</span>
            </p>
        )
    } else {
        return (
            <p>&nbsp;</p>
        )
    }
}

export function useSignableDocumentListInstruction(documents: DocumentDto[]) {

    const {t} = useTranslation()

    const persons: string[] = []
    const roles: string[] = []

    // Add each unique person and role
    documents
        .filter(document => !!document)
        .forEach(document => {
            document.missingSignatures.forEach(missingSignature => {
                if (missingSignature.signerName && !persons.includes(missingSignature.signerName)) {
                    persons.push(missingSignature.signerName)
                }
                if (missingSignature.requestedRole && !roles.includes(missingSignature.requestedRole)) {
                    roles.push(missingSignature.requestedRole)
                }
            })
        })

    if (persons.length === 0 && roles.length === 0) {
        return null
    }

    const identifiers = persons.concat(roles.map(role => 'en ' + t(`enum-SecurityRole.${role}`).toLowerCase()))

    if (identifiers.length === 1) {
        return t('components-SignableDocument.mustBeSignedBySingleIdentifier', {identifier: identifiers[0]})
    }

    if (identifiers.length > 1) {
        return t('components-SignableDocument.mustBeSignedByList', {identifiers: identifiers.slice(0, 1).join(', '), lastIdentifier: identifiers[identifiers.length - 1]})
    }

}



