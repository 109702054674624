import { Media, Webinar } from "#app/services/payloadCMS/payload-types"
import { Box, Link, Typography } from "@mui/material"

export type RichTextChild = {
    detail: number,
    format: number,
    mode: string,
    style: string,
    text: string,
    type: string,
    version: number,
    direction?: string,
    indent?: number,
    fields?: {
        url: string,
        newTab: boolean,
        linkType: "custom" | "internal",
        doc?: {
            value: Webinar
        }
    },
    children?: RichTextChild[]
}
export type RichTextContent = {
    children: RichTextChild[],
    direction: string,
    format: string,
    indent: number,
    type: string,
    version: number,
    tag?: string,
    value?: Media,
    listType?: "bullet" | "number"
}

type TypographyFormat = {
    [key: number]: {
        fontFamily?: string,
        fontWeight?: "inherit" | "medium" | "bold",
        fontStyle?: "inherit" | "italic" | "normal",
        textDecoration?: "inherit" | "line-through" | "underline" | "none"
    }
}

const typographyFormatMap: TypographyFormat = {
    0: {
        fontWeight: "inherit",
        fontStyle: "inherit",
        textDecoration: "inherit",
    },
    1: {
        fontWeight: "bold",
    },
    2: {
        fontStyle: "italic",
    },
    3: {
        fontWeight: "bold",
        fontStyle: "italic",
    },
    4: {
        textDecoration: "line-through",
    },
    8: {
        textDecoration: "underline",
    },
    9: {
        fontStyle: "italic",
        textDecoration: "underline",
    },
    11: {
        fontWeight: "bold",
        fontStyle: "italic",
        textDecoration: "underline",
    },
    16: {
        fontFamily: "monospace",
    }
}

const getTypographyTag = (tag: string) => {
    switch (tag) {
        case "h1":
            return "h1"
        case "h2":
            return "h2"
        case "h3":
            return "h3"
        case "h4":
            return "h4"
        case "h5":
            return "h5"
        case "h6":
            return "h6"
        default:
            return "body1"
    }
}

const LinkChild = ({child} : {child: RichTextChild}) => {
    const { url, newTab, linkType, doc } = child?.fields || {}
    if (linkType === "custom") {
        return (
            <Link 
                href={url}
                target={newTab ? "_blank" : "_self"}
                underline="hover">
                { child?.children?.map(child => <TypographyWithStyles key={child?.text} textChild={child}/>) }
            </Link>
        )
    }

    return null
}

const TypographyWithStyles = ({textChild} : {textChild: RichTextChild | null}) => {
    if (textChild?.type === "link" && textChild?.fields) {
        return <LinkChild child={textChild} />
    }
    return (
        <Typography 
            component="span"
            fontWeight={
                typographyFormatMap[textChild?.format || 0]?.fontWeight
            }
            fontStyle={
                typographyFormatMap[textChild?.format || 0]?.fontStyle
            }
            sx={{
                textDecoration: typographyFormatMap[textChild?.format || 0]?.textDecoration
            }}
            fontFamily={
                typographyFormatMap[textChild?.format || 0]?.fontFamily
                }>
            {textChild?.text} 
        </Typography>
    )
}

const RichTextRenderer = ({content} : {content: RichTextContent}) => {
    const type = content?.type
    const tag = content?.tag
    const children = content?.children

    if (type === "heading") {
        return <Typography variant={getTypographyTag(tag || "")}>{children?.map(t => t?.text)}</Typography>
    }

    if (type === "paragraph") {
        return (
            <Typography variant="body1" fontWeight={400}>
                { children?.map((t, i) => (
                    <TypographyWithStyles key={i} textChild={t} />
                ))}
            </Typography>
        )
    }

    if (type === "upload") {
        const imageUrl = content?.value?.sizes?.tablet?.url || content?.value?.url
        return imageUrl ? (
            <Box>
                <img 
                    style={{borderRadius: "1rem", marginTop: "1rem", marginBottom: "1rem"}} 
                    src={imageUrl}
                    loading="lazy"
                    alt="" />
            </Box>
        ) : null
    }

    if (type === "list") {
        const listType = content?.listType === "number" ? "ol" : "ul"
        return (
            <>
                <Box component={listType} mb={0} sx={{listStyle: listType === "ol" ? "decimal" : "disc", pl: "1.5rem"}}>
                    {children?.map((child, index) => (
                        <Typography mb={1} component="li" key={index}>
                            { child?.children?.map((t, index) => {
                                return (
                                    <Typography 
                                        component="span"
                                        key={t?.text + index}
                                        fontWeight={
                                            typographyFormatMap[t?.format || 0]?.fontWeight
                                        }
                                        fontStyle={
                                            typographyFormatMap[t?.format || 0]?.fontStyle
                                        }
                                        sx={{
                                            textDecoration: typographyFormatMap[t?.format || 0]?.textDecoration
                                        }}
                                        fontFamily={
                                            typographyFormatMap[t?.format || 0]?.fontFamily
                                            }>
                                        {t?.text}
                                    </Typography>
                                )
                            })}
                        </Typography>
                    ))}
                </Box>
            </>
        )
    }
    return null
}

export { RichTextRenderer }