import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import {Line} from 'react-chartjs-2'
import {dateFormatString, formatBenchmark, formatData, getBanksInSameSegment, labelFormatString} from './chartData'
import {useTranslation} from "#components/i18n";


const TotalAssetsChart = (props) => {
    if (!props.bank) return null

    const {t} = useTranslation('components-Charts')

    const data = formatData(props.bank.bankInfoList, ['totalAssets', 'totalAssetsIncludingMortgageCredit'])

    const datasets = [
        {
            label: t('totalAssets'),
            data: data.map(d => d.totalAssets),
            radius: 4,
            fill: false,
            borderColor: '#00894B',
            backgroundColor: '#00894B',
            spanGaps: true,
        },
        {
            label: t('totalAssetsIncludingMortgageCredit'),
            data: data.map(d => d.totalAssetsIncludingMortgageCredit),
            radius: 4,
            fill: false,
            borderColor: '#efde4a',
            backgroundColor: '#efde4a',
            spanGaps: true,
        },
    ]

    const banksInSameSegment = getBanksInSameSegment(props.bank, Object.values(props.banks))

    const benchmark = formatBenchmark(banksInSameSegment, data)

    if (benchmark.length > 0) {
        datasets.push(...[
            {
                label: t('benchmarkTAExclMC'),
                data: benchmark.map(b => b.totalAssets),
                fill: false,
                radius: 4,
                borderColor: 'rgba(0,137,75,0.3)',
                backgroundColor: 'rgba(0,137,75,0.3)',
                spanGaps: true,
            },
            {
                label: t('benchmarkTAInclMC'),
                data: benchmark.map(b => b.totalAssetsIncludingMortgageCredit),
                fill: false,
                radius: 4,
                borderColor: 'rgba(239,222,74,0.3)',
                backgroundColor: 'rgba(239,222,74,0.3)',
                spanGaps: true,
            },
        ])
    }

    return (
        <Line
            data={{
                labels: data.map(d => format(parse(d.quarter, dateFormatString, new Date()), labelFormatString)),
                datasets,
            }}
            options={{
                scales: {
                    y: {
                        suggestedMin: 0,
                        title: {
                            display: true,
                            text: t('labelMillionNOK'),
                        }
                    },
                },
            }}
        />
    )
}

TotalAssetsChart.propTypes = {
    bankId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => ({
    bank: state.banks[props.bankId],
    banks: state.banks,
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TotalAssetsChart)