import {MenuItem, Select} from "@mui/material";

type Props = {
    id?: string
    value: number,
    onChange: (value: unknown) => void,
    rangeStart?: number,
    rangeEnd?: number
}

export function YearSelect({id, value, onChange, rangeStart, rangeEnd}: Props) {
    const start = rangeStart || 1970
    const end = rangeEnd || new Date().getFullYear() + 10
    const range = Array.from({length: end - start}, (_, i) => start + i)
    return (
        <Select
            sx={{maxWidth: '12rem', borderTopRightRadius: '0', borderBottomRightRadius: '0'}}
            id={id}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            {range.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
        </Select>
    )
}
