import AdCard from "#app/pages/marketplace/AdCard/AdCard"
import { useSelector } from "#app/state/useSelector"
import { HelpIcon } from '#components'
import { Alert, Box, Stack, TextareaAutosize, Tooltip, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import AsyncButton from "../Button/AsyncButton"
import FxDialog from "../FxDialog/FxDialog"
import FxDialogContent from "../FxDialog/FxDialogContent"
import AIIcon from "./AIIcon"

const AdCardChat = ({url, open, close, setResult} : {url: string, open: boolean, close: () => void, setResult: (result: string[]) => void}) => {
    const navigate = useNavigate()
    const [ads, products] = useSelector(state => [
        state.ads,
        state.products,
    ])
    const [adQuery, setAdQuery] = useState("Annonser fra en bank hvor jeg ikke allerede har plassert penger")
    const [adResponse, setAdResponse] = useState<null | string[]>(null)
    const [adQueryFailed, setAdQueryFailed] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const [loading, setLoading] = useState(false)

    const queryAI = async () => {
        setAdResponse(null)
        setAdQueryFailed(false)
        setLoading(true)
        setShowResults(false)

        await fetch("/api/search/freetext", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(adQuery), // body data type must match "Content-Type" header
        })
        .then(res => res.json())
        .then(data => {
            if (Array.isArray(data)) {
                setAdResponse(data)
                setResult(data)
                close()
                //setShowResults(true)
            } else {
                setAdResponse(null)
                setAdQueryFailed(true)
            }
        })
        .catch(err => {
            console.log(err)
            setAdResponse(null)
            setAdQueryFailed(true)
        })
        .finally(() => {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }
    return (
        <FxDialog open={open} onClose={close} maxWidth={'lg'}>
            <FxDialogContent sx={{px: 0, pt: 3, width: showResults ? '80rem' : '45rem', transition: '1s', position: 'relative'}}>
                <HelpIcon supportArticleId={''} sx={{position: 'absolute', top: '2rem', right: '2rem'}}/>
                <Stack gap={1} alignItems={"center"}>
                    <AIIcon loading={loading} />
                    <Stack alignItems={"center"} sx={{position: 'relative'}}>
                        <Typography variant="h2">Fixrate Genie</Typography>
                        <Typography>Finn tilbudene som passer til deg</Typography>
                        <Tooltip title="Denne funksjonen er i beta. Vi kan ikke garantere at utvalget av annonser er akkurat det du ber om.">
                                <span style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    fontSize: '0.7em',
                                    background: 'orange',
                                    color: 'white',
                                    borderRadius: '4px',
                                    padding: '2px 6px',
                                    cursor: 'help',
                                }}>
                                    Beta
                                </span>
                        </Tooltip>
                    </Stack>
                    { (showResults && !loading) ? (
                        <>
                            <Stack sx={{width: '100%', backgroundColor: '#f2f2f2'}} p={3} mt={2} justifyContent={'center'} direction={"row"} gap={2} flexWrap={'wrap'}>
                                {adResponse.length > 0 ? adResponse.map((ad, index) => {
                                    const currentAd = ads.find(a => a.id === ad)
                                    if (currentAd) {
                                        return (
                                            <AdCard key={index} product={products[currentAd.productId]} ad={currentAd} onAdClick={() => navigate(`${url}/${currentAd.id}#!`)}  />
                                        )
                                    }
                                    return null
                                }) : (
                                    <Typography variant={"h3"} fontWeight={'400'}>Vi fant dessverre ingen tilbud basert på ditt søk</Typography>
                                )}
                            </Stack>
                            <Box my={2} textAlign={"center"}>
                                <AsyncButton onClick={() => setShowResults(false)}>Søk på nytt</AsyncButton>
                            </Box>
                        </>
                    ) : (
                        <form onSubmit={queryAI}>
                            <Stack gap={2} px={4} mb={2}>
                                <TextareaAutosize style={{minHeight: "20rem"}} value={adQuery} onChange={(e) => setAdQuery(e.target.value)} />
                                <AsyncButton onClick={queryAI}>Finn tilbud</AsyncButton>
                            </Stack>
                            { adQueryFailed && <Alert sx={{my: 2}} severity="error">Noe gikk feil, vennligst prøv på nytt</Alert> }
                        </form>
                    )}
                </Stack>
            </FxDialogContent>
        </FxDialog>
    )
}

export default AdCardChat
