import { Children, cloneElement } from 'react';
import TableHeaderCell from './TableHeaderCell'

/**
 * @param {object} props
 * @param props.children
 * @param [props.sort]
 * @return {JSX.Element}
 */
export default function TableHeader({children = null, sort = null, ...props}) {
    const cells = []
    Children.forEach(children, child => {
        if (child?.type === TableHeaderCell || child?.props?.isTableHeaderCell) {
            let item = cloneElement(child, {sort})
            cells.push(item)
        }
    })
    return (
        <tr {...props}>
            {Children.toArray(cells)}
        </tr>
    )
}