import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { Animated } from '#app/components'
import AdCardChat from '#app/components/AIChat/AdCardChat'
import AdCard from '#app/pages/marketplace/AdCard/AdCard'
import MinMaxSlider from '#app/pages/marketplace/MarketplaceFilter/MinMaxSlider'
import { useSortedAds } from '#app/services/useSortedAds'
import UserFilterSelector from '#blocks/UserFilterSelector/UserFilterSelector'
import AIIcon from '#components/AIChat/AIIcon'
import { useLocalStorage } from '#services/LocalStorageContext'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { AdDto } from '@fixrate/fixrate-query'
import { Alert, AlertTitle, Box, Button, Hidden, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AdCardDetails from './AdCardDetails/AdCardDetails'
import MarketplaceFilter from './MarketplaceFilter/MarketplaceFilter'

type Params = {
    adId?: string
}

type Props = {
    url: string
    depositMarketPlaceType: DepositMarketPlaceType
}

export type DepositMarketPlaceType = 'LOW' | 'HIGH' | 'FREE' | 'SINGLE_AD'

export default function DepositMarketplace({url, depositMarketPlaceType}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const isFreetextSearch = depositMarketPlaceType === 'FREE'
    const isOver20 = depositMarketPlaceType === 'HIGH'
    const isUnder20 = depositMarketPlaceType === 'LOW'
    const freetextSearchEnabled = useSelector(state => state.session.betaPrograms['FREETEXT_SEARCH'])
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const activeMarketplaceFilter = useSelector(state => state.marketplacefilter?.activeFilter)
    const hasActiveFilter = Object.values(activeMarketplaceFilter?.products).some(b => b === false)

    const {adId} = useParams<Params>()

    const [isAdCardChatOpen, setIsAdCardChatOpen] = useState(isFreetextSearch)
    const [freetextSearchResult, setFreetextSearchResult] = useState<null | string[]>(null)

    const adsOver20 = useSortedAds('OVER_20')
    const adsUnder20 = useSortedAds('UNDER_20')

    const selectedAds = isOver20 ? adsOver20 : adsUnder20

    const {
        keys: { minMaxVolumeFilter },
        dispatchLocalStorage,
    } = useLocalStorage()

    const [minFilter, maxFilter] = minMaxVolumeFilter

    const [session, ads, adsIsLoaded, hasViewRole, depositor, marketplaceFilter, banks, products] = useSelector(state => {
        return [
            state.session,
            state.ads,
            state.loaded.ads,
            selectors.hasViewDepositorRole(state),
            state.depositor,
            state.marketplacefilter.activeFilter,
            state.banks,
            state.products,
        ]
    })

    const partnerId = session?.associations?.find(asso => asso.organisationType === "PARTNER")?.organisation?.id
    const partnerProposals = depositor?.partnerRelations?.find(relation => relation.partnerId === partnerId)?.partnerProposals
    const acceptedPartnerProposals = partnerProposals?.filter(proposal => ads?.find(ad => ad.id === proposal?.adId))?.filter(proposal => proposal?.status === 'ACCEPTED')

    const hasAcceptedPartnerProposals = acceptedPartnerProposals?.length > 0

    const getFilteredAds = (): Array<AdDto> => {
        if (isOver20) {
            return selectedAds
                .filter(ad => Object.keys(marketplaceFilter.products).length === 0 || marketplaceFilter.products[ad.productId] === true)
                .filter(ad => marketplaceFilter.minDepositAmount === null || marketplaceFilter.minDepositAmount <= getMaxVolumeLimit(ad))
                .filter(ad => marketplaceFilter.totalAssets === null || (banks[ad.bankId]?.totalAssets ?? 0) >= marketplaceFilter.totalAssets)
        }

        if (isUnder20) {
            return selectedAds
                .filter(ad => minFilter <= getMaxVolumeLimit(ad) && (!maxFilter || maxFilter >= ad.min))
        }

        if (isFreetextSearch) {
            return selectedAds
                .filter(ad => freetextSearchResult?.includes(ad.id))
        }

        return []
    }

    const filteredAds = getFilteredAds()

    const isDepositorAndHasOrganisation = () => {
        const hasDepositorAssociation = session?.associations.some(a => a.organisation?.type === 'DEPOSITOR')
        const isDepositorAndHasOrganisation = session.organisationType === 'DEPOSITOR' && hasDepositorAssociation
        const isDepositor = session.organisationType === 'DEPOSITOR'

        return !isDepositor || isDepositorAndHasOrganisation
    }

    return (
        <Fragment>
            <AdCardChat
                url={url}
                open={!!(freetextSearchEnabled && isAdCardChatOpen)}
                close={() => setIsAdCardChatOpen(false)}
                setResult={setFreetextSearchResult}/>
            <Stack maxWidth={"147.3rem"} spacing={{xs: 2, md: 4}} px={{xs: 2, md: 0}}>
                <Stack spacing={2} direction={{xs: 'column', lg: 'row'}} justifyContent={'space-between'} alignItems={{lg: 'flex-end'}}>
                    <Box>
                        <ToggleButtonGroup value={isUnder20 ? '/marketplace/category/deposits/under-20' : isOver20 ? '/marketplace/category/deposits/over-20' : '/marketplace/category/deposits/freetext-search'} size={'small'} color={'primary'}>
                            <ToggleButton sx={{px: 2}} value={'/marketplace/category/deposits/under-20'} onClick={(_, url) => navigate(url)}>
                                { isMobile ? t('pages-marketplace.under20Million') : t('pages-marketplace.OffersUnder20Million') }
                            </ToggleButton>
                            <ToggleButton sx={{px: 2}} value={'/marketplace/category/deposits/over-20'} onClick={(_, url) => navigate(url)}>
                                { isMobile ? t('pages-marketplace.over20Million') : t('pages-marketplace.OffersOver20Million') }
                            </ToggleButton>
                            {freetextSearchEnabled &&
                            <ToggleButton sx={{px: 2}} value={'/marketplace/category/deposits/freetext-search'} onClick={(_, url) => {navigate(url); setIsAdCardChatOpen(true)}}>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <AIIcon loading={true} height={30}/><span>{t('pages-marketplace.freetextSearch')}</span>
                                </Stack>
                            </ToggleButton>
                            }
                        </ToggleButtonGroup>
                    </Box>
                    <Stack spacing={2} direction={{xs: 'column', md: 'row'}}>
                        {isUnder20 && (
                            <Stack mb={{ xs: 2, md: 0 }}>
                                <Typography variant={'filterLabel'}>
                                    <i className={'ri-filter-line'}/>
                                    <span>{t('pages-marketplace.minMaxFilterLabel')}</span>
                                </Typography>
                                <MinMaxSlider minMaxFilter={[minFilter, maxFilter]} setMinMaxFilter={value => dispatchLocalStorage({type: 'MIN_MAX_VOLUME_FILTER', payload: value})}/>
                            </Stack>
                        )}
                        {isOver20 && (
                            (!hasActiveFilter && isMobile) ? null : (
                                <Stack>
                                    <Typography variant={'filterLabel'}>
                                        <i className={'ri-filter-line'}/>
                                        <span>{t('pages-marketplace.filter')}</span>
                                    </Typography>
                                    <MarketplaceFilter/>
                                </Stack>
                            )
                        )}
                        <Hidden mdDown>
                            <Stack>
                                <Typography variant={'filterLabel'}>
                                    <i className="ri-mail-line"/>
                                    <span>{t('pages-marketplace.emailNotificationNewAds')}</span>
                                </Typography>
                                <UserFilterSelector/>
                            </Stack>
                        </Hidden>
                    </Stack>
                </Stack>
                {hasViewRole && depositor?.customerDeclarationExpired && (
                    <Alert
                        severity="warning"
                        elevation={1}
                        sx={{maxWidth: '80rem'}}
                        action={<Button variant={'outlined'} color={'inherit'} size="small" href={`/organizations/${depositor.id}/customerdecl`}>{t('pages-marketplace.doItNow')}</Button>}
                    >
                        {t('pages-marketplace.customerDeclarationMustBeUpdatedWarning', {depositorName: depositor.name})}
                    </Alert>
                )}
                {filteredAds.length === 0 && adsIsLoaded && (
                    <Alert severity="warning" sx={{maxWidth: '80rem'}} elevation={1}>
                        {isOver20 && t('pages-marketplace.noMatchingSearch')}
                        {isUnder20 && (
                            minFilter === maxFilter
                                ? t('pages-marketplace.noMatchingFilterDepositVolume', {volume: `${minFilter} ${minFilter === 1 ? t('common.million') : t('common.million_other')}.`})
                                : t('pages-marketplace.noMatchingFilterDepositRange', {min: minFilter, max: maxFilter})
                        )}
                        {isFreetextSearch && t('pages-marketplace.noMatchingFreetextSearch')}
                    </Alert>
                )}
                <Stack component="ul" direction={'row'} flexWrap={'wrap'} spacing={2}>
                    <AnimatePresence>
                        {filteredAds.map(ad => (
                            <Animated key={ad.id} data-fx={ad.id}>
                                <AdCard
                                    ad={ad}
                                    product={products[ad.productId]}
                                    partnerProposalAd={!!acceptedPartnerProposals?.find(partnerProposal => partnerProposal?.ad?.id === ad.id)}
                                    onAdClick={() => navigate(location.pathname + '/' + ad.id)}
                                />
                            </Animated>
                        ))}
                    </AnimatePresence>
                </Stack>
            </Stack>
            {filteredAds.length > 0 && (
                <AdCardDetails
                    open={!!adId}
                    key={adId}
                    adId={adId}
                    backUrl={location.pathname.replace("/" + adId, "")}
                    isDepositorAndHasOrganisation={isDepositorAndHasOrganisation()}
                />
            )}
            { hasAcceptedPartnerProposals && (
                <Alert
                    sx={{
                        position: "fixed",
                        alignItems: {xs: "flex-start", md: "center"},
                        left: {md: "25rem"},
                        bottom: {xs: "5.5rem", sm: "0"},
                        width: {md: "calc(100% - 25rem)"},
                        color: PURPLE[50],
                        backgroundColor: PURPLE[900],
                        py: 2,
                        pr: 12,
                        pl: {xs: 2, md: 6},
                        flexDirection: {
                            xs: "column",
                            md: "row"
                        },
                        "& i": {
                            color: SPRING_GREEN[500]
                        },
                        "& .MuiAlert-action": {
                            marginLeft: {
                                xs: "0",
                                md: "auto"
                            },
                            pl: {
                                xs: "0",
                                md: 2
                            }
                        }
                    }}
                    action={
                        <Button
                            color='inherit'
                            variant="outlined"
                            size="small"
                            href={`/marketplace/category/deposits/${acceptedPartnerProposals[0]?.ad?.min >= 20 ? "over-20" : "under-20"}/${acceptedPartnerProposals[0]?.ad?.id}`}>
                            {t('pages-marketplace.goToOrder')}
                        </Button>
                    }
                    icon={<i className="ri-alarm-warning-line" />}
                    severity="info">
                    <AlertTitle>{t('pages-marketplace.partnerAccountReadyForOrder')}</AlertTitle>
                    { t('pages-marketplace.partnerAccountReadyForOrderMessage',
                        {
                            bankName: banks[acceptedPartnerProposals[0]?.ad?.bankId]?.name,
                            signatoryName: acceptedPartnerProposals[0]?.receiverPerson?.firstName + " " + acceptedPartnerProposals[0]?.receiverPerson?.lastName
                        })
                    }
                </Alert>
            )}
        </Fragment>
    );
}

export function getMaxVolumeLimit(ad: Pick<AdDto, 'remaining' | 'min' | 'max'>): number {
    if (ad.remaining < ad.min) {
        return ad.max
    }

    return Math.min(ad.max, ad.remaining)
}

