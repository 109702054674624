import {Fragment, useState} from 'react'
import config from '../../config'
import SigningAction from './SigningAction'
import RemoveDocumentConfirmation from './RemoveDocumentConfirmation'
import DocumentLink from '../DocumentLink'
import {DocumentDto} from '@fixrate/fixrate-query'
import {Alert, Stack} from '@mui/material'
import {useSignableDocumentListInstruction} from './SignableDocumentList'

const API_BASE_URL = config().apiUrl

type Props = {
    isCheckingSignatureStatus: boolean
    document: DocumentDto
    linkText?: string
    onStartSignature?: () => void
    showSigningError?: boolean
    hideRemoveButton?: boolean
    className?: string
    onRemoveDocument?: () => void
    signingErrorText?: string
}

export function SignableDocument({
                                             isCheckingSignatureStatus,
                                             document,
                                             linkText,
                                             onStartSignature,
                                             showSigningError,
                                             hideRemoveButton,
                                             className,
                                             onRemoveDocument,
                                             signingErrorText,
                                         }: Props) {
    const [showRemoveDocumentConfirmation, setShowRemoveDocumentConfirmation] = useState(false)
    const signableDocumentInstruction = useSignableDocumentListInstruction([document])

    function onRemoveDocumentStart() {
        setShowRemoveDocumentConfirmation(true)
    }

    function onRemovedDocumentAbort() {
        setShowRemoveDocumentConfirmation(false)
    }

    async function onRemoveDocumentConfirmed() {
        await onRemoveDocument()
        setShowRemoveDocumentConfirmation(false)
    }

    const link = document.signedByAny ? document.signedLink : document.unsignedLink
    const documentLink = API_BASE_URL + link
    const showRemoveDocumentLink = !hideRemoveButton && !!onRemoveDocument


    return (
        <Fragment>
            <Stack id={document.id}>
                <Stack sx={{
                    borderTop: "0.1rem solid rgba(0,0,0,0.1)",
                    borderBottom: "0.1rem solid rgba(0,0,0,0.1)",
                    py: 1,
                }} direction={{sm: 'row'}} alignItems={{sm: 'center'}} justifyContent={'space-between'}>
                    <DocumentLink link={documentLink} name={linkText || document.name} onRemove={showRemoveDocumentLink && onRemoveDocumentStart}/>
                    {onStartSignature && <SigningAction
                        isCheckingSignatureStatus={isCheckingSignatureStatus}
                        document={document}
                        onStartSignature={onStartSignature}
                    />
                    }
                </Stack>
                {showSigningError && <Alert sx={{mt: 2}} severity='error'>{signingErrorText || signableDocumentInstruction}</Alert>}
            </Stack>
            {showRemoveDocumentConfirmation && (
                <RemoveDocumentConfirmation
                    onConfirm={onRemoveDocumentConfirmed}
                    onCancel={onRemovedDocumentAbort}
                />
            )}
        </Fragment>
    );
}



