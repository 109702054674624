import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import EmptyState from '#app/components/EmptyState/EmptyState'
import PartnerCalculator from '#app/pages/Onboarding/InterestCalculator/PartnerCalculator'
import { InterestOutput, LoadingSpinner } from '#components'
import PageHeader, { getIconText, getOrganisationSelectorOptions } from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout'
import CustomerInvite from '#pages/Customers/CustomerOverview/CustomerInvite'
import CustomerList from '#pages/Customers/CustomerOverview/CustomerList'
import { useSelector } from '#state/useSelector'
import { Clear } from '@mui/icons-material'
import { Avatar, Button, IconButton, InputAdornment, Paper, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import RegisterCustomer from '../RegisterCustomer/RegisterCustomer'
import styles from './CustomerOverview.module.scss'

export default function CustomerOverview({openRegistration = false}: { openRegistration?: boolean }) {
    const {t} = useTranslation()

    const [showInvite, setShowInvite] = useState(openRegistration)
    const [customerSearchQuery, setCustomerSearchQuery] = useState('')
    const [showCalculator, setShowCalculator] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const Currency = useCurrencyOutput()
    const partner = useSelector(state => state.partner)
    const allCustomers = partner?.customers ?? []
    const isAccountant = partner?.partnerCategory === 'ACCOUNTANT'
    const associations = useSelector(state => state.session?.associations)
    const association = useSelector(state => state.session?.association)
    const firstName = useSelector(state => state.session?.firstName)
    const orgOptions = getOrganisationSelectorOptions(associations, association)
    const isAdmin = associations.find(assoc => assoc.organisation?.id === partner?.id)?.roles.includes('PARTNER_ADMIN')

    const filteredCustomers = allCustomers.filter(customer => !customerSearchQuery || sanitizeString(`${customer.name}${customer.nationalIdentity}`).includes(sanitizeString(customerSearchQuery))) ?? []
    const hasCustomers = allCustomers.length > 0

    const totalPortfolioBalance = allCustomers.reduce((acc, customer) => acc + customer.calculatedBalance || 0, 0)
    const customersWithInterestRate = allCustomers.filter(cust => cust.interestRate)
    const averagePortfolioInterestRate = customersWithInterestRate.reduce((acc, customer) => acc + customer.interestRate, 0) / customersWithInterestRate.length

    const hideInvite = () => {
        setShowInvite(false)
        navigate('/customer')
    }

    useEffect(() => {
        if (location.pathname === '/customer/register') {
            setShowInvite(true)
        } else {
            setShowInvite(false)
        }
    }, [location.pathname])

    return (
        <>
            <PageHeader icon="ri-briefcase-line" title={t('pages-customers.customersPageHeading')}/>
            <PageLayout>
                {!isAccountant && <CustomerInvite visible={showInvite} hide={hideInvite}/>}
                {isAccountant && <RegisterCustomer visible={showInvite} hide={hideInvite}/>}
                <PartnerCalculator showCalculator={showCalculator} setShowCalculator={setShowCalculator}/>
                <Stack spacing={{xs: 2, md: 3}}>
                    <Stack sx={{px: {xs: 2, md: 3}}} direction="row" spacing={2} alignItems="center">
                        <Avatar sx={{width: '8rem', height: '8rem', fontSize: '2.6rem', backgroundColor: orgOptions.find(org => org.organisationId === partner?.id)?.iconColor}}>
                            {getIconText(partner?.name)}
                        </Avatar>
                        <Stack spacing={0.5}>
                            <h2>{t('common.hi')} {firstName}!</h2>
                            <p>{t('pages-customers.yourCustomersDescription')}</p>
                        </Stack>
                    </Stack>
                    <Stack sx={{mb: 3}} direction={{xs: 'column', md: 'row'}} alignItems={{md: 'flex-start'}} spacing={2}>
                        <Paper sx={{py: 3, px: 3}}>
                            <p className={styles.portfolioLabel}>{t('pages-customers.totalVolume')}</p>
                            <p className={styles.portfolioValue}>{Currency(totalPortfolioBalance)}</p>
                        </Paper>
                        <Paper sx={{py: 3, px: 3}}>
                            <p className={styles.portfolioLabel}>{t('pages-customers.averageInterestRate')}</p>
                            <p className={styles.portfolioValue}>{InterestOutput.format(averagePortfolioInterestRate || 0)}</p>
                        </Paper>
                    </Stack>
                    {hasCustomers ? (
                        <Paper sx={{p: 3}}>
                            <Stack justifyContent={'space-between'} gap={2} direction={{md: 'row'}} sx={{mb: 1, mt: 0}}>
                                <Stack direction={{md: 'row'}} alignItems={'flex-start'} spacing={{xs: 0, md: 2}}>
                                    <Button startIcon={<i className="ri-add-line"/>} variant={'contained'} sx={{marginBottom: '2rem'}} data-cy="inviteButton" onClick={() => navigate('/customer/register')}>
                                        {isAccountant ? t('pages-customers.registerNewCustomer') : t('pages-customers.inviteNewCustomer')}
                                    </Button>
                                    <Button startIcon={<i className="ri-calculator-line"/>} fullWidth={false} variant='outlined' onClick={() => setShowCalculator(true)}>{t('pages-customer.calculateInterestGain')}</Button>
                                </Stack>
                                <TextField
                                    placeholder={t('pages-customers.customerSearch')}
                                    value={customerSearchQuery}
                                    onChange={(event) => setCustomerSearchQuery(event.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end" sx={{
                                                opacity: customerSearchQuery.length === 0 ? 0 : 1,
                                                transformOrigin: 'center',
                                                transform: customerSearchQuery.length === 0 ? 'scale(0)' : 'scale(1)',
                                                transition: '0.2s ease-in-out',
                                            }}
                                            >
                                                <IconButton size={'small'} onClick={() => setCustomerSearchQuery('')}>
                                                    <Clear fontSize={'small'}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                            {!isAdmin && (
                                <p>
                                    {isAccountant
                                        ? t('pages-customers.customersListMessageAccountantAdvisorRole', {partnerName: partner?.name ?? ''})
                                        : t('pages-customers.customersListMessageAdvisorNotAdminRole', {partnerName: partner?.name ?? ''})
                                    }
                                </p>
                            )}
                            <CustomerList partner={partner} customers={filteredCustomers}/>
                        </Paper>
                    ) : (
                        partner?.id ?
                        <EmptyState
                            illustration={{name: 'womanOfficeWorking', color: 'orange'}}
                            title={t('pages-customers.addFirstCustomer')}
                            button={{
                                dataCy: 'inviteButton',
                                text: isAccountant ? t('pages-customers.registerNewCustomer') : t('pages-customers.inviteNewCustomer'),
                                onClick: () => navigate('/customer/register'),
                            }}
                        /> : <LoadingSpinner/>
                    )}
                </Stack>
            </PageLayout>
        </>
    )
}

function sanitizeString(string: string): string {
    // lowercase, remove whitespace, convert accented characters to non-accented, convert ø to o, convert æ to e, remove special characters, remove double characters
    return string.toLowerCase().replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace('ø', 'o').replace('æ', 'e').replace(/[^a-z0-9]/g, '').replace(/(.)\1+/g, '$1');
}
