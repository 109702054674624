import { Fragment } from 'react';
import config from '#app/config'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './carousel.css'
import {Carousel} from 'react-responsive-carousel'
import styles from './BankPresentation.module.css'
import {BankPresentationDto} from '@fixrate/fixrate-query'

const BASE_URL = config().apiUrl

type Props = {
    bankPresentation: BankPresentationDto
}

export default function BankPresentation({bankPresentation}: Props) {
    const {ingress, joinedFixrate, quote, quoteAuthor, photo1present, photo2present, photo3present} = bankPresentation

    const imageUrl = `${BASE_URL}/api/bank/${bankPresentation.id}/image/`

    const photos = [
        photo1present ? <img key={'p1'} src={imageUrl + 'photo1'} alt={''}/> : undefined,
        photo2present ? <img key={'p2'} src={imageUrl + 'photo2'} alt={''}/> : undefined,
        photo3present ? <img key={'p3'} src={imageUrl + 'photo3'} alt={''}/> : undefined,
    ].filter(p => !!p)

    return (
        <Fragment>
            {ingress && <p className={styles.ingress}>{ingress}</p>}
            {joinedFixrate && <p>Banken har vært med i Fixrate siden {joinedFixrate}</p>}
            {quote && <p className={styles.quote}>«{quote}»</p>}
            {quoteAuthor && <p className={styles.quoteAuthor}>{quoteAuthor}</p>}
            {(photo1present || photo2present || photo3present) && (
                <Carousel
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={5000}
                >
                    {photos}
                </Carousel>
            )}
        </Fragment>
    );
}
