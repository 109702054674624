import useShoppingCart from '#app/services/useShoppingCart';
import styles from '../FundTable.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, useMediaQuery } from '@mui/material';
import { FundDto } from '@fixrate/fixrate-query';
import useFundShareClassSelected from "#services/useFundShareClassSelected";

export default function TableShoppingCartButton({fund} : {fund: FundDto}) {
    const {t} = useTranslation()
    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(fund.id)
    const isMobile = useMediaQuery('(max-width: 900px)')
    const [fundClassId] = useFundShareClassSelected(fund.id, fund?.fundShareClasses[0]?.id)

    return (
        <td align='right' className={styles.buttonInside}>
            { !isMobile ? (
                <Button
                    variant='outlined'
                    size='small'
                    sx={{ml: 'auto'}}
                    color={!isInShoppingCart ? 'primary' : 'error'}
                    startIcon={!isInShoppingCart ? <i className="purple ri-shopping-cart-2-line"/> : <i className="red ri-shopping-cart-2-fill"/>}
                    onClick={() => !isInShoppingCart ? shoppingCart.addItem(fund.id, fundClassId) : shoppingCart.removeItem(fund.id)}>
                    { !isMobile && (
                        <Box sx={{whiteSpace: "nowrap"}}>
                            {!isInShoppingCart ? t('pages-fund-details.addToCart') : t('pages-fund-details.removeFromCart')}
                        </Box>
                    )}
                </Button>
            ) : (
                <IconButton onClick={() => !isInShoppingCart ? shoppingCart.addItem(fund.id, fundClassId) : shoppingCart.removeItem(fund.id)}>
                    {!isInShoppingCart ? <i className="purple ri-shopping-cart-2-line"/> : <i className="red ri-shopping-cart-2-fill"/>}
                </IconButton>
            )}
        </td>
    )
}
