import { GenericOrgDto, GenericUser, UsersListParams } from '#blocks/UsersList/UsersList'
import { useTranslation } from "#components/i18n"
import If from '#components/If/If'
import Modal from '#components/Modal'
import ModalConfirmation from '#components/ModalConfirmation/ModalConfirmation'
import { userIsPartner } from '#services/authorization'
import { useSelector } from '#state/useSelector'
import { BankUserDto, PartnerDto, PartnerLimitedDto } from '@fixrate/fixrate-query'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import MenuItem from './MenuItem'
import ResendUserInvite from './ResendUserInvite'
import RoleBadgeList from './RoleBadgeList'
import styles from './UsersList.module.scss'

type Props = {
    user: GenericUser,
    userListParams: UsersListParams
}

export default function User({user, userListParams}: Props) {
    const {t} = useTranslation('blocks-UsersList')

    const {isAdmin, organisation, missingRoles, basePath, removeUser} = userListParams
    const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)
    const resendUserInviteVisible = false
    const depositor = useSelector(state => state.depositor)
    const session = useSelector(({session}) => session)
    const orgId = organisation.type === 'DEPOSITOR' ? depositor.id : organisation.id
    const userIsAdvisor = !!user && userIsPartner(user)

    function isPartnerLimitedDto(org: GenericOrgDto | PartnerDto): org is PartnerDto {
        return (org as PartnerDto).partnerCategory !== undefined
    }
    const partnerCategory = isPartnerLimitedDto(organisation) ? organisation.partnerCategory : ''

    const emphasizeRoleLink = missingRoles?.length > 0

    const userIsActiveAuthorizationSigner = useSelector(({depositors, documents}) => !user ? false : depositors.find(d => d.id === orgId)?.users.flatMap(u => documents[u.authorizationDocumentId]?.allSignatures.map(s => s.userId)).filter(id => id === user.id).length > 0)

    const menu = (
        <ul className={styles.actionMenu}>
            <If condition={(isAdmin) && !userIsAdvisor && organisation.type !== 'DEPOSITOR'}>
                <MenuItem
                    emphasized={emphasizeRoleLink}
                    id="editRoleMenuItem"
                    text={t('menuItemEditRole')}
                    url={`${basePath}/edit-role/${user.id}#!`}
                    icon={'ri-edit-line'}
                />
            </If>
            <If condition={isAdmin && user.id !== session.id}>
                <MenuItem
                    id="removeUserMenuItem"
                    text={t('menuItemRemoveUser')}
                    onClick={() => setRemoveUserModalVisible(true)}
                    icon={'ri-delete-bin-line'}
                />
            </If>
        </ul>
    )

    const identityElements = (() => {
        switch (organisation.type) {
            case 'DEPOSITOR':
                return (
                    <li className={styles.userlist__identityElement}>
                        <i className="ri-mail-line"/>
                        <span>{user.email}</span>
                    </li>
                )
            case 'BANK':
                return (
                    <>
                        <li className={styles.userlist__identityElement}>
                            <i className="ri-mail-line"/>
                            <span>{user.email}</span>
                        </li>
                        <li className={styles.userlist__identityElement}>{(user as BankUserDto).mobile}</li>
                    </>
                )
        }
    })()

    return (
        <div data-cy='userListElement' className={styles.userslist__listElement}>
            <div className={styles.userslist__listElementTop}>
                <div>
                    <h3 className={styles.userlist__name}>
                        {organisation.type === 'DEPOSITOR' && (
                            <Link className={styles.profileLink} to={`/organizations/${orgId}/users/profile/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
                        )}
                        {organisation.type !== 'DEPOSITOR' && <span>{`${user.firstName} ${user.lastName}`}</span>}
                    </h3>
                    <ul className={styles.userlist__identity}>{identityElements}</ul>
                    <RoleBadgeList userListParams={userListParams} roles={user.roles} userId={user.id} />
                </div>
                {menu}
                {removeUserModalVisible && !userIsAdvisor && partnerCategory === 'SUPPORT' && (
                    <Modal onCancel={() => setRemoveUserModalVisible(false)} width='40rem' header={t('removeUserHeading')} onSubmit={() => removeUser(user.id)} className={styles.confirmation}>
                        <div className={styles.innerWrapper}>
                            <p className={styles.body}>{t('removeSupportUserConfirmation', { userName: user.firstName + ' ' + user.lastName, organizationName: organisation.name })}</p>
                        </div>
                    </Modal>
                )}
                {removeUserModalVisible && !userIsAdvisor && partnerCategory !== 'SUPPORT' && (
                    <Modal onCancel={() => setRemoveUserModalVisible(false)} width='40rem' header={t('removeUserHeading')} onSubmit={() => removeUser(user.id)} className={styles.confirmation}>
                        <div className={styles.innerWrapper}>
                            <p className={styles.body}>
                                {userIsActiveAuthorizationSigner
                                    ? t('removeUserIsActiveAuthorizationSigner', { userName: user.firstName + ' ' + user.lastName })
                                    : t('removeUserConfirmation', {
                                          userName: user.firstName + ' ' + user.lastName,
                                          organizationName: organisation.name,
                                      })}
                            </p>
                        </div>
                    </Modal>
                )}
                {removeUserModalVisible && userIsAdvisor && (
                    <ModalConfirmation
                        backUrl={''}
                        header={t('removeUserHeading')}
                        onSubmit={() => setRemoveUserModalVisible(false)}
                        text={t('removeUserContactSupport', { userName: user.firstName + ' ' + user.lastName, organizationName: organisation.name })}
                    />
                )}
                {resendUserInviteVisible && (
                    <ResendUserInvite organisationId={orgId} organisationType={organisation.type} inviteId={user.id} inviteEmail='' returnTo={`/organizations/${orgId}/users/all`} />
                )}
            </div>
        </div>
    )
}
