import {useTranslation} from 'react-i18next'
import {useAuthorization, useIsIntegrationsAdmin} from '#services/authorization'
import Paper from '#components/Paper'
import {Button, Chip, Stack, Typography} from '@mui/material'
import {Fragment} from 'react'

type IntegrationDescriptionProps = {
    title: string,
    content: string,
    isActive: boolean,
    id: string,
    logo: string,
    logoAlt: string,
    contactInfo?: string,
    children?: React.ReactNode,
    onConfigureButtonClick: () => void
}

const tPath = 'pages-organizations.integrationOverview'

export function IntegrationDescription({title, isActive ,content, id, logo, logoAlt, contactInfo, onConfigureButtonClick, children}: IntegrationDescriptionProps) {
    const {t} = useTranslation()
    const integrationsAdmin = useIsIntegrationsAdmin()

    return (
        <Paper id={id}
               title={
                   <img
                       src={logo}
                       alt={logoAlt}
                       style={{'height': '5rem'}}/>
               }
               titleActionElement={
                   <Stack direction="row" spacing={1} alignItems={"center"}>
                       <Chip sx={{borderRadius: 1}} variant={"outlined"} size={"small"} label={isActive ? "Active" : "Inactive"} color={isActive ? "success" : "error"} />
                       <Button
                           startIcon={<i className={'ri-settings-line'}/>}
                           sx={{'textDecoration': 'none', "&:hover": {textDecoration: 'none'}}}
                           disabled={!integrationsAdmin}
                           onClick={onConfigureButtonClick}>
                           {t(`${tPath}.configureIntegration`)}
                       </Button>
                   </Stack>

               }
        >
            <Stack spacing={1} flexDirection={'column'}>
                <Typography>{content}</Typography>
                {contactInfo && (
                    <Typography>{contactInfo}</Typography>
                )}
            </Stack>
            <Fragment>{children}</Fragment>
        </Paper>
    )
}
