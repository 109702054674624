import { useCommand } from '#command'
import { ActionBlock, PageHeader, PageLayout } from '#components'
import { Progress, ProgressStep } from '#components/Progress/Progress'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './DepositorOfferwizard.module.css'
import useDepositorOfferWizardFields from './DepositorOfferWizardFields'
import Status from './Status'
import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

type Params = {
    depositorOfferId?: string
    step?: string
}

export default function DepositorOfferWizard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const params = useParams<Params>()
    const navigate = useNavigate()

    const currentStep = !params.step ? 0 : parseInt(params.step)

    const fields = useDepositorOfferWizardFields(params.depositorOfferId)
    const { publishDepositorOffer } = useCommand()

    if (!fields.isLoaded) {
        return <div>Loading ...</div>
    }

    const stepBaseUrl = `/depositoroffer/${params.depositorOfferId}/edit/`

    function onPrevious() {
        if (currentStep > 0) {
            navigate(`${stepBaseUrl}${currentStep - 1}`)
        } else {
            navigate(`/depositoroffer`)
        }
    }

    function onNext() {
        if (currentStep < 5) {
            navigate(`/depositoroffer/${params.depositorOfferId}/edit/${currentStep + 1}`)
        }
    }

    async function send() {
        if (fields.allValid()) {
            const { waitForCommand } = await publishDepositorOffer(params.depositorOfferId)
            const success = await waitForCommand()
            if (success) {
                navigate(`/depositoroffer`)
                dispatch(
                    showConfirmationModal({
                        title: t('pages-offer.wizardConfirmationHeading'),
                        text: t('pages-offer.wizardConfirmationMessage'),
                        buttonText: t('common.close'),
                    })
                )
            }
        }
    }

    let step
    switch (currentStep) {
        case 0:
            step = <Step0 onPrevious={onPrevious} onNext={onNext} fields={fields} />
            break
        case 1:
            step = <Step1 onPrevious={onPrevious} onNext={onNext} fields={fields} />
            break
        case 2:
            step = <Step2 onPrevious={onPrevious} onNext={onNext} fields={fields} />
            break
        case 3:
            step = <Step3 onPrevious={onPrevious} onNext={onNext} fields={fields} />
            break
        case 4:
            step = <Step4 onPrevious={onPrevious} onNext={onNext} fields={fields} />
            break
        case 5:
            step = <Step5 onPrevious={onPrevious} onNext={send} fields={fields} />
            break
    }

    return (
        <>
            <PageHeader title={t('pages-offer.offerToBankHeading')} />
            <PageLayout>
                <ActionBlock header={t('pages-offer.termsHeading')} className={styles.paper}>
                    <div className={styles.content}>
                        <div className={styles.left}>
                            <Progress step={currentStep} edgeBar={true} className={styles.progress}>
                                <ProgressStep bottomText={t('pages-offer.wizardStepTerms')} link={stepBaseUrl + 0} />
                                <ProgressStep bottomText={t('pages-offer.wizardStepAmount')} link={stepBaseUrl + 1} />
                                <ProgressStep bottomText={t('pages-offer.wizardStepBanks')} link={stepBaseUrl + 2} />
                                <ProgressStep bottomText={t('pages-offer.wizardStepPlans')} link={stepBaseUrl + 3} />
                                <ProgressStep bottomText={t('pages-offer.wizardStepDeadline')} link={stepBaseUrl + 4} />
                                <ProgressStep
                                    bottomText={t('pages-offer.wizardStepSendOffer')}
                                    link={stepBaseUrl + 5}
                                />
                            </Progress>
                            <div className={styles.step}>{step}</div>
                        </div>
                        <div className={styles.right}>
                            <Status depositorOfferId={params.depositorOfferId} />
                        </div>
                    </div>
                </ActionBlock>
            </PageLayout>
        </>
    )
}
