import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function useSearchParams<T extends NonNullable<unknown>>(): T {
    const { search } = useLocation()
    const [searchParams, setSearchParams] = useState<T>({} as T)

    useEffect(() => {
        const params = new URLSearchParams(search)
        const queryParams = {} as T

        for (const [key, value] of params) {
            queryParams[key] = value
        }

        setSearchParams(queryParams)
    }, [search])

    return searchParams as T
}
