import styles from './LegacyCheckbox.module.css'
import classNames from 'classnames'

interface LegacyCheckboxProps {
    id?: string
    className?: string
    label: string
    value: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const LegacyCheckbox = ({id, className, value, onChange, label}: LegacyCheckboxProps) => (
    <label className={classNames(styles.body, className)}>
        <input type="checkbox"
               id={id}
               checked={value}
               onChange={onChange}
        />
        <div className={styles.labelPadding}>{label}</div>
    </label>
)

export default LegacyCheckbox