import UsersList from '#app/blocks/UsersList/UsersList'
import Paper from '#app/components/Paper/FxPaper'
import useBankUserListParams from '#pages/mybank/useBankUserListParams'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function BankUserList() {
    const { t } = useTranslation()
    const userListParams = useBankUserListParams()
    const navigate = useNavigate()

    const inviteButton = (userListParams.isAdmin) ? (
        <Button onClick={() => navigate(`${userListParams.basePath}/new-user#!`)} size={'small'} variant={'outlined'}>
            <i className="ri-user-add-line"/>
            <span>{t('blocks-UsersList.inviteNewUser')}</span>
        </Button>
    ) : null

    return (
        <Paper id="users" title={t('pages-mybank.usersHeader')} titleActionElement={inviteButton}>
            <p>{t('pages-mybank.usersInfo')}</p>
            <UsersList userListParams={userListParams}/>
        </Paper>
    )
}
