import scrollToAnchor from '#app/scroll-to-anchor'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout/PageLayout'
import BankTermsList from '#pages/mybank/BankTermsList'
import { useAuthorization } from '#services/authorization'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { useNavigate } from 'react-router-dom'
import BankEmail from './BankEmail/BankEmail'
import BankEmailDomain from './BankEmailDomain/BankEmailDomain'
import BankInfo from './BankInfo/BankInfo'
import BankUserList from './BankUserList'
import PinCodeAdmin from './PinCodeAdmin'

export default function MyBank() {
    const bank = useSelector(selectors.bankInSession)
    const auth = useAuthorization()
    const navigate = useNavigate()

    scrollToAnchor()

    if (!bank) {
        navigate('/marketplace')
        return null;
    }

    return (
        <>
            <PageHeader title={bank && bank.name}/>
            <PageLayout>
                <BankUserList/>
                {auth.bank.hasAdminRole && <BankEmail bank={bank}/>}
                {auth.bank.hasAdminRole && <BankEmailDomain bank={bank}/>}
                <BankTermsList bank={bank}/>
                <PinCodeAdmin/>
                <BankInfo bank={bank}/>
            </PageLayout>
        </>
    )
}
