import PropTypes from 'prop-types'
import DownloadIcon from '../../common/icons/DownloadIcon'
import {Trans} from "#components/i18n";
import { useTranslation } from 'react-i18next';
import FxPaper from '#app/components/Paper/FxPaper';
import { Button } from '@mui/material';

const AuthorizationDocumentPreview = function({
    previewLink = null,
}) {
    const { t } = useTranslation()

    return (
        <FxPaper 
            title={t('pages-organizations.preview')}
            titleActionElement={
                <Button 
                    size="small"
                    variant="outlined" 
                    startIcon={<i className="ri-download-line" />} 
                    onClick={() => window.location.href = previewLink?.attachment}>
                    {t('pages-organizations.download')}
                </Button>
            }>
            <div className="authorization__document-object-background">
                <object className="authorization__document-object" data={previewLink?.inline} type="application/pdf">
                    <div className="authorization__document-object-fallback">
                        <p>{t('pages-organizations.previewFallbackMessagePart1')}</p>
                        <p>
                            <Trans t={t} i18nKey={'pages-organizations.previewFallbackMessagePart2'}>
                                Du kan installere Adobe Reader ved å følge <a href="http://get.adobe.com/reader/" rel="noopener noreferrer" target="_blank">denne lenken</a>,
                                eller ta kontakt med IT-support i din organisasjon for å få hjelp til å installere PDF-støtte.
                            </Trans>
                        </p>
                    </div>
                    <embed src={previewLink?.inline} type="application/pdf"/>
                </object>
            </div>
        </FxPaper>
    )
}

AuthorizationDocumentPreview.propTypes = {
    previewLink: PropTypes.object,
}

export default AuthorizationDocumentPreview
