import { Fragment } from 'react';
import styles from './FundPlacements.module.scss'
import FundPlacementAccordion from './FundPlacementAccordion'
import {LinkButton} from '#app/components'
import FundOrders from './FundOrders'
import {useSelector} from '#state/useSelector'
import {useTranslation} from 'react-i18next'
import {Box, Stack, Tooltip} from "@mui/material";
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput';

export default function FundPlacements() {
    const {t} = useTranslation()

    const fundPlacements = useSelector(state => state.fundPlacements.filter(placement => placement.unitQuantity !== 0))
    const fundBuyOrders = useSelector(state => state.fundBuyOrders.filter(order => order.state !== 'COMPLETED'))
    const fundSellOrders = useSelector(state => state.fundSellOrders.filter(order => order.state !== 'COMPLETED'))

    if (fundPlacements.length > 0 || fundBuyOrders.length > 0 || fundSellOrders.length > 0) {
        return (
            <Fragment>
                <FundOverview />
                <FundOrders hideCompleted={true} showTitle={true}/>
                <Box
                    sx={{
                        mt: 4,
                        fontSize: '1.2rem',
                        maxWidth: '120rem'
                    }}
                >
                    {'*' + t('pages-FundOverview.returnsExplanation')}
                </Box>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <p className={styles.emptyMessage}>{t('pages-FundOverview.noFundsInPortfolio')}</p>
            <LinkButton className={styles.goToMarketplaceButton} to={"/marketplace/category/funds/money-market-fund"}>{t('pages-FundOverview.goToFundMarketplace')}</LinkButton>
        </Fragment>
    );
}


function FundOverview() {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()
    const fundPlacements = useSelector(state => state.fundPlacements.filter(placement => placement.unitQuantity !== 0))
    const totalFundValue = fundPlacements.reduce((acc, fp) => acc + fp.dirtyMarketValue, 0)
    const totalUnrealizedValue = fundPlacements.reduce((acc, fp) => acc + fp.dirtyReturnValue, 0)
    return (
        <div className={styles.myFunds}>
            {fundPlacements.length > 0 && (
                <Fragment>
                    <h3 className={styles.title}>
                        <i className="ri-funds-line"/>
                        <span>{t('pages-FundOverview.yourFundPortfolio')}</span>
                    </h3>
                    <ul className={styles.fundList}>
                        <li className={styles.accordionDescription}>
                            <span className={styles.name}>{t('pages-FundOverview.name')}</span>
                            <span className={styles.risk}>{t('pages-FundOverview.risk')}</span>
                            <span className={styles.nav}>{t('pages-FundOverview.navPrice')}</span>
                            <span className={styles.subVal}>{t('pages-FundOverview.unitQuantity')}</span>
                            <span className={styles.val}>{t('pages-FundOverview.marketValue')}</span>
                            <span className={styles.returns}>
                                <span>{t('pages-FundOverview.returns') + '*'}</span>
                                <Tooltip title={t('pages-FundOverview.returnsExplanationShort')} arrow>
                                    <i className={'ri-information-line purple'} style={{fontSize: '1.8rem', marginLeft: '0.5rem', fontWeight: 'normal'}}/>
                                </Tooltip>
                            </span>
                        </li>
                        {fundPlacements.map(fundPlacement => <FundPlacementAccordion key={fundPlacement.id} fundPlacement={fundPlacement}/>)}
                    </ul>
                    <Stack>
                        <div className={styles.totalValueLine}>
                            <span>{t('pages-FundOverview.totalMarketValue', {value: Currency(totalFundValue, { decimals: 0 })})}</span>
                            {/*<span>{t('pages-FundOverview.totalUnrealizedReturns', {value: Currency(totalUnrealizedValue, {decimals: 0})})}</span>*/}
                        </div>
                    </Stack>
                </Fragment>
            )}
        </div>
    );
}
