import styles from './Table.module.scss'

/**
 * @typedef {object} Props
 * @property [children]
 * @property [label]
 * @property [sort]
 * @property {'left'|'right'|'center'} [align]
 * @property {string} [title]
 *
 * @param {Props & any} props
 * @return {JSX.Element}
 */
export default function TableHeaderCell({children = null, label = null, sort = null, align = 'left', title, ...otherprops}) {
    const clickable = !!label && !!sort
    const cursor = clickable ? 'pointer' : 'inherit'
    const onClick = () => clickable && sort(label)
    return (
        <th {...otherprops} title={title} onClick={onClick} style={{textAlign: align, cursor: cursor}}>
            {children}
        </th>
    )
}