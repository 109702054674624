import en from "date-fns/locale/en-US";
import nb from "date-fns/locale/nb";
import {sv} from "date-fns/locale";
import {useSelector} from "#state/useSelector";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {format} from "date-fns";

const locales = {
    'en': en,
    'nb': nb,
    'sv': sv
}
type Props = {
    value: number,
    onChange: (value: unknown) => void,
}

export function MonthSelect({value, onChange}: Props) {
    const months = Array.from({length: 12}, (_, i) => i)
    const language = useSelector(state => state.session.language)
    const locale = language == null ? nb : locales[language]

    return (
        <Select
            sx={{maxWidth: '12rem', borderTopRightRadius: '0', borderBottomRightRadius: '0'}}
            value={value}
            onChange={(e: SelectChangeEvent<number>) => onChange(e.target.value)}
        >
            {months.map(month => (
                <MenuItem key={month} value={month}>{format(new Date(0, month), 'MMMM', {locale: locale})}</MenuItem>
            ))}
        </Select>
    )
}
