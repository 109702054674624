import { BURNT_ORANGE } from '#app/colors/colors';
import restEndpoint from '#app/services/rest/rest-endpoint';
import useUnresolvedTasks from '#app/services/useUnresolvedTasks';
import { messageTasksActionRequiredByUserCountSelector } from '#state/selectors'
import { Currency } from '@fixrate/fixrate-security';
import { useSelector } from '#app/state/useSelector';
import { setSession } from '#state/reducers/session';
import { Avatar, Box, CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const CurrencyTabber = ({currencies, selectedCurrency, switchCurrency} : {currencies: Currency[], selectedCurrency: Currency, switchCurrency: (currency: Currency) => void}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<Currency | null>(null)
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)
    const unresolvedTasks = useUnresolvedTasks()
    const portfolios = useSelector(state => state.depositor?.portfolios)

    // Portfolio is now only relevant for multiple currencies – if it is being used for other grouping in the future this will need to be updated
    const hasMultipleCurrencies = currencies.length > 1

    const updateSession = () => {
        restEndpoint(dispatch).getSession().then((session) => {
            dispatch(setSession(session))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            // avoid flickering between empty currency text and loading spinner
            setTimeout(() => {
                setLoading(null)
            }, 1000)
        })
    }

    const selectCurrency = async (currency) => {
        if (currency !== selectedCurrency) {
            setLoading(currency)
            await switchCurrency(currency)
            await updateSession()
        }
    }

    return hasMultipleCurrencies ? (
        <Box>
            <Typography variant='filterLabel'>{t('common.portfolio')}</Typography>
            <Tabs sx={{boxShadow: "0px 1px 3px rgba(0,0,0,0.2)", backgroundColor: "white", borderRadius: "0.6rem"}} value={selectedCurrency}>
                { currencies.map((currency, index) => (
                    <Tab
                        label={
                            loading === currency ? (
                                <CircularProgress size={20} />
                            ) : (
                                <Stack direction="row" alignItems={"center"} spacing={0.4}>
                                    <span>{currency}</span>
                                    { portfolios && unresolvedTasks.portfolios[portfolios.find(p => p.currency === currency).id] > 0 && (
                                        <Avatar sx={{width: "1.6rem", height: "1.6rem", backgroundColor: BURNT_ORANGE[500], color: "white", fontSize: "1.2rem"}}>{unresolvedTasks.portfolios[portfolios.find(p => p.currency === currency).id]}</Avatar>
                                    )}
                                    { !portfolios && messageTasksActionRequiredByUserCount > 0 && currency === selectedCurrency && (
                                        <Avatar sx={{width: "1.6rem", height: "1.6rem", backgroundColor: BURNT_ORANGE[500], color: "white", fontSize: "1.2rem"}}>{messageTasksActionRequiredByUserCount}</Avatar>
                                    )}
                                </Stack>
                            )
                        }
                        key={currency}
                        value={currency}
                        sx={{height: "5.3rem", borderRight: index !== currencies.length ? "0.1rem solid rgba(0,0,0,0.1)" : "0"}}
                        onClick={() => selectCurrency(currency)} />
                ))}
            </Tabs>
        </Box>
    ) : null
}
