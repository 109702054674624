import {useTranslation} from "react-i18next";
import {Fragment, useState} from "react";
import {useSelector} from "#state/useSelector";
import {NumberInput, PageHeader, PageLayout} from "#components";
import Paper from "#components/Paper";
import PeriodPicker from "#components/PeriodPicker/PeriodPicker";
import {Divider, MenuItem, Select, Stack} from "@mui/material";
import {ReportDownload} from "#pages/portfolio-depositor/Reports/ReportDownload";

function getCurrentQuarter() {
    const date = new Date()
    const year = date.getFullYear();
    const month = date.getMonth() + 1
    const quarter = Math.ceil(month / 3)
    return year + "-Q" + quarter;
}

type RetrocessionType = "CASH" | "UNITS"

export function FundRetrocessionDistribution() {
    const {t} = useTranslation()
    const [selectedShareClassId, setSelectedShareClassId] = useState('')
    const shareClassIdToName = useSelector(state =>
        state.funds.flatMap(fund => fund.fundShareClasses)
            .reduce((result, shareClass) => {
                result[shareClass.id] = shareClass.fullName
                return result
            }, {} as { [fundShareClassId: string]: string })
    )
    const [period, setPeriod] = useState(getCurrentQuarter())
    const [retrocessionType, setRetrocessionType] = useState<RetrocessionType>("CASH")
    const [totalRetrocession, setTotalRetrocession] = useState(0)
    const allowedPeriods = "YHQM" // Allow all period types.

    return (
        <Fragment>
            <PageHeader title={t('pages.fund-retrocession-distribution.title')}/>
            <PageLayout>
                <Paper size={'large'}>
                    <h3>{t('pages.fund-retrocession-distribution.title')}</h3>
                    <p>{t('pages.fund-retrocession-distribution.p1')}</p>

                    <Stack spacing={2}>
                        <PeriodPicker
                            value={period}
                            onChange={setPeriod}
                            showPeriods={allowedPeriods}
                        />
                        <Select
                            value={selectedShareClassId}
                            onChange={(e) => setSelectedShareClassId(e.target.value as string)}
                            displayEmpty
                            renderValue={value => {
                                return shareClassIdToName[value] ?? t('pages.fund-retrocession-distribution.select-share-class')
                            }}
                        >
                            {Object.entries(shareClassIdToName).map(([id, name]) => (
                                <MenuItem key={id}
                                          value={id}
                                          disabled={id === selectedShareClassId}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Stack direction={'row'} spacing={1}>
                            <NumberInput value={totalRetrocession}
                                         onChange={setTotalRetrocession}
                                         label={t('pages.fund-retrocession-distribution.total-retrocession')}
                            />
                            <Select value={retrocessionType}
                                    onChange={(e) => setRetrocessionType(e.target.value as RetrocessionType)}
                                    sx={{ width: '12rem' }}
                            >
                                <MenuItem value="CASH">{t('pages.fund-retrocession-distribution.retrocession-type-cash')}</MenuItem>
                                <MenuItem value="UNITS">{t('pages.fund-retrocession-distribution.retrocession-type-units')}</MenuItem>
                            </Select>
                        </Stack>
                    </Stack>
                    <Divider sx={{mt: 2, mb: 1}}/>
                    {selectedShareClassId && totalRetrocession > 0 && period && (
                        <ReportDownload
                            name={t('pages.fund-retrocession-distribution.report-download-name')}
                            description={`${shareClassIdToName[selectedShareClassId]} (${period})`}
                            link={`/document/report/FUND_RETROCESSION_EXPORT?period=${period}&fundShareClassId=${selectedShareClassId}&type=${retrocessionType}&retrocessionForPeriod=${totalRetrocession}`}
                            dataCy='fundRetrocessionDistributionDownloadButton'
                            keyValue={`fundRetrocession-${selectedShareClassId}-${period}-${retrocessionType}`}
                            pdf={false}
                            excel={true}
                        />
                    )}
                </Paper>
            </PageLayout>
        </Fragment>
    )
}
