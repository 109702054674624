import { Children, cloneElement } from 'react';
import {Transition} from 'react-transition-group'

/**
 * Animates the children on enter
 */
export default function Enter({duration = 350, children, timeout = 200, hide = false, ...props}: { duration?: number, children?, timeout?: number, hide?: boolean, key?: string }) {

    const testMode = !!window['Cypress']
    duration = testMode ? 0 : duration

    const defaultStyle = {
        transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
        transform: 'translateY(100%)',
        opacity: 0,
    }

    const transitionStyles = {
        entering: {transform: 'translateY(100%)', opacity: 0},
        entered: {transform: 'translateY(0%)', opacity: 1},
        exiting: {transform: 'translateY(100%)', opacity: 0},
        exited: {transform: 'translateY(100%)', opacity: 0},
    }

    return (
        <Transition
            mountOnEnter={true}
            unmountOnExit={true}
            appear={true}
            timeout={{
                appear: timeout,
                enter: 0,
                exit: duration,
            }}
            exit={true}
            in={!hide}
            {...props}
        >
            {state => (
                Children.map(children, Child => cloneElement(Child, {
                    style: {
                        ...defaultStyle,
                        ...transitionStyles[state],
                    }
                }))
            )}
        </Transition>
    )
}
