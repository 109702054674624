import { PURPLE, SILVER_GRAY } from '#app/colors/colors'
import AsyncButton from '#app/components/Button/AsyncButton'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import AdCard from '#app/pages/marketplace/AdCard/AdCard'
import { proposalMessageText, proposalSubject } from '#app/pages/marketplace/SendPartnerProposal/proposalUtils'
import SuggestedMessage from '#app/pages/marketplace/SendPartnerProposal/SuggestedMessage'
import { useCommand } from '#app/services/beta'
import { switchOrganisation } from '#app/services/thunks/session'
import { useSelector } from '#app/state/useSelector'
import { AdDto, DepositorPartnerProposalDto, DepositorPartnerProposalStatus, OrganisationalPerson, OrganisationType } from '@fixrate/fixrate-query'
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, Chip, Divider, Stack, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import CopyLink from './CopyLink'

type Status = {
    label: string
    color: "default" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
}

const PersonRow = ({ person, status } : {person: OrganisationalPerson, status?: Status}) => {
    return (
        <Stack direction="row" justifyContent={'space-between'}>
            <Box>
                <Typography fontSize="1.4rem" fontWeight={600}>{person.firstName} {person.lastName}</Typography>
                <Typography variant="body2">{person.email}</Typography>
            </Box>
            { status && <Chip color={status.color} label={status.label} /> }
        </Stack>
    )
}

export default function ProposalCards({ proposals }: Readonly<{ proposals: DepositorPartnerProposalDto[] }>) {
    const {t} = useTranslation()
    const sessionId = useSelector(state => state.session?.id)
    const products = useSelector(state => state.products)
    const banks = useSelector(state => state.banks)
    const customers = useSelector(state => state.partner?.customers)
    const { updatePartnerProposal } = useCommand()
    const [showEmailSuggestion, setShowEmailSuggestion] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const MARKET_URL = window.location.origin

    const authorizationDocumentSigned = (depositorId: string) => {
        return customers?.find(customer => customer.depositorId === depositorId)?.users?.find(a => a.id === sessionId)?.hasSignedAuthorizationDocument
    }

    const getBankName = (bankId: string) => {
        return banks[bankId]?.name
    }

    function goToCustomerAd(organisationType: OrganisationType, orgId: string, ad: AdDto) {
        const adUrl = `/marketplace/category/deposits/${ad.min >= 20 ? "over-20" : "under-20"}/${ad.id}`
        dispatch(switchOrganisation(organisationType, orgId, () => navigate(adUrl)))
    }

    async function deleteProposal(proposal: DepositorPartnerProposalDto) {
        const status: DepositorPartnerProposalStatus = 'DELETED'

        const { waitForCommand } = await updatePartnerProposal(proposal?.proposalId, proposal?.partnerId, proposal?.depositorId, status, proposal?.amount ? proposal.amount: 0)
        const success = await waitForCommand()
        if (success) {
            console.log(success)
        }
    }

    return (
        <Stack width={'200rem'} maxWidth={'100%'}>
            <Stack spacing={3} direction="row" flexWrap={"wrap"} data-cy="proposalCards">
                {proposals.map(proposal => (
                    <Fragment key={proposal.proposalId}>
                        <Card sx={{width: "90rem", maxWidth: "100%"}} data-cy="proposalCard">
                            <CardHeader title={(
                                <Stack direction={{lg: "row"}} gap={2} justifyContent="space-between" py={0.5}>
                                    <Stack spacing={0.5}>
                                        <Typography variant="h3">
                                            <Link data-cy="depositorName" style={{textDecoration: "none", color: PURPLE[900]}} to={"/customer/" + proposal.depositorId}>{proposal.depositorName}</Link>
                                        </Typography>
                                        <Typography color={SILVER_GRAY[500]} variant={"body2"}>Opprettet av {proposal.initiatorName}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center" flexWrap={"wrap"}>
                                        { proposal?.status === "PENDING" && (
                                            <CopyLink link={`${MARKET_URL}${proposal.link}`} />
                                        )}
                                        { proposal?.status === "PENDING" && (
                                            <Button data-cy="open-email-suggestion" size='small' startIcon={<i className='ri-mail-line' />} variant='outlined' onClick={() => setShowEmailSuggestion(true)}>
                                                {t('pages-proposal-overview.emailSuggestion')}
                                            </Button>
                                        )}
                                        { (proposal.status !== "REJECTED" && proposal.status !== "DELETED" && proposal.status !== "ORDERED") && (
                                            <AsyncButton size='small' color="error" startIcon={<i className='ri-delete-bin-line' />} variant='outlined' onClick={() => deleteProposal(proposal)}>
                                                {t('pages-proposal-overview.deleteSuggestion')}
                                            </AsyncButton>
                                        )}
                                        { proposal.status === "ACCEPTED" && (
                                            <Button data-cy="orderDepositButton" size='small' variant='contained' onClick={() => goToCustomerAd("DEPOSITOR", proposal.depositorId, proposal.ad)}>
                                                {t('pages-proposal-overview.goToOrder')}
                                            </Button>
                                        )}
                                    </Stack>
                                </Stack>
                            )} />
                            <Divider sx={{mx:3}} />
                            <CardContent>
                                <Stack alignItems={"flex-start"} direction={{lg: "row"}} gap={{xs: 3, lg: 5}} sx={{py:2}}>
                                    <AdCard product={products[proposal.ad.productId]} ad={proposal.ad} onAdClick={() => console.log('')} preview />
                                    <Stack spacing={{xs: 2, lg: 4}}>
                                        <Stack spacing={1}>
                                            <Typography variant="h3" fontWeight={700}>
                                                <i className="ri-mail-send-line" />
                                                <span>{t('pages-proposal-overview.receiver')}</span>
                                            </Typography>
                                            <PersonRow person={proposal.receiverPerson} />
                                        </Stack>
                                        <Stack spacing={1} alignItems="flex-start">
                                            { authorizationDocumentSigned(proposal.depositorId) ? (
                                                <Alert severity="success">
                                                    <AlertTitle>{t('pages-proposal-overview.signingOfAuthorization')}</AlertTitle>
                                                    {t('pages-proposal-overview.authorizationDocumentSigned')}
                                                </Alert>
                                            ) : (
                                                <Alert severity="warning">
                                                    <AlertTitle>{t('pages-proposal-overview.signingOfAuthorization')}</AlertTitle>
                                                    {t('pages-proposal-overview.authorizationDocumentNotSigned')}
                                                </Alert>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <FxDialog
                            open={showEmailSuggestion}
                            onClose={() => setShowEmailSuggestion(false)}
                        >
                            <FxDialogTitle onClose={() => setShowEmailSuggestion(false)}>
                                {t('pages-proposal-overview.emailSuggestion')}
                            </FxDialogTitle>
                            <FxDialogContent>
                                <Box py={2}>
                                    <SuggestedMessage
                                        subject={proposalSubject}
                                        linkText={`${MARKET_URL}${proposal.link}`}
                                        messageText={proposalMessageText(proposal?.ad?.interest, getBankName(proposal?.ad?.bankId), `${MARKET_URL}${proposal.link}`)}
                                        receiverName={proposal.receiverPerson.firstName}
                                    />
                                </Box>
                            </FxDialogContent>
                        </FxDialog>
                    </Fragment>
                ))}
            </Stack>
        </Stack>
    )
}
