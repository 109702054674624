import {PageHeader, PageLayout} from '#components'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import config from '#app/config'
import restEndpoint from '#services/rest/rest-endpoint'
import {POST_NO_RESPONSE} from '#services/network/rest-connection'
import {Stack} from '@mui/material'
import {
    Divider,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from '@mui/material'
import AsyncButton from '#components/Button/AsyncButton'
import Paper from '#app/components/Paper/FxPaper'
import format from 'date-fns/format'
import {nb} from 'date-fns/locale'
import {showConfirmationModal} from '#state/reducers/confirmationModal'

type FixtureOption = {
    value: string,
    label: string
}

type CurrentFixture = {
    label: string
    loaded: Date
    newEvents: number
}

function capitalizeFirstLetter(string) {
    return string && string.length > 0 && string.charAt(0).toUpperCase() + string.slice(1)
}

function sortFixtures(fixtures: FixtureOption[]) {
    const fixturesWithNumbers = fixtures
        .filter(fixture => fixture.label.match(/IntegrationTest\.\d+/))
        .sort((a, b) => {
            const aNumber = parseInt(a.label.split('.')[1])
            const bNumber = parseInt(b.label.split('.')[1])
            return bNumber - aNumber
        })
    const fixturesWithoutNumbers = fixtures
        .filter(fixture => !fixture.label.match(/IntegrationTest\.\d+/))
        .sort((a, b) => a.label.localeCompare(b.label))
    return fixturesWithNumbers.concat(fixturesWithoutNumbers)
}

function sortFixturesInDemo(fixtures: FixtureOption[]) {
    const fixturesWithDates = fixtures
        .filter(fixture => fixture.label.startsWith('20'))
        .sort((a, b) => b.label.localeCompare(a.label))
    const fixturesWithoutDates = fixtures
        .filter(fixture => !fixture.label.startsWith('20'))
        .sort((a, b) => a.label.localeCompare(b.label))
    return fixturesWithDates.concat(fixturesWithoutDates)
}

function nextIntegrationTestNumber(fixtures) {
    const highestNumber = fixtures
        .filter(fixture => fixture.label.match(/IntegrationTest\.\d+/))
        .reduce((acc, a) => {
            const current = parseInt(a.label.split('.')[1])
            return current > acc ? current : acc

        }, 0)
    return highestNumber + 1
}

export function Fixtures() {

    const [selectedOption, setSelectedOption] = useState('')
    const [newFixtureName, setNewFixtureName] = useState('')
    const [fixtures, setFixtures] = useState<FixtureOption[]>([])
    const [currentFixture, setCurrentFixture] = useState<CurrentFixture>(null)
    const [timestamp, setTimestamp] = useState<string>('')
    const [working, setWorking] = useState<boolean>(false)
    const dispatch = useDispatch()

    // Update timestamp every second
    useEffect(() => {
        const interval = setInterval(() => {
            if (!working) {
                setTimestamp(`${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [dispatch, working])

    useEffect(() => {
        restEndpoint(dispatch).getFixtures().then(result => {
            if (result) {
                const fixtures = result
                    .map(fixture => ({value: fixture, label: fixture}))

                let sortedFixtures: FixtureOption[]
                if (config().environment === 'DEMO') {
                    sortedFixtures = sortFixturesInDemo(fixtures)
                } else {
                    sortedFixtures = sortFixtures(fixtures)
                }

                setFixtures(sortedFixtures)
            }
        })
        restEndpoint(dispatch).getCurrentFixture().then(result => {
            if (result) {
                setCurrentFixture(result)
            }
        })
    }, [dispatch, working])

    async function activateFixture(label) {
        setWorking(true)
        const fixtureToActivate = label || selectedOption
        console.log(`Activating fixture: ${fixtureToActivate}`)
        await POST_NO_RESPONSE(dispatch, '/api/fixtures/' + fixtureToActivate, '')
        dispatch(showConfirmationModal({
            title: 'Fixture lastet',
            text: 'Fixture er lastet inn. Vent til du blir logget ut og logg inn igjen for å fortsette.',
            buttonText: 'OK',
        }))
        setWorking(false)
    }

    async function saveFixture(name) {
        setWorking(true)
        await POST_NO_RESPONSE(dispatch, '/api/fixtures/' + name + '/new', '')
        setWorking(false)
    }

    if (!config().enableFixtureReload) {
        return null
    } else {
        const tableCellStyle = {borderBottom: 'none', fontSize: '1.6rem', padding: '0.5rem'}
        return (
            <>
                <PageHeader icon="ri-save-line" title={'Fixtures'}/>
                <PageLayout>
                    <Paper title={'Testdata'}>
                        <Stack direction="column" spacing={2}>
                            {currentFixture &&
                                <>
                                    <Table sx={{maxWidth: 400, fontSize: 1.6}}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={tableCellStyle} component="th"
                                                           scope="row">Navn:</TableCell>
                                                <TableCell sx={tableCellStyle}
                                                           align="left">{currentFixture.label}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={tableCellStyle} component="th" scope="row">Lastet
                                                    inn:</TableCell>
                                                <TableCell sx={tableCellStyle}
                                                           align="left">{capitalizeFirstLetter(format(new Date(currentFixture.loaded), 'EEEE d. MMMM, HH:mm', {locale: nb}))}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={tableCellStyle} component="th" scope="row">Nye
                                                    eventer:</TableCell>
                                                <TableCell sx={tableCellStyle}
                                                           align="left">{currentFixture.newEvents}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <AsyncButton sx={{maxWidth: 240}}
                                                 onClick={() => activateFixture(currentFixture.label)}
                                                 disabled={currentFixture.label === null || working}>
                                        {'Reset testdata'}
                                    </AsyncButton>
                                </>
                            }

                            <Divider/>

                            <p style={{marginBottom: 0}}>{'Du kan laste inn en annen fixture.'}
                            </p>
                            <p style={{marginBottom: 0}}>{'Dette laster inn et annet sett med testdata, brukere, banker, kunder, annonser osv. ' +
                                'Alle eksisterende data blir overskrevet.'}
                            </p>

                            <Select name="field-fixture"
                                    value={selectedOption || ''}
                                    placeholder={'Velg fixture'}
                                    onChange={(e: SelectChangeEvent) => setSelectedOption(e.target.value)}
                            >
                                <MenuItem value={''}>Velg fixture</MenuItem>
                                {fixtures.map((fixture, index) => (
                                    <MenuItem key={index} value={fixture.value}>{fixture.label}</MenuItem>
                                ))}
                            </Select>
                            <AsyncButton sx={{maxWidth: 240}}
                                         onClick={() => activateFixture(selectedOption)}
                                         variant={'outlined'}
                                         disabled={!selectedOption || working}>
                                {'Last inn fixture'}
                            </AsyncButton>
                            <Divider/>
                            <p style={{marginBottom: 0}}>{'Du kan lage en ny fixture med alle testdata som er i systemet akkurat nå.'}</p>
                            {config().environment === 'DEMO' &&
                                <>
                                    <TextField
                                        label={'Kort beskrivelse'}
                                        name="description"
                                        value={newFixtureName}
                                        onChange={(e) => setNewFixtureName(e.target.value)}
                                    />
                                    <div>Navnet på ny fixture vil inneholde dato, klokkeslett og beskrivelsen.</div>
                                    <div>Navn: {timestamp + ' ' + newFixtureName}</div>
                                    <AsyncButton sx={{maxWidth: 240}}
                                                 variant={'outlined'}
                                                 onClick={() => saveFixture(timestamp + ' ' + newFixtureName)}
                                                 disabled={working}>
                                        {'Lag ny fixture'}
                                    </AsyncButton>
                                </>
                            }
                            {config().environment !== 'DEMO' &&
                                <>
                                    <div>Navn: {`IntegrationTest.${nextIntegrationTestNumber(fixtures)}`}</div>
                                    <AsyncButton sx={{maxWidth: 240}}
                                                 variant={'outlined'}
                                                 onClick={() => saveFixture(`IntegrationTest.${nextIntegrationTestNumber(fixtures)}`)}
                                                 disabled={working}>
                                        {'Lag ny fixture'}
                                    </AsyncButton>
                                </>
                            }
                        </Stack>
                    </Paper>
                </PageLayout>
            </>
        )
    }
}
