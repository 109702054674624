import { useCommand } from '#command'
import DatePicker from '#components/DatePicker'
import Modal from '#components/Modal'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomerDetailInviteResend.module.css'


type Params = {
    id?: string
    depositorId?: string
}

export default function CustomerDetailInviteResend() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const {resendUserInvite} = useCommand()

    const partner = useSelector(state => state.partner)
    const customer = partner?.customers.find(c => c.depositorId === params.depositorId)
    const invite = customer?.userInvites.find(i => i.id === params.id)

    const firstNameValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteFirstNameMissing'))
    const firstName = useFieldState(invite.firstName, firstNameValidator)

    const lastNameValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteLastNameMissing'))
    const lastName = useFieldState(invite.lastName, lastNameValidator)

    const emailValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteEmailInvalid'))
    const email = useFieldState(invite.email, emailValidator)

    const birthDateValidator = useStableValidator<Date>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteDateOfBirthInvalid'))
    const birthDate = useFieldState(new Date(invite.birthDate), birthDateValidator)

    async function send() {
        const {waitForCommand} = await resendUserInvite(customer.depositorId, invite.id, email.value, firstName.value, lastName.value, format(birthDate.value, 'yyyy-MM-dd'))
        const success = await waitForCommand()
        if (success) {
            navigate(`/customer/${customer.depositorId}`)
        }
    }

    return (
        <Modal
            cancel={`/customer/${customer.depositorId}`}
            header={t('pages-customers.customerDetailResendInviteHeading')}
            onSubmit={send}
        >
            <div className={styles.body}>

                <p>
                    {t('pages-customers.customerDetailResendInviteMessage')}
                </p>

                <label className={styles.label} htmlFor="firstNameField">
                    {t('blocks-UsersList.inviteFirstName')}
                </label>
                <input
                    className={styles.input}
                    id="firstNameField"
                    onBlur={firstName.onBlur}
                    onChange={e => firstName.setValue(e.target.value)}
                    type="text"
                    value={firstName.value}
                />
                <p className="field-error-message">
                    {!firstName.valid && firstName.errorMessage}
                </p>

                <label className={styles.label} htmlFor="lastNameField">
                    {t('blocks-UsersList.inviteLastName')}
                </label>
                <input
                    className={styles.input}
                    id="lastNameField"
                    onBlur={lastName.onBlur}
                    onChange={e => lastName.setValue(e.target.value)}
                    type="text"
                    value={lastName.value}
                />
                <p className="field-error-message">
                    {lastName.errorMessage}
                </p>

                <label className={styles.label} htmlFor="emailField">
                    {t('blocks-UsersList.inviteEmail')}
                </label>
                <input
                    className={styles.input}
                    id="emailField"
                    onBlur={email.onBlur}
                    onChange={e => email.setValue(e.target.value)}
                    type="text"
                    value={email.value}
                />
                <p className="field-error-message">
                    {email.errorMessage}
                </p>

                <label className={styles.label} htmlFor="birthDateDatePicker">
                    {t('blocks-UsersList.inviteDateOfBirth')}
                </label>
                <DatePicker
                    className={classNames(styles.datepicker)}
                    id="birthDateDatePicker"
                    maxDate={new Date()}
                    onBlur={birthDate.onBlur}
                    onChange={date => birthDate.setValue(date)}
                    selected={birthDate.value}
                />

                <p className="field-error-message">
                    {birthDate.errorMessage}
                </p>
            </div>
        </Modal>
    )

}
