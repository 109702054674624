import { useCommand } from '#command'
import { Button, ButtonRow } from '#components'
import { useTranslation } from '#components/i18n'
import Modal from '#components/Modal'
import ToggleSwitch from '#components/ToggleSwitch'
import { securityRoleTMap } from '#services/enumTranslationKeyMapping'
import { hasViewDepositorRole } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './EmailSettings.module.scss'

interface EmailCategoryProps {
    title: string
    description: string
    notificationType: string
    settings: {[key: string]: boolean}
    onChange: (notificationType: string, value: boolean) => void
    inverse?: boolean
}

function Notifications({t, role, names}: { t: TFunction, role: string, names: string[] }) {
    const [dropDownInformation, setDropDownInformation] = useState(false)
    const roleName = t(securityRoleTMap[role])
    const nameElements = names.map((name, index) => <span key={index} className={styles.orgName}>{name}</span>)

    let roleList
    switch (role) {
        case 'BANK_CUSTOMER_SERVICE':
            roleList = (
                <ul>
                    <li>{t('notificationBecause1')}</li>
                    <li>{t('notificationBecause2')}</li>
                    <li>{t('notificationBecause3')}</li>
                    <li>{t('notificationBecause4')}</li>
                    <li>{t('notificationBecause5')}</li>
                    <li>{t('notificationBecause6')}</li>
                    <li>{t('notificationBecause20')}</li>
                    <li>{t('notificationBecause21')}</li>
                    <li>{t('notificationBecause22')}</li>
                </ul>
            )
            break
        case 'BANK_AD_MANAGER':
            roleList = (
                <ul>
                    <li>{t('notificationBecause7')}</li>
                    <li>{t('notificationBecause8')}</li>
                </ul>
            )
            break
        case 'BANK_ADMIN':
            roleList = (
                <ul>
                    <li>{t('notificationBecause9')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_ORDER':
            roleList = (
                <ul>
                    <li>{t('notificationBecause10')}</li>
                    <li>{t('notificationBecause11')}</li>
                    <li>{t('notificationBecause12')}</li>
                    <li>{t('notificationBecause13')}</li>
                    <li>{t('notificationBecause14')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_ACCOUNT_HOLDER':
            roleList = (
                <ul>
                    <li>{t('notificationBecause15')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_PAYMENT':
            roleList = (
                <ul>
                    <li>{t('notificationBecause19')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_ADMIN':
            roleList = (
                <ul>
                    <li>{t('notificationBecause18')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_VIEW':
            roleList = (
                <ul>
                    <li>{t('notificationBecause16')}</li>
                    <li>{t('notificationBecause17')}</li>
                </ul>
            )
            break

    }
    if (!roleList) {
        return null
    } else {
        return (
            <div className={classNames(styles.notificationList, dropDownInformation ? styles.open : '')}>
                <div className={styles.accordionHeader} onClick={() => setDropDownInformation(!dropDownInformation)}>
                    <h4>{t('notificationListHeader')}<b>{roleName}</b></h4>
                    <i className={dropDownInformation ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}/>
                </div>
                {dropDownInformation && (
                    <>
                        <div className={styles.organisationInfo}>
                            <h5>{t('notificationRoleInfo', {role: roleName})}</h5>
                            <div className={styles.organisations}>{nameElements}</div>
                        </div>
                        <div className={styles.roleList}>
                            <h5>{t('notifications')}</h5>
                            {roleList}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

function UserNotifications({ t }: { t: TFunction }) {
    const hasViewRole = useSelector(hasViewDepositorRole)
    const dropDownInformationDefaultSetting = !hasViewRole
    const [dropDownInformation, setDropDownInformation] = useState(dropDownInformationDefaultSetting)
    return (
        <div className={classNames(styles.notificationList, dropDownInformation ? styles.open : '')}>
            <div className={styles.accordionHeader} onClick={() => setDropDownInformation(!dropDownInformation)}>
                <h4>{t('notificationUserHeader')}</h4>
                <i className={dropDownInformation ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}/>
            </div>
            {dropDownInformation && (
                <>
                    <div className={styles.roleList}>
                        <h5>{t('notifications')}</h5>
                        <ul>
                            <li>{t('notificationBecause23')}</li>
                            <li>{t('notificationBecause24')}</li>
                            <li>{t('notificationBecause25')}</li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
}

function EmailCategory({title, description, notificationType, settings, onChange, inverse}: EmailCategoryProps) {
    const value = inverse ? !settings[notificationType] : settings[notificationType]
    return (
        <div className={styles.emailCategory}>
            <div className={styles.emailCategoryLabel}>
                <div className={styles.emailCategoryTitle}>{title}</div>
                <div className={styles.emailCategoryDescription}>{description}</div>
            </div>
            <ToggleSwitch checked={value} onChange={() => onChange(notificationType, !value)}/>
        </div>
    )
}


export default function EmailSettings() {
    const {t} = useTranslation('pages-inbox')
    const {t: tp} = useTranslation('pages-profile')
    const navigate = useNavigate()

    const settings = useSelector(state => state.notificationSettings?.settings)
    const newNotificationSettings = useSelector(state => state.notificationSettings && !state.notificationSettings.legacySettings)
    const {setNotificationSetting, setUserLegacyNotifications} = useCommand()
    const associations = useSelector(state => state.session.associations)
    const hasViewRole = useSelector(hasViewDepositorRole)

    const roles = useMemo(() => {
        return associations
            .filter(association => association.organisation)
            .reduce((acc, next) => {
                const roles = next.roles
                const name = next.organisation.name
                roles.forEach(role => {
                    if (acc[role]) {
                        acc[role].push(name)
                    } else {
                        acc[role] = [name]
                    }
                })
                return acc
            }, {})
    }, [associations])

    async function onChange(notificationType, value) {
        console.log('value', value)
        const {waitForCommand} = await setNotificationSetting(notificationType, 'DEPOSITOR', value)
        await waitForCommand()
    }

    async function onUserLegacyNotificationsChange(value) {
        const {waitForCommand} = await setUserLegacyNotifications(value)
        await waitForCommand()
    }

    const notificationList = Object.keys(roles).map(role => <Notifications key={role} t={t} role={role} names={roles[role]}/>)

    if (newNotificationSettings) {
        return (
            <>
                <p className="secondary-description">{t('notificationMsg1')}</p>
                {notificationList}
                <UserNotifications t={t}/>
                {hasViewRole &&
                <div className={styles.limitedNotifications}>
                    <h4>{t('notificationTitle2')}</h4>
                    <p>{t('notificationMsg2')}</p>
                    <EmailCategory
                        title={t('notificationLimitLabel')}
                        description={t('notificationLimitDesc')}
                        notificationType={'ONLY_CRITICAL_NOTIFICATIONS'}
                        settings={settings}
                        onChange={onChange}
                    />
                </div>
                }
            </>
        )

    } else {
        return (
            <Modal>
                <h3>{tp('emailNotificationModalHeader')}</h3>
                <p>
                    {tp('emailNotificationModalMsg1')}
                </p>
                <p>
                    {tp('emailNotificationModalMsg2')}
                </p>
                <p>
                    {tp('emailNotificationModalMsg3')}
                </p>
                <p>
                    {tp('emailNotificationModalMsg4')}
                </p>
                <ButtonRow>
                    <Button variant={'secondary'} onClick={() => navigate("/profile#email-notifications")}>{t('common.cancel')}</Button>
                    <Button onClick={() => onUserLegacyNotificationsChange(false)}>{tp('emailNotificationModalButton')}</Button>
                </ButtonRow>
            </Modal>
        )
    }
}
