import { PURPLE, SIGNAL_YELLOW } from "#app/colors/colors";
import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import PartyIcon from "./Party_purple.svg?url";

export default function MessageBanner({
    title,
    description,
    buttonText,
    onClick,
    icon
}: {
    title: string
    icon?: 'party'
    description: string
    buttonText: string
    onClick?: () => void
}) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
                py: 2,
                px: {
                    xs: 2,
                    md: 6,
                },
                backgroundColor: SIGNAL_YELLOW[500],
                color: PURPLE[800],
            }}
        >
            <Stack direction={"row"} spacing={{xs: 1, md: 3}} alignItems={"center"} width={'100%'} maxWidth={'140rem'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={1.5}>
                    {icon === 'party' && !isMobile && (<Box><img src={PartyIcon} alt={''}/></Box>)}
                    <Stack spacing={0.4}>
                        <Typography variant="h3" fontSize={{xs: '1.4rem', md: '1.8rem'}} color={"inherit"} fontWeight="700">{title}</Typography>
                        {description.split('|').map((line, index) => (
                            <Typography key={index} fontSize={{xs: '1.2rem', md: '1.4rem'}} color={PURPLE[800]}>{line}</Typography>
                        ))}
                    </Stack>
                </Stack>
                {onClick && (
                    isMobile ?
                    <Box color={PURPLE[800]}>
                        <IconButton onClick={onClick} color="inherit">
                            <i className="ri-close-line"/>
                        </IconButton>
                    </Box>
                    :
                    <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        sx={{whiteSpace: 'nowrap'}}
                        onClick={onClick}
                    >
                        <span>{buttonText}</span>
                    </Button>
                )}
            </Stack>
        </Stack>
    );
}
